import React, { Component } from 'react'
import { TouchableOpacity, View, Platform } from 'react-native'
import { COLORS } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import _ from 'lodash'
import { ISelectedStoreMap, PrintOptionKey } from 'x/index'
import VStack from './VStack'
import HStack from './HStack'
import XIconButton from './XIconButton'
import XText from './XText'
import XButton from './XButton'
import XSpinner from './XSpinner'
import XOverlay from './XOverlay'

export interface IPrintingOptionUIProps {
  selectedStore: ISelectedStoreMap
  afterSubmit?: (printKey: PrintOptionKey) => void | Promise<void>
  onSubmit: (printKey: PrintOptionKey) => Promise<void>
  onSubmitingStateChange?: (newSubmitting: boolean) => Promise<void>
  onSettings?: () => void | Promise<void>
  disabledSettings?: boolean
  disabledBill?: boolean // default false
  enabledReport?: boolean // default true
}

interface IPrintingOptionUIState {
  selectedPrintingKey: PrintOptionKey
  submitting: boolean
  isOverlayVisible?: boolean
}

class PrintingOptionUI extends Component<IPrintingOptionUIProps, IPrintingOptionUIState> {
  constructor(props: IPrintingOptionUIProps) {
    super(props)
    this.state = {
      selectedPrintingKey: null,
      submitting: false,
    }
  }

  componentDidMount() {
    this._loadDefaultOptionFromAsyncStorage()
  }

  _setPrintingKey = async (pk: PrintOptionKey) => {
    await p.op.storageSet(CONS.STORAGE_KEYS.PRINTING_XOVERLAY_OPTION_KEY, pk)
    this.setState({ selectedPrintingKey: pk })
  }

  _loadDefaultOptionFromAsyncStorage = async () => {
    const defaultPrintingKey = await p.op.storageGet(CONS.STORAGE_KEYS.PRINTING_XOVERLAY_OPTION_KEY)
    const { PRINTING_LABLE_KEY, PRINTING_BILL, PRINTING_REPORTS } = this._getPrintingKeys()
    if (_.includes(PRINTING_LABLE_KEY, defaultPrintingKey) || _.includes(PRINTING_BILL, defaultPrintingKey)) {
      await this._setPrintingKey(defaultPrintingKey as PrintOptionKey)
    } else if (_.includes(PRINTING_LABLE_KEY, defaultPrintingKey) || _.includes(PRINTING_REPORTS, defaultPrintingKey)) {
      await this._setPrintingKey(defaultPrintingKey as PrintOptionKey)
    } else {
      await this._setPrintingKey(PRINTING_LABLE_KEY[0])
    }
  }

  _getPrintingKeys = () => {
    const { selectedStore, disabledBill, enabledReport } = this.props
    const usePaperang = util.isUsePaperangPrinting(selectedStore)
    const useA4PDF = util.isUseA4PDFPrinting(selectedStore)
    const useStickerPDF = util.isUseStickerPDFPrinting(selectedStore)
    const isUseBillPrinting = util.isUseBillPrinting(selectedStore)
    const isUseReportPrinting = true

    const PRINTING_LABLE_KEY: PrintOptionKey[] = []
    const PRINTING_BILL: PrintOptionKey[] = []
    const PRINTING_REPORTS: PrintOptionKey[] = []

    if (usePaperang) PRINTING_LABLE_KEY.push(CONS.PRINT_OPTIONS.PAPERANG)
    if (useA4PDF) PRINTING_LABLE_KEY.push(CONS.PRINT_OPTIONS.A4_PDF)
    if (useStickerPDF) PRINTING_LABLE_KEY.push(CONS.PRINT_OPTIONS.STICKER_PDF)
    if (isUseBillPrinting && !disabledBill) PRINTING_BILL.push(CONS.PRINT_OPTIONS.BILL_PAPERANG)
    if (isUseReportPrinting && enabledReport) PRINTING_REPORTS.push(CONS.PRINT_OPTIONS.REPORT_PRODUCT_DAILY_SALES)

    return { PRINTING_LABLE_KEY, PRINTING_BILL, PRINTING_REPORTS }
  }

  _renderPrintItem = (key: PrintOptionKey, onPick: (key: PrintOptionKey) => void | Promise<void>) => {
    const { selectedPrintingKey } = this.state
    const printing = _.find(PRINTING_DATAS, (parting) => parting.key === key)
    const isActive = key === selectedPrintingKey
    return (
      <TouchableOpacity
        key={key}
        onPress={() => onPick(key)}
        style={{
          ...Platform.select({
            ios: { flex: 0 },
            android: { flex: 0 },
          }),
          flexBasis: 'auto',
          borderColor: isActive ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderRadius: 7,
          paddingLeft: 4,
          paddingBottom: 4,
          paddingRight: 4,
          paddingTop: 4,
          height: 35,
          marginTop: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {isActive ? <XIcon name='caretright' family='AntDesign' minW='30px' /> : <View style={{ width: 30 }} />}
        <XText pl='2' variant={isActive ? 'active' : 'inactive'}>{`${printing.lable}`}</XText>
      </TouchableOpacity>
    )
  }

  renterPrintOptionHeader = () => {
    const { onSettings, disabledSettings } = this.props

    return (
      <HStack w='full' minH='9' py='2' px='3' borderBottomWidth='1' borderColor='muted.300'>
        <HStack flex='1' alignItems='center' justifyContent='center'>
          <XText bold>โหลดเอกสาร</XText>
        </HStack>
        {/* {!_.isFunction(onSettings) || disabledSettings ? null : <XIconButton name='settings' family='Ionicons' onPress={onSettings} />} */}
        {!_.isFunction(onSettings) || disabledSettings ? null : (
          <XIconButton name='settings' family='Ionicons' onPress={this._handleOnSetting} />
        )}
      </HStack>
    )
  }

  _setSubmitting = async () => {
    this.setState({ submitting: true })
    if (_.isFunction(this.props.onSubmitingStateChange)) {
      await this.props.onSubmitingStateChange(true)
    }
  }

  _unsetSubmitting = async () => {
    this.setState({ submitting: false })
    if (_.isFunction(this.props.onSubmitingStateChange)) {
      await this.props.onSubmitingStateChange(false)
    }
  }

  handleOnSubmitByKey = async () => {
    const { onSubmit, afterSubmit } = this.props
    const { selectedPrintingKey } = this.state
    await this._setSubmitting()
    await util.delay(200)
    await onSubmit(selectedPrintingKey)
    await util.delay(200)
    await this._unsetSubmitting()
    if (_.isFunction(afterSubmit)) {
      afterSubmit(selectedPrintingKey)
    }
  }

  renderPrintOptionFooter = () => {
    const { submitting } = this.state
    return (
      <XButton w='full' disabled={submitting} isDisabled={submitting} onPress={this.handleOnSubmitByKey}>
        {submitting ? <XSpinner size='sm' color='white' /> : 'ดาวน์โหลด'}
      </XButton>
    )
  }

  openOverlay = async () => {
    this.setState({ isOverlayVisible: true })
  }

  closeOverlay = () => {
    const { submitting } = this.state
    if (submitting) {
      return
    }
    this.setState({ isOverlayVisible: false })
  }

  _handleOnSetting = async () => {
    const { onSettings } = this.props
    if (_.isFunction(onSettings)) {
      this.closeOverlay()
      await util.delay(200)
      onSettings()
    }
  }

  render() {
    const { onSettings, disabledSettings, disabledBill, enabledReport } = this.props
    const { isOverlayVisible } = this.state
    const { PRINTING_LABLE_KEY, PRINTING_BILL, PRINTING_REPORTS } = this._getPrintingKeys()

    return (
      <XOverlay visible={isOverlayVisible} onRequestClose={this.closeOverlay}>
        <VStack w='full' space='1'>
          {this.renterPrintOptionHeader()}
          <VStack w='full' px='3' py='1' space='3'>
            <VStack w='full'>
              <XText bold>ใบปะหน้าพัสดุ</XText>
              {PRINTING_LABLE_KEY.map((key: PrintOptionKey) => this._renderPrintItem(key, this._setPrintingKey))}
            </VStack>

            <VStack w='full'>
              {!disabledBill ? <XText bold>ใบเสร็จรับเงิน</XText> : null}
              {!disabledBill ? PRINTING_BILL.map((key: PrintOptionKey) => this._renderPrintItem(key, this._setPrintingKey)) : null}
            </VStack>

            <VStack w='full'>
              {enabledReport ? <XText bold>รายงาน</XText> : null}
              {enabledReport ? PRINTING_REPORTS.map((key: PrintOptionKey) => this._renderPrintItem(key, this._setPrintingKey)) : null}
            </VStack>
          </VStack>
          <HStack w='full' p='2'>
            {this.renderPrintOptionFooter()}
          </HStack>
        </VStack>
      </XOverlay>
    )
  }
}

const PRINTING_DATAS = [
  // Shipping Label
  {
    key: CONS.PRINT_OPTIONS.PAPERANG,
    lable: 'Paperang',
  },
  {
    key: CONS.PRINT_OPTIONS.A4_PDF,
    lable: 'PDF A4',
  },
  {
    key: CONS.PRINT_OPTIONS.STICKER_PDF,
    lable: 'PDF Sticker',
  },
  // Bill
  {
    key: CONS.PRINT_OPTIONS.BILL_PAPERANG,
    lable: 'Paperang',
  },
  // Reports
  {
    key: CONS.PRINT_OPTIONS.REPORT_PRODUCT_DAILY_SALES,
    lable: 'การขายสินค้ารายวัน',
  },
]

export default PrintingOptionUI
