import { List } from 'immutable'
import { connect } from 'react-redux'

import { getState, getDispatch } from 'x/modules/orderlist/StoreOrderSingleTabListViewConnect'
import * as NavActions from 'x/utils/navigation'
import CONS from 'x/config/constants'
import { IOrderListItem, IOrderListViewProps, IOrderListViewState } from 'x/index'

import BaseUIOrderListView from './BaseUIOrderListView'

class OrderListView extends BaseUIOrderListView<IOrderListViewProps, IOrderListViewState> {
  static displayName = 'OrderListView'

  // ฉันเป็นผู้ช่วย?
  isHelper = () => {
    const { selectedStore } = this.props
    return selectedStore.has('role_id') ? selectedStore.get('role_id') === CONS.STORE_ROLE.HELPER : false
  }

  getOverridePreSetupInitialState = (): Partial<IOrderListViewState> => {
    const { selectedStore, sellerStores } = this.props
    const hasReseller = selectedStore.get('has_reseller') || false
    const hasSeller = List.isList(sellerStores) && sellerStores.size > 0

    // ไม่มีทั้ง seller / reseller
    let availableTabKeys = CONS.ORDER_SUMMARY_NO_SELLER_AND_RESELLER

    if (hasSeller) {
      availableTabKeys = CONS.ORDER_SUMMARY_NO_RESELLER
    }

    if (hasReseller) {
      availableTabKeys = CONS.ORDER_SUMMARY_NO_SELLER
    }

    if (hasSeller && hasReseller) {
      availableTabKeys = CONS.ORDER_SUMMARY_ALL
    }

    if (this.isHelper()) {
      availableTabKeys = CONS.ORDER_SUMMARY_ALL
    }

    return { availableTabKeys, isCheckable: true }
    // return { availableTabKeys }
  }

  onPressOrderListItem = (od: IOrderListItem) => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToOrderDetail({
        order_id: od.id,
        store_id: this.getStoreId(),
      })
    )
    // navigation.dispatch(
    //   NavActions.navToOrderListView({
    //     // order_id: od.id,
    //     store_id: this.getStoreId(),
    //     onPressOrderListItem: this._mockOnPressOrderListItem,
    //     onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
    //   })
    // )

    // p.op.showActionSheet(
    //   {
    //     title: 'Nav to test ...',
    //     options: ['PrintDocument', 'AutoShipment', 'AutoPayment', 'MkpConfirm', 'Cancel'],
    //     destructiveButtonIndex: 3,
    //   },
    //   (idx) => {
    //     switch (idx) {
    //       case 0:
    //         this._navToPrintDocument()
    //         break
    //       case 1:
    //         this._navToAutoShipment()
    //         break
    //       case 2:
    //         this._navToAutoPayment()
    //         break
    //       case 3:
    //         this._navToMkpConfirm()
    //         break
    //     }
    //   }
    // )
  }

  getSubmitCheckOrdersButtonText = () => {
    const checkedOrders = this.getCheckedOrderIds()
    const checkedCount = checkedOrders.length

    if (checkedCount === 0) {
      return 'กรุณาเลือกออเดอร์'
    }

    return 'ดำเนินการ'
  }

  renderSubmitCheckOrdersButton = () => {
    const { isCheckable, isInitialized } = this.state
    if (!isCheckable || !isInitialized) {
      return null
    }

    return this.renderMultiOperationSubmitCheckOrdersButton()
  }

  // _navToPrintDocument = () => {
  //   const { navigation } = this.props
  //   navigation.dispatch(
  //     NavActions.navToPrintDocumentOrderListView({
  //       // order_id: od.id,
  //       store_id: this.getStoreId(),
  //       // onPressOrderListItem: this._mockOnPressOrderListItem,
  //       // onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
  //     })
  //   )
  // }

  // _navToAutoShipment = () => {
  //   const { navigation } = this.props
  //   navigation.dispatch(
  //     NavActions.navToAutoShipmentOrderListView({
  //       // order_id: od.id,
  //       store_id: this.getStoreId(),
  //       // onPressOrderListItem: this._mockOnPressOrderListItem,
  //       // onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
  //     })
  //   )
  // }

  // _navToAutoPayment = () => {
  //   const { navigation } = this.props
  //   navigation.dispatch(
  //     NavActions.navToAutoPaymentOrderListView({
  //       // order_id: od.id,
  //       store_id: this.getStoreId(),
  //       // onPressOrderListItem: this._mockOnPressOrderListItem,
  //       // onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
  //     })
  //   )
  // }

  // _navToMkpConfirm = () => {
  //   const { navigation } = this.props
  //   navigation.dispatch(
  //     NavActions.navToMkpConfirmOrderListView({
  //       // order_id: od.id,
  //       store_id: this.getStoreId(),
  //       // onPressOrderListItem: this._mockOnPressOrderListItem,
  //       // onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
  //     })
  //   )
  // }

  // _mockOnPressOrderListItem = (od: IOrderListItem) => {
  //   p.op.alert('_mockOnPressOrderListItem', JSON.stringify(od))
  // }

  // _mockOnPressSubmitCheckedOrders = (orderIds: number[]) => {
  //   p.op.alert('_mockOnPressSubmitCheckedOrders', JSON.stringify(orderIds))
  // }
}

// export default OrderListView

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(OrderListView)
