import React from 'react'
import { FlatList, TouchableOpacity, ListRenderItemInfo } from 'react-native'

import * as util from 'x/utils/util'
import * as acl from 'x/utils/acl'
import p from 'x/config/platform-specific'

import _ from 'lodash'
import { IAddress, IAddressFormSubmitReturn, IStoreAddressListViewProps, IStoreAddressListViewState } from 'x/index'
import XContainer from 'xui/components/XContainer'
import { VStack } from 'native-base'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import * as api from 'x/utils/api'
import XInput from 'xui/components/XInput'
import XIconButton from 'xui/components/XIconButton'
import * as NavActions from 'x/utils/navigation'
import XSpinner from 'xui/components/XSpinner'
import XCustomHeader from 'xui/components/XCustomHeader'
import XIcon from 'xui/components/XIcon'
import XAddressCard from 'xui/components/XAddressCard'
import XCard from 'xui/components/XCard'
import { COLORS } from 'x/config/styles'

const FETCH_LIMIT = 40

export default class StoreAddressListView extends React.Component<IStoreAddressListViewProps, IStoreAddressListViewState> {
  static displayName = 'StoreAddressListView'

  isAddressCardPressed?: boolean

  isRefreshing?: boolean

  isLoadingMore?: boolean

  inQueueSearch?: NodeJS.Timeout

  constructor(props) {
    super(props)
    this.state = {
      isInitialized: false,
      isRefreshing: false,
      isLoadingMore: false,

      addresses: [],
      hasMore: false,

      searchAddresses: [],
      hasSearchMore: false,
      searchText: '',
    }
  }

  async componentDidMount(): Promise<void> {
    await this._initialize()
  }

  _initialize = async () => {
    await this._refresh()
    await util.setStatePromise(this, { isInitialized: true })
  }

  _refresh = async () => {
    if (this.isRefreshing) {
      return
    }

    this.isRefreshing = true
    await util.setStatePromise(this, { isRefreshing: true })

    if (this.isSearch()) {
      await util.setStatePromise(this, { isSearching: true })
    }

    try {
      const store_id = util.getNavParam(this.props, 'store_id')
      const reqBody: any = {
        store_id,
        offset: 0,
        limit: FETCH_LIMIT,
      }

      if (this.isSearch()) {
        reqBody.search_name = this.state.searchText
      }

      const loadedAddresses = await api.getStoreAddressList(reqBody)
      const hasMore = loadedAddresses.length === FETCH_LIMIT

      if (this.isSearch()) {
        await util.setStatePromise(this, { searchAddresses: loadedAddresses, hasSearchMore: hasMore })
      } else {
        await util.setStatePromise(this, { addresses: loadedAddresses, hasMore })
      }
    } catch (err) {
      console.log('_refresherr => ', err)
    }

    await util.setStatePromise(this, { isRefreshing: false, isSearching: false })
    this.isRefreshing = false
  }

  _loadMore = async () => {
    if (this.isRefreshing || this.isLoadingMore) {
      return
    }

    const { hasMore, hasSearchMore } = this.state
    if (!this.isSearch() && !hasMore) {
      return
    }

    if (this.isSearch() && !hasSearchMore) {
      return
    }

    this.isLoadingMore = true
    await util.setStatePromise(this, { isLoadingMore: true })

    try {
      const store_id = util.getNavParam(this.props, 'store_id')
      const { addresses, searchAddresses, searchText } = this.state
      const offset = this.isSearch() ? searchAddresses.length : addresses.length
      const reqBody: any = {
        store_id,
        offset,
        limit: FETCH_LIMIT,
      }

      if (this.isSearch()) {
        reqBody.search_name = searchText
      }

      const loadedAddresses = await api.getStoreAddressList(reqBody)
      const hasMoreItems = loadedAddresses.length === FETCH_LIMIT

      if (this.isSearch()) {
        await util.setStatePromise(this, { searchAddresses: searchAddresses.concat(loadedAddresses), hasSearchMore: hasMoreItems })
      } else {
        await util.setStatePromise(this, { addresses: addresses.concat(loadedAddresses), hasMore: hasMoreItems })
      }
    } catch (err) {
      console.log('_refresherr => ', err)
    }

    await util.setStatePromise(this, { isLoadingMore: false })
    this.isLoadingMore = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _onSearchTextChange = (text: string) => {
    const isSearching = text.length > 0

    this.setState({ searchText: text, searchAddresses: [], hasSearchMore: false, isSearching }, () => {
      if (this.inQueueSearch) {
        clearTimeout(this.inQueueSearch)
      }

      this.inQueueSearch = setTimeout(this._refresh, 1000)
    })
  }

  _clearSearchText = () => {
    this.setState({ searchAddresses: [], hasSearchMore: false, isSearching: false, searchText: '' })
  }

  _onSubmitAddStoreAddress = async (addr: IAddress): Promise<IAddressFormSubmitReturn> => {
    console.log('_onSubmitAddStoreAddress addr => ', addr)

    let shouldGoBack = false

    try {
      const store_id = util.getNavParam(this.props, 'store_id')
      const newAddr = await api.addStoreAddress({ store_id, ...addr })
      if (newAddr && 'id' in newAddr) {
        await util.setStatePromise(this, { addresses: [newAddr, ...this.state.addresses] })
        shouldGoBack = true
      }
    } catch (error) {
      console.log('_onSubmitAddStoreAddress error => ', error)
    }

    return {
      isGoBack: shouldGoBack,
      newAddress: addr,
    }
  }

  _navToAddStoreAddress = () => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'เพิ่มที่อยู่ร้าน',
        type: 'default',
        onSubmit: this._onSubmitAddStoreAddress,
      })
    )
  }

  _renderAddStoreAddressButton = () => {
    if (!acl.isSelectedStoreOwner()) {
      return null
    }

    return (
      <TouchableOpacity onPress={this._navToAddStoreAddress} style={{ width: 44, alignItems: 'center', justifyContent: 'center' }}>
        {/* <AddIcon /> */}
        <XIcon name='add' />
      </TouchableOpacity>
    )
  }

  renderCustomHeader = () => {
    // return <XCustomHeader title='รายชื่อลูกค้า' headerLeftProps={{ backIcon: true, onPressItem: this._goBack }} />
    const { searchText = '' } = this.state
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    const title = util.getNavParam(this.props, 'title', 'ที่อยู่ร้านฉัน')

    return (
      <VStack w='full'>
        <XCustomHeader
          title={title}
          headerLeftProps={isGoBackButtonHidden ? null : { backIcon: true, onPressItem: this._goBack }}
          headerRight={this._renderAddStoreAddressButton()}
        />

        <HStack
          w='full'
          px='2'
          py='1'
          minH='44px'
          space='1'
          alignItems='center'
          justifyContent='center'
          borderBottomWidth='1'
          borderBottomColor='gray.300'>
          {/* {isGoBackButtonHidden ? null : <NavHeaderButton backIcon onPressItem={this._goBack} />} */}
          <HStack flex={1}>
            <XInput style={{ width: '100%' }} value={searchText} onChangeText={this._onSearchTextChange} placeholder='ค้นหาด้วยชื่อ...' />

            {this.isSearch() && (
              <HStack position='absolute' right='0' top='0' bottom='0'>
                <XIconButton name='close' onPress={this._clearSearchText} />
              </HStack>
            )}
          </HStack>
          {/* <TouchableOpacity onPress={this._navToAddStoreAddress} style={{ width: 44, alignItems: 'center', justifyContent: 'center' }}>
          <AddIcon />
        </TouchableOpacity> */}
        </HStack>
      </VStack>
    )
  }

  _deleteAddress = (addr: IAddress) => {
    this._doDeleteAddress(addr)
  }

  _doDeleteAddress = async (addr: IAddress) => {
    const isUserConfirmed = await p.op.isUserConfirm('ลบที่อยู่', `ต้องการลบที่อยู่ ${addr.name} ใช่หรือไม่`)

    if (!isUserConfirmed) {
      return
    }

    const store_id = util.getNavParam(this.props, 'store_id')

    try {
      await api.deleteStoreAddress({ store_id, id: addr.id })

      await util.setStatePromise(this, {
        addresses: this.state.addresses.filter((adr) => adr.id !== addr.id),
        searchAddresses: this.state.searchAddresses.filter((adr) => adr.id !== addr.id),
      })

      const onSuccessDeleteAddress = util.getNavParam(this.props, 'onSuccessDeleteAddress')
      if (_.isFunction(onSuccessDeleteAddress)) {
        onSuccessDeleteAddress(addr.id)
      }
    } catch (err) {
      console.log('_doDeleteAddress err => ', err)
    }
  }

  _editAddress = (addr: IAddress) => {
    this._doEditAddress(addr)
  }

  _doEditAddress = (addr: IAddress) => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'แก้ไขที่อยู่ร้าน',
        initAddress: addr,
        onSubmit: this._onSubmitEditStoreAddress,
      })
    )
  }

  _onSubmitEditStoreAddress = async (addr: IAddress): Promise<IAddressFormSubmitReturn> => {
    const store_id = util.getNavParam(this.props, 'store_id')
    const reqBody = { ...addr }
    // @ts-ignore
    reqBody.store_id = store_id
    let isSuccess = false
    try {
      // @ts-ignore
      const newAddr = await api.editStoreAddress(reqBody)
      // console.log('_onSubmitEditStoreAddress newAddr => ', newAddr)

      if ('id' in newAddr) {
        isSuccess = true
        await util.setStatePromise(this, {
          addresses: this.state.addresses.map((adr) => (adr.id === newAddr.id ? newAddr : adr)),
          searchAddresses: this.state.searchAddresses.map((adr) => (adr.id === newAddr.id ? newAddr : adr)),
        })

        const onSuccessEditAddress = util.getNavParam(this.props, 'onSuccessEditAddress')
        if (_.isFunction(onSuccessEditAddress)) {
          onSuccessEditAddress(addr.id, newAddr)
        }
      }
    } catch (err) {
      console.log('_onSubmitEditStoreAddress err => ', err)
    }

    return { isGoBack: isSuccess, newAddress: addr }
  }

  _onPickEmptyAddress = () => {
    this._doPickEmptyAddress()
  }

  _doPickEmptyAddress = async () => {
    if (this.isAddressCardPressed) {
      return
    }
    this.isAddressCardPressed = true
    await util.delay(200)

    const onAddressCardPress = util.getNavParam(this.props, 'onAddressCardPress')
    const emptyAddressChoiceLabel = util.getNavParam(this.props, 'emptyAddressChoiceLabel', '')

    const emptyAddress = {
      id: null,
      name: null,
      address1: emptyAddressChoiceLabel,
    }

    // @ts-ignore
    onAddressCardPress(emptyAddress)

    await util.delay(200)
    this._goBack()

    await util.delay(200)
    this.isAddressCardPressed = false
  }

  _onPressCard = (addr: IAddress) => {
    this._doOnPressCard(addr)
  }

  _doOnPressCard = async (addr: IAddress) => {
    if (this.isAddressCardPressed) {
      return
    }
    this.isAddressCardPressed = true
    await util.delay(200)
    const onAddressCardPress = util.getNavParam(this.props, 'onAddressCardPress', null)

    onAddressCardPress(addr)
    await util.delay(200)
    this._goBack()

    await util.delay(200)
    this.isAddressCardPressed = false
  }

  renderStoreAddressListItem = (itemInfo: ListRenderItemInfo<IAddress>) => {
    const { index, item } = itemInfo
    const onAddressCardPress = util.getNavParam(this.props, 'onAddressCardPress', null)

    return (
      <VStack key={`addr-card-${index}-${item.id}`} w='full' px='2' py='1'>
        <XAddressCard
          address={item}
          // isEditable={acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.)}
          isEditable={acl.isSelectedStoreOwner()}
          isDeletable={acl.isSelectedStoreOwner()}
          onPressDelete={this._deleteAddress}
          onPressEdit={this._editAddress}
          onPressCard={_.isFunction(onAddressCardPress) ? (Address: IAddress) => this._onPressCard(Address) : null}
        />
      </VStack>
    )
  }

  keyExtractor = (item, index) => `sAddrItem_${index}_${item.id}`

  isSearch = () => {
    const { searchText = '' } = this.state
    return _.isString(searchText) && searchText.length > 0
  }

  _renderListHeader = () => {
    const { isSearching } = this.state

    if (isSearching) {
      return null
    }

    const onAddressCardPress = util.getNavParam(this.props, 'onAddressCardPress')
    if (!_.isFunction(onAddressCardPress)) {
      return null
    }

    const emptyAddressChoiceLabel = util.getNavParam(this.props, 'emptyAddressChoiceLabel', null)
    if (!_.isString(emptyAddressChoiceLabel)) {
      return null
    }

    return (
      <VStack w='full' px='2' py='1'>
        <XCard w='full' onPress={this._onPickEmptyAddress}>
          <VStack w='full' p='2'>
            <HStack w='full' space='1.5' minH='34px' alignItems='center'>
              <VStack
                w='40px'
                // pt='2.5'
                alignItems='center'>
                <XIcon size='sm' variant='inactive' name='address-book-o' family='FontAwesome' />
              </VStack>
              {/* <VStack flex={1} minH='34px'> */}
              <XText variant='active'>{emptyAddressChoiceLabel}</XText>
              {/* </VStack> */}
            </HStack>
          </VStack>
        </XCard>
      </VStack>
    )
  }

  _renderListFooter = () => {
    const { isLoadingMore, isSearching, searchAddresses, searchText } = this.state

    if (isSearching) {
      return (
        <HStack w='full' px='4' py='8' alignItems='center' justifyContent='center'>
          <XSpinner variant='inactive' />
        </HStack>
      )
    }

    if (isLoadingMore) {
      return (
        <HStack w='full' p='4' alignItems='center' justifyContent='center'>
          <XSpinner variant='inactive' />
        </HStack>
      )
    }

    if (this.isSearch() && !isSearching && searchAddresses.length === 0) {
      return (
        <HStack w='full' p='4' alignItems='center' justifyContent='center'>
          <XText variant='inactive'>{`ไม่พบที่อยู่ร้านจาก "${searchText}"`}</XText>
        </HStack>
      )
    }

    return (
      <HStack w='full' p='2' borderTopWidth='1' borderTopColor='gray.200' alignItems='center' justifyContent='center'>
        <XText variant='inactive'>สิ้นสุดรายการ</XText>
      </HStack>
    )
  }

  renderStoreAddressList = () => {
    const { addresses, searchAddresses, isRefreshing, isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    const adrs = this.isSearch() ? searchAddresses : addresses
    const listKey = this.isSearch() ? 'searchAddresses' : 'addresses'

    return (
      <FlatList<IAddress>
        key={listKey}
        data={adrs}
        refreshing={isRefreshing}
        onRefresh={this._refresh}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderStoreAddressListItem}
        onEndReached={this._loadMore}
        onEndReachedThreshold={0.8}
        ListHeaderComponent={this._renderListHeader}
        ListFooterComponent={this._renderListFooter}
        style={{ backgroundColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE }}
      />
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        {this.renderStoreAddressList()}
      </XContainer>
    )
  }
}
