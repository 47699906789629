import React from 'react'

import { StyleSheet, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import XIcon from './XIcon'
import HStack from './HStack'
import XText from './XText'

interface XCheckboxProps<DataType = any> {
  checked: boolean
  onPress?: (args: {
    disabled?: boolean
    index?: number
    data?: DataType
    name?: string
    label?: string
    // current checked
    checked: boolean
  }) => void
  disabled?: boolean
  index?: number
  name?: string
  label?: string
  data?: DataType
  iconStyle?: { [key: string]: any }
  buttonStyle?: { [key: string]: any }
  textStrikeThrough?: boolean

  renderLabel?: (args: {
    disabled?: boolean
    index?: number
    data?: DataType
    name?: string
    label?: string
    checked: boolean // current checked
  }) => JSX.Element
}

class CheckboxIcon extends React.PureComponent<XCheckboxProps> {
  render() {
    const { disabled = false, checked = null, iconStyle = {} } = this.props

    return (
      <HStack pb='1px'>
        <XIcon
          name={checked ? 'check-square-o' : 'square-o'}
          family='FontAwesome'
          w='24px'
          h='24px'
          variant={disabled ? 'inactive' : 'primary'}
          style={iconStyle}
        />
      </HStack>
    )

    // return (
    //   <Icon
    //     type='FontAwesome'
    //     style={{
    //       fontSize: STYLES.FONT_ICON_NORMAL,
    //       height: STYLES.FONT_ICON_NORMAL,
    //       width: STYLES.FONT_ICON_NORMAL,
    //       color: disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
    //       textAlign: 'center',
    //       ...iconStyle,
    //     }}
    //     name={checked ? 'check-square-o' : 'square-o'}
    //   />
    // )
  }
}

export default class XCheckbox<T> extends React.PureComponent<XCheckboxProps<T>> {
  static displayName = 'XCheckbox'

  _onPress = () => {
    const { index, name, data, onPress, checked } = this.props
    if (_.isFunction(onPress)) {
      onPress({ index, data, name, checked })
    }
  }

  _renderCheckbox = () => {
    const { disabled = false, checked = false, ...restProps } = this.props

    return <CheckboxIcon {...restProps} disabled={disabled || false} checked={checked || false} />
  }

  _renderLabel = () => {
    const { disabled, checked, label, textStrikeThrough = false, renderLabel } = this.props

    if (_.isFunction(renderLabel)) {
      return renderLabel(this.props)
    }

    if (_.isString(label) && label.length > 0) {
      return (
        <XText variant={disabled || !checked ? 'inactive' : 'active'} strikeThrough={textStrikeThrough}>
          {label}
        </XText>
      )
    }

    return null
  }

  render() {
    const { disabled = false, onPress = null, buttonStyle = {} } = this.props
    if (!_.isFunction(onPress)) {
      return this._renderCheckbox()
    }
    return (
      <TouchableOpacity disabled={disabled} onPress={this._onPress}>
        <HStack py='1' space='0.5' alignItems='center' style={StyleSheet.flatten([buttonStyle])}>
          {this._renderCheckbox()}
          {this._renderLabel()}
        </HStack>
      </TouchableOpacity>
    )
  }
}
