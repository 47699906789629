/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Dimensions, StyleSheet, TouchableOpacity } from 'react-native'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getSelectedStore, getSellerStores } from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'
// import * as OrderState from 'x/modules/order/OrderState'
import { COLORS, STYLES } from 'x/config/styles'
import * as xUtil from 'x/utils/util'
import * as acl from 'x/utils/acl'
import XIcon from 'xui/components/XIcon'
import 'intl/locale-data/jsonp/th.js'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import _ from 'lodash'
import {
  IBaseOrderListItemForDisplayProps,
  IOrderListItem,
  IOrderListItemVisibilitySetting,
  IOrderXShippingInfo,
  ISelectedStoreMap,
} from 'x/index'
import Immutable, { fromJS } from 'immutable'
import MkpLogo from 'xui/components/MkpLogo'
// import ExpireTimer from '../ExpireTimer'
import * as xFmt from 'x/utils/formatter'
import time from 'x/utils/time'
// import { Divider, VStack } from 'native-base'
import HStack from 'xui/components/HStack'
import NB from 'x/config/nativebase'
import * as util from 'x/utils/util'
import XText from '../XText'
import XShipping from '../XShipping'
import VStack from '../VStack'
import Divider from '../Divider'
import ErpLogo from '../ErpLogo'
import ShippingTypePicker from '../order/ShippingTypePicker'
import ForwardIcon from '../ForwardIcon'
import Box from '../Box'

import XSpinner from '../XSpinner'
import XImage from '../XImage'

// const COL_WIDTH_CHECKER = p.op.isWeb() ? 32 : 48
// const COL_WIDTH_INFO = 36

const MAX_SHIPPING_BUTTON_WIDTH = p.op.isWeb() ? 250 : Dimensions.get('window').width - 100

interface IOrderListItemProps {
  index: number
  data: IOrderListItem
  // onPress: (orderId: number) => void

  isCheckable?: boolean
  checked?: boolean
  onCheckedPress?: (orderId: number) => void

  onPress: (order: IOrderListItem) => void
  // onPressLeftCol: (orderId: number) => void
  // onPressRightCol: Function
  // isSelected: boolean
  // mode: string
  selectedStore: ISelectedStoreMap
  // autoRequestTracking: (params: ActionApiParams) => void
  // createTackingCode: (params: ActionApiParams) => void
  // updateTackingCode: (params: ActionApiParams) => void
  // cancelTackingCode: (params: ActionApiParams) => void
  // setShouldFetchOrderSummary?: Function

  visibilitySetting?: IOrderListItemVisibilitySetting

  // onShippingTypeChange: (params: {
  //   store_id: number
  //   order_id: number
  //   shipping_type_id: number
  //   update_sales_order?: number
  //   update_purchase_orders?: number[]
  //   successCallback?: () => void
  // }) => void

  onDataChange?: (newData: IOrderListItem, index: number) => void
  onErrorItemUpdate?: (orderId: number, errorMessage: { title: string; message: string }) => void
  resetErrorMessage?: (orderId: number) => void
  errorMessage?: { title?: string; message: string }

  onRequestEditShippingType?: (orderId: number, newShippingTypeId: number) => Promise<void>
}

interface IOrderListItemState {
  isLoading?: boolean
}

class OrderListItem extends React.Component<IOrderListItemProps, IOrderListItemState> {
  static displayName = 'OrderListItem'

  inProcess?: boolean

  constructor(props: IOrderListItemProps) {
    super(props)

    this.state = {
      isLoading: false,
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   const { errorMessage, data } = nextProps
  //   const { errorMessage: oldErrorMessage, data: oldData } = this.props

  //   // If "errorMessage" or "data" is different, then update
  //   return errorMessage !== oldErrorMessage || data !== oldData
  // }

  // constructor(props) {
  //   super(props)
  //   // this._handleDimensionsChange = this._handleDimensionsChange.bind(this)
  //   this._renderCheckerCol = this._renderCheckerCol.bind(this)
  // }

  // componentDidMount() {
  //   if (this._isModeSelectPayment()) {
  //     Dimensions.addEventListener('change', this._handleDimensionsChange)
  //   }
  // }

  // _handleDimensionsChange(newDims) {
  //   // console.log('_handleDimensionsChange')
  //   this.mainContentWidth = newDims.width - COL_WIDTH_INFO - COL_WIDTH_CHECKER
  //   this.forceUpdate()
  // }

  // startTimer() {
  //   // console.log('startTime for ', this.props.data)
  //   if (this.refs.ExpireTimer) {
  //     // @ts-ignore call method in js component
  //     this.refs.ExpireTimer.startTimer()
  //   }
  // }

  // stopTimer() {
  //   // console.log('stopTime for ' + this.props.data)
  //   if (this.refs.ExpireTimer) {
  //     // @ts-ignore call method in js component
  //     this.refs.ExpireTimer.stopTimer()
  //   }
  // }

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  _onPress = () => {
    const { data, onPress } = this.props
    // log('BaseOrderListItem._onPress')
    // log(this.props.data, this.props.data.item.toJS())
    // onPress(data.item.get('id'))
    // onPress(data.id)
    if (typeof onPress === 'function') {
      onPress(data)
    }
    // this.props.navigation.dispatch(NavActions.navToOrderDetail(item.get('id'), 1))
  }

  _onDataChange = (newData: IOrderListItem) => {
    const { onDataChange, index } = this.props
    if (_.isFunction(onDataChange)) {
      onDataChange(newData, index)

      // setTimeout(() => {
      //   this.forceUpdate()
      // }, 1000)
    }
  }

  _onXShippingDataChange = (newX: IOrderXShippingInfo) => {
    const { data } = this.props
    const newData = { ...data, x: newX }
    this._onDataChange(newData)
  }

  _onItemErrorUpdate = (orderId: number, errorMessage: { title: string; message: string }) => {
    const { onErrorItemUpdate } = this.props
    if (_.isFunction(onErrorItemUpdate)) {
      onErrorItemUpdate(orderId, errorMessage)

      // setTimeout(() => {
      //   this.forceUpdate()
      // }, 1000)
    }
  }

  _editShippingType = async (newShippingTypeId: number) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { selectedStore, data, onRequestEditShippingType } = this.props

    if (data.y === newShippingTypeId) {
      const shippingTypeName = util.getShippingTypeNameById(newShippingTypeId)
      await p.op.alertPromise(`รูปแบบจัดส่งเดิมคือ "${shippingTypeName}" อยู่แล้ว`)
      this.inProcess = false
      return
    }

    if (util.isShippingTypeCod(data.y) && !util.isShippingTypeCod(newShippingTypeId)) {
      // กรณีเปลี่ยนจาก COD เป็นไม่ใช่ COD
      await p.op.alertPromise('ไม่สามารถเปลี่ยนรูปแบบจัดส่งจากที่ "เป็น COD" เป็น "ไม่ใช่ COD" ได้')
      this.inProcess = false
      return
    }

    const hasPermission = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_EDIT)
    if (data.prevent_mod && !hasPermission) {
      await p.op.alertPromise(
        'คุณไม่มีสิทธิ์แก้ไขออเดอร์ที่ถูกล็อค',
        `ออเดอร์ #${data.y} ถูกล็อคอยู่ กรุณาเลือกเฉพาะออเดอร์ที่ไม่ถูกล็อคเพื่อดำเนินการ`
      )
      this.inProcess = false
      return
    }

    if (_.includes(CONS.ORDER_CANCELABLE_STATES, data.o.toString())) {
      await p.op.alertPromise('ไม่สามารถแก้ไขรูปแบบจัดส่งได้', 'ออเดอร์นี้ถูกยกเลิกแล้ว ไม่สามารถแก้ไขรูปแบบจัดส่งได้')
      this.inProcess = false
      return
    }

    await util.setStatePromise(this, { isLoading: true })

    if (!_.isFunction(onRequestEditShippingType)) {
      this.inProcess = false
      return
    }

    await onRequestEditShippingType(this.props.data.id, newShippingTypeId)

    // try {
    //   const store_id = selectedStore.get('id')
    //   const order_id = data.id

    //   const res = await api.editOrderShippingType({
    //     store_id,
    //     order_ids: [order_id],
    //     new_shipping_type_id: newShippingTypeId,
    //     // new_shipping_type_id: 9999,
    //   })

    //   // console.log('OrderListItem._editShippingType:: res => ', res)

    //   if ('orders' in res) {
    //     const foundOrder = res.orders.find((o) => o.order_id === order_id)
    //     // console.log('OrderListItem._editShippingType:: foundOrder => ', foundOrder)

    //     if (foundOrder && _.isObject(foundOrder.updated_order)) {
    //       this._onDataChange({ ...data, ...foundOrder.updated_order })
    //       await util.delay(100)
    //     }

    //     if (foundOrder && 'errorMessage' in foundOrder) {
    //       // console.log('OrderListItem._editShippingType:: foundOrder.errorMessage => ', foundOrder.errorMessage)
    //       this._onItemErrorUpdate(order_id, foundOrder.errorMessage)
    //       await util.delay(100)
    //     }

    //     // Worst-case: กรณีหลังบ้านลืมส่ง shipping_type_id กลับมา
    //     if (foundOrder && _.isNil(foundOrder.updated_order) && _.isNil(foundOrder.errorMessage)) {
    //       this._onDataChange({ ...data, y: newShippingTypeId })
    //       await util.delay(100)
    //     }
    //   }
    //   // else {
    //   //   this._onDataChange({ ...data, y: newShippingTypeId })
    //   //   await util.delay(100)
    //   // }
    // } catch (err) {
    //   console.log('OrderListItem._editShippingType:: err => ', err)
    // }

    await util.setStatePromise(this, { isLoading: false })
    this.inProcess = false
  }

  // _onPressLeftCol() {
  //   const { data, onPressLeftCol } = this.props
  //   // util.log(this.props.data, this.props.data.item.toJS())
  //   if (_.isFunction(onPressLeftCol)) {
  //     onPressLeftCol(data.item.get('id'))
  //   }
  // }

  // _onPressRightCol() {
  //   const { data, onPressRightCol } = this.props
  //   // util.log(this.props.data, this.props.data.item.toJS())
  //   onPressRightCol(data.item.get('id'))
  // }

  // _isModeSelectPayment() {
  //   return CONS.ORDERS_VIEW_MODE_PAYMENT === this.props.mode
  // }

  _getDisplayData = (i: IOrderListItem): IBaseOrderListItemForDisplayProps => {
    const d = {} as IBaseOrderListItemForDisplayProps
    const UNKNOWN_NAME = '-'
    const orderType = i.type
    const partnerName = i.pn || UNKNOWN_NAME
    let receiverName = i.rn || UNKNOWN_NAME

    // limit the length of receivername if too long
    receiverName = receiverName.trim()
    // const receiverNameDisplayLen = p.op.isWeb() ? 12 : 30
    // if (receiverName && receiverName.length > receiverNameDisplayLen) {
    //   receiverName = `${receiverName.substr(0, receiverNameDisplayLen)}..`
    // }

    const RECEIVER_PREFIX = 'ลูกค้า'
    let SELLER_ME = 'ฉัน'
    let isCreatedByHelper = false
    const { selectedStore } = this.props
    const hasMkpChannel = i.m
    // console.log('hasMkpChannel => ', hasMkpChannel)
    const helper = i.h ? i.h : null
    const helpers = Immutable.isMap(selectedStore) && selectedStore.has('helpers') ? selectedStore.get('helpers') : null
    if (!_.isNil(helpers) && !_.isNil(helper)) {
      helpers.map((h) => {
        if (h.get('pid') === helper) {
          isCreatedByHelper = true
          if (Immutable.isMap(h) && h.has('n')) {
            SELLER_ME = `ผู้ช่วย [${h.get('n')}]`
          } else {
            SELLER_ME = `ผู้ช่วย`
          }
        }
      })
    }
    d.orderId = i.id
    switch (orderType) {
      case 1:
        d.receiverName = partnerName
        d.receiverNameLabel = RECEIVER_PREFIX
        if (isCreatedByHelper) {
          d.partnerName = SELLER_ME
          // d.partnerNameLabel = 'ผู้ขาย'
          d.partnerNameLabel = 'สร้างโดย'
        } else {
          // ซ่อนเมื่อผู้ขายเป็นฉัน: https://app.clickup.com/t/86cy8gnzz
          d.partnerName = null
          d.partnerNameLabel = null
        }
        break
      case 2:
        d.receiverName = receiverName
        d.receiverNameLabel = RECEIVER_PREFIX
        d.partnerName = `${partnerName}`
        d.partnerNameLabel = 'ผู้ซื้อ(ตัวแทน)'
        break
      case 3:
        d.receiverName = receiverName
        d.receiverNameLabel = RECEIVER_PREFIX
        d.partnerName = `${partnerName}`
        d.partnerNameLabel = 'ผู้ขาย'
        break
      default:
        break
    }

    if (i.x) {
      d.x = i.x
    } else {
      d.x = null
    }
    const orderState = i.o
    const paymentState = i.q
    const shippingState = i.s
    if (orderType && _.isNumber(orderState) && _.isNumber(paymentState) && _.isNumber(shippingState)) {
      d.states = {
        orderState,
        paymentState,
        shippingState,
      }
      const orderStateColor = xUtil.getOrderStateColor(orderType, orderState)
      const paymentStateColor = xUtil.getPaymentStateColor(orderType, paymentState)
      const shippingStateColor = xUtil.getShippingStateColor(orderType, shippingState)
      d.colors = {
        orderStateColor,
        paymentStateColor,
        shippingStateColor,
      }

      const orderStateOfParent = i.u
      const paymentStateOfParent = i.v
      const shippingStateOfParent = i.w
      const parentOrderType = i.pt
      if (parentOrderType) {
        d.parentOrderType = parentOrderType
      }
      if (parentOrderType && _.isNumber(orderStateOfParent) && _.isNumber(paymentStateOfParent) && _.isNumber(shippingStateOfParent)) {
        d.hasParent = true
        const orderStateOfParentColor = xUtil.getOrderStateColor(parentOrderType, orderStateOfParent)
        const paymentStateOfParentColor = xUtil.getPaymentStateColor(parentOrderType, paymentStateOfParent)
        const shippingStateOfParentColor = xUtil.getShippingStateColor(parentOrderType, shippingStateOfParent)
        d.states = {
          ...d.states,
          orderStateOfParent,
          paymentStateOfParent,
          shippingStateOfParent,
        }
        d.colors = {
          ...d.colors,
          orderStateOfParentColor,
          paymentStateOfParentColor,
          shippingStateOfParentColor,
        }
      }
    }

    const codAmt = i.f // cod_amount
    const isShipBeforePay = i.a // ship_before_pay
    const isCod = i.b // is_cod
    if (!_.isNil(codAmt)) {
      d.codAmt = xFmt.formatCurrency(codAmt)
    }
    if (!_.isNil(isCod)) {
      d.isCod = isCod
    }
    if (!_.isNil(isShipBeforePay)) {
      d.isShipBeforePay = isShipBeforePay
    }

    d.orderType = orderType
    d.expireTime = i.e || null
    const order_state_for_cancelled = i.l
    switch (order_state_for_cancelled) {
      case CONS.ORDER_STATE.CANCELLED_BY_SELLER:
        d.cancelledDesc = 'ยกเลิกโดยผู้ขาย'
        break
      case CONS.ORDER_STATE.CANCELLED_BY_BUYER:
        d.cancelledDesc = 'ยกเลิกโดยผู้ซื้อ'
        break
      case CONS.ORDER_STATE.CANCELLED_BY_SYSTEM:
        d.cancelledDesc = 'ยกเลิกโดยระบบ'
        break
      case CONS.ORDER_STATE.CANCELLED_BY_EXPIRED:
        d.cancelledDesc = 'ยกเลิก(เลยกำหนดชำระ)'
        break
    }
    d.labelOrderId = i.p ? `ซื้อ#${i.id}` : `ขาย#${i.id}`
    // d.labelOrderIdInfo = i.p ? `(เพื่อ ขาย#${i.p})` : null
    // d.labelOrderIdInfo = i.p ? `เพื่อ ขาย#${i.p}` : null
    d.labelOrderIdInfo = i.p ? `ขาย#${i.p}` : null
    d.totalAmt = xFmt.formatCurrency(i.t)
    // d.createdAgo = moment(i.c' 'YYYY-MM-DD h:mm').fromNow()
    // d.createdAgo = xUtil.formatDateForDisplay(i.c'
    d.createdAgo = time.convertServerDateTimeToReadableDateTimeText(i.c)
    const completedAt = i.d
    if (completedAt) {
      // d.completedAgo = moment(completedAt, 'YYYY-MM-DD h:mm').fromNow()
      // d.completedAgo = xUtil.formatDateForDisplay(completedAt)
      d.completedAgo = time.convertServerDateTimeToReadableDateTimeText(completedAt)
    } else {
      // printedAt is mutualy-exclusive with completedAt (Only one can be displayed)
      const printedAt = i.n
      if (printedAt) {
        // d.printedAgo = moment(printedAt, 'YYYY-MM-DD h:mm').fromNow()
        // d.printedAgo = xUtil.formatDateForDisplay(printedAt)
        d.printedAgo = time.convertServerDateTimeToReadableDateTimeText(printedAt)
      }
    }

    // HAS MKP CHANNEL
    if (!_.isNil(hasMkpChannel)) {
      d.mkpType = i.m
      d.ugId = i.ug
      d.chId = i.ch
    }
    if (i.k) {
      d.mkp_order_id = i.k
    }

    if (_.has(i, 'prevent_mod')) {
      d.prevent_mod = i.prevent_mod
    }

    if (_.has(i, 'mkp_is_self_delivery')) {
      d.mkp_is_self_delivery = i.mkp_is_self_delivery
    }

    d.isCreatedByHelper = isCreatedByHelper

    return d
  }

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  // _renderSelectionIcon() {
  //   if (this.props.isSelected) {
  //     return <XIcon family='FontAwesome' name='check-circle' variant='success' />
  //   }
  //   return <XIcon family='FontAwesome' name='circle-o' variant='success' />
  // }

  // _renderCheckerCol = () => {
  //   const { data, isSelected } = this.props
  //   // const { index } = data
  //   // const isOdd = index % 2 === 0
  //   if (!this._isAvailableLeftChecker()) {
  //     return null
  //   }
  //   // if (this._isModeSelectPayment()) {
  //   return (
  //     <View
  //       style={{
  //         // flex: p.op.isWeb() ? 1 : 0,
  //         flexBasis: 'auto',
  //         width: COL_WIDTH_CHECKER,
  //         // height: 46,
  //         height: '100%',
  //         // flexDirection: 'row',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         alignSelf: 'center',
  //         backgroundColor: isSelected ? COLORS.FORM_SUCCESS_BG : COLORS.BG_LIGHT_GREY,
  //       }}>
  //       {/* <TouchableOpacity style={s.sideBtnContainer} onPress={this._onPressLeftCol}>
  //         {this._renderSelectionIcon()}
  //       </TouchableOpacity> */}
  //     </View>
  //   )
  //   // } else {
  //   //   return null
  //   // }
  // }

  // _renderMoreInfo() {
  //   if (this._isModeSelectPayment()) {
  //     return (
  //       <TouchableOpacity style={s.sideBtnContainer} onPress={this._onPressRightCol}>
  //         <VStack style={{ width: COL_WIDTH_INFO, height: 46, justifyContent: 'center', alignItems: 'center' }}>
  //           <Icon
  //             name='md-information-circle'
  //             style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.FORM_PRIMARY }} />
  //         </VStack>
  //       </TouchableOpacity>
  //     )
  //   } else {
  //     return <View />
  //   }
  // }

  _renderOrderStateIcons = (
    d: IBaseOrderListItemForDisplayProps
    // {
    // orderType: 1 | 2 | 3
    // parentOrderType?: 1 | 2
    // states: {
    //   orderState: number
    //   paymentState: number
    //   shippingState: number
    //   orderStateOfParent?: number
    //   paymentStateOfParent?: number
    //   shippingStateOfParent?: number,
    // },
    // }
  ) => {
    // if (!d || !d.states || !_.isObject(d.states) || _.isEmpty(d.states)) {
    if (!d || !d.states || !_.isObject(d.colors) || _.isEmpty(d.colors)) {
      return null
    }
    // const { orderState, paymentState, shippingState, orderStateOfParent, paymentStateOfParent, shippingStateOfParent } = d.states

    // const orderStateColor = xUtil.getOrderStateColor(d.orderType, orderState)
    // const paymentStateColor = xUtil.getPaymentStateColor(d.orderType, paymentState)
    // const shippingStateColor = xUtil.getShippingStateColor(d.orderType, shippingState)

    // const hasParent = _.isNumber(orderStateOfParent) && _.isNumber(paymentStateOfParent) && _.isNumber(shippingStateOfParent)
    // const orderStateOfParentColor = xUtil.getOrderStateColor(d.parentOrderType, orderStateOfParent)
    // const paymentStateOfParentColor = xUtil.getPaymentStateColor(d.parentOrderType, paymentStateOfParent)
    // const shippingStateOfParentColor = xUtil.getShippingStateColor(d.parentOrderType, shippingStateOfParent)

    // return (
    //   <Row style={s.rowContainerForIcons}>
    //     {/* <XIcon family='MaterialCommunityIcons' name='file-document-outline' /> */}
    //     {this._renderOrderStateIcon(orderStateColor)}
    //     {this._renderPaymentStateIcon(paymentStateColor)}
    //     {this._renderShippingStateIcon(shippingStateColor)}
    //     {hasParent ? <XText>{'('}</XText> : null}
    //     {hasParent ? this._renderOrderStateIcon(orderStateOfParentColor) : null}
    //     {hasParent ? this._renderPaymentStateIcon(paymentStateOfParentColor) : null}
    //     {hasParent ? this._renderShippingStateIcon(shippingStateOfParentColor) : null}
    //     {hasParent ? <XText>{')'}</XText> : null}
    //   </Row>
    // )
    return (
      <HStack alignItems='center' justifyContent='space-between' space='1'>
        <HStack alignItems='center' style={{ paddingLeft: -8 }}>
          {/* <XIcon family='MaterialCommunityIcons' name='file-document-outline' /> */}
          {this._renderOrderStateIcon(d.colors.orderStateColor)}
          {this._renderPaymentStateIcon(d.colors.paymentStateColor)}
          {this._renderShippingStateIcon(d.colors.shippingStateColor)}

          {d.hasParent ? (
            <XText variant='inactive' style={[s.txtBracket, { paddingLeft: 8 }]}>
              (
            </XText>
          ) : null}
          {d.hasParent ? this._renderOrderStateIcon(d.colors.orderStateOfParentColor) : null}
          {d.hasParent ? this._renderPaymentStateIcon(d.colors.paymentStateOfParentColor) : null}
          {d.hasParent ? this._renderShippingStateIcon(d.colors.shippingStateOfParentColor) : null}
          {d.hasParent ? (
            <XText variant='inactive' style={s.txtBracket}>
              )
            </XText>
          ) : null}
        </HStack>

        <HStack alignItems='center' justifyContent='flex-end' space='1'>
          {/* {d.isCod && d.codAmt ? <XText style={s.listItemValueCODTotal}>{`[COD ${d.codAmt}]`}</XText> : null} */}

          {d.isShipBeforePay ? (
            <XIcon family='MaterialCommunityIcons' name='truck-delivery' style={StyleSheet.flatten(s.iconShipBeforePay)} />
          ) : null}
        </HStack>
      </HStack>
    )
  }

  _renderHasMkpChannel = (d: IBaseOrderListItemForDisplayProps) => {
    if (d.mkpType) {
      return (
        <HStack w='full' p='1' space='1' style={{ backgroundColor: COLORS.BLUE_TAB }} flexWrap='warp'>
          <HStack w='150px' space='1' flexWrap='warp'>
            <MkpLogo mkpId={d.mkpType} style={{ width: 20, height: 20, paddingTop: 1 }} />

            <VStack flex={1} flexWrap='wrap'>
              <XText w='full' numberOfLines={2} ellipsizeMode='middle'>{`${xUtil.getMKPChannelName(d.chId)}`}</XText>
            </VStack>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText>{d.mkp_order_id}</XText>
          </HStack>
        </HStack>
      )
    }
    return null
  }

  _renderHasErpChannel = (data: IOrderListItem) => {
    if (data.erp_docs) {
      const erpDosc = data.erp_docs
      const erpName = xUtil.getErpChannelName(erpDosc[0].erp_ch_id)
      // console.log('erpDosc => ', erpDosc)
      const erpLabel = xUtil.getErpCodeLabel(erpDosc)
      return (
        <HStack w='full' justifyContent='space-between' p='1' style={{ backgroundColor: COLORS.BLUE_TAB }}>
          <HStack style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <ErpLogo erpSystemId={erpDosc[0].erp_system_id} style={{ width: 20, height: 20, paddingTop: 1 }} />
            <XText ml='2' numberOfLines={1}>
              {erpName}
            </XText>
          </HStack>
          <VStack flex={1} alignItems='flex-end' justifyContent='flex-end'>
            <XText textAlign='right'>{erpLabel}</XText>
            {/* {erpDosc.map((doc, idx) => {
              let code = doc.erp_doc_code
              if (idx === 2 && erpDosc.length > 3) {
                code = `${doc.erp_doc_code}, (${erpDosc.length - 3})`
              }
              return <XText key={`erp_doc_code_${idx}`}>{code}</XText>
            })} */}
            {/* <XText>IV-20240100008</XText>
            <XText>BL-20240100001</XText>
            <XText>RT-2024010005, (2)</XText> */}
          </VStack>
        </HStack>
      )
    }
    return null
  }

  _renderOrderStateIcon = (color: string) => (
    <XIcon size='xs' family='MaterialCommunityIcons' name='file-document' style={StyleSheet.flatten([s.iconOrder, { color }])} />
  )

  _renderPaymentStateIcon = (color: string) => (
    <XIcon size='xs' family='Ionicons' name='cash' style={StyleSheet.flatten([s.iconPayment, { color }])} />
  )

  _renderShippingStateIcon = (color: string) => (
    <XIcon size='xs' family='MaterialIcons' name='local-shipping' style={StyleSheet.flatten([s.iconShipping, { color }])} />
  )

  _renderPreventModStateIcon = () => (
    <XIcon
      size='xs'
      family='MaterialIcons'
      name='lock'
      style={StyleSheet.flatten([s.iconShipping, { color: COLORS.FORM_SUCCESS, marginLeft: 12 }])}
    />
  )

  _onPressToggleCheck = () => {
    const { data, onCheckedPress } = this.props
    onCheckedPress(data.id)
  }

  _resetErrorMessage = () => {
    const { resetErrorMessage, data } = this.props

    if (_.isFunction(resetErrorMessage)) {
      resetErrorMessage(data.id)
    }
  }

  _renderErrorMessage = () => {
    const { errorMessage } = this.props

    if (_.isObject(errorMessage) && 'title' in errorMessage && 'message' in errorMessage) {
      const errorText = `${errorMessage.title} ${errorMessage.message}`.trim()

      return (
        <HStack w='full' px='1.5' py='1' space='1' bg='red.500' alignItems='center' flexWrap='warp'>
          <HStack flex={1} alignItems='center' space='1'>
            <XText variant='activeDark'>{errorText}</XText>
          </HStack>

          <HStack w='34px' justifyContent='flex-end'>
            <TouchableOpacity onPress={this._resetErrorMessage}>
              <XIcon name='close-circle' color='white' />
            </TouchableOpacity>
          </HStack>
        </HStack>
      )
    }

    return null
  }

  // _callBackForXShipping = () => {
  //   const { setShouldFetchOrderSummary } = this.props
  //   if (_.isFunction(setShouldFetchOrderSummary)) {
  //     setShouldFetchOrderSummary(true)
  //   }
  // }

  // _isAvailableLeftChecker = () => _.isFunction(this.props.onPressLeftCol)

  render() {
    // const { updateTackingCode, createTackingCode, cancelTackingCode, selectedStore, autoRequestTracking } = this.props
    const { isLoading } = this.state
    const { selectedStore, data, index, isCheckable, checked, visibilitySetting = {}, errorMessage } = this.props

    const {
      isCreatedAtShow = true,
      isSellerNameShow = true,
      isReceiverNameShow = true,
      isHelperSellerNameShow = true,
      isShippingTypeShow = false,
      isPpInfoShow = true,
      isReceiverAddressShow = false,
    } = visibilitySetting

    xUtil.logRender(this)
    // xUtil.log(this.props.data.item.toJS())
    // const { item } = this.props.data
    // console.log(`item `, item.toJS())
    // const d = this._getDisplayData(this.props.data.item)
    const d = this._getDisplayData(data)
    // console.log('D', d)
    // return (<View style={{backgroundColor: 'blue', width: '100%'}}><XText>sdfsdf</XText></View>)
    // { id, c, e, n, t } // c: createdAt, e: expiredAt, n: name, t: total amount
    // console.log('OrderListItem.Render: ' + this.props.orderId)
    // 12 ก.ค. 60, 13:01:43
    /*
                <Row style={{ borderBottomWidth: 0 }}>
              <View style={s.listItemLabelCol}>
                <XText style={s.listItemLabelText}>{p.op.t('Order.customerName')}</XText>
              </View>
              <View style={s.listItemValueCol}>
                <XText numberOfLines={1} ellipsizeMode='tail' style={[s.listItemValueText, s.alignRight] }>{item.get('n')}</XText>
              </View>
            </Row>
             <Row>
              <View style={s.listItemLabelCol}>
                <XText style={s.listItemLabelText}>{p.op.t('Order.toBeCancelledWithIn')}</XText>
              </View>
              <View style={s.listItemValueCol}>
                <XText style={[s.listItemValueText, s.alignRight] }>
                  <ExpireTimer ref='ExpireTimer' test={this.props.test} serverTimeOffset={60} endTime={item.get('e')}/>
               </XText>
              </View>
            </Row>
      <Row>
               <View style={[s.listItemLabelVStack, { width: '100%' }]}>
                <XText style={[s.listItemValueText, s.alignRight, { color: 'grey' }]}>{p.op.t('Order.createdAt')} {moment(item.get('c'), 'YYYY-MM-DD h:mm:ss').fromNow()}</XText>
              </View>
            </Row>
            */

    let moreTextInfoLabel = null
    let moreTextInfoComp = null
    if (d.expireTime) {
      moreTextInfoComp = (
        <XText
          fontSize='xs'
          // variant='primary'
          // variant='inactive'
        >
          {/* <ExpireTimer ref='ExpireTimer' serverTimeOffset={60} endTime={d.expireTime} /> */}
          {`${time.convertServerDateTimeToReadableDateTimeText(d.expireTime)}`}
        </XText>
      )
      moreTextInfoLabel = 'หมดอายุเมื่อ'
    } else if (d.cancelledDesc) {
      // l === 1 if true
      moreTextInfoComp = (
        <XText fontSize='xs' style={s.listItemValueTextDanger}>
          {d.cancelledDesc}
        </XText>
      )
      moreTextInfoLabel = ''
    } else if (d.completedAgo) {
      moreTextInfoComp = (
        <XText fontSize='xs' style={s.listItemValueTextTotal}>
          {d.completedAgo}
        </XText>
      )
      moreTextInfoLabel = 'เสร็จสิ้นเมื่อ'
    } else if (d.printedAgo) {
      moreTextInfoComp = (
        <XText fontSize='xs' style={s.listItemValueTextTotal}>
          {d.printedAgo}
        </XText>
      )
      moreTextInfoLabel = 'พิมพ์เมื่อ'
    }
    // xUtil.log('OrderListItem....')
    // xUtil.log(item.toJS())

    // const colRightPaddingRightStyle = { paddingRight: this._isModeSelectPayment() ? 3 : 6 }

    // if (Platform.OS === 'web') {
    //   // เพราะว่า web จะมี vertical scroll bar เสมอ
    //   colRightPaddingRightStyle.paddingRight = this._isModeSelectPayment() ? 12 : 20
    // }

    // const screenWidth = this._getScreenWidth()
    // const webScreenWidthWithPadding = this._getScreenWidth() - xCONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH
    // const mainScreenWidthStyle = { width: p.op.isWeb() ? webScreenWidthWithPadding : screenWidth }
    // let mainContentWidth = p.op.isWeb() ? webScreenWidthWithPadding : screenWidth
    // if (p.op.isWeb()) {
    //   mainContentWidth = webScreenWidthWithPadding
    // } else {
    //   mainContentWidth = screenWidth
    // }

    // if has LeftColumn checker
    // if (this._isAvailableLeftChecker()) {
    //   mainContentWidth -= COL_WIDTH_CHECKER
    // }

    // style={StyleSheet.flatten([
    //   s.landing,
    //   s.listItemContainer,
    //   {
    //     backgroundColor: d.orderType === xCONS.ORDER_TYPE_3 ? '#ffeedc' : 'white',
    //     // paddingRight: p.op.isWeb() ? xCONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH : undefined,
    //     overflow: 'hidden',
    //     // width: screenWidth,
    //     flexDirection: 'row',
    //   },
    //   mainScreenWidthStyle,
    //   // { backgroundColor: item.get('type') === 3 ? '#DDDDDD' : 'white' },
    //   // { backgroundColor: this.props.data.index % 2 === 0 ? 'white' : '#e9e9e9' },
    // ])}

    const isSellOrder = d.orderType === CONS.ORDER_TYPE_3

    return (
      <TouchableOpacity onPress={this._onPress}>
        <VStack
          w='full'
          // bg={isSellOrder ? '#ffeedc' : undefined}
          _light={{ bg: isSellOrder ? '#ffeedc' : NB.C.L.BG.CLEAR }}
          _dark={{ bg: isSellOrder ? '#ffeedc' : NB.C.D.BG.CLEAR }}
          borderWidth='1'
          borderTopColor='gray.200'
          // borderLeftWidth={checked ? '4px' : '1'}
          borderLeftWidth='4'
          borderLeftColor={checked ? 'primary.500' : 'gray.200'}
          borderBottomColor='gray.400'
          borderRightColor='gray.400'
          borderRadius='lg'
          flexWrap='wrap'
          // pt='1'
          // onPress={this._onPress}
        >
          {/* <HStack w='full' pt='1' alignItems='center' justifyContent='center'>
            <XText fontSize='xs' variant='inactive'>
              #{index + 1}
            </XText>
          </HStack> */}

          <HStack
            w='full'
            // pb='1'
            // px='1.5'
            flexWrap='wrap'>
            <VStack
              w='full'
              px='1.5'
              pl={isCheckable ? '44px' : '1.5'}
              py='1'
              flexWrap='wrap'
              borderLeftWidth={isCheckable ? '1' : '0'}
              borderColor='muted.200'>
              {/* {isCheckable ? (
                <Box w='1px' pr='1.5' style={{ height: '100%', borderStyle: 'dotted' }} borderLeftWidth='1' borderColor='muted.200' />
              ) : null} */}

              {/* <XText>index: {index}</XText> */}
              {this._renderErrorMessage()}

              {this._renderHasMkpChannel(d)}

              {this._renderHasErpChannel(data)}

              <HStack w='full' flexWrap='wrap' space='1'>
                <VStack pt='2px' flex={1} flexWrap='wrap'>
                  <HStack alignItems='center'>
                    <XText minW='28px' pr='1.5' fontSize='xs' variant='inactive'>
                      #{index + 1}
                    </XText>
                    <XText fontSize='sm'>{d.labelOrderId} </XText>
                    {this._renderOrderStateIcon(d.colors.orderStateColor)}
                    {this._renderPaymentStateIcon(d.colors.paymentStateColor)}
                    {this._renderShippingStateIcon(d.colors.shippingStateColor)}
                    {d.prevent_mod ? this._renderPreventModStateIcon() : null}
                    {d.mkp_is_self_delivery ? (
                      <HStack pl='1' alignItems='center'>
                        {/* <XIcon color='yellow.700' name='local-shipping' family='MaterialIcons' /> */}
                        <XText fontSize='xs' color='yellow.700'>
                          (Seller Own Fleet)
                        </XText>
                      </HStack>
                    ) : null}
                  </HStack>

                  {d.labelOrderIdInfo ? (
                    <HStack alignItems='center'>
                      <XText fontSize='xs' variant='inactive'>
                        (
                      </XText>
                      <XText fontSize='xs' variant='inactive'>
                        {d.labelOrderIdInfo}
                      </XText>
                      {d.hasParent ? this._renderOrderStateIcon(d.colors.orderStateOfParentColor) : null}
                      {d.hasParent ? this._renderPaymentStateIcon(d.colors.paymentStateOfParentColor) : null}
                      {d.hasParent ? this._renderShippingStateIcon(d.colors.shippingStateOfParentColor) : null}
                      <XText variant='inactive'>)</XText>
                    </HStack>
                  ) : null}

                  {d.isShipBeforePay ? (
                    <XIcon
                      size='xs'
                      family='MaterialCommunityIcons'
                      name='truck-delivery'
                      style={StyleSheet.flatten(s.iconShipBeforePay)}
                    />
                  ) : null}
                </VStack>

                <VStack minW='16' alignItems='flex-end' justifyContent='space-between'>
                  <XText fontSize='md' bold>
                    {d.cancelledDesc ? null : d.totalAmt}
                  </XText>
                  {/* {d.isCod && d.codAmt ? <XText style={s.listItemValueCODTotal}>{`[COD ${d.codAmt}]`}</XText> : null} */}
                  {d.isCod && d.codAmt ? <XText fontSize='md' bold style={s.listItemValueCODTotal}>{`COD ${d.codAmt}`}</XText> : null}
                  {/* {d.isShipBeforePay ? (
                  <XIcon family='MaterialCommunityIcons' name='truck-delivery' style={StyleSheet.flatten(s.iconShipBeforePay)} />
                ) : null} */}
                </VStack>
              </HStack>

              <Divider my='0.5' />

              {/* {this._renderOrderStateIcons(d)} */}

              {/* {this._renderCheckerCol()} */}

              {((isSellerNameShow && !d.isCreatedByHelper) || (isHelperSellerNameShow && d.isCreatedByHelper)) &&
              _.isString(d.partnerName) &&
              _.isString(d.partnerNameLabel) ? (
                <HStack w='full'>
                  <XText w='100px' fontSize='xs' variant='inactive'>
                    {d.partnerNameLabel}
                  </XText>

                  <XText flex={1} textAlign='right' fontSize='xs' numberOfLines={2}>
                    {d.partnerName}
                  </XText>
                </HStack>
              ) : null}

              {isReceiverNameShow && _.isString(d.receiverName) && _.isString(d.receiverNameLabel) ? (
              <HStack w='full'>
                <XText w='100px' fontSize='xs' variant='inactive'>
                  {d.receiverNameLabel}
                </XText>

                <XText flex={1} textAlign='right' fontSize='xs' numberOfLines={2}>
                  {d.receiverName}
                </XText>
              </HStack>
            ) : null}

              {isCreatedAtShow ? (
                <HStack w='full' justifyContent='space-between'>
                  <XText fontSize='xs' variant='inactive'>
                    {p.op.t('Order.createdAt')}
                  </XText>
                  <XText fontSize='xs' numberOfLines={2}>
                    {d.createdAgo}
                  </XText>
                </HStack>
              ) : null}

              {moreTextInfoComp ? (
                <HStack w='full' justifyContent='space-between'>
                  <XText fontSize='xs' variant='inactive'>
                    {moreTextInfoLabel}
                  </XText>
                  {moreTextInfoComp}
                </HStack>
              ) : null}
              {/* {this._renderMoreInfo()} */}

              {d.x ? (
                <VStack w='full'>
                  <Divider my='1' />
                  <HStack w='full'>
                    <XShipping
                      data={fromJS(d.x)}
                      orderId={d.orderId}
                      storeId={selectedStore.get('id')}
                      limitRes={1}
                      // callBack={() => this._callBackForXShipping()}
                      // @ts-ignore
                      orderStatus={data.o}
                      onDataChange={this._onXShippingDataChange}
                    />
                  </HStack>
                </VStack>
              ) : null}

              <VStack w='full' flexWrap='wrap'>
                {isShippingTypeShow ? (
                  <HStack
                    w='full'
                    pt='1.5'
                    alignItems='center'
                    // px='2' py='1' borderWidth='1' borderColor='gray.300' borderRadius='lg'
                  >
                    <HStack w='100px' alignItems='center' space='1.5'>
                      <XText w='100px' fontSize='xs' variant='inactive'>
                        รูปแบบจัดส่ง
                      </XText>
                    </HStack>

                    <HStack flex={1} alignItems='center' justifyContent='flex-end' flexWrap='wrap'>
                      <ShippingTypePicker
                        // disabled={MODE !== 'dev'}
                        title='เปลี่ยนรูปแบบจัดส่ง'
                        selectedShippingTypeId={data.y}
                        onSelect={(item) => {
                          // p.op.alert('To be implemented', JSON.stringify(item))
                          // this.props.onShippingTypeChange({
                          //   store_id: this.props.selectedStore.get('id'),
                          //   order_id: data.id,
                          //   shipping_type_id: item.value,
                          //   successCallback: () => {
                          //     this._onDataChange({ ...this.props.data, y: item.value })
                          //   },
                          // })
                          this._editShippingType(item.value)
                        }}
                        renderButton={({ selectedItem, onPress, disabled }) => (
                          <TouchableOpacity disabled={disabled} onPress={onPress}>
                            <HStack
                              minW={p.op.isWeb() ? '120px' : '200px'}
                              maxW={`${MAX_SHIPPING_BUTTON_WIDTH}px`}
                              px={disabled ? '0' : '2'}
                              py='1'
                              alignItems='center'
                              space='1'
                              borderWidth={disabled ? '0' : '1'}
                              bg={disabled ? undefined : 'muted.50'}
                              borderColor='primary.500'
                              borderRadius='2xl'>
                              <HStack flex={1} pt='0.5' flexWrap='wrap'>
                                <XText flex={1} textAlign={disabled ? 'right' : 'left'} fontSize='xs' variant='active'>
                                  {selectedItem.label}
                                </XText>
                              </HStack>
                              {disabled ? null : <ForwardIcon />}
                            </HStack>
                          </TouchableOpacity>
                        )}
                      />
                    </HStack>
                  </HStack>
                ) : null}

                {isReceiverAddressShow && _.isObject(data.receiver) && !_.isEmpty(data.receiver) ? (
                  <VStack w='full' pt='1'>
                    <XText fontSize='xs' variant='inactive'>
                      ที่อยู่ผู้รับ
                    </XText>

                    <XText fontSize='xs'>{xUtil.convertAddressToInlineAddress(data.receiver)}</XText>
                  </VStack>
                ) : null}

                {isPpInfoShow && data.products && data.products.length > 0 ? (
                  <VStack w='full' pt='1.5'>
                    {/* <HStack w='full' borderTopWidth='1' borderTopColor='muted.400' alignItems='center'>
                    <XImageIcon size='md' variant='inactive' source={require('../../img/tb/tb_my0.png')} />
                    <XText fontSize='xs' variant='inactive'>
                      ร้านฉัน ABC
                    </XText>
                  </HStack> */}

                    {data.products.map((opi, idx) => (
                      <HStack
                        key={`OrderProductItem_${idx}_${opi.pp_id}`}
                        w='full'
                        py='1'
                        borderTopWidth='1'
                        borderTopColor='muted.200'
                        space='1'>
                        <HStack flex={1} space='1'>
                          <VStack w='42px' space='0.5'>
                            <HStack
                              w='42px'
                              h='42px'
                              alignItems='center'
                              justifyContent='center'
                              borderWidth='1'
                              borderTopColor='gray.100'
                              borderLeftColor='gray.100'
                              borderRightColor='gray.300'
                              borderBottomColor='gray.300'
                              borderRadius='lg'
                              flexWrap='wrap'
                              overflow='hidden'>
                              <XImage source={{ uri: opi.thumbnail_url }} w='42px' h='42px' />
                            </HStack>

                            {/* <XText fontSize='xs' variant='inactive'>
                              #{idx + 1}
                            </XText> */}
                          </VStack>

                          <HStack flex={1} flexWrap='wrap'>
                            <XText minW='20px' pr='1.5' fontSize='xs' variant='inactive'>
                              #{idx + 1}
                            </XText>

                            <VStack flex={1} flexWrap='wrap'>
                              <XText w='full' fontSize='xs' variant='active' numberOfLines={1}>
                                {opi.pt_name}
                              </XText>
                              <XText w='full' fontSize='xs' variant='inactive' numberOfLines={1}>
                                {opi.pp_name}
                              </XText>
                            </VStack>

                            <HStack w='full' space='1.5'>
                              <XText fontSize='xs' variant='inactive'>
                                <XText
                                  fontSize='xs'
                                  variant={
                                    _.isString(opi.price_after_discount) &&
                                    opi.price_after_discount.length > 0 &&
                                    opi.price !== opi.price_after_discount
                                      ? 'inactive'
                                      : 'active'
                                  }
                                  strikeThrough={
                                    _.isString(opi.price_after_discount) &&
                                    opi.price_after_discount.length > 0 &&
                                    opi.price !== opi.price_after_discount
                                  }>
                                  {xFmt.formatCurrency(opi.price)}
                                </XText>
                                {_.isString(opi.price_after_discount) &&
                                opi.price_after_discount.length > 0 &&
                                opi.price !== opi.price_after_discount ? (
                                  <XText pl='1' fontSize='xs' variant='active'>
                                    {xFmt.formatCurrency(opi.price_after_discount)}
                                  </XText>
                                ) : null}
                              </XText>
                            </HStack>
                          </HStack>
                        </HStack>

                        <HStack w='40px' justifyContent='flex-end'>
                          <XText fontSize='xs' textAlign='right'>
                            x
                            <XText fontSize='sm' textAlign='right'>
                              {opi.qty}
                            </XText>
                          </XText>
                        </HStack>
                      </HStack>
                    ))}

                    {/* <HStack w='full' alignItems='center'>
                    <XImageIcon size='md' variant='inactive' source={require('../../img/tb/tb_seller0.png')} />
                    <XText fontSize='xs' variant='inactive'>
                      ร้านขายส่ง: XYZ
                    </XText>
                  </HStack> */}
                  </VStack>
                ) : null}
              </VStack>
            </VStack>

            {isCheckable ? (
              <TouchableOpacity style={{ width: 58, position: 'absolute', left: 0, top: 0, bottom: 0 }} onPress={this._onPressToggleCheck}>
                <HStack flex={1} pt='1' pl='3' borderTopLeftRadius='lg' borderBottomLeftRadius='lg'>
                  <XIcon
                    // onPress={this._onPressToggleCheck}
                    // family='FontAwesome'
                    // name={checked ? 'check-circle' : 'circle-o'}
                    family={checked ? 'Ionicons' : 'FontAwesome'}
                    name={checked ? 'checkmark-circle' : 'circle-o'}
                    // iconVariant={checked ? 'success' : 'inactive'}
                    variant='primary'
                  />
                </HStack>
              </TouchableOpacity>
            ) : null}
          </HStack>

          {isLoading ? (
            <Box position='absolute' left='0' right='0' top='0' bottom='0' bg='rgba(0,0,0,0.1)' justifyContent='center' alignItems='center'>
              <XSpinner />
            </Box>
          ) : null}
        </VStack>
      </TouchableOpacity>
    )
  }

  // injectContainerStyle = () => ({})
}

const s = StyleSheet.create({
  rowContainer: {
    flex: 1,
    paddingTop: 0,
    paddingBottom: 1,
  },
  rowContainerForIcons: {
    flex: 1,
    paddingTop: 0,
    // paddingBottom: 1,
    // borderWidth: 1,
    alignItems: 'center',
  },
  sideBtnContainer: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemContainer: {
    flex: 1,
    paddingTop: 6,
    paddingBottom: 6,
    // paddingRight: 6,
    // paddingLeft: 4,
    borderBottomWidth: 1,
    borderColor: COLORS.BLACK,
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
    // backgroundColor: 'transparent',
    paddingRight: 4,
  },
  listItemLabelCol: {
    width: '45%',
  },
  listItemLabelCol2: {
    width: '25%',
  },
  listItemValueCol2: {
    width: '45%',
  },
  listItemValueCol: {
    width: '55%',
    // flexWrap: 'wrap',
    // backgroundColor: 'red'
  },
  listItemLabelOrderIdActive: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'left',
    paddingTop: 1,
  },
  listItemLabelOrderIdInActive: {
    // fontSize: 12,
    fontSize: p.op.isWeb() ? STYLES.FONT_SIZE_SMALLER : STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'left',
    paddingTop: 1,
  },
  listItemLabelText: {
    // backgroundColor: 'green',
    // color: 'grey',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    // paddingTop: 2,
    fontSize: 12,
    color: COLORS.TEXT_INACTIVE,
    // width: '100%',
  },
  listItemLabelOrderIdCol: {
    width: '55%',
  },
  listItemValueTextOrderId: {
    // fontWeight: 'bold'
    paddingTop: 2,
    // paddingRight: 8,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  listItemValueTextTotal: {
    color: COLORS.FORM_SUCCESS,
    // fontWeight: 'bold',
  },
  listItemValueCODTotal: {
    color: COLORS.BRAND_Primary,
  },
  listItemValueText: {
    color: COLORS.TEXT_ACTIVE,
    flex: 1,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  listItemValueTextDanger: {
    color: COLORS.BRAND_Danger,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flatList: {
    flex: 1,
    width: '100%',
  },
  landing: { alignItems: 'center', justifyContent: 'center', flex: 1 },
  line: { backgroundColor: '#ccc', height: StyleSheet.hairlineWidth },

  iconShipBeforePay: {
    // fontSize: STYLES.FONT_ICON_SMALLER - 2,
    color: COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther,
    // height: 14,
    // width: 14,
    // paddingLeft: 2,
    // paddingTop: 1,
    // textAlign: 'left',
    // marginLeft: -2,
  },
  iconOrder: {
    //   fontSize: STYLES.FONT_ICON_SMALLER - 4,
    //   height: 14,
    //   width: 11,
    //   paddingTop: 1,
    //   textAlign: 'left',
    //   marginLeft: -2,
  },
  iconPayment: {
    // fontSize: STYLES.FONT_ICON_SMALLER,
    // height: 14,
    // width: 16,
    // textAlign: 'left',
    // marginLeft: 4,
    // paddingTop: 0.5,
    // ...Platform.select({
    //   web: {
    //     marginTop: -3,
    //   },
    // }),
  },
  iconShipping: {
    // fontSize: STYLES.FONT_ICON_SMALLER,
    // height: 14,
    // width: 16,
    // textAlign: 'left',
    // marginLeft: 3,
  },
  txtBracket: {
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    // color: COLORS.TEXT_INACTIVE,
    // // paddingBottom: 1,
  },
})

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    sellerStores: getSellerStores(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
    // onShippingTypeChange: bindActionCreators(OrderState.onShippingTypeChange, dispatch),
  })
)(OrderListItem) as React.FC<
  Omit<IOrderListItemProps, 'selectedStore' | 'sellerStores' | 'updateSelectedStoreByKey' | 'onShippingTypeChange'>
>
