import { connect } from 'react-redux'

import { getState, getDispatch } from 'x/modules/orderlist/StoreOrderSingleTabListViewConnect'
import { IOrderListItem, IOrderListViewProps, IOrderListViewState, PrintOptionKey } from 'x/index'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as acl from 'x/utils/acl'
import CONS from 'x/config/constants'
import * as NavActions from 'x/utils/navigation'

import BaseUIOrderListView from './BaseUIOrderListView'

class PrintDocumentOrderListView extends BaseUIOrderListView<IOrderListViewProps, IOrderListViewState> {
  static displayName = 'PrintDocumentOrderListView'

  onPressOrderListItem = (od: IOrderListItem) => {
    const { navigation } = this.props

    // this.toggleCheckOrderListItem(od.id)
    navigation.dispatch(
      NavActions.navToOrderDetail({
        order_id: od.id,
        store_id: this.getStoreId(),
      })
    )
  }

  getOverridePreSetupInitialState = (): Partial<IOrderListViewState> => {
    const opMenuIndex = this.operationMenus.findIndex((om) => 'key' in om && om.key === 'print')

    return {
      isCheckable: true,
      // availableTabKeys: ['myTasks_ship'],
      // availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready', 'myTasks_ship_all'],
      availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready'],
      displayMode: 'page',
      displayPageLimit: 30,
      selectedMultipleOperationIndex: opMenuIndex,
    }
  }

  hasPermissionToPrintDocumentsWithAlert = () => {
    const hasPermissionToPrint = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
    if (!hasPermissionToPrint) {
      p.op.alert('ไม่มีสิทธิ์ในการพิมพ์', 'กรุณาติดต่อเจ้าของร้านหากคุณต้องการพิมพ์เอกสารเหล่านี้')
    }
    return acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
  }

  _onPressPrintDocuments = async (printKey: PrintOptionKey) => {
    const hasPermissionToPrintDocumentsWithAlert = this.hasPermissionToPrintDocumentsWithAlert()
    if (!hasPermissionToPrintDocumentsWithAlert) {
      return
    }

    if (this.isSubmitting) {
      return
    }
    this.isSubmitting = true

    await util.setStatePromise(this, { isSubmitting: true })

    await util.submitPrintingDocuments({
      printKey,
      store_id: this.getStoreId(),
      order_ids: this.getCheckedOrderIds(),
      handleOpenPaperang: p.op.isWeb() ? null : (url) => this._onOpenPaperangFromUrl(url, printKey),
    })

    await util.setStatePromise(this, { isSubmitting: false })

    this.isSubmitting = false
  }

  _onOpenPaperangFromUrl = async (url: string, key: string): Promise<void> => {
    const { selectedStore } = this.props
    let paperangAutoSave = util.checkPaperangAutoSaveImg(selectedStore)

    if (key === 'BILL_PAPERANG') {
      paperangAutoSave = util.checkPaperangAutoSaveImgFromPrintingBill(selectedStore)
    }
    await this.props.navigation.dispatch(
      NavActions.navToPaperangView({
        imageURL: url,
        noAutoSaveImgAtHeightBelow: CONS.NO_AUTO_SAVE_IMG_AT_HEIGHT_BELOW_SHIPING_LABEL.COVER_SHEET,
        disabledAutoSave: paperangAutoSave,
      })
    )
  }

  getSubmitCheckOrdersButtonText = () => {
    const checkedOrders = this.getCheckedOrderIds()
    const checkedCount = checkedOrders.length

    if (checkedCount === 0) {
      return 'กรุณาเลือกออเดอร์'
    }

    return 'ดำเนินการ'
  }

  renderSubmitCheckOrdersButton = () => {
    const { isCheckable, isInitialized } = this.state
    if (!isCheckable || !isInitialized) {
      return null
    }

    return this.renderMultiOperationSubmitCheckOrdersButton()
  }

  // renderSubmitCheckOrdersButton = () => this.renderPrintingOptionOverlayButton()

  // renderPrintingOptionOverlayButton = () => (
  //   <PrintingOptionOverlayButton
  //     selectedStore={this.props.selectedStore}
  //     // renderButton={this._renderMultiOpFooterButton}
  //     renderButton={this._renderPrintingOptionFooterButton}
  //     onSubmit={this._onPressPrintDocuments}
  //     // disabledBill={true}
  //   />
  // )

  // _renderPrintingOptionFooterButton = (onPressGenericFooterButton: () => Promise<void>) => {
  //   const orderIds = this.getCheckedOrderIds()

  //   const checkOrderCount = orderIds.length
  //   const exceededLimit = 50
  //   // const checkOrderCount = this._getCheckedOrderCount()
  //   // const exceededLimit = this.getCheckedOrderExceededLimit()
  //   const { isSubmitting, isOrdersRefreshing: isRefreshing } = this.state
  //   const isExceeded = checkOrderCount > exceededLimit
  //   const isActive = checkOrderCount > 0
  //   // const isRefreshing = !this._getIsInited()
  //   // const btnTextColorConditionStyle = isActive && !isExceeded ? S.TEXT_ACTIVE_DARK : S.TEXT_INACTIVE
  //   // const btnTextSizeConditionStyle = isActive && !isExceeded ? S.TEXT_LARGER_2 : S.TEXT_LARGER
  //   // const btnTextCondition = isActive ? 'พิมพ์เอกสาร' : 'กรุณาเลือกออเดอร์ที่ต้องการพิมพ์ โดยแตะปุ่มกลมทางซ้าย'
  //   // const btnTextCondition = this._getMultiOpFooterButtonText(isActive)
  //   const btnTextCondition = isActive ? 'พิมพ์เอกสาร' : 'กรุณาเลือกออเดอร์ที่ต้องการพิมพ์ โดยแตะปุ่มกลมทางซ้าย'
  //   return (
  //     <HStack w='full' p='1' flexWrap='wrap'>
  //       <XButton
  //         w='full'
  //         minH='9'
  //         isDisabled={!isActive || isExceeded || isSubmitting || isRefreshing}
  //         disabled={!isActive || isExceeded || isSubmitting || isRefreshing}
  //         isLoading={isSubmitting}
  //         onPress={onPressGenericFooterButton}>
  //         {!isExceeded && !isSubmitting && !isRefreshing ? btnTextCondition : null}
  //         {isExceeded && !isSubmitting && !isRefreshing ? `กรุณาเลือกดำเนินงานไม่เกิน ${exceededLimit} ออเดอร์ต่อครั้ง` : null}
  //       </XButton>
  //     </HStack>
  //   )
  // }

  _mockOnPressOrderListItem = (od: IOrderListItem) => {
    p.op.alert('_mockOnPressOrderListItem', JSON.stringify(od))
  }

  _mockOnPressSubmitCheckedOrders = (orderIds: number[]) => {
    p.op.alert('_mockOnPressSubmitCheckedOrders', JSON.stringify(orderIds))
  }
}

// export default OrderListView

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(PrintDocumentOrderListView)
