import React from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dayjs from 'dayjs'

import { getSelectedStore } from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'
import {
  IProductListFilterManageViewProps,
  IProductListFilterManageViewState,
  IProductListFilterPreference,
  IProductListFilterTemplate,
} from 'x/types'
import * as util from 'x/utils/util'
import * as api from 'x/utils/api'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as NavActions from 'x/utils/navigation'

import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import XButton from 'xui/components/XButton'
import XIconButton from 'xui/components/XIconButton'
import Box from 'xui/components/Box'
import { diff } from 'deep-object-diff'
import XOverlay from 'xui/components/XOverlay'
import XColorPalette from 'xui/components/XColorPalette'

class ProductListFilterManageView extends React.Component<IProductListFilterManageViewProps, IProductListFilterManageViewState> {
  static displayName = 'ProductListFilterManageView'

  inProcess?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,

      editingPref: {
        templates: [
          // {
          //   id: '00001',
          //   name: 'กรอง 00001',
          //   color: FILTER_COLORS.blue,
          //   createdAt: '2022-11-01 00:00:00',
          //   updatedAt: '2022-11-01 00:00:00',
          // },
          // {
          //   id: '00002',
          //   name: 'กรอง 00002',
          //   color: FILTER_COLORS.brown,
          //   createdAt: '2022-11-01 00:00:00',
          //   updatedAt: '2022-11-01 00:00:00',
          // },
          // {
          //   id: '00003',
          //   name: 'กรอง 00003',
          //   color: FILTER_COLORS.darkgreen,
          //   createdAt: '2022-11-01 00:00:00',
          //   updatedAt: '2022-11-01 00:00:00',
          // },
          // {
          //   id: '00004',
          //   name: 'กรอง 00004',
          //   color: FILTER_COLORS.darkorchid,
          //   createdAt: '2022-11-01 00:00:00',
          //   updatedAt: '2022-11-01 00:00:00',
          // },
          // {
          //   id: '00005',
          //   name: 'กรอง 00005',
          //   color: FILTER_COLORS.maroon,
          //   createdAt: '2022-11-01 00:00:00',
          //   updatedAt: '2022-11-01 00:00:00',
          // },
        ],
        // dashboardItems: [],
        createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
        updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      },
    }
  }

  componentDidMount(): void {
    this._initialize()
  }

  _getPref = (): IProductListFilterPreference => util.getProductListFilterPreferance()

  _initialize = async () => {
    const navPrams = util.getNavParams(this.props)
    const pref = this._getPref()

    // console.log('ProductListFilterManageView _initialize pref => ', pref)

    await util.setStatePromise(this, { ...navPrams, editingPref: pref, isInitialized: true })
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _moveFilterTemplateItem(from: number, to: number) {
    const { editingPref } = this.state
    const { templates = [] } = editingPref

    const newTemplates = [...templates]

    // remove `from` item and store it
    const f = newTemplates.splice(from, 1)[0]

    // insert stored item into position `to`
    newTemplates.splice(to, 0, f)

    this.setState({ editingPref: { ...editingPref, templates: newTemplates } })
  }

  _moveFilterTemplateUp = (fromIndex: number) => {
    this._moveFilterTemplateItem(fromIndex, fromIndex - 1)
  }

  _moveFilterTemplateDown = (fromIndex: number) => {
    this._moveFilterTemplateItem(fromIndex, fromIndex + 1)
  }

  _removeFilterTemplate = (tmpl: IProductListFilterTemplate, index: number) => {
    const { editingPref } = this.state
    const { templates = [] } = editingPref
    const newTemplates = _.remove(templates, (item, i) => i !== index)
    this.setState({ editingPref: { ...editingPref, templates: newTemplates } })
  }

  _onEditFilterSaveSuccess = async (newPref: IProductListFilterPreference, savedTemplate?: IProductListFilterTemplate) => {
    // console.log('_navToEditFilterTemplate onSaveSuccess')
    await this._initialize()
    const onSaveSuccess = util.getNavParam(this.props, 'onSaveSuccess')

    if (onSaveSuccess) {
      await onSaveSuccess(newPref, savedTemplate)
    }
  }

  _navToEditFilterTemplate = (index: number) => {
    const { editingPref } = this.state
    const { templates = [] } = editingPref
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToProductListFilterEditorView({
        headerTitle: 'แก้ไขข้อมูลเทมเพลตตัวกรองสินค้า',
        store_id: util.getNavParam(this.props, 'store_id'),
        defaultSetting: templates[index],
        appliedSetting: templates[index],
        onSaveSuccess: this._onEditFilterSaveSuccess,
        // onDeleteSuccess: this._onEditFilterSaveSuccess,
      })
    )
  }

  _navToCreateFilterTemplate = () => {
    const { editingPref } = this.state
    const { templates = [] } = editingPref
    const { navigation } = this.props

    if (templates && templates.length >= 15) {
      p.op.alert('ไม่สามารถสร้างเทมเพลตตัวกรองสินค้าได้เกิน 15 รายการ', 'กรุณาลบเทมเพลตที่ไม่ได้ใช้ออกก่อน แล้วจึงสร้างใหม่')
      return
    }

    const newTemplate: IProductListFilterTemplate = {
      id: `${dayjs().format('YYYYMMDD').toString()}x${util.generateRandomString(5)}`,
      name: '',
      createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      color: '#b79ec7',
    }

    navigation.dispatch(
      NavActions.navToProductListFilterEditorView({
        headerTitle: 'สร้างเทมเพลตตัวกรองสินค้า',
        store_id: util.getNavParam(this.props, 'store_id'),
        defaultSetting: newTemplate,
        appliedSetting: newTemplate,
        onSaveSuccess: this._onEditFilterSaveSuccess,
      })
    )
  }

  _openColorSelectorModal = (tmpl: IProductListFilterTemplate, index: number) => {
    // console.log('_openColorSelectorModal index', index)
    // console.log('_openColorSelectorModal tmpl', tmpl)
    this.setState({ editingTemplate: { ...tmpl, index } })
  }

  _closeColorSelectorModal = () => {
    this.setState({ editingTemplate: null })
  }

  _renderFilterTemplate = (tmpl: IProductListFilterTemplate, index: number, tmplArr: IProductListFilterTemplate[]) => {
    const { isSubmitting } = this.state

    const isMoveUpAvailable = isSubmitting || index > 0
    const isMoveDownAvailable = isSubmitting || index < tmplArr.length - 1

    return (
      <HStack
        key={index.toString()}
        w='full'
        p='1'
        minH='44px'
        space='1.5'
        bg='white'
        alignItems='center'
        borderWidth='1'
        borderColor={tmpl.color || 'gray.400'}
        // borderTopColor='gray.200'
        // borderLeftColor='gray.200'
        // borderRightColor='gray.500'
        // borderBottomColor='gray.500'
        borderRadius='lg'>
        {/* <XButton disabled={isSubmitting} colorScheme='danger' onPress={() => this._removeFilterTemplate(tmpl, index)} w='34px' h='34px'>
          <XIcon name='trash' family='Ionicons' />
        </XButton> */}
        {/* <HStack>
          <TouchableOpacity disabled={isSubmitting} onPress={() => this._openColorSelectorModal(tmpl, index)}>
            <Box w='34px' h='34px' bg={tmpl.color} borderWidth='1' borderRadius='full' borderColor='primary.500' />
          </TouchableOpacity>
        </HStack> */}

        <Box w='20px' h='20px' bg={tmpl.color} borderRadius='full' />

        <HStack flex={1} alignItems='center'>
          <XText color={tmpl.color}>{tmpl.name}</XText>
        </HStack>

        <XIconButton
          opacity={isMoveUpAvailable ? 1 : 0}
          variant='ghost'
          bg='gray.100'
          borderRadius='full'
          onPress={() => this._moveFilterTemplateUp(index)}
          disabled={!isMoveUpAvailable}
          w='34px'
          h='34px'
          name='arrow-up'
          family='Ionicons'
        />

        <XIconButton
          opacity={isMoveDownAvailable ? 1 : 0}
          variant='ghost'
          bg='gray.100'
          borderRadius='full'
          onPress={() => this._moveFilterTemplateDown(index)}
          disabled={!isMoveDownAvailable}
          w='34px'
          h='34px'
          name='arrow-down'
          family='Ionicons'
        />

        <XIconButton
          variant='ghost'
          bg='gray.100'
          borderRadius='full'
          onPress={() => this._navToEditFilterTemplate(index)}
          disabled={isSubmitting}
          w='34px'
          h='34px'
          name='edit'
          family='MaterialIcons'
          // pb='1'
        />
      </HStack>
    )
  }

  _renderEmptyFilterTemplate = () => (
    <HStack w='full' px='2' py='4' alignItems='center' justifyContent='center'>
      <XText textAlign='center' variant='inactive'>
        {'คุณสามารถสร้าง "เทมเพลตตัวกรองสินค้า"\nเพื่อ *บันทึกตัวเลือกการกรอง* ไว้ใช้งานในภายหลังได้\n\n' + 'กดปุ่ม + ด้านบนขวา เพื่อสร้าง'}
      </XText>
    </HStack>
  )

  _renderFilterTemplates = () => {
    const { editingPref } = this.state
    const { templates = [] } = editingPref

    if (!templates || !templates.length) {
      return this._renderEmptyFilterTemplate()
    }

    return templates.map(this._renderFilterTemplate)
  }

  _renderDebugResetButton = () => (
    <HStack w='full' p='2' space='1.5' alignItems='center' justifyContent='center'>
      <XButton
        variant='outline'
        onPress={async () => {
          try {
            const isSuccess = await api.postStoreUser({
              store_id: util.getNavParam(this.props, 'store_id'),
              pref_ui_pp_list_filter: { reset: true },
            })
            if (isSuccess) {
              this.props.updateSelectedStoreByKey('pref_ui_pp_list_filter', {})
            }
          } catch (err) {
            console.log('_renderDebugResetButton error => ', err)
          }
          await util.delay(1000)
          this._initialize()
        }}>
        DEV DEBUG: Clear all filter templates
      </XButton>
    </HStack>
  )

  _isPrefDirty = () => {
    const pref = this._getPref()
    const { editingPref } = this.state
    const prefDiff = diff(pref, editingPref)
    return prefDiff && !_.isEmpty(prefDiff)
  }

  _onSavePressed = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const isUserConfirm = await p.op.isUserConfirm(
      'บันทึกเทมเพลตตัวกรองสินค้า',
      'กรุณายืนยันว่าฉันต้องการบันทึกการเปลี่ยนแปลงเทมเพลตตัวกรองสินค้า',
      'บันทึก',
      'กลับไปแก้ไข'
    )

    if (!isUserConfirm) {
      this.inProcess = false
      return
    }

    await util.setStatePromise(this, { isSubmitting: true })

    const { editingPref } = this.state
    // console.log('editingPref => ', editingPref)

    const store_id = util.getNavParam(this.props, 'store_id')
    const onSaveSuccess = util.getNavParam(this.props, 'onSaveSuccess')
    const isPreventGoBackAfterSaveSuccess = util.getNavParam(this.props, 'isPreventGoBackAfterSaveSuccess', false)

    // const isSuccess = await api.postStoreUser({ store_id, perf_ui_order_list_filter: editingPref })
    const isSuccess = await api.postStoreUser({ store_id, pref_ui_pp_list_filter: editingPref })
    // console.log('_onSavePressed isSuccess => ', isSuccess)

    if (isSuccess) {
      const { updateSelectedStoreByKey } = this.props
      updateSelectedStoreByKey('pref_ui_pp_list_filter', editingPref)
      p.op.showToast('บันทึกเทมเพลตตัวกรองสินค้าเรียบร้อยแล้ว', 'success')

      if (_.isFunction(onSaveSuccess)) {
        await util.delay(100)
        await onSaveSuccess(editingPref)
      }

      if (!isPreventGoBackAfterSaveSuccess) {
        util.navGoBack(this.props)
      }
    } else {
      p.op.showToast('บันทึกเทมเพลตตัวกรองสินค้าไม่สําเร็จ', 'danger')
    }

    await util.setStatePromise(this, { isSubmitting: false })
    this.inProcess = false
  }

  _onCancelPressed = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const isUserConfirm = await p.op.isUserConfirm(
      'ยกเลิกการแก้ไขเทมเพลตตัวกรองสินค้า',
      'กรุณายืนยันว่าฉันต้องการยกเลิกการเปลี่ยนแปลงของเทมเพลตตัวกรองสินค้า',
      'ยกเลิก',
      'กลับไปแก้ไข'
    )

    if (!isUserConfirm) {
      this.inProcess = false
      return
    }

    await util.setStatePromise(this, { editingPref: this._getPref() })

    this.inProcess = false
  }

  _onEditingTemplateColorChange = (newColor: string) => {
    const { editingTemplate } = this.state
    editingTemplate.color = newColor
    this.setState({ editingTemplate })
  }

  _onSaveEditingTemplate = () => {
    const { editingTemplate, editingPref } = this.state
    const { templates = [] } = editingPref

    const savedEditingTemplate = { ...editingTemplate }
    const savedTemplateIndex = savedEditingTemplate.index
    delete savedEditingTemplate.index

    const newEditingPref = _.cloneDeep(editingPref)
    newEditingPref.templates[savedTemplateIndex] = savedEditingTemplate
    newEditingPref.templates[savedTemplateIndex].updatedAt = dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString()

    this.setState({ editingTemplate: null, editingPref: newEditingPref })
    this._closeColorSelectorModal()
  }

  _renderColorSelectorModal = () => {
    const { editingTemplate } = this.state

    if (_.isNil(editingTemplate) || _.isEmpty(editingTemplate)) {
      return null
    }

    return (
      <XOverlay key={editingTemplate.id} visible onRequestClose={this._closeColorSelectorModal}>
        <XCustomHeader
          title='เลือกสีเทมเพลตตัวกรองสินค้า'
          headerRightProps={{ closeIcon: true, onPressItem: this._closeColorSelectorModal }}
        />

        <VStack w='full' p='2' space='1.5' bg='white'>
          <VStack w='full' space='0.5'>
            <XText variant='inactive'>สีเทมเพลตตัวกรองสินค้า</XText>
            <XColorPalette value={editingTemplate.color} onChange={this._onEditingTemplateColorChange} />
          </VStack>
        </VStack>

        <HStack w='full' alignItems='center' justifyContent='center' p='2'>
          <XButton w='100px' onPress={this._onSaveEditingTemplate}>
            บันทึก
          </XButton>
        </HStack>
      </XOverlay>
    )
  }

  _renderHeader = () => {
    const { isSubmitting } = this.state

    return (
      <XCustomHeader
        headerLeftProps={
          this._isPrefDirty()
            ? { label: 'บันทึก', onPressItem: this._onSavePressed, submitting: isSubmitting }
            : { backIcon: true, onPressItem: this._goBack, submitting: isSubmitting }
        }
        title='จัดการเทมเพลตตัวกรองสินค้า'
        // @ts-ignore
        headerRightProps={
          this._isPrefDirty()
            ? { label: 'ยกเลิก', onPressItem: this._onCancelPressed, submitting: isSubmitting }
            : { addIcon: true, onPressItem: this._navToCreateFilterTemplate, submitting: isSubmitting }
        }
      />
    )
  }

  _renderContent = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    return (
      <XContent>
        <VStack w='full' p='2' space='1.5'>
          {this._renderFilterTemplates()}
          {this._renderDebugResetButton()}
        </VStack>
      </XContent>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        {this._renderContent()}
        {this._renderColorSelectorModal()}
        {/* <TouchableOpacity onPress={() => this.props.updateSelectedStoreByKey('pref_ui_pp_list_filter', {})}>
          <XText>Clear Test</XText>
        </TouchableOpacity> */}
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
  // @ts-ignore
)(ProductListFilterManageView)
