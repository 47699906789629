import React, { Component } from 'react'
import { Clipboard, TouchableOpacity } from 'react-native'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getSelectedStore, getSellerStores } from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'
import * as acl from 'x/utils/acl'

import {
  IFetchOrdersRequestBody,
  ISearchProductItem,
  ISearchProductVariantListItemProps,
  ISearchProductVariantListItemState,
  IVariantMkpInfo,
} from 'x/index'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'
import * as api from 'x/utils/api'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import * as fmt from 'x/utils/formatter'

import _ from 'lodash'
import dayjs from 'dayjs'
import { List, Map } from 'immutable'
import { MODE } from 'x/config/mode'
import time from 'x/utils/time'
import HStack from '../HStack'
import VStack from '../VStack'
import XText from '../XText'
import XImage from '../XImage'
import XIcon from '../XIcon'
import XOverlay from '../XOverlay'
import XCustomHeader from '../XCustomHeader'
import Box from '../Box'
import XButton from '../XButton'
import XNumericInput from '../XNumericInput'
import XInput from '../XInput'
import ForwardIcon from '../ForwardIcon'
import MkpLogo from '../MkpLogo'
import { Menu, MenuOptions, MenuOption, MenuTrigger, renderers } from '../popup'

const ICON_SELLER_STORE = require('../../img/tb/tb_seller0.png')

class SearchProductVariantListItem extends Component<ISearchProductVariantListItemProps, ISearchProductVariantListItemState> {
  static displayName = 'SearchProductVariantListItem'

  inProcess?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ isInitialized: true })
  }

  _isPermProductQtyViewable = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_VIEW)

  _isPermProductQtyEditable = () =>
    acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_EDIT) && acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)

  _isPermProductCostViewable = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_COST)

  _isPermProductCostEditable = () =>
    acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_EDIT) && acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT)

  _isPermProductPriceViewable = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_PRICE)

  _isPermProductPriceEditable = () =>
    acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_EDIT) && acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)

  _isItemPressable = () => {
    const { onPress } = this.props
    return _.isFunction(onPress)
  }

  _onPressItem = () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { onPress, data } = this.props
    if (_.isFunction(onPress)) {
      onPress(data)
    }

    this.inProcess = false
  }

  _navToReservedProductsOrderListView = () => {
    const { navigation, storeId, data } = this.props

    const overrideRequestBody: Partial<IFetchOrdersRequestBody> = {
      unshipped_pp_ids: [data.v[0].i],
    }

    navigation.dispatch(
      NavActions.navToReservedProductsOrderListView({
        store_id: storeId,
        overrideRequestBody,

        // เนื่องจาก default ใหม่ของเรามัน 2 เดือน การ search เราเลยต้องปรับใหม่เป็น 5 ปีเพื่อให้มันค้นได้ทั่วถึงมากขึ้น
        ordersDefaultFilterSetting: {
          createOrderDateRangeOptionKey: CONS.DATERANGE_SELECTOR.OPTION.Custom,
          // createOrderFrom: dayjs().subtract(5, 'years').startOf('day'),
          createOrderFrom: dayjs('2017-01-01 00:00:00', CONS.SERVER_DATETIME_FORMAT).startOf('day'),
          createOrderTo: dayjs().endOf('day'),
        },
      })
    )
  }

  _navToProductDetail = () => {
    const {
      storeId,
      sellerStoreId,
      navigation,
      data,
      isPullProductToOrderMode,
      ugId,
      pgIds,
      onSuccessPullMyProductToOrderCallback,
      onSuccessPullSellersProductToOrderCallback,
    } = this.props

    if (isPullProductToOrderMode && sellerStoreId) {
      navigation.dispatch(
        NavActions.navToProductViewPullSellerProductToOrder({
          store_id: storeId,
          product_id: data.id,
          seller_store_id: sellerStoreId,
          onSuccessPullSellersProductToOrderCallback,
        })
      )
      return
    }

    if (isPullProductToOrderMode && !sellerStoreId) {
      navigation.dispatch(
        NavActions.navToProductViewPullMyProductToOrder({
          store_id: storeId,
          product_id: data.id,
          ug_id: ugId,
          pg_ids: pgIds,
          onSuccessPullMyProductToOrderCallback,
        })
      )
      return
    }

    if (sellerStoreId) {
      navigation.dispatch(
        NavActions.navToProductViewSeller({
          seller_store_id: sellerStoreId,
          product_id: data.id,
        })
      )
      return
    }

    navigation.dispatch(
      NavActions.navToProductViewMyStore({
        store_id: storeId,
        product_id: data.id,
        onSuccessAddProductCallback: this._setDirty,
        onSuccessDeleteProductCallback: this._setDirty,
        onSuccessEditProductCallback: this._setDirty,
      })
    )
  }

  _onChangeItemData = (newData: ISearchProductItem) => {
    const { onChangeData } = this.props
    if (_.isFunction(onChangeData)) {
      onChangeData(newData)
    }
  }

  _setDirty = async () => {
    const { data } = this.props
    const newItemData = _.cloneDeep(data)
    // @ts-ignore
    newItemData.isDirty = true
    this._onChangeItemData(newItemData)
  }

  _onSubmitEditQty = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { storeId, data } = this.props
    const { editingQty } = this.state

    try {
      const res = await api.inventoryQtyAdjust({
        mode: 's',
        store_id: storeId,
        items: [{ pp_id: data.v[0].i, qty: editingQty }],
      })

      if (res.status === 'ok') {
        const newItemData = _.cloneDeep(data)
        const currentQty = _.isNil(data.v[0].q) ? 0 : data.v[0].q
        const currentAvailableQty = _.isNil(data.v[0].a) ? 0 : data.v[0].a
        const diffQty = editingQty - currentQty

        newItemData.v[0].q = editingQty
        newItemData.v[0].a = currentAvailableQty + diffQty

        this._onChangeItemData(newItemData)

        p.op.showToast('บันทึกจำนวนสินค้าสำเร็จ', 'success')
      }

      // console.log('_onSubmitEditQty res => ', res)
      this._onRequestCloseEditQty()
    } catch (error) {
      console.log('_onSubmitEditQty error => ', error)
    }

    this.inProcess = false
  }

  _openEditQtyOverlay = () => {
    const { data } = this.props
    const { v: vs, t } = data
    const v = vs[0]

    const currentQty = _.isNil(v.q) ? 0 : v.q

    this.setState({ isEditQtyOverlayVisible: true, editingQty: currentQty })
  }

  _onRequestCloseEditQty = () => {
    this.setState({ isEditQtyOverlayVisible: false, editingQty: null })
  }

  _renderEditQtyOverlay = () => {
    const { isEditQtyOverlayVisible = false, editingQty = 0 } = this.state

    if (!isEditQtyOverlayVisible) {
      return null
    }

    const { data } = this.props
    const { v: vs, t } = data
    const v = vs[0]

    const currentQty = _.isNil(v.q) ? 0 : v.q
    const currentAvailableQty = _.isNil(v.a) ? 0 : v.a
    const changedDiff = editingQty - currentQty

    return (
      <XOverlay
        contentStyle={{ minHeight: 280 }}
        visible
        onRequestClose={() => {
          // do nothing
        }}>
        <XCustomHeader headerRightProps={{ closeIcon: true, onPressItem: this._onRequestCloseEditQty }} title='ระบุจำนวนสินค้า' />
        <Box w='full' h='1px' borderBottomWidth='1' borderBottomColor='gray.200' />
        <VStack flex={1} w='full' p='2' space='1'>
          <HStack w='full' space='2'>
            {this._renderProductVariantImage()}
            {this._renderProductVariantInfo()}
          </HStack>

          <HStack w='full' h='34px' pt='2' alignItems='center' space='1'>
            <XText w='120px' variant='inactive'>
              {`จำนวน${p.op.t('qty')}เดิม`}
            </XText>

            <XText flex={1} pr='35px' textAlign='right' variant='inactive'>
              {currentQty}
            </XText>
          </HStack>

          <HStack w='full' h='28px' alignItems='center' space='1'>
            {changedDiff !== 0 && (
              <XText flex={1} pr='35px' textAlign='right' variant={changedDiff > 0 ? 'success' : 'danger'} bold>
                {` ${changedDiff > 0 ? '+' : ''}${changedDiff}`}
              </XText>
            )}
          </HStack>

          <HStack w='full' alignItems='center' space='1'>
            <XText flex={1} variant='inactive'>
              {`จำนวน${p.op.t('qty')}ใหม่`}
            </XText>

            <VStack w='160px'>
              <XNumericInput
                autoFocus
                textAlign='right'
                focusable
                isInteger
                style={{ flex: 1, height: 34 }}
                value={editingQty}
                keyboardType='numeric'
                minValue={0}
                maxValue={999999999999}
                maxLength={12}
                // onChangeText={this._onChangeInputQtyText}
                onChangeValue={(newValue) => {
                  this.setState({ editingQty: newValue })
                }}
                onSubmitEditing={this._onSubmitEditQty}
              />
            </VStack>
          </HStack>
        </VStack>
        <VStack w='full' p='2' alignItems='center' justifyContent='center'>
          <XButton h='34px' w='80px' onPress={this._onSubmitEditQty}>
            บันทึก
          </XButton>
        </VStack>
      </XOverlay>
    )
  }

  _onSubmitEditCost = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { storeId, data } = this.props
    const { editingCost } = this.state

    if (_.isNil(editingCost) || editingCost === '') {
      p.op.showToast('กรุณาระบุจำนวนต้นทุนสินค้า', 'danger')
      return
    }

    try {
      const normalizedCost = Math.floor(parseFloat(editingCost) * 100) / 100
      const cost = normalizedCost.toString()
      const res = await api.patchProduct({
        store_id: storeId,
        product_id: data.id,

        variants: [
          {
            pp_id: data.v[0].i,
            cost,
            action: 'EDIT',
          },
        ],
      })

      if (res && res.id) {
        const newItemData = _.cloneDeep(data)
        newItemData.v[0].c = cost
        this._onChangeItemData(newItemData)

        p.op.showToast('บันทึกต้นทุนสินค้าสำเร็จ', 'success')
      }

      // console.log('_onSubmitEditCost res => ', res)
      this._onRequestCloseEditCost()
    } catch (error) {
      console.log('_onSubmitEditCost error => ', error)
    }

    this.inProcess = false
  }

  _openEditCostOverlay = () => {
    const { data } = this.props
    const { v: vs, t } = data
    const v = vs[0]

    const currentCost = _.isNil(v.c) ? 0 : +v.c

    this.setState({ isEditCostOverlayVisible: true, editingCost: currentCost.toString() })
  }

  _onRequestCloseEditCost = () => {
    this.setState({ isEditCostOverlayVisible: false, editingCost: null })
  }

  _renderEditCostOverlay = () => {
    const { isEditCostOverlayVisible = false, editingCost = '0' } = this.state

    if (!isEditCostOverlayVisible) {
      return null
    }

    const { data } = this.props
    const { v: vs, t } = data
    const v = vs[0]

    const currentCost = _.isNil(v.c) ? 0 : +v.c
    const changedDiff = +editingCost - currentCost

    return (
      <XOverlay
        contentStyle={{ minHeight: 280 }}
        visible
        onRequestClose={() => {
          // do nothing
        }}>
        <XCustomHeader headerRightProps={{ closeIcon: true, onPressItem: this._onRequestCloseEditCost }} title='ระบุต้นทุนสินค้า' />
        <Box w='full' h='1px' borderBottomWidth='1' borderBottomColor='gray.200' />
        <VStack flex={1} w='full' p='2' space='1'>
          <HStack w='full' space='2'>
            {this._renderProductVariantImage()}
            {this._renderProductVariantInfo()}
          </HStack>

          <HStack w='full' h='34px' pt='2' alignItems='center' space='1'>
            <XText flex={1} variant='inactive'>
              ต้นทุนเดิม
            </XText>

            {/* <XText flex={1} pr='35px' textAlign='right' variant='inactive'>
              {currentCost}
            </XText> */}
            <XInput
              disabled
              isDisabled
              textAlign='right'
              isMoney
              style={{ flex: 1, height: 34, width: 120, color: 'black', fontWeight: 'bold' }}
              value={currentCost.toString()}
              keyboardType='decimal-pad'
              maxLength={12}
            />
          </HStack>

          <HStack w='full' h='28px' alignItems='center' space='1'>
            {changedDiff !== 0 && (
              <XText flex={1} pr='19px' textAlign='right' variant={changedDiff > 0 ? 'success' : 'danger'} bold>
                {` ${changedDiff > 0 ? '+' : ''}${changedDiff}`}
              </XText>
            )}
          </HStack>

          <HStack w='full' alignItems='center' space='1'>
            <XText flex={1} variant='inactive'>
              ต้นทุนใหม่
            </XText>

            <XInput
              autoFocus
              textAlign='right'
              focusable
              isMoney
              style={{ flex: 1, height: 34, width: 120 }}
              value={editingCost}
              keyboardType='numeric'
              maxLength={12}
              onChangeText={(newValue) => {
                this.setState({ editingCost: newValue })
              }}
              onSubmitEditing={this._onSubmitEditCost}
            />
          </HStack>
        </VStack>
        <VStack w='full' p='2' alignItems='center' justifyContent='center'>
          <XButton h='34px' w='80px' onPress={this._onSubmitEditCost}>
            บันทึก
          </XButton>
        </VStack>
      </XOverlay>
    )
  }

  _onSubmitEditPrice = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { storeId, data } = this.props
    const { editingPrice } = this.state

    if (_.isNil(editingPrice) || editingPrice === '') {
      p.op.showToast('กรุณาระบุจำนวนต้นทุนสินค้า', 'danger')
      return
    }

    try {
      const normalizedPrice = Math.floor(parseFloat(editingPrice) * 100) / 100
      const price = normalizedPrice.toString()
      const res = await api.patchProduct({
        store_id: storeId,
        product_id: data.id,

        variants: [
          {
            pp_id: data.v[0].i,
            prices: [
              {
                pg_id: data.v[0].s[0].g,
                price,
              },
            ],
            action: 'EDIT',
          },
        ],
      })

      if (res && res.id) {
        const newItemData = _.cloneDeep(data)
        newItemData.v[0].s[0].p = price
        this._onChangeItemData(newItemData)

        p.op.showToast('บันทึกต้นทุนสินค้าสำเร็จ', 'success')
      }

      // console.log('_onSubmitEditPrice res => ', res)
      this._onRequestCloseEditPrice()
    } catch (error) {
      console.log('_onSubmitEditPrice error => ', error)
    }

    this.inProcess = false
  }

  _openEditPriceOverlay = () => {
    const { data } = this.props
    const { v: vs, t } = data
    const v = vs[0]

    const currentPrice = _.isNil(v.s) || v.s.length === 0 ? 0 : +v.s[0].p

    this.setState({ isEditPriceOverlayVisible: true, editingPrice: currentPrice.toString() })
  }

  _onRequestCloseEditPrice = () => {
    this.setState({ isEditPriceOverlayVisible: false, editingPrice: null })
  }

  _renderEditPriceOverlay = () => {
    const { isEditPriceOverlayVisible = false, editingPrice = '0' } = this.state

    if (!isEditPriceOverlayVisible) {
      return null
    }

    const { data } = this.props
    const { v: vs, t } = data
    const v = vs[0]

    const currentPrice = _.isNil(v.s) || v.s.length === 0 ? 0 : +v.s[0].p
    const changedDiff = +editingPrice - currentPrice

    return (
      <XOverlay
        contentStyle={{ minHeight: 280 }}
        visible
        onRequestClose={() => {
          // do nothing
        }}>
        <XCustomHeader headerRightProps={{ closeIcon: true, onPressItem: this._onRequestCloseEditPrice }} title='ระบุราคาสินค้า' />
        <Box w='full' h='1px' borderBottomWidth='1' borderBottomColor='gray.200' />
        <VStack flex={1} w='full' p='2' space='1'>
          <HStack w='full' space='2'>
            {this._renderProductVariantImage()}
            {this._renderProductVariantInfo()}
          </HStack>

          <HStack w='full' h='34px' pt='2' alignItems='center' space='1'>
            <XText flex={1} variant='inactive'>
              {`${this._getPriceName()} เดิม`}
            </XText>

            {/* <XText flex={1} pr='35px' textAlign='right' variant='inactive'>
              {currentPrice}
            </XText> */}
            <XInput
              disabled
              isDisabled
              textAlign='right'
              isMoney
              style={{ flex: 1, height: 34, width: 120, color: 'black', fontWeight: 'bold' }}
              value={currentPrice.toString()}
              keyboardType='decimal-pad'
              maxLength={12}
            />
          </HStack>

          <HStack w='full' h='28px' alignItems='center' space='1'>
            {changedDiff !== 0 && (
              <XText flex={1} pr='19px' textAlign='right' variant={changedDiff > 0 ? 'success' : 'danger'} bold>
                {` ${changedDiff > 0 ? '+' : ''}${changedDiff}`}
              </XText>
            )}
          </HStack>

          <HStack w='full' alignItems='center' space='1'>
            <XText flex={1} variant='inactive'>
              {`${this._getPriceName()} ใหม่`}
            </XText>

            <XInput
              autoFocus
              textAlign='right'
              focusable
              isMoney
              style={{ flex: 1, height: 34, width: 120 }}
              value={editingPrice}
              keyboardType='numeric'
              maxLength={12}
              onChangeText={(newValue) => {
                this.setState({ editingPrice: newValue })
              }}
              onSubmitEditing={this._onSubmitEditPrice}
            />
          </HStack>
        </VStack>
        <VStack w='full' p='2' alignItems='center' justifyContent='center'>
          <XButton h='34px' w='80px' onPress={this._onSubmitEditPrice}>
            บันทึก
          </XButton>
        </VStack>
      </XOverlay>
    )
  }

  _getPriceName = () => {
    const { data, selectedStore } = this.props
    const { n, v: vs, t } = data
    const v = vs[0]
    const havePrice = !_.isNil(v.s) && _.isArray(v.s) && v.s.length > 0 && v.s[0].p

    if (!havePrice) {
      return null
    }

    const pgs = selectedStore.get('product_groups') || List([])

    const foundPgIndex = pgs.findIndex((pg) => pg.get('id') === v.s[0].g)

    if (foundPgIndex === -1) {
      return null
    }

    const pg = pgs.get(foundPgIndex)
    const pgName = Map.isMap(pg) && pg.get('name') ? pg.get('name') : null

    return pgName
  }

  _renderProductVariantImage = () => {
    const { data } = this.props
    const { v: vs, t } = data
    let imgUri = t[0]
    if (vs[0].img_url) {
      imgUri = vs[0].img_url
    }

    return (
      <HStack
        h='64px'
        w='64px'
        alignItems='center'
        justifyContent='center'
        borderWidth='1'
        borderTopColor='gray.200'
        borderLeftColor='gray.200'
        borderRightColor='gray.300'
        borderBottomColor='gray.300'
        flexWrap='wrap'
        borderRadius='lg'
        overflow='hidden'>
        <XImage source={{ uri: imgUri }} h='64px' w='64px' />
      </HStack>
    )
  }

  _renderProductVariantInfo = () => {
    const { data } = this.props
    const { n, v: vs } = data
    const v = vs[0]
    const productName = n || ''
    const variantName = v.n || ''
    const sku = v.sku || null
    const upc = v.upc || null

    return (
      <VStack pl='1' flex={1}>
        <XText variant='inactive' numberOfLines={2}>
          {productName}
        </XText>

        <XText numberOfLines={2}>{variantName}</XText>

        {sku ? <XText variant='inactive' fontSize='xs' numberOfLines={1}>{`SKU: ${sku}`}</XText> : null}
        {upc ? <XText variant='inactive' fontSize='xs' numberOfLines={1}>{`UPC: ${upc}`}</XText> : null}
      </VStack>
    )
  }

  _renderProductVariantId = () => {
    const { data } = this.props
    if (!data) {
      return null
    }
    const id = data.v[0].i

    if (!id) {
      return null
    }

    return (
      <XText
        variant='inactive'
        fontSize='2xs'
        numberOfLines={1}
        onPress={() => {
          // @ts-ignore
          Clipboard.setString(id.toString())
          p.op.showToast(`คัดลอก ${id} แล้ว`, 'success')
        }}>
        {`ID:${id}`}
      </XText>
    )
  }

  _isPulledProduct = () => {
    const { data, sellerStores } = this.props
    if (!data) {
      return false
    }

    const storeIdOfTheTemplate = data.s
    if (!storeIdOfTheTemplate) {
      return false
    }

    return _.isNumber(storeIdOfTheTemplate) && storeIdOfTheTemplate > 0

    // const foundIndex = sellerStores.findIndex((store) => store.get('id') === storeIdOfTheTemplate)
    // return foundIndex !== -1
  }

  _renderNumberOfItem = () => {
    const { index } = this.props

    if (_.isNil(index) || !_.isNumber(index)) {
      return null
    }

    return (
      <XText pr='0.5' fontSize='2xs' variant='inactive'>
        {`#${index + 1}`}
      </XText>
    )
  }

  _renderPulledProductIcon = () => {
    if (!this._isPulledProduct()) {
      // return <HStack w='22px' h='22px' />
      return null
    }

    const { data, sellerStores } = this.props
    const storeIdOfTheTemplate = data.s
    const foundIndex = sellerStores.findIndex((store) => store.get('id') === storeIdOfTheTemplate)
    const storeName = foundIndex >= 0 ? sellerStores.getIn([foundIndex, 'name']) : 'ร้านขายส่ง'

    return (
      <VStack
        alignItems='center'
        //  position='absolute' bottom='0' right='0'
      >
        <TouchableOpacity
          onPress={() => {
            if (MODE === 'dev') {
              p.op.alert(
                `สินค้าจากร้าน: ${storeName}`,
                `DEV DEBUG:\nstoreIdOfTheTemplate: ${storeIdOfTheTemplate}\nSellerStoreIds: ${sellerStores
                  .map((store) => store.get('id'))
                  .join(', ')}`
              )
            } else {
              p.op.alert(`สินค้าจากร้าน: ${storeName}`)
            }
          }}>
          <HStack w='22px' h='22px' alignItems='center' justifyContent='center' bg='gray.100' borderRadius='full'>
            <XImage source={ICON_SELLER_STORE} h='18px' w='18px' />
          </HStack>
        </TouchableOpacity>

        {/* <XText variant='inactive' fontSize='xs' numberOfLines={1}>
          {`สินค้าจากร้าน: ${storeName}`}
        </XText> */}
      </VStack>
    )
  }

  _hasMkpChannels = () => {
    const { data } = this.props
    const { v: vs } = data
    const v = vs[0]

    return v.mkp_ch_ids && v.mkp_ch_ids.length > 0
  }

  _getMkpChannelIdsCount = () => {
    const { data } = this.props
    const { v: vs } = data
    const v = vs[0]
    let count = 0

    v.mkp_ch_ids.forEach((ch_id) => {
      if (this._getMkpChannelName(ch_id) !== '-') {
        count++
      }
    })

    return count
  }

  _getMkpChannelCountArray = (): Array<{ mkpChId: number; count: number }> => {
    const { data } = this.props
    const { v: vs } = data
    const v = vs[0]

    if (!this._hasMkpChannels()) {
      return
    }

    const countMap: { [key: string]: number } = {}

    // นับจำนวนในรูปแบบ object
    v.mkp_ch_ids.forEach((ch_id) => {
      if (this._getMkpChannelName(ch_id) !== '-') {
        countMap[ch_id] = (countMap[ch_id] || 0) + 1
      }
    })

    // แปลงผลลัพธ์จาก object เป็น array of objects
    return Object.entries(countMap).map(([ch_id, count]) => ({
      mkpChId: parseInt(ch_id), // แปลง key กลับเป็นตัวเลข
      count,
    }))
  }

  _getMkpChannelName = (mkpChId: number) => util.getMKPChannelName(mkpChId)

  _navToProductDetailMkpInfoDetailView = (ppId?: number) => {
    const { navigation, storeId, data } = this.props

    // navigation.dispatch(
    //   NavActions.navToProductViewMyStore({
    //     store_id: storeId,
    //     product_id: data.id,
    //     navAfterInit: (viewNavigation) => {
    //       viewNavigation.dispatch(
    //         NavActions.navToProductDetailMkpInfoDetailView({
    //           store_id: storeId,
    //           product_id: data.id,
    //           pp_id: ppId || undefined,
    //           // onMappingSuccess: () => this._onProductDetailMkpInfoDetailViewMappingSuccess(ppId),
    //           onMappingSuccess: () => this._onProductDetailMkpInfoDetailViewMappingSuccess(ppId),
    //         })
    //       )
    //     },
    //   })
    // )

    navigation.dispatch(
      NavActions.navToProductDetailMkpInfoDetailView({
        store_id: storeId,
        product_id: data.id,
        pp_id: ppId || undefined,
        fromProductListItem: data,
        // onMappingSuccess: (updatedVariants: IProductDetailVariant[]) =>
        //   this._onProductDetailMkpInfoDetailViewMappingSuccess(updatedVariants),
        onUpdateVariantMkpInfo: this._onProductDetailMkpInfoDetailViewMkpInfoUpdate,
      })
    )
  }

  areArraysEqual = (arr1: number[], arr2: number[]): boolean => {
    if (arr1.length !== arr2.length) return false
    const sorted1 = arr1.slice().sort()
    const sorted2 = arr2.slice().sort()
    return sorted1.every((value, index) => value === sorted2[index])
  }

  _onProductDetailMkpInfoDetailViewMkpInfoUpdate = async (updatedVariantMkpInfo: IVariantMkpInfo[]) => {
    const { data } = this.props

    const updatedMkpChIds = updatedVariantMkpInfo.map((mkpInfo) => mkpInfo.mkp_ch_id) || []
    const isMkpChannelIdsChanged = !this.areArraysEqual(data.v[0].mkp_ch_ids, updatedMkpChIds)

    if (isMkpChannelIdsChanged) {
      const newItemData = _.cloneDeep(data)
      // @ts-ignore
      newItemData.isDirty = true
      newItemData.v[0].mkp_ch_ids = updatedMkpChIds
      this._onChangeItemData(newItemData)
    }
  }

  _renderMkpInfoItem = (mkpInfoItem: { mkpChId: number; count: number }, index: number, array) => {
    const { mkpChId, count } = mkpInfoItem
    const mkpChannelName = this._getMkpChannelName(mkpChId)

    const mkpId = util.getMKPId(mkpChId)

    if (index === 3 && array.length > 3) {
      return (
        <HStack key={`mkp-info-item-${index}_${mkpChId}`} px='1' py='0.5' space='1' alignItems='center'>
          <XText variant='inactive' fontSize='xs' numberOfLines={1}>
            และอีก {array.length - 3} ช่องทาง
          </XText>
        </HStack>
      )
    }

    if (index > 3) {
      return null
    }

    return (
      <HStack
        key={`mkp-info-item-${index}_${mkpChId}`}
        px='1.5'
        py='1'
        space='1'
        alignItems='center'
        borderWidth='1'
        borderColor='gray.400'
        borderRadius='3xl'
        bg='white'>
        {count > 1 && (
          <XText variant='inactive' bold fontSize='xs' numberOfLines={1}>
            {`${count}x `}
          </XText>
        )}
        <MkpLogo mkpId={mkpId} width={18} height={18} />
        <XText variant='inactive' bold fontSize='xs' numberOfLines={1}>
          {mkpChannelName}
        </XText>
      </HStack>
    )
  }

  _renderCreatedByMkpChId = () => {
    const { data } = this.props
    const { v: vs } = data
    const v = vs[0]

    const createdAtByMkpChId = v.created_by_mkp_ch_id
    if (!createdAtByMkpChId) {
      return null
    }

    return (
      <HStack w='full' space='1' alignItems='center'>
        <XText w='150px' variant='inactive' fontSize='xs'>
          สร้างโดยระบบจากช่องทาง:
        </XText>

        <HStack flex={1} justifyContent='flex-end' alignItems='center'>
          {this._renderMkpInfoItem({ mkpChId: createdAtByMkpChId, count: 1 }, 0, [])}
        </HStack>
      </HStack>
    )
  }

  _renderDatetimeInfo = () => {
    const { data } = this.props

    const { v: vs } = data
    const v = vs[0]

    const { pt_created_at, pt_updated_at, pp_created_at, pp_updated_at, qty_updated_at, available_qty_updated_at } = v

    if (
      !_.isString(pt_created_at) &&
      !_.isString(pt_updated_at) &&
      !_.isString(pp_created_at) &&
      !_.isString(pp_updated_at) &&
      !_.isString(qty_updated_at) &&
      !_.isString(available_qty_updated_at)
    ) {
      return null
    }

    return (
      <VStack w='full' px='0.5' py='0.5' space='0.5' flexWrap='wrap'>
        {_.isString(pt_created_at) && pt_created_at.length > 0 && (
          <HStack space='1' alignItems='center'>
            <XText fontSize='xs' variant='inactive'>
              สินค้า สร้างเมื่อ:
            </XText>
            <XText flex={1} textAlign='right' fontSize='xs' variant='active'>
              {time.convertServerDateTimeToReadableDateTimeText(pt_created_at)}
            </XText>
          </HStack>
        )}

        {_.isString(pt_updated_at) && pt_updated_at.length > 0 && (
          <HStack space='1' alignItems='center'>
            <XText fontSize='xs' variant='inactive'>
              สินค้า อัพเดทล่าสุดเมื่อ:
            </XText>
            <XText flex={1} textAlign='right' fontSize='xs' variant='active'>
              {time.convertServerDateTimeToReadableDateTimeText(pt_updated_at)}
            </XText>
          </HStack>
        )}

        {_.isString(pp_created_at) && pp_created_at.length > 0 && (
          <HStack space='1' alignItems='center'>
            <XText fontSize='xs' variant='inactive'>
              ตัวเลือกสินค้า สร้างเมื่อ:
            </XText>
            <XText flex={1} textAlign='right' fontSize='xs' variant='active'>
              {time.convertServerDateTimeToReadableDateTimeText(pp_created_at)}
            </XText>
          </HStack>
        )}

        {_.isString(pp_updated_at) && pp_updated_at.length > 0 && (
          <HStack space='1' alignItems='center'>
            <XText fontSize='xs' variant='inactive'>
              ตัวเลือกสินค้า อัพเดทล่าสุดเมื่อ:
            </XText>
            <XText flex={1} textAlign='right' fontSize='xs' variant='active'>
              {time.convertServerDateTimeToReadableDateTimeText(pp_updated_at)}
            </XText>
          </HStack>
        )}

        {_.isString(qty_updated_at) && qty_updated_at.length > 0 && (
          <HStack space='1' alignItems='center'>
            <XText fontSize='xs' variant='inactive'>
              <XText bold fontSize='xs' variant='inactive'>
                {p.op.t('qty')}
              </XText>
              {' อัพเดทล่าสุดเมื่อ: '}
            </XText>
            <XText flex={1} textAlign='right' fontSize='xs' variant='active'>
              {time.convertServerDateTimeToReadableDateTimeText(qty_updated_at)}
            </XText>
          </HStack>
        )}

        {_.isString(available_qty_updated_at) && available_qty_updated_at.length > 0 && (
          <HStack space='1' alignItems='center'>
            <XText fontSize='xs' variant='inactive'>
              <XText bold fontSize='xs' variant='inactive'>
                {p.op.t('available_qty')}
              </XText>
              {' อัพเดทล่าสุดเมื่อ:'}
            </XText>
            <XText flex={1} textAlign='right' fontSize='xs' variant='active'>
              {time.convertServerDateTimeToReadableDateTimeText(available_qty_updated_at)}
            </XText>
          </HStack>
        )}
      </VStack>
    )
  }

  _renderMkpChannelBar = () => {
    if (!this._hasMkpChannels()) {
      return null
    }

    const { data } = this.props
    const mkpChannelCounArray = this._getMkpChannelCountArray()

    if (!mkpChannelCounArray || mkpChannelCounArray.length === 0) {
      return null
    }

    return (
      <VStack w='full' pt='2'>
        <TouchableOpacity
          onPress={() => {
            this._navToProductDetailMkpInfoDetailView(data.v[0].i)
          }}>
          <HStack
            w='full'
            px='1.5'
            py='1'
            space='1'
            alignItems='center'
            bg='primary.100'
            borderWidth='1'
            borderColor='primary.300'
            borderRadius='xl'>
            {/* <VStack flex={1} space='0.5'> */}
            {/* <XText variant='inactive'>{`${this._getMkpChannelIdsCount()} ช่องทางขาย ที่มีสินค้านี้ผูกอยู่`}</XText> */}
            <HStack flex={1} alignItems='center' space='1' flexWrap='wrap'>
              {/* {this.renderMkpInfos()} */}
              {mkpChannelCounArray.map(this._renderMkpInfoItem)}
            </HStack>
            {/* </VStack> */}

            {/* <HStack w='30px' h='30px' alignItems='center' justifyContent='center'>
              <XText variant='inactive'>{this._getMkpChannelIdsCount()}</XText>
            </HStack> */}
            <HStack w='30px' h='30px' alignItems='center' justifyContent='center'>
              <ForwardIcon />
            </HStack>
          </HStack>
        </TouchableOpacity>
      </VStack>
    )
  }

  _renderPressItemButton = () => {
    if (!this._isItemPressable()) {
      return null
    }

    const { data, buttonText = 'เลือก', renderListItemButton } = this.props

    return (
      <HStack flex={1} alignItems='center' justifyContent='flex-end'>
        {_.isFunction(renderListItemButton) ? (
          renderListItemButton(data, this._onPressItem)
        ) : (
          <TouchableOpacity onPress={this._onPressItem}>
            <HStack
              px='3'
              py='1.5'
              borderWidth='1'
              borderColor='primary.400'
              bg='primary.500'
              borderRadius='3xl'
              alignItems='center'
              justifyContent='center'
              space='1'>
              <XText variant='activeDark' bold>
                {buttonText}
              </XText>
            </HStack>
          </TouchableOpacity>
        )}
      </HStack>
    )
  }

  _renderPopupButton = () => {
    if (MODE !== 'dev') {
      return null
    }
    // FIXME: Need to implement pop-up menus
    const { data, index } = this.props

    const isMockToShow = true
    const isMockToHide = true

    return (
      <Menu renderer={renderers.Popover} rendererProps={{ preferredPlacement: 'bottom' }}>
        <MenuTrigger>
          {/* <XText style={{ color: 'red' }}>Click to open Popup: {index}</XText> */}
          <XIcon name='dots-horizontal-circle-outline' family='MaterialCommunityIcons' />
        </MenuTrigger>
        <MenuOptions
          // จริงๆ แล้วไม่ควร set width ไว้ เพราะ Component สามารถปรับขนาดตาม content ได้
          customStyles={{
            optionsContainer: { paddingHorizontal: 8, paddingVertical: 12, borderRadius: 16, width: 200, flexWrap: 'wrap' },
          }}>
          <MenuOption disabled>
            <HStack justifyContent='center' alignItems='center' space='1'>
              <XText bold variant='inactive'>
                I am just a Header
              </XText>
            </HStack>
          </MenuOption>
          <MenuOption onSelect={this._navToProductDetail}>
            <HStack alignItems='center' space='1'>
              <HStack w='28px'>
                <XIcon variant='active' name='information-circle-outline' />
              </HStack>

              <XText>ดูสินค้า</XText>
            </HStack>
          </MenuOption>

          {isMockToShow ? (
            <MenuOption onSelect={this._navToProductDetail}>
              <HStack alignItems='center' space='1'>
                <HStack w='28px'>
                  <XIcon variant='active' name='information-circle-outline' />
                </HStack>

                <XText>ดูติดจอง</XText>
              </HStack>
            </MenuOption>
          ) : null}

          {isMockToHide ? (
            <MenuOption onSelect={() => p.op.alert('Just Alert')}>
              <XText variant='primary'>Just Alert</XText>
            </MenuOption>
          ) : null}

          <MenuOption onSelect={() => p.op.alert('Just Alert')} disabled text='Test Disabled' />
        </MenuOptions>
      </Menu>
    )
  }

  _renderMain = () => {
    const { data, sellerStoreId, isPullProductToOrderMode } = this.props
    const { n, v: vs, t } = data
    const v = vs[0]
    // const productName = n || ''
    // const variantName = v.n || ''
    // // const priceText = v.p ? v.p : v.s[0].p
    // const sku = v.sku || null
    // const upc = v.upc || null
    // let imgUri = t[0]
    // if (vs[0].img_url) {
    //   imgUri = vs[0].img_url
    // }

    const isQtyNotAvailable = _.isNil(v.q)
    const currentQty = isQtyNotAvailable ? 0 : v.q

    const currentAvailableQty = _.isNil(v.a) ? 0 : v.a
    const currentReservedQty = Math.abs(currentQty - currentAvailableQty)

    const haveCost = !_.isNil(v.c) && v.c
    const currentCost = haveCost ? v.c : 0
    const havePulledProductCost = !_.isNil(v.cost_pulled_amount) && v.cost_pulled_amount
    const currentPulledProductCost = havePulledProductCost ? v.cost_pulled_amount : 0
    const havePrice = !_.isNil(v.s) && _.isArray(v.s) && v.s.length > 0 && v.s[0].p
    const currentPrice = havePrice ? v.s[0].p : 0

    const isPulledProduct = this._isPulledProduct()
    const isSellerStoreProduct = !_.isNil(sellerStoreId)

    const isQtyViewable = this._isPermProductQtyViewable()
    const isCostViewable = this._isPermProductCostViewable()
    const isPriceViewable = this._isPermProductPriceViewable()

    const isQtyEditable = !isSellerStoreProduct && !isPulledProduct && this._isPermProductQtyEditable()
    const isCostEditable = !isSellerStoreProduct && !isPulledProduct && this._isPermProductCostEditable()
    const isPriceEditable = !isSellerStoreProduct && this._isPermProductPriceEditable()

    const isFocusOnAvailableQty = isPullProductToOrderMode || isQtyNotAvailable || isPulledProduct

    return (
      <VStack w='full' px='2' py='1.5' space='1' bg='white' borderRadius='lg'>
        <HStack w='full' space='1'>
          {/* <XImage source={{ uri: imgUri }} h='64px' w='64px' borderRadius='lg' /> */}
          {/* <VStack pl='1' flex={1}>
            <XText variant='inactive' numberOfLines={2}>
              {productName}
            </XText>

            <XText numberOfLines={2}>{variantName}</XText>
            {sku ? <XText variant='inactive' fontSize='xs' numberOfLines={1}>{`SKU: ${sku}`}</XText> : null}
            {upc ? <XText variant='inactive' fontSize='xs' numberOfLines={1}>{`UPC: ${upc}`}</XText> : null}
          </VStack> */}
          {this._renderProductVariantImage()}
          {this._renderProductVariantInfo()}

          <TouchableOpacity onPress={this._navToProductDetail}>
            <VStack alignItems='center'>
              <XIcon name='information-circle-outline' />
              <XText variant='primary' fontSize='3xs'>
                ดูสินค้า
              </XText>
            </VStack>
          </TouchableOpacity>

          {this._renderPopupButton()}
        </HStack>

        <VStack w='full' space='1'>
          {isFocusOnAvailableQty && isQtyViewable ? (
            <HStack w='full' alignItems='center' space='1'>
              <HStack alignItems='center'>
                <XText bold color={currentAvailableQty >= 0 ? 'gray.600' : 'red.600'}>
                  {p.op.t('available_qty')} {currentAvailableQty}
                </XText>
              </HStack>

              {!isPulledProduct && currentReservedQty && currentReservedQty > 0 ? (
                <HStack alignItems='center'>
                  <XText>(</XText>
                  <XText color='blue.600'>
                    {p.op.t('qty')} {currentQty}
                  </XText>

                  <XText> - </XText>

                  <XText color='yellow.700'>ติดจอง {currentReservedQty}</XText>

                  <XText>)</XText>
                </HStack>
              ) : null}
            </HStack>
          ) : (
            <HStack w='full' alignItems='center' space='1'>
              <HStack alignItems='center'>
                <XText
                  color='blue.600'
                  bold={isQtyEditable}
                  underline={isQtyEditable}
                  onPress={isQtyEditable ? this._openEditQtyOverlay : undefined}>
                  {p.op.t('qty')} {currentQty}
                </XText>
              </HStack>

              {currentReservedQty && currentReservedQty > 0 ? (
                <HStack alignItems='center'>
                  <XText>(</XText>
                  <XText color={currentAvailableQty >= 0 ? 'gray.600' : 'red.600'}>
                    {p.op.t('available_qty')} {currentAvailableQty}
                  </XText>

                  <XText> + </XText>

                  <XText color='yellow.700' bold underline onPress={this._navToReservedProductsOrderListView}>
                    ติดจอง {currentReservedQty}
                  </XText>

                  <XText>)</XText>
                </HStack>
              ) : null}
            </HStack>
          )}

          {(haveCost && isCostViewable) || (havePulledProductCost && isCostViewable) || (havePrice && isPriceViewable) ? (
            <HStack w='full' space='2' flexWrap='wrap'>
              {haveCost && (
                <XText
                  w='150px'
                  color='red.400'
                  bold={isCostEditable}
                  underline={isCostEditable}
                  onPress={isCostEditable ? this._openEditCostOverlay : undefined}>
                  ต้นทุน {fmt.formatCurrency(currentCost)}
                </XText>
              )}

              {havePulledProductCost && (
                <XText w='150px' color='red.400'>
                  ต้นทุน {fmt.formatCurrency(currentPulledProductCost)}
                </XText>
              )}

              {havePrice && (
                <VStack flex={1} flexWrap='wrap'>
                  <HStack w='full' space='1.5' flexWrap='wrap'>
                    <VStack>
                      <XText
                        color='gray.600'
                        // w='100px'
                        numberOfLines={1}>
                        {this._getPriceName()}
                      </XText>
                    </VStack>

                    <XText
                      color='gray.700'
                      // w='150px'
                      // textAlign='right'
                      bold={isPriceEditable}
                      underline={isPriceEditable}
                      onPress={isPriceEditable ? this._openEditPriceOverlay : undefined}>
                      {fmt.formatCurrency(currentPrice)}
                    </XText>
                  </HStack>
                </VStack>
              )}
            </HStack>
          ) : null}
        </VStack>

        {this._renderDatetimeInfo()}
        {this._renderCreatedByMkpChId()}

        {this._renderMkpChannelBar()}

        <HStack w='full' alignItems='flex-end' space='1'>
          {this._renderNumberOfItem()}
          {this._renderPulledProductIcon()}
          {this._renderProductVariantId()}
          {this._renderPressItemButton()}
        </HStack>

        {this._renderEditQtyOverlay()}
        {this._renderEditCostOverlay()}
        {this._renderEditPriceOverlay()}
      </VStack>
    )
  }

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    // if (this._isItemPressable()) {
    //   return <TouchableOpacity onPress={this._onPressItem}>{this._renderMain()}</TouchableOpacity>
    // }

    return this._renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    sellerStores: getSellerStores(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(SearchProductVariantListItem) as React.FC<
  Omit<ISearchProductVariantListItemProps, 'selectedStore' | 'sellerStores' | 'updateSelectedStoreByKey'>
>
