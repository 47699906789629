import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
import { Image, VStack } from 'native-base'
import _ from 'lodash'
import dayjs from 'dayjs'

// import { getSelectedStoreErpChannels, getSelectedStoreOrdersDates, getSelectedStoreOrdersDatesOptionKey } from 'x/redux/selectors'
// import * as StoreState from 'x/modules/store/StoreState'

import {
  IERPChannel,
  IErpDocTypeOption,
  IMKPChannelDetail,
  IMap,
  IOrdersFilter,
  IXDateRangeSelectorChangeParams,
  IOrderListFilterProps,
  IOrderListFilterState,
  IOrdersSortType,
} from 'x/index'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import { COLORS } from 'x/config/styles'

import { diff } from 'deep-object-diff'
import HStack from 'xui/components/HStack'
import { Dimensions, TouchableOpacity } from 'react-native'
import XText from '../XText'
import XIcon from '../XIcon'
import Segment from '../Segment'
import XCheckButton from '../XCheckButton'
import ShippingTypeSelectorSegmentView from '../ShippingTypeSelectorSegmentView'
import XDateRangeSelectorButton from '../XDateRangeSelectorButton'
import ErpLogo from '../ErpLogo'
import MkpLogo from '../MkpLogo'
import Box from '../Box'
import XDataProtectionPolicyShopee from '../XDataProtectionPolicyShopee'
import XSegment from '../XSegment'
import XRadioList from '../XRadioList'
// import Grid from './Grid'
// import Col from './Col'
// import Row from './Row'

const imgIconCod = require('../../images/cod.png')

const IS_SMALL_PHONE = !p.op.isWeb() && Dimensions.get('window').width < 500
const OrderChannelItems = CONS.ORDER_VIEW_CHANNEL_ITEMS.map((item) => ({
  key: item.index,
  id: item.value,
  ...item,
  label: p.op.t(`Order.channelItems.${item.key}`),
}))

// -----------------------------------------------------
interface IFilterSectionProps {
  visible?: boolean
  collapseKey: string
  title: string

  collapseState: { [key: string]: boolean }
  onCollapseStateChange: (newCollapseState: { [key: string]: boolean }) => void
}

const FilterSection: React.FC<IFilterSectionProps> = ({
  visible = true,
  collapseKey,
  title,
  children,
  collapseState = {},
  onCollapseStateChange,
}) => {
  // const [isCollapsed, setIsCollapsed] = React.useState(false)
  const isCollapsed = collapseState && _.isBoolean(collapseState[collapseKey]) ? collapseState[collapseKey] : false

  const toggleCollapsed = () => {
    // setIsCollapsed(!isCollapsed)
    const newCollapseState = { ...collapseState }
    newCollapseState[collapseKey] = !isCollapsed
    onCollapseStateChange(newCollapseState)
  }

  // React.useEffect(() => {
  //   let isMounted = true

  //   if (isMounted) {
  //     setIsCollapsed(key === 'sort')
  //   }

  //   return () => {
  //     isMounted = false
  //   }
  // }, [])

  return (
    <VStack
      w='full'
      borderRadius='lg'
      borderWidth='1'
      borderTopColor='gray.400'
      borderRightColor='gray.500'
      borderBottomColor='gray.500'
      borderLeftColor='gray.400'
      overflow='hidden'
      style={visible ? {} : { display: 'none' }}>
      <TouchableOpacity onPress={toggleCollapsed}>
        <HStack w='full' px='1.5' py='2' borderBottomWidth='1' borderBottomColor='gray.400' alignItems='center' space='2' bg='gray.100'>
          <XText flex={1} variant='inactive' bold>
            {title}
          </XText>

          <HStack w='38px' justifyContent='flex-end' alignItems='center'>
            <XIcon
              variant='inactive'
              size='xs'
              name={isCollapsed ? 'arrow-expand-down' : 'arrow-collapse-up'}
              family='MaterialCommunityIcons'
            />
          </HStack>
        </HStack>
      </TouchableOpacity>

      <VStack w='full' p='1' pl='2' style={isCollapsed ? { display: 'none' } : {}} space='1'>
        {children}
      </VStack>
    </VStack>
  )
}

// -----------------------------------------------------

class OrderListFilter extends Component<IOrderListFilterProps, IOrderListFilterState> {
  static displayName = 'OrderListFilter'

  inProcess?: boolean

  // // drawerRef?: React.RefObject<typeof Drawer>

  // // OPTS_SECTION_TITLE: {
  // //   isCOD: string
  // //   isShipBeforePay: string
  // // }
  // baseBasicOrderFilterRef: any

  // // OPTS_METADATA: ISectionOptionMetadata[]

  // static defaultProps = {
  //   headerTitle: 'คัดกรองการแสดงผลออเดอร์',
  // }

  // static getDerivedStateFromProps(props: IOrderListFilterProps, state: IOrderListFilterState) {
  //   // ถ้าไม่มี hasPrintedAt ให้ reset ค่าของช่วงเวลาต่างๆ "ที่เลือกเอาไว้" ออก
  //   if (!state.setting.hasPrintedAt) {
  //     if (state.setting.segmentHasPrintedAtIndex) {
  //       const newSetting = _.cloneDeep(state.setting)
  //       newSetting.segmentHasPrintedAtIndex = 0
  //       delete newSetting.printedAtFrom
  //       delete newSetting.printedAtTo
  //       return {
  //         setting: newSetting,
  //       }
  //     }
  //   }

  //   return null
  // }

  // constructor(props) {
  //   super(props)
  //   // this.drawerRef = React.createRef()
  //   this.baseBasicOrderFilterRef = React.createRef()

  //   const { currentSetting = {}, defaultFilterSetting = {} } = props
  //   const setting = this.translateRequestBodyToFilterState({ ...defaultFilterSetting, ...currentSetting })
  //   const loadedChannels = this._getActiveMkpChannels()

  //   this.state = {
  //     setting,
  //     activeChannels: _.isArray(loadedChannels) ? loadedChannels : [],
  //     // callBackFromBaseBasicOrderFilter: null,
  //     callBackFromBaseBasicOrderFilter: null,
  //     isStartSetFilter: false,
  //     resetShippingTypeSelectorSegmentView: false,
  //   }
  // }

  // async componentDidMount() {
  //   // const { forceMkpOnly, forceXSellyOnly } = this.props
  //   // if (this.props.forceMkpOnly) {
  //   await this._onResetFilter()
  //   await delay(400)
  //   this._onApplyFilter()
  //   // }
  // }

  _getActiveMkpChannels = (): IMKPChannelDetail[] => {
    const chs = util.getMKPChannels() || []
    const activeChs = chs.filter(util.filterMkpChannelIsActive)
    // Set active By default
    activeChs.forEach((ch, idx) => {
      activeChs[idx].is_active = true
    })
    return activeChs
  }

  // close = async () => {
  //   const { currentSetting: propsSetting = {} } = this.props
  //   if (propsSetting) {
  //     const { setting: stateSetting } = this.state
  //     const diffSetting = diff(propsSetting, this.translateFilterStateToRequestBody(stateSetting))
  //     if (!_.isEmpty(diffSetting)) {
  //       const setting = { ...this._getDefaultSetting(), ...this.translateRequestBodyToFilterState(propsSetting) }
  //       await setStatePromise(this, { setting })
  //     }
  //   }

  //   this.closeFilterOverlay()

  //   const { onCloseFilter } = this.props
  //   if (_.isFunction(onCloseFilter)) {
  //     onCloseFilter()
  //   }
  // }

  // translateFilterStateToRequestBody = (filterSetting: IOrdersFilterSetting): Partial<IFetchOrdersRequestBody> => {
  //   const body: Partial<IFetchOrdersRequestBody> = {}

  //   if (_.isBoolean(filterSetting.isCOD)) {
  //     body.isCOD = filterSetting.isCOD
  //   }
  //   if (_.isBoolean(filterSetting.isShipBeforePay)) {
  //     body.isShipBeforePay = filterSetting.isShipBeforePay
  //   }
  //   if (_.isBoolean(filterSetting.hasPrintedAt)) {
  //     const { hasPrintedAt } = filterSetting
  //     // const segmentHasPrintedAtIndex = filterSetting.segmentHasPrintedAtIndex || 0

  //     // เริ่ม apply filter has_printed_at
  //     body.has_printed_at = hasPrintedAt

  //     // ถ้าหากมีการกรองที่ระบุเวลาเกิดขึ้น
  //     if (hasPrintedAt) {
  //       const { printedAtFrom } = filterSetting
  //       const { printedAtTo } = filterSetting
  //       if (dayjs.isdayjs(printedAtFrom) && dayjs.isdayjs(printedAtTo)) {
  //         // apply filter by datetime
  //         body.printed_at_from = printedAtFrom.format(CONS.SERVER_DATETIME_FORMAT)
  //         body.printed_at_to = printedAtTo.format(CONS.SERVER_DATETIME_FORMAT)
  //       }
  //     }
  //   }

  //   if (_.isString(filterSetting.orderSegment)) {
  //     body.order_segment = filterSetting.orderSegment
  //   }

  //   // สำหรับ mkp channels
  //   if (_.has(filterSetting, 'mkpChannelIds') && !_.isNil(filterSetting.mkpChannelIds)) {
  //     body.ug_ids = filterSetting.mkpChannelIds
  //   }
  //   if (_.has(filterSetting, 'orderOrigin') && _.isString(filterSetting.orderOrigin)) {
  //     body.order_origin = filterSetting.orderOrigin
  //   }

  //   // create order
  //   if (!_.isNil(filterSetting.createOrderFrom) && !_.isNil(filterSetting.createOrderTo)) {
  //     body.created_at_from = filterSetting.createOrderFrom.format(CONS.SERVER_DATETIME_FORMAT)
  //     body.created_at_to = filterSetting.createOrderTo.format(CONS.SERVER_DATETIME_FORMAT)
  //   }

  //   // Selector Shipping Type
  //   // const selectorShippingTypeIds = _.isNil(filterSetting.selectorShippingTypeIds) ? null : filterSetting.selectorShippingTypeIds.toJS()
  //   if (
  //     !_.isNil(filterSetting.selectorShippingTypeIds) &&
  //     _.isArray(filterSetting.selectorShippingTypeIds) &&
  //     filterSetting.selectorShippingTypeIds.length > 0
  //   ) {
  //     body.shipping_type_ids = filterSetting.selectorShippingTypeIds
  //   }

  //   // FILTER CREATE BODY
  //   // สภานะพร้อมส่ง
  //   // console.log('ABC => ', filterSetting.hasReadyToShipDate)
  //   if (filterSetting.hasReadyToShipDate === false) {
  //     body.has_ready_to_ship_date = false
  //   } else if (filterSetting.hasReadyToShipDate) {
  //     const segmentReadyToShipForDateTimeIndex = _.isNil(filterSetting.segmentReadyToShipForDateTimeIndex)
  //       ? 0
  //       : filterSetting.segmentReadyToShipForDateTimeIndex
  //     if (segmentReadyToShipForDateTimeIndex === 0) {
  //       body.has_ready_to_ship_date = true
  //     } else {
  //       body.ready_to_ship_date_from = filterSetting.readyToShipDateFrom.format(CONS.SERVER_DATETIME_FORMAT)
  //       body.ready_to_ship_date_to = filterSetting.readyToShipDateTo.format(CONS.SERVER_DATETIME_FORMAT)
  //     }
  //   }
  //   // กำหนดส่ง
  //   if (filterSetting.hasDateDelivery === false) {
  //     body.has_date_delivery = false
  //   } else if (filterSetting.hasDateDelivery) {
  //     const segmentDateDeliveryIndex = _.isNil(filterSetting.segmentDateDeliveryIndex) ? 0 : filterSetting.segmentDateDeliveryIndex
  //     if (segmentDateDeliveryIndex === 0) {
  //       body.has_date_delivery = true
  //     } else {
  //       body.date_delivery_from = filterSetting.dateDeliveryFrom.format(CONS.SERVER_DATETIME_FORMAT)
  //       body.date_delivery_to = filterSetting.dateDeliveryTo.format(CONS.SERVER_DATETIME_FORMAT)
  //     }
  //   }
  //   //  ยกเลิกออเดอร์อัตโนมัติ
  //   if (filterSetting.hasExpirationDate === false) {
  //     body.has_expiration_date = false
  //   } else if (filterSetting.hasExpirationDate) {
  //     const segmentAutoCancelOrderIndex = _.isNil(filterSetting.segmentAutoCancelOrderIndex) ? 0 : filterSetting.segmentAutoCancelOrderIndex
  //     if (segmentAutoCancelOrderIndex === 0) {
  //       body.has_expiration_date = true
  //     } else {
  //       body.expiration_date_from = filterSetting.AutoCancelOrderFrom.format(CONS.SERVER_DATETIME_FORMAT)
  //       body.expiration_date_to = filterSetting.AutoCancelOrderTo.format(CONS.SERVER_DATETIME_FORMAT)
  //     }
  //   }

  //   return body
  // }

  // translateRequestBodyToFilterState = (req: Partial<IFetchOrdersRequestBody>): IOrdersFilterSetting => {
  //   const filterSetting: Partial<IOrdersFilterSetting> = {}

  //   if (_.isBoolean(req.isCOD)) {
  //     filterSetting.isCOD = req.isCOD
  //   }

  //   if (_.isBoolean(req.isShipBeforePay)) {
  //     filterSetting.isShipBeforePay = req.isShipBeforePay
  //   }

  //   if (_.isBoolean(req.has_printed_at)) {
  //     filterSetting.hasPrintedAt = req.has_printed_at

  //     if (req.has_printed_at) {
  //       const { printed_at_from, printed_at_to } = req

  //       if (printed_at_from && printed_at_to) {
  //         filterSetting.printedAtFrom = dayjs(printed_at_from)
  //         filterSetting.printedAtTo = dayjs(printed_at_to)
  //       }
  //     }
  //   }

  //   if (_.isString(req.order_segment)) {
  //     filterSetting.orderSegment = req.order_segment
  //   }

  //   // สำหรับ mkp channels
  //   if (_.has(req, 'ug_ids') && !_.isNil(req.ug_ids)) {
  //     filterSetting.mkpChannelIds = [...req.ug_ids]
  //   }

  //   if (_.has(req, 'order_origin') && _.isString(req.order_origin)) {
  //     filterSetting.orderOrigin = req.order_origin
  //   }

  //   // create order
  //   if (_.isString(req.created_at_from) && _.isString(req.created_at_to)) {
  //     filterSetting.createOrderFrom = dayjs(req.created_at_from)
  //     filterSetting.createOrderTo = dayjs(req.created_at_to)
  //   }

  //   // Selector Shipping Type
  //   if (_.isArray(req.shipping_type_ids) && req.shipping_type_ids.length > 0) {
  //     filterSetting.selectorShippingTypeIds = [...req.shipping_type_ids]
  //   }

  //   // FILTER CREATE BODY
  //   // สภานะพร้อมส่ง
  //   if (req.has_ready_to_ship_date) {
  //     const { ready_to_ship_date_from, ready_to_ship_date_to } = req
  //     if (_.isString(ready_to_ship_date_from) && _.isString(ready_to_ship_date_to)) {
  //       filterSetting.segmentReadyToShipForDateTimeIndex = 1
  //       filterSetting.readyToShipDateFrom = dayjs(ready_to_ship_date_from)
  //       filterSetting.readyToShipDateTo = dayjs(ready_to_ship_date_to)
  //     } else {
  //       filterSetting.segmentReadyToShipForDateTimeIndex = 0
  //     }
  //   }

  //   // กำหนดส่ง
  //   if (req.has_date_delivery) {
  //     const { date_delivery_from, date_delivery_to } = req
  //     if (_.isString(date_delivery_from) && _.isString(date_delivery_to)) {
  //       filterSetting.segmentDateDeliveryIndex = 1
  //       filterSetting.dateDeliveryFrom = dayjs(date_delivery_from)
  //       filterSetting.dateDeliveryTo = dayjs(date_delivery_to)
  //     } else {
  //       filterSetting.segmentDateDeliveryIndex = 0
  //     }
  //   }

  //   //  ยกเลิกออเดอร์อัตโนมัติ
  //   if (req.has_expiration_date) {
  //     const { expiration_date_from, expiration_date_to } = req
  //     if (_.isString(expiration_date_from) && _.isString(expiration_date_to)) {
  //       filterSetting.segmentAutoCancelOrderIndex = 1
  //       filterSetting.AutoCancelOrderFrom = dayjs(expiration_date_from)
  //       filterSetting.AutoCancelOrderTo = dayjs(expiration_date_to)
  //     } else {
  //       filterSetting.segmentAutoCancelOrderIndex = 0
  //     }
  //   }

  //   return filterSetting as IOrdersFilterSetting
  // }

  // open = async () => {
  //   // const setting = Map.isMap(currentSetting) ? currentSetting.toJS() : currentSetting

  //   // @ts-ignore FIXME: @O type ไม่ถูกต้อง
  //   // await setStatePromise(this, { setting })

  //   const { currentSetting: propsSetting } = this.props
  //   if (propsSetting && !_.isEmpty(propsSetting)) {
  //     const { setting: stateSetting } = this.state
  //     const diffSetting = diff(propsSetting, this.translateFilterStateToRequestBody(stateSetting))
  //     if (!_.isEmpty(diffSetting)) {
  //       const setting = { ...this._getDefaultSetting(), ...this.translateRequestBodyToFilterState(propsSetting) }
  //       await setStatePromise(this, { setting })
  //     }
  //   }

  //   this.openFilterOverlay()

  //   // ต้องเซตตัวนี้เพื่อนให้เมื่อเข้าหน้า order list แล้ว ไม่ต้องยิง api ก่อน
  //   util.setStatePromise(this, {
  //     isStartSetFilter: true,
  //   })
  // }

  // _renderHeader = () => <XCustomHeader title={this.props.headerTitle} headerRightProps={{ closeIcon: true, onPressItem: this.close }} />

  // // _onPressCheckbox = ({ name }) => {
  // //   const oldValue = this.state[name]
  // //   const newCheckboxValue = {}
  // //   newCheckboxValue[name] = !oldValue
  // //   this.setState(newCheckboxValue)
  // // }

  // // if index > 800 is mean customize we will not provided contaiener and underline
  // // _renderOptionByMetadata = (metadata: ISectionOptionMetadata, index: number) => {
  // //   const { title, icon, option } = metadata

  // //   const renderMetaDataContent = () => (
  // //     <>
  // //       <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
  // //         {this._renderTitleIcon(icon)}
  // //         <XText style={s.TXT_SECTION_TITLE}>{title}</XText>
  // //       </View>
  // //       <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>{option.map(this._renderCheckButton)}</View>
  // //       {/*<Row>{this._renderOptionByType(key, option, optionType)}</Row>*/}
  // //     </>
  // //   )

  // //   if (index > 800) {
  // //     return renderMetaDataContent()
  // //   }

  // //   return (
  // //     <View key={`opt_${index}`} style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 6 }}>
  // //       {renderMetaDataContent()}
  // //       {this._renderMetadataOptionUnderline()}
  // //     </View>
  // //   )
  // // }

  // // _renderMetadataOptionUnderline = () => (
  // //   <View style={{ marginTop: 8, height: 1, width: '100%', borderTopWidth: 0.5, borderTopColor: COLORS.TEXT_INACTIVE }} />
  // // )

  // // _renderTitleIcon = (icon) => {
  // //   const { src, name, type, iconStyle = {} } = icon
  // //   if (src) {
  // //     return <Image source={src} resizeMode={'contain'} style={[s.ICON_IMG_TITLE, iconStyle]} />
  // //   }
  // //   if (name) {
  // //     return <Icon name={name} type={type} style={[s.ICON_TITLE, iconStyle]} />
  // //   }
  // //   return null
  // // }

  // // _renderOptionByType(keyName: string, option: IOptionMetadata[], optionType: 'only-one-check-button' | 'multiple-check-button') {
  // //   switch (optionType) {
  // //     case 'only-one-check-button':
  // //       return option.map(this._renderOnlyOneCheckButton)
  // //     // case 'multiple-check-button':
  // //     //   return option.map(this._renderMultipleCheckButton)
  // //     default:
  // //       return null
  // //   }
  // // }

  // _getDefaultSetting = () => {
  //   const { forceMkpOnly, forceXSellyOnly, defaultFilterSetting = {} } = this.props
  //   // @ts-ignore
  //   // const defaultSetting: IOrdersFilterSetting = { ...defaultFilterSetting }
  //   const defaultSetting: IOrdersFilterSetting = this.translateRequestBodyToFilterState(defaultFilterSetting)

  //   if (forceMkpOnly) {
  //     defaultSetting.segmentOrderOriginIndex = 1
  //     defaultSetting.orderOrigin = 'mkp_only'
  //   }

  //   if (forceXSellyOnly) {
  //     defaultSetting.segmentOrderOriginIndex = 0
  //     defaultSetting.orderOrigin = 'xselly_only'
  //   }

  //   // defaultSetting.selectorShippingTypeIds = null
  //   // const newDate = dayjs().format(CONS.SERVER_DATE_FORMAT)
  //   // defaultSetting.createOrderFrom = dayjs(newDate)
  //   // defaultSetting.createOrderTo = dayjs(newDate)
  //   return defaultSetting
  // }

  // _isForceMkpOnly = () => this.state.setting.orderOrigin === 'mkp_only'

  // _isForceXSellyOnly = () => this.state.setting.orderOrigin === 'xselly_only'

  // _onResetFilter = async () => {
  //   const { onApplyFilter } = this.props
  //   // const { currentSetting = {} } = this.props
  //   await setStatePromise(this, {
  //     setting: this._getDefaultSetting(),
  //     activeChannels: this._getActiveMkpChannels(),
  //     resetShippingTypeSelectorSegmentView: true,
  //   })
  //   // set ให้รูปแบบจัดส่งกลับมาแสดง
  //   await setStatePromise(this, {
  //     resetShippingTypeSelectorSegmentView: false,
  //   })
  //   // console.log('Drawer _onResetFilter this.state.setting => ', this.state.setting)
  //   await delay(100)

  //   // console.log('this.baseBasicOrderFilterRef ** ', this.baseBasicOrderFilterRef)
  //   if (!_.isNil(this.baseBasicOrderFilterRef) && !_.isNil(this.baseBasicOrderFilterRef.current)) {
  //     this.baseBasicOrderFilterRef.current.resetToDefaultSetting()
  //   }

  //   // if (!preventCloseAfterReset) {
  //   //   onApplyFilter(this.translateFilterStateToRequestBody(this._getDefaultSetting()))
  //   //   this.close()
  //   // }
  // }

  // _onApplyFilter = () => {
  //   const { onApplyFilter, preventCloseAfterApply = false } = this.props
  //   const { setting } = this.state

  //   onApplyFilter(this.translateFilterStateToRequestBody(setting))

  //   if (!preventCloseAfterApply) {
  //     this.close()
  //   }
  // }

  // _renderFooter = () => {
  //   if (this.props.readonly) {
  //     return null
  //   }

  //   return (
  //     <HStack w='full' p='1' bg='gray.200' space='1' alignItems='center' justifyContent='center'>
  //       <XButton flex={1} variant='outline' onPress={this._onResetFilter}>
  //         ล้าง
  //       </XButton>
  //       <XButton flex={2} onPress={this._onApplyFilter}>
  //         กรอง
  //       </XButton>
  //     </HStack>
  //   )
  // }

  // // _setSegmentHasPrintedAtIndex = async (newIndex: number) => {
  // //   const newSetting = _.cloneDeep(this.state.setting)
  // //   newSetting.segmentHasPrintedAtIndex = newIndex

  // //   // set default ของช่วงเวลาพิมพ์ใบปะหน้า
  // //   switch (newIndex) {
  // //     case 0: // ทั้งหมด
  // //       newSetting.printedAtFrom = null
  // //       newSetting.printedAtTo = null
  // //       break
  // //     case 1: // 8 ชม
  // //       newSetting.printedAtFrom = dayjs().subtract(5, 'minutes')
  // //       newSetting.printedAtTo = dayjs()
  // //       break
  // //     case 2: // 1 วัน
  // //       newSetting.printedAtFrom = dayjs().subtract(12, 'hours')
  // //       newSetting.printedAtTo = dayjs()
  // //       break
  // //     case 3: // ระบุเอง
  // //       newSetting.printedAtFrom = dayjs().startOf('day')
  // //       newSetting.printedAtTo = dayjs().endOf('day')
  // //       break
  // //   }

  // //   await setStatePromise(this, { setting: newSetting })
  // // }

  // // _setPrintedAtFromTo = async (newDateRange: IDateRange) => {
  // //   const newSetting = _.cloneDeep(this.state.setting)
  // //   newSetting.printedAtFrom = newDateRange.begin
  // //   newSetting.printedAtTo = newDateRange.end
  // //   await setStatePromise(this, { setting: newSetting })
  // // }

  // // _renderFilterHasPrintedAtRange = () => {
  // //   const { setting = this._getDefaultSetting() } = this.state
  // //   const hasPrintedAt = setting.hasPrintedAt || null
  // //   const segmentHasPrintedAtIndex = setting.segmentHasPrintedAtIndex || 0
  // //   const printedAtFrom = setting.printedAtFrom || dayjs().startOf('day')
  // //   const printedAtTo = setting.printedAtTo || dayjs().endOf('day')
  // //   return (
  // //     <View style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 6 }}>
  // //       {this._renderOptionByMetadata(
  // //         {
  // //           title: 'ใบปะหน้าพัสดุ',
  // //           icon: {
  // //             name: 'printer',
  // //             type: 'MaterialCommunityIcons',
  // //           },
  // //           option: [
  // //             {
  // //               key: 'hasPrintedAt',
  // //               label: 'พิมพ์ไปแล้ว',
  // //               value: true,
  // //             },
  // //             {
  // //               key: 'hasPrintedAt',
  // //               label: 'ยังไม่ได้พิมพ์',
  // //               value: false,
  // //             },
  // //           ],
  // //         },
  // //         // just mock customize index number
  // //         801
  // //       )}
  // //       {/* <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
  // //         <XIcon inactive={true} name={'printer'} family={'MaterialCommunityIcons'} style={s.ICON_TITLE} />
  // //         <XText style={s.TXT_SECTION_TITLE}>{'สถานะใบปะหน้าพัสดุ'}</XText>
  // //       </View> */}

  // //       {/* Separator */}
  // //       <View style={{ height: 8, width: '100%' }} />

  // //       {/* <View style={{ minHeight: 41, width: '100%' }}> */}
  // //       {hasPrintedAt ? (
  // //         <>
  // //           <XText style={S.TEXT_INACTIVE}>{'ภายใน'}</XText>
  // //           <Segment
  // //             selectedIndex={segmentHasPrintedAtIndex}
  // //             options={['ทั้งหมด', '5 นาที', '12 ชั่วโมง', 'ระบุเวลา']}
  // //             onSegmentChange={this._setSegmentHasPrintedAtIndex}
  // //           />
  // //         </>
  // //       ) : null}
  // //       {/* </View> */}
  // //       {/* Separator */}
  // //       <View style={{ height: 8, width: '100%' }} />

  // //       {/* ถ้าเลือกระบุเวลา */}
  // //       {/* <View style={{ minHeight: 60, width: '100%' }}> */}
  // //       {segmentHasPrintedAtIndex === 3 ? (
  // //         <XDateRangePicker
  // //           // allowOnlyDaysFromToday={31}
  // //           selectedDates={{ begin: printedAtFrom, end: printedAtTo }}
  // //           onChangeDate={this._setPrintedAtFromTo}
  // //           showTime={true}
  // //         />
  // //       ) : null}
  // //       {/* </View> */}

  // //       {this._renderMetadataOptionUnderline()}
  // //     </View>
  // //   )
  // // }

  // _setSegmentOrderOriginAtIndex = async (newIndex: number) => {
  //   // console.log('_setSegmentOrderOriginAtIndex newIndex => ', newIndex)
  //   const newSetting = _.cloneDeep(this.state.setting)
  //   newSetting.segmentOrderOriginIndex = newIndex

  //   const { activeChannels } = this.state

  //   // set default ของช่วงเวลาพิมพ์ใบปะหน้า
  //   switch (newIndex) {
  //     case 0: // XSelly
  //       newSetting.orderOrigin = 'xselly_only'
  //       await this._callBackFromMKPChannelsPicker([])
  //       break
  //     case 1: // ช่องทางขาย
  //       newSetting.orderOrigin = 'mkp_only'
  //       if (activeChannels.length >= 1) {
  //         await this._callBackFromMKPChannelsPicker(activeChannels)
  //       }
  //       break
  //     case 2: // ทั้งหมด
  //       delete newSetting.orderOrigin
  //       await this._callBackFromMKPChannelsPicker([])
  //       break
  //   }

  //   await setStatePromise(this, { setting: newSetting })
  // }

  // _renderFilterByMkpChannels = () => {
  //   const { forceMkpOnly, forceXSellyOnly } = this.props
  //   const { setting = this._getDefaultSetting(), activeChannels } = this.state
  //   if (!isUseMKPChannels()) {
  //     return null
  //   }

  //   // const activeChannelSize = activeChannels.length

  //   const segmentOrderOriginIndex = _.isNumber(setting.segmentOrderOriginIndex) ? setting.segmentOrderOriginIndex : 2

  //   return (
  //     <>
  //       {forceMkpOnly || forceXSellyOnly ? null : (
  //         <VStack w='full'>
  //           <XText variant='inactive'>เฉพาะออเดอร์ในระบบ...</XText>
  //           <Segment
  //             selectedIndex={segmentOrderOriginIndex}
  //             options={['XSelly', 'ช่องทางขาย', 'ทั้งหมด']}
  //             onSegmentChange={this._setSegmentOrderOriginAtIndex}
  //           />
  //         </VStack>
  //       )}
  //       {segmentOrderOriginIndex === 1 ? this._renderMkpChannelSelector() : null}
  //       {/* {segmentOrderOriginIndex === 1 && activeChannelSize > 1 ? this._renderMkpChannelSelector() : null} */}
  //     </>
  //   )
  // }

  // _renderMkpChannelSelector = () => (
  //   <MKPChannelsActive channels={this.state.activeChannels} onPressChannel={this._callBackFromMKPChannelsPicker} />
  // )

  _onMkpChannelSelectorItemPress = async (ch: IMKPChannelDetail, index: number) => {
    const newSetting = this._getNewSetting()

    const { mkpChannelActiveMap: oneOne = {} } = newSetting
    const mkpChannelActiveMap = { ...oneOne }
    const mkpChId = ch.id

    if (_.isNil(mkpChannelActiveMap[mkpChId])) {
      mkpChannelActiveMap[mkpChId] = true
    } else if (_.isBoolean(mkpChannelActiveMap[mkpChId])) {
      mkpChannelActiveMap[mkpChId] = !mkpChannelActiveMap[mkpChId]
    }

    newSetting.mkpChannelActiveMap = mkpChannelActiveMap

    let isAllFalse = true
    const mChIds = Object.keys(newSetting.mkpChannelActiveMap).map((mChId) => parseInt(mChId))
    for (let i = 0; i < mChIds.length; i++) {
      const mChId = mChIds[i]
      if (newSetting.mkpChannelActiveMap[mChId]) {
        isAllFalse = false
        break
      }
    }

    if (isAllFalse) {
      return
    }

    await this._updateNewSetting(newSetting)
  }

  _renderMkpChannelSelectorItem = (ch: IMKPChannelDetail, index: number) => {
    const { mkpChannelActiveMap = {} } = this.getSetting()
    const checked = mkpChannelActiveMap[ch.id] || false

    return (
      <HStack key={`mkp-item-${ch.id}_${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onMkpChannelSelectorItemPress(ch, index)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <MkpLogo mkpId={ch.mkp_id} width={24} height={24} />
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {ch.name}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderMkpChannelSelector = () => {
    const mkpChannels = util.getMKPChannels()
    return mkpChannels.map(this._renderMkpChannelSelectorItem)
  }

  // _onChangeBaseBasicOrderFilterView = (newSetting) => {
  //   // เอาของจาก BaseBasicOrderFilterView มาทับ setting ปัจจุบัน
  //   this.setState({ setting: { ...this.state.setting, ...newSetting } })
  // }

  // _renderBaseBasicOrderFilterView = () => {
  //   if (!this.state.isOverlayVisible) {
  //     return null
  //   }
  //   // const { currentSetting, defaultFilterSetting } = this.props
  //   return (
  //     <BaseBasicOrderFilterView
  //       ref={this.baseBasicOrderFilterRef}
  //       // onChange={(setting: IBaseBasicOrdersFilter) => this.callBackFromBaseBasicOrderFilterView(setting)}
  //       // setting={settingForBaseBasicOrderFilter}
  //       onChange={this._onChangeBaseBasicOrderFilterView}
  //       // setting={this.state.setting}
  //       defaultSetting={this.state.setting}
  //     />
  //   )
  // }

  // _renderCustomHeaderShippingTypeSelectorSegment = () => (
  //   <View style={{ width: '100%', flexDirection: 'row' }}>
  //     <XIcon
  //       name='local-shipping'
  //       family='MaterialIcons'
  //       style={{ color: COLORS.TEXT_ACTIVE, flex: 0, minWidth: 25, fontSize: STYLES.FONT_ICON_SMALLER }}
  //     />
  //     <XText variant='inactive'>รูปแบบจัดส่ง</XText>
  //   </View>
  // )

  // _renderShippingTypeSelectorSegmentView = () => {
  //   if (this.state.resetShippingTypeSelectorSegmentView) {
  //     return null
  //   }
  //   let visibleShippingTypes = util.getVisibleShippingTypesIds()
  //   if (_.isNil(visibleShippingTypes) || visibleShippingTypes.length < 1) {
  //     visibleShippingTypes = util.getDefaultVisibleShippingTypesIds()
  //   }
  //   const selectorShippingType = _.isNil(this.state.setting.selectorShippingTypeIds) ? [] : this.state.setting.selectorShippingTypeIds
  //   return (
  //     <ShippingTypeSelectorSegmentView
  //       customHeaderSegment={() => this._renderCustomHeaderShippingTypeSelectorSegment()}
  //       footerLable='ยืนยัน'
  //       headertitle='เลือกรูปแบบจัดส่ง'
  //       activeShippingTypeIds={selectorShippingType}
  //       visibleShippingTypes={visibleShippingTypes}
  //       onSubmit={(selectedShippingTypeIds: number[]) => {
  //         // console.log('selectedShippingTypeIds => ', selectedShippingTypeIds)
  //         const newSetting = _.cloneDeep(this.state.setting)
  //         newSetting.selectorShippingTypeIds = selectedShippingTypeIds
  //         util.setStatePromise(this, {
  //           setting: newSetting,
  //         })
  //       }}
  //     />
  //   )
  // }

  // _renderContent = () => (
  //   <XContent
  //   // _contentContainerStyle={{
  //   //   _dark: NB.U.BG_D_CLEAR,
  //   //   _light: NB.U.BG_L_CLEAR,
  //   // }}
  //   >
  //     <VStack p='2' space='1' flexWrap='wrap' _dark={NB.U.BG_D_CLEAR} _light={NB.U.BG_L_CLEAR}>
  //       {this._renderBaseBasicOrderFilterView()}
  //       {this._renderShippingTypeSelectorSegmentView()}
  //       {this._renderFilterByMkpChannels()}
  //     </VStack>
  //   </XContent>
  // )

  // _callBackFromMKPChannelsPicker = async (channels: IMKPChannelDetail[]) => {
  //   console.log('_callBackFromMKPChannelsPicker channels => ', channels)
  //   // const newSetting = _.cloneDeep(this.state.setting)
  //   // if (!_.isNil(channels)) {
  //   //   const channelsIds = []
  //   //   channels.map((ch: IMKPChannelDetail) => {
  //   //     channelsIds.push(ch.id)
  //   //   })
  //   //   newSetting.mkpChannelIds = channelsIds
  //   // } else {
  //   //   delete newSetting.mkpChannelIds
  //   // }
  //   // await util.setStatePromise(this, { setting: newSetting })
  //   await util.setStatePromise(this, { activeChannels: channels })
  //   const newSetting = this._getNewSetting()
  //   const activeMkpChannelIds = channels.filter(util.filterMkpChannelIsActive).map((ch: IMKPChannelDetail) => ch.id)

  //   if (activeMkpChannelIds.length > 0) {
  //     newSetting.mkpChannelIds = activeMkpChannelIds
  //   } else {
  //     delete newSetting.mkpChannelIds
  //   }

  //   await this._updateNewSetting(newSetting)
  // }

  // _renderMain = () => (
  //   <XContainer>
  //     {this._renderHeader()}
  //     {this._renderContent()}
  //     {this._renderFooter()}
  //   </XContainer>
  // )

  // openFilterOverlay = () => {
  //   this.setState({ isOverlayVisible: true })
  // }

  // closeFilterOverlay = () => {
  //   this.setState({ isOverlayVisible: false })
  // }

  // _isFiltered = () => {
  //   const { setting } = this.state
  //   // const defaultSetting = this._getDefaultSetting()
  //   // const diffSetting = diff(this.translateFilterStateToRequestBody(defaultSetting), this.translateFilterStateToRequestBody(setting))
  //   const defaultSetting = this._getDefaultSetting()
  //   const diffSetting = diff(defaultSetting, setting)
  //   // console.log('_isFiltered diffSetting => ', diffSetting)
  //   return !_.isEmpty(diffSetting)
  // }

  // render() {
  //   const { isOverlayVisible } = this.state
  //   return (
  //     <Box>
  //       {isOverlayVisible ? (
  //         <XOverlay
  //           // webWrapperContainerStyle={{ backgroundColor: 'white' }}
  //           visible
  //           // webNumColumns={1}
  //           onRequestClose={this.close}>
  //           {this._renderMain()}
  //         </XOverlay>
  //       ) : null}

  //       <XIconButton family='MaterialCommunityIcons' name='filter' onPress={this.open} />
  //       {this._isFiltered() ? (
  //         <XIcon
  //           family='MaterialCommunityIcons'
  //           variant='success'
  //           name='check-circle'
  //           position='absolute'
  //           bottom='0'
  //           right='0'
  //           size='12px'
  //         />
  //       ) : null}
  //     </Box>
  //   )
  // }

  // renderFilterOrderCreate = () => {
  //   const { selectedSetting } = this.state
  //   const segmentCreateAtIndex = selectedSetting.segmentCreateAtIndex || 0
  //   const createOrderFrom = selectedSetting.createOrderFrom || dayjs().startOf('day')
  //   const createOrderTo = selectedSetting.createOrderTo || dayjs().endOf('day')
  //   return (
  //     <VStack w='full' py='2' space='1' style={{ backgroundColor: BACKGROUND_COLOR }}>
  //       <HStack alignItems='center' space='1'>
  //         <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' />
  //         <XText variant='inactive'>สร้างภายใน</XText>
  //       </HStack>
  //       <Segment
  //         selectedIndex={segmentCreateAtIndex}
  //         options={['ทุกวัน', 'วันนี้', '7 วัน', 'ระบุเวลา']}
  //         onSegmentChange={this.setSegmentCreateAtIndex}
  //       />
  //       <Box w='full' h='2' />
  //       {segmentCreateAtIndex === 3 ? (
  //         <VStack w='full'>
  //           <XDateRangePicker
  //             // allowOnlyDaysFromToday={31}
  //             selectedDates={{ begin: createOrderFrom, end: createOrderTo }}
  //             onChangeDate={this.setCreateOrderAtFromTo}
  //             showTime
  //             oneLineDateTime={!p.op.isWeb()}
  //           />
  //         </VStack>
  //       ) : null}
  //       {/* {renderMetadataOptionUnderline()} */}
  //     </VStack>
  //   )
  // }

  constructor(props) {
    super(props)

    this.state = {
      // isOverlayVisible: false,
      // isFilterApplied: false,
      isInitialized: false,
      setting: {},
      activeChannels: [],
      collapseState: {
        sort: true,
        order: false,
        payment: false,
        shipment: false,
        erp: false,
      },
    }

    this.inProcess = false
  }

  async componentDidMount(): Promise<void> {
    const activeChannels = this._getActiveMkpChannels() || []
    await util.setStatePromise(this, { activeChannels })

    // await this.resetToDefaultSetting()
    await this._loadCollpaseState()
    await this.resetToAppliedSetting()
    await util.delay(50)
    await util.setStatePromise(this, { isInitialized: true })
  }

  // ------------------ GETTER/SETTER (BEGIN) ----------------------
  getSetting = (): IOrdersFilter => {
    // const { currentSetting } = this.props
    // if (_.isObject(currentSetting)) {
    //   return currentSetting
    // }
    const { setting } = this.state
    return setting
  }

  getDefaultSetting = (): IOrdersFilter => {
    const { defaultSetting = {}, selectedStoreOrdersDates, selectedStoreOrdersDatesOptionKey } = this.props

    return defaultSetting
  }

  _loadCollpaseState = async () => {
    const savedCollapseState = await p.op.storageGet(CONS.STATE_SAVE_KEYS.ORDER_LIST_FILTER_COLLAPSE_STATE, true)
    if (_.isObject(savedCollapseState)) {
      await util.setStatePromise(this, { collapseState: savedCollapseState })
    }
  }

  resetToDefaultSetting = async () => {
    const defaultSetting = this.getDefaultSetting()
    await this._updateNewSetting(defaultSetting)
  }

  resetToAppliedSetting = async () => {
    const { appliedSetting } = this.props
    if (_.isObject(appliedSetting)) {
      await this._updateNewSetting(appliedSetting)
    }
  }

  updateNewSetting = async (newSetting: IOrdersFilter) => {
    await this._updateNewSetting(newSetting)
  }

  isFiltered = () => {
    const { appliedSetting } = this.props
    let usageSetting: IOrdersFilter
    if (_.isObject(appliedSetting)) {
      usageSetting = appliedSetting
    } else {
      usageSetting = this.getSetting()
    }
    const defaultSetting = this.getDefaultSetting()
    const diffSetting = diff(defaultSetting, usageSetting)
    // console.log('_isFiltered diffSetting => ', diffSetting)
    return !_.isEmpty(diffSetting)
  }

  // openFilterOverlay = async () => {
  //   await this.resetToDefaultSetting()
  //   await this.resetToAppliedSetting()

  //   const { setting } = this.state
  //   // @ts-ignore
  //   if (setting.orderOrigin === 'mkp_only' && setting.mkpChannelIds.length > 0) {
  //     const activeChannels = this._getActiveMkpChannels()
  //     for (let i = 0; i < activeChannels.length; i++) {
  //       // @ts-ignore
  //       if (_.includes(setting.mkpChannelIds, activeChannels[i].id)) {
  //         activeChannels[i].is_active = true
  //       } else {
  //         delete activeChannels[i].is_active
  //       }
  //     }
  //     util.setStatePromise(this, { activeChannels })
  //   }

  //   await util.setStatePromise(this, { isOverlayVisible: true })
  // }

  // closeFilterOverlay = async () => {
  //   await util.setStatePromise(this, { isOverlayVisible: false })
  // }

  _getNewSetting = () => {
    const selectedSetting = this.getSetting()
    return { ...selectedSetting }
  }

  _updateNewSetting = async (newSetting: IOrdersFilter) => {
    const newSettingClone = _.cloneDeep(newSetting)
    // อนาคตเอา isCOD เพราะไม่ใช้แล้ว
    if (_.has(newSettingClone, 'isCOD')) {
      delete newSettingClone.isCOD
      newSettingClone.is_cod = newSetting.isCOD
    }
    await util.setStatePromise(this, { setting: newSettingClone })
    // await this._updateCurrentSettingProp(newSetting)
    // console.log('this.state.setting => ', this.state.setting)

    const { onFilterChange } = this.props
    if (_.isFunction(onFilterChange)) {
      await onFilterChange(newSetting)
    }
  }

  // _updateCurrentSettingProp = async (newSetting: IOrdersFilter) => {
  //   const { onChangeFilter } = this.props
  //   if (_.isFunction(onChangeFilter)) {
  //     onChangeFilter(newSetting)
  //   }
  // }

  // ------------------ GETTER/SETTER (END) ----------------------
  _onResetFilter = async () => {
    await this.resetToDefaultSetting()

    const { onResetFilter } = this.props
    if (_.isFunction(onResetFilter)) {
      await onResetFilter()
    }
  }

  _onApplyFilter = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await util.setStatePromise(this, { isFilterApplyProcessing: true })

    const { onApplyFilter } = this.props
    // const { onApplyFilter, preventCloseAfterApply = false } = this.props
    // let isApplied = true
    const applySetting = this.getSetting()

    if (_.isFunction(onApplyFilter)) {
      try {
        // isApplied = await onApplyFilter(applySetting)
        await onApplyFilter(applySetting)
      } catch (err) {
        console.log('_onApplyFilter err => ', err)
      }
    }

    // if (isApplied && !preventCloseAfterApply) {
    //   this.closeFilterOverlay()
    // }

    await util.setStatePromise(this, { isFilterApplyProcessing: false })
    this.inProcess = false
  }
  // ------------------ EVENTS (BEGIN) ----------------------

  // ------------------ EVENTS (END) ----------------------

  // ------------------ Filter Change handler (BEGIN) ----------------------
  onChangeSegmentCreateAtIndex = async (newIndex: number) => {
    const newSetting = this._getNewSetting()

    newSetting.segmentCreateAtIndex = newIndex
    // set default ของช่วงเวลาพิมพ์ใบปะหน้า
    switch (newIndex) {
      case 0: // ทุกวัน (default)
        // newSetting.createOrderFrom = null
        // newSetting.createOrderTo = null
        delete newSetting.createOrderFrom
        delete newSetting.createOrderTo
        delete newSetting.segmentCreateAtIndex
        break
      case 1: // วันนี้
        newSetting.createOrderFrom = dayjs().startOf('day')
        newSetting.createOrderTo = dayjs()
        break
      case 2: // 7 วัน
        newSetting.createOrderFrom = dayjs().subtract(6, 'day')
        newSetting.createOrderTo = dayjs()
        break
      case 3: // ระบุเวลา
        newSetting.createOrderFrom = dayjs().startOf('day')
        newSetting.createOrderTo = dayjs().endOf('day')
        break
      default:
        // do nothing
        return
    }

    await this._updateNewSetting(newSetting)
  }

  // onChangeDateCreateOrderAtFromTo = async (newDateRange: IDateRange) => {
  //   const newSetting = this._getNewSetting()

  //   newSetting.createOrderFrom = newDateRange.begin
  //   newSetting.createOrderTo = util.changeSecondTime(newDateRange.end, 59)

  //   await this._updateNewSetting(newSetting)
  // }

  onChangeDateCreateOrderAtFromTo2 = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newSetting = this._getNewSetting()

    newSetting.createOrderFrom = dates.begin
    newSetting.createOrderTo = util.changeSecondTime(dates.end, 59)
    newSetting.createOrderDateRangeOptionKey = optionKey

    await this._updateNewSetting(newSetting)
  }

  // onChangeSegmentHasPrintedAtIndex = async (newIndex: number) => {
  //   const newSetting = this._getNewSetting()
  //   newSetting.segmentHasPrintedAtIndex = newIndex

  //   // set default ของช่วงเวลาพิมพ์ใบปะหน้า
  //   switch (newIndex) {
  //     case 0: // ทั้งหมด (default)
  //       // newSetting.printedAtFrom = null
  //       // newSetting.printedAtTo = null
  //       delete newSetting.printedAtFrom
  //       delete newSetting.printedAtTo
  //       delete newSetting.segmentHasPrintedAtIndex
  //       break
  //     case 1: // 5 นาที
  //       newSetting.printedAtFrom = dayjs().subtract(5, 'minutes')
  //       newSetting.printedAtTo = dayjs()
  //       break
  //     case 2: // 12 ชั่วโมง
  //       newSetting.printedAtFrom = dayjs().subtract(12, 'hours')
  //       newSetting.printedAtTo = dayjs()
  //       break
  //     case 3: // ระบุเวลา
  //       newSetting.printedAtFrom = dayjs().startOf('day')
  //       newSetting.printedAtTo = dayjs().endOf('day')
  //       break
  //     default:
  //       return
  //   }

  //   await this._updateNewSetting(newSetting)
  // }

  onChangeSegmentHasPrintedAtIndex = async (newIndex: number) => {
    const newSetting = this._getNewSetting()
    newSetting.segmentHasPrintedAtIndex = newIndex

    // set default ของช่วงเวลาพิมพ์ใบปะหน้า
    switch (newIndex) {
      case 0: // ทั้งหมด (default)
        // newSetting.printedAtFrom = null
        // newSetting.printedAtTo = null
        delete newSetting.printedAtDateRangeOptionKey
        delete newSetting.printedAtFrom
        delete newSetting.printedAtTo
        delete newSetting.segmentHasPrintedAtIndex
        break
      case 1: // ระบุเวลา
        const printedAtDateRange = util.getDateRangeFromOption(CONS.DATERANGE_SELECTOR.OPTION.Today)
        newSetting.printedAtFrom = printedAtDateRange.begin
        newSetting.printedAtTo = printedAtDateRange.end
        newSetting.printedAtDateRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
        break
      default:
        return
    }

    await this._updateNewSetting(newSetting)
  }

  // onChangeDatePrintedAtFromTo = async (newDateRange: IDateRange) => {
  //   const newSetting = this._getNewSetting()

  //   newSetting.printedAtFrom = newDateRange.begin
  //   newSetting.printedAtTo = util.changeSecondTime(newDateRange.end, 59)

  //   await this._updateNewSetting(newSetting)
  // }

  onChangeDatePrintedAtFromTo2 = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newSetting = this._getNewSetting()

    newSetting.printedAtFrom = dates.begin
    newSetting.printedAtTo = util.changeSecondTime(dates.end, 59)
    newSetting.printedAtDateRangeOptionKey = optionKey

    await this._updateNewSetting(newSetting)
  }

  // onChangeDateDeliveryFromTo = async (newDateRange: IDateRange) => {
  //   const newSetting = this._getNewSetting()

  //   newSetting.dateDeliveryFrom = newDateRange.begin
  //   newSetting.dateDeliveryTo = util.changeSecondTime(newDateRange.end, 59)

  //   await this._updateNewSetting(newSetting)
  // }

  onChangeDateDeliveryFromTo2 = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newSetting = this._getNewSetting()

    newSetting.dateDeliveryFrom = dates.begin
    newSetting.dateDeliveryTo = util.changeSecondTime(dates.end, 59)
    newSetting.dateDeliveryDateRangeOptionKey = optionKey

    await this._updateNewSetting(newSetting)
  }

  // onChangeSegmentReadyToShipDateAtIndex = async (newIndex: number) => {
  //   const newSetting = this._getNewSetting()

  //   newSetting.segmentReadyToShipAtIndex = newIndex

  //   switch (newIndex) {
  //     case 0: // ทั้งหมด
  //       // newSetting.readyToShipDateFrom = null
  //       // newSetting.readyToShipDateTo = null
  //       delete newSetting.readyToShipDateFrom
  //       delete newSetting.readyToShipDateTo
  //       delete newSetting.segmentReadyToShipAtIndex
  //       break
  //     case 1: // เมื่อวาน
  //       newSetting.readyToShipDateFrom = dayjs(dayjs().subtract(1, 'day')).startOf('day')
  //       newSetting.readyToShipDateTo = dayjs(dayjs().subtract(1, 'day')).endOf('day')
  //       break
  //     case 2: // วันนี้
  //       newSetting.readyToShipDateFrom = dayjs().startOf('day')
  //       newSetting.readyToShipDateTo = dayjs().endOf('day')
  //       break
  //     case 3: // ระบุเวลา
  //       newSetting.readyToShipDateFrom = dayjs().startOf('day')
  //       newSetting.readyToShipDateTo = dayjs().endOf('day')
  //       break
  //     default:
  //       return
  //   }

  //   await this._updateNewSetting(newSetting)
  // }

  onChangeSegmentReadyToShipDateAtIndex = async (newIndex: number) => {
    const newSetting = this._getNewSetting()

    newSetting.segmentReadyToShipAtIndex = newIndex

    switch (newIndex) {
      case 0: // ทั้งหมด
        // newSetting.readyToShipDateFrom = null
        // newSetting.readyToShipDateTo = null
        delete newSetting.readyToShipDateRangeOptionKey
        delete newSetting.readyToShipDateFrom
        delete newSetting.readyToShipDateTo
        delete newSetting.segmentReadyToShipAtIndex
        break
      case 1: // ระบุเวลา
        const readyToShipDateRange = util.getDateRangeFromOption(CONS.DATERANGE_SELECTOR.OPTION.Today)
        newSetting.readyToShipDateFrom = readyToShipDateRange.begin
        newSetting.readyToShipDateTo = readyToShipDateRange.end
        newSetting.readyToShipDateRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
        break
      default:
        return
    }

    await this._updateNewSetting(newSetting)
  }

  // onChangeDateReadyToShipAtFromTo = async (newDateRange: IDateRange) => {
  //   const newSetting = this._getNewSetting()

  //   newSetting.readyToShipDateFrom = newDateRange.begin
  //   newSetting.readyToShipDateTo = util.changeSecondTime(newDateRange.end, 59)

  //   await this._updateNewSetting(newSetting)
  // }

  onChangeDateReadyToShipAtFromTo2 = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newSetting = this._getNewSetting()

    newSetting.readyToShipDateFrom = dates.begin
    newSetting.readyToShipDateTo = util.changeSecondTime(dates.end, 59)
    newSetting.readyToShipDateRangeOptionKey = optionKey

    await this._updateNewSetting(newSetting)
  }

  // onChangeSegmentDateDeliverAtIndex = async (newIndex: number) => {
  //   const newSetting = this._getNewSetting()

  //   newSetting.segmentDateDeliveryAtIndex = newIndex

  //   // set default ของช่วงเวลาพิมพ์ใบปะหน้า
  //   switch (newIndex) {
  //     case 0: // ทั้งหมด (default)
  //       // newSetting.dateDeliveryFrom = null
  //       // newSetting.dateDeliveryTo = null
  //       delete newSetting.dateDeliveryFrom
  //       delete newSetting.dateDeliveryTo
  //       delete newSetting.segmentDateDeliveryAtIndex
  //       break
  //     case 1:
  //       newSetting.dateDeliveryFrom = dayjs().startOf('day')
  //       newSetting.dateDeliveryTo = dayjs().endOf('day')
  //       break
  //     case 2:
  //       newSetting.dateDeliveryFrom = dayjs(dayjs().add(1, 'day')).startOf('day')
  //       newSetting.dateDeliveryTo = dayjs(dayjs().add(1, 'day')).endOf('day')
  //       break
  //     case 3:
  //       newSetting.dateDeliveryFrom = dayjs().startOf('day')
  //       newSetting.dateDeliveryTo = dayjs().endOf('day')
  //       break
  //     default:
  //       return
  //   }

  //   await this._updateNewSetting(newSetting)
  // }

  onChangeSegmentDateDeliverAtIndex = async (newIndex: number) => {
    const newSetting = this._getNewSetting()

    newSetting.segmentDateDeliveryAtIndex = newIndex

    // set default ของช่วงเวลาพิมพ์ใบปะหน้า
    switch (newIndex) {
      case 0: // ทั้งหมด (default)
        // newSetting.dateDeliveryFrom = null
        // newSetting.dateDeliveryTo = null
        delete newSetting.dateDeliveryDateRangeOptionKey
        delete newSetting.dateDeliveryFrom
        delete newSetting.dateDeliveryTo
        delete newSetting.segmentDateDeliveryAtIndex
        break
      case 1: // ระบุเวลา
        const dateDeliveryDateRange = util.getDateRangeFromOption(CONS.DATERANGE_SELECTOR.OPTION.Today)
        newSetting.dateDeliveryFrom = dateDeliveryDateRange.begin
        newSetting.dateDeliveryTo = dateDeliveryDateRange.end
        newSetting.dateDeliveryDateRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
        break
      default:
        return
    }

    await this._updateNewSetting(newSetting)
  }

  // onChangeDateAutoCancelOrderFromTo = async (newDateRange: IDateRange) => {
  //   const newSetting = this._getNewSetting()

  //   newSetting.autoCancelOrderFrom = newDateRange.begin
  //   newSetting.autoCancelOrderTo = util.changeSecondTime(newDateRange.end, 59)

  //   await this._updateNewSetting(newSetting)
  // }

  onChangeDateAutoCancelOrderFromTo2 = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newSetting = this._getNewSetting()

    newSetting.autoCancelOrderFrom = dates.begin
    newSetting.autoCancelOrderTo = util.changeSecondTime(dates.end, 59)
    newSetting.autoCancelOrderDateRangeOptionKey = optionKey

    await this._updateNewSetting(newSetting)
  }

  // onChangeSegmentAutoCancelOrderAtIndex = async (newIndex: number) => {
  //   const newSetting = this._getNewSetting()

  //   newSetting.segmentAutoCancelOrderAtIndex = newIndex

  //   // set default ของช่วงเวลาพิมพ์ใบปะหน้า
  //   switch (newIndex) {
  //     case 0: // ทั้งหมด (default)
  //       // newSetting.autoCancelOrderFrom = null
  //       // newSetting.autoCancelOrderTo = null
  //       delete newSetting.autoCancelOrderFrom
  //       delete newSetting.autoCancelOrderTo
  //       delete newSetting.segmentAutoCancelOrderAtIndex
  //       break
  //     case 1: // วันนี้
  //       newSetting.autoCancelOrderFrom = dayjs().startOf('day')
  //       newSetting.autoCancelOrderTo = dayjs().endOf('day')
  //       break
  //     case 2: // พรุ่งนี้
  //       newSetting.autoCancelOrderFrom = dayjs(dayjs().add(1, 'day')).startOf('day')
  //       newSetting.autoCancelOrderTo = dayjs(dayjs().add(1, 'day')).endOf('day')
  //       break
  //     case 3: // ระบุเวลา
  //       newSetting.autoCancelOrderFrom = dayjs().startOf('day')
  //       newSetting.autoCancelOrderTo = dayjs().endOf('day')
  //       break
  //     default:
  //       return
  //   }

  //   await this._updateNewSetting(newSetting)
  // }

  onChangeSegmentAutoCancelOrderAtIndex = async (newIndex: number) => {
    const newSetting = this._getNewSetting()

    newSetting.segmentAutoCancelOrderAtIndex = newIndex

    // set default ของช่วงเวลาพิมพ์ใบปะหน้า
    switch (newIndex) {
      case 0: // ทั้งหมด (default)
        // newSetting.autoCancelOrderFrom = null
        // newSetting.autoCancelOrderTo = null
        delete newSetting.autoCancelOrderDateRangeOptionKey
        delete newSetting.autoCancelOrderFrom
        delete newSetting.autoCancelOrderTo
        delete newSetting.segmentAutoCancelOrderAtIndex
        break
      case 1: // ระบุเวลา
        const autoCancelDateRange = util.getDateRangeFromOption(CONS.DATERANGE_SELECTOR.OPTION.Today)
        newSetting.autoCancelOrderFrom = autoCancelDateRange.begin
        newSetting.autoCancelOrderTo = autoCancelDateRange.end
        newSetting.autoCancelOrderDateRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
        break
      default:
        return
    }

    await this._updateNewSetting(newSetting)
  }

  onSubmitNewShippingTypeIds = (selectedShippingTypeIds: number[]) => {
    const newSetting = this._getNewSetting()

    newSetting.selectorShippingTypeIds = selectedShippingTypeIds

    this._updateNewSetting(newSetting)
  }

  onChangeSegmentOrderOriginAtIndex = async (newIndex: number) => {
    const newSetting = this._getNewSetting()

    newSetting.segmentOrderOriginIndex = newIndex

    // set default ของช่วงเวลาพิมพ์ใบปะหน้า
    switch (newIndex) {
      case 0: // XSelly
        newSetting.orderOrigin = 'xselly_only'
        // delete newSetting.mkpChannelIds
        // // await this._callBackFromMKPChannelsPicker([])
        newSetting.mkpChannelActiveMap = {}
        break
      case 1: // ช่องทางขาย
        newSetting.orderOrigin = 'mkp_only'
        // // const { activeChannels } = this.state
        // // if (activeChannels.length >= 1) {
        // //   await this._callBackFromMKPChannelsPicker(activeChannels)
        // // }
        // // case: ช่องทางขาย ถ้าถูกเปิดใช้งาน ให้เปิด mkp channel selectors
        // const activeMkpChannels = this._getActiveMkpChannels()
        // const activeMkpChannelIds = activeMkpChannels.filter(util.filterMkpChannelIsActive).map((ch: IMKPChannelDetail) => ch.id)
        // newSetting.mkpChannelIds = activeMkpChannelIds
        // await util.setStatePromise(this, { activeChannels: activeMkpChannels })
        // await util.delay(50)
        const mkpChannels = util.getMKPChannels()
        newSetting.mkpChannelActiveMap = {}
        for (let i = 0; i < mkpChannels.length; i++) {
          newSetting.mkpChannelActiveMap[mkpChannels[i].id] = true
        }
        break
      case 2: // ทั้งหมด (default)
        delete newSetting.orderOrigin
        delete newSetting.segmentOrderOriginIndex
        // delete newSetting.mkpChannelIds
        // // await this._callBackFromMKPChannelsPicker([])
        newSetting.mkpChannelActiveMap = {}
        break
      default:
        return
    }

    await this._updateNewSetting(newSetting)
  }

  onChangeSegmentOrderChannelsAtIndex = async (newIndex: number) => {
    const newSetting = this._getNewSetting()

    newSetting.segmentOrderChannelsIndex = newIndex

    switch (newIndex) {
      case 0: // all
        newSetting.orderChannelActiveMap = {}
        break
      case 1: // ระบุ
        const mkpChannelIds = OrderChannelItems.map((ch) => ch.id)
        newSetting.orderChannelActiveMap = {}
        newSetting.orderChannelActiveMap[0] = true
        // for (let i = 0; i < mkpChannelIds.length; i++) {
        //   newSetting.orderChannelActiveMap[mkpChannelIds[i]] = true
        // }
        break
      default:
        return
    }

    await this._updateNewSetting(newSetting)
  }
  // ------------------ Filter Change handler (END) ----------------------

  // ------------------ Generic XCheckButtons (START) ----------------------
  _onPressGenericCheckButton = (args: { index?: number; data?: any; name?: string }): void => {
    const { name, data } = args
    const newSetting = this._getNewSetting()
    if (newSetting[name] === data) {
      // newSetting[name] = null
      delete newSetting[name]
    } else {
      newSetting[name] = data
    }
    this._updateNewSetting(newSetting)
  }

  _renderGenericCheckButton = (opt: { key: keyof IOrdersFilter; label: string; value: any }, idx: number) => {
    const { key, value, label } = opt
    const selectedSetting = this.getSetting()
    // console.log('key => ', key)
    let finnelKey = key
    if (key === 'isCOD') {
      finnelKey = 'is_cod'
    }
    return (
      <XCheckButton
        key={`${finnelKey}_${idx}`}
        // buttonStyle={{ marginTop: 2 }}
        checked={selectedSetting[finnelKey] === value}
        name={finnelKey}
        label={label}
        data={value}
        onPress={this._onPressGenericCheckButton}
      />
    )
  }

  renderGenericCheckButtons = (options: Array<{ key: keyof IOrdersFilter; label: string; value: any }>) => (
    <HStack w='full' flexWrap='wrap' space='2'>
      {options.map(this._renderGenericCheckButton)}
    </HStack>
  )
  // ------------------ Generic XCheckButtons (END) ----------------------

  // ------------------ Filter render method (START) ----------------------

  renderFilterSortBy = () => {
    const { sortByIndex = 0 } = this.getSetting()

    return (
      <VStack w='full' space='1'>
        {/* <Text style={[S.TEXT_BLUE, S.TEXT_BOLD]}>{'yoooooo'}</Text> */}

        <XRadioList
          title='เรียงโดย...'
          selectedIndex={sortByIndex}
          options={CONS.ORDERS_SORT_BY_OPTIONS}
          onRadioChange={(opt, index) => {
            const newSetting = this._getNewSetting()
            newSetting.sortByIndex = index
            this._updateNewSetting(newSetting)
          }}
        />
      </VStack>
    )
  }

  renderFilterSortType = () => {
    const { sortTypeIndex = 0 } = this.getSetting()

    return (
      <XSegment<IOrdersSortType>
        segmentContainerStyle={{ minWidth: 200 }}
        title='เรียงจาก...'
        selectedIndex={sortTypeIndex}
        onSegmentChange={(opt, index) => {
          const newSetting = this._getNewSetting()
          newSetting.sortTypeIndex = index
          this._updateNewSetting(newSetting)
        }}
        options={CONS.ORDERS_SORT_TYPE_OPTIONS}
      />
    )
  }

  renderFilterOrderCreate = () => {
    const {
      segmentCreateAtIndex = 0,
      // createOrderFrom = dayjs().startOf('day'),
      // createOrderTo = dayjs().endOf('day'),
      createOrderFrom,
      createOrderTo,
      createOrderDateRangeOptionKey,
      isFilterOrderCreateDisabled = false,
    } = this.getSetting()

    const { selectedStoreOrdersDates, selectedStoreOrdersDatesOptionKey } = this.props

    // const createOrderAtDateRange =
    //   createOrderFrom && createOrderTo
    //     ? {
    //         begin: createOrderFrom,
    //         end: createOrderTo,
    //       }
    //     : selectedStoreOrdersDates

    const createOrderAtDateRangeOptionKey = createOrderDateRangeOptionKey || selectedStoreOrdersDatesOptionKey
    let createOrderAtDateRange
    if (selectedStoreOrdersDatesOptionKey === 'Custom' && !createOrderDateRangeOptionKey) {
      createOrderAtDateRange = selectedStoreOrdersDates
    } else if (createOrderAtDateRangeOptionKey === 'Custom') {
      createOrderAtDateRange = { begin: createOrderFrom, end: createOrderTo }
    } else {
      createOrderAtDateRange = util.getDateRangeFromOption(createOrderAtDateRangeOptionKey)
    }

    if (isFilterOrderCreateDisabled) {
      return null
    }

    // console.log('renderFilterOrderCreate selectedStoreOrdersDatesOptionKey => ', selectedStoreOrdersDatesOptionKey)
    // console.log('renderFilterOrderCreate selectedStoreOrdersDates => ', selectedStoreOrdersDates)
    // console.log('renderFilterOrderCreate createOrderAtDateRange => ', createOrderAtDateRange)
    // console.log('renderFilterOrderCreate createOrderAtDateRangeOptionKey => ', createOrderAtDateRangeOptionKey)
    // const segmentCreateAtIndex = selectedSetting.segmentCreateAtIndex || 0
    // const createOrderFrom = selectedSetting.createOrderFrom || dayjs().startOf('day')
    // const createOrderTo = selectedSetting.createOrderTo || dayjs().endOf('day')
    return (
      <VStack w='full' p='2' space='1' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' />
          <XText variant='inactive'>สร้างภายใน</XText>
        </HStack>
        {/* <Segment
          selectedIndex={segmentCreateAtIndex}
          options={['ทุกวัน', 'วันนี้', '7 วัน', 'ระบุเวลา']}
          onSegmentChange={this.onChangeSegmentCreateAtIndex}
        /> */}
        {/* <Box w='full' h='2' /> */}
        {/* {segmentCreateAtIndex === 3 ? (
          <VStack w='full'>
            <XDateRangePicker
              // allowOnlyDaysFromToday={31}
              selectedDates={{ begin: createOrderFrom, end: createOrderTo }}
              onChangeDate={this.onChangeDateCreateOrderAtFromTo}
              showTime
              oneLineDateTime={!p.op.isWeb()}
            />
          </VStack>
        ) : null} */}
        <XDateRangeSelectorButton
          allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
          isOptionsScrollable={IS_SMALL_PHONE}
          title='สร้างภายใน...'
          selectedDates={createOrderAtDateRange}
          selectedOptionKey={createOrderAtDateRangeOptionKey}
          onChange={this.onChangeDateCreateOrderAtFromTo2}
          showTime
        />
      </VStack>
    )
  }

  renderFilterIsCod = () => {
    const { isFilterIsCodDisabled = false } = this.getSetting()
    if (isFilterIsCodDisabled) {
      return null
    }
    return (
      <VStack w='full' p='2' bg='white'>
        <HStack alignItems='center' space='1'>
          <Image alt='COD' source={imgIconCod} resizeMode='contain' w='6' h='6' />
          <XText variant='inactive'>การเก็บเงินปลายทาง [COD]</XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'is_cod',
            label: 'เก็บ',
            value: true,
          },
          {
            key: 'is_cod',
            label: 'ไม่เก็บ',
            value: false,
          },
        ])}
      </VStack>
    )
  }

  renderFilterIsTaxInvoiceRequested = () => {
    const { isFilterIsTaxInvoiceRequestedDisabled = false } = this.getSetting()
    if (isFilterIsTaxInvoiceRequestedDisabled) {
      return null
    }
    return (
      <VStack w='full' p='2' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='receipt' family='MaterialCommunityIcons' />
          <XText variant='inactive'>ขอใบกำกับภาษี</XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'isTaxInvoiceRequested',
            label: 'มี',
            value: true,
          },
          {
            key: 'isTaxInvoiceRequested',
            label: 'ไม่มี',
            value: false,
          },
        ])}
      </VStack>
    )
  }

  renderFilterIsShipBeforePay = () => {
    const { isFilterIsShipBeforePayDisabled = false } = this.getSetting()
    if (isFilterIsShipBeforePayDisabled) {
      return null
    }
    // const segmentCreateAtIndex = selectedSetting.segmentCreateAtIndex || 0
    // const createOrderFrom = selectedSetting.createOrderFrom || dayjs().startOf('day')
    // const createOrderTo = selectedSetting.createOrderTo || dayjs().endOf('day')
    return (
      <VStack w='full' p='2' space='1'>
        <HStack alignItems='center' space='1'>
          <XIcon
            variant='active'
            name='truck-delivery'
            family='MaterialCommunityIcons'
            style={{ color: COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther }}
          />
          <XText variant='inactive'>การจัดส่ง</XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'isShipBeforePay',
            label: 'จัดส่งก่อนชำระ',
            value: true,
          },
          {
            key: 'isShipBeforePay',
            label: 'จัดส่งหลังชำระ',
            value: false,
          },
        ])}
      </VStack>
    )
  }

  renderFilterPrintedAt = () => {
    const {
      hasPrintedAt,
      segmentHasPrintedAtIndex = 0,
      // printedAtFrom = dayjs().startOf('day'),
      // printedAtTo = dayjs().endOf('day'),
      printedAtFrom,
      printedAtTo,
      printedAtDateRangeOptionKey,
      isFilterPrintedAtDisabled = false,
    } = this.getSetting()

    if (isFilterPrintedAtDisabled) {
      return null
    }
    // const isBoolHasPrintedAt = _.isBoolean(hasPrintedAt)

    // const printedAtDateRange = { begin: printedAtFrom, end: printedAtTo }
    let printedAtDateRange
    if (printedAtDateRangeOptionKey === 'Custom') {
      printedAtDateRange = { begin: printedAtFrom, end: printedAtTo }
    } else {
      printedAtDateRange = util.getDateRangeFromOption(printedAtDateRangeOptionKey)
    }

    return (
      <VStack w='full' p='2' space='1' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='printer' family='MaterialCommunityIcons' />
          <XText variant='inactive'>ใบปะหน้าพัสดุ</XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'hasPrintedAt',
            label: 'พิมพ์ไปแล้ว',
            value: true,
          },
          {
            key: 'hasPrintedAt',
            label: 'ยังไม่ได้พิมพ์',
            value: false,
          },
        ])}

        {hasPrintedAt ? (
          <VStack w='full' space='1'>
            <XText variant='inactive'>ภายใน</XText>
            <Segment
              selectedIndex={segmentHasPrintedAtIndex}
              // options={['ทั้งหมด', '5 นาที', '12 ชั่วโมง', 'ระบุเวลา']}
              options={['ทั้งหมด', 'ระบุเวลา']}
              onSegmentChange={this.onChangeSegmentHasPrintedAtIndex}
            />
          </VStack>
        ) : null}

        {/* {hasPrintedAt && segmentHasPrintedAtIndex === 3 ? (
          <XDateRangePicker
            // allowOnlyDaysFromToday={31}
            selectedDates={{ begin: printedAtFrom, end: printedAtTo }}
            onChangeDate={this.onChangeDatePrintedAtFromTo}
            showTime
            oneLineDateTime={false}
            // oneLineDateTime={!p.op.isWeb()}
          />
        ) : null} */}

        {hasPrintedAt && segmentHasPrintedAtIndex === 1 ? (
          <XDateRangeSelectorButton
            allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            isOptionsScrollable={IS_SMALL_PHONE}
            title='พิมพ์ไปแล้วภายใน...'
            selectedDates={printedAtDateRange}
            selectedOptionKey={printedAtDateRangeOptionKey}
            onChange={this.onChangeDatePrintedAtFromTo2}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  renderFilterDateDelivery = () => {
    const {
      hasDateDelivery,
      segmentDateDeliveryAtIndex,
      dateDeliveryFrom,
      dateDeliveryTo,
      dateDeliveryDateRangeOptionKey,
      isFilterDateDeliveryDisabled = false,
    } = this.getSetting()

    if (isFilterDateDeliveryDisabled) {
      return null
    }

    // const deliveryAtDateRange = { begin: dateDeliveryFrom, end: dateDeliveryTo }
    let deliveryAtDateRange
    if (dateDeliveryDateRangeOptionKey === 'Custom') {
      deliveryAtDateRange = { begin: dateDeliveryFrom, end: dateDeliveryTo }
    } else {
      deliveryAtDateRange = util.getDateRangeFromOption(dateDeliveryDateRangeOptionKey)
    }

    // const isBoolHasPrintedAt = _.isBoolean(hasPrintedAt)
    return (
      <VStack w='full' p='2' space='1'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='back-in-time' family='Entypo' />
          <XText variant='inactive'>วันกำหนดส่ง</XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'hasDateDelivery',
            label: 'มี',
            value: true,
          },
          {
            key: 'hasDateDelivery',
            label: 'ไม่มี',
            value: false,
          },
        ])}

        {hasDateDelivery ? (
          <VStack w='full' space='1'>
            <XText variant='inactive'>ภายใน</XText>
            <Segment
              selectedIndex={segmentDateDeliveryAtIndex}
              // options={['ทั้งหมด', 'วันนี้', 'พรุ่งนี้', 'ระบุเวลา']}
              options={['ทั้งหมด', 'ระบุเวลา']}
              onSegmentChange={this.onChangeSegmentDateDeliverAtIndex}
            />
          </VStack>
        ) : null}

        {/* {hasDateDelivery && segmentDateDeliveryAtIndex === 3 ? (
          <XDateRangePicker
            // allowOnlyDaysFromToday={31}
            selectedDates={{ begin: dateDeliveryFrom, end: dateDeliveryTo }}
            onChangeDate={this.onChangeDateDeliveryFromTo}
            showTime
            // oneLineDateTime={!p.op.isWeb()}
            oneLineDateTime={false}
            beginDateLength={{ min: dayjs().subtract(6, 'month'), max: dayjs().add(5, 'year') }}
          />
        ) : null} */}

        {hasDateDelivery && segmentDateDeliveryAtIndex === 1 ? (
          <XDateRangeSelectorButton
            allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            isOptionsScrollable={IS_SMALL_PHONE}
            title='วันกำหนดส่งภายใน...'
            selectedDates={deliveryAtDateRange}
            selectedOptionKey={dateDeliveryDateRangeOptionKey}
            onChange={this.onChangeDateDeliveryFromTo2}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  onChangeSegmentPaymentCompletedAtIndex = async (newIndex: number) => {
    const newSetting = this._getNewSetting()

    newSetting.segmentPaymentCompletedAtIndex = newIndex

    switch (newIndex) {
      case 0: // ทั้งหมด
        delete newSetting.paymentCompletedAtDateRangeOptionKey
        delete newSetting.paymentCompletedAtFrom
        delete newSetting.paymentCompletedAtTo
        delete newSetting.segmentPaymentCompletedAtIndex
        break
      case 1: // ระบุเวลา
        const paymentCompletedAtDateRange = util.getDateRangeFromOption(CONS.DATERANGE_SELECTOR.OPTION.Today)
        newSetting.paymentCompletedAtFrom = paymentCompletedAtDateRange.begin
        newSetting.paymentCompletedAtTo = paymentCompletedAtDateRange.end
        newSetting.paymentCompletedAtDateRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
        break
      default:
        return
    }

    await this._updateNewSetting(newSetting)
  }

  onChangePaymentCompletedAtFromTo = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newSetting = this._getNewSetting()

    newSetting.paymentCompletedAtFrom = dates.begin
    newSetting.paymentCompletedAtTo = util.changeSecondTime(dates.end, 59)
    newSetting.paymentCompletedAtDateRangeOptionKey = optionKey

    await this._updateNewSetting(newSetting)
  }

  renderFilterPaymentCompletedAt = () => {
    const {
      hasPaymentCompletedAt,
      segmentPaymentCompletedAtIndex = 0,
      paymentCompletedAtFrom,
      paymentCompletedAtTo,
      paymentCompletedAtDateRangeOptionKey,
      isFilterPaymentCompletedAtDisabled = false,
    } = this.getSetting()
    // const isBoolHasPrintedAt = _.isBoolean(hasPrintedAt)

    if (isFilterPaymentCompletedAtDisabled) {
      return null
    }

    // const paymentCompletedAtDateRange = { begin: paymentCompletedAtFrom, end: paymentCompletedAtTo }
    let paymentCompletedAtDateRange
    if (paymentCompletedAtDateRangeOptionKey === 'Custom') {
      paymentCompletedAtDateRange = { begin: paymentCompletedAtFrom, end: paymentCompletedAtTo }
    } else {
      paymentCompletedAtDateRange = util.getDateRangeFromOption(paymentCompletedAtDateRangeOptionKey)
    }

    return (
      <VStack w='full' p='2' space='1' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='cash' family='Ionicons' />
          <XText variant='inactive'>สถานะการชำระ</XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'hasPaymentCompletedAt',
            label: 'ชำระครบถ้วน',
            value: true,
          },
          {
            key: 'hasPaymentCompletedAt',
            label: 'ชำระไม่ครบถ้วน',
            value: false,
          },
        ])}

        {hasPaymentCompletedAt ? (
          <VStack w='full' space='1'>
            <XText variant='inactive'>ภายใน</XText>
            <Segment
              selectedIndex={segmentPaymentCompletedAtIndex}
              // options={['ทั้งหมด', 'เมื่อวาน', 'วันนี้', 'ระบุเวลา']}
              options={['ทั้งหมด', 'ระบุเวลา']}
              onSegmentChange={this.onChangeSegmentPaymentCompletedAtIndex}
            />
          </VStack>
        ) : null}

        {hasPaymentCompletedAt && segmentPaymentCompletedAtIndex === 1 ? (
          <XDateRangeSelectorButton
            allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            isOptionsScrollable={IS_SMALL_PHONE}
            title='พร้อมส่งภายใน...'
            selectedDates={paymentCompletedAtDateRange}
            selectedOptionKey={paymentCompletedAtDateRangeOptionKey}
            onChange={this.onChangePaymentCompletedAtFromTo}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  onChangeSegmentShippingCompletedAtIndex = async (newIndex: number) => {
    const newSetting = this._getNewSetting()

    newSetting.segmentShippingCompletedAtIndex = newIndex

    switch (newIndex) {
      case 0: // ทั้งหมด
        delete newSetting.shippingCompletedAtDateRangeOptionKey
        delete newSetting.shippingCompletedAtFrom
        delete newSetting.shippingCompletedAtTo
        delete newSetting.segmentShippingCompletedAtIndex
        break
      case 1: // ระบุเวลา
        const shippingCompletedAtDateRange = util.getDateRangeFromOption(CONS.DATERANGE_SELECTOR.OPTION.Today)
        newSetting.shippingCompletedAtFrom = shippingCompletedAtDateRange.begin
        newSetting.shippingCompletedAtTo = shippingCompletedAtDateRange.end
        newSetting.shippingCompletedAtDateRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
        break
      default:
        return
    }

    await this._updateNewSetting(newSetting)
  }

  onChangeShippingCompletedAtFromTo = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newSetting = this._getNewSetting()

    newSetting.shippingCompletedAtFrom = dates.begin
    newSetting.shippingCompletedAtTo = util.changeSecondTime(dates.end, 59)
    newSetting.shippingCompletedAtDateRangeOptionKey = optionKey

    await this._updateNewSetting(newSetting)
  }

  renderFilterShippingCompletedAt = () => {
    const {
      hasShippingCompletedAt,
      segmentShippingCompletedAtIndex = 0,
      shippingCompletedAtFrom,
      shippingCompletedAtTo,
      shippingCompletedAtDateRangeOptionKey,
      isFilterShippingCompletedAtDisabled = false,
    } = this.getSetting()
    // const isBoolHasPrintedAt = _.isBoolean(hasPrintedAt)

    if (isFilterShippingCompletedAtDisabled) {
      return null
    }

    // const shippingCompletedAtDateRange = { begin: shippingCompletedAtFrom, end: shippingCompletedAtTo }
    let shippingCompletedAtDateRange
    if (shippingCompletedAtDateRangeOptionKey === 'Custom') {
      shippingCompletedAtDateRange = { begin: shippingCompletedAtFrom, end: shippingCompletedAtTo }
    } else {
      shippingCompletedAtDateRange = util.getDateRangeFromOption(shippingCompletedAtDateRangeOptionKey)
    }

    return (
      <VStack w='full' p='2' space='1'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' family='MaterialIcons' name='local-shipping' />
          <XText variant='inactive'>สถานะการจัดส่ง</XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'hasShippingCompletedAt',
            label: 'จัดส่งครบถ้วน',
            value: true,
          },
          {
            key: 'hasShippingCompletedAt',
            label: 'จัดส่งไม่ครบถ้วน',
            value: false,
          },
        ])}

        {hasShippingCompletedAt ? (
          <VStack w='full' space='1'>
            <XText variant='inactive'>ภายใน</XText>
            <Segment
              selectedIndex={segmentShippingCompletedAtIndex}
              // options={['ทั้งหมด', 'เมื่อวาน', 'วันนี้', 'ระบุเวลา']}
              options={['ทั้งหมด', 'ระบุเวลา']}
              onSegmentChange={this.onChangeSegmentShippingCompletedAtIndex}
            />
          </VStack>
        ) : null}

        {hasShippingCompletedAt && segmentShippingCompletedAtIndex === 1 ? (
          <XDateRangeSelectorButton
            allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            isOptionsScrollable={IS_SMALL_PHONE}
            title='พร้อมส่งภายใน...'
            selectedDates={shippingCompletedAtDateRange}
            selectedOptionKey={shippingCompletedAtDateRangeOptionKey}
            onChange={this.onChangeShippingCompletedAtFromTo}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  renderFilterReadyToShip = () => {
    const {
      hasReadyToShipDate,
      segmentReadyToShipAtIndex = 0,
      readyToShipDateFrom,
      readyToShipDateTo,
      readyToShipDateRangeOptionKey,
      isFilterReadyToShipDateDisabled = false,
    } = this.getSetting()
    // const isBoolHasPrintedAt = _.isBoolean(hasPrintedAt)

    if (isFilterReadyToShipDateDisabled) {
      return null
    }

    // const readyToShipDateRange = { begin: readyToShipDateFrom, end: readyToShipDateTo }
    let readyToShipDateRange
    if (readyToShipDateRangeOptionKey === 'Custom') {
      readyToShipDateRange = { begin: readyToShipDateFrom, end: readyToShipDateTo }
    } else {
      readyToShipDateRange = util.getDateRangeFromOption(readyToShipDateRangeOptionKey)
    }

    return (
      <VStack w='full' p='2' space='1' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='cancel' family='MaterialCommunityIcons' />
          <XText variant='inactive'>สถานะพร้อมส่ง</XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'hasReadyToShipDate',
            label: 'พร้อมส่ง',
            value: true,
          },
          {
            key: 'hasReadyToShipDate',
            label: 'ไม่พร้อมส่ง',
            value: false,
          },
        ])}

        {hasReadyToShipDate ? (
          <VStack w='full' space='1'>
            <XText variant='inactive'>ภายใน</XText>
            <Segment
              selectedIndex={segmentReadyToShipAtIndex}
              // options={['ทั้งหมด', 'เมื่อวาน', 'วันนี้', 'ระบุเวลา']}
              options={['ทั้งหมด', 'ระบุเวลา']}
              onSegmentChange={this.onChangeSegmentReadyToShipDateAtIndex}
            />
          </VStack>
        ) : null}

        {/* {hasReadyToShipDate && segmentReadyToShipAtIndex === 3 ? (
          <XDateRangePicker
            // allowOnlyDaysFromToday={31}
            selectedDates={{ begin: readyToShipDateFrom, end: readyToShipDateTo }}
            onChangeDate={this.onChangeDateReadyToShipAtFromTo}
            showTime
            // oneLineDateTime={!p.op.isWeb()}
            oneLineDateTime={false}
          />
        ) : null} */}

        {hasReadyToShipDate && segmentReadyToShipAtIndex === 1 ? (
          <XDateRangeSelectorButton
            allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            isOptionsScrollable={IS_SMALL_PHONE}
            title='พร้อมส่งภายใน...'
            selectedDates={readyToShipDateRange}
            selectedOptionKey={readyToShipDateRangeOptionKey}
            onChange={this.onChangeDateReadyToShipAtFromTo2}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  renderFilterAutoCancelOrder = () => {
    const {
      hasExpirationDate,
      segmentAutoCancelOrderAtIndex = 0,
      autoCancelOrderFrom,
      autoCancelOrderTo,
      autoCancelOrderDateRangeOptionKey,
      isFilterAutoCancelOrderDisabled = false,
    } = this.getSetting()

    if (isFilterAutoCancelOrderDisabled) {
      return null
    }

    // const autoCancelOrderDateRange = { begin: autoCancelOrderFrom, end: autoCancelOrderTo }
    let autoCancelOrderDateRange
    if (autoCancelOrderDateRangeOptionKey === 'Custom') {
      autoCancelOrderDateRange = { begin: autoCancelOrderFrom, end: autoCancelOrderTo }
    } else {
      autoCancelOrderDateRange = util.getDateRangeFromOption(autoCancelOrderDateRangeOptionKey)
    }

    // const isBoolHasPrintedAt = _.isBoolean(hasPrintedAt)
    return (
      <VStack w='full' p='2' space='1'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='cancel' family='MaterialCommunityIcons' />
          <XText variant='inactive' t='StoreSetting.MenuStoreCancelOrderAutomation' />
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'hasExpirationDate',
            label: 'มี',
            value: true,
          },
          {
            key: 'hasExpirationDate',
            label: 'ไม่มี',
            value: false,
          },
        ])}

        {hasExpirationDate ? (
          <VStack w='full' space='1'>
            <XText variant='inactive'>ภายใน</XText>
            <Segment
              selectedIndex={segmentAutoCancelOrderAtIndex}
              // options={['ทั้งหมด', 'วันนี้', 'พรุ่งนี้', 'ระบุเวลา']}
              options={['ทั้งหมด', 'ระบุเวลา']}
              onSegmentChange={this.onChangeSegmentAutoCancelOrderAtIndex}
            />
          </VStack>
        ) : null}

        {/* {hasExpirationDate && segmentAutoCancelOrderAtIndex === 3 ? (
          <XDateRangePicker
            // allowOnlyDaysFromToday={31}
            selectedDates={{ begin: autoCancelOrderFrom, end: autoCancelOrderTo }}
            onChangeDate={this.onChangeDateAutoCancelOrderFromTo}
            showTime
            // oneLineDateTime={!p.op.isWeb()}
            oneLineDateTime={false}
            beginDateLength={{ min: dayjs().subtract(6, 'month'), max: dayjs().add(5, 'year') }}
          />
        ) : null} */}

        {hasExpirationDate && segmentAutoCancelOrderAtIndex === 1 ? (
          <XDateRangeSelectorButton
            allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            isOptionsScrollable={IS_SMALL_PHONE}
            title='พิมพ์ไปแล้วภายใน...'
            selectedDates={autoCancelOrderDateRange}
            selectedOptionKey={autoCancelOrderDateRangeOptionKey}
            onChange={this.onChangeDateAutoCancelOrderFromTo2}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  _renderCustomHeaderShippingTypeSelectorSegment = () => (
    <HStack alignItems='center' space='1'>
      <XIcon variant='active' name='local-shipping' family='MaterialIcons' />
      <XText variant='inactive'>รูปแบบจัดส่ง</XText>
    </HStack>
  )

  renderFilterMkpIsSelfDelivery = () => {
    const { isFilterMkpIsSelfDeliveryDisabled = false } = this.getSetting()
    if (isFilterMkpIsSelfDeliveryDisabled) {
      return null
    }

    const mkpChannels = util.getMKPChannels()
    if (_.isNil(mkpChannels) || mkpChannels.length === 0) {
      return null
    }

    return (
      <VStack w='full' p='2' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon color='yellow.700' name='local-shipping' family='MaterialIcons' />
          <XText color='yellow.700'>
            Seller Own Fleet <XText variant='inactive'>(ใช้เลขพัสดุตัวเองในการจัดส่งออเดอร์จากช่องทางขาย)</XText>
          </XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'mkpIsSelfDelivery',
            label: 'ใช่',
            value: true,
          },
          {
            key: 'mkpIsSelfDelivery',
            label: 'ไม่ใช่',
            value: false,
          },
        ])}
      </VStack>
    )
  }

  renderFilterShippingTypes = () => {
    const { selectorShippingTypeIds = [], isFilterShippingTypesDisabled = false } = this.getSetting()
    if (isFilterShippingTypesDisabled) {
      return null
    }
    // if (this.state.resetShippingTypeSelectorSegmentView) {
    //   return null
    // }
    let visibleShippingTypes = util.getVisibleShippingTypesIds()
    if (_.isNil(visibleShippingTypes) || visibleShippingTypes.length < 1) {
      visibleShippingTypes = util.getDefaultVisibleShippingTypesIds()
    }
    // const selectorShippingType = _.isNil(this.state.setting.selectorShippingTypeIds) ? [] : this.state.setting.selectorShippingTypeIds

    return (
      <VStack w='full' p='2' space='1' bg='white'>
        {/* <View style={{ marginTop: 0, height: 1, width: '100%', borderTopWidth: 0.5, borderTopColor: COLORS.TEXT_INACTIVE }} /> */}
        <ShippingTypeSelectorSegmentView
          customHeaderSegment={this._renderCustomHeaderShippingTypeSelectorSegment}
          footerLable='ยืนยัน'
          headertitle='เลือกรูปแบบจัดส่ง'
          activeShippingTypeIds={selectorShippingTypeIds}
          visibleShippingTypes={visibleShippingTypes}
          onSubmit={this.onSubmitNewShippingTypeIds}
        />
      </VStack>
    )
  }

  renderFilterOrderOrigin = () => {
    // const { forceMkpOnly, forceXSellyOnly } = this.props
    // const { setting = this._getDefaultSetting(), activeChannels } = this.state
    // if (!isUseMKPChannels()) {
    //   return null
    // }
    const { segmentOrderOriginIndex = 2, isFilterOrderOriginDisabled = false } = this.getSetting()

    if (isFilterOrderOriginDisabled) {
      return null
    }

    return (
      <VStack w='full' p='2' space='1'>
        <HStack alignItems='center' space='1'>
          {/* <XIcon variant='active' name='local-shipping' family='MaterialIcons' /> */}
          <XText variant='inactive'>เฉพาะออเดอร์ในระบบ...</XText>
        </HStack>

        <Segment
          selectedIndex={segmentOrderOriginIndex}
          options={['XSelly', 'ช่องทางขาย', 'ทั้งหมด']}
          onSegmentChange={this.onChangeSegmentOrderOriginAtIndex}
        />
        <XDataProtectionPolicyShopee />
        {segmentOrderOriginIndex === 1 ? (
          <VStack w='full'>
            <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap' space='1.5'>
              {this._renderMkpChannelSelector()}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  _onOrderChannelSelectorItemPress = async (ch, index: number) => {
    const newSetting = this._getNewSetting()

    const { orderChannelActiveMap: oneOne = {} } = newSetting
    const orderChannelActiveMap = { ...oneOne }
    const orderChId = ch.id

    if (_.isNil(orderChannelActiveMap[orderChId])) {
      orderChannelActiveMap[orderChId] = true
    } else if (_.isBoolean(orderChannelActiveMap[orderChId])) {
      orderChannelActiveMap[orderChId] = !orderChannelActiveMap[orderChId]
    }

    newSetting.orderChannelActiveMap = orderChannelActiveMap

    let isAllFalse = true
    const mChIds = Object.keys(newSetting.orderChannelActiveMap).map((mChId) => parseInt(mChId))
    for (let i = 0; i < mChIds.length; i++) {
      const mChId = mChIds[i]
      if (newSetting.orderChannelActiveMap[mChId]) {
        isAllFalse = false
        break
      }
    }

    if (isAllFalse) {
      return
    }

    await this._updateNewSetting(newSetting)
  }

  _renderOrderChannelItem = (oCh, index) => {
    const { orderChannelActiveMap = {} } = this.getSetting()
    const checked = orderChannelActiveMap[oCh.id] || false

    return (
      <HStack key={`order-item-${oCh.id}_${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onOrderChannelSelectorItemPress(oCh, index)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {oCh.label}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  renderFilterOrderChannels = () => {
    // const { forceMkpOnly, forceXSellyOnly } = this.props
    // const { setting = this._getDefaultSetting(), activeChannels } = this.state
    // if (!isUseMKPChannels()) {
    //   return null
    // }
    const { segmentOrderChannelsIndex = 0, isFilterOrderChannelsDisabled = false } = this.getSetting()

    if (isFilterOrderChannelsDisabled) {
      return null
    }

    return (
      <VStack w='full' p='2' space='1'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='locate' family='Ionicons' />
          <XText variant='inactive'>ช่องทางการขาย</XText>
        </HStack>

        <Segment
          selectedIndex={segmentOrderChannelsIndex}
          options={['ทั้งหมด', 'ระบุ']}
          onSegmentChange={this.onChangeSegmentOrderChannelsAtIndex}
        />

        {segmentOrderChannelsIndex === 1 ? (
          <VStack w='full'>
            <XText variant='inactive'>แสดงเฉพาะช่องทางการขายเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap' space='1.5'>
              {OrderChannelItems.map(this._renderOrderChannelItem)}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  renderOrderLockStatus = () => {
    const { isFilterIsCodDisabled = false } = this.getSetting()
    if (isFilterIsCodDisabled) {
      return null
    }
    return (
      <VStack w='full' p='2' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon family='MaterialIcons' name='lock' style={{ color: COLORS.FORM_SUCCESS }} />
          <XText variant='inactive'>สถานะล็อกออเดอร์</XText>
        </HStack>
        {this.renderGenericCheckButtons([
          {
            key: 'lockOrder',
            label: 'ล็อก',
            value: true,
          },
          {
            key: 'lockOrder',
            label: 'ไม่ล็อก',
            value: false,
          },
        ])}
      </VStack>
    )
  }

  _onErpChannelActiveMapPress = async (erpChannelId: number) => {
    const newSetting = this._getNewSetting()

    const { erpChannelActiveMap: oneOne = {} } = newSetting
    const erpChannelActiveMap = { ...oneOne }

    if (_.isNil(erpChannelActiveMap[erpChannelId])) {
      erpChannelActiveMap[erpChannelId] = true
    } else if (_.isBoolean(erpChannelActiveMap[erpChannelId])) {
      erpChannelActiveMap[erpChannelId] = !erpChannelActiveMap[erpChannelId]
    }
    newSetting.erpChannelActiveMap = erpChannelActiveMap

    let isAllFalse = true
    const echIds = Object.keys(newSetting.erpChannelActiveMap).map((echId) => parseInt(echId))
    for (let i = 0; i < echIds.length; i++) {
      const echId = echIds[i]
      if (newSetting.erpChannelActiveMap[echId]) {
        isAllFalse = false
        break
      }
    }

    if (isAllFalse) {
      return
    }

    await this._updateNewSetting(newSetting)
  }

  _renderErpChannelSelectorItem = (erpChannel: IMap<IERPChannel>, idx: number) => {
    const { erpChannelActiveMap = {} } = this.getSetting()
    const erpChannelId = erpChannel.get('id')
    const checked = erpChannelActiveMap[erpChannelId]

    return (
      <HStack key={`${idx}_${erpChannelId}`} pb='1'>
        <TouchableOpacity onPress={() => this._onErpChannelActiveMapPress(erpChannelId)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <ErpLogo width={24} height={24} erpSystemId={erpChannel.get('erp_system_id')} />
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {erpChannel.get('name')}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderErpChannelSelector = () => {
    const { erpChannels } = this.props

    if (_.isNil(erpChannels) || erpChannels.size < 1) {
      return null
    }

    // console.log('erpChannels => ', erpChannels)
    return erpChannels.map(this._renderErpChannelSelectorItem)
  }

  _onChangeSegmentErpChannelAtIndex = async (index) => {
    const { erpChannels } = this.props
    const newSetting = this._getNewSetting()

    newSetting.segmentErpChannelWhichInIndex = index

    if (index === 0) {
      newSetting.erpChannelActiveMap = {}
    } else if (index === 1) {
      newSetting.erpChannelActiveMap = {}
      for (let i = 0; i < erpChannels.size; i++) {
        newSetting.erpChannelActiveMap[erpChannels.getIn([i, 'id'])] = true
      }
    }

    await this._updateNewSetting(newSetting)
  }

  renderFilterErpChannels = () => {
    const { segmentErpChannelWhichInIndex = 0 } = this.getSetting()

    return (
      <VStack w='full' p='2' space='1'>
        <HStack alignItems='center' space='1'>
          {/* <XIcon variant='active' name='local-shipping' family='MaterialIcons' /> */}
          <XText variant='inactive'>ชื่อระบบบัญชี</XText>
        </HStack>

        <Segment
          selectedIndex={segmentErpChannelWhichInIndex}
          options={['ทั้งหมด', 'ระบุ']}
          onSegmentChange={this._onChangeSegmentErpChannelAtIndex}
        />

        {segmentErpChannelWhichInIndex === 1 ? (
          <VStack w='full'>
            <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap' space='1'>
              {this._renderErpChannelSelector()}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  _onErpDocTypeActiveMapPress = async (erpDocTypeId: number) => {
    const newSetting = this._getNewSetting()

    const { erpDocTypeNotInActiveMap: notInMap = {}, erpDocTypeActiveMap: inMap = {} } = newSetting
    const erpDocTypeActiveMap = { ...inMap }
    const erpDocTypeNotInActiveMap = { ...notInMap }

    if (_.isNil(erpDocTypeActiveMap[erpDocTypeId])) {
      erpDocTypeActiveMap[erpDocTypeId] = true
      erpDocTypeNotInActiveMap[erpDocTypeId] = false
    } else if (_.isBoolean(erpDocTypeActiveMap[erpDocTypeId])) {
      const newValue = !erpDocTypeActiveMap[erpDocTypeId]
      erpDocTypeActiveMap[erpDocTypeId] = newValue
      if (newValue === true) {
        erpDocTypeNotInActiveMap[erpDocTypeId] = false
      }
    }

    newSetting.erpDocTypeActiveMap = erpDocTypeActiveMap
    newSetting.erpDocTypeNotInActiveMap = erpDocTypeNotInActiveMap

    // let isAllFalse = true
    // const edtIds = Object.keys(newSetting.erpDocTypeActiveMap).map((edtId) => parseInt(edtId))
    // for (let i = 0; i < edtIds.length; i++) {
    //   const edtId = edtIds[i]
    //   if (newSetting.erpDocTypeActiveMap[edtId]) {
    //     isAllFalse = false
    //     break
    //   }
    // }

    // if (isAllFalse) {
    //   return
    // }

    await this._updateNewSetting(newSetting)
  }

  _renderErpDocTypeSelectorItem = (erpDocType: IErpDocTypeOption, idx: number) => {
    const { visibleErpDocTypeIds = [12, 14] } = this.props
    const { erpDocTypeActiveMap = {} } = this.getSetting()
    const erpDocTypeId = erpDocType.id
    const checked = erpDocTypeActiveMap[erpDocTypeId]

    if (!_.includes(visibleErpDocTypeIds, erpDocTypeId)) {
      return null
    }

    return (
      <HStack key={`erpDocTypeItem_${idx}_${erpDocTypeId}`} pb='1'>
        <TouchableOpacity onPress={() => this._onErpDocTypeActiveMapPress(erpDocTypeId)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {erpDocType.label}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderErpDocTypeSelector = () => {
    const { erpChannels } = this.props

    if (_.isNil(erpChannels) || erpChannels.size < 1) {
      return null
    }

    return CONS.ERP_DOC_TYPE_OPTIONS.map(this._renderErpDocTypeSelectorItem)
  }

  _onChangeSegmentErpDocTypeWhichInIndex = async (index) => {
    const { visibleErpDocTypeIds = [12, 14] } = this.props
    const newSetting = this._getNewSetting()

    const { erpDocTypeNotInActiveMap = {}, segmentErpDocTypeWhichNotInIndex = 0 } = newSetting

    newSetting.segmentErpDocTypeWhichInIndex = index

    if (index === 0) {
      newSetting.erpDocTypeActiveMap = {}
    }

    if (index === 1 && segmentErpDocTypeWhichNotInIndex === 0) {
      newSetting.erpDocTypeActiveMap = {}
      for (let i = 0; i < visibleErpDocTypeIds.length; i++) {
        newSetting.erpDocTypeActiveMap[visibleErpDocTypeIds[i]] = true
      }
    }

    if (index === 1 && segmentErpDocTypeWhichNotInIndex === 1) {
      newSetting.erpDocTypeActiveMap = {}
      const erpDocTypeNotInActiveIds = Object.keys(erpDocTypeNotInActiveMap).map((key) => parseInt(key))
      for (let i = 0; i < erpDocTypeNotInActiveIds.length; i++) {
        const erpDocTypeNotInActiveId = erpDocTypeNotInActiveIds[i]
        newSetting.erpDocTypeActiveMap[erpDocTypeNotInActiveId] = !erpDocTypeNotInActiveMap[erpDocTypeNotInActiveId]
      }
    }

    await this._updateNewSetting(newSetting)
  }

  renderFilterErpDocTypes = () => {
    const { segmentErpDocTypeWhichInIndex = 0 } = this.getSetting()

    return (
      <VStack w='full' p='2' space='1'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='receipt' family='MaterialIcons' />
          <XText variant='inactive'>
            ประเภทเอกสารที่ออเดอร์ต้อง<XText bold>มี</XText>
          </XText>
        </HStack>

        <Segment
          selectedIndex={segmentErpDocTypeWhichInIndex}
          options={['ไม่ระบุ', 'ระบุ']}
          onSegmentChange={this._onChangeSegmentErpDocTypeWhichInIndex}
        />

        {segmentErpDocTypeWhichInIndex === 1 ? (
          <VStack w='full'>
            {/* <XText variant='inactive'>แสดงเฉพาะรูปแบบบัญชีเหล่านี้...</XText> */}
            <HStack w='full' flexWrap='wrap' space='1'>
              {this._renderErpDocTypeSelector()}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  // ------

  _onErpDocTypeNotInActiveMapPress = async (erpDocTypeId: number) => {
    const newSetting = this._getNewSetting()

    const { erpDocTypeNotInActiveMap: notInMap = {}, erpDocTypeActiveMap: inMap = {} } = newSetting
    const erpDocTypeActiveMap = { ...inMap }
    const erpDocTypeNotInActiveMap = { ...notInMap }

    if (_.isNil(erpDocTypeNotInActiveMap[erpDocTypeId])) {
      erpDocTypeNotInActiveMap[erpDocTypeId] = true
      erpDocTypeActiveMap[erpDocTypeId] = false
    } else if (_.isBoolean(erpDocTypeNotInActiveMap[erpDocTypeId])) {
      const newValue = !erpDocTypeNotInActiveMap[erpDocTypeId]
      erpDocTypeNotInActiveMap[erpDocTypeId] = newValue
      if (newValue === true) {
        erpDocTypeActiveMap[erpDocTypeId] = false
      }
    }

    newSetting.erpDocTypeActiveMap = erpDocTypeActiveMap
    newSetting.erpDocTypeNotInActiveMap = erpDocTypeNotInActiveMap

    // let isAllFalse = true
    // const edtIds = Object.keys(newSetting.erpDocTypeNotInActiveMap).map((edtId) => parseInt(edtId))
    // for (let i = 0; i < edtIds.length; i++) {
    //   const edtId = edtIds[i]
    //   if (newSetting.erpDocTypeNotInActiveMap[edtId]) {
    //     isAllFalse = false
    //     break
    //   }
    // }

    // if (isAllFalse) {
    //   return
    // }

    await this._updateNewSetting(newSetting)
  }

  _renderErpDocTypeNotInSelectorItem = (erpDocType: IErpDocTypeOption, idx: number) => {
    const { visibleErpDocTypeIds = [12, 14] } = this.props
    const { erpDocTypeNotInActiveMap = {} } = this.getSetting()
    const erpDocTypeId = erpDocType.id
    const checked = erpDocTypeNotInActiveMap[erpDocTypeId]

    if (!_.includes(visibleErpDocTypeIds, erpDocTypeId)) {
      return null
    }

    return (
      <HStack key={`erpDocTypeNotInItem_${idx}_${erpDocTypeId}`} pb='1'>
        <TouchableOpacity onPress={() => this._onErpDocTypeNotInActiveMapPress(erpDocTypeId)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {erpDocType.label}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderErpDocTypeNotInSelector = () => {
    const { erpChannels } = this.props

    if (_.isNil(erpChannels) || erpChannels.size < 1) {
      return null
    }

    return CONS.ERP_DOC_TYPE_OPTIONS.map(this._renderErpDocTypeNotInSelectorItem)
  }

  _onChangeSegmentErpDocTypeWhichNotInIndex = async (index) => {
    const { visibleErpDocTypeIds = [12, 14] } = this.props
    const newSetting = this._getNewSetting()

    const { erpDocTypeActiveMap = {}, segmentErpDocTypeWhichInIndex = 0 } = newSetting

    newSetting.segmentErpDocTypeWhichNotInIndex = index

    if (index === 0) {
      newSetting.erpDocTypeNotInActiveMap = {}
    }

    if (index === 1 && segmentErpDocTypeWhichInIndex === 0) {
      newSetting.erpDocTypeNotInActiveMap = {}
      for (let i = 0; i < visibleErpDocTypeIds.length; i++) {
        newSetting.erpDocTypeNotInActiveMap[visibleErpDocTypeIds[i]] = true
      }
    }

    if (index === 1 && segmentErpDocTypeWhichInIndex === 1) {
      newSetting.erpDocTypeNotInActiveMap = {}
      const erpDocTypeActiveIds = Object.keys(erpDocTypeActiveMap).map((key) => parseInt(key))
      for (let i = 0; i < erpDocTypeActiveIds.length; i++) {
        const erpDocTypeNotInActiveId = erpDocTypeActiveIds[i]
        newSetting.erpDocTypeNotInActiveMap[erpDocTypeNotInActiveId] = !erpDocTypeActiveMap[erpDocTypeNotInActiveId]
      }
    }

    await this._updateNewSetting(newSetting)
  }

  renderFilterErpDocTypesNotIn = () => {
    const { segmentErpDocTypeWhichNotInIndex = 0 } = this.getSetting()

    return (
      <VStack w='full' p='2' space='1'>
        <HStack alignItems='center' space='1'>
          <Box>
            <XIcon variant='active' name='receipt' family='MaterialIcons' />
            <HStack
              alignItems='flex-start'
              justifyContent='flex-start'
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                borderRadius: 7,
                backgroundColor: 'white',
                width: 14,
                height: 14,
              }}>
              <XIcon
                variant='danger'
                name='close'
                family='MaterialIcons'
                style={{
                  position: 'absolute',
                  top: -4,
                  fontSize: 14,
                  width: 14,
                  height: 14,
                  // backgroundColor: 'red',
                }}
              />
            </HStack>
          </Box>
          <XText variant='inactive'>
            ประเภทเอกสารที่ออเดอร์ต้อง<XText bold>ไม่มี</XText>
          </XText>
        </HStack>

        <Segment
          selectedIndex={segmentErpDocTypeWhichNotInIndex}
          options={['ไม่ระบุ', 'ระบุ']}
          onSegmentChange={this._onChangeSegmentErpDocTypeWhichNotInIndex}
        />

        {segmentErpDocTypeWhichNotInIndex === 1 ? (
          <VStack w='full'>
            {/* <XText variant='inactive'>แสดงเฉพาะรูปแบบบัญชีเหล่านี้...</XText> */}
            <HStack w='full' flexWrap='wrap' space='1'>
              {this._renderErpDocTypeNotInSelector()}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }
  // ------------------ Filter render method (END) ----------------------

  _onCollapseStateChange = (newCollapseState: { [key: string]: boolean }) => {
    this.setState({ collapseState: newCollapseState }, () => {
      p.op.storageSet(CONS.STATE_SAVE_KEYS.ORDER_LIST_FILTER_COLLAPSE_STATE, newCollapseState, true)
    })
  }

  renderSectionFilterMain = () => {
    const { erpChannels } = this.props
    const { collapseState } = this.state

    const {
      isFilterOrderCreateDisabled = false,
      isFilterIsCodDisabled = false,
      isFilterIsShipBeforePayDisabled = false,
      isFilterPrintedAtDisabled = false,
      isFilterDateDeliveryDisabled = false,
      isFilterPaymentCompletedAtDisabled = false,
      isFilterShippingCompletedAtDisabled = false,
      isFilterReadyToShipDateDisabled = false,
      isFilterAutoCancelOrderDisabled = false,
      isFilterShippingTypesDisabled = false,
      isFilterMkpIsSelfDeliveryDisabled = false,
      isFilterOrderOriginDisabled = false,
      isFilterErpChannelsDisabled = false,
      isFilterErpDocTypesDisabled = false,
      isFilterIsTaxInvoiceRequestedDisabled = false,
      isFilterOrderChannelsDisabled = false,
      isFilterSortByDisabled = false,
      isFilterSortTypeDisabled = false,
    } = this.getSetting()

    const isSortSectionDisabled = isFilterSortByDisabled && isFilterSortTypeDisabled
    const isOrderSectionDisabled = isFilterOrderCreateDisabled && isFilterOrderOriginDisabled && isFilterOrderChannelsDisabled

    const isPaymentSectionDisabled =
      isFilterPaymentCompletedAtDisabled &&
      isFilterIsCodDisabled &&
      isFilterAutoCancelOrderDisabled &&
      isFilterIsTaxInvoiceRequestedDisabled

    const isShippingSectionDisabled =
      isFilterReadyToShipDateDisabled &&
      isFilterShippingCompletedAtDisabled &&
      isFilterPrintedAtDisabled &&
      isFilterIsShipBeforePayDisabled &&
      isFilterDateDeliveryDisabled &&
      isFilterShippingTypesDisabled &&
      isFilterMkpIsSelfDeliveryDisabled

    const isErpSectionDisabled =
      _.isNil(erpChannels) || erpChannels.size < 1 || (isFilterErpChannelsDisabled && isFilterErpDocTypesDisabled)

    return (
      <VStack w='full' p='1' space='2'>
        <FilterSection
          collapseKey='sort'
          title='การเรียง'
          collapseState={collapseState}
          onCollapseStateChange={this._onCollapseStateChange}
          visible={!isSortSectionDisabled}>
          {this.renderFilterSortType()}
          {this.renderFilterSortBy()}
        </FilterSection>

        <FilterSection
          collapseKey='order'
          title='ออเดอร์'
          collapseState={collapseState}
          onCollapseStateChange={this._onCollapseStateChange}
          visible={!isOrderSectionDisabled}>
          {this.renderFilterOrderCreate()}
          {this.renderFilterOrderOrigin()}
          {this.renderFilterOrderChannels()}
          {this.renderOrderLockStatus()}
        </FilterSection>

        <FilterSection
          collapseKey='payment'
          title='ชำระ'
          collapseState={collapseState}
          onCollapseStateChange={this._onCollapseStateChange}
          visible={!isPaymentSectionDisabled}>
          {this.renderFilterPaymentCompletedAt()}
          {this.renderFilterIsCod()}
          {this.renderFilterAutoCancelOrder()}
          {this.renderFilterIsTaxInvoiceRequested()}
        </FilterSection>

        <FilterSection
          collapseKey='shipment'
          title='จัดส่ง'
          collapseState={collapseState}
          onCollapseStateChange={this._onCollapseStateChange}
          visible={!isShippingSectionDisabled}>
          {this.renderFilterReadyToShip()}
          {this.renderFilterShippingCompletedAt()}
          {this.renderFilterPrintedAt()}
          {this.renderFilterIsShipBeforePay()}
          {this.renderFilterDateDelivery()}
          {this.renderFilterMkpIsSelfDelivery()}
          {this.renderFilterShippingTypes()}
        </FilterSection>

        <FilterSection
          collapseKey='erp'
          title='ระบบบัญชี'
          collapseState={collapseState}
          onCollapseStateChange={this._onCollapseStateChange}
          visible={!isErpSectionDisabled}>
          {this.renderFilterErpDocTypes()}
          {this.renderFilterErpDocTypesNotIn()}
          {this.renderFilterErpChannels()}
        </FilterSection>
      </VStack>
    )
  }

  // main render form
  renderFilterContent = () => this.renderSectionFilterMain()
  // renderFilterContent = () => (
  //   <XCard w='full'>
  //     {/* {this.renderHeader()} */}
  //     {/*
  // isFilterIsCodDisabled?: boolean
  // isFilterIsShipBeforePayDisabled?: boolean
  // isFilterPrintedAtDisabled?: boolean
  // isFilterDateDeliveryDisabled?: boolean
  // isFilterAutoCancelOrderDisabled?: boolean
  // isFilterShippingTypesDisabled?: boolean
  // isFilterOrderOriginDisabled?: boolean
  //  */}

  //     {this.renderSectionFilterMain()}

  //     <HStack h={p.op.isWeb() ? '12' : undefined} minH='12' w='full' p='2' space='2' borderTopWidth='1' borderTopColor='gray.400'>
  //       <XButton w='100px' h='9' variant='outline' onPress={this._onResetFilter}>
  //         ล้าง
  //       </XButton>
  //       <XButton flex={1} h='9' onPress={this._onApplyFilter}>
  //         กรอง
  //       </XButton>
  //     </HStack>
  //   </XCard>
  // )

  // renderHeader = () => (
  //   <XCustomHeader title={this.props.headerTitle || 'กรอง'} headerRightProps={{ closeIcon: true, onPressItem: this.closeFilterOverlay }} />
  // )

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return this.renderFilterContent()
  }
}

// export default connect(
//   (state) => ({
//     selectedStoreOrdersDates: getSelectedStoreOrdersDates(state),
//     selectedStoreOrdersDatesOptionKey: getSelectedStoreOrdersDatesOptionKey(state),
//     erpChannels: getSelectedStoreErpChannels(state),
//   }),
//   (dispatch) => ({
//     dispatch,
//     updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
//   })
// )(OrderListFilter)

export default OrderListFilter
