// This is common navigation file to shared NavActions
import { Platform } from 'react-native'
import { CommonActions as NavigationActions, StackActions } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import xCONS from '../config/constants'
import {
  IQuickAddress,
  MultiOpMode,
  IMKPChannelDetail,
  IProductListViewMyStoreNavParams,
  IProductListViewMyStoreWithVolumeDiscountNavParams,
  IProductListViewSellersStoreNavParams,
  IProductListViewPullSellersProductsToMyStoreNavParams,
  IProductListViewPullSellersProductsToOrderNavParams,
  IProductListViewPullMyProductsToOrderNavParams,
  IProductListViewPullSellersProductsToSalePageNavParams,
  IProductListViewPullMyProductsToSalePageNavParams,
  IProductListViewMkpMappingParams,
  IPullProductRequestBody,
  IProductDetailItem,
  IProductViewPullSellersProductsToOrderNavParams,
  IProductViewPullMyProductsToOrderNavParams,
  IMkpProductDetailNavParams,
  IMkpProductDetailMappingNavParams,
  IBackgroundJobListViewNavParams,
  IBackgroundTaskListViewNavParams,
  IBackgroundJobInProcessByTypeViewNavParams,
  IAutoCompleteOrderTabItemsViewNavParams,
  IMultipleOrdersOperationMenuViewNavParams,
  IProductVariantEditorNavParams,
  IProductDetailMkpInfoDetailViewNavParams,
  IProductListViewMkpViewParams,
  IConversationViewNavParams,
  IChatSettingViewNavParams,
  IShippingViewNavParams,
  IOrderListViewNavParams,
  IPaymentOrderListViewNavParams,
  IAddress,
  IPaymentViewNavParams,
  IPaymentListViewNavParams,
  IStorePaymentAccountListViewNavParams,
  IOrderViewParams,
  IPaperangViewParams,
  IProductListViewXSellyToMkpMappingParams,
  IProductVariantXSellyToMkpMappingViewNavParams,
  IMkpMappingChannelListViewPickerNavParams,
  IProductListViewMkpToXSellyMappingParams,
  IProductVariantMkpToXSellyMappingViewNavParams,
  IDashboardViewNavParams,
  IDailySaleChartViewNavParams,
  IVerifySlipDetailNavParams,
  IVerifySlipCreditHistoryNavParams,
  IStoreSettingVerifySlipNavParams,
  IERPSubmitDocumentOrderListViewNavParams,
  IAddressFormViewNavParams,
  IOrderListFilterViewNavParams,
  IOrderListFilterManageViewNavParams,
  IOrderListFilterEditorViewNavParams,
  IStoreContactListViewNavParams,
  IStoreContactDetailNavParams,
  IMkpAutoMappingVariantsViewNavParams,
  IXSellyVariantMappingSelectorViewNavParams,
  IStoreAddressListViewNavParams,
  IQtyAdjustmentViewNavParams,
  ISearchProductVariantListViewNavParams,
  IQtyAdjustmentConfirmationViewNavParams,
  IQtyAdjustmentListViewNavParams,
  // @ts-ignore
  IStoreWebsiteSettingWebViewParams,
  IProductListFilterViewNavParams,
  IProductListFilterEditorViewNavParams,
  IProductListFilterManageViewNavParams,
  IProductVariantListFilterViewNavParams,
} from '../types'

// // --------------- Navigation util functions -----------------------------------------------------

// type IXComp<T extends object = IAnyObject> = Readonly<IXScreenProps<T>>

// export function getParam<T extends object, Key extends keyof T>(
//   props: IXComp<T>,
//   paramKey: Key,
//   defaultValue: T[Key] = undefined
// ): T[Key] | undefined {
//   try {
//     return props.route.params[paramKey]
//   } catch (err) {
//     console.log('NavActions::getParam err => ', err)
//     return _.isUndefined(defaultValue) ? undefined : defaultValue
//   }
// }

// export function goBack<T extends object, Key extends keyof T>(props: IXComp<T>) {
//   try {
//     props.navigation.goBack()
//   } catch (err) {
//     console.log('NavActions::goBack err => ', err)
//   }
// }

// --------------- Nav Actions -----------------------------------------------------
// Background Job
export const navToBackgroundJobListView = (params: IBackgroundJobListViewNavParams) =>
  NavigationActions.navigate({
    name: 'BackgroundJobListView',
    params,
  })

export const navToBackgroundTaskListView = (params: IBackgroundTaskListViewNavParams) =>
  NavigationActions.navigate({
    name: 'BackgroundTaskListView',
    params,
  })

export const navToBackgroundJobInProcessByTypeView = (params: IBackgroundJobInProcessByTypeViewNavParams) =>
  NavigationActions.navigate({
    name: 'BackgroundJobInProcessByTypeView',
    params,
  })

// Product List
export const navToProductListViewMyStore = (params: IProductListViewMyStoreNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListViewMyStore',
    params,
  })
export const navToProductListViewMyStoreWithVolumeDiscount = (params: IProductListViewMyStoreWithVolumeDiscountNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListViewMyStoreWithVolumeDiscount',
    params,
  })

export const navToProductListViewSellersStore = (params: IProductListViewSellersStoreNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListViewSellersStore',
    params,
  })

export const navToProductListViewPullSellersProductsToOrder = (params: IProductListViewPullSellersProductsToOrderNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListViewPullSellersProductsToOrder',
    params,
  })

export const navToProductListViewPullMyProductsToOrder = (params: IProductListViewPullMyProductsToOrderNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListViewPullMyProductsToOrder',
    params,
  })

export const navToProductListViewPullSellersProductsToMyStore = (params: IProductListViewPullSellersProductsToMyStoreNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListViewPullSellersProductsToMyStore',
    params,
  })

export const navToProductListViewPullMyProductsToSalePage = (params: IProductListViewPullMyProductsToSalePageNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListViewPullMyProductsToSalePage',
    params,
  })

export const navToProductListViewPullSellersProductsToSalePage = (params: IProductListViewPullSellersProductsToSalePageNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListViewPullSellersProductsToSalePage',
    params,
  })
export const navToSearchableProductListView = (
  params: IProductViewPullSellersProductsToOrderNavParams | IProductViewPullMyProductsToOrderNavParams
) =>
  NavigationActions.navigate({
    name: 'SearchableProductListView',
    params,
  })

// Marketplace ProductList
export const navToProductListViewMkpMapping = (params: IProductListViewMkpMappingParams) =>
  NavigationActions.navigate({
    key: 'ProductListViewMkpMapping',
    name: 'ProductListViewMkpMapping',
    params,
  })

export const navToProductListViewMkpView = (params: IProductListViewMkpViewParams) =>
  NavigationActions.navigate({
    key: 'ProductListViewMkpView',
    name: 'ProductListViewMkpView',
    params,
  })

export const navToProductListViewXSellyToMkpMapping = (params: IProductListViewXSellyToMkpMappingParams) =>
  NavigationActions.navigate({
    key: 'ProductListViewXSellyToMkpMapping',
    name: 'ProductListViewXSellyToMkpMapping',
    params,
  })

export const navToProductListViewMkpToXSellyMapping = (params: IProductListViewMkpToXSellyMappingParams) =>
  NavigationActions.navigate({
    key: 'ProductListViewMkpToXSellyMapping',
    name: 'ProductListViewMkpToXSellyMapping',
    params,
  })

// Product Detail
// เพิ่มสินค้า
export const navToProductViewAdd = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ProductView',
    name: 'ProductView',
    params: {
      isModal: true,
      mode: xCONS.PRODUCT_VIEW_MODE.ADD,
      store_id,
    },
  })

// generic nav by mode
export const navToProductView = (params) =>
  NavigationActions.navigate({
    key: 'ProductView',
    name: 'ProductView',
    params,
  })

// ดูสินค้า/แก้ไขสินค้าร้านฉัน
export const navToProductViewSelf = ({ store_id, product_id }) =>
  NavigationActions.navigate({
    key: 'ProductView',
    name: 'ProductView',
    params: { mode: xCONS.PRODUCT_VIEW_MODE.VIEW_SELF, store_id, product_id },
  })

// ดูสินค้า/แก้ไขสินค้าร้านผู้ขาย
// export const navToProductViewSeller = ({ store_id, product_id, seller_store_id }) => NavigationActions.navigate({
//   name: 'ProductView',
//   params: { mode: xCONS.PRODUCT_VIEW_MODE.VIEW_SELLER, store_id, product_id, seller_store_id },
// })
export const navToProductViewSeller = ({ product_id, seller_store_id }) =>
  NavigationActions.navigate({
    key: 'ProductView',
    name: 'ProductView',
    params: { mode: xCONS.PRODUCT_VIEW_MODE.VIEW_SELLER, store_id: seller_store_id, product_id, seller_store_id },
  })

// สำหรับ สร้างใบสั่งซื้อ
export const navToProductListPullMyProductToOrder = ({ store_id, ug_id, pg_ids }) =>
  NavigationActions.navigate({
    key: 'ProductList',
    name: 'ProductList',
    params: { mode: xCONS.PRODUCT_VIEW_MODE.PULL_MY_PRODUCT_TO_ORDER, store_id, ug_id, pg_ids },
  })

export const navToProductListPullSellerProductToOrder = ({ store_id, seller_store_id, order_use_retail_price }) =>
  NavigationActions.navigate({
    key: 'ProductList',
    name: 'ProductList',
    params: { mode: xCONS.PRODUCT_VIEW_MODE.PULL_SELLER_PRODUCT_TO_ORDER, store_id, seller_store_id, order_use_retail_price },
  })

export const navToMkpProductLinkManagerView = (params: { store_id: number; product_id: number }) =>
  NavigationActions.navigate({
    key: 'MkpProductLinkManagerView',
    name: 'MkpProductLinkManagerView',
    params,
  })

// export const navToProductViewPullMyProductToOrder = ({ store_id, product_id, order_use_retail_price, ug_id, pg_ids, successCallback }) =>
//   NavigationActions.navigate({
//     key: 'ProductView',
//     name: 'ProductView',
//     params: {
//       mode: xCONS.PRODUCT_VIEW_MODE.PULL_MY_PRODUCT_TO_ORDER,
//       store_id,
//       product_id,
//       order_use_retail_price,
//       ug_id,
//       pg_ids,
//       successCallback,
//     },
//   })

export const navToProductViewPullMyProductToOrder = (params: IProductViewPullMyProductsToOrderNavParams) =>
  NavigationActions.navigate({
    key: 'ProductView',
    name: 'ProductView',
    params: {
      mode: xCONS.PRODUCT_VIEW_MODE.PULL_MY_PRODUCT_TO_ORDER,
      ...params,
    },
  })

export const navToProductViewPullSellerProductToOrder = (params: IProductViewPullSellersProductsToOrderNavParams) =>
  NavigationActions.navigate({
    key: 'ProductView',
    name: 'ProductView',
    params: {
      mode: xCONS.PRODUCT_VIEW_MODE.PULL_SELLER_PRODUCT_TO_ORDER,
      ...params,
    },
  })

// สำหรับ ดึงสินค้าเข้าร้านฉัน
export const navToProductListPullProduct = ({ store_id, seller_store_id }) =>
  NavigationActions.navigate({
    name: 'ProductList',
    params: { mode: xCONS.PRODUCT_VIEW_MODE.PULL_PRODUCT, store_id, seller_store_id },
  })

interface INavToProductViewPullProductParams {
  // mode: string // 'PULL_PRODUCT'
  store_id: number
  product_id: number
  seller_store_id: number
  successCallback?: () => void // old usage

  // new callback
  onSuccessPullSellersProductToMyStoreCallback?: (requestBody: IPullProductRequestBody) => Promise<void>
}
export const navToProductViewPullProduct = (params: INavToProductViewPullProductParams) =>
  NavigationActions.navigate({
    name: 'ProductView',
    params: {
      mode: xCONS.PRODUCT_VIEW_MODE.PULL_PRODUCT,
      ...params,
    },
  })

interface INavToProductViewMyStoreParams {
  // mode: string // 'VIEW_SELF'
  store_id: number
  product_id: number

  isFromMkpProductView?: boolean

  // new callback
  onSuccessAddProductCallback?: (addedProduct: IProductDetailItem) => Promise<void>
  onSuccessEditProductCallback?: (editedProduct: IProductDetailItem) => Promise<void>
  onSuccessDeleteProductCallback?: (requestDeletedProductId: number) => Promise<void>

  navAfterInit?: (navigation: StackNavigationProp<any>) => void
}

export const navToProductViewMyStore = (params: INavToProductViewMyStoreParams) =>
  NavigationActions.navigate({
    name: 'ProductView',
    params: {
      mode: xCONS.PRODUCT_VIEW_MODE.VIEW_SELF,
      ...params,
    },
  })

interface INavToProductViewAddProductParams {
  // mode: string // 'ADD'
  store_id: number

  // new callback
  onSuccessAddProductCallback?: (addedProduct: IProductDetailItem) => Promise<void>
  onSuccessEditProductCallback?: (editedProduct: IProductDetailItem) => Promise<void>

  // web usage
  isGoBackButtonHidden?: boolean
}

export const navToProductViewAddProduct = (params: INavToProductViewAddProductParams) =>
  NavigationActions.navigate({
    name: 'ProductView',
    params: {
      mode: xCONS.PRODUCT_VIEW_MODE.ADD,
      ...params,
    },
  })

export const navToProductVariantEditorView = (params: IProductVariantEditorNavParams) =>
  NavigationActions.navigate({
    name: 'ProductVariantEditorView',
    params,
  })

// ตอนนี้อ่านข้อมูลส่วนใหญ่ใน redux (จากหน้า ProductDetail) แต่ส่ง params ไว้ล่วงหน้า
export const navToProductDetailMkpInfoDetailView = (params: IProductDetailMkpInfoDetailViewNavParams) =>
  NavigationActions.navigate({
    name: 'ProductDetailMkpInfoDetailView',
    params,
  })

type IOrderViewParamsModified = Omit<IOrderViewParams, 'mode'> & {
  mode?: string
}

export const navToOrderDetail = (params: IOrderViewParamsModified) =>
  NavigationActions.navigate({
    name: 'OrderView',
    params: {
      mode: xCONS.ORDER_VIEW_MODE.VIEW_EDIT,
      ...params,
    },
    // key: Math.round(Math.random() * 100000).toString(),
    // key: 'OrderDetail',
  })

export const navToOrderCreate = (params: IOrderViewParamsModified) =>
  NavigationActions.navigate({
    name: 'OrderViewCreate',
    params: {
      mode: xCONS.ORDER_VIEW_MODE.CREATE,
      ...params,
    },
    // key: Math.round(Math.random() * 100000).toString(),
    // key: 'OrderDetail',
  })

export const navToOrderListFilterView = (params: IOrderListFilterViewNavParams) =>
  NavigationActions.navigate({
    name: 'OrderListFilterView',
    params,
  })
export const navToOrderListFilterEditorView = (params: IOrderListFilterEditorViewNavParams) =>
  NavigationActions.navigate({
    name: 'OrderListFilterEditorView',
    params,
  })

export const navToOrderListFilterManageView = (params: IOrderListFilterManageViewNavParams) =>
  NavigationActions.navigate({
    name: 'OrderListFilterManageView',
    params,
  })

export const navToDashboardView = (params: IDashboardViewNavParams) =>
  NavigationActions.navigate({
    name: 'DashboardView',
    params,
  })

export const navToOrderQuickAddress = (params: {
  initAddress: IQuickAddress | IQuickAddress
  mode: string
  store_id: number
  shippingTypeIndex: number
  type: 'SENDER' | 'RECEIVER'
  callBack?: () => void | null
}) =>
  NavigationActions.navigate({
    key: 'OrderQuickAddressView',
    name: 'OrderQuickAddressView',
    params,
  })

export const navToAddressFormView = (params: IAddressFormViewNavParams) =>
  NavigationActions.navigate({
    key: 'AddressFormView',
    name: 'AddressFormView',
    params,
  })

export const navToShipView = ({ order_id, store_id }) =>
  NavigationActions.navigate({
    key: 'ShipView',
    name: 'ShipView',
    params: { order_id, store_id },
  })

export const navToOrderShipList = ({ order_id, store_id }) =>
  NavigationActions.navigate({
    key: 'OrderShipListView',
    name: 'OrderShipListView',
    params: { order_id, store_id },
  })

export const navToAddressListStorePick = ({ store_id }) =>
  NavigationActions.navigate({
    name: 'AddressListView',
    params: { store_id, mode: xCONS.ADDRESS_LIST_MODE_KEYS.STORE_PICK },
  })

export const navToAddressListStorePickChange = ({ store_id }) =>
  NavigationActions.navigate({
    name: 'AddressListView',
    params: { store_id, mode: xCONS.ADDRESS_LIST_MODE_KEYS.STORE_EDIT },
  })

export const navToReportResellerView = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ReportResellerView',
    name: 'ReportResellerView',
    params: { store_id },
  })

export const navToReportHelperSales = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ReportHelperSales',
    name: 'ReportHelperSales',
    params: { store_id },
  })

export const navToDailySalesReportView = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'DailySalesReportView',
    name: 'DailySalesReportView',
    params: { store_id },
  })

export const navToDailySaleChartView = (params: IDailySaleChartViewNavParams) =>
  NavigationActions.navigate({
    key: 'DailySaleChartView',
    name: 'DailySaleChartView',
    params,
  })

export const navToCompleteSalesReportView = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'CompleteSalesReportView',
    name: 'CompleteSalesReportView',
    params: { store_id },
  })

export const navToPaymentPTTTransactions = ({ store_id, ptts, navBackAction = null }) =>
  NavigationActions.navigate({
    key: 'PaymentPTTTransactions',
    name: 'PaymentPTTTransactions',
    params: { store_id, ptts, navBackAction },
  })

export const navToReportProductPriceList = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ReportProductPriceList',
    name: 'ReportProductPriceList',
    params: { store_id },
  })

export const navToReportRequisitionView = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ReportRequisitionView',
    name: 'ReportRequisitionView',
    params: { store_id },
  })

export const navToReportSalesByChannelView = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ReportSalesByChannelView',
    name: 'ReportSalesByChannelView',
    params: { store_id },
  })

export const navToReportShippingView = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ReportShippingView',
    name: 'ReportShippingView',
    params: { store_id },
  })

export const navToReportStockView = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ReportStockView',
    name: 'ReportStockView',
    params: { store_id },
  })

export const navToReportWarehouseAdjustment = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ReportWarehouseAdjustment',
    name: 'ReportWarehouseAdjustment',
    params: { store_id },
  })

export const navToReportImportPeak = ({ store_id }) =>
  NavigationActions.navigate({
    name: 'ReportImportPeak',
    params: { store_id },
  })

export const navToReportProductDailySalesView = ({ store_id, variants = null, reseller = null, userGroupName = null }) =>
  NavigationActions.navigate({
    key: 'ReportProductDailySales',
    name: 'ReportProductDailySales',
    params: { store_id, variants, reseller, userGroupName },
  })

export const navToReportPaymentView = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ReportPayment',
    name: 'ReportPayment',
    params: { store_id },
  })

// Address View with mode
export const navToAddressViewForRegister = ({ address, mode, isStorePickMode, isPrimaryOnly, callBackBody }) =>
  NavigationActions.navigate({
    name: 'AddressViewForRegister',
    params: { address, mode, isStorePickMode, isPrimaryOnly, callBackBody }, // index is optional
  })

export const navToProductWarehousesPicker = ({ warehouses, callBackEditWarehouses = null }) =>
  NavigationActions.navigate({
    key: 'ProductWarehousesPicker',
    name: 'ProductWarehousesPicker',
    params: { warehouses, callBackEditWarehouses },
  })

export const navToChannelAssignProductGroups = ({ store_id, ug_id, callBackMKP = null }) =>
  NavigationActions.navigate({
    name: 'ProductGroupListView',
    params: {
      store_id,
      ug_id,
      callBackMKP,
      is_mkp: true,
      mode: xCONS.PRODUCT_GROUP_LIST_MODE.ASSIGN_TO_UG,
    },
  })

export const navToWarehouseList = ({ store_id }) =>
  NavigationActions.navigate({
    name: 'WarehouseListView',
    params: {
      store_id,
    },
  })

export const navToWarehouseView = ({ warehouse, mode, callBackUpdateWarehouses }) =>
  NavigationActions.navigate({
    name: 'WarehouseView',
    params: {
      warehouse,
      mode,
      callBackUpdateWarehouses,
    },
  })

export const navToAddCoverSheet = ({ store_id, mode, orderTabIndex, pending_shp_lbl_img, onSuccessEditNoteCallback }) =>
  NavigationActions.navigate({
    name: 'AddCoverSheet',
    params: { store_id, mode, orderTabIndex, pending_shp_lbl_img, onSuccessEditNoteCallback },
  })

export const navToAddCoverSheetNote = ({ store_id, mode, orderTabIndex, pending_shp_lbl_note, onSuccessEditNoteCallback }) =>
  NavigationActions.navigate({
    name: 'AddCoverSheetNote',
    params: { store_id, mode, orderTabIndex, pending_shp_lbl_note, onSuccessEditNoteCallback },
  })

export const navToOrderPrivateAndPublicNotes = ({ store_id, mode, orderTabIndex, key, notes }) =>
  NavigationActions.navigate({
    name: 'OrderPrivateAndPublicNotes',
    params: { store_id, mode, orderTabIndex, key, notes },
  })

export const navToMkpProductDetailView = (params: IMkpProductDetailNavParams) => StackActions.push('MkpProductDetailView', params)

export const navToMkpProductDetailMapping = (params: IMkpProductDetailMappingNavParams) =>
  NavigationActions.navigate({
    name: 'MkpProductDetailMapping',
    params,
  })

export const navToProductVariantXSellyToMkpMappingView = (params: IProductVariantXSellyToMkpMappingViewNavParams) =>
  NavigationActions.navigate({
    name: 'ProductVariantXSellyToMkpMappingView',
    params,
  })

export const navToProductVariantMkpToXSellyMappingView = (params: IProductVariantMkpToXSellyMappingViewNavParams) =>
  NavigationActions.navigate({
    name: 'ProductVariantMkpToXSellyMappingView',
    params,
  })

export const navToMkpMappingChannelListViewPicker = (params: IMkpMappingChannelListViewPickerNavParams) =>
  NavigationActions.navigate({
    name: 'MkpMappingChannelListViewPicker',
    params,
  })

export const navToOrderCopyTemplateView = NavigationActions.navigate({
  name: 'OrderCopyTemplateView',
})

// export const navToTimeSlotsView = NavigationActions.navigate({
//   name: 'TimeSlotsView',
// })

export const navToTimeSlotsView = (params: { channel: IMKPChannelDetail; callBackUpdateChannel: (channe: IMKPChannelDetail) => void }) =>
  NavigationActions.navigate({
    key: 'TimeSlotsView',
    name: 'TimeSlotsView',
    params: { ...params },
  })

export const navToStoreOrderSingleTabListView = (params: { tabKey: string; title?: string; multiOpMode?: MultiOpMode }) =>
  NavigationActions.navigate({
    key: 'StoreOrderSingleTabListView',
    name: 'StoreOrderSingleTabListView',
    params: {
      shouldFetchOnlySingleTabKey: true,
      ...params,
    },
  })

export const navToAutoCompleteOrderTabItemsView = (params: IAutoCompleteOrderTabItemsViewNavParams) =>
  NavigationActions.navigate({
    key: 'AutoCompleteOrderTabItemsView',
    name: 'AutoCompleteOrderTabItemsView',
    params,
  })

export const navToAutoCompletePaymentBySegmentView = () =>
  NavigationActions.navigate({
    key: 'AutoCompletePaymentBySegmentViewContainer',
    name: 'AutoCompletePaymentBySegmentViewContainer',
  })

export const navToAutoCompleteShipmentBySegmentView = () =>
  NavigationActions.navigate({
    key: 'AutoCompleteShipmentBySegmentViewContainer',
    name: 'AutoCompleteShipmentBySegmentViewContainer',
  })

// export const navToSellerStorePaymentAccountListView = ({ store_id, title, selectAccountCallback = null }) =>
//   NavigationActions.navigate({
//     name: 'SellerStorePaymentAccountListView',
//     params: { store_id, title, selectAccountCallback },
//   })

export const navToMultipleOrdersOperationMenuView = (params: IMultipleOrdersOperationMenuViewNavParams) =>
  NavigationActions.navigate({
    key: 'MultipleOrdersOperationMenuView',
    name: 'MultipleOrdersOperationMenuView',
    params,
  })

export const navToImportProductQTYView = () =>
  NavigationActions.navigate({
    key: 'importProductQTYView',
    name: 'importProductQTYView',
    // params: { store_id },
  })

export const navToImportEditProductWarehouseBySKU = () =>
  NavigationActions.navigate({
    key: 'importEditProductWarehouseBySKU',
    name: 'importEditProductWarehouseBySKU',
    // params: { store_id },
  })

export const navToImportReduceProductWarehouseBySKU = () =>
  NavigationActions.navigate({
    key: 'importReduceProductWarehouseBySKU',
    name: 'importReduceProductWarehouseBySKU',
    // params: { store_id },
  })

export const navToImportAddProductWarehouseBySKU = () =>
  NavigationActions.navigate({
    key: 'importAddProductWarehouseBySKU',
    name: 'importAddProductWarehouseBySKU',
    // params: { store_id },
  })

export const navToImportProductBarcodeView = () =>
  NavigationActions.navigate({
    key: 'ImportProductBarcodeView',
    name: 'ImportProductBarcodeView',
    // params: { store_id },
  })

export const navToImportWarehouseExcelFromShipjung = () =>
  NavigationActions.navigate({
    key: 'ImportWarehouseExcelFromShipjung',
    name: 'ImportWarehouseExcelFromShipjung',
    // params: { store_id },
  })

export const navToImportAddProductView = () =>
  NavigationActions.navigate({
    key: 'ImportAddProductView',
    name: 'ImportAddProductView',
    // params: { store_id },
  })

export const navToStoreSettingMenuShipping = () =>
  NavigationActions.navigate({
    key: 'StoreStoreSettingMenuShipping',
    name: 'StoreStoreSettingMenuShipping',
    // params: { store_id },
  })

export const navToStoreSettingShippingTypeView = ({ onPressGoBack = null }) =>
  NavigationActions.navigate({
    key: 'StoreSettingShippingTypeView',
    name: 'StoreSettingShippingTypeView',
    params: { onPressGoBack },
  })

export const navToConversationView = (params: IConversationViewNavParams) =>
  NavigationActions.navigate({
    key: params.conversationUUID ? params.conversationUUID : 'ConversationView',
    name: 'ConversationView',
    params,
  })

export const navToChatSettingView = (params: IChatSettingViewNavParams) =>
  NavigationActions.navigate({
    key: 'ChatSettingView',
    name: 'ChatSettingView',
    params,
  })

export const navToImportExcelCODView = () =>
  NavigationActions.navigate({
    key: 'ImportExcelCODView',
    name: 'ImportExcelCODView',
    // params: { store_id },
  })

export const navToImportExcelListView = () =>
  NavigationActions.navigate({
    key: 'importExcelListViewContainer',
    name: 'importExcelListViewContainer',
    // params: { store_id },
  })

export const navToImportExcelCustomerView = () =>
  NavigationActions.navigate({
    key: 'ImportAddCustomerView',
    name: 'ImportAddCustomerView',
    // params: { store_id },
  })

export const navToImportExcelCreateOrderView = () =>
  NavigationActions.navigate({
    key: 'ImportExcelCreateOrderView',
    name: 'ImportExcelCreateOrderView',
    // params: { store_id },
  })

export const navToStoreSettingXShipping = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingView',
    name: 'StoreSettingXShippingView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingKerry = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingKerryView',
    name: 'StoreSettingXShippingKerryView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingBast = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingBestView',
    name: 'StoreSettingXShippingBestView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingJAntT = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingJAndTView',
    name: 'StoreSettingXShippingJAndTView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingFlash = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingFlashView',
    name: 'StoreSettingXShippingFlashView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingNinjaVan = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingNinjaVanView',
    name: 'StoreSettingXShippingNinjaVanView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingSCG = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingSCGView',
    name: 'StoreSettingXShippingSCGView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingDHL = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingDHLView',
    name: 'StoreSettingXShippingDHLView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingEMS = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingEMSView',
    name: 'StoreSettingXShippingEMSView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingEcoPost = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingEcoPostView',
    name: 'StoreSettingXShippingEcoPostView',
    params: { onPressGoBack },
  })

export const navToStoreSettingXShippingSpx = ({ onPressGoBack }) =>
  NavigationActions.navigate({
    key: 'StoreSettingXShippingSpxView',
    name: 'StoreSettingXShippingSpxView',
    params: { onPressGoBack },
  })

export const navToStoreSettingSpx = ({ spxConfig, succeedCallback }) =>
  NavigationActions.navigate({
    key: 'StoreSettingSpxView',
    name: 'StoreSettingSpxView',
    params: { spxConfig, succeedCallback },
  })

export const navToShippingView = (params: IShippingViewNavParams = {}) => {
  const actionPayload = {
    name: 'ShippingView',
    params,
  }

  if (params.fromOrder) {
    // @ts-ignore
    actionPayload.key = 'ShippingViewFromOrderView'
  }

  return NavigationActions.navigate(actionPayload)
}

export const navToJAndTConnectView = ({ callBackSenderAddress }) =>
  NavigationActions.navigate({
    name: 'JAndTConnectView',
    params: { callBackSenderAddress },
  })

export const navToCreateXShippingAddressView = ({ address = null, callBackAddressUpdate = null, titleDescription = null, name = null }) =>
  NavigationActions.navigate({
    name: 'CreateXShippingAddressView',
    params: { address, callBackAddressUpdate, titleDescription, name },
  })

export const navToVolumeDiscountListView = ({ store_id, pg_id, pt_id, pg_vd_ids, mode, productMode, product_group_ids }) =>
  NavigationActions.navigate({
    name: 'VolumeDiscountListView',
    params: { store_id, pg_id, pt_id, pg_vd_ids, mode, productMode, product_group_ids },
  })

export const navToVolumeDiscountView = ({ store_id, volume_discount, mode }) =>
  NavigationActions.navigate({
    name: 'VolumeDiscountView',
    params: { store_id, volume_discount, mode },
  })

export const navToStoreSettingGeneral = NavigationActions.navigate({
  name: 'StoreSettingGeneral',
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const navToAddressListStore = (params: {
  profile_id?: number
  store_id?: number
  onPressAddress?: (address: IAddress) => void
  headerTitle: string
  useSellerAddress?: boolean // หากต้องการให้แสดงรายการเลือกที่อยู่ผู้ขายส่ง
  useSellerAddressCustomText?: string // หากต้องการให้แสดงคำที่ต้องการ
}) =>
  NavigationActions.navigate({
    name: 'AddressListView',
    params,
  })

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const navToAddressView = (params: {
  store_id?: number | null
  headerTitle: string
  type: 'STORE' | 'PROFILE' | 'CUSTOMER'
  profile_id?: number | null
  address?: IAddress // NO address = Create mode
  succeedCallback?: (address: IAddress[]) => void
}) =>
  NavigationActions.navigate({
    name: 'AddressView',
    params,
  })

// export const navToAddressView = ({ address, mode, isStorePickMode, callBackBody = null, isPrimaryOnly = null, callbackResponse = null }) =>
//   NavigationActions.navigate({
//     name: 'AddressView',
//     params: { address, mode, isStorePickMode, callBackBody, isPrimaryOnly, callbackResponse }, // index is optional
//   })
// export const navToAddressListStore = ({ store_id }) =>
//   NavigationActions.navigate({
//     name: 'AddressListView',
//     params: { store_id, mode: xCONS.ADDRESS_LIST_MODE_KEYS.STORE_EDIT },
//   })

// export const navToStorePaymentAccountListView = ({ store_id }) =>
export const navToStorePaymentAccountListView = (params: IStorePaymentAccountListViewNavParams) =>
  NavigationActions.navigate({
    name: 'StorePaymentAccountListView',
    params,
  })

export const navToAssistantGroupList = NavigationActions.navigate({
  name: 'AssistantGroupList',
})

export const navToStoreSettingAllPrintingShipping = NavigationActions.navigate({
  name: 'StoreSettingAllPrintingShipping',
})

export const navToStoreSettingOrderPolicy = NavigationActions.navigate({
  name: 'StoreSettingOrderPolicy',
})

export const navToStoreSettingOrderBillOnlineShowAmount = NavigationActions.navigate({
  name: 'StoreSettingOrderBillOnlineShowAmount',
})

export const navToStoreSettingOrderAutomation = NavigationActions.navigate({
  name: 'StoreSettingOrderAutomation',
})

export const navToStoreSettingLockOrderAutomation = NavigationActions.navigate({
  name: 'StoreSettingLockOrderAutomation',
})

export const navToStoreCancelOrderAutomation = NavigationActions.navigate({
  name: 'StoreSettingStoreCancelOrderAutomation',
})

export const navToStoreSettingShipBeforePayAndCod = NavigationActions.navigate({
  name: 'StoreSettingShipBeforePayAndCod',
})

export const navToStoreSettingOrderNote = NavigationActions.navigate({
  name: 'StoreSettingOrderNote',
})

export const navToStoreSettingVerifySlip = (params: IStoreSettingVerifySlipNavParams) =>
  NavigationActions.navigate({
    name: 'StoreSettingVerifySlip',
    params,
  })

export const navToVerifySlipCreditHistoryView = (params: IVerifySlipCreditHistoryNavParams) =>
  NavigationActions.navigate({
    name: 'VerifySlipCreditHistoryView',
    params,
  })

export const navToVerifySlipDetailView = (params: IVerifySlipDetailNavParams) =>
  NavigationActions.navigate({
    name: 'VerifySlipDetailView',
    params,
  })

export const navToVerifySlipCreditSummaryView = NavigationActions.navigate({
  name: 'VerifySlipCreditSummaryView',
})

export const navToVerifySlipCreditSummaryUnusableView = NavigationActions.navigate({
  name: 'VerifySlipCreditSummaryUnusableView',
})

export const navToCategoryListViewContainer = ({ mode, callBack = null }) =>
  NavigationActions.navigate({
    name: 'CategoryList',
    params: { mode, callBack },
  })

export const navToStoreSettingVolumeDiscount = NavigationActions.navigate({
  name: 'StoreSettingVolumeDiscount',
})

export const navToStoreSettingStoreProductSN = NavigationActions.navigate({
  name: 'StoreSettingStoreProductSN',
})

export const navToOtherList = ({ store_id }) =>
  NavigationActions.navigate({
    name: 'StoreSettingPeakView',
    params: {
      store_id,
    },
  })

export const navToStoreSettingPaperang = NavigationActions.navigate({
  name: 'StoreSettingPaperang',
})

export const navToStoreSettingPDFShippingLabel = NavigationActions.navigate({
  name: 'StoreSettingPDFShippingLabel',
})

export const navToStoreSettingPDFStickerShippingLabel = NavigationActions.navigate({
  name: 'StoreSettingStickertContainer',
})

export const navToStoreSettingBillView = NavigationActions.navigate({
  name: 'StoreSettingBillView',
})

export const navToStoreSettingPrintingBarcode = NavigationActions.navigate({
  name: 'StoreSettingPrintingBarcode',
})

export const navToAssistantMember = ({ callBack, tabIndex, permissionList, permission_id }) =>
  NavigationActions.navigate({
    name: 'AssistantMember',
    params: { callBack, tabIndex, permissionList, permission_id },
  })

export const navToAssistantGroupViewContainer = ({ mode, callBack, permission }) =>
  NavigationActions.navigate({
    name: 'AssistantGroup',
    params: { mode, callBack, permission },
  })

export const navToCategoryPropertyViewContainer = ({ mode, category, allCategory, callBackCE, callBackDelete, callBackChangeCategory }) =>
  // callBackCE => CALL BACK FOR CREATE AND EDIT
  // callBackDelete => CALL BACK FOR DELETE COMPLETED
  // callBackChangeCategory => CALL BACK FOR CHANGE CATEGORY
  NavigationActions.navigate({
    name: 'CategoryProperty',
    params: { mode, category, allCategory, callBackCE, callBackDelete, callBackChangeCategory },
  })

export const navToOrderListView = (params: IOrderListViewNavParams) => StackActions.push('OrderListView', params)

export const navToPaymentOrderListView = (params: IPaymentOrderListViewNavParams) => StackActions.push('PaymentOrderListView', params)

export const navToAutoPaymentOrderListView = (params: IOrderListViewNavParams) => StackActions.push('AutoPaymentOrderListView', params)

export const navToAutoShipmentOrderListView = (params: IOrderListViewNavParams) => StackActions.push('AutoShipmentOrderListView', params)

export const navToPrintDocumentOrderListView = (params: IOrderListViewNavParams) =>
  StackActions.push('PrintDocumentOrderListView', {
    // availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready', 'myTasks_ship_all'],
    ...params,
  })

export const navToAllPrintDocumentOrderListView = (params: IOrderListViewNavParams) =>
  StackActions.push('AllPrintDocumentOrderListView', {
    // availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready', 'myTasks_ship_all'],
    ...params,
  })

export const navToERPSubmitDocumentOrderListView = (params: IERPSubmitDocumentOrderListViewNavParams) =>
  StackActions.push('ERPSubmitDocumentOrderListView', {
    // availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready', 'myTasks_ship_all'],
    ...params,
  })

export const navToReservedProductsOrderListView = (params: IOrderListViewNavParams) =>
  StackActions.push('ReservedProductsOrderListView', params)

export const navToMkpConfirmOrderListView = (params: IOrderListViewNavParams) =>
  StackActions.push('MkpConfirmOrderListView', {
    // availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready', 'myTasks_ship_all'],
    ...params,
  })

export const navToSearchOrderListView = (params: IOrderListViewNavParams) => StackActions.push('SearchOrderListView', params)

export const navToSearchOrderView = (params: { store_id: number }) =>
  NavigationActions.navigate({
    name: 'SearchOrderView',
    params,
  })

export const navToBankEditView = ({ store_id, account, succeedCallback, isVerifySlipBankAccountOnly = false }) =>
  NavigationActions.navigate({
    name: 'BankEditView',
    params: { store_id, account, succeedCallback, isVerifySlipBankAccountOnly },
  })

// export const navToPrintDocumentOrderListView_myTasks_ship = (params: IOrderListViewNavParams) =>
//   StackActions.push({
//     name: 'PrintDocumentOrderListView',
//     params: {
//       availableTabKeys: ['myTasks_ship'],
//       ...params,
//     },
//   })

// export const navToPrintDocumentOrderListView_myTasks_ship_not_ready = (params: IOrderListViewNavParams) =>
//   StackActions.push({
//     name: 'PrintDocumentOrderListView',
//     params: {
//       availableTabKeys: ['myTasks_ship_not_ready'],
//       ...params,
//     },
//   })

// export const navToPrintDocumentOrderListView_myTasks_ship_all = (params: IOrderListViewNavParams) =>
//   StackActions.push({
//     name: 'PrintDocumentOrderListView',
//     params: {
//       availableTabKeys: ['myTasks_ship_all'],
//       ...params,
//     },
//   })

export const navToPaymentView = (params: IPaymentViewNavParams) =>
  NavigationActions.navigate({
    name: 'PaymentView',
    params,
  })

export const navToChannelView = ({
  store_id,
  mode,
  channel = null,
  callBackURL = null,
  callBackUpdateChannelList = null,
  callBackUpdateChannel,
}) =>
  NavigationActions.navigate({
    name: 'ChannelView',
    params: { store_id, mode, channel, callBackURL, callBackUpdateChannelList, callBackUpdateChannel },
  })

// navToPaymentListView
export const navToPaymentListView = (params: IPaymentListViewNavParams) =>
  NavigationActions.navigate({
    key: params.fromOrderDetail ? 'PaymentListView4OrderDetail' : 'PaymentListView',
    name: 'PaymentListView',
    params,
  })

export const navToCustomOrderView = ({ mode, store_id, callBackFromCustomOrder }) =>
  NavigationActions.navigate({
    name: 'CustomOrderView',
    params: {
      mode: xCONS.ORDER_VIEW_MODE.VIEW_EDIT,
      store_id,
      callBackFromCustomOrder,
    },
  })

export const navToCustomCreateOrderView = ({ mode, store_id, callBackFromCustomOrder }) =>
  NavigationActions.navigate({
    name: 'CustomCreateOrderView',
    params: {
      mode: xCONS.ORDER_VIEW_MODE.VIEW_EDIT,
      store_id,
      callBackFromCustomOrder,
    },
  })

export const navToProfile = NavigationActions.navigate({
  name: 'ProfileView',
  params: { mode: 'VIEW' },
})

export const navToCardInfoViewContainer = NavigationActions.navigate({
  name: 'CardInfoViewContainer',
})

export const navToSettingEmailView = NavigationActions.navigate({
  name: 'SettingEmailViewContainer',
})

export const navToSettingResetPasswordView = NavigationActions.navigate({
  name: 'SettingResetPasswordView',
})

export const navToAboutAppView = NavigationActions.navigate({
  name: 'About',
})

// navToCustomerList MODE PICK_ADDRESS AND VIEW_EDIT
export const navToCustomerList = ({ mode, callBackSelectedAddress = null }) =>
  NavigationActions.navigate({
    name: 'CCustomerList',
    params: { mode, callBackSelectedAddress },
  })

export const navToCCustomerCreateView = NavigationActions.navigate({
  name: 'CCustomerCreateView',
})

export const navToContactView = ({ contact_id, callBack = null, mode }) =>
  NavigationActions.navigate({
    name: 'ContactView',
    params: { contact_id, callBack, mode },
  })

export const navToPagePicker = (cfg) =>
  NavigationActions.navigate({
    name: 'PagePicker',
    params: {
      key: cfg.key,
      title: cfg.title,
      viewType: cfg.viewType,
      items: cfg.items,
      callBackAfterSelected: cfg.callBackAfterSelected,
    },
  })

export const navToUserGroupsAssignProductGroups = ({ store_id, ug_id }) =>
  NavigationActions.navigate({
    name: 'ProductGroupListView',
    params: {
      store_id,
      mode: xCONS.PRODUCT_GROUP_LIST_MODE.ASSIGN_TO_UG,
      ug_id,
    },
  })

export const navToUserGroupResellerTabs = ({ store_id, ug_id, initTabIndex = 0, callBack = null }) =>
  NavigationActions.navigate({
    name: 'UserGroupResellerTabs',
    params: { store_id, ug_id, initTabIndex, callBack },
  })

export const navToUserGroupView = ({ store_id, mode, ug_id }) =>
  NavigationActions.navigate({
    name: 'UserGroupView',
    params: { store_id, mode, ug_id },
  })

export const navToDevMenuSubscription = NavigationActions.navigate({
  name: 'DevMenuSubscriptionView',
})

export const resetToFirstPage =
  Platform.OS === 'web'
    ? NavigationActions.reset({
        // key: null,
        index: 0,
        routes: [{ name: 'LoginView' }],
        // actions: [NavigationActions.navigate({ name: 'LoginView' })],
      })
    : NavigationActions.reset({
        // key: null,
        index: 0,
        routes: [{ name: 'AppLauncherViewContainer' }],
      })

export const switchToAuthStackNavigator = NavigationActions.reset({
  // name: 'AuthStackNavigator',
  index: 0,
  routes: [
    {
      name: 'MainTab',
      state: {
        routes: [{ name: 'MyStores' }],
      },
    },
  ],
  // action: NavigationActions.navigate({
  //   // By Keng: Change route name here for dev
  //   // name: 'DevMenuSubscriptionView',
  //   name: 'MyStores',
  // }),
})

export const resetToAuthStackNavigator = (params = {}) =>
  Platform.OS === 'web'
    ? NavigationActions.reset({
        // key: null,
        index: 0,
        routes: [
          {
            name: 'LoggedInStack',
            params,
            // state: {
            //   routes: [{ name: 'Home', params }],
            // },
          },
        ],
        // routes: [{ name: 'AuthSwitchNavigator', params }],
        // actions: [NavigationActions.navigate({ name: 'AuthSwitchNavigator', params })],
      })
    : NavigationActions.reset({
        // name: 'AuthStackNavigator',
        // action: NavigationActions.navigate({
        //   // By Keng: Change route name here for dev
        //   // name: 'DevMenuSubscriptionView',
        //   name: 'MyStores',
        //   params,
        // }),
        index: 0,
        routes: [
          {
            name: 'MainTab',
            params,
            // state: {
            //   routes: [{ name: 'Home', params }],
            // },
          },
        ],
      })

export const switchToOnboardingRegisterView =
  // Platform.OS === 'web'
  //   ?
  NavigationActions.reset({
    // key: null,
    index: 0,
    routes: [{ name: 'OnboardingRegisterView' }],
    // actions: [NavigationActions.navigate({ name: 'OnboardingRegisterView' })],
  })
// : NavigationActions.navigate({
//     name: 'OnboardingStackNavigator',
//     action: NavigationActions.navigate({
//       name: 'OnboardingRegisterView',
//     }),
//   })

export const switchToOnboardingEmail = (params: { email: string }) =>
  // Platform.OS === 'web'
  //   ?
  NavigationActions.reset({
    // key: null,
    index: 0,
    routes: [{ name: 'OnboardingEmailView', params }],
    // actions: [NavigationActions.navigate({ name: 'OnboardingEmailView', params })],
  })
// : NavigationActions.navigate({
//     name: 'OnboardingStackNavigator',
//     routes: [{ name: 'OnboardingEmailView', params }],
//     // action: NavigationActions.navigate({
//     //   name: 'OnboardingEmailView',
//     //   params,
//     // }),
//   })

export const switchToOnboardingUsername =
  // Platform.OS === 'web'
  //   ?
  NavigationActions.reset({
    // key: null,
    index: 0,
    routes: [{ name: 'OnboardingUsernameView' }],
    // actions: [NavigationActions.navigate({ name: 'OnboardingUsernameView' })],
  })
// : NavigationActions.navigate({
//     name: 'OnboardingStackNavigator',
//     action: NavigationActions.navigate({
//       name: 'OnboardingUsernameView',
//     }),
//   })

export const switchToOnboardingFirstStore =
  // Platform.OS === 'web'
  //   ?
  NavigationActions.reset({
    // key: null,
    index: 0,
    routes: [{ name: 'OnboardingFirstStoreNameView' }],
    // actions: [NavigationActions.navigate({ name: 'OnboardingFirstStoreNameView' })],
  })
// : NavigationActions.navigate({
//     name: 'OnboardingStackNavigator',
//     action: NavigationActions.navigate({
//       name: 'OnboardingFirstStoreNameView',
//     }),
//   })

export const navToForgottenPasswordView =
  Platform.OS === 'web'
    ? NavigationActions.reset({
        // key: null,
        index: 0,
        routes: [{ name: 'ForgottenPasswordView' }],
        // actions: [NavigationActions.navigate({ name: 'ForgottenPasswordView' })],
      })
    : NavigationActions.navigate({
        name: 'ForgottenPasswordView',
      })

export const navToPaperangView = (params: IPaperangViewParams) =>
  Platform.OS === 'web'
    ? null
    : NavigationActions.navigate({
        name: 'PaperangView',
        params,
      })

export const navToProductGroupSettingForUserGroup = ({ store_id, userGroup, callBack = null }) =>
  NavigationActions.navigate({
    name: 'ProductGroupSettingForUserGroup',
    params: {
      store_id,
      userGroup,
      callBack,
    },
  })

export const navToResellerChangeGroupView = ({ store_id, ug_id, selectedReseller, callBack = null }) =>
  NavigationActions.navigate({
    name: 'ResellerChangeGroupView',
    params: { store_id, ug_id, selectedReseller, callBack },
  })

export const navToProductGroupListView = ({ store_id }) =>
  NavigationActions.navigate({
    name: 'ProductGroupListView',
    params: {
      store_id,
      mode: xCONS.PRODUCT_GROUP_LIST_MODE.VIEW,
    },
  })

export const navToProductGroupView = ({ store_id, mode, pg_id = null, callBack = null }) =>
  NavigationActions.navigate({
    name: 'ProductGroupView',
    params: { store_id, mode, pg_id, callBack },
  })

export const navToPickCustomerAddress = ({ mode, callBackSelectedAddress = null }) =>
  NavigationActions.navigate({
    name: 'CCustomerList',
    params: { mode, callBackSelectedAddress },
  })

// navToPGSelectorOnly callBack = number[]
export const navToPGSelectorOnly = ({ callBack, mkp_id = null }) =>
  NavigationActions.navigate({
    name: 'PGSelectorOnly',
    params: { callBack, mkp_id },
  })

export const navToCCustomerEditingView = ({ profile, succeedCallback }) =>
  NavigationActions.navigate({
    name: 'CCustomerEditingView',
    params: { profile, succeedCallback },
  })

export const navToResellerManageView = ({ ug_id, selectedReseller, callBackUpdateResellerName }) =>
  NavigationActions.navigate({
    name: 'ResellerManageView',
    params: { ug_id, selectedReseller, callBackUpdateResellerName },
  })

export const navToMultiParcelView = ({ order, callBackRerender }) =>
  NavigationActions.navigate({
    name: 'MultiParcelView',
    params: { order, callBackRerender },
  })

export const navToSelectedProductMultiParcelView = ({ order, parcels, parcelIndex, callBack }) =>
  NavigationActions.navigate({
    name: 'SelectedProductMultiParcelView',
    params: { order, parcels, parcelIndex, callBack },
  })

export const navToPaymentSubscriptionPackageView = ({}) =>
  NavigationActions.navigate({
    name: 'PaymentSubscriptionPackageView',
    params: {},
  })

export const navToSelectAddOnPackageView = ({ subscriptionPackageList, tskuItems, selectedPackages, key, callback }) =>
  NavigationActions.navigate({
    name: 'SelectAddOnPackageView',
    params: { subscriptionPackageList, tskuItems, selectedPackages, key, callback },
  })

export const navToSelectMainPackageView = ({ subscriptionPackageList, mode, callback }) =>
  NavigationActions.navigate({
    name: 'SelectPackageView',
    params: { subscriptionPackageList, mode, callback },
  })

export const navToStoreWebsiteListView = ({ type }) =>
  NavigationActions.navigate({
    name: 'StoreWebsiteListView',
    params: { type },
  })

export const navToStoreWebsiteView = ({ storeWebsite, callBackForSucceedDeleteStoreWebsite }) =>
  NavigationActions.navigate({
    name: 'StoreWebsiteView',
    params: {
      storeWebsite,
      callBackForSucceedDeleteStoreWebsite,
    },
  })

export const navToSalePageView = ({ salePage, callBackForSucceedDeleteStoreWebsite, callBackForSucceedEditStoreWebsite }) =>
  NavigationActions.navigate({
    name: 'SalePageView',
    params: {
      salePage,
      callBackForSucceedDeleteStoreWebsite,
      callBackForSucceedEditStoreWebsite,
    },
  })

export const navToSalePageSetttingWebView = (params: IStoreWebsiteSettingWebViewParams) =>
  NavigationActions.navigate({
    name: 'SalePageSettingWebView',
    params,
  })

export const navToEditorView = ({ html, index, callBack }) =>
  NavigationActions.navigate({
    name: 'EditorView',
    params: {
      html,
      index,
      callBack,
    },
  })

export const navToCreateStoreWebsiteView = ({ callBackCreateSucceed }) =>
  NavigationActions.navigate({
    name: 'CreateStoreWebsiteView',
    params: {
      callBackCreateSucceed,
    },
  })

export const navToCreateSalePageView = ({ callBackCreateSucceed }) =>
  NavigationActions.navigate({
    name: 'CreateSalePageView',
    params: {
      callBackCreateSucceed,
    },
  })

export const navToStoreWebsiteSettingPaymentView = ({ storeWebsite, callBackSucceedUpdateApi }) =>
  NavigationActions.navigate({
    name: 'StoreWebsiteSettingPaymentView',
    params: {
      storeWebsite,
      callBackSucceedUpdateApi,
    },
  })

export const navToSalePageEditInfoView = ({ salePage, callBackSucceedUpdateApi }) =>
  NavigationActions.navigate({
    name: 'SalePageEditInfoView',
    params: {
      salePage,
      callBackSucceedUpdateApi,
    },
  })

export const navToSalePagePixelView = ({ salePage, callBackSucceedUpdateApi }) =>
  NavigationActions.navigate({
    name: 'SalePagePixelView',
    params: {
      salePage,
      callBackSucceedUpdateApi,
    },
  })

export const navToStoreWebsiteSettingOptionsOrderView = ({ storeWebsite, callBackSucceedUpdateApi }) =>
  NavigationActions.navigate({
    name: 'StoreWebsiteSettingOptionsOrderView',
    params: {
      storeWebsite,
      callBackSucceedUpdateApi,
    },
  })

export const navToStoreWebsiteCreateOptionsOrderView = ({ storeWebsite, orderPicker, index, mode, callBack }) =>
  NavigationActions.navigate({
    name: 'StoreWebsiteCreateOptionsOrderView',
    params: {
      storeWebsite,
      orderPicker,
      index,
      mode,
      callBack,
    },
  })

export const navToSalePageSelectProductView = ({ productIndex, mode, callBack }) =>
  NavigationActions.navigate({
    name: 'SalePageSelectProductView',
    params: {
      productIndex,
      mode,
      callBack,
    },
  })

export const navToSalePageAddItemWebView = ({ index, callBack }) =>
  NavigationActions.navigate({
    name: 'SalePageAddItemWebView',
    params: {
      index,
      callBack,
    },
  })

// countdownTime = null if create mode
export const navToEditCountdownTimeView = ({ index, countdownTime, popViewNumber, callBack }) =>
  NavigationActions.navigate({
    name: 'EditCountdownTimeView',
    params: {
      index,
      countdownTime,
      popViewNumber,
      callBack,
    },
  })

export const navToStoreWebsiteSelectedOptionModeView = ({ index, callBack }) =>
  NavigationActions.navigate({
    name: 'StoreWebsiteSelectedOptionModeView',
    params: {
      index,
      callBack,
    },
  })

export const navToReauthLazadaView = (params: {
  store_id: number
  mkp_ch_id: number
  callBackFromLazadaReauth: (channel: IMKPChannelDetail) => void
}) =>
  NavigationActions.navigate({
    key: 'ReauthLazadaView',
    name: 'ReauthLazadaView',
    params: { ...params },
  })

export const navToReauthShopifyView = (params: {
  store_id: number
  mkp_ch_id: number
  callBackFromShopifyReauth: (channel: IMKPChannelDetail) => void
}) =>
  NavigationActions.navigate({
    key: 'ReauthShopifyView',
    name: 'ReauthShopifyView',
    params: { ...params },
  })

export const navToERPListView = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ERPListView',
    name: 'ERPListView',
    params: { store_id },
  })

export const navToERPSubmitDocumentView = ({ store_id, erpChannel }) =>
  NavigationActions.navigate({
    key: 'ERPSubmitDocumentView',
    name: 'ERPSubmitDocumentView',
    params: { store_id, erpChannel },
  })

export const navToERPCreateView = ({ store_id, callBack }) =>
  NavigationActions.navigate({
    key: 'ERPCreateView',
    name: 'ERPCreateView',
    params: { store_id, callBack },
  })

export const navToERPCreatePackView = ({ store_id, callBack }) =>
  NavigationActions.navigate({
    key: 'ERPCreatePeakView',
    name: 'ERPCreatePeakView',
    params: { store_id, callBack },
  })

export const navToERPDetailView = ({ store_id, erp_ch_id, callBackUpdateNewErp, callBackUpdateNewErpList }) =>
  NavigationActions.navigate({
    key: 'ERPDetailView',
    name: 'ERPDetailView',
    params: { store_id, erp_ch_id, callBackUpdateNewErp, callBackUpdateNewErpList },
  })

export const navToERPPaymentAccountView = ({ store_id, erpChannel }) =>
  NavigationActions.navigate({
    key: 'ERPPaymentAccountView',
    name: 'ERPPaymentAccountView',
    params: { store_id, erpChannel },
  })

export const navToERPEditPaymentAccountView = ({
  store_id,
  erp_paymen_account,
  payment,
  erpChannel,
  callBackUpdateErpPayment,
  callBackUpdatePaymentList,
}) =>
  NavigationActions.navigate({
    key: 'ERPEditPaymentAccountView',
    name: 'ERPEditPaymentAccountView',
    params: { store_id, erp_paymen_account, payment, erpChannel, callBackUpdateErpPayment, callBackUpdatePaymentList },
  })

export const navToERPChannelContact = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ERPChannelContact',
    name: 'ERPChannelContact',
    params: { store_id },
  })

export const navToERPShippingSetting = ({ store_id }) =>
  NavigationActions.navigate({
    key: 'ERPShippingSetting',
    name: 'ERPShippingSetting',
    params: { store_id },
  })

export const navToERPOrderView = ({ store_id, order, erp_ch_id, callBackFromErpOrderDetailUpdate }) =>
  NavigationActions.navigate({
    key: 'ErpOrderView',
    name: 'ErpOrderView',
    params: { store_id, order, erp_ch_id, callBackFromErpOrderDetailUpdate },
  })

// mode => 'upgrade' | 'continue_package' | 'buy_package' | 'add_on'
export const navToInAppPurchaseView = ({
  subscriptionPackageList,
  mode,
  callBackSucceedPaymentIos,
  succeedPaymentCallBack,
  popView = null,
}) =>
  NavigationActions.navigate({
    name: 'InAppPurchaseView',
    params: { subscriptionPackageList, mode, callBackSucceedPaymentIos, succeedPaymentCallBack, popView },
  })

export const navToInAppPurchasePerPackageView = ({ subscriptionPackageList, callBackSucceedPaymentIos, popView = null }) =>
  NavigationActions.navigate({
    name: 'InAppPurchasePerPackageView',
    params: { subscriptionPackageList, callBackSucceedPaymentIos, popView },
  })

export const navToActivePackageDetailView = ({ subscriptionPackageList, callBackSucceedPaymentIos }) =>
  NavigationActions.navigate({
    name: 'ActivePackageDetailView',
    params: { subscriptionPackageList, callBackSucceedPaymentIos },
  })

export const navToNextPackageDetailView = ({ subscriptionPackageList, callBackSucceedPaymentIos }) =>
  NavigationActions.navigate({
    name: 'NextPackageDetail',
    params: { subscriptionPackageList, callBackSucceedPaymentIos },
  })

export const navToMkpAutoMappingVariantsView = (params: IMkpAutoMappingVariantsViewNavParams) =>
  NavigationActions.navigate({
    name: 'MkpAutoMappingVariantsView',
    params,
  })

export const navToXSellyVariantMappingSelectorView = (params: IXSellyVariantMappingSelectorViewNavParams) =>
  NavigationActions.navigate({
    name: 'XSellyVariantMappingSelectorView',
    params,
  })

export const navToStoreContactListView = (params: IStoreContactListViewNavParams) =>
  NavigationActions.navigate({
    name: 'StoreContactListView',
    params,
  })

export const navToStoreContactDetailView = (params: IStoreContactDetailNavParams) =>
  NavigationActions.navigate({
    name: 'StoreContactDetailView',
    params,
  })

export const navToStoreAddressListView = (params: IStoreAddressListViewNavParams) =>
  NavigationActions.navigate({
    name: 'StoreAddressListView',
    params,
  })

export const navToMkpChannelListView = ({ channelReAuth }) =>
  NavigationActions.navigate({
    name: 'ChannelListViewContainer',
    params: { channelReAuth },
  })

export const navToSubscriptionView = () =>
  NavigationActions.navigate({
    name: 'SubscriptionView',
  })

// mode => 'upgrade' *if create or continue package mode = null
// mode // 'upgrade' | 'continue_package' | 'buy_package' | 'add_on'
export const navToPaymentSubscriptionView = ({
  subscriptionPackageList,
  sku,
  succeedPaymentCallBack,
  mode = null,
  skipRecommendPackage = false,
}) =>
  NavigationActions.navigate({
    name: 'PaymentSubscriptionView',
    params: { subscriptionPackageList, sku, succeedPaymentCallBack, mode, skipRecommendPackage },
  })

export const navToQtyAdjustmentListView = (params: IQtyAdjustmentListViewNavParams) =>
  NavigationActions.navigate({
    name: 'QtyAdjustmentListView',
    params,
  })

export const navToQtyAdjustmentView = (params: IQtyAdjustmentViewNavParams) =>
  NavigationActions.navigate({
    name: 'QtyAdjustmentView',
    params,
  })

export const navToQtyAdjustmentConfirmationView = (params: IQtyAdjustmentConfirmationViewNavParams) =>
  NavigationActions.navigate({
    name: 'QtyAdjustmentConfirmationView',
    params,
  })

export const navToSearchProductVariantListView = (params: ISearchProductVariantListViewNavParams) =>
  NavigationActions.navigate({
    name: 'SearchProductVariantListView',
    params,
  })

export const navToSelectAddOnOrderView = ({
  subscriptionPackageList,
  selectedPackage,
  sku,
  callBackFromSelectedAddOn,
  selectedAddOn,
  mode,
  orderCountHistory = null,
}) =>
  NavigationActions.navigate({
    name: 'SelectAddOnOrderView',
    params: { subscriptionPackageList, selectedPackage, sku, callBackFromSelectedAddOn, selectedAddOn, mode, orderCountHistory },
  })

export const navToSelectedAddOnListView = ({ addOnList, callBackFormSelectedAddOn }) =>
  NavigationActions.navigate({
    name: 'SelectedAddOnListView',
    params: { addOnList, callBackFormSelectedAddOn },
  })

export const navToProductListFilterView = (params: IProductListFilterViewNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListFilterView',
    params,
  })

export const navToProductVariantListFilterView = (params: IProductVariantListFilterViewNavParams) =>
  NavigationActions.navigate({
    name: 'ProductVariantListFilterView',
    params,
  })

export const navToProductListFilterEditorView = (params: IProductListFilterEditorViewNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListFilterEditorView',
    params,
  })

export const navToProductListFilterManageView = (params: IProductListFilterManageViewNavParams) =>
  NavigationActions.navigate({
    name: 'ProductListFilterManageView',
    params,
  })
