import Intl from 'intl'
import 'intl/locale-data/jsonp/th.js'
import moment from 'moment'
import _ from 'lodash'

export const currencyFormatter = new Intl.NumberFormat('th-TH', {
  // style: 'currency',
  // currency: 'THB',
  // minimumFractionDigits: 2, /* this might not be necessary */
  maximumFractionDigits: 2,
})

export function formatCurrency(amount: number | string, fixedFractionDigits = 0): string {
  // @ts-ignore
  let amountReplace: number = _.isNumber(amount) ? amount : amount.replace(/,/g, '')
  // eslint-disable-next-line no-restricted-globals
  if (!amountReplace || isNaN(amountReplace)) {
    const zero = 0
    if (fixedFractionDigits) {
      return `฿${zero.toFixed(fixedFractionDigits)}`
    }
    return `฿${zero}`
  }
  let isNegativeNum = false
  if (amountReplace < 0) {
    isNegativeNum = true
    amountReplace *= -1 // convert to positive first
  }
  const amountStr = String(amountReplace)
  let amountText = `฿${currencyFormatter.format(amountReplace)}`
  if (fixedFractionDigits) {
    amountText = `฿${currencyFormatter.format(amountReplace.toFixed(fixedFractionDigits))}`
  }
  const isEndedWithDot = amountStr.indexOf('.')
  // console.log('idx ' + isEndedWithDot + ' len: ' + amountStr.length)
  if (isEndedWithDot === amountStr.length - 1) {
    amountText += '.'
  }

  // interupt check ถ้ามีเคส .x ใดๆ ให้คงค่าหลังจุดไว้
  if (amountStr.match(/^(\d*\.\d|\.\d)$/)) {
    const amountArr = amountStr.split('.')
    amountText = `฿${currencyFormatter.format(amountArr[0])}.${amountArr[1]}`
  }

  if (isNegativeNum) {
    amountText = `(${amountText})`
  }
  // log('formatCurrency input output:', amount, amountText)
  return amountText
}

export function formatDecimal(amount: number | string, fixedFractionDigits = 0): string {
  // @ts-ignore
  let amountReplace: number = _.isNumber(amount) ? amount : amount.replace(/,/g, '')
  // eslint-disable-next-line no-restricted-globals
  if (!amountReplace || isNaN(amountReplace)) {
    const zero = 0
    if (fixedFractionDigits) {
      return `${zero.toFixed(fixedFractionDigits)}`
    }
    return `${zero}`
  }
  let isNegativeNum = false
  if (amountReplace < 0) {
    isNegativeNum = true
    amountReplace *= -1 // convert to positive first
  }
  const amountStr = String(amountReplace)
  let amountText = `${currencyFormatter.format(amountReplace)}`
  if (fixedFractionDigits) {
    amountText = `${currencyFormatter.format(amountReplace.toFixed(fixedFractionDigits))}`
  }
  const isEndedWithDot = amountStr.indexOf('.')
  // console.log('idx ' + isEndedWithDot + ' len: ' + amountStr.length)
  if (isEndedWithDot === amountStr.length - 1) {
    amountText += '.'
  }

  // interupt check ถ้ามีเคส .x ใดๆ ให้คงค่าหลังจุดไว้
  if (amountStr.match(/^(\d*\.\d|\.\d)$/)) {
    const amountArr = amountStr.split('.')
    amountText = `${currencyFormatter.format(amountArr[0])}.${amountArr[1]}`
  }

  if (isNegativeNum) {
    amountText = `(${amountText})`
  }
  // log('formatCurrency input output:', amount, amountText)
  return amountText
}

// สร้างไว้ใช่ทดแทน formatCurrency ตัวเดิม เนื่องจากลองแล้วใช้งาน fixedFractionDigits ไม่ได้ ส่วน formatCurrency มีการใช้งานหลายที่ยังไม่กล้าเปลี่ยน :: Artid 25/03/2026
export function formatCurrencyFixedPoint(amount: number | string, fixedFractionDigits = 0): string {
  // @ts-ignore
  const amountReplace: number = _.isNumber(amount) ? amount : amount.replace(/,/g, '')

  if (!amountReplace || _.isNaN(amountReplace)) {
    const zero = 0
    return `฿${zero.toFixed(fixedFractionDigits)}` // Return 0 with fixedFractionDigits
  }

  // Format the number with fixedFractionDigits if provided
  let amountText = amountReplace.toLocaleString(undefined, {
    minimumFractionDigits: fixedFractionDigits,
    maximumFractionDigits: fixedFractionDigits,
  })
  // console.log('amountText => ', amountText)
  // If there are no decimals and fixedFractionDigits > 0, ensure we add `.00`
  if (fixedFractionDigits > 0 && !amountText.includes('.')) {
    amountText += '.'.padEnd(fixedFractionDigits + 1, '0')
  }

  // Add currency symbol
  return `฿${amountText}`
}

export function formatDecimalVerTwo(amount: number | string, fixedFractionDigits = 0): string {
  // @ts-ignore
  let amountReplace: number = _.isNumber(amount) ? amount : amount.replace(/,/g, '')

  if (!amountReplace || _.isNaN(amountReplace)) {
    const zero = 0
    if (fixedFractionDigits) {
      return `${zero.toFixed(fixedFractionDigits)}`
    }
    return `${zero}`
  }

  // let amountStr = String(amountReplace);

  // Apply fixedFractionDigits if provided
  if (fixedFractionDigits > 0) {
    amountReplace = parseFloat(amountReplace.toFixed(fixedFractionDigits)) // Ensure fixed fraction digits
  }

  // Format the number with or without decimal places, depending on the fixedFractionDigits
  let amountText = amountReplace.toLocaleString(undefined, {
    minimumFractionDigits: fixedFractionDigits,
    maximumFractionDigits: fixedFractionDigits,
  })

  // Check if we need to append a dot if the number is an integer and fixedFractionDigits > 0
  if (fixedFractionDigits > 0 && !amountText.includes('.')) {
    amountText += '.'
  }

  return amountText
}

// Returns the server-expected date format i.e. '2017-08-10 21:52:12'.
// Returns '' if the specified jsDateObj is invalid
export function formatToServerDateTime(jsDateObj) {
  const d = moment(jsDateObj)
  if (d.isValid()) {
    return d.format('YYYY-MM-DD HH:mm:ss')
  }
  return ''
}
