import React, { Component } from 'react'
import { View, Image, Pressable } from 'react-native'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import _ from 'lodash'
import { Svg, Path } from 'react-native-svg'
// import Grid from 'xui/components/Grid'
// import Row from 'xui/components/Row'
// import Col from 'xui/components/Col'
import * as acl from 'x/utils/acl'
import * as util from 'x/utils/util'

import p from 'x/config/platform-specific'

import { IAnyObject, ISelectedStoreMap, IXScreenProps } from 'x/index'
// import XButton from '../../components/XButton'
import { COLORS } from 'x/config/styles'
import xCONS from 'x/config/constants'
import XIcon, { XIconFamily } from 'xui/components/XIcon'
import Badge from 'xui/components/Badge'
// import ModalDropdown from '../../components/ModalDropdown'
import { List } from 'immutable'
import XText from 'xui/components/XText'
import * as NavActions from 'x/utils/navigation'

import { IXShippingConfig } from 'x/config/app'
import { CommonActions } from '@react-navigation/native'
// import { NavigationNavigateAction, NavigationPushAction } from 'react-navigation'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreState from 'x/modules/store/StoreState'
import { getSelectedStore, getMyStores } from 'x/redux/selectors'
import * as XShippingActions from 'x/modules/xshipping/XShippingState'
import XScrollView from 'xui/components/XScrollView'
import { MODE } from 'x/config/mode'
import Box from 'xui/components/Box'
import dayjs from 'dayjs'
import NewIcon from 'xui/components/NewIcon'
import AUTOCONS from '../../config/autoupdateConstants'

type NavigationNavigateAction = any
type NavigationPushAction = any

const isImpersonateMode = (process && process.env && process.env.REACT_APP_IMPERSONATE) || false

const {
  SIDEBAR_WIDTH,
  MAIN_CONTENT_WIDTH,
  MAX_WEB_WIDTH,
  HEADER_HEIGHT,
  MIN_WEB_HEIGHT,
  BOTTOM_SCROLLBAR_HEIGHT,
  RIGHT_SCROLLBAR_WIDTH,
  NAV_HEADER_HEIGHT,
} = xCONS.SPECIFIC_PLATFORM.WEB

const {
  ORDER_LIST,
  ORDER_ADD,
  ORDER_PRINT_SHIPPING,
  SHIPPING_ADD,
  PAYMENT_EDIT,
  PRODUCT_LIST,
  PRODUCT_EDIT,
  PRODUCT_STOCK_EDIT,
  PRODUCT_ADD,
  UG_LIST,
  UG_ADD,
  UG_DELETE,
  UG_EDIT,
  PG_LIST,
  PG_ADD,
  PG_DELETE,
  PG_EDIT,
  REPORT_VIEW,
  MKP_ADD,
  MKP_DELETE,
  MKP_EDIT,
  MKP_LIST,
  ERP_CHANNEL_LIST,
} = xCONS.PERM_STORE_HELPER

const logoSetting = require('../../xui/x/img/tb/tb_others0.png')
const logoMkpChannel = require('../../xui/x/img/about/icon_mkp_channel.png')

interface ISidebarProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  myStores: List<any>

  dispatch: (params: any) => void
  fetchInitializeData: (params: any) => void
  fetchMyStore: (params: { body: object; successCallback?: () => void; failedCallback?: () => void }) => void
  setXShippingConfig: (xshippingCofig: IXShippingConfig[]) => void

  // from parent
  activeIndex: number
  onActiveIndexChange: (newIndex: number) => void

  isCollapsed?: boolean
}

interface ISidebarState {
  // visibleModal: boolean
  isPopoverOpen?: boolean
  isVisibleStoreSelectorModal?: boolean
  browserWidth?: number
  browserHeight?: number
  isInitialized: boolean
  // sidebarButtonActiveIndex: number
}

const { Fragment } = React
// const SIDEBAR_WIDTH = 180
// const MAIN_CONTENT_WIDTH = 960
// const MAX_WEB_WIDTH = MAIN_CONTENT_WIDTH + SIDEBAR_WIDTH
// const HEADER_HEIGHT = 44
// const MIN_WEB_HEIGHT = 500
// const BOTTOM_SCROLLBAR_HEIGHT = 15
// const RIGHT_SCROLLBAR_WIDTH = 15

class SideBarItem extends React.PureComponent<{
  index: number
  activeIndex: number
  title: string
  onPress: () => void | Promise<void>
  // isActive?: boolean
  icon?: {
    name: string
    family?:
      | 'AntDesign'
      | 'Entypo'
      | 'EvilIcons'
      | 'Feather'
      | 'FontAwesome'
      | 'FontAwesome5'
      | 'Foundation'
      | 'Ionicons'
      | 'MaterialCommunityIcons'
      | 'MaterialIcons'
      | 'Octicons'
      | 'SimpleLineIcons'
      | 'Zocial'
  }
  count?: number
  isSomsaiMenu?: boolean
  imgge?: JSX.Element
  isCollapsed?: boolean
  createdAt?: string
}> {
  inProcess?: boolean

  _handlePress = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { onPress } = this.props
    if (util.isAsyncFunction(onPress)) {
      await onPress()
    } else {
      onPress()
    }
    await util.delay(100)
    this.inProcess = false
  }

  _isActive = () => {
    const { index, activeIndex } = this.props
    return index === activeIndex
  }

  _isNewMenu = () => {
    const { createdAt } = this.props
    if (!createdAt) {
      return false
    }
    const createdAtDate = dayjs(createdAt, xCONS.SERVER_DATETIME_FORMAT)
    const now = dayjs()
    const diff = now.diff(createdAtDate, 'day')
    return diff < 30
  }

  _renderIsNewMenuIcon = () => {
    if (!this._isNewMenu()) {
      return null
    }
    return (
      <HStack justifyContent='center' alignItems='center' position='absolute' top='0' bottom='2px' right='5px'>
        {/* <XIcon
          name='burst-new'
          // family='FontAwesome'
          family='Foundation'
          size='lg'
          color='red.600'
        /> */}
        <NewIcon width={32} />
      </HStack>
    )
  }

  render() {
    const { title, icon, count = null, isSomsaiMenu = false, imgge, isCollapsed = false } = this.props
    const isActive = this._isActive()
    const txtStyle = isActive ? s.btnActiveText : s.btnInactiveText
    const containerStyle = isActive ? s.btnActiveContainer : s.btnInactiveContainer
    const isVisibleBadge = _.isNumber(count) || _.isString(count)
    let titleWidth = isVisibleBadge ? 98 : 142
    if (isSomsaiMenu) {
      titleWidth -= 16 // padding 8
    }
    return (
      <Pressable style={containerStyle} onPress={this._handlePress}>
        <HStack
          w='full'
          // opacity={opacity}
          px='1'
          space='1'>
          <HStack flex={1} alignItems='center' minW={isCollapsed ? '2' : '7'} space='1'>
            {icon ? <XIcon inactive={!isActive} name={icon.name} family={icon.family} /> : null}
            {/* <div className='tooltip'>
              <div className='tooltiptext'>{title}</div>
            </div> */}
            {imgge || null}
            {isCollapsed ? null : (
              <XText flex={1} bold={isActive} adjustsFontSizeToFit style={txtStyle}>
                {title}
              </XText>
            )}
          </HStack>
          {isVisibleBadge && !isCollapsed ? (
            <VStack alignItems='center' justifyContent='center'>
              <Badge
              // borderWidth={isActive ? '1' : '0'}
              >
                {count}
              </Badge>
            </VStack>
          ) : null}
          {this._renderIsNewMenuIcon()}
        </HStack>
      </Pressable>
    )
  }
}

const TrapezoidTitle = (props: { title: string; width?: number | string; height?: number }) => {
  const { title, width = '100%', height = 26 } = props
  return (
    <View
      style={{
        // marginTop: 2,
        // marginBottom: 2,
        height,
        width,
        overflow: 'hidden',
      }}>
      <Svg width={width} height={height} viewBox='0 0 150 40' preserveAspectRatio='xMinYMid'>
        <Path
          d='M 0 0 L 190 0 L 160 40 L 0 40 L 0 0'
          fill={COLORS.APP_MAIN}
          stroke={COLORS.APP_MAIN}
          strokeWidth={1}
          // markerEnd="url(#Triangle)"
        />
        {/* <SvgText
          fill={COLORS.WHITE}
          // stroke={COLORS.WHITE}
          strokeWidth={1}
          fontSize={24}
          // fontWeight={'bold'}
          // fontFamily={'Roboto'}
          fontFamily='Prompt-Bold'
          x={6}
          y={28}
          textAnchor='start'>
          {title}
        </SvgText> */}
      </Svg>
      <HStack position='absolute' top='3px' left='12px'>
        <XText bold variant='activeDark'>
          {title}
        </XText>
      </HStack>
    </View>
  )
}

export type ISidebarMenuItem =
  | {
      type: 'header'
      title: string
    }
  | {
      type: 'menu'
      title: string
      icon: {
        name: string
        family: XIconFamily
      }
      countGetter?: () => number
      onPress: (menuIndex: number) => void | Promise<void>
      imgge?: JSX.Element
      isShowMenu?: () => boolean
      createdAt?: string
    }

class Sidebar extends Component<ISidebarProps, ISidebarState> {
  static displayName = 'Sidebar'

  static defaultProps = {
    activeIndex: -1,
  }

  // class Sidebar extends Component<IStoreMyViewProps, IStoreMyViewState> {
  SIDEBAR_MENUS: ISidebarMenuItem[]

  constructor(props) {
    super(props)

    // this.state = {
    //   sidebarButtonActiveIndex: 0,
    // }

    this.SIDEBAR_MENUS = [
      // {
      //   type: 'header',
      //   title: 'Dashboard',
      // },
      {
        type: 'header',
        title: 'ออเดอร์',
      },
      // {
      //   type: 'menu',
      //   title: 'MOCK',
      //   icon: { name: 'help', family: 'FontAwesome' },
      //   onPress: async (menuIndex: number) => {
      //     await this._setSidebarButtonActiveIndex(menuIndex)
      //     // const { selectedStore, navigation } = this.props
      //     // const store_id = selectedStore.get('id')
      //     // navigation.navigate('OrderListView', { store_id })
      //     this.props.navigation.navigate('OrderListStackNavigator')
      //   },
      // },
      {
        type: 'menu',
        title: 'เปิดออเดอร์ใหม่',
        icon: { name: 'add-circle-sharp', family: 'Ionicons' },
        onPress: this._navToCreateOrderView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(ORDER_ADD)) {
            return false
          }
          return true
        },
      },
      {
        type: 'menu',
        title: 'ค้นหา',
        icon: { name: 'search1', family: 'AntDesign' },
        onPress: this._navToSearchOrderView,
        isShowMenu: () => this._isHideMenuIfDontHavePermOrderList(),
      },
      {
        type: 'menu',
        title: 'ที่รอฉันดำเนินการ',
        icon: { name: 'chart-donut', family: 'MaterialCommunityIcons' },
        onPress: this._navToStoreOrdersDonutViewStackNavigator,
        countGetter: this._getSidebarDonutWaitingMeCount,
        isShowMenu: () => this._isHideMenuIfDontHavePermOrderList(),
      },
      {
        // 3
        type: 'menu',
        // title: 'ทั้งหมดที่เปิดอยู่',
        title: 'ทั้งหมด',
        icon: { name: 'profile', family: 'AntDesign' },
        onPress: this._navToStoreOrderListView,
        countGetter: this._getSidebarOrderCount,
        isShowMenu: () => this._isHideMenuIfDontHavePermOrderList(),
      },
      {
        // 4
        type: 'menu',
        title: 'เลือกหลายออเดอร์',
        icon: { name: 'checkbox-multiple-marked-circle', family: 'MaterialCommunityIcons' },
        onPress: this._navToMultipleOrdersOperationMenuViewStackNavigator,
        isShowMenu: () => this._isHideMenuIfDontHavePermOrderList(),
      },
      {
        // 5
        type: 'menu',
        title: 'สแกนจัดส่ง',
        icon: { name: 'barcode-scan', family: 'MaterialCommunityIcons' },
        onPress: this._navToShippingView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(SHIPPING_ADD)) {
            return false
          }
          return true
        },
      },
      // {
      //   type: 'menu',
      //   title: 'รายการรับชำระ',
      //   icon: { name: 'cash', family: 'Ionicons' },
      //   onPress: this._navToPaymentListView,
      // },
      {
        type: 'menu',
        title: 'ยืนยันรับชำระ',
        icon: { name: 'cash', family: 'Ionicons' },
        onPress: this._navToStorePaymentAccountListView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(PAYMENT_EDIT)) {
            return false
          }
          return true
        },
      },
      // {
      //   // 6
      //   type: 'menu',
      //   title: 'สแกนจัดส่ง (เก่า)',
      //   icon: { name: 'barcode-scan', family: 'MaterialCommunityIcons' },
      //   onPress: this._navToQuickShipView,
      // },
      {
        type: 'menu',
        title: 'พิมพ์ใบปะหน้าพัสดุ',
        icon: { name: 'printer', family: 'AntDesign' },
        onPress: this._navToPrintShippingLabelView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(ORDER_PRINT_SHIPPING)) {
            return false
          }
          return true
        },
      },
      {
        type: 'header',
        title: 'สินค้า',
      },
      {
        type: 'menu',
        title: 'สร้างสินค้าใหม่',
        icon: { name: 'add-circle-sharp', family: 'Ionicons' },
        onPress: this._navToAddProductView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(PRODUCT_ADD)) {
            return false
          }
          return true
        },
      },
      {
        type: 'menu',
        title: 'รายการสินค้า',
        icon: { name: 'shopping-cart', family: 'FontAwesome' },
        onPress: this._navToProductListView,
        countGetter: this._getSidebarProductCount,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(PRODUCT_LIST)) {
            return false
          }
          return true
        },
      },
      {
        // Stock Management
        type: 'menu',
        title: 'รายการสต๊อก',
        icon: { name: 'albums', family: 'Ionicons' },
        onPress: this._navToStockListView,
        isShowMenu: () => {
          // if (MODE !== 'dev') {
          //   return false
          // }

          if (!acl.canDoAtSelectedStore(PRODUCT_LIST)) {
            return false
          }

          return true
        },
        createdAt: '2025-01-29 00:00:00',
      },
      {
        // Qty Adjustment
        type: 'menu',
        title: 'จัดการจำนวนสินค้า',
        icon: { name: 'albums', family: 'Ionicons' },
        onPress: this._navToQtyAdjustmentListView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(PRODUCT_STOCK_EDIT)) {
            return false
          }
          return true
        },
      },
      {
        type: 'header',
        title: 'ระบบภายนอก',
      },
      // @ts-ignore
      {
        type: 'menu',
        title: 'ช่องทางขาย',
        // @ts-ignore
        imgge: (
          <Image
            // resizeMode='cover'
            style={{
              width: 25,
              height: 25,
            }}
            source={logoMkpChannel}
          />
        ),
        onPress: this._navToMKPChannelListView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(MKP_LIST)) {
            return false
          }
          return true
        },
      },
      {
        type: 'menu',
        title: 'ระบบบัญชี',
        icon: { name: 'file-document-edit', family: 'MaterialCommunityIcons' },
        onPress: this._navToERPListView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(ERP_CHANNEL_LIST)) {
            return false
          }
          // CODE FOR TEST
          // const storeByPassIds = [3, 55384, 56634, 62333, 70355, 71608, 67253]
          // if (util.isDevMode() || _.includes(storeByPassIds, this.props.selectedStore.get('id'))) {
          //   return true
          // }
          return true
        },
      },
      {
        type: 'header',
        title: 'เว็บไซต์',
      },
      {
        type: 'menu',
        title: 'ร้านค้าออนไลน์',
        icon: { name: 'sale', family: 'MaterialCommunityIcons' },
        onPress: this._navToStoreWebsiteListViewMenu,
        isShowMenu: () => {
          if (util.isDevMode()) {
            return true
          }
          return false
        },
      },
      {
        type: 'menu',
        title: 'เซลเพจ',
        icon: { name: 'sale', family: 'MaterialCommunityIcons' },
        onPress: this._navToStoreSalepageListViewMenu,
        isShowMenu: () => {
          if (util.isDevMode()) {
            return true
          }
          return false
        },
      },
      {
        type: 'header',
        title: 'อื่นๆ',
      },
      {
        type: 'menu',
        title: 'แดชบอร์ด',
        icon: { name: 'graph-trend', family: 'Foundation' },
        onPress: this._navToDashboardView,
        // isShowMenu: () => {
        //   if (!acl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.DASHBOARD)) {
        //     return false
        //   }
        //   return true
        // },
      },
      // {
      //   type: 'menu',
      //   title: 'เว็บไซต์',
      //   icon: { name: 'sale', family: 'MaterialCommunityIcons' },
      //   onPress: this._navToStoreWebsiteListViewMenu,
      //   isShowMenu: () => {
      //     if (util.isDevMode()) {
      //       return true
      //     }
      //     return false
      //   },
      // },
      {
        type: 'menu',
        title: 'รายชื่อลูกค้า',
        icon: { name: 'group', family: 'MaterialIcons' },
        onPress: this._navToStoreContactListView,
        isShowMenu: () => {
          if (acl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.CONTACT_LIST)) {
            return true
          }
          return false
        },
      },
      {
        type: 'menu',
        title: 'รายงาน',
        icon: { name: 'chart-bar', family: 'MaterialCommunityIcons' },
        onPress: this._navToReportListViewView,
        isShowMenu: () => {
          if (!acl.canSeeReport()) {
            return false
          }
          return true
        },
      },
      {
        type: 'menu',
        title: 'สมาชิก',
        icon: { name: 'people', family: 'Ionicons' },
        onPress: this._navToUGListView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(UG_LIST)) {
            return false
          }
          return true
        },
      },
      {
        type: 'menu',
        title: 'รายการราคา',
        icon: { name: 'pricetags', family: 'Ionicons' },
        onPress: this._navToPGListView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(PG_LIST)) {
            return false
          }
          return true
        },
      },

      {
        type: 'menu',
        title: 'นำเข้าข้อมูล',
        icon: { name: 'import', family: 'MaterialCommunityIcons' },
        onPress: this._navToImportExcelListViewView,
      },
      {
        type: 'menu',
        title: 'คิวคำสั่งงาน',
        icon: { name: 'tasks', family: 'FontAwesome' },
        onPress: this._navToBackgroundJobListView,
        isShowMenu: () => {
          if (!acl.canDoAtSelectedStore(REPORT_VIEW)) {
            return false
          }
          return true
        },
      },
      {
        type: 'menu',
        title: 'ตั้งค่าร้าน',
        icon: { name: 'settings', family: 'Ionicons' },
        onPress: this._navToStoreSeetingMenu,
        isShowMenu: () => this._isHelperUser(),
      },
      // @ts-ignore
      {
        type: 'menu',
        title: 'อื่นๆ',
        // @ts-ignore
        imgge: (
          <Image
            // resizeMode='cover'
            style={{
              width: 25,
              height: 25,
            }}
            source={logoSetting}
          />
        ),
        onPress: this._navToSeetingMenu,
      },
      {
        type: 'header',
        title: 'Package',
      },
      {
        type: 'menu',
        title: 'แพ็กเกจของฉัน',
        icon: { name: 'crown', family: 'MaterialCommunityIcons' },
        onPress: this._navToSubscriptionMenu,
        isShowMenu: () => true,
      },
      // {
      //   type: 'menu',
      //   title: 'อัปเกรด Package',
      //   icon: { name: 'package-up', family: 'MaterialCommunityIcons' },
      //   onPress: this._navToPaymentSubscriptionPackageViewMenu,
      //   isShowMenu: () => {
      //     if (!util.isDevMode()) {
      //       return false
      //     }
      //     return true
      //   },
      // },
      // {
      //   type: 'menu',
      //   title: 'อัปเกรด Package',
      //   icon: { name: 'package-up', family: 'MaterialCommunityIcons' },
      //   onPress: this._navToSelectPackageViewMenu,
      //   isShowMenu: () => false,
      // },
      // {
      //   type: 'menu',
      //   title: 'Package เสริม',
      //   icon: { name: 'puzzle', family: 'MaterialCommunityIcons' },
      //   onPress: this._navToSelectPackageViewMenu,
      //   isShowMenu: () => false,
      // },
    ]
  }

  _renderSideBarItem = (item: ISidebarMenuItem, index: number) => {
    if (!item) {
      return null
    }

    // TODO :: ซ่อนช่องทางขายถ้าไม่ใช่ Dev Mode
    // if (item.title === 'ช่องทางขาย' && !isDevMode()) {
    //   return null
    // }

    // @ts-ignore item มองไม่เห็นของใน interface
    // if (item.isShowMenu && item.isShowMenu()) {
    //   return null
    // }
    const { isShowMenu } = item
    const { isCollapsed = false } = this.props
    // console.log('/// => ', isShowMenu())
    if (!_.isNil(isShowMenu) && _.isFunction(isShowMenu) && isShowMenu() === false) {
      // console.log('item.isShowMenu => ', isShowMenu())
      return null
    }

    // if (item.title === 'Package' && !util.isDevMode()) {
    //   return null
    // }

    if (item.title === 'เว็บไซต์' && !util.isDevMode()) {
      return null
    }

    if (item.type === 'header' && isCollapsed) {
      return <Box key={`SideBar_${index}`} borderBottomWidth='1' borderBottomColor='gray.300' />
    }

    if (item.type === 'header' && !isCollapsed) {
      return <TrapezoidTitle key={`SideBar_${index}`} {...item} />
    }

    if (item.type === 'menu') {
      const { activeIndex } = this.props
      const onPressWithIndex = () => item.onPress(index)
      const count = _.isFunction(item.countGetter) ? item.countGetter() : null
      return (
        <SideBarItem
          key={`SideBar_${index}`}
          index={index}
          {...item}
          // activeIndex={this.state.sidebarButtonActiveIndex}
          activeIndex={activeIndex}
          count={count}
          onPress={onPressWithIndex}
          isCollapsed={isCollapsed}
        />
      )
    }
    return null
  }

  _renderSideBarItems = () => (
    <VStack
      width='full'
      px='1'
      py='0.5'
      bg='white'
      // overflow='scroll'
      _dark={{
        bg: 'dark.100',
      }}
      // style={{
      //   flexBasis: 'auto',
      //   width: '100%',
      //   // padding: 4,
      //   paddingTop: 4,
      //   paddingLeft: 4,
      //   paddingBottom: 4,
      //   paddingRight: 4,
      //   backgroundColor: 'white',
      // }}
    >
      {this.SIDEBAR_MENUS.map(this._renderSideBarItem)}
      {/* <SideBarItem
          index={84}
          activeIndex={999}
          title={'นำเข้าตัวแทน'}
          icon={{ name: 'file-import', family: 'FontAwesome5' }}
          isSomsaiMenu={true}
          onPress={this._navToResellerImportView}
        /> */}
    </VStack>
  )

  _isHelperUser = () => {
    const { selectedStore } = this.props
    return selectedStore.has('role_id') ? selectedStore.get('role_id') !== xCONS.STORE_ROLE.HELPER : false
  }

  // ซ่อนเมนูถ้าไม่มีสิทธิ์ดู order list
  _isHideMenuIfDontHavePermOrderList = () => {
    if (!acl.canDoAtSelectedStore(ORDER_LIST)) {
      return false
    }
    return true
  }

  _getSidebarOrderCount = () => {
    const { selectedStore } = this.props
    const orderCount = selectedStore.get('order_count')
    return orderCount
  }

  _getSidebarProductCount = () => {
    const { selectedStore } = this.props
    const productCount = selectedStore.get('product_count')
    return productCount
  }

  _getSidebarDonutWaitingMeCount = () => {
    const { selectedStore } = this.props
    const orderSummary = selectedStore.get('order_summary')
    if (!orderSummary) {
      return null
    }
    const donutData = util.getDonutData(orderSummary)
    const donutWaitingMeCount = util.getDonutOrderWaitingMeCount(donutData)
    // const product_count = selectedStore.get('product_count')
    return donutWaitingMeCount
  }

  _setSidebarButtonActiveIndex = async (index: number) => {
    // await setStatePromise(this, { sidebarButtonActiveIndex: index })

    const { onActiveIndexChange } = this.props
    try {
      onActiveIndexChange(index)
    } catch (err) {
      console.log('_setSidebarButtonActiveIndex onActiveIndexChange err => ', err)
    }
  }

  doSidebarNavigateTo = async (navToMenuIndex: number, navAction: NavigationNavigateAction | NavigationPushAction) => {
    await this._setSidebarButtonActiveIndex(navToMenuIndex)
    const { selectedStore, navigation, route } = this.props
    // const store_id = selectedStore.get('id')
    // console.log('_resetSE navigation.getParent => ', navigation.getParent('LoggedIn'))
    const state = navigation.getState()

    // console.log('doSidebarNavigateTo navAction => ', navAction)
    navigation.dispatch({
      ...CommonActions.reset({ index: 0, routes: [navAction.payload] }),
      source: route.key,
      target: state.routes[0].state.key,
    })

    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')

    // // เพื่อ clean view ที่ routeName ซื้อแต่ตัวเอง re-use ไม่ได้
    // navigation.navigate(
    //   'CommonContentStackNavigator',
    //   { store_id },
    //   {
    //     type: 'Navigation/RESET',
    //     index: 0,
    //     // @ts-ignore
    //     actions: [
    //       NavigationActions.navigate({
    //         key: `${Math.random() * 1000}`,
    //         routeName: 'EmptyView',
    //       }),
    //     ],
    //   }
    // )
    // await delay(100)
    // navigation.navigate(
    //   'CommonContentStackNavigator',
    //   { store_id },
    //   {
    //     type: 'Navigation/RESET',
    //     index: 0,
    //     // @ts-ignore
    //     actions: [navAction],
    //   }
    // )
  }

  _navToDashboardView = async (menuIndex: number) => {
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')

    await this.doSidebarNavigateTo(menuIndex, NavActions.navToDashboardView({ store_id, isGoBackButtonHidden: true }))
  }

  _navToERPListView = async (menuIndex: number) => {
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')

    await this.doSidebarNavigateTo(menuIndex, NavActions.navToERPListView({ store_id }))
  }

  _navToCreateOrderView = async (menuIndex: number) => {
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')

    await this.doSidebarNavigateTo(
      menuIndex,
      NavActions.navToOrderCreate({ mode: xCONS.ORDER_VIEW_MODE.CREATE, store_id, isGoBackButtonHidden: true })
    )
    // await this.doSidebarNavigateTo(
    //   menuIndex,
    //   NavigationActions.navigate({
    //     key: `OrderView_${Math.random()}`,
    //     routeName: 'OrderView',
    //     params: {
    //       mode: xCONS.ORDER_VIEW_MODE.CREATE,
    //       store_id,
    //     },
    //   })
    // )
  }

  _navToAddProductView = async (menuIndex: number) => {
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')

    await this.doSidebarNavigateTo(
      menuIndex,
      NavActions.navToProductViewAddProduct({
        store_id,
        // onSuccessAddProductCallback: this.onSuccessAddProductCallback,
        // onSuccessEditProductCallback: this.onSuccessEditProductCallback,
        isGoBackButtonHidden: true,
      })
    )
  }

  _navToStoreOrderListView = async (menuIndex: number) => {
    // await this._setSidebarButtonActiveIndex(menuIndex)
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')
    // // navigation.navigate('StoreOrderListView',  { store_id }, {
    // //   type: 'Navigation/NAVIGATE',
    // //   routeName: 'StoreOrderListStackNavigator',
    // //   params: { store_id, mode: 'VIEW_SELF' },
    // // })
    // // navigation.navigate({
    // //     type: 'Navigation/NAVIGATE',
    // //     routeName: 'StoreOrderListStackNavigator',
    // //     // params: { store_id },
    // //     action: {
    // //       type: 'Navigation/NAVIGATE',
    // //       routeName: 'StoreOrderListView',
    // //       key: xCONS.ORDERS_VIEW_MODE_STORE,
    // //       params: { store_id, mode: 'VIEW_SELF' },
    // //     },
    // // })

    // // navigation.navigate(
    // //   'StoreOrderListStackNavigator',
    // //   { store_id },
    // //   {
    // //     type: 'Navigation/NAVIGATE',
    // //     routeName: 'StoreOrderListView',
    // //     key: xCONS.ORDERS_VIEW_MODE_STORE,
    // //     params: { store_id, mode: 'VIEW_SELF' },
    // //   }
    // // )

    // navigation.navigate(
    //   'OrderListStackNavigator',
    //   { store_id },
    //   {
    //     type: 'Navigation/NAVIGATE',
    //     routeName: 'OrderListView',
    //     params: { store_id },
    //   }
    // )
    // // this.props.navigation.navigate('OrderListStackNavigator')

    await this.doSidebarNavigateTo(menuIndex, NavActions.navToOrderListView({ store_id, isGoBackButtonHidden: true }))
  }

  _navToSearchOrderView = async (menuIndex: number) => {
    // await this._setSidebarButtonActiveIndex(menuIndex)
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')
    // navigation.navigate('SearchOrderView', { store_id })

    await this.doSidebarNavigateTo(menuIndex, NavActions.navToSearchOrderView({ store_id }))
  }

  // reset stack expand first page to
  _resetSE = (routeName: string, params: IAnyObject = {}) => {
    //
    const { selectedStore, navigation, route } = this.props
    const store_id = selectedStore.get('id')
    // console.log('_resetSE navigation.getParent => ', navigation.getParent('LoggedIn'))
    const state = navigation.getState()
    navigation.dispatch({
      ...CommonActions.reset({
        index: 0,
        routes: [
          {
            name: routeName,
            params: {
              // for general
              store_id,

              // for web disabled header goBack button
              isGoBackButtonHidden: true,

              // overwrite params
              ...params,
            },
          },
        ],
      }),
      source: route.key,
      target: state.routes[0].state.key,
    })

    // navigation.dispatch(
    //   CommonActions.reset({
    //     index: 0,
    //     routes: [
    //       {
    //         name: 'LoggedIn',
    //         params: { store_id },
    //         state: {
    //           index: 0,
    //           routes: [
    //             {
    //               name: routeName,
    //               params: {
    //                 store_id,
    //                 ...params,
    //               },
    //             },
    //           ],
    //         },
    //       },
    //     ],
    //     stale: true,
    //   })
    // )
  }

  _navToStoreOrdersDonutViewStackNavigator = async (menuIndex: number) => {
    // await this._setSidebarButtonActiveIndex(menuIndex)
    // // const { selectedStore, navigation } = this.props
    // // const store_id = selectedStore.get('id')
    // // navigation.navigate('SearchOrderView', { store_id })
    // this.props.navigation.navigate('StoreOrdersDonutViewStackNavigator')

    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { selectedStore, navigation, route } = this.props
    // console.log('_navToStoreOrdersDonutViewStackNavigator navigation => ', navigation)
    // console.log('_navToStoreOrdersDonutViewStackNavigator navigation.getState() => ', navigation.getState())
    // console.log('_navToStoreOrdersDonutViewStackNavigator route => ', route)
    // const store_id = selectedStore.get('id')
    this._resetSE('StoreOrdersDonutListView')

    // navigation.dispatch(
    //   CommonActions.reset({
    //     index: 0,
    //     routes: [{ name: 'StoreOrdersDonutListView', params: { store_id } }],
    //   })
    // )

    // await this._setSidebarButtonActiveIndex(menuIndex)
    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate(
    //   'StoreOrdersDonutViewStackNavigator',
    //   { store_id },
    //   {
    //     type: 'Navigation/RESET',
    //     index: 0,
    //     // @ts-ignore
    //     actions: [
    //       NavigationActions.navigate({
    //         key: `${Math.random() * 1000}`,
    //         routeName: 'StoreOrdersDonutListView',
    //       }),
    //     ],
    //   }
    // )
  }

  _navToPaymentListView = async (menuIndex: number) => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')

    await this.doSidebarNavigateTo(menuIndex, NavActions.navToPaymentListView({ store_id, isGoBackButtonHidden: true }))
  }

  _navToStorePaymentAccountListView = async (menuIndex: number) => {
    const { selectedStore, navigation } = this.props
    const targetStoreId = selectedStore.get('id')

    await this.doSidebarNavigateTo(
      menuIndex,
      NavActions.navToStorePaymentAccountListView({
        targetStoreId,
        headerTitle: 'เลือกบัญชียืนยันรับชำระ',
        isGoBackButtonHidden: true,
        onPressPaymentAccountCard: async (paymentAccount) => {
          navigation.dispatch(
            NavActions.navToPaymentListView({
              store_id: targetStoreId,
              payment_account_id: paymentAccount.id,
            })
          )
          return false
        },
      })
    )
  }

  _navToMultipleOrdersOperationMenuViewStackNavigator = async (menuIndex: number) => {
    const { selectedStore, navigation } = this.props
    const selectedStoreId = selectedStore.get('id')
    // await this._setSidebarButtonActiveIndex(menuIndex)

    // const navigateAction = NavigationActions.navigate({
    //   routeName: 'MultipleOrdersOperationMenuViewStackNavigator',
    //   key: 'MultipleOrdersOperationMenuView',
    //   params: { selectedStoreId },

    //   // // @ts-ignore
    //   // action: StackActions.replace({
    //   //   key: 'MultipleOrdersOperationMenuView',
    //   //   routeName: 'MultipleOrdersOperationMenuView',
    //   //   params: { selectedStoreId },
    //   // }),
    // })
    // this.props.navigation.dispatch(navigateAction)
    // // this.props.navigation.navigate(
    // //   'MultipleOrdersOperationMenuViewStackNavigator',
    // //   { selectedStoreId },
    // //   {
    // //     type: 'Navigation/NAVIGATE',
    // //     key: 'MultipleOrdersOperationMenuView',
    // //     routeName: 'MultipleOrdersOperationMenuView',
    // //     params: { selectedStoreId },
    // //   }
    // // )

    await this.doSidebarNavigateTo(
      menuIndex,
      NavActions.navToMultipleOrdersOperationMenuView({
        selectedStoreId,
      })
    )
  }

  _navToProductListView = async (menuIndex: number) => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')

    await this.doSidebarNavigateTo(
      menuIndex,
      NavActions.navToProductListViewMyStore({
        store_id,
        isGoBackButtonHidden: true,
      })
    )
  }

  _navToQuickShipView = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    this._resetSE('QuickShipView')

    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate(
    //   'QuickShipView',
    //   { store_id },
    //   {
    //     key: 'QuickShipView',
    //     type: 'Navigation/NAVIGATE',
    //     routeName: 'QuickShipView',
    //     params: { store_id },
    //   }
    // )
  }

  _navToShippingView = async (menuIndex: number) => {
    // await this._setSidebarButtonActiveIndex(menuIndex)
    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate(
    //   'ShippingStackView',
    //   { store_id },
    //   {
    //     key: 'ShippingStackView',
    //     type: 'Navigation/NAVIGATE',
    //     routeName: 'ShippingStackView',
    //     params: { store_id },
    //   }
    // )

    await this.doSidebarNavigateTo(
      menuIndex,
      NavActions.navToShippingView({
        // store_id,
        // isGoBackButtonHidden: true,
      })
    )
  }

  _navToQtyAdjustmentListView = async (menuIndex: number) => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    await this.doSidebarNavigateTo(
      menuIndex,
      NavActions.navToQtyAdjustmentListView({
        store_id,
        isGoBackButtonHidden: true,
      })
    )
  }

  _navToStockListView = async (menuIndex: number) => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    await this.doSidebarNavigateTo(
      menuIndex,
      NavActions.navToSearchProductVariantListView({
        store_id,
        isGoBackButtonHidden: true,
        // onProductItemPressed: (productVariant) => {
        //   // this._navToProductVariantView(productVariant)
        //   // return false
        // },
        // onSearchSuccess: (productVariant) => {
        //   // this._navToProductVariantView(productVariant)
        //   // return false
        // },
      })
    )
  }

  _navToPrintShippingLabelView = async (menuIndex: number) => {
    // await this._setSidebarButtonActiveIndex(menuIndex)
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')
    // navigation.navigate('PrintShippingLabelView', { store_id })

    // await this.doSidebarNavigateTo(menuIndex, {
    //   key: 'PrintShippingLabelView',
    //   type: 'Navigation/NAVIGATE',
    //   routeName: 'PrintShippingLabelView',
    //   params: { store_id },
    // })

    await this.doSidebarNavigateTo(
      menuIndex,
      CommonActions.navigate({
        key: 'PrintShippingLabelView',
        name: 'PrintShippingLabelView',
        params: { store_id },
      })
    )
  }

  _navToReportRequisitionView = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')
    navigation.navigate('ReportRequisitionView', { store_id })
  }

  _navToResellerApprovalListView = async () => {
    await this._setSidebarButtonActiveIndex(81)
    this._resetSE('ResellerListView')
    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate(
    //   'ResellerApprovalListStackNavigator',
    //   { store_id },
    //   {
    //     type: 'Navigation/NAVIGATE',
    //     routeName: 'ResellerListView',
    //     params: { store_id },
    //   }
    // )
  }

  _navToResellerListView = async () => {
    await this._setSidebarButtonActiveIndex(82)
    this._resetSE('ResellerListView')

    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate(
    //   'ResellerListStackNavigator',
    //   { store_id },
    //   {
    //     type: 'Navigation/NAVIGATE',
    //     routeName: 'ResellerListView',
    //     params: { store_id },
    //   }
    // )
  }

  _navToResellerRegisterView = async () => {
    await this._setSidebarButtonActiveIndex(83)
    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate('ResellerRegisterView', { store_id })
    this._resetSE('ResellerRegisterView')
  }

  _navToResellerImportView = async () => {
    await this._setSidebarButtonActiveIndex(84)
    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate('ResellerImportView', { store_id })
    this._resetSE('ResellerImportView')
  }

  _navToUGListView = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate('UserGroupListView', { store_id })
    // // this.props.navigation.navigate('UserGroupListView', {
    // //   store_id: selectedStoreId,
    // // })
    this._resetSE('UserGroupListView')
  }

  _navToStoreContactListView = async (menuIndex: number) => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')

    await this.doSidebarNavigateTo(menuIndex, NavActions.navToStoreContactListView({ store_id, isGoBackButtonHidden: true }))
  }

  _navToPGListView = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate('ProductGroupListView', { store_id })
    // this.props.navigation.navigate('UserGroupListView', {
    //   store_id: selectedStoreId,
    // })

    this._resetSE('ProductGroupListView')
  }

  _navToReportListViewView = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate('ReportListStackNavigator', { store_id })

    this._resetSE('ReportListView')
  }

  _navToMKPChannelListView = async (menuIndex: number) => {
    // await this._setSidebarButtonActiveIndex(menuIndex)
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')
    // navigation.navigate('MKPChannelListStackNavigator', { store_id })

    // await this.doSidebarNavigateTo(menuIndex, {
    //   key: 'ChannelListViewContainer',
    //   type: 'Navigation/NAVIGATE',
    //   routeName: 'ChannelListViewContainer',
    //   params: { store_id },
    // })
    await this.doSidebarNavigateTo(
      menuIndex,
      CommonActions.navigate({
        key: 'ChannelListViewContainer',
        name: 'ChannelListViewContainer',
        params: { store_id, isGoBackButtonHidden: true },
      })
    )
  }

  _navToBackgroundJobListView = async (menuIndex: number) => {
    // await this._setSidebarButtonActiveIndex(menuIndex)
    const { selectedStore, navigation } = this.props
    const storeId = selectedStore.get('id')
    // // navigation.navigate('BackgroundJobListViewStack', { storeId })
    // navigation.navigate(
    //   'BackgroundJobListViewStackNavigator',
    //   { storeId }
    //   // {
    //   //   key: 'BackgroundJobListView',
    //   //   type: 'Navigation/NAVIGATE',
    //   //   routeName: 'BackgroundJobListView',
    //   //   params: { storeId },
    //   // }
    // )

    await this.doSidebarNavigateTo(
      menuIndex,
      NavActions.navToBackgroundJobListView({
        storeId,
        isGoBackButtonHidden: true,
      })
    )
  }

  _navToImportExcelListViewView = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { selectedStore, navigation } = this.props
    // const store_id = selectedStore.get('id')
    // navigation.navigate('importExcelStackNavigator', { store_id })
    this._resetSE('importExcelListView')
  }

  _navToMockingMenu = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    p.op.showToast('Just mocking menu :)', 'warning')
  }

  _navToStoreSeetingMenu = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { selectedStore, navigation } = this.props
    // const storeId = selectedStore.get('id')
    // navigation.navigate('StoreSettingStackNavigator', { storeId })
    this._resetSE('StoreSettingList')
  }

  _navToSeetingMenu = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { navigation } = this.props
    // // const storeId = selectedStore.get('id')
    // navigation.navigate('SettingViewStackNavigator')
    this._resetSE('SettingView')
  }

  _navToSubscriptionMenu = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { navigation } = this.props
    // // const storeId = selectedStore.get('id')
    // navigation.navigate('SettingViewStackNavigator')
    this._resetSE('SubscriptionView')
  }

  _navToSelectPackageViewMenu = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { navigation } = this.props
    // // const storeId = selectedStore.get('id')
    // navigation.navigate('SettingViewStackNavigator')
    this._resetSE('SelectPackageView')
  }

  _navToPaymentSubscriptionPackageViewMenu = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { navigation } = this.props
    // // const storeId = selectedStore.get('id')
    // navigation.navigate('SettingViewStackNavigator')
    this._resetSE('PaymentSubscriptionPackageView')
  }

  _navToStoreWebsiteListViewMenu = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { navigation } = this.props
    // // const storeId = selectedStore.get('id')
    // navigation.navigate('SettingViewStackNavigator')
    this._resetSE('StoreWebsiteListView', { type: 'storefront' })
  }

  _navToStoreSalepageListViewMenu = async (menuIndex: number) => {
    await this._setSidebarButtonActiveIndex(menuIndex)
    // const { navigation } = this.props
    // // const storeId = selectedStore.get('id')
    // navigation.navigate('SettingViewStackNavigator')
    this._resetSE('StoreWebsiteListView', { type: 'sale_page' })
  }

  _renderVersionView = () => (
    <HStack my='1' w='full' justifyContent='center' alignItems='flex-end'>
      <XText fontSize='xs' variant='inactive'>
        {`Version: ${AUTOCONS.WEB_BUILD_TIMESTAMP}${MODE[0]}`}
      </XText>
    </HStack>
  )

  render() {
    const { selectedStore } = this.props
    if (!selectedStore) {
      return null
    }

    if (!selectedStore.get('id')) {
      return null
    }

    return (
      <XScrollView>
        {this._renderSideBarItems()}
        {/* {this._renderVersionView()} */}
      </XScrollView>
    )
  }
}

const s = {
  container: {
    // backgroundColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
    // backgroundColor: 'red',
    overflow: 'hidden',
  },
  contentContainer: {
    // flex: 1,
    alignSelf: 'center',
    // paddingTop: 50,
    width: MAX_WEB_WIDTH,
    // overflow: 'scroll',
    // height: '100%',
    // minHeight: 480,
    // backgroundColor: 'purple',
  },
  colSidebar: {
    flex: 1,
    flexBasis: 'auto',
    // height: '100%',
    flexDirection: 'column',
    width: SIDEBAR_WIDTH,
    // backgroundColor: 'white',
    // borderLeftWidth: 0.5,
    // borderRightWidth: 0.5,
    // borderColor: COLORS.TEXT_INACTIVE,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingRight: 4,
  },
  colMainContent: {
    flex: 1,
    flexBasis: 'auto',
    // height: '100%',
    width: MAIN_CONTENT_WIDTH,
    backgroundColor: 'lightgrey',
    borderColor: 'blue',
    overflow: 'hidden',
  },
  flex: {
    flex: 1,
    flexBasis: 'auto',
  },
  noflex: {
    flex: 0,
    flexBasis: 'auto',
  },
  height50: {
    height: 50,
  },
  imageLogo: {
    width: 112,
    height: 34,
  },
  fixedHeaderBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: HEADER_HEIGHT,
    // backgroundColor: COLORS.APP_MAIN,
  },
  bgAppMain: {
    backgroundColor: COLORS.APP_MAIN,
  },
  bgContainer: {
    backgroundColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
  },
  btnActiveContainer: {
    paddingVertical: 8,
    backgroundColor: COLORS.APP_SECONDARY,
  },
  btnInactiveContainer: {
    paddingVertical: 8,
  },
  btnActiveText: {
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.APP_MAIN,
  },
  btnInactiveText: {
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  btnXIcon: {
    flex: 0,
    flexBasis: 'auto',
    fontSize: 18,
    minWidth: 18,
    textAlign: 'left',
  },
}

// export default Sidebar

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    myStores: getMyStores(state),
  }),
  (dispatch) => ({
    // onBarcodeRead: bindActionCreators(BarcodeListenerActions.onBarcodeRead, dispatch),
    fetchInitializeData: bindActionCreators(StoreState.fetchInitializeData, dispatch),
    fetchMyStore: bindActionCreators(StoreState.fetchMyStore, dispatch),
    setXShippingConfig: bindActionCreators(XShippingActions.setXShippingConfig, dispatch),
    dispatch,
  })
)(Sidebar)
