import React from 'react'
import { TouchableOpacity, View, BackHandler, NativeEventSubscription } from 'react-native'
import _ from 'lodash'

import * as util from 'x/utils/util'
import api, { handleGlobalApiError } from 'x/utils/api'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import { S, COLORS } from 'x/config/styles'
import {
  ISelectedStoreMap,
  IStoreItemMapList,
  IOrder,
  IShippingOrderProductItem,
  IShippingTrackingNumberInfo,
  ICreateOrderShippingRequestBody,
  ICreateOrderShippingResponse,
  IShippingConfirmationFormData,
  IShippingViewSetting,
  IShippingViewNavParams,
  IXSellyErrorResponse,
  IXScreenProps,
  NavigationEventSubscription,
} from 'x/index'

import * as NavActions from 'x/utils/navigation'

// import BackIcon from 'xui/components/BackIcon'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HelpButton from 'xui/components/HelpButton'
import { IParcel } from 'x/modules/order/BaseMultiParcelView'
import { IXShippingConfig } from 'x/config/app'
import ShippingOrderCard from '../../components/shipment/ShippingOrderCard'
import ShippingProductsCard from '../../components/shipment/ShippingProductsCard'
import ShippingTrackingNumberCard from '../../components/shipment/ShippingTrackingNumberCard'
import ShippingConfirmationCard from '../../components/shipment/ShippingConfirmationCard'
import ShippingSettingIconButton from '../../components/shipment/ShippingSettingIconButton'
import XContent from '../../components/XContent'
import XContainer from '../../components/XContainer'
import XCustomHeader from '../../components/XCustomHeader'

interface IShippingViewProps extends IXScreenProps<IShippingViewNavParams> {
  selectedStore: ISelectedStoreMap
  myStores: IStoreItemMapList
  // xShippingConfig: List<IXShippingConfig>
  xShippingConfig: IXShippingConfig[]
}

interface IShippingViewState {
  isInitialized: boolean

  isOrderCardExpanded: boolean
  isProductsCardExpanded: boolean
  isTrackingNumberCardExpanded: boolean
  isConfirmationCardExpanded: boolean

  isOrderCardDisabled: boolean
  isProductsCardDisabled: boolean
  isTrackingNumberCardDisabled: boolean
  isConfirmationCardDisabled: boolean

  scannedOrder?: IOrder
  preloadOrder?: IOrder
  confirmedShippingOrderProducts?: IShippingOrderProductItem[]
  confirmedTrackingNumberInfo: IShippingTrackingNumberInfo
  // confirmedTrackingNumbers?: string[]
  extraFormData?: IShippingConfirmationFormData

  setting: IShippingViewSetting

  multiParcel?: IParcel[]

  // isSelectedAllProduct มีไว้เพื่อดูว่าได้เลือกตัวเลือกสินค้าทั้งหมดหรือไม่
  isSelectedAllProduct: boolean
}

const DEFAULT_STATE: IShippingViewState = {
  isInitialized: false,

  isOrderCardExpanded: false,
  isProductsCardExpanded: false,
  isTrackingNumberCardExpanded: false,
  isConfirmationCardExpanded: false,

  isOrderCardDisabled: true,
  isProductsCardDisabled: true,
  isTrackingNumberCardDisabled: true,
  isConfirmationCardDisabled: true,

  scannedOrder: null,
  preloadOrder: null,
  confirmedShippingOrderProducts: null,
  confirmedTrackingNumberInfo: null,
  // confirmedTrackingNumbers: null,
  extraFormData: null,

  setting: CONS.DEFAULT_SHIPPING_VIEW_SETTING,

  multiParcel: [],
  isSelectedAllProduct: false,
}
class ShippingView extends React.Component<IShippingViewProps, IShippingViewState> {
  static displayName = 'ShippingView'

  // static navigationOptions = {
  //   header: null,
  //   title: null,
  //   headerLeft: null,
  //   headerRight: null,
  // }

  _isSubmitting?: boolean

  _shippingSettingSaveKey: string

  _backHandleSubscription?: NativeEventSubscription

  _navWillFocusSubscription?: NavigationEventSubscription

  _navWillBlurSubscription?: NavigationEventSubscription

  onShippingSuccessCallack?: () => void

  isUnmounting?: boolean

  constructor(props: IShippingViewProps) {
    super(props)
    this.state = DEFAULT_STATE
    this.onShippingSuccessCallack = null
  }

  async componentDidMount() {
    // console.log('ShippingView:: this.props.selectedStore.toJS() => ', this.props.selectedStore.toJS())
    const { navigation } = this.props

    // Initialize shipping setting save key

    // set default view settings
    this._shippingSettingSaveKey = CONS.STATE_SAVE_KEYS.SHIPPING_VIEW_SETTING

    // when use this view from OrderView, we will add prefix to default key
    const preloadOrderDetail = this._getPreloadOrderDetail()
    // console.log('preloadOrderDetail => ', preloadOrderDetail)
    if (!_.isNil(preloadOrderDetail)) {
      this._shippingSettingSaveKey = CONS.STATE_SAVE_PREFIX.SHIPPING_VIEW_FROM_ORDER_VIEW + this._shippingSettingSaveKey
    }

    // Subscibe reactive events
    this._navWillFocusSubscription = navigation.addListener('focus', this._setDirtyHandler)
    this._navWillBlurSubscription = navigation.addListener('blur', this._unsetBackHandler)
    this.onShippingSuccessCallack = util.getNavParam(this.props, 'onShippingSuccess', null)
    await this._initializeView()
    // await this._fetchMultiParcel()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.scannedOrder && this.state.scannedOrder) {
      this._setDirtyHandler()
    }
    if (prevState.scannedOrder && !this.state.scannedOrder) {
      this._unsetBackHandler()
    }
  }

  componentWillUnmount() {
    if (!this.isUnmounting) {
      this.isUnmounting = true

      this._unsetBackHandler()

      if (this._navWillFocusSubscription) {
        this._navWillFocusSubscription()
      }

      if (this._navWillBlurSubscription) {
        this._navWillBlurSubscription()
      }
    }
  }

  _fetchMultiParcel = async (orderId: number) => {
    const { selectedStore } = this.props
    // const preloadOrderDetail = this._getPreloadOrderDetail()
    const apiOptions = {
      showSpinner: true,
    }
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      // order_id: preloadOrderDetail.id,
      order_id: orderId,
    }
    const res = await api.postV2(api.POST_MULTIPARCEL_LIST, body, apiOptions)
    // console.log('res => ', res)
    // console.log('newParcels => ', newParcels)
    if (res.order_id) {
      await util.setStatePromise(this, {
        multiParcel: res.parcels,
      })
    }
  }

  _setDirtyHandler = () => {
    const isDirty = !_.isNil(this.state.scannedOrder)
    if (isDirty) {
      // แจ้งว่าข้อมูล dirty
      this._backHandleSubscription = BackHandler.addEventListener('hardwareBackPress', this._onPressGoBackAction)
    }
  }

  _unsetBackHandler = () => {
    if (this._backHandleSubscription) {
      this._backHandleSubscription.remove()
    }
  }

  _onPressGoBackAction = () => this._handlePressBackBtn()

  _initializeView = async () => {
    await util.setStatePromise(this, DEFAULT_STATE)

    // Init activeStatesMap
    const newState: Partial<IShippingViewState> = {}

    // do active order card
    newState.isOrderCardExpanded = true
    // newState.isOrderCardDisabled = false

    const preloadOrderDetail = this._getPreloadOrderDetail()
    if (preloadOrderDetail) {
      newState.preloadOrder = preloadOrderDetail
    }

    if (preloadOrderDetail && preloadOrderDetail.id) {
      await this._fetchMultiParcel(preloadOrderDetail.id)
    }

    // set default view settings
    newState.setting = preloadOrderDetail ? CONS.DEFAULT_SHIPPING_VIEW_SETTING_FROM_ORDER_VIEW : CONS.DEFAULT_SHIPPING_VIEW_SETTING

    // load shipping view setting
    const savedSetting = (await util.loadDefaultState(this._shippingSettingSaveKey)) as IShippingViewSetting
    if (!_.isEmpty(savedSetting)) {
      newState.setting = savedSetting
    }

    // get ready to render
    newState.isInitialized = true

    await util.setStatePromise(this, newState)
  }

  _resetView = async () => {
    await util.setStatePromise(this, { isInitialized: false })
    await util.delay(200)
    await this._initializeView()
  }

  _getPreloadOrderDetail = () => {
    try {
      return util.getNavParam(this.props, 'fromOrder', null)
    } catch (error) {
      return null
    }
  }

  _getSelectedStoreId = (): number => {
    try {
      return this.props.selectedStore.get('id')
    } catch (error) {
      return null
    }
  }

  // _getStoreXShippings = (): IStoreXShippingItem[] => {
  //   try {
  //     return this.props.selectedStore.get('xshippings').toJS()
  //   } catch (error) {
  //     return []
  //   }
  // }

  _getStoreXShippings = (): IXShippingConfig[] => {
    try {
      const { xShippingConfig } = this.props
      // console.log('_getStoreXShippings xShippingConfig => ', xShippingConfig)
      // const storeXShippings = isImmutable(xShippingConfig) ? xShippingConfig.toArray() : xShippingConfig
      // console.log('_getStoreXShippings storeXShippings => ', storeXShippings)

      return xShippingConfig.filter((x) => x.store_id === this._getSelectedStoreId())
    } catch (error) {
      console.log('_getStoreXShippings error => ', error)
      return []
    }
  }

  _getMyStoreIds = () => {
    try {
      return this.props.myStores.map((st) => st.get('id')).toArray()
    } catch (error) {
      return []
    }
  }

  _getSelectedStoreName = (): string => {
    try {
      return this.props.selectedStore.get('name')
    } catch (error) {
      return null
    }
  }

  _getMyStoreNames = (): string[] => {
    try {
      return this.props.myStores.map((st) => st.get('name')).toArray()
    } catch (error) {
      return null
    }
  }

  _isXShippingEnabled = () => {
    try {
      return this.props.selectedStore.get('s_use_xshipping')
    } catch (error) {
      return false
    }
  }

  // https://reactnative.dev/docs/backhandler#pattern
  _handlePressBackBtn = (): boolean => {
    if (this.state.scannedOrder) {
      p.op.showConfirmation(
        'คุณยังไม่ได้แจ้งจัดส่ง',
        `หากต้องการบันทึกการจัดส่งนี้ กรุณากด "ปิด" แล้วกด "จัดส่ง" (ด้านขวาล่าง)`,
        () => {
          // do nothing
        },
        this._navBack,
        'ปิด',
        'ออกจากหน้านี้'
      )
      return true
    }

    this._navBack()
    return false
  }

  _navBack = () => {
    util.navGoBack(this.props)
  }

  _renderSettingIcon = () => {
    const preloadOrderDetail = this._getPreloadOrderDetail()
    const isOrderDetail = !_.isNil(preloadOrderDetail)
    const hideSettingKeys = isOrderDetail ? ['scanOrderInEveryMyStores'] : null
    const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    return (
      <HStack>
        <XFaqModal
          key='วิธีใช้งานสแกนจัดส่ง'
          headerTitle='วิธีใช้งานสแกนจัดส่ง'
          // @ts-ignore
          FAQ={FAQ_CONSTANTS.FAQ_SCAN_SHIPPING}
          initiateOpenIndex={[0]}
          renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
        />
        <ShippingSettingIconButton
          hideSettingKeys={hideSettingKeys}
          currentSetting={this.state.setting}
          onChange={this._onSettingChange}
          myStoreNames={this._getMyStoreNames()}
          selectedStoreName={this._getSelectedStoreName()}
          onCloseAfterModified={this._onCloseAfterModified}
        />
      </HStack>
    )
  }

  _onCloseAfterModified = async (diffSettingModified: Partial<IShippingViewSetting>) => {
    // console.log('_onCloseAfterModified diffSettingModified => ', diffSettingModified)
    if (_.has(diffSettingModified, 'skipCheckShippingProducts') || _.has(diffSettingModified, 'skipScanTrackingNumber')) {
      await this._resetView()
    }
  }

  _onSettingChange = (newSetting: IShippingViewSetting) => {
    this.setState({ setting: newSetting }, async () => {
      await util.saveDefaultState(this._shippingSettingSaveKey, newSetting)
    })
  }

  _renderCustomHeader = () => (
    <XCustomHeader
      title='สแกนใบปะหน้าพัสดุเพื่อแจ้งจัดส่ง'
      headerLeftProps={p.op.isWeb() && !this.state.preloadOrder ? undefined : { backIcon: true, onPressItem: this._handlePressBackBtn }}
      renderHeaderRight={this._renderSettingIcon}
    />
  )

  _isOrderCancelled = (order: IOrder) => order && order.state >= 180

  _onScanOrderFound = async (order: IOrder) => {
    // console.log('_onScanOrderFound order => ', order)
    // pass secion 1 / open section 2

    await this._fetchMultiParcel(order.id)

    const isOrderCancelled = this._isOrderCancelled(order)

    if (isOrderCancelled) {
      p.op.playSoundRingError()
    }

    await util.setStatePromise(this, {
      // isOrderCardExpanded: false,
      // isOrderCardDisabled: false,
      // isProductsCardDisabled: false,
      // isProductsCardExpanded: true,
      isOrderCardDisabled: !!isOrderCancelled,
      isProductsCardDisabled: !!isOrderCancelled,
      isProductsCardExpanded: !isOrderCancelled,
      scannedOrder: order,
    })
  }

  _onScanOrderFail = async (req, err: IXSellyErrorResponse) => {
    // console.log('_onScanOrderFail err => ', err)
    p.op.playSoundRingError()

    if (err.error.error_code === '07_007' && req.search_order_id) {
      // await p.op.alertPromise('ไม่พบข้อมูลออเดอร์', `กรุณาตรวจสอบเลขออเดอร์แล้วลองใหม่อีกครั้ง เนื่องจากไม่พบ "${req.search_order_id}"`)
      await p.op.alertPromise(
        `ไม่พบออเดอร์ ${req.search_order_id}`,
        `กรุณาสแกนหรือพิมพ์ใหม่อีกครั้ง เนื่องจากไม่พบออเดอร์ที่สแกน(ระบุ)มานี้\n\n"${req.search_order_id}"
        \nหากสแกนใหม่ กรุณาสแกนให้กล้องไม่โดนบาร์โค้ดอื่นๆที่อยู่ใกล้ เช่น บาร์โค้ดติดตามพัสดุจากขนส่ง`
      )
    } else if (err.error.status_code === 500) {
      await p.op.alertPromise(
        'เกิดข้อผิดพลาด (500)',
        `กรุณาตรวจสอบเลขออเดอร์แล้วลองใหม่อีกครั้ง เนื่องจากมีปัญหาที่เกิดจากค้นหา "${req.search_order_id}"`
      )
    } else {
      await handleGlobalApiError(err, {})
    }

    // reset camera
    // await util.setStatePromise(this, { isOrderCardExpanded: false })
    // await util.delay(200)
    // await util.setStatePromise(this, { isOrderCardExpanded: true })
  }

  _getOrderProducts = () => {
    const { scannedOrder = null } = this.state
    if (_.isNil(scannedOrder)) {
      return null
    }
    return scannedOrder.products
  }

  _onScanProductFinished = async (confirmedShippingOrderProducts: IShippingOrderProductItem[], isSelectedAllProduct: boolean) => {
    // console.log('_onScanProductFinished confirmedShippingOrderProducts => ', confirmedShippingOrderProducts)
    await util.setStatePromise(this, {
      isProductsCardExpanded: false,
      isTrackingNumberCardDisabled: false,
      isTrackingNumberCardExpanded: true,

      confirmedShippingOrderProducts,
      isSelectedAllProduct,
    })
  }

  _onScanTrackingNumberFinished = async (confirmedTrackingNumberInfo: IShippingTrackingNumberInfo) => {
    // _onScanTrackingNumberFinished = async (confirmedTrackingNumbers: string[]) => {
    // console.log('_onScanTrackingNumberFinished confirmedTrackingNumbers => ', confirmedTrackingNumbers)
    // console.log('_onScanTrackingNumberFinished confirmedTrackingNumberInfo => ', confirmedTrackingNumberInfo)
    await util.setStatePromise(this, {
      isTrackingNumberCardExpanded: false,
      isConfirmationCardDisabled: false,
      isConfirmationCardExpanded: true,

      confirmedTrackingNumberInfo,
      // confirmedTrackingNumbers,
    })
  }

  _revertTrackingNumberCard = async () => {
    const isUserConfirm = await p.op.isUserConfirm('แก้ไขเลขพัสดุ?', '', 'ใช่', 'ไม่ใช่')
    if (!isUserConfirm) {
      return false
    }

    await util.setStatePromise(this, {
      isTrackingNumberCardExpanded: true,
      isConfirmationCardDisabled: true,
      isConfirmationCardExpanded: false,
      confirmedTrackingNumberInfo: null,
    })
    return true
  }

  _getPreparedOrderProductsRequest = (): Array<{ pp_id: number; qty: number }> => {
    const preparedOrderProducts: Array<{ pp_id: number; qty: number }> = []
    this.state.confirmedShippingOrderProducts.forEach((csp) => {
      const qty = csp.scannedQty
      if (qty > 0) {
        preparedOrderProducts.push({ pp_id: csp.pp_id, qty })
      }
    })
    return preparedOrderProducts
  }

  // _getPreparedTrackingNumberRequestObject = (): { tracking_number: string } | { tracking_numbers: string[] } => {
  //   const { confirmedTrackingNumbers } = this.state
  //   if (confirmedTrackingNumbers.length > 1) {
  //     return { tracking_numbers: confirmedTrackingNumbers }
  //   }
  //   return { tracking_number: confirmedTrackingNumbers[0] }
  // }

  _getRequestBody = (): ICreateOrderShippingRequestBody => {
    const { scannedOrder, confirmedTrackingNumberInfo, extraFormData } = this.state
    // const trackingNumberObj = this._getPreparedTrackingNumberRequestObject()
    const reqBody: ICreateOrderShippingRequestBody = {
      order_id: scannedOrder.id,
      store_id: scannedOrder.store_id,
      products: this._getPreparedOrderProductsRequest(),
      // ...trackingNumberObj,
      ...confirmedTrackingNumberInfo,
      ...extraFormData,
    }
    return reqBody
  }

  _doSubmitCreateOrderShipping = async () => {
    try {
      const reqBody = this._getRequestBody()
      // console.log('_doSubmitCreateOrderShipping reqBody => ', reqBody)
      const apiOptions = {
        messages: {
          successMsg: 'ดำเนินการการจัดส่งเสร็จสิ้น',
          errorMsg: 'เกิดข้อผิดพลาดในดำเนินการจัดส่ง',
        },
        showSpinner: true,
      }
      const res = await api.put<ICreateOrderShippingRequestBody, ICreateOrderShippingResponse>(api.PUT_ORDER_SHIP, reqBody, apiOptions)
      // console.log('_doSubmitCreateOrderShipping res: ', res)
      if (res) {
        p.op.playSoundRingSuccess()
        await util.delay(200)
        await this._resetView()
        if (_.isFunction(this.onShippingSuccessCallack)) {
          this.onShippingSuccessCallack()
        }
        if (this.state.preloadOrder) {
          this._navBack()
        }
      }
    } catch (err) {
      // console.log('_doSubmitCreateOrderShipping err: ', err)
      p.op.playSoundRingError()

      // case นี้เกิดจากการพยายามกดส่งเกินจำนวน / เคยส่งเสร็จสิ้นไปแล้ว submit ซ้ำ
      // @ts-ignore
      if (err.error.error_code === '10_027') {
        this._resetView()
      }
    }
  }

  _onPressShippingReset = async () => {
    if (this._isSubmitting) {
      return
    }

    this._isSubmitting = true

    let isUserConfirm = false
    if (this._isNothingToBeResetButtonDisabled()) {
      isUserConfirm = true
    }

    if (!isUserConfirm) {
      isUserConfirm = await p.op.isUserConfirm(
        'ยืนยันการรีเซ็ต',
        'กรุณายืนยันว่าคุณต้องการลบค่าเดิมทั้งหมด แล้วเริ่มดำเนินการสแกนออเดอร์ใหม่'
      )
    }

    if (isUserConfirm) {
      await this._resetView()
    }
    this._isSubmitting = false
  }

  _onPressShippingSubmit = async () => {
    if (this._isSubmitting) {
      return
    }
    this._isSubmitting = true
    const isWaitingAlert = await this._checkAllToShowWaitingAlerts()
    if (isWaitingAlert) {
      this._isSubmitting = false
      return
    }

    await this._doSubmitCreateOrderShipping()
    this._isSubmitting = false
  }

  _onExtraInfoFormDataChange = async (extraFormData: IShippingConfirmationFormData) => {
    // console.log('_onExtraInfoChange extraFormData => ', extraFormData)
    await util.setStatePromise(this, { extraFormData })
  }

  _onPressToggleOrderCard = async (nextExpended: boolean) => {
    // console.log('_onPressToggleOrderCard this.state.isOrderCardExpanded => ', this.state.isOrderCardExpanded)
    await util.setStatePromise(this, { isOrderCardExpanded: nextExpended })
  }

  _onPressToggleProductsCard = async (nextExpended: boolean) => {
    const isWaitingAlert = await this._checkToShowAlertWatingScanOrder()
    if (isWaitingAlert) {
      return
    }
    await util.setStatePromise(this, { isProductsCardExpanded: nextExpended })
  }

  _onPressToggleTrackingNumberCard = async (nextExpended: boolean) => {
    let isWaitingAlert = await this._checkToShowAlertWatingScanOrder()
    if (isWaitingAlert) {
      return
    }
    isWaitingAlert = await this._checkToShowAlertWatingScanProducts()
    if (isWaitingAlert) {
      return
    }

    await util.setStatePromise(this, { isTrackingNumberCardExpanded: nextExpended })
  }

  _onPressToggleConfirmationCard = async (nextExpended: boolean) => {
    const isWaitingAlert = await this._checkAllToShowWaitingAlerts()
    if (isWaitingAlert) {
      return
    }
    await util.setStatePromise(this, { isConfirmationCardExpanded: nextExpended })
  }

  _checkAllToShowWaitingAlerts = async () => {
    let isAlert = false

    isAlert = await this._checkToShowAlertWatingScanOrder()

    if (!isAlert) {
      isAlert = await this._checkToShowAlertWatingScanProducts()
    }

    if (!isAlert) {
      isAlert = await this._checkToShowAlertWatingTrackingNumber()
    }

    return isAlert
  }

  _checkToShowAlertWatingScanOrder = async () => {
    let isAlert = false
    if (this.state.isOrderCardDisabled || !this.state.scannedOrder) {
      await p.op.alertPromise('ยังไม่พร้อมใช้งาน', 'กรุณาสแกนออเดอร์ให้เสร็จสิ้นก่อน')
      isAlert = true
    }
    return isAlert
  }

  _checkToShowAlertWatingScanProducts = async () => {
    let isAlert = false
    if (this.state.isProductsCardDisabled || !this.state.confirmedShippingOrderProducts) {
      await p.op.alertPromise('ยังไม่พร้อมใช้งาน', 'กรุณาตรวจสอบสินค้าให้เสร็จสิ้นก่อน')
      isAlert = true
    }
    return isAlert
  }

  _checkToShowAlertWatingTrackingNumber = async () => {
    let isAlert = false
    if (this.state.isTrackingNumberCardDisabled || !this.state.confirmedTrackingNumberInfo) {
      await p.op.alertPromise('ยังไม่พร้อมใช้งาน', 'กรุณาระบุเลขพัสดุให้เสร็จสิ้นก่อน')
      isAlert = true
    }
    return isAlert
  }

  _renderContent() {
    const {
      isInitialized,

      isOrderCardExpanded,
      isProductsCardExpanded,
      isTrackingNumberCardExpanded,
      isConfirmationCardExpanded,

      isOrderCardDisabled,
      isProductsCardDisabled,
      isTrackingNumberCardDisabled,
      isConfirmationCardDisabled,

      scannedOrder,
      preloadOrder,
      // confirmedTrackingNumberInfo,
      // confirmedShippingOrderProducts,
      // confirmedTrackingNumbers,
      setting,
      multiParcel,
      isSelectedAllProduct,
    } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XContent
      // style={[
      //   S.FLEX,
      //   S.PADDING_8,
      //   S.BG_LIGHT_GREY,
      //   // {
      //   //   flexBasis: 'auto',
      //   // },
      // ]}
      // persistentScrollbar={p.op.isWeb()}
      // contentContainerStyle={S.PADDING_8}
      // style={[S.PADDING_8]}
      >
        <VStack w='full' p='2'>
          <ShippingOrderCard
            disabled={isOrderCardDisabled}
            expanded={isOrderCardExpanded}
            onPressToggle={this._onPressToggleOrderCard}
            myStoreIds={this._getMyStoreIds()}
            selectedStoreId={this._getSelectedStoreId()}
            onSuccess={this._onScanOrderFound}
            onFail={this._onScanOrderFail}
            onRequestGoToOrderDetail={this._navToOrderDetail}
            onRequestReset={this._resetView}
            scanEveryStores={setting.scanOrderInEveryMyStores}
            preloadOrder={preloadOrder}
          />

          <ShippingProductsCard
            skip={setting.skipCheckShippingProducts}
            disabled={isProductsCardDisabled}
            expanded={isProductsCardExpanded}
            onPressToggle={this._onPressToggleProductsCard}
            onFinish={this._onScanProductFinished}
            onRequestReset={this._resetView}
            products={this._getOrderProducts()}
            // แสดง warning นำทางไปเปิด setting สำหรับปิดการ skip สแกนตรวจสอบสินค้า
            showSkipScanSettingInfo={_.isNil(setting.lastModified) && setting.skipCheckShippingProducts}
          />

          <ShippingTrackingNumberCard
            // key={scannedOrder && scannedOrder.id ? scannedOrder.id.toString() : 'empty'}
            skip={setting.skipScanTrackingNumber}
            disabled={isTrackingNumberCardDisabled}
            expanded={isTrackingNumberCardExpanded}
            onPressToggle={this._onPressToggleTrackingNumberCard}
            onFinish={this._onScanTrackingNumberFinished}
            onRequestRevert={this._revertTrackingNumberCard}
            order={scannedOrder}
            xShippingEnabled={this._isXShippingEnabled()}
            storeXShippings={this._getStoreXShippings()}
            multiParcel={multiParcel}
            isSelectedAllProduct={isSelectedAllProduct}
          />

          <ShippingConfirmationCard
            disabled={isConfirmationCardDisabled}
            expanded={isConfirmationCardExpanded}
            onPressToggle={this._onPressToggleConfirmationCard}
            // onSubmit={this._onSubmitConfirmation}
            onFormChange={this._onExtraInfoFormDataChange}
            onRequestSubmit={this._onPressShippingSubmit}
            order={scannedOrder}
            // confirmedShippingOrderProducts={confirmedShippingOrderProducts}
            // confirmedTrackingNumbers={confirmedTrackingNumbers}
          />

          <View style={{ height: 144 }} />
        </VStack>
      </XContent>
    )
  }

  _isNothingToBeResetButtonDisabled = () => {
    const { scannedOrder } = this.state
    return _.isNil(scannedOrder)
  }

  _isShipButtonDisabled = () => {
    const { scannedOrder, confirmedShippingOrderProducts, confirmedTrackingNumberInfo, extraFormData } = this.state
    return (
      _.isNil(scannedOrder) || _.isNil(confirmedShippingOrderProducts) || _.isNil(confirmedTrackingNumberInfo) || _.isNil(extraFormData)
    )
  }

  _navToOrderDetail = () => {
    const { scannedOrder } = this.state
    this.props.navigation.dispatch(
      NavActions.navToOrderDetail({
        store_id: scannedOrder.store_id,
        order_id: scannedOrder.id,
        isNoInitFetch: true,
        mode: CONS.ORDER_VIEW_MODE.VIEW_EDIT,
      })
    )
  }

  _renderFooter = () => {
    const isShipButtonDisabled = this._isShipButtonDisabled()
    return (
      <View
        style={[
          S.WIDTH_FULL,
          S.ROW_MIDDLE_BETWEEN,
          // S.MIN_HEIGHT_44,
          S.PADDING_8,
          S.BG_LIGHT_GREY,
          {
            position: 'absolute',
            bottom: 0,
            height: 54,
            borderTopWidth: 0.5,
            borderTopColor: COLORS.TEXT_INACTIVE,
          },
        ]}>
        <TouchableOpacity
          onPress={this._onPressShippingReset}
          style={[S.FLEX, S.BORDER, S.BORDERS_ACTIVE, S.BG_WHITE, S.BORDER_RADIUS_8, S.COLUMN_CENTER, S.MIN_HEIGHT_44]}>
          <XText fontSize='md' bold style={[S.TEXT_ALIGN_CENTER, S.WIDTH_FULL]}>
            เริ่มใหม่
          </XText>
        </TouchableOpacity>

        {/* Placeholder */}
        <View style={[S.FLEX]} />

        <TouchableOpacity
          // disabled={isShipButtonDisabled}
          onPress={this._onPressShippingSubmit}
          style={[
            S.FLEX,
            S.BORDER,
            S.BORDER_RADIUS_8,
            S.COLUMN_CENTER,
            S.MIN_HEIGHT_44,
            isShipButtonDisabled ? S.BORDERS_ACTIVE : S.BORDERS_PRIMARY,
            isShipButtonDisabled ? S.BG_GREY : S.BG_PRIMARY,
          ]}>
          <XText fontSize='md' bold variant={isShipButtonDisabled ? 'active' : 'activeDark'} style={[S.TEXT_ALIGN_CENTER, S.WIDTH_FULL]}>
            จัดส่ง
          </XText>
        </TouchableOpacity>
      </View>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        {this._renderContent()}
        {this._renderFooter()}
      </XContainer>
    )
  }
}

export default ShippingView
