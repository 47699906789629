// import { Drawer } from 'native-base'
import { connect } from 'react-redux'
import { getSelectedStore, getCategoryList, getSellerStores } from 'x/redux/selectors'
import { bindActionCreators } from 'redux'
import * as StoreState from 'x/modules/store/StoreState'
import * as util from 'x/utils/util'
import * as CategoryActions from 'x/modules/category/categoryState'

import { IProductListFilterState, IProductListFilterViewProps, IProductListFilterViewState } from 'x/index'

// import NavHeaderButton from './NavHeaderButton'
// import XCheckbox from './XCheckbox'
// import XCheckButton from './XCheckButton'
import BaseUIProductListFilterView from './BaseUIProductListFilterView'

export class ProductListFilterView extends BaseUIProductListFilterView<IProductListFilterViewProps, IProductListFilterViewState> {
  static displayName = 'ProductListFilterView'

  _getHeaderTitle = () => {
    const headerTitle = util.getNavParam(this.props, 'headerTitle', 'กรองสินค้า')
    return headerTitle
  }

  isHiddenSortFilterCard = () => false

  isHiddenStockFilterCard = () => true

  isHiddenCostFilterCard = () => true

  isHiddenDatetimeFilterCard = () => false

  isHiddenMkpFilterCard = () => false

  isHiddenCategoryFilterCard = () => false

  isHiddenPriceFilterCard = () => true

  _renderMkpPairedVisibility = () => null

  _setupNavParamsToState = async () => {
    const defaultSetting = util.getNavParam(this.props, 'defaultSetting', {}) as IProductListFilterState
    const appliedSetting = util.getNavParam(this.props, 'appliedSetting', {}) as IProductListFilterState
    defaultSetting.isProductTemplateFilter = true
    appliedSetting.isProductTemplateFilter = true
    await util.setStatePromise(this, { defaultSetting, currentSetting: appliedSetting, appliedSetting })
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    sellerStores: getSellerStores(state),
    categoryList: getCategoryList(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
    requestCategoryList: bindActionCreators(CategoryActions.requestCategoryList, dispatch),
  })
)(ProductListFilterView)
