import React, { Component } from 'react'
import { TouchableOpacity } from 'react-native'

// import { ScrollView } from 'native-base'

import * as util from 'x/utils/util'
import _ from 'lodash'

import { IOrderListItemVisibilityControlProps, IOrderListItemVisibilityControlState, IOrderListItemVisibilitySetting } from 'x/types'
import { diff } from 'deep-object-diff'
import XOverlay from '../XOverlay'
import XText from '../XText'
import VStack from '../VStack'
import HStack from '../HStack'
import XIcon from '../XIcon'
import XCustomHeader from '../XCustomHeader'
import Box from '../Box'
import XSwitch from '../XSwitch'

export default class OrderListItemVisibilityControl extends Component<
  IOrderListItemVisibilityControlProps,
  IOrderListItemVisibilityControlState
> {
  static displayName = 'OrderListItemVisibilityControl'

  constructor(props: IOrderListItemVisibilityControlProps) {
    super(props)

    this.state = {
      isInitialized: false,
      isOverlayVisible: false,
      visibilitySetting: {},
    }
  }

  async componentDidMount() {
    await this._initialize()

    await util.setStatePromise(this, { isInitialized: true })
  }

  _initialize = async () => {
    const { visibilitySetting } = this.props

    await util.setStatePromise(this, { visibilitySetting })
  }

  _renderButton = () => {
    const { renderButton, disabled } = this.props

    if (_.isFunction(renderButton)) {
      return renderButton({ onPress: this.openOverlay, disabled })
    }

    return (
      <TouchableOpacity onPress={this.openOverlay}>
        <XIcon name='eye' family='Entypo' />
      </TouchableOpacity>
    )
  }

  openOverlay = () => {
    const { visibilitySetting = {} } = this.props

    this.setState({ isOverlayVisible: true, visibilitySetting })
  }

  closeOverlay = () => {
    this.setState({ isOverlayVisible: false })
  }

  _onRequestClose = () => {
    if (this._isDirty()) {
      return
    }
    this.closeOverlay()
  }

  _onRequestForceClose = () => {
    this.closeOverlay()
  }

  _onSubmit = async () => {
    this.closeOverlay()
    await util.delay(100)

    const { onSubmit } = this.props
    if (_.isFunction(onSubmit)) {
      const { visibilitySetting } = this.state
      await onSubmit(visibilitySetting)
    }
  }

  _setVisibilitySetting = (newVisibilitySetting: Partial<IOrderListItemVisibilitySetting>) => {
    const { visibilitySetting } = this.state
    const newSetting = { ...visibilitySetting, ...newVisibilitySetting }
    this.setState({ visibilitySetting: newSetting })
  }

  _isDirty = () => {
    try {
      const { visibilitySetting } = this.state
      const { visibilitySetting: defaultVisibilitySetting } = this.props

      const diffObj = diff(defaultVisibilitySetting, visibilitySetting)

      return _.isObject(diffObj) && !_.isEmpty(diffObj)
    } catch (error) {
      return true
    }
  }

  _renderOverlay = () => {
    const { isOverlayVisible, visibilitySetting = {} } = this.state
    if (!isOverlayVisible) {
      return null
    }

    const {
      //
      isReceiverNameShow = true,
      isCreatedAtShow = true,
      isSellerNameShow = true,
      isHelperSellerNameShow = true,

      isShippingTypeShow = false,
      isPpInfoShow = true,
      isReceiverAddressShow = false,
    } = visibilitySetting

    const isSubmitDisabled = !this._isDirty()

    return (
      <XOverlay
        contentStyle={{
          width: 320,
          minHeight: 290,
        }}
        visible={isOverlayVisible}
        onRequestClose={this._onRequestClose}
        // onRequestClose={() => {
        //   // do nothing
        // }}
      >
        <XCustomHeader headerRightProps={{ closeIcon: true, onPressItem: this._onRequestForceClose }} title='ตั้งค่าการแสดงข้อมูล' />
        <Box h='1px' w='full' borderBottomWidth='1' borderBottomColor='gray.400' />
        <VStack flex={1} w='full' px='3' py='2' space='2.5'>
          {/* // content begin // */}
          <HStack w='full' space='1'>
            <VStack flex={1}>
              {/* <XText>แสดงชื่อผู้ขาย</XText> */}
              <XText>แสดงชื่อผู้ช่วยที่สร้างออเดอร์</XText>
            </VStack>

            <XSwitch
              value={isHelperSellerNameShow}
              onValueChange={(newValue) => this._setVisibilitySetting({ isHelperSellerNameShow: newValue })}
            />
          </HStack>

          <HStack w='full' space='1'>
            <VStack flex={1}>
              <XText>แสดงชื่อร้านขายส่ง</XText>
            </VStack>

            <XSwitch value={isSellerNameShow} onValueChange={(newValue) => this._setVisibilitySetting({ isSellerNameShow: newValue })} />
          </HStack>

          <HStack w='full' space='1'>
            <VStack flex={1}>
              <XText>แสดงวันที่สร้างออเดอร์</XText>
            </VStack>

            <XSwitch value={isCreatedAtShow} onValueChange={(newValue) => this._setVisibilitySetting({ isCreatedAtShow: newValue })} />
          </HStack>

          <HStack w='full' space='1'>
            <VStack flex={1}>
              <XText>แสดงรูปแบบจัดส่ง</XText>
            </VStack>

            <XSwitch
              value={isShippingTypeShow}
              onValueChange={(newValue) => this._setVisibilitySetting({ isShippingTypeShow: newValue })}
            />
          </HStack>

          <HStack w='full' space='1'>
            <VStack flex={1}>
              <XText>แสดงรายการสินค้า</XText>
            </VStack>

            <XSwitch value={isPpInfoShow} onValueChange={(newValue) => this._setVisibilitySetting({ isPpInfoShow: newValue })} />
          </HStack>

          <HStack w='full' space='1'>
            <VStack flex={1}>
              <XText>แสดงชื่อลูกค้า</XText>
            </VStack>

            <XSwitch
              value={isReceiverNameShow}
              onValueChange={(newValue) => this._setVisibilitySetting({ isReceiverNameShow: newValue })}
            />
          </HStack>

          <HStack w='full' space='1'>
            <VStack flex={1}>
              <XText>แสดงที่อยู่ผู้รับ</XText>
            </VStack>

            <XSwitch
              value={isReceiverAddressShow}
              onValueChange={(newValue) => this._setVisibilitySetting({ isReceiverAddressShow: newValue })}
            />
          </HStack>
        </VStack>
        {/* // content end // */}
        <HStack w='full' p='2' alignItems='center' justifyContent='center'>
          <TouchableOpacity disabled={isSubmitDisabled} onPress={this._onSubmit}>
            <HStack
              w='full'
              px='3'
              py='1.5'
              alignItems='center'
              justifyContent='center'
              bg={isSubmitDisabled ? 'muted.300' : 'primary.500'}
              borderRadius='lg'>
              <XText variant={isSubmitDisabled ? 'active' : 'activeDark'} bold>
                บันทึก
              </XText>
            </HStack>
          </TouchableOpacity>
        </HStack>
      </XOverlay>
    )
  }

  _renderMain = () => (
    <>
      {this._renderButton()}
      {this._renderOverlay()}
    </>
  )

  render() {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    return this._renderMain()
  }
}
