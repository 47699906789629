import React from 'react'
import _ from 'lodash'
// import EntypoIcon from 'react-native-vector-icons/Entypo'
import xCONS from 'x/config/constants'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
// import SRowItem from '../../components/renderRowLeftAndRight'
import { COLORS, STYLES } from 'x/config/styles'
import BaseActivePackageDetail from 'x/modules/subscription/BaseActivePackageDetail'
import HStack from 'xui/components/HStack'
import * as util from 'x/utils/util'
import ProfilePackage from 'xui/components/ProfilePackage'
import VStack from 'xui/components/VStack'
import XCard from 'xui/components/XCard'
import PackageTag from 'xui/components/PackageTag'
import XStatusBar from '../../components/XStatusBar'
import { RenderBtnCallActionPackage, renderCurrentBillingCycle } from './BaseUISubscriptionView'
import XUISubcriptionDetail from './XUISubcriptionDetail'

const BTN_BUY_PACKAGE = require('../../img/btn/btn_buy_package.png')

const { expireWarningInDay, expireDangerInDay, orderDangerInPercentage, orderWarningInPercentage } = xCONS.SUBSCRIPTION_SETTINGS

const SERVER_DATE_FORMAT = 'YYYY-MM-DD'

const { FONT_SIZE_SMALLER } = STYLES
const { APP_MAIN, APP_SECONDARY, TEXT_ACTIVE, TEXT_INACTIVE, TEXT_ACTIVE_DARK, BRAND_Warning, BRAND_Danger, BG_LIGHT_GREY } = COLORS

const LOCAL_DATE_FORMAT = 'D MMM YYYY'

export default abstract class BaseUIActivePackageDetail extends BaseActivePackageDetail {
  _renderProfile = () => {
    const { subscription, profile } = this.props
    if (_.isNil(profile) || _.isNil(subscription)) {
      return null
    }
    // console.log('profile => ', profile.toJS())
    if (!profile || !profile.get('user_id')) {
      return null
    }

    const typeId: number = subscription.has('type') ? subscription.get('type') : 0

    return <ProfilePackage profile={profile.toJS()} typeId={typeId} />
  }

  _renderMyPackage = () => {
    const { subscription } = this.props
    // const packageName = util.getSubscriptionTypeName(subscription.get('type'))

    return (
      <VStack m='2'>
        <XText variant='active' fontSize='lg' bold>
          แพ็กเกจปัจจุบันของคุณ
        </XText>
        <PackageTag typeId={subscription.get('type')} width={80} height={32} />
      </VStack>
    )
  }

  _renderNowBillingCycle = () => {
    const { subscription } = this.props
    return <XCard m='2'>{renderCurrentBillingCycle(subscription)}</XCard>
  }

  _renderBtnBuyPackage = () => {
    const isCanRenderBuyPack = util.showBtnBuyPackage()
    if (!isCanRenderBuyPack) {
      return null
    }

    const textLabel = 'ซื้อแพ็กเกจ'
    return (
      <VStack>
        <HStack alignItems='center' justifyContent='center'>
          {RenderBtnCallActionPackage(textLabel, () => this._navToInAppPurchaseView('buy_package'))}
        </HStack>
      </VStack>
    )
  }

  _renderBtnPerPackage = () => {
    const canRenderPerPackage = util.showBtnPerPackage()
    if (!canRenderPerPackage) {
      return null
    }

    return (
      <VStack>
        <HStack alignItems='center' justifyContent='center'>
          {RenderBtnCallActionPackage('ต่อแพ็กเกจ', () => this._navToInAppPurchasePerPackageView())}
        </HStack>
      </VStack>
    )
  }

  _renderBtnPackage = () => {
    const { subscription } = this.props
    if (_.isNil(subscription)) {
      return null
    }
    // console.log('_renderBtnPackage => ', subscription.toJS())
    return (
      <VStack>
        <HStack alignItems='center' justifyContent='center'>
          {this._renderBtnBuyPackage()}
          {this._renderBtnPerPackage()}
        </HStack>
      </VStack>
    )
  }

  _renderPackageDetails = () => {
    const { subscription } = this.props
    // const { packageDetails } = this.state
    // console.log('packageDetails => ', packageDetails)
    if (_.isNil(subscription)) {
      return null
    }
    const type = subscription.get('type')
    // console.log('type // => ', type)
    return (
      <VStack mt='2'>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} h='10' alignItems='center' justifyContent='center'>
          <XText bold fontSize='lg'>
            แพ็กเกจของฉัน
          </XText>
        </HStack>
        <VStack p='2'>
          <XUISubcriptionDetail subscription={subscription.toJS()} type={type} />
        </VStack>
      </VStack>
    )
  }

  _renderAddOnListView = () => {
    const { currentPackageBillingCycle } = this.state
    if (!_.isNil(currentPackageBillingCycle)) {
      return currentPackageBillingCycle.map((addOn) => {
        if (addOn.item_type !== 'package_main' && addOn.item_type !== 'package_upgrade') {
          const { title, dataTimeText, countText } = util.getAddOnDetail(addOn)
          return this._renderAddOnView(title, dataTimeText, countText)
        }
        return null
      })
    }

    return null
  }

  _renderAddOnView = (title: string, dataTimeText: string, countText: string) => (
    <VStack key={title}>
      <HStack p='2'>
        <VStack flex={2}>
          <XText>{title}</XText>
          <XText variant='inactive'>{dataTimeText}</XText>
        </VStack>
        <HStack flex={1} flexDirection='flex-end'>
          <XText textAlign='right' color={COLORS.GREEN}>
            {countText}
          </XText>
        </HStack>
      </HStack>
      <HStack borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} />
    </VStack>
  )

  _renderAddOnPackage = () => (
    <VStack mt='2'>
      <XText px='2' variant='active' bold>
        รายละเอียดแพ็กเสริม สำหรับแพ็กเกจปัจจุบัน
      </XText>
      {this._renderAddOnListView()}
    </VStack>
  )

  _renderPackage = () => {
    const {} = this.props
    return (
      <VStack bgColor={COLORS.WHITE}>
        {this._renderMyPackage()}
        {this._renderNowBillingCycle()}
        <HStack borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} />
        {this._renderAddOnPackage()}
        {this._renderBtnPackage()}
        {this._renderPackageDetails()}
      </VStack>
    )
  }

  render() {
    const { profile, subscription } = this.props
    const headerTitle = 'แพ็กเกจปัจจุบัน'

    return (
      <XContainer>
        <XStatusBar backgroundColor='#fff' />
        <XCustomHeader title={headerTitle} headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }} />
        <XContent
          // p='1'
          style={{ backgroundColor: BG_LIGHT_GREY }}>
          {/* {this._renderProfile()} */}
          {this._renderPackage()}
        </XContent>
      </XContainer>
    )
  }
}
