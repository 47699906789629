/* eslint-disable react/no-unused-state */
import React from 'react'
import {
  IAddress,
  IAddressFormViewNavParams,
  IApiOptions,
  IPackageBillingCycle,
  IProfile,
  IProfileMap,
  IQuickAddress,
  IResponseGetSubscriptionsOrder,
  ISelectedStoreMap,
  ISubscriptionPackageList,
  IXSellyFile,
  SubscriptionPackageListItems,
} from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'
import dayjs from 'dayjs'
import * as NavActions from 'x/utils/navigation'
import * as upload from 'x/utils/upload'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'

export interface ISelectedAddOn {
  sku: string
  billing_cycle: number
  qty: number
}

interface IBasePaymentSubscriptionViewProps {
  profile: IProfileMap
  selectedStore: ISelectedStoreMap
  subscription: any
  myStores: any
  fetchSubscription: any
}

interface IBasePaymentSubscriptionViewState {
  subscriptionPackageList: ISubscriptionPackageList
  profile: IProfile
  isLoading: boolean

  selectedOptionRangePackageIndex: number // รายบละเอียด package list จาก หลังบ้าน
  selectedMainPackage: SubscriptionPackageListItems // แพ็กหลักที่เลือก
  addOnPackages: ISelectedAddOn[] // แพ็กเสริมที่เลือก

  uploadedUrl: string
  paymentPostDate: Date

  pendingOrderData: IResponseGetSubscriptionsOrder

  mode: 'upgrade' | 'buy' | 'add_on' | 'continue_package'

  currentPackageBillingCycle: IPackageBillingCycle[]
  nextPackageBillingCycle: IPackageBillingCycle[]

  isvisibleChangeBillingCycle: boolean

  orderCountHistory: { start_date: string; end_date: string; order_count: number }[]

  isOrderCountHistory: boolean

  subscriptionAddressContact: IAddress
  isTaxReceiptRequested: boolean
  isTaxWithheld: boolean
}

export default abstract class BasePaymentSubscriptionView extends React.Component<
  IBasePaymentSubscriptionViewProps,
  IBasePaymentSubscriptionViewState
> {
  inLoopPamyntChackProcess: boolean

  // eslint-disable-next-line react/no-unused-class-component-methods
  // @ts-ignore
  carouselRef: React.useRef<any>

  PACKAGE_SKU_CYCLE_1: string[]

  PACKAGE_SKU_CYCLE_12: string[]

  ADD_ON_COUNTSTANT: {
    title: string
    addOnKey: string
    cutSku: string
  }[]

  constructor(props) {
    super(props)
    this.state = {
      subscriptionPackageList: null,
      profile: null,
      selectedOptionRangePackageIndex: 3,
      selectedMainPackage: null,
      addOnPackages: null,
      uploadedUrl: null,
      paymentPostDate: new Date(),
      pendingOrderData: null,
      isLoading: true,
      mode: 'buy',
      currentPackageBillingCycle: null,
      nextPackageBillingCycle: null,
      isvisibleChangeBillingCycle: false,
      orderCountHistory: null,
      isOrderCountHistory: false,
      subscriptionAddressContact: null,
      isTaxReceiptRequested: false,
      isTaxWithheld: false,
    }

    this.inLoopPamyntChackProcess = false

    this.ADD_ON_COUNTSTANT = [
      {
        title: 'ออเดอร์',
        addOnKey: 'order_count_quota',
        cutSku: 'ADDON-QUOTA-ORDER',
      },
      {
        title: 'ช่องทางขาย',
        addOnKey: 'mkp_quota',
        cutSku: 'ADDON-QUOTA-MKP',
      },
      {
        title: 'กลุ่มสมาชิก',
        addOnKey: 'ug_quota',
        cutSku: 'ADDON-QUOTA-UG',
      },
      {
        title: 'รายการราคา',
        addOnKey: 'pg_quota',
        cutSku: 'ADDON-QUOTA-PG',
      },
      {
        title: 'ผู้ช่วย',
        addOnKey: 'helper_count',
        cutSku: 'ADDON-QUOTA-HELPER',
      },
      {
        title: 'ร้านค้า',
        addOnKey: 'store_count',
        cutSku: 'ADDON-QUOTA-STORE',
      },
      // {
      //   title: 'คลัง',
      //   addOnKey: 'wh_quota',
      //   cutSku: 'ADDON-QUOTA-WH',
      // },
    ]
  }

  async componentDidMount() {
    // console.log('BasePaymentSubscriptionView componentDidMount => ', this.props.subscription.toJS())
    await this._setProfile()
    await this._setCurrentAndNextPackageBillingCycle()
    await this._getSubscriptionPackageList()
    await this._getSubscriptionOrder()
    await this._getSubscriptionAddress()
  }

  _getSubscriptionAddress = async () => {
    const reqBody = {
      is_pending: true,
    }
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const res = await api.postV2('subscription/contact/get', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res.id) {
      const isShowWithheld = this._showWithheld()
      await util.setStatePromise(this, {
        subscriptionAddressContact: res.billing_address,
        isTaxReceiptRequested: true,
        isTaxWithheld: isShowWithheld,
      })
    }
  }

  _setCurrentAndNextPackageBillingCycle = async () => {
    const { subscription } = this.props
    if (_.isNil(subscription)) {
      return null
    }
    const currentPackageBillingCycle = subscription.has('current_package_billing_cycle')
      ? subscription.get('current_package_billing_cycle').toJS()
      : null
    const nextPackageBillingCycle = subscription.has('next_package_billing_cycle')
      ? subscription.get('next_package_billing_cycle').toJS()
      : null
    await util.setStatePromise(this, { currentPackageBillingCycle, nextPackageBillingCycle })
  }

  _getSubscriptionOrder = async () => {
    const { subscriptionPackageList } = this.state
    const reqBody = {
      is_pending: true,
    }
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    // @ts-ignore
    const params = util.getNavParams(this.props)
    const { sku, mode, skipRecommendPackage } = params
    const res = await api.postV2('subscription/order/get', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res?.id) {
      const { items } = res
      const mainPackageSku = items.find((item) => _.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      let seletedMainPackageSku = sku
      if (!_.isNil(mainPackageSku)) {
        seletedMainPackageSku = mainPackageSku.sku
      }
      const mainPackage = subscriptionPackageList.items.find((item) => item.sku === seletedMainPackageSku)
      const addOnPackages = items.filter((item) => !_.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      await util.setStatePromise(this, {
        pendingOrderData: res,
        selectedMainPackage: mainPackage,
        addOnPackages,
        isLoading: false,
        isTaxReceiptRequested: res.is_tax_receipt_requested,
        isTaxWithheld: res.is_tax_withheld,
        subscriptionAddressContact: _.has(res, 'billing_address') ? res.billing_address : null,
      })
      if (res.status === 'pending') {
        await util.delay(5000)
        this.inLoopPamyntChackProcess = true
        await this._loopChackPayment()
      }
    } else {
      // set main package และ add on หากไม่มี pending package
      let selectedMainPackage = null
      let selectedMainPackageSku = sku
      if (mode === 'continue_package' && skipRecommendPackage === false) {
        selectedMainPackageSku = await this._getRecommendPackage()
      }
      const { items } = subscriptionPackageList
      if (!_.isNil(items)) {
        items.forEach((item) => {
          if (item.sku === selectedMainPackageSku) {
            selectedMainPackage = item
          }
        })
      }
      await util.setStatePromise(this, { isLoading: false, selectedMainPackage })
      if (skipRecommendPackage === true) {
        await util.setStatePromise(this, {
          addOnPackages: [],
        })
      }
    }
  }

  _setLoading = () => {
    this.setState({ isLoading: true })
  }

  _unSetLoading = () => {
    this.setState({ isLoading: false })
  }

  // _getRecommendPackage จะยิง api ออกไปเพื่อขอข้อมูลแพ็กเกจจากหลังบ้าน เพื่อนำมาใช้ในกรณีต่อแพ็กเกจอีกรอบ หรือ ซื้อแพ็กเสริม หน้าบ้านจะได้รู้ว่าจะต้องหยิบแพ็กเกจไหนมาให้
  _getRecommendPackage = async () => {
    const { profile } = this.state
    if (_.isNil(profile)) {
      return null
    }
    // @ts-ignore
    const params = util.getNavParams(this.props)

    const { mode } = params
    const reqBody = {
      user_id: profile.user_id,
    }
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const res = await api.postV2('subscription/package/recommend', reqBody, apiOptions)
    // console.log('res // => ', res)
    // กรณี mode ซื้อแพ็กเสริม('add_on') ไม่ต้องเซตแพ็กเสริม เพราะต้องการแค่ sku แพ็กหลัก
    if (mode === 'continue_package') {
      await util.setStatePromise(this, {
        addOnPackages: res.addon_items,
      })
    }

    if (res.main_item) {
      if (res.order_count_history) {
        await util.setStatePromise(this, {
          orderCountHistory: res.order_count_history,
        })
      }
      return res.main_item.sku
    }

    return null
  }

  _getSubscriptionPackageList = async () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    const { subscriptionPackageList, mode } = params
    await util.setStatePromise(this, {
      subscriptionPackageList,
      mode: _.isNil(mode) ? 'buy' : mode,
    })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    const { pendingOrderData } = this.state
    const params = this._getParams()
    const { succeedPaymentCallBack } = params
    if (_.isFunction(succeedPaymentCallBack) && !_.isNil(pendingOrderData)) {
      succeedPaymentCallBack()
    }
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _setProfile = async (): Promise<void> => {
    const { profile } = this.props
    const profileJS = profile?.toJS() || null
    await util.setStatePromise(this, {
      profile: profileJS,
    })
  }

  // _getAddOnDataByCutSku = (cutSku: string) => {
  //   const { subscriptionPackageList, addOnPackages } = this.state
  //   if (_.isNil(addOnPackages)) {
  //     return null
  //   }

  //   let fullSku = null
  //   addOnPackages.forEach((addOn) => {
  //     const { sku } = addOn
  //     const isPrefix = sku.startsWith(cutSku)
  //     if (isPrefix) {
  //       fullSku = sku
  //     }
  //   })

  //   if (_.isNil(fullSku)) {
  //     return null
  //   }

  //   const { items } = subscriptionPackageList
  //   let jsonData = null
  //   items.forEach((item) => {
  //     const { sku } = item
  //     if (sku === fullSku) {
  //       jsonData = item
  //     }
  //   })
  //   return jsonData
  // }

  _getSelectedAddOnDataByCutSku = (cutSku: string) => {
    const { subscriptionPackageList, addOnPackages } = this.state

    // If addOnPackages is null or undefined, return null.
    if (_.isNil(addOnPackages)) {
      return null
    }

    // Use the find method to locate the first add-on package whose SKU starts with cutSku.
    const fullSku = addOnPackages.find((addOn) => addOn.sku.startsWith(cutSku))?.sku

    // If no matching SKU is found, return null.
    if (_.isNil(fullSku)) {
      return null
    }

    // Use the find method to locate the item in subscriptionPackageList with the matching SKU.
    const jsonData = subscriptionPackageList.items.find((item) => item.sku === fullSku) || null

    return jsonData
  }

  // This function retrieves the selected add-on package based on the provided cutSku.
  _getAddOnSelectedData = (cutSku: string) => {
    const { addOnPackages } = this.state

    // If addOnPackages is null or undefined, return null.
    if (_.isNil(addOnPackages)) {
      return null
    }

    // Use the find method to locate the first add-on package whose SKU starts with cutSku.
    // If no matching add-on is found, return null.
    return addOnPackages.find((addOn) => addOn.sku.startsWith(cutSku)) || null
  }

  _getAddOnSelectedDataBySku = (sku: string) => {
    const { subscriptionPackageList } = this.state
    return subscriptionPackageList.items.find((item) => item.sku === sku) || null
  }

  _getStartAndEndBillingCycle = (billingCycle: number) => {
    const { subscriptionPackageList } = this.state
    // console.log('subscriptionPackageList => ', subscriptionPackageList)
    const { future_billing_dates, current_billing_dates } = subscriptionPackageList
    let startBillingDate = current_billing_dates
    // ถ้าแพ็กฟรีมาเลยจะไม่มี current_billing_dates
    if (_.isNil(current_billing_dates) || current_billing_dates.length === 0) {
      startBillingDate = future_billing_dates
    }
    const { start } = startBillingDate[0]
    const { end } = future_billing_dates[billingCycle - 1]
    return `${dayjs(start, 'YYYY-MM-DD').format('D MMM YYYY')} - ${dayjs(end, 'YYYY-MM-DD').format('D MMM YYYY')}`
  }

  _getCurrentSelectedAddOnDataByCutSku = (cutSku: string) => {
    const { subscriptionPackageList, currentPackageBillingCycle } = this.state

    // If addOnPackages is null or undefined, return null.
    if (_.isNil(currentPackageBillingCycle)) {
      return null
    }

    // Use the find method to locate the first add-on package whose SKU starts with cutSku.
    const fullSku = currentPackageBillingCycle.find((addOn) => addOn.sku.startsWith(cutSku))?.sku

    // If no matching SKU is found, return null.
    if (_.isNil(fullSku)) {
      return null
    }

    // Use the find method to locate the item in subscriptionPackageList with the matching SKU.
    const jsonData = subscriptionPackageList.items.find((item) => item.sku === fullSku) || null

    return jsonData
  }

  _callBackFormSelectedAddOn = (addOnSelectedList: ISelectedAddOn[], cutSku: string) => {
    const { addOnPackages } = this.state
    if (_.isNil(addOnPackages) || addOnPackages.length === 0) {
      this.setState({ addOnPackages: addOnSelectedList })
    } else if (_.isNil(addOnSelectedList) || addOnSelectedList.length === 0) {
      const newAddOnPackages = addOnPackages.filter((addOn) => !addOn.sku.startsWith(cutSku))
      this.setState({ addOnPackages: newAddOnPackages })
    } else {
      const oldAddOnPackages = _.cloneDeep(addOnPackages)
      const newAddOnPackages = oldAddOnPackages.filter((addOn) => !addOn.sku.startsWith(cutSku))
      addOnSelectedList.forEach((addOnSelected) => {
        newAddOnPackages.push(addOnSelected)
      })
      this.setState({ addOnPackages: newAddOnPackages })
    }
  }

  // SUBSCRIPTION_UPGRADE_PACKAGE_SKU: [
  //   'BRONZE-TO-SILVER-CYCLE-1',
  //   'BRONZE-TO-GOLD-CYCLE-1',
  //   'BRONZE-TO-PLATINUM-CYCLE-1',

  //   'SILVER-TO-GOLD-CYCLE-1',
  //   'SILVER-TO-PLATINUM-CYCLE-1',

  //   'GOLD-TO-PLATINUM-CYCLE-1',
  // ]

  _getMainPackageByUpgradePackageMode = () => {
    const { selectedMainPackage, subscriptionPackageList } = this.state
    const { sku } = selectedMainPackage
    let shortMainSku = 'PACKAGE-SILVER-CYCLE'
    if (sku.match(/TO-GOLD/)) {
      shortMainSku = 'PACKAGE-GOLD-CYCLE'
    }
    if (sku.match(/TO-PLATINUM/)) {
      shortMainSku = 'PACKAGE-PLATINUM-CYCLE'
    }
    const { items } = subscriptionPackageList
    let selectedAddOn = null
    items.forEach((item) => {
      if (item.sku.includes(shortMainSku)) {
        selectedAddOn = item
      }
    })
    selectedAddOn.billing_cycle = selectedMainPackage.billing_cycle
    return selectedAddOn
  }

  _onPressAddOn = (shortSku: string) => {
    // console.log('shortSku => ', shortSku)
    // @ts-ignore
    const { navigation } = this.props
    const { subscriptionPackageList, selectedMainPackage, mode } = this.state
    const mainPackage = mode !== 'upgrade' ? selectedMainPackage : this._getMainPackageByUpgradePackageMode()
    // console.log('mainPackage => ', mainPackage)
    navigation.dispatch(
      NavActions.navToSelectAddOnOrderView({
        subscriptionPackageList,
        sku: shortSku,
        selectedPackage: mainPackage,
        callBackFromSelectedAddOn: (addOnSelectedList, cutSku) => this._callBackFormSelectedAddOn(addOnSelectedList, cutSku),
        selectedAddOn: null,
        mode: this.state.mode,
        orderCountHistory: shortSku.includes('ADDON-QUOTA-ORDER') ? this.state.orderCountHistory : null,
      })
    )
  }

  _onPressEditAddOn = (shortSku: string) => {
    // console.log('shortSku => ', shortSku)
    // @ts-ignore
    const { navigation } = this.props
    const { subscriptionPackageList, selectedMainPackage } = this.state
    const addOnFromCutSku = this._getAddOnSelectedListData(shortSku)
    navigation.dispatch(
      NavActions.navToSelectAddOnOrderView({
        subscriptionPackageList,
        sku: shortSku,
        selectedPackage: selectedMainPackage,
        callBackFromSelectedAddOn: (addOnSelectedList, cutSku) => this._callBackFormSelectedAddOn(addOnSelectedList, cutSku),
        selectedAddOn: addOnFromCutSku,
        mode: this.state.mode,
        orderCountHistory: shortSku.includes('ADDON-QUOTA-ORDER') ? this.state.orderCountHistory : null,
      })
    )
  }

  _getSelectedAddOn = (sku: string) => {
    if (_.isNil(sku)) {
      return null
    }
    const { subscriptionPackageList } = this.state
    const { items } = subscriptionPackageList
    let selectedAddOn = null
    items.forEach((item) => {
      if (item.sku === sku) {
        selectedAddOn = item
      }
    })
    return selectedAddOn
  }

  _getAddOnSelectedListData = (cutSku: string) => {
    const { addOnPackages } = this.state

    if (_.isNil(addOnPackages)) {
      return null
    }

    const listAddOn = []
    // addOnPackages.find((addOn) => addOn.sku.startsWith(cutSku)) || null
    addOnPackages.forEach((addOn) => {
      const { sku } = addOn
      const isPrefix = sku.startsWith(cutSku)
      if (isPrefix) {
        listAddOn.push(addOn)
      }
    })
    return listAddOn
  }

  _getAddOnCount = (addOn: { title: string; addOnKey: string; cutSku: string }) => {
    const { addOnPackages } = this.state
    let countAddOn = 0
    if (_.isNil(addOnPackages)) {
      return countAddOn
    }
    const addOnFromCutSku = this._getAddOnSelectedListData(addOn.cutSku)
    addOnFromCutSku.forEach((addOnSelected) => {
      const getAddOn = this._getAddOnSelectedDataBySku(addOnSelected.sku)
      const countQty = getAddOn.data_json[addOn.addOnKey] * addOnSelected.qty
      countAddOn += countQty
    })
    return countAddOn
  }

  _onSubmitUpload = async () => {
    this._setLoading()
    // @ts-ignore มันคือ IXSellyFile แต่ไม่ได้ประกาศใน state
    const { selectedFile, paymentPostDate } = this.state
    // console.log('_onTestUploadPress selectedFile', selectedFile)

    if (_.isNil(selectedFile)) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกไฟล์ที่ต้องการอัพโหลด')
      this._unSetLoading()
      return
    }

    const YYMM = dayjs(paymentPostDate).format('YYMM')
    const YYMMDD = dayjs(paymentPostDate).format('YYMMDD')
    const HHMMSS = dayjs(paymentPostDate).format('HHmmss')
    const fileName = `/subs_payment_slip_${YYMMDD}_${HHMMSS}`
    const path = `subs/payment_slip/${YYMM}/${YYMMDD}_${HHMMSS}`
    try {
      // console.log('_onTestUploadPress file', selectedFile)
      const uploadResponse = await upload.uploadFile(selectedFile as IXSellyFile, { fileName, folderPath: path })
      // console.log('_onTestUploadPress uploadResponse', uploadResponse)
      // @ts-ignore
      await util.setStatePromise(this, { uploadedUrl: uploadResponse.url })
      await this._onSubmitPayment()
    } catch (error) {
      // console.log('_onTestUploadPress error', error)
      // p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${error}`)
      await util.setStatePromise(this, { uploadedUrl: null })
      this._unSetLoading()
    }
  }

  _onSubmitUploadDayAddOne = async () => {
    this._setLoading()
    // @ts-ignore มันคือ IXSellyFile แต่ไม่ได้ประกาศใน state
    const { selectedFile, paymentPostDate } = this.state
    // console.log('_onTestUploadPress selectedFile', selectedFile)

    if (_.isNil(selectedFile)) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกไฟล์ที่ต้องการอัพโหลด')
      this._unSetLoading()
      return
    }

    const YYMM = dayjs(paymentPostDate).format('YYMM')
    const YYMMDD = dayjs(paymentPostDate).format('YYMMDD')
    const HHMMSS = dayjs(paymentPostDate).format('HHmmss')
    const fileName = `/subs_payment_slip_${YYMMDD}_${HHMMSS}`
    const path = `subs/payment_slip/${YYMM}/${YYMMDD}_${HHMMSS}`
    try {
      // console.log('_onTestUploadPress file', selectedFile)
      const uploadResponse = await upload.uploadFile(selectedFile as IXSellyFile, { fileName, folderPath: path })
      // console.log('_onTestUploadPress uploadResponse', uploadResponse)
      // @ts-ignore
      await util.setStatePromise(this, { uploadedUrl: uploadResponse.url })
      await this._onSubmitPaymentDayAddOne()
    } catch (error) {
      // console.log('_onTestUploadPress error', error)
      // p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${error}`)
      await util.setStatePromise(this, { uploadedUrl: null })
      this._unSetLoading()
    }
  }

  _getSumTotalPayment = () => {
    const { selectedMainPackage, addOnPackages, mode } = this.state
    if (_.isNil(selectedMainPackage)) {
      return 0
    }

    const { price_with_vat } = selectedMainPackage
    let price = 0
    if (mode !== 'add_on') {
      price += price_with_vat
    }
    if (!_.isNil(addOnPackages)) {
      addOnPackages.forEach((addOn) => {
        const { billing_cycle, qty, sku } = addOn
        const selectedPackage = this._getAddOnSelectedDataBySku(sku)
        price += selectedPackage.price_with_vat * qty * billing_cycle
      })
    }
    return price
  }

  _getSumTotalPriceBeforeVat = () => {
    const { selectedMainPackage, addOnPackages, mode } = this.state
    if (_.isNil(selectedMainPackage)) {
      return 0
    }

    const { price_before_vat } = selectedMainPackage
    let price = 0
    if (mode !== 'add_on') {
      price += price_before_vat
    }
    if (!_.isNil(addOnPackages)) {
      addOnPackages.forEach((addOn) => {
        const { billing_cycle, qty, sku } = addOn
        const selectedPackage = this._getAddOnSelectedDataBySku(sku)
        price += selectedPackage.price_before_vat * qty * billing_cycle
      })
    }
    return price
  }

  _getSumTotalVat = () => {
    const { selectedMainPackage, addOnPackages, mode } = this.state
    if (_.isNil(selectedMainPackage)) {
      return 0
    }

    const { vat } = selectedMainPackage
    let price = 0
    if (mode !== 'add_on') {
      price += vat
    }
    if (!_.isNil(addOnPackages)) {
      addOnPackages.forEach((addOn) => {
        const { billing_cycle, qty, sku } = addOn
        const selectedPackage = this._getAddOnSelectedDataBySku(sku)
        price += selectedPackage.vat * qty * billing_cycle
      })
    }
    return price
  }

  _getSumTotalWhTex = () => {
    const { selectedMainPackage, addOnPackages, mode } = this.state
    if (_.isNil(selectedMainPackage)) {
      return 0
    }

    const { wh_tax } = selectedMainPackage
    let price = 0
    if (mode !== 'add_on') {
      price += wh_tax
    }
    if (!_.isNil(addOnPackages)) {
      addOnPackages.forEach((addOn) => {
        const { billing_cycle, qty, sku } = addOn
        const selectedPackage = this._getAddOnSelectedDataBySku(sku)
        price += selectedPackage.wh_tax * qty * billing_cycle
      })
    }
    return price
  }

  _showWithheld = () => {
    const price = this._getSumTotalPayment()
    return price >= 1000
  }

  _onChangePaymentPostDate = (newDate: Date) => {
    this.setState({ paymentPostDate: newDate })
  }

  _getParams = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    return params
  }

  _onChangeBillingCycle = (month: number) => {
    const { selectedMainPackage, subscriptionPackageList, addOnPackages } = this.state
    const mainPackageSku = selectedMainPackage.sku
    const cleanedSku = mainPackageSku.replace(/\d+/g, '') // Remove numbers from sku
    const sku = cleanedSku + month
    let selectedNewMainPackage = null
    const { items } = subscriptionPackageList
    if (!_.isNil(items)) {
      items.forEach((item) => {
        if (item.sku === sku) {
          selectedNewMainPackage = item
        }
      })
    }
    const newAddOnPackages = []
    if (!_.isNil(addOnPackages) && addOnPackages.length > 0) {
      addOnPackages.forEach((addOn) => {
        const newAddon: ISelectedAddOn = { sku: '', billing_cycle: 0, qty: 0 }
        newAddon.sku = addOn.sku
        newAddon.qty = addOn.qty
        newAddon.billing_cycle = month
        newAddOnPackages.push(newAddon)
      })
    }
    this.setState({ selectedMainPackage: selectedNewMainPackage, addOnPackages: newAddOnPackages })
  }

  _getDifferenceFromMainPackageAndNowPackage = (key: string) => {
    const { subscription } = this.props
    const { selectedMainPackage } = this.state
    const { data_json } = selectedMainPackage
    const value = !_.isNil(data_json) ? data_json[key] : 0
    const orderCountQuota = subscription.get(key)
    const diff = orderCountQuota - value
    return diff
  }

  _getMainPackageStarDateEndDateText = () => {
    const { currentPackageBillingCycle } = this.state
    // console.log('currentPackageBillingCycle => ', currentPackageBillingCycle)
    const endDate = () => {
      const { subscription } = this.props
      const endPackage = dayjs(subscription.get('m_e_date'), 'YYYY-MM-DD').clone().format('D MMM YYYY')
      return `ถึง ${endPackage}`
    }
    if (_.isNil(currentPackageBillingCycle)) {
      return endDate()
    }
    let mainPackage = null
    currentPackageBillingCycle.forEach((item) => {
      if (item.item_type === 'package_main') {
        mainPackage = item
      }
    })
    if (_.isNil(mainPackage)) {
      return endDate()
    }
    const { start_date, end_date } = mainPackage
    const startPackage = dayjs(start_date, 'YYYY-MM-DD').clone().format('D MMM YYYY')
    const endDataPackage = dayjs(end_date, 'YYYY-MM-DD').clone().format('D MMM YYYY')
    return `${startPackage} - ${endDataPackage}`
  }

  _onSaveQuickAddress = async (newAddr: IQuickAddress) => {
    // console.log('newAddr => ', newAddr)
    this.goBack()
    const { subscriptionAddressContact } = this.state
    const apiMode = _.isNil(subscriptionAddressContact) ? 'create' : 'update'
    // return
    const reqBody = {
      name: newAddr.name,
      // @ts-ignore
      address1: newAddr.address1,
      address2: newAddr.address2,
      subdistrict: newAddr.sub_district,
      district: newAddr.district,
      province: newAddr.province,
      postal_code: newAddr.postal_code,
      telephone: newAddr.telephone,
      legal_entity_type: `${newAddr.legal_entity_type}`,
      legal_entity_id: _.has(newAddr, 'legal_entity_id') && !_.isNil(newAddr.legal_entity_id) ? `${newAddr.legal_entity_id}` : '',
      // branch_number: newAddr.branch_number,
      email: newAddr.email,
    }
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    if (apiMode === 'update') {
      // @ts-ignore
      reqBody.id = subscriptionAddressContact.id
    }
    if (_.has(newAddr, 'note')) {
      // @ts-ignore
      reqBody.note = newAddr.note
    }
    const res = await api.postV2(`subscription/address/${apiMode}`, reqBody, apiOptions)
    // console.log('res => ', res)
    if (res.id) {
      await util.setStatePromise(this, { subscriptionAddressContact: res, isTaxReceiptRequested: true })
    }
  }

  _navToEditAddressView = async () => {
    // @ts-ignore
    const { navigation } = this.props
    const { subscriptionAddressContact, pendingOrderData } = this.state
    if (!_.isNil(pendingOrderData)) {
      return
    }
    const _onSubmit = async (newAddr: IQuickAddress) => {
      const isSuccess = await this._onSaveQuickAddress(newAddr)
      return { isGoBack: isSuccess }
    }

    const initAddress: any = {}

    if (!_.isNil(subscriptionAddressContact)) {
      initAddress.name = subscriptionAddressContact.name
      initAddress.address1 = subscriptionAddressContact.address1
      initAddress.address2 = subscriptionAddressContact.address2
      initAddress.sub_district = subscriptionAddressContact.sub_district
      initAddress.district = subscriptionAddressContact.district
      initAddress.province = subscriptionAddressContact.province
      initAddress.postal_code = subscriptionAddressContact.postal_code
      initAddress.telephone = subscriptionAddressContact.telephone
      initAddress.legal_entity_type = subscriptionAddressContact.legal_entity_type
      initAddress.legal_entity_id = subscriptionAddressContact.legal_entity_id
      initAddress.email = subscriptionAddressContact.email
      initAddress.note = subscriptionAddressContact.note
      initAddress.id = subscriptionAddressContact.id
    }

    const navParams: IAddressFormViewNavParams = {
      initAddress,
      submitButtonText: 'บันทึก',
      headerTitle: 'แก้ไขที่อยู่ใบกํากับภาษีx',
      // @ts-ignore
      onSubmit: _onSubmit,
    }

    navigation.dispatch(NavActions.navToAddressFormView(navParams))
  }

  _onSubmitPayment = async () => {
    const {
      addOnPackages,
      selectedMainPackage,
      profile,
      paymentPostDate,
      uploadedUrl,
      subscriptionPackageList,
      mode,
      isTaxReceiptRequested,
      isTaxWithheld,
      subscriptionAddressContact,
    } = this.state
    if (_.isNil(uploadedUrl)) {
      p.op.showConfirmationOkOnly('', 'อัพโหลดหลักฐานการชำระเงินไม่สำเร็จ')
      this._unSetLoading()
      return
    }
    if (isTaxReceiptRequested && _.isNil(subscriptionAddressContact)) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุที่อยู่ใบกํากับภาษี')
      this._unSetLoading()
      return
    }
    const price = this._getSumTotalPayment()
    const whTex = this._getSumTotalWhTex()
    const sumTotalPrice = isTaxWithheld ? price - whTex : price
    const items = _.isNil(addOnPackages) || addOnPackages.length === 0 ? [] : addOnPackages
    let mainPackage = null
    if (!_.isNil(selectedMainPackage)) {
      mainPackage = {
        sku: selectedMainPackage.sku,
        billing_cycle: selectedMainPackage.billing_cycle,
        qty: 1,
      }
    }
    // console.log('mainPackage => ', mainPackage)
    if (mode !== 'add_on') {
      items.push(mainPackage)
    }

    const paidAt = dayjs(paymentPostDate).format('YYYY-MM-DD HH:mm:ss')
    // console.log('profile => ', profile)
    // return
    const reqBody = {
      user_id: profile.user_id,
      is_tax_withheld: isTaxWithheld,
      is_vat: true,
      is_tax_receipt_requested: isTaxReceiptRequested,
      total_amount: sumTotalPrice,
      payment_method_id: 1,
      // payment_channel_id: 1,
      added_day: 0,
      payment: {
        paid_at: paidAt,
        ref_info: uploadedUrl,
      },
      items,
    }

    if (!_.isNil(subscriptionAddressContact)) {
      // @ts-ignore
      reqBody.billing_address_id = subscriptionAddressContact.id
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res: IResponseGetSubscriptionsOrder = await api.postV2('subscription/order/create', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res?.id) {
      // p.op.showConfirmationOkOnly(
      //   'อัพโหลดหลักฐานการชำระเรียบร้อย',
      //   `กรุณารอระบบทำการตรวจสอบหลักฐานการชำระเงินภายใน 30 นาที ระบบจะอัพเดทแพ็กเกจของคุณให้อัตโนมัติเมื่อผ่านการตรวจสอบ`
      // )
      p.op.showToast('อัพโหลดหลักฐานการชำระเรียบร้อย', 'success')
      const mainPackageSku = res.items.find((item) => _.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      // console.log('mainPackageSku => ', mainPackageSku)
      const newMainPackage = _.isNil(mainPackageSku)
        ? this.state.selectedMainPackage
        : subscriptionPackageList.items.find((item) => item.sku === mainPackageSku.sku)

      const newAddOnPackages = res.items.filter((item) => !_.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      await util.setStatePromise(this, {
        pendingOrderData: res,
        selectedMainPackage: newMainPackage,
        addOnPackages: newAddOnPackages,
        subscriptionAddressContact: _.has(res, 'billing_address') ? res.billing_address : null,
      })
    }
    this._unSetLoading()
    await util.delay(3000)
    this.inLoopPamyntChackProcess = true
    await this._loopChackPayment()
  }

  _onSubmitPaymentDayAddOne = async () => {
    const { addOnPackages, selectedMainPackage, profile, paymentPostDate, uploadedUrl, subscriptionPackageList, mode } = this.state
    if (_.isNil(uploadedUrl)) {
      p.op.showConfirmationOkOnly('', 'อัพโหลดหลักฐานการชำระเงินไม่สำเร็จ')
      this._unSetLoading()
      return
    }
    const price = this._getSumTotalPayment()
    const items = _.isNil(addOnPackages) || addOnPackages.length === 0 ? [] : addOnPackages
    let mainPackage = null
    if (!_.isNil(selectedMainPackage)) {
      mainPackage = {
        sku: selectedMainPackage.sku,
        billing_cycle: selectedMainPackage.billing_cycle,
        qty: 1,
      }
    }
    // console.log('mainPackage => ', mainPackage)
    if (mode !== 'add_on') {
      items.push(mainPackage)
    }
    const paidAt = dayjs(paymentPostDate).format('YYYY-MM-DD HH:mm:ss')
    // console.log('profile => ', profile)
    // return
    const reqBody = {
      user_id: profile.user_id,
      is_tax_withheld: false,
      is_vat: true,
      is_tax_receipt_requested: false,
      total_amount: price,
      payment_method_id: 1,
      // payment_channel_id: 1,
      added_day: 1,
      payment: {
        paid_at: paidAt,
        ref_info: uploadedUrl,
      },
      items,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res: IResponseGetSubscriptionsOrder = await api.postV2('subscription/order/create', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res?.id) {
      // p.op.showConfirmationOkOnly(
      //   'อัพโหลดหลักฐานการชำระเรียบร้อย',
      //   `กรุณารอระบบทำการตรวจสอบหลักฐานการชำระเงินภายใน 30 นาที ระบบจะอัพเดทแพ็กเกจของคุณให้อัตโนมัติเมื่อผ่านการตรวจสอบ`
      // )
      p.op.showToast('อัพโหลดหลักฐานการชำระเรียบร้อย', 'success')
      const mainPackageSku = res.items.find((item) => _.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      // console.log('mainPackageSku => ', mainPackageSku)
      const newMainPackage = _.isNil(mainPackageSku)
        ? this.state.selectedMainPackage
        : subscriptionPackageList.items.find((item) => item.sku === mainPackageSku.sku)

      const newAddOnPackages = res.items.filter((item) => !_.includes(CONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku))
      await util.setStatePromise(this, { pendingOrderData: res, selectedMainPackage: newMainPackage, addOnPackages: newAddOnPackages })
    }
    this._unSetLoading()
    await util.delay(3000)
    this.inLoopPamyntChackProcess = true
    await this._loopChackPayment()
  }

  _loopChackPayment = async () => {
    const { pendingOrderData } = this.state
    if (_.isNil(pendingOrderData)) {
      return
    }
    const reqBody = {
      order_id: pendingOrderData.id,
    }
    const res: IResponseGetSubscriptionsOrder = await api.postV2('subscription/order/get', reqBody)
    // console.log('_loopChackPayment res => ', res)
    if (res?.status === 'completed') {
      this.props.fetchSubscription()
      const params = this._getParams()
      const { succeedPaymentCallBack } = params
      if (_.isFunction(succeedPaymentCallBack)) {
        succeedPaymentCallBack()
      }
      p.op.showConfirmationOkOnly('', 'ระบบได้ทำการตรวจสอบการชำระเงินเรียบร้อยแล้ว', () => {
        this.goBack()
      })
      await util.setStatePromise(this, { pendingOrderData: res })
      this.inLoopPamyntChackProcess = false
      return
    }

    if (res?.status === 'cancelled') {
      this.props.fetchSubscription()
      p.op.showConfirmationOkOnly('', 'ระบบได้ปฎิเสธการชำระเงิน', () => {
        this.inLoopPamyntChackProcess = false
        this.goBack()
      })
    }

    await util.delay(5000)
    if (this.inLoopPamyntChackProcess) {
      await this._loopChackPayment()
    }
  }

  componentWillUnmount() {
    this.inLoopPamyntChackProcess = false
  }
}
