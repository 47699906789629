import React, { useEffect, useState } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import _ from 'lodash'
import XOverlay from 'xui/components/XOverlay'
import XIcon from 'xui/components/XIcon'
import * as util from 'x/utils/util'
import xCONS from 'x/config/constants'
import settings from 'x/config/settings'
import { IProductDetailMap, ISelectedStoreMap } from 'x/index'
import { STYLES, COLORS, S } from 'x/config/styles'
import { IPrintingBarcodeStoreSetting } from 'x/modules/xshipping/BaseStoreSettingPrintingBarcode'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'
import XText from '../XText'
import VStack from '../VStack'
import Box from '../Box'
import HStack from '../HStack'
import Segment from '../Segment'

interface IPrintProductBarcodeProps {
  navigation: any
  visible: boolean
  onRequestClose: () => void
  downloadPrintProductBarcode: (vutl: string, fileName: string) => void
  selectedProduct: IProductDetailMap
  selectedStore: ISelectedStoreMap
  // ส่ง pt_id หรือ pp_id มาแค่อย่างใดอย่างหนึ่ง
  pt_id?: number
  pp_id?: number
}

const overlayDefaultHeight = 140
const pgHeight = 130

const PrintProductBarcode: React.FC<IPrintProductBarcodeProps> = function ({
  navigation,
  visible,
  downloadPrintProductBarcode,
  onRequestClose,
  selectedProduct,
  selectedStore,
  pp_id,
  pt_id,
}) {
  // const [isReportHistory, setIsReportHistory] = useState(null)
  const pg_ids = selectedProduct.has('product_group_ids') ? selectedProduct.get('product_group_ids').toJS() : []
  const product_groups = selectedStore.has('product_groups') ? selectedStore.get('product_groups').toJS() : []
  // console.log('pg_ids => ', pg_ids)
  // console.log('selectedStore => ', selectedStore.toJS())
  const [showPriceIndex, setShowPrice] = useState(0)
  const [selectedVariantIndex, setIsSelectedVariantIndex] = useState(0)
  const [selectedVariantData, setSelectedVariantData] = useState(pg_ids.length > 0 ? pg_ids[0] : null)
  const [overlayHeight, setOverlayHeight] = useState(overlayDefaultHeight)
  const [isSkuOrUpc, setSkuUpcIndex] = useState(0)

  const [uesSku, setUseSku] = useState(false)
  const [uesUpc, setUseUpc] = useState(false)

  useEffect(() => {
    async function fetchData() {
      // console.log('selectedProduct => ', selectedProduct.toJS())

      const sku = selectedProduct.get('variants').some((variant) => !_.isNil(variant.get('sku')))
      const upc = selectedProduct.get('variants').some((variant) => !_.isNil(variant.get('upc')))
      // console.log('uesSku => ', sku)
      // console.log('uesUpc => ', upc)
      setUseSku(sku)
      setUseUpc(upc)

      let showPriceIndexFromStorage = 0
      let skuupcIndexFromStorage = 0
      let selectedVariantIndexFromStorage = 0
      let overlayHeightFormInit = overlayDefaultHeight
      await p.op.storageGet(xCONS.STORAGE_KEYS.PRINT_SKU_AND_UPC_BARCODE).then((val: any) => {
        // console.log('val => ', val)
        if (!_.isNil(val)) {
          showPriceIndexFromStorage = val.showPriceIndex
          skuupcIndexFromStorage = sku && upc ? val.skuupc : 0
          pg_ids.forEach((pg_id, index) => {
            if (pg_id === val.pg_id) {
              selectedVariantIndexFromStorage = index
            }
          })
          setShowPrice(showPriceIndexFromStorage)
          setSkuUpcIndex(skuupcIndexFromStorage)
          setIsSelectedVariantIndex(selectedVariantIndexFromStorage)
          if (showPriceIndexFromStorage === 1 && pg_ids.length > 1) {
            overlayHeightFormInit += pgHeight
          }
        }
      })
      if (sku && upc) {
        overlayHeightFormInit += 90
        setOverlayHeight(overlayHeightFormInit)
      }
    }
    fetchData()
  }, [selectedProduct])

  // let overlayHeight = 180

  // if (!_.isNil(variants) && variants.size > 1) {
  //   overlayHeight += pgHeight
  // }

  const onSegmentChange = (index) => {
    const useSkuAndUpc = uesSku && uesUpc
    const addMoreView = useSkuAndUpc ? 90 : 30
    if (index === 0) {
      setOverlayHeight(overlayDefaultHeight + (useSkuAndUpc ? 60 : 0))
    } else if (index === 1 && pg_ids.length > 1) {
      setOverlayHeight(overlayDefaultHeight + pgHeight + addMoreView)
    } else {
      setOverlayHeight(overlayHeight)
    }
    setShowPrice(index)
  }

  const onSegmentSkuUpcChange = (index) => {
    setSkuUpcIndex(index)
  }

  const onPressSelectedVariantData = (index, variantData) => {
    setIsSelectedVariantIndex(index)
    setSelectedVariantData(showPriceIndex === 0 ? null : variantData)
    // downloadHistoryReport(v)
  }

  const _getPgData = (pg_id: number) => {
    let pgData = null
    product_groups.forEach((pg) => {
      if (pg.id === pg_id) {
        pgData = pg
      }
    })
    return pgData
  }

  const navToStoreSettingPDFStickerShippingLabel = async () => {
    onRequestClose()
    await util.delay(300)
    navigation.dispatch(NavActions.navToStoreSettingPrintingBarcode)
  }

  const navToStoreSettingStoreProductSN = () => {
    navigation.dispatch(NavActions.navToStoreSettingStoreProductSN)
  }

  const onPressDownload = async () => {
    onRequestClose()
    await util.delay(300)
    const printingBarcode: IPrintingBarcodeStoreSetting = selectedStore.has('printing_barcode')
      ? selectedStore.get('printing_barcode').toJS()
      : xCONS.DEFAULT_BARCODE
    const pref = printingBarcode.p
    const barcode_type = selectedStore.get('s_use_product_barcode')
    // console.log('printingBarcode => ', printingBarcode)
    // console.log('pref => ', pref)
    if (pref.c === 0 && pref.d === 0 && pref.v === 0) {
      _downloadSKUAndUPC(null, selectedProduct.get('id'))
      return
    }
    if (!uesSku && !uesUpc) {
      onRequestClose()
      await util.delay(300)
      p.op.showConfirmationOkOnly('', 'สินค้าตัวนี้ไม่มีรหัสสินค้า SKU หรือ UPC')
      return
    }
    if (barcode_type === 0) {
      onRequestClose()
      await util.delay(300)
      p.op.showConfirmation('ท่านไม่เปิดใช้งาน SKU หรือ UPC', 'ท่านต้องการเปิดใช้งาน SKU หรือ UPC ตอนนี้หรือไม่ ?', () =>
        navToStoreSettingStoreProductSN()
      )
      return
    }
    // console.log('isSkuOrUpc ** => ', isSkuOrUpc)
    // 1 = UPC // 2 = SKU
    if (uesSku && uesUpc) {
      // console.log('uesSku && uesUpc')
      _downloadSKUAndUPC(isSkuOrUpc === 0 ? 2 : 1, selectedProduct.get('id'))
    } else if (uesSku && !uesUpc) {
      _downloadSKUAndUPC(2, selectedProduct.get('id'))
    } else if (uesUpc && !uesSku) {
      _downloadSKUAndUPC(1, selectedProduct.get('id'))
    }
  }

  const _downloadSKUAndUPC = async (barcode_type: number, product_id: number) => {
    // const { selectedStore } = this.props
    const newDate = new Date()
    const day = newDate.getDate()
    const month = newDate.getMonth() + 1
    const year = newDate.getFullYear()
    const hours = newDate.getHours()
    const minute = newDate.getMinutes()
    const seconde = newDate.getSeconds()
    const store_id = selectedStore.get('id')
    // const print_template_id = 2

    const rootAPI = settings.app.GO_API_ROOT
    let url = `${rootAPI}/product/print_label?store_id=${store_id}`
    if (showPriceIndex === 1) {
      url += `&product_group_id=${pg_ids[selectedVariantIndex]}`
    }
    if (!_.isNil(pt_id)) {
      url += `&product_template_id=${pt_id}`
    }
    if (!_.isNil(pp_id)) {
      url += `&product_product_id=${pp_id}`
    }
    if (!_.isNil(barcode_type)) {
      url += `&barcode_type=${barcode_type}`
    }
    // console.log('url => ', url)
    const fileName = `${day}_${month}_${year}__${hours}_${minute}_${seconde}.pdf`
    if (_.isNil(store_id) || _.isNil(product_id)) {
      return
    }
    const saveLastSelection = {
      showPriceIndex,
      pg_id: pg_ids[selectedVariantIndex],
      skuupc: isSkuOrUpc,
    }
    await p.op.storageSet(xCONS.STORAGE_KEYS.PRINT_SKU_AND_UPC_BARCODE, saveLastSelection)
    // await this.openPDFFromUrl(url, fileName)
    onRequestClose()
    await util.delay(300)
    downloadPrintProductBarcode(url, fileName)
  }

  // console.log('PrintProductBarcode => ', variants.get(0).toJS())
  // console.log('selectedProduct => ', selectedProduct.toJS())
  return (
    <XOverlay
      contentStyle={{ padding: 8, width: 315, height: 'auto', alignSelf: 'center', flexBasis: 'auto' }}
      visible={visible}
      // numColumns={1}
      // children={_renderChildren()}
      onRequestClose={() => (_.isFunction(onRequestClose) ? onRequestClose() : null)}>
      <VStack w='full'>
        {/* HREADER */}
        <HStack w='full' h='35px' alignItems='center'>
          <Box flex={1}>
            <XText variant='active' bold>
              พิมพ์ฉลากสินค้า(Barcode / QR)
            </XText>
          </Box>
          <TouchableOpacity
            onPress={() => {
              navToStoreSettingPDFStickerShippingLabel()
            }}>
            <XIcon name='settings-sharp' family='Ionicons' style={{ color: COLORS.APP_MAIN }} />
          </TouchableOpacity>
        </HStack>

        {/* BODY */}
        <VStack w='full'>
          <XText variant='active' bold>
            แสดงราคา
          </XText>
          <Segment selectedIndex={showPriceIndex} options={['ไม่แสดง', 'แสดง']} onSegmentChange={(newIndex) => onSegmentChange(newIndex)} />
        </VStack>
        {/* เลือก 1 ตัวเลือกสินค้าที่ต้องการ */}
        {showPriceIndex === 0 || pg_ids.length < 2 ? null : (
          <Box w='full' mt='2'>
            <XText variant='active'>เลือก 1 ราคาที่ต้องการแสดง</XText>
            <Box style={[S.BORDER, { height: pgHeight }]}>
              <ScrollView>
                {pg_ids.map((pgid: any, index: number) => {
                  const data = _getPgData(pgid)
                  if (_.isNil(data)) {
                    return null
                  }
                  const checker = selectedVariantIndex === index
                  // if (_.isNil(selectedVariant) && index === 0) {
                  //   checker = true
                  //   // seletedRadioBtn(data)
                  // } else if (!_.isNil(selectedVariant) && selectedVariant.get('name') === data.get('name')) {
                  //   checker = true
                  // }
                  // console.log('NAME => ', data.get('name'))
                  return (
                    <TouchableOpacity
                      style={{ height: 25, paddingLeft: 4, paddingTop: 2 }}
                      key={data.name}
                      onPress={() => onPressSelectedVariantData(index, data)}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <XIcon
                          name={checker ? 'radio-button-on' : 'radio-button-off'}
                          family='Ionicons'
                          style={{ flex: 0, minWidth: 30 }}
                        />
                        <XText
                          variant='inactive'
                          pl='1'
                          alignItems='center'
                          justifyContent='center'
                          numberOfLines={1}>{`${data.name}`}</XText>
                      </View>
                    </TouchableOpacity>
                  )
                })}
              </ScrollView>
            </Box>
          </Box>
        )}
        {/* SKU UPC */}
        {uesSku && uesUpc ? (
          <VStack w='full' mt='2'>
            <XText variant='active' bold>
              เลือกตัวเลือกที่ท่านต้องการ...
            </XText>
            <Segment selectedIndex={isSkuOrUpc} options={['SKU', 'UPC']} onSegmentChange={(newIndex) => onSegmentSkuUpcChange(newIndex)} />
          </VStack>
        ) : null}

        {/* FOOTER */}
        <HStack w='full' mt='2'>
          <TouchableOpacity
            style={{
              flex: 1,
              borderColor: COLORS.APP_MAIN,
              backgroundColor: COLORS.APP_MAIN,
              borderRadius: 7,
              alignSelf: 'center',
              marginRight: 4,
              justifyContent: 'center',
              height: 30,
              borderWidth: 1,
              flexDirection: 'row',
            }}
            onPress={() => {
              onPressDownload()
            }}>
            <XIcon
              family='AntDesign'
              name='pdffile1'
              style={{
                fontSize: STYLES.FONT_ICON_SMALLER,
                color: COLORS.WHITE,
                flex: 0,
                minWidth: 30,
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                alignSelf: 'center',
              }}
            />
            <XText alignItems='center' justifyContent='center' alignSelf='center' color={COLORS.WHITE}>
              ดาวน์โหลด
            </XText>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: 40,
              borderColor: COLORS.APP_MAIN,
              borderRadius: 7,
              height: 30,
              justifyContent: 'center',
              alignSelf: 'center',
              // borderWidth: 1,
              borderRightWidth: 1,
              borderBottomWidth: 1,
              borderLeftWidth: 1,
              borderTopWidth: 1,
            }}
            onPress={() => onRequestClose()}>
            <XText alignItems='center' justifyContent='center' alignSelf='center' color={COLORS.APP_MAIN}>
              ปิด
            </XText>
          </TouchableOpacity>
        </HStack>
      </VStack>
    </XOverlay>
  )
}

export default PrintProductBarcode

// const stylesReport = StyleSheet.create({
//   box: {
//     height: 50,
//     borderWidth: 1,
//     borderColor: COLORS.TEXT_ACTIVE,
//   },
// })
