import React from 'react'
import { LayoutChangeEvent, LayoutRectangle, NativeScrollEvent, ScrollView, NativeSyntheticEvent, TouchableOpacity } from 'react-native'
import { IScrollViewProps } from 'native-base'
import { diff } from 'deep-object-diff'
import _ from 'lodash'
// import p from 'x/config/platform-specific'
import HStack from './HStack'
import XScrollView from './XScrollView'
import XIcon from './XIcon'
import Box from './Box'

// import * as xFmt from 'x/utils/formatter'
// import p from 'x/config/platform-specific'
// import * as xUtil from 'x/utils/util'
// import CONS from 'x/config/constants'

// const XHorizontalScrollView: React.FC<IScrollViewProps> = ({ children, ...restProps }) => {
//   const localRef = React.useRef<ScrollView>()

//   const [isInitialized, setIsInitialized] = React.useState<boolean>(false)
//   const [maxContentWidth, setMaxContentWidth] = React.useState<number>(0)
//   const [sLayout, setScrollLayout] = React.useState<LayoutRectangle>({ height: 0, width: 0, x: 0, y: 0 })
//   const [lastScrollX, setLastScrollX] = React.useState<number>(0)

//   const [controlsVisibility, setControlsVisibility] = React.useState<{ prev: boolean; next: boolean }>({ prev: false, next: false })
//   // const [isPrevButtonVisible, setIsPrevButtonVisible] = React.useState<boolean>(false)
//   // const [isNextButtonVisible, setIsNextButtonVisible] = React.useState<boolean>(false)

//   const _checkControlButtonsVisibility = React.useCallback(() => {
//     // console.log('_checkControlButtonsVisibility triggerd maxContentWidth => ', maxContentWidth)
//     // console.log('_checkControlButtonsVisibility triggerd sLayout => ', sLayout)
//     if (maxContentWidth && maxContentWidth > 0 && sLayout && sLayout.width > 0) {
//       const acceptableOverflowWidth = 8
//       const hasOverflowContent = maxContentWidth > sLayout.width
//       const maxScrollOffset = maxContentWidth - sLayout.width - acceptableOverflowWidth
//       const isAvailableOffset = maxScrollOffset >= 12
//       const shouldVisiblePrevButton = hasOverflowContent && isAvailableOffset && (lastScrollX > 0 || lastScrollX === maxScrollOffset)
//       const shouldVisibleNextButton = hasOverflowContent && isAvailableOffset && (lastScrollX === 0 || lastScrollX < maxScrollOffset)
//       // console.log('_checkControlButtonsVisibility maxScrollOffset => ', maxScrollOffset)
//       // console.log('_checkControlButtonsVisibility lastScrollX => ', lastScrollX)
//       // console.log('_checkControlButtonsVisibility isAvailableOffset => ', isAvailableOffset)
//       // console.log('_checkControlButtonsVisibility shouldVisiblePrevButton => ', shouldVisiblePrevButton)
//       // console.log('_checkControlButtonsVisibility shouldVisibleNextButton => ', shouldVisibleNextButton)
//       // setIsPrevButtonVisible(shouldVisiblePrevButton)
//       // setIsNextButtonVisible(shouldVisibleNextButton)
//       setControlsVisibility({ prev: shouldVisiblePrevButton, next: shouldVisibleNextButton })
//     }
//   }, [maxContentWidth, sLayout, lastScrollX])

//   React.useEffect(() => {
//     setTimeout(() => {
//       setIsInitialized(true)
//     }, 200)
//   }, [])

//   React.useEffect(() => {
//     if (isInitialized) {
//       _checkControlButtonsVisibility()
//     }
//   }, [isInitialized, maxContentWidth, sLayout, lastScrollX])

//   const _onLayout = (evt: LayoutChangeEvent) => {
//     const newLayout = evt.nativeEvent.layout
//     setScrollLayout(newLayout)

//     // console.log('_onLayout newLayout => ', newLayout)
//     // setTimeout(() => _checkControlButtonsVisibility(), 500)
//   }

//   const _onContentSizeChange = (w: number, h: number): void => {
//     // if (width !== maxContentWidth) {
//     setMaxContentWidth(w)
//     // console.log('_onContentSizeChange w => ', w)
//     // }
//     // if (!isInitialized) {
//     //   setIsInitialized(true)
//     // }
//     // setTimeout(() => _checkControlButtonsVisibility(), 500)
//   }

//   const _onScroll = (evt: NativeSyntheticEvent<NativeScrollEvent>) => {
//     const latestScrollX = evt.nativeEvent.contentOffset.x
//     setLastScrollX(latestScrollX)
//     // console.log('_onContentSizeChange latestScrollX => ', latestScrollX)

//     // setTimeout(_checkControlButtonsVisibility, 100)
//   }

//   const _getJumpSize = () => Math.floor(sLayout.width * 0.7)

//   const _onPressPrev = () => {
//     const newX = lastScrollX - _getJumpSize()
//     localRef.current.scrollTo({ x: newX > 0 ? newX : 0, animated: true })
//   }

//   const _onPressNext = () => {
//     const newX = lastScrollX + _getJumpSize()
//     localRef.current.scrollTo({ x: newX < maxContentWidth ? newX : maxContentWidth, animated: true })
//   }

//   return (
//     <HStack key={`XHSV_${sLayout.width}`} w='full' onLayout={_onLayout}>
//       {isInitialized ? (
//         <NBScrollView
//           // key={`${maxContentWidth}`}
//           // key={`${maxContentWidth}_${sLayout.width}`}
//           ref={localRef}
//           w='full'
//           // disableScrollViewPanResponder
//           scrollToOverflowEnabled
//           showsHorizontalScrollIndicator={false}
//           horizontal
//           scrollEnabled
//           scrollEventThrottle={4}
//           onContentSizeChange={_onContentSizeChange}
//           // onMomentumScrollEnd={_onScroll}
//           // onScrollEndDrag={_onScroll}
//           onScroll={_onScroll}
//           {...restProps}>
//           {children}
//         </NBScrollView>
//       ) : null}

//       {isInitialized && controlsVisibility.prev ? (
//         <XIconButton
//           // key={`${maxContentWidth}_${sLayout.width}`}
//           opacity={0.9}
//           _hover={{ opacity: 0.6, bg: NB.HC.BG.CLEAR() }}
//           bg={NB.HC.BG.LIGHT_GREY()}
//           // variant='outline'
//           name='arrow-back'
//           h='full'
//           position='absolute'
//           left='0'
//           onPress={_onPressPrev}
//         />
//       ) : null}

//       {isInitialized && controlsVisibility.next ? (
//         <XIconButton
//           // key={`${maxContentWidth}_${sLayout.width}`}
//           opacity={0.9}
//           _hover={{ opacity: 0.6, bg: NB.HC.BG.CLEAR() }}
//           bg={NB.HC.BG.LIGHT_GREY()}
//           name='arrow-forward'
//           h='full'
//           position='absolute'
//           right='0'
//           onPress={_onPressNext}
//         />
//       ) : null}
//     </HStack>
//   )
// }

// XHorizontalScrollView.displayName = 'XHorizontalScrollView'

interface IXHorizontalScrollViewState {
  isInitialized: boolean

  controlsVisibility: {
    prev: boolean
    next: boolean
  }
}

class XHorizontalScrollView extends React.Component<IScrollViewProps, IXHorizontalScrollViewState> {
  static displayName = 'XHorizontalScrollView'

  scrollRef: React.RefObject<ScrollView>

  maxContentWidth: number

  sLayout: LayoutRectangle

  lastScrollX: number

  constructor(props) {
    super(props)

    this.scrollRef = React.createRef<ScrollView>()
    this.maxContentWidth = 0
    this.sLayout = { height: 0, width: 0, x: 0, y: 0 }
    this.lastScrollX = 0

    this.state = {
      isInitialized: false,
      // maxContentWidth: 0,
      // sLayout: { height: 0, width: 0, x: 0, y: 0 },
      // lastScrollX: 0,

      controlsVisibility: {
        prev: false,
        next: false,
      },
    }
  }

  componentDidMount() {
    this.setIsInitialized(true)
    this._checkControlButtonsVisibility()
  }

  setIsInitialized = (isInitialized: boolean) => {
    this.setState({ isInitialized })
  }

  setMaxContentWidth = (maxContentWidth: number) => {
    // this.setState({ maxContentWidth })
    this.maxContentWidth = maxContentWidth
  }

  setScrollLayout = (sLayout: LayoutRectangle) => {
    // this.setState({ sLayout })
    this.sLayout = sLayout
  }

  setLastScrollX = (lastScrollX: number) => {
    // this.setState({ lastScrollX })
    this.lastScrollX = lastScrollX
  }

  setControlsVisibility = (newControlsVisibility: { prev: boolean; next: boolean }) => {
    const { controlsVisibility } = this.state
    const visibilityDiff = diff(newControlsVisibility, controlsVisibility)
    const isDiff = !_.isEmpty(visibilityDiff)
    // console.log('setControlsVisibility visibilityDiff => ', visibilityDiff)
    // console.log('setControlsVisibility isDiff => ', isDiff)
    if (isDiff) {
      this.setState({ controlsVisibility: newControlsVisibility })
    }
  }

  // [maxContentWidth, sLayout, lastScrollX]
  _checkControlButtonsVisibility = () => {
    // const { maxContentWidth, sLayout, lastScrollX } = this.state
    const { maxContentWidth, sLayout, lastScrollX } = this
    // console.log('_checkControlButtonsVisibility triggerd maxContentWidth => ', maxContentWidth)
    // console.log('_checkControlButtonsVisibility triggerd sLayout => ', sLayout)
    if (maxContentWidth && maxContentWidth > 0 && sLayout && sLayout.width > 0) {
      const acceptableOverflowWidth = 8
      const hasOverflowContent = maxContentWidth > sLayout.width
      const maxScrollOffset = maxContentWidth - sLayout.width - acceptableOverflowWidth
      const isAvailableOffset = maxScrollOffset >= 12
      const shouldVisiblePrevButton = hasOverflowContent && isAvailableOffset && (lastScrollX > 0 || lastScrollX === maxScrollOffset)
      const shouldVisibleNextButton = hasOverflowContent && isAvailableOffset && (lastScrollX === 0 || lastScrollX < maxScrollOffset)
      // console.log('_checkControlButtonsVisibility maxScrollOffset => ', maxScrollOffset)
      // console.log('_checkControlButtonsVisibility lastScrollX => ', lastScrollX)
      // console.log('_checkControlButtonsVisibility isAvailableOffset => ', isAvailableOffset)
      // console.log('_checkControlButtonsVisibility shouldVisiblePrevButton => ', shouldVisiblePrevButton)
      // console.log('_checkControlButtonsVisibility shouldVisibleNextButton => ', shouldVisibleNextButton)
      // setIsPrevButtonVisible(shouldVisiblePrevButton)
      // setIsNextButtonVisible(shouldVisibleNextButton)
      this.setControlsVisibility({ prev: shouldVisiblePrevButton, next: shouldVisibleNextButton })
    }
  }

  _onLayout = (evt: LayoutChangeEvent) => {
    const newLayout = evt.nativeEvent.layout
    this.setScrollLayout(newLayout)
    this._checkControlButtonsVisibility()
  }

  _onContentSizeChange = (w: number, h: number): void => {
    // if (width !== maxContentWidth) {
    this.setMaxContentWidth(w)
    this._checkControlButtonsVisibility()
  }

  _onScroll = (evt: NativeSyntheticEvent<NativeScrollEvent>) => {
    const latestScrollX = evt.nativeEvent.contentOffset.x
    this.setLastScrollX(latestScrollX)
    this._checkControlButtonsVisibility()
  }

  // _getJumpSize = () => Math.floor(this.state.sLayout.width * 0.7)
  _getJumpSize = () => Math.floor(this.sLayout.width * 0.7)

  _onPressPrev = () => {
    // const newX = this.state.lastScrollX - this._getJumpSize()
    const newX = this.lastScrollX - this._getJumpSize()
    this.scrollRef.current.scrollTo({ x: newX > 0 ? newX : 0, animated: true })
  }

  _onPressNext = () => {
    // const newX = this.state.lastScrollX + this._getJumpSize()
    // this.scrollRef.current.scrollTo({ x: newX < this.state.maxContentWidth ? newX : this.state.maxContentWidth, animated: true })
    const newX = this.lastScrollX + this._getJumpSize()
    this.scrollRef.current.scrollTo({ x: newX < this.maxContentWidth ? newX : this.maxContentWidth, animated: true })
  }

  render() {
    const { children, ...restProps } = this.props
    const { isInitialized, controlsVisibility } = this.state
    return (
      <HStack key={`XHSV_${this.sLayout.width}`} w='full' onLayout={this._onLayout}>
        {isInitialized ? (
          <XScrollView
            // key={`${maxContentWidth}`}
            // key={`${maxContentWidth}_${sLayout.width}`}
            ref={this.scrollRef}
            w='full'
            // style={{ width: '100%', flex: 1 }}
            // disableScrollViewPanResponder
            scrollToOverflowEnabled
            showsHorizontalScrollIndicator={false}
            horizontal
            scrollEnabled
            scrollEventThrottle={4}
            onContentSizeChange={this._onContentSizeChange}
            onScroll={this._onScroll}
            {...restProps}>
            {children}
          </XScrollView>
        ) : null}

        {/* {isInitialized && controlsVisibility.prev ? (
          <XIconButton
            // key={`${maxContentWidth}_${sLayout.width}`}
            opacity={0.9}
            _hover={{ opacity: 0.6 }}
            _light={{ bg: NB.C.L.BG.LIGHT_GREY }}
            _dark={{ bg: NB.C.D.BG.LIGHT_GREY }}
            // variant='outline'
            name='arrow-back'
            h='full'
            position='absolute'
            left='0'
            onPress={this._onPressPrev}
          />
        ) : null} */}

        {/* {isInitialized && controlsVisibility.next ? (
          <XIconButton
            // key={`${maxContentWidth}_${sLayout.width}`}
            opacity={0.9}
            _hover={{ opacity: 0.6 }}
            _light={{ bg: NB.C.L.BG.LIGHT_GREY }}
            _dark={{ bg: NB.C.D.BG.LIGHT_GREY }}
            name='arrow-forward'
            h='full'
            position='absolute'
            right='0'
            onPress={this._onPressNext}
          />
        ) : null} */}
        {isInitialized && controlsVisibility.prev ? <PrevButton onPress={this._onPressPrev} /> : null}
        {isInitialized && controlsVisibility.next ? <NextButton onPress={this._onPressNext} /> : null}
      </HStack>
    )
  }
}

const PrevButton = ({ onPress, ...restProps }) => (
  <HStack position='absolute' top='0' bottom='0' left='0' alignItems='center' justifyContent='center' {...restProps}>
    <Box h='full' w='full' opacity={0.6} bg='gray.300' position='absolute' top='0' bottom='0' left='0' right='0' />

    <TouchableOpacity onPress={onPress}>
      <HStack alignItems='center'>
        <XIcon name='arrow-back' />
      </HStack>
    </TouchableOpacity>
  </HStack>
)

const NextButton = ({ onPress, ...restProps }) => (
  <HStack position='absolute' top='0' bottom='0' right='0' alignItems='center' justifyContent='center' {...restProps}>
    <Box h='full' w='full' opacity={0.6} bg='gray.300' position='absolute' top='0' bottom='0' left='0' right='0' />

    <TouchableOpacity onPress={onPress}>
      <HStack alignItems='center'>
        <XIcon name='arrow-forward' />
      </HStack>
    </TouchableOpacity>
  </HStack>
)

export default XHorizontalScrollView
