import _ from 'lodash'
import { List } from 'immutable'
import p from '../config/platform-specific'
import CONS from '../config/constants'
// import { log } from '../utils/util'
import {
  getSelectedStoreRoleId,
  getSellerStores,
  getSelectedStore,
  getSelectedStoreHelperPermissions,
  getSubscription,
} from '../redux/selectors'

export function isSelectedStoreOwner(): boolean {
  const state = p.op.getAppState()
  return getSelectedStoreRoleId(state) === CONS.STORE_ROLE.OWNER
}

// If input is array will be done with && operator
// Returns TRUE if the user is a store owner or is a helper with permission
export function canDoAtSelectedStore(permission: string | string[], bitIndex?: number): boolean {
  // log('canDoAtSelectedStore::  permission => ', permission)

  if (_.isString(permission)) {
    return _canDoAtSelectedStore(permission, bitIndex)
  }
  // now bitIndex not support string[]
  if (_.isArray(permission) && permission.length > 0) {
    const permLength = permission.length
    let flag = _canDoAtSelectedStore(permission[0]) // check first flag
    for (let i = 1; i < permLength; i++) {
      flag = flag && _canDoAtSelectedStore(permission[i])
    }
    return flag
  }

  return false
}

function _isBitSet(number: number, bitIndex: number): boolean {
  // eslint-disable-next-line no-bitwise
  return (number & bitIndex) !== 0
}

function _canDoAtSelectedStore(permission: string, bitIndex?: number): boolean {
  if (!permission) return false

  const state = p.op.getAppState()
  const roleId = getSelectedStoreRoleId(state)

  if (roleId === CONS.STORE_ROLE.OWNER) return true
  if (roleId !== CONS.STORE_ROLE.OWNER && permission === CONS.PERM_STORE_OWNER_ONLY) return false

  if (roleId === CONS.STORE_ROLE.HELPER) {
    const helperPerms = getSelectedStoreHelperPermissions(state)
    if (_.isNumber(bitIndex)) {
      const helperBitPerm = helperPerms.get(permission)
      return _.isNumber(helperBitPerm) ? _isBitSet(helperBitPerm, bitIndex) : true
    }
    return !!(helperPerms?.has(permission) && helperPerms.get(permission))
  }

  return false

  // if (!permission) {
  //   return false
  // }
  // const state = p.op.getAppState()
  // let roleId
  // roleId = getSelectedStoreRoleId(state)
  // // log('_canDoAtSelectedStore::  roleId => ', roleId)

  // if (roleId === CONS.STORE_ROLE.OWNER) {
  //   // ถ้า เป็นเจ้าของ ทำได้ทุกอย่าง
  //   return true
  // } else if (roleId !== CONS.STORE_ROLE.OWNER && permission === CONS.PERM_STORE_OWNER_ONLY) {
  //   // ถ้า ไม่เป็นเจ้าของ แต่ permission required === owner_only
  //   return false
  // } else if (roleId === CONS.STORE_ROLE.HELPER) {
  //   // ถ้า เป็นผู้ช่วย ให้เอา permissions จาก selectedStore มาเช็ค
  //   let perms
  //   perms = getSelectedStoreHelperPermissions(state)
  //   if (!_.isNil(bitIndex)) {
  //     const getBitPerms = perms.get(permission)
  //     // console.log('getBitPerms', getBitPerms)
  //     // console.log('(getBitPerms & bitIndex) === bitIndex', (getBitPerms & bitIndex) === bitIndex)
  //     if (!_.isNil(getBitPerms)) {
  //       return (getBitPerms & bitIndex) === bitIndex
  //     } else {
  //       return true
  //     }
  //   } else {
  //     // log('STORE_ROLE.HELPER:: perms => ', perms.toJS())
  //     if (perms && perms.has(permission) && perms.get(permission)) {
  //       // log(`perms.get(${permission}): ` + perms.get(permission))
  //       return true
  //     } else {
  //       // log(`perms.get(${permission}): false`)
  //       return false
  //     }
  //   }
  // }
  // return false
}

export function canSeeReport(): boolean {
  const state = p.op.getAppState()
  const roleId = getSelectedStoreRoleId(state)
  if (roleId === CONS.STORE_ROLE.OWNER) {
    // ถ้า เป็นเจ้าของ ทำได้ทุกอย่าง
    return true
  }
  const perms = getSelectedStoreHelperPermissions(state)
  const storeHelper = CONS.PERM_STORE_HELPER
  const reportOrder = perms.get(storeHelper.REPORT_ORDER)
  const reportInventory = perms.get(storeHelper.REPORT_INVENTORY)
  const reportShipping = perms.get(storeHelper.REPORT_SHIPPING)
  const reportReseller = perms.get(storeHelper.REPORT_RESELLER)
  const reportHelper = perms.get(storeHelper.REPORT_HELPER)
  const reportPayment = perms.get(storeHelper.REPORT_PAYMENT)
  return reportOrder + reportInventory + reportShipping + reportReseller + reportHelper + reportPayment > 0
}

export function authorizedOrderListTabs(): string[] {
  const state = p.op.getAppState()
  const sellerStores = getSellerStores(state)
  const selectedStore = getSelectedStore(state)
  const hasReseller = selectedStore.get('has_reseller') || false
  const hasSeller = List.isList(sellerStores) && sellerStores.size > 0

  // let excludeList
  // const noSellerExcludeList = ['sellerTasks_confirmOrder', 'sellerTasks_confirmGettingPaid', 'sellerTasks_ship', 'sellerTasks_pay']
  // const noResellerExcludeList = ['resellerTasks_confirmOrder', 'resellerTasks_pay', 'resellerTasks_confirmGettingPaid']
  // if (hasSeller && hasReseller) {
  //   // have both
  //   excludeList = []
  // } else if (hasSeller) {
  //   // no reseller
  //   excludeList = noResellerExcludeList
  // } else if (hasReseller) {
  //   // no seller
  //   excludeList = noSellerExcludeList
  // } else {
  //   // no have both (seller / reseller)
  //   excludeList = noSellerExcludeList.concat(noSellerExcludeList)
  // }
  //
  // let fullOrderSummary
  // fullOrderSummary = []
  // CONS.ORDER_SUMMARY_ALL.forEach(key => {
  //   // if (!key.includes('resellerTasks')) {
  //   if (!_.includes(excludeList, key)) {
  //     fullOrderSummary.push(key)
  //   }
  // })

  let fullOrderSummary
  if (hasSeller && hasReseller) {
    // have both
    fullOrderSummary = CONS.ORDER_SUMMARY_ALL.concat(CONS.ORDER_SUMMARY_SPECIAL_TAB_KEYS)
  } else if (hasSeller) {
    // no reseller
    fullOrderSummary = CONS.ORDER_SUMMARY_NO_RESELLER.concat(CONS.ORDER_SUMMARY_SPECIAL_TAB_KEYS)
  } else if (hasReseller) {
    // no seller
    fullOrderSummary = CONS.ORDER_SUMMARY_NO_SELLER
  } else {
    // no have both (seller / reseller)
    fullOrderSummary = CONS.ORDER_SUMMARY_NO_SELLER_AND_RESELLER
  }

  // Bypass permission to fetch all tabs
  return fullOrderSummary

  if (isSelectedStoreOwner()) {
    // return CONS.ORDER_SUMMARY_ALL
    return fullOrderSummary
  }
  let tabs
  tabs = []

  const { ORDER_LIST, SHIPPING_ADD, ORDER_EDIT, PAYMENT_EDIT, PAYMENT_LIST, SHIPPING_LIST } = CONS.PERM_STORE_HELPER
  if (canDoAtSelectedStore(ORDER_LIST)) {
    // tabs.push('myTasks_ship')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'sellerTasks_pay')
  }

  if (canDoAtSelectedStore(SHIPPING_ADD)) {
    // tabs.push('myTasks_ship')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'myTasks_ship')
  }

  if (canDoAtSelectedStore([ORDER_LIST, ORDER_EDIT])) {
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'myTasks_confirmOrder')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'custTasks_autoComplete')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'resellerTasks_confirmOrder')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'sellerTasks_confirmOrder')
  }

  if (canDoAtSelectedStore([ORDER_LIST])) {
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'myTasks_pay')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'myTasks_paySalesOrdersPaid') // for donut
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'myTasks_payOther') // for donut
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'myTasks_confirmGettingPaid')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'custTasks_pay')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'resellerTasks_pay')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'sellerTasks_confirmGettingPaid')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'sellerTasks_pay')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'resellerTasks_confirmGettingPaid')
  }

  if (canDoAtSelectedStore([ORDER_LIST, PAYMENT_LIST, SHIPPING_LIST])) {
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'doneTasks_shipped')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'doneTasks_autoCompleted')
    pushKeyIfNoExisted(fullOrderSummary, tabs, 'doneTasks_cancelled')
  }

  // TODO: Handle all permissions
  // if (canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PAYMENT_ADD)) {
  //     tabs.push('myTasks_pay')
  // }
  // tabs.push('doneTasks_shipped')
  // tabs.push('doneTasks_autoCompleted')
  // tabs.push('doneTasks_cancelled')
  return tabs
}

// local util function
function pushKeyIfNoExisted(fullSourceArr: string[], targetArr: string[], newKey: string): void {
  if (!fullSourceArr || !targetArr || !_.isArray(targetArr)) {
    return
  }

  const isDuplicated = _.includes(targetArr, newKey)
  if (isDuplicated) {
    return
  }

  if (!_.includes(fullSourceArr, newKey)) {
    return
  }

  targetArr.push(newKey)
}

// // Deprecated when back-end has great subscription validation
// export async function canCreateNewOrder(): Promise<boolean> {
//   const state = p.op.getAppState()
//   const subscription: Map<string, any> = selectors.getSubscription(state)
//   log('canOpenNewOrder subscription => ', subscription.toJS())
//   if (!subscription) {
//     return false
//   }
//   const orderCount_curr = subscription.get('order_count_curr') || 0
//   const orderCount_quota = subscription.get('order_count_quota') || 0
//   const availableOrder = order_count_quota - order_count_curr
//   if (availableOrder > 0) {
//     return true
//   }
//
//   const orderTracker = await p.op.storageGet(CONS.STORAGE_KEYS.SUBSCRIPTION_ORDER_TRACKER)
//   log('canOpenNewOrder orderTracker => ', orderTracker)
//   if (!orderTracker) {
//     return false
//   }
//
//   const now = moment()
//   const outOfOrderTime = moment(orderTracker.exceededAt, CONS.SERVER_DATETIME_FORMAT)
//   // const diffTime = now.diff(outOfOrderTime, 'm')
//   // log('canOpenNewOrder diffTime => ', diffTime)
//
//   if (now.isSameOrBefore(outOfOrderTime, 'm')) {
//     // ยังเหลือเวลา
//     return true
//   }
//
//   return false
// }

export function myPack(): number {
  const state = p.op.getAppState()
  const subscription = getSubscription(state)
  const type = subscription.get('type')
  return type
}

export function packFree(): boolean {
  const state = p.op.getAppState()
  const subscription = getSubscription(state)
  const type = subscription.get('type')
  if (type === CONS.SUBSCRIPTION_PACKAGE.FREE) {
    return true
  }
  return false
}

export function packBRONZE(): boolean {
  const state = p.op.getAppState()
  const subscription = getSubscription(state)
  const type = subscription.get('type')
  if (type === CONS.SUBSCRIPTION_PACKAGE.BRONZE) {
    return true
  }
  return false
}

export function packSILVER(): boolean {
  const state = p.op.getAppState()
  const subscription = getSubscription(state)
  const type = subscription.get('type')
  if (type === CONS.SUBSCRIPTION_PACKAGE.SILVER) {
    return true
  }
  return false
}

export function packGOLD(): boolean {
  const state = p.op.getAppState()
  const subscription = getSubscription(state)
  const type = subscription.get('type')
  if (type === CONS.SUBSCRIPTION_PACKAGE.GOLD) {
    return true
  }
  return false
}

export function packPLATINUM(): boolean {
  const state = p.op.getAppState()
  const subscription = getSubscription(state)
  const type = subscription.get('type')
  if (type === CONS.SUBSCRIPTION_PACKAGE.PLATINUM) {
    return true
  }
  return false
}

export function packTRIAL(): boolean {
  const state = p.op.getAppState()
  const subscription = getSubscription(state)
  const type = subscription.get('type')
  if (type === CONS.SUBSCRIPTION_PACKAGE.TRIAL) {
    return true
  }
  return false
}
