import {
  ISearchProductItem,
  ISearchInventoryProductsParams,
  ISearchInventoryProductsRequestBody,
  IInventoryQtyAdjustRequestBody,
  IInventoryQtyAdjustResponse,
  IApiOptions,
} from 'x/types'
// import CONS from 'x/config/constants'
// import p from 'x/config/platform-specific'

import _ from 'lodash'
import api from './api'

interface ISearchInventoryProductsResponse {
  products: ISearchProductItem[]

  count?: number
  // c?: number
}

export async function searchInventoryProducts(params: ISearchInventoryProductsParams): Promise<ISearchInventoryProductsResponse> {
  const { store_id, queryText, role_id, offset = 0, limit = 40, is_helper, is_v2 = false, ...restParams } = params

  if (!store_id) {
    throw new Error('searchInventoryProducts:: Need store_id')
  }

  // if (!queryText) {
  //   throw new Error('searchInventoryProducts:: Need queryText')
  // }

  let queryTextArrayFiltered
  let queryTextArray = []
  if (queryText && queryText.trim().length > 0) {
    queryTextArrayFiltered = queryText.split(/(\s+)/g).filter((str) => str.trim().length > 0)
    queryTextArray = queryTextArrayFiltered.slice(0, 12)
  }

  // @ts-ignore
  const reqBody: ISearchInventoryProductsRequestBody = {
    store_id,
    offset,
    limit,
    // query_text_and: queryTextArray,

    // @ts-ignore
    show_qty: true, // default false
    show_cost: false, // default true (require pg or ug)
    show_price: false, // default true (require pg or ug)
    show_pt_created_at: false, // default false
    show_pp_created_at: false, // default false
    // @ts-ignore
    show_pt_updated_at: false, // default false
    show_pp_updated_at: false, // default false
    // is_query_sku: true, // default true (at least one if is_query_xxx must be true or InvalidQueryTextType error will be thrown)
    // is_query_upc: true, // default true (at least one if is_query_xxx must be true or InvalidQueryTextType error will be thrown)
    // is_query_pp_name: false, // default true (at least one if is_query_xxx must be true or InvalidQueryTextType error will be thrown)

    ...restParams,
  }

  // if (_.isNumber(restParams.user_group_id) || (_.isArray(restParams.product_group_ids) && restParams.product_group_ids.length > 0)) {
  //   reqBody.show_cost = true
  //   reqBody.show_price = true
  // }

  if (queryTextArray.length > 0) {
    reqBody.query_text_and = queryTextArray
  }

  if (_.isBoolean(params.is_query_pp_name)) {
    reqBody.is_query_pp_name = params.is_query_pp_name
  }

  if (_.isBoolean(params.is_query_sku)) {
    reqBody.is_query_sku = params.is_query_sku
  }

  if (_.isBoolean(params.is_query_upc)) {
    reqBody.is_query_upc = params.is_query_upc
  }

  if (_.isBoolean(params.is_exact_match_sku)) {
    reqBody.is_exact_match_sku = params.is_exact_match_sku
  }

  if (_.isBoolean(params.is_exact_match_upc)) {
    reqBody.is_exact_match_upc = params.is_exact_match_upc
  }

  if (offset === 0) {
    reqBody.c = 1
  }

  // backend require if role_id === 2 (helper)
  if (role_id === 2) {
    // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
    reqBody.role_id = role_id
  }

  if (is_helper) {
    reqBody.is_helper = is_helper
  }

  const apiOptions: IApiOptions = {
    axiosOptions: {
      timeout: 30000,
    },
    showSpinner: false,
    isApiV2: true,
    isErrorAlertDisabled: true,
  }

  // // @ts-ignore
  // reqBody.product_group_ids = [64080]

  const endpoint = is_v2 ? 'products/searchv2' : 'products/search'

  try {
    // console.log('In searchInventoryProducts:: reqBody => ', reqBody)
    const res = await api.post<ISearchInventoryProductsRequestBody, ISearchInventoryProductsResponse>(endpoint, reqBody, apiOptions)
    // console.log('In searchInventoryProducts:: response => ', res)

    if (!res.products) {
      throw new Error('searchInventoryProducts:: No "product" in response')
    }
    return res
  } catch (err) {
    console.log('In searchInventoryProducts:: err => ', err)
    throw err
  }
}

export async function inventoryQtyAdjust(params: IInventoryQtyAdjustRequestBody): Promise<IInventoryQtyAdjustResponse> {
  const { store_id, mode, items } = params

  if (!store_id) {
    throw new Error('inventoryQtyAdjust:: Need store_id')
  }

  if (!mode) {
    throw new Error('inventoryQtyAdjust:: Need mode')
  }

  if (!items || !items.length) {
    throw new Error('inventoryQtyAdjust:: Need items')
  }

  const apiOptions = {
    axiosOptions: {
      timeout: 30000,
    },
    showSpinner: false,
    isApiV2: true,
  }

  // // @ts-ignore
  // reqBody.product_group_ids = [64080]

  try {
    // console.log('In inventoryQtyAdjust:: reqBody => ', reqBody)
    const res = await api.post<IInventoryQtyAdjustRequestBody, IInventoryQtyAdjustResponse>('inventory/qty/adjust', params, apiOptions)
    console.log('In inventoryQtyAdjust:: response => ', res)

    return res
  } catch (err) {
    console.log('In inventoryQtyAdjust:: err => ', err)
    throw err
  }
}
