import React from 'react'
import { TouchableOpacity } from 'react-native'

import { COLORS } from 'x/config/styles'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import * as acl from 'x/utils/acl'
import CONS from 'x/config/constants'

import _ from 'lodash'
import XCustomHeader from 'xui/components/XCustomHeader'
import { IAddress, IAddressFormSubmitReturn, IStoreContactDetailProps, IStoreContactDetailState } from 'x/index'
import XIcon from 'xui/components/XIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import { VStack } from 'native-base'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import XAddressCard from 'xui/components/XAddressCard'
import * as api from 'x/utils/api'
import * as NavActions from 'x/utils/navigation'

export default class StoreContactDetailView extends React.Component<IStoreContactDetailProps, IStoreContactDetailState> {
  static displayName = 'StoreContactDetailView'

  isLoading?: boolean

  constructor(props: IStoreContactDetailProps) {
    super(props)
    this.state = {
      isInitialized: false,
      selectedContact: null,
    }
  }

  async componentDidMount() {
    await this._initialize()
  }

  async _initialize() {
    await this._fetchContactDetail()
    await util.setStatePromise(this, { isInitialized: true })
  }

  _fetchContactDetail = async () => {
    if (this.isLoading) {
      return
    }
    this.isLoading = true

    const store_id = util.getNavParam(this.props, 'store_id')
    const contact_id = util.getNavParam(this.props, 'contact_id')

    try {
      const selectedContact = await api.getStoreContactDetail({ store_id, contact_id })
      if ('id' in selectedContact) {
        await util.setStatePromise(this, { selectedContact })
      }
    } catch (error) {
      console.log('_fetchContactDetail error => ', error)
    }

    this.isLoading = false
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    const title = util.getNavParam(this.props, 'title', 'ข้อมูลลูกค้า')
    const leftBtn = { backIcon: true, onPressItem: () => this.goBack() }

    return <XCustomHeader title={title} headerLeftProps={leftBtn} />
  }

  _isAddressCardPressable = () => {
    const onAddressCardPress = util.getNavParam(this.props, 'onAddressCardPress')
    return _.isFunction(onAddressCardPress)
  }

  _onSubmitEditStoreContact = async (profile: any): Promise<IAddressFormSubmitReturn> => {
    // console.log('_onSubmitEditStoreContact => ', profile)
    let shouldGoBack = false

    try {
      const store_id = util.getNavParam(this.props, 'store_id')
      const { selectedContact } = this.state
      const reqBody: any = {
        store_id,
        contact_id: selectedContact.id,
      }

      if (profile.first_name) {
        reqBody.first_name = profile.first_name
      }

      if (profile.last_name) {
        reqBody.last_name = profile.last_name
      }

      if (profile.phone) {
        reqBody.phone = profile.phone
      }

      if (profile.email) {
        reqBody.email = profile.email
      }

      const newContact = await api.editStoreContact(reqBody)
      if (newContact && 'id' in newContact) {
        await util.setStatePromise(this, { selectedContact: newContact })
        shouldGoBack = true
      }
    } catch (error) {
      console.log('_onSubmitEditStoreContact error => ', error)
    }

    return {
      isGoBack: shouldGoBack,
      newAddress: profile,
    }
  }

  _navToEditStoreContact = () => {
    const { selectedContact } = this.state
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'แก้ไขข้อมูลลูกค้า',
        type: 'edit_store_contact',
        // @ts-ignore
        initAddress: selectedContact,
        onSubmit: this._onSubmitEditStoreContact,
      })
    )
  }

  _onSubmitEditStoreContactAddress = async (addr: IAddress): Promise<IAddressFormSubmitReturn> => {
    // console.log('_onSubmitEditStoreContactAddress addr => ', addr)
    let shouldGoBack = false
    // const apiOptions: IApiOptions = {
    //   messages: {
    //     successMsg: 'บันทึกที่อยู่สำเร็จ',
    //     errorMsg: 'บันทึกที่อยู่ล้มเหลว',
    //   },
    //   showSpinner: true,
    //   isApiV2: true,
    // }

    try {
      const reqBody: any = _.cloneDeep(addr)
      reqBody.store_id = util.getNavParam(this.props, 'store_id')
      reqBody.contact_id = util.getNavParam(this.props, 'contact_id')
      // reqBody.contact_type = 'main'

      // // API นี้หลังบ้านต้องการ zipcode เป็น int ไม่งั้น error
      // if (reqBody.postal_code && reqBody.postal_code.length === 5) {
      //   reqBody.postal_code = parseInt(reqBody.postal_code)
      // }

      const res: { addresses: IAddress[] } = await api.editStoreContactAddress(reqBody)
      if ('id' in res) {
        shouldGoBack = true
        await this._initialize()
      }
      // console.log('_onSubmitEditStoreContactAddress res => ', res)
    } catch (error) {
      console.log('_onSubmitEditStoreContactAddress error => ', error)
    }

    return {
      isGoBack: shouldGoBack,
      newAddress: addr,
    }
  }

  _navToEditStoreContactAddress = (address: IAddress) => {
    // const { selectedContact } = this.state
    const { navigation } = this.props
    // console.log('_navToEditStoreContactAddress address => ', address)

    navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'แก้ไขข้อมูลลูกค้า',
        type: 'customer_address',
        // @ts-ignore
        initAddress: address,
        onSubmit: this._onSubmitEditStoreContactAddress,
      })
    )
  }

  _onSubmitAddStoreContactAddress = async (addr: IAddress): Promise<IAddressFormSubmitReturn> => {
    // console.log('_onSubmitAddStoreContactAddress addr => ', addr)

    let shouldGoBack = false
    // const apiOptions: IApiOptions = {
    //   messages: {
    //     successMsg: 'บันทึกที่อยู่สำเร็จ',
    //     errorMsg: 'บันทึกที่อยู่ล้มเหลว',
    //   },
    //   showSpinner: true,
    //   isApiV2: true,
    // }

    try {
      const reqBody: any = _.cloneDeep(addr)
      reqBody.store_id = util.getNavParam(this.props, 'store_id')
      reqBody.contact_id = util.getNavParam(this.props, 'contact_id')
      // reqBody.contact_type = 'main'
      // reqBody.contact_type = 'shipping'
      // reqBody.addressable_id = this.state.selectedContact.id
      // reqBody.contact_id = this.state.selectedContact.id

      // API นี้หลังบ้านต้องการ zipcode เป็น int ไม่งั้น error
      if (reqBody.postal_code && reqBody.postal_code.length === 5) {
        reqBody.postal_code = parseInt(reqBody.postal_code)
      }

      reqBody.address_id = addr.id
      delete reqBody.id

      const res: { addresses: IAddress[] } = await api.addStoreContactAddress(reqBody)

      if ('id' in res) {
        shouldGoBack = true
        await this._initialize()
      }
      console.log('_onSubmitAddStoreContactAddress res => ', res)
    } catch (error) {
      console.log('_onSubmitAddStoreContactAddress error => ', error)
    }

    return {
      isGoBack: shouldGoBack,
      newAddress: addr,
    }
  }

  _navToAddStoreContactAddress = () => {
    const { selectedContact } = this.state
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'แก้ไขข้อมูลลูกค้า',
        type: 'customer_address',
        // @ts-ignore
        initAddress: { name: `${selectedContact.first_name} ${selectedContact.last_name}` },
        onSubmit: this._onSubmitAddStoreContactAddress,
      })
    )
  }

  _renderProfile = () => {
    const isAddressCardPressable = this._isAddressCardPressable()
    if (isAddressCardPressable) {
      return null
    }

    const { selectedContact: profile } = this.state

    let name = `${profile.first_name}`
    if (profile.last_name) {
      name += ` ${profile.last_name}`
    }

    let phone = null
    if (profile.phone) {
      phone = `โทรศัพท์ ${profile.phone}`
    }

    let email = null
    if (profile.email) {
      email = `${profile.email}`
    }

    const isEditable = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.CONTACT_EDIT)
    const isDelateable = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.CONTACT_DELETE)

    return (
      <XCard w='full' p='2'>
        <HStack alignItems='center' justifyContent='center'>
          <XText variant='active' bold>
            ข้อมูลทั่วไป
          </XText>
        </HStack>
        <HStack px='2' space='1'>
          <VStack pt='1' minH='34px' flex={1} justifyContent='center'>
            <XText variant='inactive'>{name}</XText>
            {phone ? <XText variant='inactive'>{phone}</XText> : null}
            {email ? <XText variant='inactive'>{email}</XText> : null}
          </VStack>

          <HStack w='100px' justifyContent='flex-end' space='1'>
            <TouchableOpacity
              disabled={!isEditable}
              style={{ width: 38, height: 38, justifyContent: 'center', alignItems: 'center', opacity: isEditable ? 1 : 0 }}
              onPress={this._onEditProfilePress}>
              <XIcon name='edit' family='FontAwesome5' />
            </TouchableOpacity>
            <TouchableOpacity
              disabled={!isDelateable}
              style={{ width: 38, height: 38, justifyContent: 'center', alignItems: 'center', opacity: isDelateable ? 1 : 0 }}
              onPress={this._onDeleteStoreContactPress}>
              <XIcon name='delete' family='AntDesign' color={COLORS.APP_MAIN} />
            </TouchableOpacity>
          </HStack>
        </HStack>
      </XCard>
    )
  }

  _onEditProfilePress = () => {
    console.log('_onEditProfilePress')
    this._navToEditStoreContact()
  }

  _onDeleteStoreContactPress = () => {
    console.log('_onDeleteStoreContactPress')

    p.op.showConfirmation(
      'คำเตือน',
      'กรุณายืนยันการลบข้อมูลลูกค้านี้\n\nข้อมูลลูกค้าที่ลบไปแล้วจะไม่สามารถกู้คืนมาได้อีก คุณยืนยันหรือไม่',
      () => {
        this._doDeleteStoreContact()
      },
      null,
      'ยืนยัน',
      'ยกเลิก'
    )
  }

  _doDeleteStoreContact = async () => {
    const { selectedContact } = this.state
    const store_id = util.getNavParam(this.props, 'store_id')
    const onSuccessDeleteContact = util.getNavParam(this.props, 'onSuccessDeleteContact')

    try {
      await api.deleteStoreContact({ store_id, contact_id: selectedContact.id })

      if (onSuccessDeleteContact) {
        onSuccessDeleteContact(selectedContact.id)
      }

      await util.delay(200)
      this.goBack()
    } catch (error) {
      console.log('_onDeleteStoreContactPress error => ', error)
    }
  }

  _onAdressCardPress = (address: IAddress) => {
    // console.log('_onAdressCardPress address => ', address)
    const onAddressCardPress = util.getNavParam(this.props, 'onAddressCardPress')
    const isPreventGoBackAfterAddressCardPressed = util.getNavParam(this.props, 'isPreventGoBackAfterAddressCardPressed', false)

    if (this._isAddressCardPressable()) {
      onAddressCardPress(address)
    }

    if (!isPreventGoBackAfterAddressCardPressed) {
      this.goBack()
    }
  }

  _onAddressCardDeletePress = (address: IAddress) => {
    // console.log('_onAddressCardDeletePress address => ', address)
    p.op.showConfirmation(
      p.op.t('Contact.Address.warning.confirm_title'),
      p.op.t('Contact.Address.warning.confirm_delete'),
      () => {
        this._doDeleteStoreContactAddress(address)
      },
      null,
      'ยืนยัน',
      'ยกเลิก'
    )
  }

  _doDeleteStoreContactAddress = async (address: IAddress) => {
    const store_id = util.getNavParam(this.props, 'store_id')

    try {
      await api.deleteStoreContactAddress({ store_id, id: address.id })
      await this._initialize()
    } catch (error) {
      console.log('_doDeleteStoreContactAddress error => ', error)
    }
  }

  _onAddressCardEditPress = (address: IAddress) => {
    // console.log('_onAddressCardEditPress address => ', address)
    this._navToEditStoreContactAddress(address)
  }

  _addAddress = () => {
    // console.log('_addAddress')
    this._navToAddStoreContactAddress()
  }

  _renderAddresses = () => {
    const { selectedContact: profile } = this.state
    if (_.isNil(profile)) {
      return null
    }

    const { addresses } = profile
    if (_.isNil(addresses) || addresses.length < 1) {
      return null
    }

    return (
      <XCard w='full' p='2' mt='2'>
        <HStack alignItems='center' justifyContent='center'>
          <XText variant='active' bold>
            ที่อยู่จัดส่ง
          </XText>
        </HStack>
        <VStack w='full' space='1'>
          {addresses ? addresses.map(this._renderAddressCardItem) : null}
        </VStack>
      </XCard>
    )
  }

  _renderAddressCardItem = (address: IAddress, index: number) => {
    const isAddressCardPressable = this._isAddressCardPressable()
    const isEditable = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.CONTACT_EDIT)
    const isDeletable = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.CONTACT_DELETE)

    return (
      <XAddressCard
        key={`address-${index}`}
        address={address}
        isEditable={isEditable}
        isDeletable={isDeletable}
        onPressCard={isAddressCardPressable ? this._onAdressCardPress : undefined}
        onPressDelete={this._onAddressCardDeletePress}
        onPressEdit={this._onAddressCardEditPress}
      />
    )
  }

  _renderBtnAddAddress = () => {
    const isEditable = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.CONTACT_EDIT)
    if (!isEditable) {
      return null
    }

    return (
      <XButton mt='2' onPress={this._addAddress}>
        <XIcon name='plus' family='AntDesign' variant='primary' />
      </XButton>
    )
  }

  renderContent = () => {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XContent p='2'>
        {this._renderProfile()}
        {this._renderAddresses()}
        {this._renderBtnAddAddress()}
      </XContent>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        {this.renderContent()}
      </XContainer>
    )
  }
}
