import _ from 'lodash'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'

interface IXOrderCountHistoryProps {
  orderCountHistory: {
    start_date: string
    end_date: string
    order_count: number
  }[]
}

const XOrderCountHistory = (props: IXOrderCountHistoryProps) => {
  // console.log('props => ', props)
  // console.log('subcription => ', props.subscription)
  // console.log('type => ', props.type)
  if (_.isNil(props)) {
    return null
  }
  if (_.isNil(props.orderCountHistory)) {
    return null
  }
  // Function to format the date
  const formatDate = (dateStr) => dateStr.split(' ')[0] // Extracts the date part before the space (YYYY-MM-DD)

  // Remove time from start_date and end_date
  const updatedHistory = props.orderCountHistory.map((item) => ({
    ...item,
    start_date: formatDate(item.start_date),
    end_date: formatDate(item.end_date),
  }))
  return (
    <VStack>
      <XText bold>สถิติจำนวนออเดอร์ที่ใช้ในรอบบิลที่ผ่านมา</XText>
      {updatedHistory.map((orderCountHistory) => {
        const orderCount = orderCountHistory.order_count
        if (orderCount === 0) {
          return null
        }
        return (
          <HStack w='full' mt='2' key={`${orderCountHistory.start_date}-${orderCountHistory.end_date}`}>
            <HStack flex={2}>
              <XText>{`${orderCountHistory.start_date} - ${orderCountHistory.end_date}`}</XText>
            </HStack>
            <HStack flex={1} alignItems='flex-end' justifyContent='flex-end'>
              <XText>{`${orderCount} ออเดอร์`}</XText>
            </HStack>
          </HStack>
        )
      })}
    </VStack>
  )
}

export default XOrderCountHistory
