import dayjs, { Dayjs } from 'dayjs'

// import plugins
// import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import calendar from 'dayjs/plugin/calendar'
import isoWeek from 'dayjs/plugin/isoWeek'

import 'dayjs/locale/th' // import locale

import _ from 'lodash'
import CONS from '../config/constants'

const SERVER_DATETIME_FORMATS = [
  CONS.SERVER_DATE_FORMAT,
  CONS.SERVER_DATETIME_FORMAT,
  CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS,
  CONS.SERVER_DATETIME_FORMAT_WITH_MICROSECONDS,
]

const DateTimeWithNanoseconds = '2006-01-02 15:04:05.999999'
const DateTime = '2006-01-02 15:04:05'
const FakeTime = '27-12-1987'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(calendar)
dayjs.extend(isoWeek)
dayjs.locale('th') // use locale
dayjs.tz.setDefault('Asia/Bangkok')

export function init() {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(relativeTime)
  dayjs.extend(duration)
  dayjs.extend(calendar)
  dayjs.extend(isoWeek)
  dayjs.locale('th') // use locale
  dayjs.tz.setDefault('Asia/Bangkok')
}

// export function printTimeLocale() {
//   const x = dayjs(new Date())
//   dayjs().isValid
//   console.log('printTimeLocale x => ', x)
//   console.log('printTimeLocale x.locale() => ', x.locale())
//   // console.log('printTimeLocale dayjs().fromNow() => ', dayjs('1999-01-01').fromNow())
//   // console.log('printTimeLocale DateTimeWithNanoseconds => ', dayjs(DateTimeWithNanoseconds, CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS))
//   // console.log('printTimeLocale DateTime => ', dayjs(DateTime, CONS.SERVER_DATETIME_FORMAT))
//   // console.log('printTimeLocale DateTime isValid => ', dayjs(DateTime, CONS.SERVER_DATETIME_FORMAT).isValid())
//   // console.log('printTimeLocale FakeTime isValid => ', dayjs(FakeTime, CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS).isValid())
//   // console.log('printTimeLocale FakeTime isValid => ', dayjs(FakeTime, CONS.SERVER_DATETIME_FORMAT).isValid())
//   // console.log('printTimeLocale isServerDateTimeWithMilliseconds 2006-01-02 isValid => ', isServerDateTimeWithMilliseconds('2006-01-02'))
//   // console.log(
//   //   'printTimeLocale isServerDateTimeWithMilliseconds 2006-01-02 15:04:05 isValid => ',
//   //   isServerDateTimeWithMilliseconds('2006-01-02 15:04:05')
//   // )
//   // console.log(
//   //   'printTimeLocale isServerDateTimeWithMilliseconds 2006-01-02 15:04:05.999999 isValid => ',
//   //   isServerDateTimeWithMilliseconds('2006-01-02 15:04:05.999999')
//   // )
//   // console.log(
//   //   'printTimeLocale isServerDateTimeWithMilliseconds 2006-01-02 15:04:05.999 isValid => ',
//   //   isServerDateTimeWithMilliseconds('2006-01-02 15:04:05.999')
//   // )

//   const [t, ok] = convertServerDateTimeStringToTime('2006-01-02 15:04:05.999999')
//   console.log('t => ', t)
//   console.log('ok => ', ok)
//   console.log('fromNow =>', t.fromNow())
//   console.log('toNow =>', t.toNow())

//   console.log(RNLocalize.getLocales())
//   console.log(RNLocalize.getCurrencies())

//   RNLocalize.addEventListener('change', handleLocalizationChange)
// }

// function handleLocalizationChange() {
//   console.log(RNLocalize.getLocales())
//   console.log(RNLocalize.getCurrencies())
// }

// Functions
export function isServerDate(dateStr: string): boolean {
  return validateDateTimeFormat(dateStr, CONS.SERVER_DATE_FORMAT)
}

export function isServerDateTime(datetimeStr: string): boolean {
  return validateDateTimeFormat(datetimeStr, CONS.SERVER_DATETIME_FORMAT)
}

export function isServerDateTimeWithMilliseconds(datetimeStr: string): boolean {
  return validateDateTimeFormat(datetimeStr, CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS)
}

export function isServerDateTimeWithMicroseconds(datetimeStr: string): boolean {
  return validateDateTimeFormat(datetimeStr, CONS.SERVER_DATETIME_FORMAT_WITH_MICROSECONDS)
}

export function validateDateTimeFormat(dateStr: string, format: string) {
  return dayjs(dateStr, format).format(format) === dateStr
}

/**
 * @description แปลงค่าที่น่าจะเป็น datetime ของ x-go server
 * @example
 * const [t, ok] = convertServerDateTimeStringToTime(targetDateString)
 * if (ok) {
 *     //...dosomething...
 * }
 * @param  {string} datetimeStr
 * @returns [Dayjs, canParsed, parsedFormat]
 */
export function convertServerDateTimeStringToTime(datetimeStr: string): [Dayjs, boolean, string] {
  let ok = false
  let datetime
  let format

  // ถ้าใช้ for-of จะ break loop ไม่ได้

  for (let i = 0; i < SERVER_DATETIME_FORMATS.length; i++) {
    const vFormat = SERVER_DATETIME_FORMATS[i]
    const isValid = validateDateTimeFormat(datetimeStr, vFormat)
    if (isValid) {
      // FIXME: เหมือนว่าจะมีบั๊คเวลาใส่ datetime ของเราลงไปแล้วใช้ custom format ใน function tz มันถูก offset ทำให้เวลาเพี้ยน
      // https://github.com/iamkun/dayjs/issues/1827#issuecomment-1080308508
      // datetime = dayjs.tz(datetimeStr, vFormat, 'Asia/Bangkok')
      datetime = dayjs(datetimeStr, vFormat, 'th')
      // console.log('convertServerDateTimeStringToTime datetimeStr => ', datetimeStr)
      // console.log('convertServerDateTimeStringToTime datetime (tz) => ', datetime.toISOString())
      ok = true
      format = vFormat
      break
    }
  }

  // last force try
  if (!ok) {
    // FIXME: เหมือนว่าจะมีบั๊คเวลาใส่ datetime ของเราลงไปแล้วใช้ custom format ใน function tz มันถูก offset ทำให้เวลาเพี้ยน
    // https://github.com/iamkun/dayjs/issues/1827#issuecomment-1080308508
    const forcedFormat = CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS
    // datetime = dayjs.tz(datetimeStr, forcedFormat, 'Asia/Bangkok')
    datetime = dayjs(datetimeStr, forcedFormat, 'th')
    ok = datetime instanceof dayjs
    format = forcedFormat
  }

  return [datetime, ok, format]
}

function isValidUtcDateTimeString(dateString: string): boolean {
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/
  return isoRegex.test(dateString)
}

function isValidISODateTimeString(dateString) {
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|[+-]\d{2}:\d{2})$/
  return isoRegex.test(dateString)
}

/**
 * แปลงเวลาเป็น Readable Text อ้างอิงจากเวลาปัจจุบัน เช่น "18:00 น.", "เม.ย. 15"
 * @param  {Dayjs|string} datetimeStr
 * @returns string
 */
export function convertServerDateTimeToReadableDateTimeText(datetimeStr: Dayjs | string): string {
  let datetimeString = datetimeStr
  // console.log('convertServerDateTimeToReadableDateTimeText 1 datetimeString => ', datetimeString)
  if (_.isString(datetimeString) && isValidUtcDateTimeString(datetimeString)) {
    // datetimeString = datetimeString.replace('Z', '+07:00')
    datetimeString = datetimeString.replace('Z', '')
    // console.log('convertServerDateTimeToReadableDateTimeText 2 datetimeString => ', datetimeString)
    datetimeString = dayjs.tz(datetimeString, 'Asia/Bangkok').utc().format()
  } else if (_.isString(datetimeString) && isValidISODateTimeString(datetimeString)) {
    datetimeString = dayjs(datetimeString, 'Asia/Bangkok').utc().format()
  }
  // console.log('convertServerDateTimeToReadableDateTimeText 3 datetimeString => ', datetimeString)
  // console.log('convertServerDateTimeToReadableDateTimeText datetimeString => ', datetimeString)
  let dt: Dayjs
  if (typeof datetimeString === 'string') {
    const [pdt, ok] = convertServerDateTimeStringToTime(datetimeString)
    if (ok) {
      dt = pdt
    }
  } else {
    dt = datetimeString
  }

  if (!(dt instanceof dayjs)) {
    return null
  }

  // FIXME: ปิดไว้ชั่วคราวเนื่องจากการคำนวณ วันนี้/เมื่อวานนี้ ทำได้ไม่ถูกต้องเท่าไหร่
  // เพราะมนุษย์ตัดสินเมื่อวานนี้จากการผ่านเที่ยงคืน แต่ฟังก์ชั่นคำนวณตัดสินเมื่อวานนี้จาก diff 24 hours
  // // console.log('convertServerDateTimeToReadableDateTimeText dt => ', dt)
  // const now = dayjs(new Date())
  // const todayDiff = dt.diff(now, 'day')
  // // console.log('convertServerDateTimeToReadableDateTimeText todayDiff => ', todayDiff)

  // // ถ้าอยู่ในวันเดียวกัน
  // if (todayDiff === 0) {
  //   // return dt.format('HH:mm น.')
  //   // console.log('convertServerDateTimeToReadableDateTimeText datetimeStr => ', datetimeStr)
  //   // console.log('convertServerDateTimeToReadableDateTimeText todayDiff => ', todayDiff)
  //   // console.log('convertServerDateTimeToReadableDateTimeText dt => ', dt)
  //   return `วันนี้ ${dt.format('HH:mm น.')}`
  // }

  // // เมื่อวาน
  // if (todayDiff === -1) {
  //   // console.log('convertServerDateTimeToReadableDateTimeText todayDiff => ', todayDiff)
  //   // console.log('convertServerDateTimeToReadableDateTimeText dt => ', dt)
  //   return `เมื่อวาน ${dt.format('HH:mm น.')}`
  // }

  // const startOfWeek = dayjs().startOf('week')
  // const dayDiff = dt.diff(startOfWeek, 'day')

  // // console.log(`${datetimeStr} startOfWeek => `, startOfWeek)
  // // console.log(`${datetimeStr} dayDiff => `, dayDiff)

  // // ถ้าอยู่ภายในสัปดาห์เดียวกัน (วันแรกของสัปดาห์คือวันอาทิตย์)
  // if (dayDiff >= 0) {
  //   return dt.format('dd HH:mm น.')
  // }

  // // const startOfMonth = dayjs().startOf('month')
  // // const monthDiff = dt.diff(startOfMonth, 'month')
  // // // console.log(`${datetimeStr} startOfMonth => `, startOfMonth)
  // // // console.log(`${datetimeStr} monthDiff => `, monthDiff)

  // // // ถ้าอยู่ภายในเดือนเดียวกัน
  // // if (monthDiff === 0) {
  // //   return dt.format('D MMM HH:mm น.')
  // // }

  // const startOfYear = dayjs().startOf('year')
  // const yearDiff = dt.diff(startOfYear, 'year')
  // // console.log(`${datetimeStr} startOfMonth => `, startOfYear)
  // // console.log(`${datetimeStr} yearDiff => `, yearDiff)

  // // ถ้าอยู่ภายในปีเดียวกัน
  // if (yearDiff === 0) {
  //   return dt.format('D MMM HH:mm น.')
  // }

  // อื่นๆ
  // return dt.format('D MMM YYYY HH:mm น.')
  // return dt.format('D MMM YY, HH:mm')
  return dt.format('D MMM YYYY, HH:mm')
}

export function convertServerDateTimeToISOString(datetimeInput: Dayjs | string | number): string {
  let dt: Dayjs

  if (typeof datetimeInput === 'number') {
    const validUnixTime = new Date(datetimeInput).getTime() > 0
    if (validUnixTime) {
      const digitNum = datetimeInput.toString().length
      const dti = digitNum === 10 ? datetimeInput * 1000 : datetimeInput
      dt = dayjs.unix(dti)
    }
  } else if (typeof datetimeInput === 'string') {
    const [pdt, ok] = convertServerDateTimeStringToTime(datetimeInput)
    if (ok) {
      dt = pdt
    }
  } else {
    dt = datetimeInput
  }

  if (!(dt instanceof dayjs)) {
    throw new Error(`${datetimeInput} is not server's datetime.`)
  }

  return dt.toISOString()
}

export function convertISOStringToDateTime(datetimeISOString: string) {
  return dayjs(datetimeISOString)
}

export function changeTimezone(date: Dayjs): Dayjs {
  return dayjs.tz(date.format())
}

export function setDefaultTimezone(tz: string) {
  dayjs.tz.setDefault(tz)
}

export function setLocale(locale: string) {
  dayjs.locale(locale)
}

export default {
  init,
  // printTimeLocale,
  isServerDate,
  isServerDateTime,
  isServerDateTimeWithMilliseconds,
  convertServerDateTimeStringToTime,
  convertServerDateTimeToReadableDateTimeText,
  convertServerDateTimeToISOString,
  convertISOStringToDateTime,
  setDefaultTimezone,
  setLocale,
}
