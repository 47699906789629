import {
  IApiOptions,
  IComputedMultipleOperationOrderItem,
  IComputedMultipleOperationOrderResponse,
  IMultipleOperationOrderItem,
  IMultipleOperationOrderResponse,
  IXSellyErrorResponse,
} from 'x/types'

import _ from 'lodash'
import * as util from 'x/utils/util'
import api from './api'

type IEditOrderShippingTypeParams = {
  store_id: number

  order_ids: number[]
  new_shipping_type_id: number
}

export async function editOrderShippingType(params: IEditOrderShippingTypeParams): Promise<IComputedMultipleOperationOrderResponse> {
  const { store_id, order_ids, new_shipping_type_id } = params

  if (!store_id) {
    throw new Error('editOrderShippingType:: Need store_id')
  }

  if (!order_ids) {
    throw new Error('editOrderShippingType:: Need order_ids')
  }

  if (!new_shipping_type_id) {
    throw new Error('editOrderShippingType:: Need new_shipping_type_id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'แก้ไขรูปแบบจัดส่งสำเร็จ',
    //   errorMsg: 'แก้ไขรูปแบบจัดส่งไม่สำเร็จ',
    // },
    axiosOptions: {
      timeout: 60000,
    },
    showSpinner: false,
    isApiV2: true,
  }

  try {
    // console.log('In editOrderShippingType:: reqBody => ', reqBody)
    const res = await api.post<IEditOrderShippingTypeParams, IMultipleOperationOrderResponse>(
      'order/edit_shipping_type',
      params,
      apiOptions
    )
    // console.log('In editOrderShippingType:: response => ', res)

    if (!res.total_order_count) {
      throw new Error('editOrderShippingType:: No "total_order_count" in response')
    }

    return computeMultipleOrderErrorsFromResponse(res)
  } catch (err) {
    console.log('In editOrderShippingType:: err => ', err)
    throw err
  }
}

type IOrderAutoCompleteShippingsParams = {
  store_id: number
  order_ids: number[]

  force_create?: boolean
  create_shipping_only_with_xshipping?: boolean
}

export async function orderAutoCompleteShippings(
  params: IOrderAutoCompleteShippingsParams
): Promise<IComputedMultipleOperationOrderResponse> {
  const { store_id, order_ids, force_create, create_shipping_only_with_xshipping } = params

  if (!store_id) {
    throw new Error('orderAutoCompleteShippings:: Need store_id')
  }

  if (!order_ids) {
    throw new Error('orderAutoCompleteShippings:: Need order_ids')
  }

  const apiOptions: IApiOptions = {
    axiosOptions: {
      timeout: 60000,
    },
    showSpinner: false,
    isApiV2: true,
  }

  try {
    const reqBody: IOrderAutoCompleteShippingsParams = {
      store_id,
      order_ids,
    }

    if (force_create) {
      reqBody.force_create = force_create
    }

    if (create_shipping_only_with_xshipping) {
      reqBody.create_shipping_only_with_xshipping = create_shipping_only_with_xshipping
    }

    // console.log('In orderAutoCompleteShippings:: reqBody => ', reqBody)
    const res = await api.post<IOrderAutoCompleteShippingsParams, IMultipleOperationOrderResponse>(
      'order/auto_complete_shippings',
      params,
      apiOptions
    )
    // console.log('In orderAutoCompleteShippings:: response => ', res)

    if (!res.total_order_count) {
      throw new Error('orderAutoCompleteShippings:: No "total_order_count" in response')
    }

    return computeMultipleOrderErrorsFromResponse(res)
  } catch (err) {
    console.log('In orderAutoCompleteShippings:: err => ', err)
    throw err
  }
}

function computeMultipleOrderErrorsFromResponse(res: IMultipleOperationOrderResponse): IComputedMultipleOperationOrderResponse {
  const newRes = _.cloneDeep(res)

  if ('orders' in newRes) {
    newRes.orders = newRes.orders.map((od) => {
      const newOd = { ...od } as IComputedMultipleOperationOrderItem
      if ('error' in od) {
        newOd.errorMessage = computeMultipleOperationOrderError(od)
      }
      return newOd
    })
  }

  return newRes
}

function computeMultipleOperationOrderError(od: IMultipleOperationOrderItem): { title: string; message: string } {
  if (util.isXSellyErrorV2(od.error as IXSellyErrorResponse)) {
    // @ts-ignore
    const title = util.parseXSellyErrorToTitleText(od.error.error)
    // @ts-ignore
    const message = util.parseXSellyErrorToMessageText(od.error.error)

    if (_.isString(title) && _.isString(message)) {
      return {
        title,
        message,

        // @ts-ignore
        error: od.error,
        // @ts-ignore
        isErrorV2: true,
      }
    }
  }

  if (util.isXSellyErrorV1(od.error as IXSellyErrorResponse)) {
    const parsedOldErrorMessage = util.getErrMessageObj(od.error as IXSellyErrorResponse, '')
    if (
      parsedOldErrorMessage &&
      parsedOldErrorMessage.title &&
      _.isString(parsedOldErrorMessage.msg) &&
      parsedOldErrorMessage.msg.length > 0
    ) {
      return {
        title: parsedOldErrorMessage.title,
        message: parsedOldErrorMessage.msg,

        // @ts-ignore
        error: od.error,
        // @ts-ignore
        isErrorV1: true,
      }
    }
  }

  if (util.isXSellyApiErrorCode(od.error as string)) {
    // console.log('In computeMultipleOperationOrderError:: od.error => ', od.error)
    const title = util.parseXSellyErrorKeyToTitleText(od.error as string)
    // console.log('In computeMultipleOperationOrderError:: od.error tt => ', tt)
    const message = util.parseXSellyErrorKeyToMessageText(od.error as string)
    // console.log('In computeMultipleOperationOrderError:: od.error mm => ', mm)

    if (_.isString(title) && _.isString(message)) {
      return {
        title,
        message,

        // @ts-ignore
        error: od.error,
        // @ts-ignore
        isErrorCodeSpecificError: true,
      }
    }

    const genericError = util.getErrMessageObj({ error: { error_code: od.error as string, status_code: 400 } }, '')
    // console.log('In computeMultipleOperationOrderError:: genericError => ', genericError)

    if (genericError && _.isString(genericError.title) && _.isString(genericError.msg) && genericError.msg.length > 0) {
      return {
        title: genericError.title,
        message: genericError.msg,

        // @ts-ignore
        error: od.error,
        // @ts-ignore
        isErrorCodeGenericError: true,
      }
    }
  }

  return {
    title: 'ไม่สามารถระบุข้อผิดพลาดได้',
    message: `(${JSON.stringify(od.error)})`,

    // @ts-ignore
    isErrorCannotDecoded: true,
  }
}
