import React from 'react'
import { StyleProp, View, ViewProps, ViewStyle } from 'react-native'

interface HoverableViewProps extends ViewProps {
  activeStyle: StyleProp<ViewStyle>
  hoverStyle: StyleProp<ViewStyle>
}

interface HoverableViewState {
  hover: boolean
}

class HoverableView extends React.Component<HoverableViewProps, HoverableViewState> {
  rootRef?: React.RefObject<View>

  constructor(props) {
    super(props)

    this.state = {
      hover: false,
    }

    this.rootRef = React.createRef()
  }

  setStyles = (styles) => {
    if (this.rootRef?.current) {
      this.rootRef.current.setNativeProps({ style: styles })
    }
  }

  render() {
    // @ts-ignore
    const { activeStyle, hoverStyle, style, onPressIn, onPressOut, ...passThrough } = this.props

    return (
      <View
        ref={this.rootRef}
        // @ts-ignore
        onMouseEnter={() => {
          this.setStyles(hoverStyle)
          this.setState({ hover: true })
        }}
        onMouseLeave={() => {
          this.setStyles(style)
          this.setState({ hover: false })
        }}
        onStartShouldSetResponder={() => true}
        onResponderStart={() => {
          this.setStyles(activeStyle)
        }}
        onResponderRelease={() => {
          this.setStyles(this.state.hover ? hoverStyle : style)
        }}
        style={style}
        {...passThrough}
      />
    )
  }
}

export default HoverableView
