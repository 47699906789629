import React from 'react'

import {
  Clipboard,
  Image,
  Platform,
  // Switch,
  TouchableOpacity,
  View,
  StyleSheet,
  RefreshControl,
  FlatList,
} from 'react-native'

import { isImmutable, List, Map } from 'immutable'
import _ from 'lodash'
import { COLORS, STYLES } from 'x/config/styles'
// import { COLORS as xCOLORS } from 'x/config/styles'

import p from 'x/config/platform-specific'
// import * as xUtil from 'x/utils/util'
import * as xAcl from 'x/utils/acl'

import xCONS from 'x/config/constants'
import BaseProductView from 'x/modules/product/BaseProductView'
import ReportHistory from 'xui/components/report/ReportHistory'
import PrintProductBarcode from 'xui/components/product/PrintProductBarcode'
import { IProductDetailVariantMap, IProductDetailItem, INavHeaderButtonProps, ICategory, IProductDetailVariant } from 'x/index'

import XIcon from 'xui/components/XIcon'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import Icon from 'xui/components/Icon'
import XDropdown from 'xui/components/XDropdown'
import DotDotDotVerticalIcon from 'xui/components/DotDotDotVerticalIcon'
import ImgManager from 'xui/components/ImgManager'
import ProductDetailMkpInfoButton from 'xui/components/product/ProductDetailMkpInfoButton'

import { getConfig } from 'x/config/mode'
import XCustomHeader from 'xui/components/XCustomHeader'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import { Dayjs } from 'dayjs'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XIconButton from 'xui/components/XIconButton'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XButton from 'xui/components/XButton'
import XOverlay from 'xui/components/XOverlay'
import XScrollView from 'xui/components/XScrollView'
import XImage from 'xui/components/XImage'
import XSellyLogo from 'xui/components/XSellyLogo'
import XSegment from 'xui/components/XSegment'
import VariantSearchPanel from './VariantSearchPanel'
import ForwardIcon from '../../components/ForwardIcon'
import ButtonRemoveProductVariant from './ButtonRemoveProductVariant'
import XFlatPicker from '../../components/XFlatPicker'
import XInput from '../../components/XInput'
import ChannelItemBarButton from '../channels/ChannelItemBarButton'
import XSwitch from '../../components/XSwitch'
import ProductVariantItem from './ProductVariantItem'
import BaseUICategoryListOverlay from '../store/Category/BaseUICategoryListOverlay'

const RECEIVER_ADDR_ACTIONS = {
  SKU_AND_UPC: {
    TITLE: 'เลือกตัวเลือกที่ท่านต้องการ...',
    BUTTONS: ['ดาวน์โหลด SKU', 'ดาวน์โหลด UPC', 'ยกเลิก'],
    ACTION_INDEXES: {
      SKU: 0,
      UPC: 1,
      CANCEL: 3,
    },
  },
  PRINT_NUMBER: {
    TITLE: 'ท่านต้องการพิมพ์ทั้งหมดกี่ชุด...',
    BUTTONS: ['1', '2', '3', '4', '5', '6', '7', '8', 'ยกเลิก'],
    ACTION_INDEXES: {
      ONE: 1,
      TWO: 2,
      THREE: 3,
      FOUR: 4,
      FIVE: 5,
      SIX: 6,
      SEVEN: 7,
      EIGHT: 8,
      CANCEL: 9,
    },
  },
}

const config = getConfig()
const { CARD_COMMON, NO_MARGIN } = STYLES

const {
  ADD,
  VIEW_SELF,
  VIEW_SELLER,
  EDIT_SELF,
  EDIT_SELLER,
  PULL_MY_PRODUCT_TO_ORDER,
  PULL_SELLER_PRODUCT_TO_ORDER,
  PULL_PRODUCT,
  VIEW_VOLUME_DISCOUNT,
} = xCONS.PRODUCT_VIEW_MODE

const { NAME, COST, PRICE, PRICE_RETAIL, QTY, AVAILABLE_QTY, WEIGHT, H, SKU, UPC } = xCONS.PRODUCT_ATTR

const {
  PRODUCT_COST,
  PRODUCT_PRICE,
  PRODUCT_WEIGHT,
  PRODUCT_SHIPPING_QTY_RATE,
  PRODUCT_CODE_EDIT_SKU,
  PRODUCT_CODE_EDIT_UPC,
} = xCONS.PERM_STORE_HELPER

const ICON_SELLER_STORE = require('../../img/tb/tb_seller0.png')

const s = StyleSheet.create({
  // ICON_SELLER_STORE: require('../../img/tb/tb_seller0.png'),
  productContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    // backgroundColor: COLORS.SUCCESS_MAIN,
  },
  variantGrid: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  variantGridColor: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  variantColLeft: {
    // width: '15%',
    // backgroundColor: 'powderblue',
    flex: 3,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
  },
  variantColRight: {
    flex: 17,
    // backgroundColor: 'skyblue',
    justifyContent: 'center',
    padding: 5,
  },
  variantColRightColor: {
    width: '80%',
    backgroundColor: 'rgba(15,185,215,0.2)',
    justifyContent: 'center',
    padding: 5,
  },
  variantFullGrid: {
    width: '100%',
    // backgroundColor: 'skyblue',
    justifyContent: 'center',
    padding: 5,
  },
  variantAddItem: {
    // backgroundColor: 'lightgreen',
    padding: 10,
  },
  cardStyle: {
    elevation: 3,
    width: '100%',
  },
  cardBody: {
    width: '100%',
    height: 220,
  },
  cardImg: {
    alignSelf: 'center',
    width: '100%',
    height: 200,
  },
  textEmptyStore: {
    marginTop: '40%',
    alignSelf: 'center',
  },
  fullBtnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  txtHeader: {
    fontSize: STYLES.FONT_SIZE_ORDERS_HEADER_ROW2,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  txtHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
  },
  txtHLBG: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
  },
  labelMultiple: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  txtMultiple: {
    width: '100%',
    minHeight: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultipleHL: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  txtLabelShippingRate: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    paddingTop: 4,
  },
  txtLabelVariant: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    marginTop: 14,
    height: 32,
    // backgroundColor: 'red',
  },
  txtRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    textAlign: 'right',
  },
  txtHLRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
    textAlign: 'right',
  },
  labelRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'right',
  },
  input: {
    width: 100,
    alignSelf: 'flex-end',
    paddingRight: 3,
    paddingTop: 0,
    ...Platform.select({
      android: {
        paddingBottom: 5,
      },
    }),
  },
  shippingRateLabel: {
    ...Platform.select({
      android: {
        paddingTop: 8,
      },
      ios: {
        paddingTop: 4,
      },
    }),
  },
  shippingRateInputQty: {
    width: 38,
  },
  shippingRateInputPrice: {
    width: 48,
  },
  shippingRateInputCommon: {
    textAlign: 'center',
    ...Platform.select({
      android: {
        height: 32,
        paddingTop: 0,
        paddingBottom: 8,
      },
      ios: {
        height: 25,
      },
    }),
  },
  shippingRateInputQtyContainer: {
    width: 40,
    ...Platform.select({
      android: {
        height: 32,
      },
      ios: {
        height: 25,
      },
    }),
  },
  shippingRateInputPriceContainer: {
    width: 50,
    height: 25,
  },
  formItem: { paddingTop: 2, paddingBottom: 2, height: 50, borderBottomWidth: 0 },
  formVItem: { paddingTop: 2, paddingBottom: 2, height: 36, borderBottomWidth: 0 },
  formNormalText: { height: 40 },
  listFooter: {
    paddingVertical: 20,
  },
  iconSellerProduct: {
    height: STYLES.FONT_ICON_SMALLEST + 2,
    width: STYLES.FONT_ICON_SMALLEST + 2,
    paddingRight: 4,
    marginTop: -4,
  },
  pItemL3View: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  pItemL3Icon: {
    fontSize: STYLES.FONT_ICON_SMALLEST,
    color: COLORS.TEXT_INACTIVE,
  },
  pItemL3Qty: {
    paddingLeft: 5,
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
  },
  pItemWeightView: {
    flex: 1,
    alignItems: 'flex-end',
    paddingBottom: 3,
  },
  pItemWeightLabelProdMy: {
    fontSize: 8,
    paddingTop: 1,
    alignItems: 'flex-end',
    color: COLORS.TEXT_INACTIVE,
    paddingRight: 0,
  },
  pItemWeightLabelProdSeller: {
    fontSize: 8,
    paddingTop: 1,
    alignItems: 'flex-end',
    color: COLORS.TEXT_INACTIVE,
    paddingRight: 4,
  },
  pItemWeightLabelBlank: {
    flex: 1,
  },
  txtNoProductNameInfo: {
    flex: 1,
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
    // backgroundColor: 'red',
    paddingLeft: 15,
    paddingTop: 15,
    paddingBottom: 15,
    // alignSelf: 'center',
  },
})

const LOCAL_STYLES: { [key: string]: any } = {
  vdRow: {
    padding: 4,
  },
  vdCol: {
    padding: 4,
  },
  colMiddleLeft: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexBasis: 'auto',
  },
  colMiddleCenter: {
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: 'auto',
  },
  colMiddleRight: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexBasis: 'auto',
  },
  variantIcon: {
    color: COLORS.TEXT_INACTIVE,
    fontSize: STYLES.FONT_ICON_NORMAL,
    height: STYLES.FONT_ICON_NORMAL,
    width: STYLES.FONT_ICON_NORMAL,
    textAlign: 'center',
    // paddingRight: 4
  },
  variantLabel: {
    // color: COLORS.TEXT_INACTIVE,
    color: COLORS.TEXT_ACTIVE,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    // fontWeight: 'bold',
    marginTop: 0,
  },
  variantInput: {
    minHeight: 44,
  },
  btnRemoveVariant: {
    fontSize: STYLES.FONT_ICON_LARGER,
    color: COLORS.FORM_ERROR,
  },
  labelInactive: {
    color: COLORS.TEXT_INACTIVE,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    marginTop: 0,
  },
  labelInactiveSmall: {
    color: COLORS.TEXT_INACTIVE,
    fontSize: STYLES.FONT_SIZE_SMALLER,
    marginTop: 0,
  },
  labelActive: {
    color: COLORS.TEXT_ACTIVE,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    marginTop: 0,
  },
  iconInButtonWithBottomLabel: {
    marginLeft: 8,
    marginRight: 8,
    paddingTop: 0,
    paddingBottom: 4,
  },
  labelActiveDarkBottomIconButton: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    bottom: 3,
    color: 'white',
    fontWeight: 'bold',
    fontSize: STYLES.FONT_SIZE_SMALLEST,
  },
}

const INTL_STYLES: { [key: string]: any } = {
  colMiddleCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  colMiddleLeft: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  btnPrimaryActive: {
    flex: 1,
    height: '100%',
    minHeight: 40,
    width: '100%',
    // margin: 2,
    paddingVertical: 4,
    paddingHorizontal: 2,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLORS.APP_MAIN,
    backgroundColor: COLORS.APP_MAIN,
    alignItems: 'center',
  },
  txtBtnPrimaryActive: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    // color: TEXT_ACTIVE_DARK,
    color: 'white',
    fontWeight: 'bold',
  },
  btnSecondaryActive: {
    flex: 1,
    height: '100%',
    width: '100%',
    margin: 2,
    paddingVertical: 4,
    paddingHorizontal: 2,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLORS.APP_MAIN,
    backgroundColor: 'white',
  },
  txtBtnSecondaryActive: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    // color: TEXT_ACTIVE_DARK,
    color: COLORS.APP_MAIN,
    fontWeight: 'bold',
  },
  txtTitle: {
    // fontSize: FONT_SIZE_NORMAL,
    fontSize: STYLES.FONT_SIZE_LARGER,
    color: COLORS.TEXT_ACTIVE,
    fontWeight: 'bold',
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  txtActive: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  rowGroupAreaBordered: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderWidth: 0.5,
    borderColor: COLORS.TEXT_INACTIVE,
  },
  hamburgerDropdownWrapper: {
    flex: 1,
    // height: 40,
    // width: 40,
    // paddingLeft: 5,
    // paddingRight: 5,
    // marginRight: 5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabStyle: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingLeft: 20,
    // paddingRight: 20,
    // paddingLeft: 16,
    // paddingRight: 24,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // borderRightWidth: 0.5,
    // borderRightColor: COLORS.BG_LIGHT_GREY,
    // borderBottomWidth: 0.5,
    // borderBottomColor: COLORS.BG_LIGHT_GREY,
    marginRight: 4,
    ...Platform.select({
      ios: {
        paddingLeft: 12,
        paddingRight: 20,
      },
      android: {
        paddingLeft: 16,
        paddingRight: 24,
      },
    }),
  },
  tabUnderline: {
    width: '100%',
    borderBottomWidth: 2,
  },
  tabTriangle: {
    position: 'absolute',
    // top: 0,
    right: 0,
    zIndex: 10,

    width: 0,
    height: 0,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    lineHeight: 0,
    // Triangle
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderTopColor: 'transparent',
    borderRightColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
    // borderRightColor: 'red',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
    ...Platform.select({
      ios: {
        top: -12,
        borderRightWidth: 25,
        borderBottomWidth: 25,
      },
      android: {
        top: 0,
        borderRightWidth: 15,
        borderBottomWidth: 15,
      },
    }),
  },
  tabTriangleBg: {
    position: 'absolute',
    // top: 0,
    right: 0,
    zIndex: 5,

    width: 0,
    height: 0,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    // Triangle
    borderTopWidth: 0,
    borderRightWidth: 16,
    borderBottomWidth: 16,
    borderLeftWidth: 0,
    borderTopColor: 'transparent',
    borderRightColor: COLORS.BG_LIGHT_GREY,
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
    ...Platform.select({
      ios: {
        top: -12,
        borderRightWidth: 26,
        borderBottomWidth: 26,
      },
      android: {
        top: 0,
        borderRightWidth: 16,
        borderBottomWidth: 16,
      },
    }),
  },
  tabRightBorderLine: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 5,

    width: 1,
    height: 28,
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
}

const Radio = ({ disabled = false, checked = null }) => (
  <Icon
    style={{
      fontSize: STYLES.FONT_ICON_NORMAL,
      color: disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
      textAlign: 'center',
    }}
    name={checked ? 'radio-button-on' : 'radio-button-off'}
  />
)

const { Fragment } = React

export default abstract class BaseUIProductView extends BaseProductView {
  static displayName = 'BaseUIProductView'

  static inProcess = false // MUST DO at static level since doing at the "this" base's instance level is NOT fast enough to block double click

  abstract handleOnDownloadFile(url: string, fileName: string): Promise<void>

  abstract openPDFFromUrl(url: string, fileName: string): Promise<void>

  // abstract _renderReportProductDailySales(): JSX.Element

  // Navigation
  abstract navToStoreSettingStoreProductSN(): void

  abstract _openPGSelector(): void

  abstract navToVolumeDiscountListView(params: {
    // FIXME: To Artid :: รอให้ review ว่าประกาศถูกมั้ย
    store_id: number
    pg_id: number
    pt_id: number
    pg_vd_ids: number[]
    mode: 'PULL_MODE' | string
  }): void

  // abstract renderMain(): JSX.Element

  // abstract renderProductVariantItem(vProps: IProductVariantItemProps): JSX.Element
  // abstract renderHamburgerButton(): JSX.Element

  // static navigationOptions = ({ navigation }) => {
  //   // log('navigationOptions/navigation: => ',navigation)
  //   const { state, setParams, goBack } = navigation

  //   if (!state || !state.params || _.isEmpty(state.params) || !state.params.mode) {
  //     return {
  //       headerLeft: null,
  //       title: null,
  //       headerRight: null,
  //     }
  //   }

  //   const {
  //     mode,
  //     handleSave,
  //     handleEdit,
  //     handleCancelEditProduct,
  //     handlePullProductToOrder,
  //     handlePullProductToMyStore,
  //     // syncStateAndParams,
  //     handleChangeToEditMode,
  //     outOfStock = false,
  //     uploading = false,
  //     submitting = false,
  //     handleCancelUploading,
  //     handleGoBack,
  //   } = state.params

  //   let title = 'รายละเอียดสินค้า'
  //   const headerLeftProps: INavHeaderButtonProps = { submitting: submitting || uploading }
  //   const headerRightProps: INavHeaderButtonProps = { submitting: submitting || uploading }
  //   let headerRightCustomRender = null

  //   switch (mode) {
  //     case VIEW_SELF:
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = handleGoBack
  //       if (xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PRODUCT_EDIT)) {
  //         // headerRightProps.label = 'แก้ไข++'
  //         // headerRightProps.onPressItem = handleChangeToEditMode
  //         headerRightCustomRender = this._renderHamburgerButton
  //       }
  //       break
  //     case VIEW_SELLER:
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = handleGoBack
  //       break
  //     case PULL_MY_PRODUCT_TO_ORDER:
  //       title = 'เลือกสินค้าสู่ออเดอร์'
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = handleGoBack
  //       headerRightProps.label = 'ยืนยัน'
  //       headerRightProps.onPressItem = handlePullProductToOrder
  //       if (outOfStock) {
  //         headerRightProps.label = 'หมดสต็อก'
  //         headerRightProps.submitting = true
  //       }
  //       break
  //     case PULL_SELLER_PRODUCT_TO_ORDER:
  //       title = 'เลือกสินค้าสู่ออเดอร์'
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = handleGoBack
  //       headerRightProps.label = 'ยืนยัน'
  //       headerRightProps.onPressItem = handlePullProductToOrder
  //       if (outOfStock) {
  //         headerRightProps.label = 'หมดสต็อก'
  //         headerRightProps.submitting = true
  //       }
  //       break
  //     case PULL_PRODUCT:
  //       title = 'เลือกสินค้าเข้าร้าน'
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = handleGoBack
  //       headerRightProps.label = 'ยืนยัน'
  //       headerRightProps.onPressItem = handlePullProductToMyStore
  //       break
  //     case EDIT_SELF:
  //       title = 'แก้ไขสินค้า'
  //       headerLeftProps.label = 'บันทึก'
  //       headerLeftProps.onPressItem = handleEdit
  //       headerRightProps.label = 'ยกเลิก'
  //       headerRightProps.onPressItem = handleCancelEditProduct
  //       break
  //     case ADD:
  //       title = 'เพิ่มสินค้าในร้าน'
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = handleGoBack
  //       headerRightProps.label = 'บันทึก'
  //       headerRightProps.onPressItem = handleSave
  //       break
  //     case VIEW_VOLUME_DISCOUNT:
  //       title = 'สินค้าที่ใช้กับส่วนลดขายส่ง'
  //       headerLeftProps.backIcon = true
  //       headerLeftProps.onPressItem = handleGoBack
  //       headerRightProps.label = 'แก้ไข'
  //       headerRightProps.onPressItem = handleChangeToEditMode
  //       break
  //     default:
  //       return {
  //         headerLeft: null,
  //         title: null,
  //         headerRight: null,
  //       }
  //   }

  //   if (uploading && handleCancelUploading) {
  //     headerLeftProps.label = 'ยกเลิก'
  //     headerLeftProps.onPressItem = handleCancelUploading
  //     headerLeftProps.submitting = false
  //     headerRightProps.label = 'กำลังอัพโหลด...'
  //     headerRightProps.submitting = true
  //   }

  //   return {
  //     headerLeft: <NavHeaderButton {...headerLeftProps} />,
  //     title,
  //     headerRight: <NavHeaderButton {...headerRightProps} />,
  //   }
  // }

  // _handleOnDownloadFile = async (url: string, fileName: string): Promise<void> => {
  //   await utilN.openFileFromUrl(url, fileName, 'xlsx')
  // }

  // _renderReportProductDailySales = () => {
  //   const { subscription, selectedProduct, selectedStore } = this.props
  //   const { isReportProductDailySales } = this.state
  //   const r_shpg_day = subscription.get('r_shpg_day')
  //   const variants = selectedProduct.get('variants')
  //   const store_id = selectedStore.get('id')
  //   // console.log('variants', variants.toJS())
  //   return (
  //     <ReportProductDailySales
  //       onRequestClose={() => this._handleCloseReportProductDailySales}
  //       visible={isReportProductDailySales}
  //       allowOnlyDaysFromToday={r_shpg_day}
  //       store_id={store_id}
  //       variants={variants}
  //     />
  //   )
  // }

  _renderPGVDList = (): JSX.Element | JSX.Element[] => {
    // const { vds, productGroups, selectedIds } = params
    // const pgs = List.isList(productGroups) ? productGroups.toJS() : productGroups
    const { editingProduct, selectedProductGroups, selectedStore } = this.props
    const pgs = List.isList(selectedProductGroups) ? selectedProductGroups.toJS() : selectedProductGroups
    const vds = selectedStore.get('vds') || List([])
    const selectedPgIds = editingProduct.get('product_group_ids') || List([])
    const selectedIds = List.isList(selectedPgIds) ? selectedPgIds.toJS() : selectedPgIds
    const { mode } = this.state
    let textWarning = `กรุณากดปุ่ม แก้ไข (ด้านขวาบน)\nและแตะปุ่มนี้ เพื่อผูกราคาขาย สำหรับสินค้านี้`
    if (mode !== 'VIEW_SELF') {
      textWarning = `กรุณาแตะเพื่อ ผูกราคาขาย สำหรับสินค้านี้\n(แล้วจึงระบุราคาด้านล่าง)`
    }
    const pg_vd_ids = editingProduct.get('pg_vd_ids')
    let renderVolumeDiscount = false
    if (pg_vd_ids) {
      renderVolumeDiscount = true
    }
    if (selectedIds.length > 0) {
      return selectedIds.map((pg_id, idx) => {
        const selectedGroup = pgs.find((pg) => pg.id === pg_id)
        if (!selectedGroup || !selectedGroup.id || !selectedGroup.name) {
          return null
        }
        const s_use_volume_discount = this.props.selectedStore.get('s_use_volume_discount')
        let txt
        let selectedVD
        if (renderVolumeDiscount) {
          selectedVD = pg_vd_ids.map((vd) => {
            if (vd.get('pg_id') === pg_id) {
              vds.map((valDiscount) => {
                if (valDiscount.get('id') === vd.get('vd_id')) {
                  return (txt = util.getTxtvalueDiscount(valDiscount))
                }
              })
              return txt
            }
          })
        }
        return (
          <Box key={idx} p='1' m='1' borderWidth='1' borderColor='primary.200' borderRadius='xs' w='full'>
            <XText bold>{selectedGroup.name}</XText>
            {s_use_volume_discount && renderVolumeDiscount ? (
              <XText variant='inactive' bold>
                {selectedVD}
              </XText>
            ) : null}
          </Box>
        )
      })
    }
    if (pgs.length > 0) {
      return (
        <Box p='1' m='1' borderWidth='1' borderColor='coolGray.200' borderRadius='xs'>
          <XText variant='danger' bold>
            {textWarning}
          </XText>
        </Box>
      )
    }
    // KEEP_ME :: อาจได้ใช้ ถ้าไม่ติดปัญหาอะไร จะมาลบ artid
    // else if (pgs.length > 0) {
    //   return pgs.map((pg_id, idx) => {
    //     console.log('pg_id', pg_id)
    //     return (
    //       <HStack size={7} key={idx}>
    //         <View
    //           style={{
    //             padding: 4,
    //             marginRight: 4,
    //             marginBottom: 2,
    //             borderWidth: 1,
    //             borderStyle: 'solid',
    //             borderColor: APP_SECONDARY,
    //             // borderColor: BG_LIGHT_GREY,
    //             marginTop: 4,
    //             borderRadius: 4,
    //             flex: 1,
    //             // backgroundColor: APP_SECONDARY,
    //           }}>
    //           <Text
    //             style={{
    //               // color: TEXT_INACTIVE,
    //               color: TEXT_ACTIVE,
    //               textAlign: 'left',
    //               fontSize: STYLES.FONT_SIZE_NORMAL,
    //               fontWeight: 'bold',
    //               marginTop: 0,
    //               paddingLeft: 4,
    //             }}>
    //             {pg_id.name}
    //           </Text>
    //         </View>
    //         {/* <VDModalSelectedText>{selectedGroup.name} BBCCTOME</VDModalSelectedText> */}
    //       </HStack>
    //     )
    //   })
    // }
    return null
  }

  renderProductGroupCard = ({ mode, vds, editingProduct, isEditable, product_groups, product_group_ids, s_use_volume_discount }) => {
    const {
      navigation,
      selectedStore,
      updateContInVolumeDiscounts,
      updateDataVolumeDiscountAndProductGroup,
      loadListVolumeDiscounts,
    } = this.props

    // const { disabled = false, productGroups, style = {}, mode } = this.props
    // const { isVisibleSelector, selectedIds } = this.state
    const disabled = !isEditable
    return (
      <XCard disabled={disabled} onPress={disabled ? null : this._openPGSelector} mt='1'>
        <XCard.Body flex={1}>
          <HStack flex={1} flexWrap='wrap' alignItems='center' justifyContent='space-between' space='1'>
            <VStack flex={1}>
              <XText variant='inactive'>อยู่ในรายการราคา</XText>
              <HStack flexWrap='wrap' w='full'>
                {this._renderPGVDList()}
              </HStack>
            </VStack>
            {/* <HStack size={6}>
                <VDModalSelectedText key={1}>BBBBBBBBBBBBBBBBBBBBBBB</VDModalSelectedText>
              </HStack> */}
            {isEditable ? <ForwardIcon /> : null}
          </HStack>
        </XCard.Body>
        {/* <Grid>
            <HStack>
              <Col size={17} style={{ paddingHorizontal: 8 }}>
                <HStack>
                  <Col
                    size={1}
                    style={{
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      paddingHorizontal: 8,
                      paddingVertical: 4,
                    }}>
                    <Text
                      style={{
                        flex: 1,
                        textAlign: 'left',
                        fontSize: STYLES.FONT_SIZE_NORMAL,
                        // color: TEXT_ACTIVE,
                        color: TEXT_INACTIVE,
                        fontWeight: 'bold',
                        marginTop: 0,
                      }}>
                      {'อยู่ในรายการราคา'}
                    </Text>
                  </Col>
                </HStack>
                <HStack
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    // justifyContent: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    paddingHorizontal: 8,
                  }}>
                  {this._renderSelectedProductGroups(productGroups, selectedIds)}
                </HStack>
              </Col>
              <Col
                size={3}
                style={{
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  paddingHorizontal: 8,
                }}>
                {disabled ? null : <Icon name="arrow-forward" style={{ color: COLORS.TEXT_PRIMARY }} />}
              </Col>
            </HStack>
          </Grid> */}
      </XCard>
    )
    // return (
    //   <PGModalSelector
    //     // style={[CARD_COMMON, { marginLeft: 10 }]}
    //     // selectedIds={ List([1, 2, 3]) }
    //     navigation={navigation}
    //     vds={vds}
    //     editingProduct={editingProduct}
    //     disabled={!isEditable}
    //     style={{ marginLeft: 0, marginRight: 0 }}
    //     productGroups={product_groups}
    //     selectedIds={product_group_ids}
    //     allowedEmptySelected={false}
    //     onSubmitEditing={this._onChangeProductGroups}
    //     unpairVolumeDiscount={this.props.unpairVolumeDiscount}
    //     selectedStore={selectedStore}
    //     updateContInVolumeDiscounts={updateContInVolumeDiscounts}
    //     updateDataVolumeDiscountAndProductGroup={updateDataVolumeDiscountAndProductGroup}
    //     mode={mode}
    //     s_use_volume_discount={s_use_volume_discount}
    //     loadListVolumeDiscounts={loadListVolumeDiscounts}
    //     // onSubmitEditing={ (newPgIds) => onChangeProduct({
    //     //   key: 'product_group_ids',
    //     //   value: newPgIds,
    //     // }) }
    //     // onEmptySubmitEditing={ () => {
    //     //   p.op.alert('ไม่สามารถบันทึก', 'กรุณาเลือกอย่างน้อย 1 รายการราคาให้กับสินค้าชิ้นนี้')
    //     // }}
    //     // onEmptySubmitEditing={this._onChangeProductGroups}
    //   />
    // )
  }

  renderHamburgerButton = (): JSX.Element | null => {
    // const hamburgerMenuOptions = this._calculateHamburgerMenuOptions()
    const { hamburgerMenuOptions, isInitialized, submitting, refreshing, mode } = this.state
    const isDisabled = !isInitialized || submitting || refreshing
    // haveText2line คือ เมนูไหนที่มี 2 บันทัด haveText2line จะ * 1 ไปเรื่ยวๆ ให้ layout มันออกมาพอดี
    // รายงาน ประวัติการ\nเคลื่อนไหวสินค้า * 1
    // รายงาน การขาย\nการขายสินค้า * 2
    // const permission = acl.canDoAtSelectedStore(PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
    // const showPrinter = mode === VIEW_EDIT && permission && this._canPrintShippingLabel(order)
    // const marginRightFromMode = showPrinter ? 25 : 5
    // const showAllShipping = permission && this._canPrintShippingLabel(order)
    // console.log('_renderHamburgerButton:: hamburgerMenuOptions => ', hamburgerMenuOptions)
    return (
      // MUST specify height here as there is iOS bug that if no view has height (in this case the printer icon is hidden),
      // the ... button will DISAPPEARED
      // <View style={{ flexDirection: 'row', marginRight: 5, height: 36 }}>
      <View>
        <XDropdown
          disabled={isDisabled}
          // ref={r => r && (this.hamburgerMenuRef = r)}
          // ref={this.hamburgerMenuRef}
          // style={{
          //   flex: 1,
          //   width: '100%',
          //   height: '100%',
          //   alignItems: 'center',
          //   justifyContent: 'center',
          //   flexDirection: 'row',
          //   display: hamburgerMenuOptions && hamburgerMenuOptions.length > 0 ? 'flex' : 'none',
          // }}
          // dropdownStyle={{
          //   width: 190,
          //   // height: 40 * hamburgerMenuOptions.length + haveText2line,
          //   // + 1 มาจาก report 2 ตัวที่ขึ้นบรรทัดหใม่
          //   height: 42 * (hamburgerMenuOptions.length + 1),
          // }}
          // showsVerticalScrollIndicator={false}
          // onDropdownWillShow={this._onHamburgerWillShow}
          // onDropdownWillHide={this._onHamburgerWillHide}
          renderRow={this._renderModalDropdownRow}
          onSelect={this._onHamburgerMenuSelect}
          options={hamburgerMenuOptions}>
          <DotDotDotVerticalIcon disabled={isDisabled} />
        </XDropdown>
      </View>
    )
  }

  _renderCustomHeader = () => {
    // const params = util.getNavParams(this.props)
    // const {
    //   mode,
    //   handleSave,
    //   handleEdit,
    //   handleCancelEditProduct,
    //   handlePullProductToOrder,
    //   handlePullProductToMyStore,
    //   // syncStateAndParams,
    //   handleChangeToEditMode,
    //   outOfStock = false,
    //   uploading = false,
    //   submitting = false,
    //   handleCancelUploading,
    //   // handleGoBack,
    // } = params

    const { mode = VIEW_SELF, outOfStock = false, submitting = false, uploading = false } = this.state

    const isGoBackButtonHidden: boolean = util.getNavParam(this.props, 'isGoBackButtonHidden', false)

    let headerRightCustomRender = null
    let title = 'รายละเอียดสินค้า'
    const headerLeftProps: INavHeaderButtonProps = { submitting: submitting || uploading }
    const headerRightProps: INavHeaderButtonProps = { submitting: submitting || uploading }
    // ORDER VIRE HEADER

    switch (mode) {
      case VIEW_SELF:
        if (!isGoBackButtonHidden) {
          headerLeftProps.backIcon = true
          headerLeftProps.onPressItem = this._goBack
        }
        // if (xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PRODUCT_EDIT)) {
        // headerRightProps.label = 'แก้ไข++'
        // headerRightProps.onPressItem = handleChangeToEditMode
        headerRightCustomRender = this.renderHamburgerButton
        // }
        break
      case VIEW_SELLER:
        headerLeftProps.backIcon = true
        headerLeftProps.onPressItem = this._goBack
        break
      case PULL_MY_PRODUCT_TO_ORDER:
        title = 'เลือกสินค้าสู่ออเดอร์'
        headerLeftProps.backIcon = true
        headerLeftProps.onPressItem = this._goBack
        headerRightProps.label = 'ยืนยัน'
        headerRightProps.onPressItem = this._handlePullProductToOrder
        if (outOfStock) {
          headerRightProps.label = 'หมดสต็อก'
          headerRightProps.submitting = true
        }
        break
      case PULL_SELLER_PRODUCT_TO_ORDER:
        title = 'เลือกสินค้าสู่ออเดอร์'
        headerLeftProps.backIcon = true
        headerLeftProps.onPressItem = this._goBack
        headerRightProps.label = 'ยืนยัน'
        headerRightProps.onPressItem = this._handlePullProductToOrder
        if (outOfStock) {
          headerRightProps.label = 'หมดสต็อก'
          headerRightProps.submitting = true
        }
        break
      case PULL_PRODUCT:
        title = 'เลือกสินค้าเข้าร้าน'
        headerLeftProps.backIcon = true
        headerLeftProps.onPressItem = this._goBack
        headerRightProps.label = 'ยืนยัน'
        headerRightProps.onPressItem = this._handlePullProductToMyStore
        break
      case EDIT_SELF:
        title = 'แก้ไขสินค้า'
        headerLeftProps.label = 'บันทึก'
        headerLeftProps.onPressItem = this._handleSubmitEditProduct
        headerRightProps.label = 'ยกเลิก'
        headerRightProps.onPressItem = this._handleCancelEditProduct
        break
      case ADD:
        title = 'เพิ่มสินค้าในร้าน'
        if (!isGoBackButtonHidden) {
          headerLeftProps.backIcon = true
          headerLeftProps.onPressItem = this._goBack
        }
        headerRightProps.label = 'บันทึก'
        headerRightProps.onPressItem = this._handleSubmitAddProduct
        break
      case VIEW_VOLUME_DISCOUNT:
        title = 'สินค้าที่ใช้กับส่วนลดขายส่ง'
        headerLeftProps.backIcon = true
        headerLeftProps.onPressItem = this._goBack
        // headerRightProps.label = 'แก้ไข'
        // headerRightProps.onPressItem = handleChangeToEditMode
        headerRightCustomRender = this.renderHamburgerButton
        break
      default:
        return {
          headerLeft: null,
          title: null,
          headerRight: null,
        }
    }

    return (
      <XCustomHeader
        headerLeftProps={headerLeftProps}
        headerRightProps={!_.isFunction(headerRightCustomRender) ? headerRightProps : null}
        title={title}
        renderHeaderRight={_.isFunction(headerRightCustomRender) ? headerRightCustomRender : null}
      />
    )
    // return (
    //   <Header
    //     iosBarStyle='dark-content'
    //     // style={[{ paddingTop: 0 }, Platform.OS === 'ios' ? {} : { height: 0, marginTop: 0 }]}
    //     style={{
    //       marginTop: isIphoneX() ? getStatusBarHeight() - 4 : undefined,
    //       paddingLeft: 0,
    //       paddingRight: 0,
    //       borderBottomWidth: 0.5,
    //       borderBottomColor: COLORS.TEXT_INACTIVE,
    //     }}>
    //     {/* hasTabs={orderTabsVisibility}> */}
    //     <XStatusBar />
    //     <VStack style={{ flex: 1, minHeight: 40 }}>
    //       <Row>
    //         <VStack style={[INTL_STYLES.colMiddleCenter, { flex: 0, width: 54 }]}>
    //           <NavHeaderButton {...headerLeftProps} />
    //         </VStack>
    //         <VStack size={100} style={INTL_STYLES.colMiddleCenter}>
    //           <XText style={INTL_STYLES.txtTitle}>{title}</XText>
    //         </VStack>
    //         <VStack style={[INTL_STYLES.colMiddleCenter, { flex: 0, width: 54 }]}>
    //           {_.isFunction(headerRightCustomRender) ? headerRightCustomRender() : <NavHeaderButton {...headerRightProps} />}
    //         </VStack>
    //       </Row>
    //     </VStack>
    //   </Header>
    // )
  }

  // _onHamburgerWillShow = async (): Promise<void> => {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   await util.setStatePromise(this, { isHamburgerMenuOpened: true })
  //   this.inProcess = false
  // }

  // _onHamburgerWillHide = async (): Promise<void> => {
  //   await util.setStatePromise(this, { isHamburgerMenuOpened: false })
  // }

  _renderModalDropdownRow = (data: { label: string; isDanger?: boolean; icon?: any; onPress: () => void }): JSX.Element => {
    // log('ModalDropdown renderRow::data => ', data)
    const isDanger = _.has(data, 'isDanger') ? data.isDanger : false
    const icon = _.has(data, 'icon') ? data.icon : null
    // let textColor = COLORS.TEXT_ACTIVE
    // if (isDanger) {
    //   textColor = COLORS.FORM_ERROR
    // }

    // let textPaddingLeft = -2
    // if (_.isNil(icon)) {
    //   textPaddingLeft = 10
    // }
    return (
      <HStack px='1' py='2' space='1' alignItems='center'>
        {_.isNil(icon) ? null : (
          <XIcon
            name={icon.name}
            family={icon.family}
            variant={isDanger ? 'danger' : 'active'}
            // style={{
            //   alignSelf: 'center',
            //   textAlign: 'center',
            //   color: textColor,
            //   fontSize: STYLES.FONT_ICON_SMALLER,
            //   minWidth: 28,
            //   flex: 0,
            //   paddingTop: 1,
            //   // จริงๆ 0 มันกลางแล้ว แต่ปรับขึ้นเป็น 2 เพราะสายตาคนมองเทียบกับอย่างอื่นแล้วมันจึงดูไม่กลาง
            //   paddingBottom: Platform.OS === 'android' ? 2 : 0,
            //   paddingLeft: 2,
            //   paddingRight: 2,
            // }}
          />
        )}
        <XText
          variant={isDanger ? 'danger' : 'active'}
          // allowFontScaling={false}
          // style={{
          //   fontSize: STYLES.FONT_SIZE_LARGER,
          //   color: textColor,
          //   paddingTop: 10,
          //   paddingBottom: 10,
          //   paddingLeft: textPaddingLeft,
          //   paddingRight: 10,
          // }}
        >
          {data.label}
        </XText>
      </HStack>
    )
  }

  _onHamburgerMenuSelect = async (index: number, rowData: any) => {
    if (this.inSelectHamburger) {
      return
    }
    this.inSelectHamburger = true
    // log('ModalDropdown:: onSelect index => ', index)
    // log('_onHamburgerMenuSelect ModalDropdown:: onSelect index => ', index)
    const selectedItem = this.state.hamburgerMenuOptions[index]
    // log('ModalDropdown:: onSelect selectedItem => ', selectedItem)
    if (selectedItem && _.isFunction(selectedItem.onPress)) {
      // MUST!! delay for finished render dropdown before call method
      await new Promise((delayOnPress) => {
        setTimeout(delayOnPress, 200)
      })
      if (!_.isNil(selectedItem.perm)) {
        const perm = xAcl.canDoAtSelectedStore(selectedItem.perm)
        if (!perm) {
          p.op.showConfirmationOkOnly(``, `คุณไม่ได้รับสิทธิ์ในการใช้งานฟีเจอร์นี้`)
          this.inSelectHamburger = false
          // await util.setStatePromise(this, { isHamburgerMenuOpened: false })
          return
        }
      }
      selectedItem.onPress()
      this._afterHamburgerMenuSelectedSafetyClose()
    }
    this.inSelectHamburger = false
    // await util.setStatePromise(this, { isHamburgerMenuOpened: false })
  }

  _afterHamburgerMenuSelectedSafetyClose = () => {
    setTimeout(() => {
      if (this.state.isHamburgerMenuOpened) {
        this.setState({ isHamburgerMenuOpened: false })
      }
    }, 200)
  }

  // async componentDidUpdate(prevProps, prevState): Promise<void> {
  //   const modePath = 'mode'
  //   const prevMode = utilN.getParamFromNav(prevProps.navigation, modePath)
  //   const currMode = utilN.getParamFromNav(this.props.navigation, modePath)
  //   // log('In componentDidUpdate prevMode => ', prevMode, ' currMode => ', currMode)
  //   if (prevMode !== currMode) {
  //     if (currMode === ADD) {
  //       await this._clearImagesInImgMgr()
  //     } else {
  //       await this._loadImagesToImgMgr()
  //     }
  //   }
  //
  //   // ถ้ามีการโหลด product ใหม่ ให้ load imgMgr ใหม่
  //   if (prevProps.selectedProduct !== this.props.selectedProduct) {
  //     await this._loadImagesToImgMgr()
  //   }
  //
  //   await this._updateProductStaticConfigs(prevProps, prevState)
  //   await this._updateProductGroupIds(prevProps, prevState)
  //   // this._updateProductVariantKeysInDidUpdate(prevProps, prevState, prevMode, currMode)
  //   await this._updateProductVariantKeysInDidUpdate(prevProps, prevState)
  // }

  // _revertProductToDefault(callback: () => void) {
  //   const { selectedProduct, revertProduct } = this.props
  //   // if (this.imgBlockRef) {
  //   //   const img_uris = selectedProduct.get('img_uris') || List([])
  //   //   const thumbnail_uris = selectedProduct.get('thumbnail_uris') || List([])
  //   //   const tiny_img_uris = selectedProduct.get('tiny_img_uris') || List([])
  //   //   this.imgBlockRef.loadImages({ images: img_uris, thumbnails: thumbnail_uris, tinys: tiny_img_uris })
  //   // }
  //   revertProduct(callback)
  // }

  _handleCancelEditProduct = async (): Promise<void> => {
    const { selectedProduct, revertProduct } = this.props
    // const { mode } = this.state
    // if (this.imgBlockRef) {
    //   const img_uris = selectedProduct.get('img_uris') || List([])
    //   const thumbnail_uris = selectedProduct.get('thumbnail_uris') || List([])
    //   const tiny_img_uris = selectedProduct.get('tiny_img_uris') || List([])
    //   this.imgBlockRef.loadImages({ images: img_uris, thumbnails: thumbnail_uris, tinys: tiny_img_uris })
    // }
    revertProduct()
    // await this._syncStateAndParams({ mode: VIEW_SELF })
    // await new Promise((delayForRevert) => setTimeout(delayForRevert, 200))
    await util.delay(100)
    this.inputRefs = []
    await util.setStatePromise(this, { mode: VIEW_SELF })
    await util.delay(200)
  }

  // // abstract method
  // _getParams() {
  //   const { navigation } = this.props
  //   const { state } = navigation
  //   if (state.params) {
  //     return state.params
  //   }
  //   return {}
  // }

  // _extendComponentWillReceiveProps(nextProps) {
  //   const { pp, onChangeProduct, editingProduct, selectedProduct } = nextProps
  //   if (this.isUnmounting) {
  //     return
  //   }
  //
  //   const modePath = 'mode'
  //   const currMode = utilN.getParamFromNav(this.props.navigation, modePath)
  //   const nextMode = utilN.getParamFromNav(nextProps.navigation, modePath)
  //   // log('In componentDidUpdate prevMode => ', prevMode, ' currMode => ', currMode)
  //   if (nextMode !== currMode) {
  //     this._computeEditable(nextMode)
  //   }
  //
  //   // log('this.imgBlockRef => ', this.imgBlockRef)
  //   // TODO: สร้าง PagePicker ที่เป็น Callback ขึ้นมาใหมา (อาจจะเป็น ModalPicker?) จะได้ smooth ขึ้น ไม่ต้องมายุ่งกับ redux
  //   if (Map.isMap(this.props.pp) && Map.isMap(pp) && pp.get(xCONS.PRODUCT_CATEGORY_KEY)) {
  //     // log('___pp.get(xCONS.PRODUCT_CATEGORY_KEY)_: ', pp.get(xCONS.PRODUCT_CATEGORY_KEY))
  //     if (this.props.pp.get(xCONS.PRODUCT_CATEGORY_KEY) !== pp.get(xCONS.PRODUCT_CATEGORY_KEY)) {
  //       const category_id = pp.getIn([xCONS.PRODUCT_CATEGORY_KEY, 'value', 'id'])
  //       if (category_id) {
  //         // log('_BaseProductView_componentWillReceiveProps___pp_value: ', pp.get(xCONS.PRODUCT_CATEGORY_KEY))
  //         // onChangeProduct(Map({ category_id: pp.getIn([xCONS.PRODUCT_CATEGORY_KEY, 'value', 'id']) }))
  //         p.op.setAppDefaultValue(this.DEFAULT_PRODUCT_CATEGORY_KEY, category_id)
  //         onChangeProduct({ key: 'category_id', value: category_id })
  //       }
  //     }
  //   }
  //   const currentVSize = this.props.editingProduct.get('variants').size
  //   const nextVSize = editingProduct.get('variants').size
  //   if (this.props.selectedProduct === selectedProduct && currentVSize !== nextVSize) {
  //     this.inputRefs = [] // Reset refs if variants size was changed
  //     // if (currentVSize === 1 && nextVSize === 2) {
  //     //   setTimeout(() => util.focusKeyRefIfExisted(this.inputRefs, 'variant_0_name', this.scrollViewRef)
  //     //     , 500)
  //     // } else if (nextVSize > currentVSize) {
  //     //   setTimeout(() => util.focusKeyRefIfExisted(this.inputRefs, `variant_${currentVSize}_name`, this.scrollViewRef)
  //     //     , 500)
  //     // }
  //   }
  //
  //   // ถ้ามีการกรอกชื่อครั้งแรก reset input refs
  //   if (this.props.selectedProduct === selectedProduct && !this.props.editingProduct.get(NAME) && editingProduct.get(NAME)) {
  //     this.inputRefs = [] // Reset refs if variants size was changed
  //     this.forceUpdate()
  //   }
  //
  //   if (this.props.selectedProduct !== selectedProduct) { // ถ้ามีการโหลด product ใหม่ ให้ focus input แรก
  //     this.inputRefs = [] // Reset refs selectedProduct was changed
  //     // const { mode } = this._getParams()
  //     // if (mode === ADD) {
  //     //   // setTimeout(() => util.focusFirstRefIfExisted(this.inputRefs, this.scrollViewRef)
  //     //   //   , 200)
  //     // }
  //   }
  //
  //   // // setup initialImages for ImgBlock
  //   // if (this.props.editingProduct && editingProduct && isImmutable(editingProduct) &&
  //   //   this.props.editingProduct.get('img_uris') !== editingProduct.get('img_uris')) {
  //   //   const img_uris = isImmutable(editingProduct.get('img_uris')) ? editingProduct.get('img_uris').toJS() : editingProduct.get('img_uris')
  //   //   const thumbnail_uris = isImmutable(editingProduct.get('thumbnail_uris')) ? editingProduct.get('thumbnail_uris').toJS() : editingProduct.get('thumbnail_uris')
  //   //   const tiny_img_uris = isImmutable(editingProduct.get('tiny_img_uris')) ? editingProduct.get('tiny_img_uris').toJS() : editingProduct.get('tiny_img_uris')
  //   //   // this.imgBlockRef.loadImages({ images: img_uris, thumbnails: thumbnail_uris, tinys: tiny_img_uris })
  //   //   this.initialImagesImgBlock = { images: img_uris, thumbnails: thumbnail_uris, tinys: tiny_img_uris }
  //   // }
  // }

  _extendedWillUnmount() {
    // if (this.imgBlockRef) {
    //   this.imgBlockRef.clearState()
    // }
  }

  _handleAfterAlertMissingProductFields(fieldKey) {
    // util.focusKeyRefIfExisted(this.inputRefs, fieldKey, this.scrollViewRef)
    // () => this.scrollViewRef.scrollToPosition(0, 0, true)
  }

  _handleAfterAlertMissingInitQty(shippingTypeIdInt) {
    // util.focusKeyRefIfExisted(this.inputRefs, `shippingRate_${shippingTypeIdInt}_init_qty`, this.scrollViewRef)
    // () => this.scrollViewRef.scrollToPosition(0, 0, true)
  }

  _handleAfterAlertMissingInitPrice(shippingTypeIdInt) {
    // util.focusKeyRefIfExisted(this.inputRefs, `shippingRate_${shippingTypeIdInt}_init_price`,this.scrollViewRef)
    // () => this.scrollViewRef.scrollToPosition(0, 0, true)
  }

  _handleAfterAlertMissingNextPrice(shippingTypeIdInt) {
    // util.focusKeyRefIfExisted(this.inputRefs, `shippingRate_${shippingTypeIdInt}_next_price`, this.scrollViewRefthis.scrollViewRef)
    // () => this.scrollViewRef.scrollToPosition(0, 0, true)
  }

  _handleAfterAlertMissingProductVariant(variantKey, variantIndex) {
    console.log(`_handleAfterAlertMissingProductVariant variantKey=${variantKey}, variantIndex=${variantIndex}`)
    // util.focusKeyRefIfExisted(this.inputRefs, `variant_${variantIndex}_${variantKey}`, this.scrollViewRef)
  }

  _handleAfterAlertDuplicateVariantName(lastVariantIndex) {
    // util.focusKeyRefIfExisted(this.inputRefs, `variant_${lastVariantIndex}_name`, this.scrollViewRef)
  }

  _handleAfterAlertFormIsNotFullfill() {
    // util.focusKeyRefIfExisted(this.inputRefs, 'variant_0_cost', this.scrollViewRef)
  }

  _handleAfterPullProductToOrder(res: Response, mode: string) {
    const { navigation } = this.props
    const onSuccessPullMyProductToOrderCallback = util.getNavParam(this.props, 'onSuccessPullMyProductToOrderCallback')
    const onSuccessPullSellersProductToOrderCallback = util.getNavParam(this.props, 'onSuccessPullSellersProductToOrderCallback')

    if (_.isFunction(onSuccessPullMyProductToOrderCallback)) {
      onSuccessPullMyProductToOrderCallback()
      return
    }

    if (_.isFunction(onSuccessPullSellersProductToOrderCallback)) {
      onSuccessPullSellersProductToOrderCallback()
      return
    }

    if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
      const popTime = p.op.isWeb() ? 1 : 2
      setTimeout(() => navigation.pop(popTime), 1000)
    }
  }

  async _handleAfterDeleteProduct(): Promise<void> {
    util.navGoBack(this.props)
  }

  async _handleAfterAddProduct(): Promise<void> {
    const { selectedProduct } = this.props
    // await this._syncStateAndParams({
    //   product_id: selectedProduct.get('id'),
    //   mode: VIEW_SELF,
    //   showUploaded: false,
    // })
    this.inputRefs = []
    await util.setStatePromise(this, { product_id: selectedProduct.get('id'), mode: VIEW_SELF, showUploaded: false })

    // if (res.product) {
    //   await util.setStatePromise(this,{ showUploaded: false })
    //   if (res.product.id) {
    //     await new Promise(resolveSetParams => {
    //       this.props.navigation.setParams({ product_id: res.product.id })
    //       resolveSetParams()
    //     })
    //   }
    // }
    //
    // return new Promise<null>(resolve => {
    //   this._changeModeTo({ mode: VIEW_SELF,
    //     callback: () => {
    //       // this.inProcess = false
    //       setTimeout(resolve, 200)
    //     }
    //   })
    // })
  }

  async _handleAfterEditProduct(): Promise<void> {
    const { selectedProduct } = this.props
    // await this._syncStateAndParams({
    //   product_id: selectedProduct.get('id'),
    //   mode: VIEW_SELF,
    //   showUploaded: false,
    // })
    this.inputRefs = []
    await util.setStatePromise(this, { product_id: selectedProduct.get('id'), mode: VIEW_SELF, showUploaded: false })
  }

  async _handleAfterPullProductToMyStore(): Promise<void> {
    util.navGoBack(this.props)
    // navigation.pop(1)
  }

  async _handleAfterFetchedProduct(): Promise<void> {
    await util.delay(50)
    // log('ProductView :: _handleAfterFetchProduct res => ', res)
    if (this.state.showUploaded) {
      await util.setStatePromise(this, { showUploaded: false })
    }

    const { selectedProduct, navigation } = this.props
    const { mode, outOfStock = false } = this.state
    // ถ้าสินค้าหมดสต็อก ไม่ควรจะดึงเข้าออเดอร์ได้
    if (_.includes([PULL_SELLER_PRODUCT_TO_ORDER, PULL_MY_PRODUCT_TO_ORDER], mode)) {
      const productVariants = selectedProduct.get('variants') || List([])
      let hasAvailableQty = false
      for (let i = 0; i < productVariants.size; i++) {
        const currVariant = productVariants.get(i) || Map({})
        const availQty = currVariant.get(AVAILABLE_QTY) || 0
        // console.log('_handleAfterFetchedProduct hasAvailableQty => ', hasAvailableQty)
        // console.log('_handleAfterFetchedProduct availQty => ', availQty)
        if (availQty > 0 || util.isSkipCheckEmptyProductStock()) {
          hasAvailableQty = true
          break
        }
      }
      if (!outOfStock && !hasAvailableQty) {
        // navigation.setParams({ outOfStock: true })
        await util.setStatePromise(this, { outOfStock: true })
      } else if (outOfStock && hasAvailableQty) {
        await util.setStatePromise(this, { outOfStock: false })
      }
    }

    // await this._loadImagesToImgMgr()
  }

  async _beginUploading(): Promise<void> {
    // await this._syncStateAndParams({ uploading: true, showUploaded: true })
    // await new Promise((delay) => setTimeout(delay, 100))
    await util.setStatePromise(this, { uploading: true, showUploaded: true })
    await util.delay(100)
  }

  async _endUploading(): Promise<void> {
    // await this._syncStateAndParams({ uploading: false })
    // await new Promise((delay) => setTimeout(delay, 100))
    await util.setStatePromise(this, { uploading: false })
    await util.delay(100)
  }

  async _beginSubmitting(): Promise<void> {
    this.inProcess = true
    // await this._syncStateAndParams({ submitting: true })
    // await new Promise((delay) => setTimeout(delay, 100))
    await util.setStatePromise(this, { submitting: true })
    await util.delay(100)
  }

  async _endSubmitting(): Promise<void> {
    // await this._syncStateAndParams({ submitting: false })
    // await new Promise((delay) => setTimeout(delay, 100))
    await util.setStatePromise(this, { submitting: false })
    await util.delay(100)
    this.inProcess = false
  }

  _goBack = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return
    }
    util.navGoBack(this.props)
  }

  async _initializeComponentDidMountExtended(): Promise<void> {
    const { navigation, revertProduct } = this.props
    // const { setParams } = navigation
    // const { mode } = this.state
    // setParams({
    //   handleSave: this._handleSubmitAddProduct,
    //   handleEdit: this._handleSubmitEditProduct,
    //   handleCancelEditProduct: this._handleCancelEditProduct,
    //   handlePullProductToOrder: this._handlePullProductToOrder,
    //   handlePullProductToMyStore: this._handlePullProductToMyStore,
    //   // syncStateAndParams: this._syncStateAndParams,
    //   handleCancelUploading: this._handleCancelUploading,
    //   // handleGoBack: this._goBack,
    //   handleChangeToEditMode: this._handleChangeToEditMode,

    //   // Web Usage only
    //   // @ts-ignore
    //   onRequestCloseView: this._handleOnRequestCloseStackExpandModal,
    //   // handleSave: this._handleSubmitAddProduct,
    //   // handleEdit: callback => this._handleSubmitEditProduct(callback),
    //   // handleCancel: callback => this._revertProductToDefault(callback),
    //   // handlePullOrder: () => this._pullProductToOrder(),
    //   // handlePullOrder: () => this._pullProductToOrder(),
    //   // handlePullProduct: () => this._pullProductRequest(),
    //   // handleChangeModeTo: ({ mode }) => this._changeModeTo({ mode }),
    //   // handleCancelUploading: () => {
    //   //   this.setState({uploading: false}, () => {
    //   //     setParams({ uploading: false })
    //   //     this.inProcess = false
    //   //   })
    //   // },
    // })
    // await this._initProductGroupIdsOnAddMode()
    // await this._updateProductVariantKeysInDidUpdate()
  }

  _handleOnRequestCloseStackExpandModal = async () => {
    const { mode } = this.state
    if (mode === ADD) {
      const isUserConfirm = await p.op.isUserConfirm('ยังไม่ได้บันทึกสินค้า', 'คุณต้องการละทิ้งการแก้ไขหรือไม่', 'ละทิ้งการแก้ไข', 'ปิด')
      return isUserConfirm
    }
    return true
  }

  async _cannotInitializeExtended(): Promise<void> {
    this._goBack()
    // const {navigation} = this.props
    // const {state, goBack} = navigation
    // goBack(state.key)
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.isUnmounting) {
  //     return false
  //   }
  //   const isPropsDiff = xUtil.isDiff(this.props, nextProps, [
  //     'selectedProduct',
  //     'editingProduct',
  //     'pp',
  //     'imgBlock_status',
  //     'selectedStore',
  //     'sellerStores',
  //     'navigation',
  //   ])
  //
  //   const isStateDiff = xUtil.isDiff(this.state, nextState, [
  //     'inputRefs',
  //     'currentRef',
  //     'previewImageURI',
  //     'previewVisible',
  //     'refreshing',
  //     'uploading',
  //     'submitting',
  //     'showUploaded',
  //     'isInitialized',
  //     'PRODUCT_VARIANT_ITEMS',
  //     'VARIANT_ITEM_PROPERTIES',
  //     'mode',
  //     'store_id',
  //     'product_id',
  //     'seller_store_id',
  //     'order_use_retail_price',
  //   ])
  //   return isPropsDiff || isStateDiff
  // }

  // _syncStateAndParams = async (newState: { [key: string]: any }): Promise<void> => {
  //   const { navigation } = this.props
  //   const { setParams } = navigation
  //   if (_.has(newState, 'mode')) {
  //     this.inputRefs = []
  //   }
  //   setParams(newState)
  //   await util.setStatePromise(this, newState)
  // }

  // _handleChangeToEditMode = async (): Promise<void> => {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   // await this._syncStateAndParams({ mode: EDIT_SELF })
  //   await util.setStatePromise(this, { mode: EDIT_SELF })
  //   this.inProcess = false
  // }

  // _handleCancelUploading = async (newState: { [key: string]: any }): Promise<void> => {
  //   // await this._syncStateAndParams({ uploading: false })
  //   await util.setStatePromise(this, { uploading: false })
  //   this.inProcess = false
  // }

  // _renderNoVariant = (mode, variants, parent_id, isAddableVariants) => {
  //   // เอาออกเพราะ variant แรกไม่ให้ลบได้
  //   const pp_id = isImmutable(variants) ? variants.getIn([0, 'pp_id']) : null
  //   const variant = variants.get(0)
  //   return (
  //     <View key={'Variant_One'}>
  //       <View style={s.variantGrid}>
  //         <View style={s.variantFullGrid}>
  //           {
  //             this.state.PRODUCT_VARIANT_ITEMS.map((v, index) => {
  //               // log('_renderNoVariant v => ', v)
  //               // log('_renderNoVariant variants.getIn([0, v.key]) => ', variants.getIn([0, v.key]))
  //               // const value = variants.getIn([0, v.key]) ? variants.getIn([0, v.key]).toString() : ''
  //               // if (index === 0 && (mode === ADD || (mode !== ADD && !variant))) {
  //               // ซ่อน variants name ถ้าเป็น create mode หรือ variant มี 1 ตัวและไม่มีค่า name
  //               // return null
  //               // }
  //               return this._renderVariantItem({idx: 0, mode, ...v, variant, pp_id, hasOnlyOneVariant: true})
  //             })
  //           }
  //         </View>
  //       </View>
  //       {this._renderAddVariantButton(isAddableVariants)}
  //     </View>
  //   )
  // }

  // _renderVariants = (mode, variants, parent_id, isAddableVariants) => {
  //   // const { editingProduct } = this.props
  //   let vcount = variants.size - 1
  //   return variants.map((v, i) => {
  //     // log('====variants=====', i, v)
  //     if (i === vcount) {
  //       return this._renderVariant(i, fromJS(v), true, mode, parent_id, isAddableVariants)
  //     }
  //     return this._renderVariant(i, fromJS(v), false, mode, parent_id, isAddableVariants)
  //   })
  //   //.reverse()
  // }

  // _renderVariant = (idx, variant, isLastItem, mode, parent_id, isAddableVariants) => {
  //   // log('_renderVariant variant => ', variant.toJS())
  //   if (_.isNull(variant) || _.isUndefined(variant) || !isImmutable(variant)) {
  //     // App might crash if variant is null. Not sure how would it be possible but just safety
  //     return null
  //   }
  //   const pp_id = variant.get('pp_id') || null
  //   const has_ordered = variant.get('has_ordered') || false
  //   let isRemoveableVariant = false
  //   if (mode === ADD || (isAddableVariants && !has_ordered && _.includes([EDIT_SELF, EDIT_SELLER], mode))) {
  //     isRemoveableVariant = true
  //   }
  //
  //   if (this.state.uploading) {
  //     isRemoveableVariant = false
  //   }
  //
  //   let isEven = (idx % 2) === 0
  //   return (
  //     <View key={`variant_${idx}`}>
  //       <View style={isEven ? s.variantGrid : s.variantGridColor}>
  //         {isRemoveableVariant ? (
  //           <View style={s.variantColLeft}>
  //             <ButtonRemoveProductVariant
  //               variantIndex={idx}
  //               onPressItem={this._onRemoveProductVariant}
  //             />
  //             {/*<TouchableOpacity*/}
  //               {/*onPress={() => this.props.removeProductVariant(idx)}>*/}
  //               {/*<Icon*/}
  //                 {/*name='md-remove-circle'*/}
  //                 {/*style={{fontSize: STYLES.FONT_ICON_LARGER, color: COLORS.FORM_ERROR}}*/}
  //               {/*/>*/}
  //             {/*</TouchableOpacity>*/}
  //           </View>
  //         ) : <View/>
  //         }
  //         <View style={s.variantColRight}>
  //           {MODE === 'dev' ? <XText style={STYLES.TXT_LABEL_SMALLER}>{`ppid_id=${pp_id}`}</XText> : null}
  //           {
  //             this.state.PRODUCT_VARIANT_ITEMS.map(v => {
  //               // const value = variant && isImmutable(variant) && variant.get(v.key)
  //               //   ? variant.get(v.key).toString()
  //               //   : ''
  //               // log('variantColRight v.key, value', v.key, value, pp_id)
  //               return this._renderVariantItem({idx, mode, ...v, variant, pp_id})
  //             })
  //           }
  //         </View>
  //       </View>
  //       {isLastItem ? this._renderAddVariantButton(isAddableVariants) : null}
  //     </View>
  //   )
  // }

  // // TODO: Refactor to PureComponent?
  // _renderVariantItem = ({idx, mode, key, variant, icon, label, placeholder, isNumber, isInteger, isMoney, pp_id, hasOnlyOneVariant = false}) => {
  //   const vProps = this._getComputeVariantItemProperties({
  //     idx,
  //     mode,
  //     key,
  //     variant,
  //     icon,
  //     label,
  //     placeholder,
  //     isNumber,
  //     isInteger,
  //     isMoney,
  //     hasOnlyOneVariant
  //   })
  //   // util.log('_renderVariantItem key => ', key, ' this.isEditableProductVariantsMap => ', this.isEditableProductVariantsMap)
  //   if (!vProps) {
  //     return null
  //   }
  //
  //   return (
  //     <Row key={`VItem_${key}_${idx}`} style={{
  //       paddingTop: 2,
  //       paddingBottom: 2,
  //       // height: 36,
  //       borderBottomWidth: 0,
  //       // borderBottomWidth: 0.5,
  //       // borderBottomColor: COLORS.TEXT_INACTIVE,
  //     }}>
  //       <VStack size={1} style={{justifyContent: 'center'}}>
  //         {
  //           icon === 'scale'
  //             ? <IconMC style={{fontSize: 24, paddingRight: 4}} name={icon}/>
  //             : <Icon name={icon}/>
  //         }
  //       </VStack>
  //       <VStack size={5} style={{justifyContent: 'center'}}>
  //         <XText
  //           numberOfLines={2}
  //           style={{
  //             fontSize: STYLES.FONT_SIZE_NORMAL,
  //             color: COLORS.TEXT_INACTIVE,
  //           }}>
  //           {label}
  //         </XText>
  //       </VStack>
  //       <VStack size={4} style={{justifyContent: 'center'}}>
  //         { //keng
  //           vProps.editable
  //             ? (
  //               <Input
  //                 ref={r => util.pushNativeBaseRef(this.inputRefs, `variant_${idx}_${key}`, r)}
  //                 onFocus={() => this.setState({currentRef: `variant_${idx}_${key}`})}
  //                 keyboardType={isNumber ? 'numeric' : 'default'}
  //                 value={!_.isNull(vProps.value) ? vProps.value.toString() : ''}
  //                 onChangeText={val => this.props.onChangeVariant({idx, key, val, isMoney, isInteger})}
  //                 style={[s.formNormalText, !vProps.editable ? s.txtRight : s.txtHLRight, s.input]}
  //                 maxLength={isNumber ? 9 : 50}
  //                 placeholder={placeholder}
  //                 placeholderTextColor={COLORS.TEXT_INACTIVE}
  //                 disabled={!vProps.editable}
  //                 // returnKeyType='done'
  //                 multiline={false}
  //                 numberOfLines={1}
  //               />
  //             ) : (
  //               <XText style={[s.txtRight, {alignSelf: 'flex-end'}]}>
  //                 {vProps.value}
  //               </XText>
  //             )
  //         }
  //       </VStack>
  //     </Row>
  //   )
  // }

  _handlePressAddVariant = () => {
    if (this.isVariantPageFiltered()) {
      p.op.alert('เพิ่มตัวเลือกสินค้า', 'กรุณาปิดการกรองตัวเลือกสินค้าก่อนกดปุ่ม เพื่อทำการเพิ่มตัวเลือกสินค้า')
      return
    }

    this.props.addProductVariant()
    // if (this.scrollToBottomY && this.scrollViewRef) {
    //   // log('___this.scrollViewRef_: ', this.scrollViewRef)
    //   this.scrollViewRef.scrollToPosition(0, this.scrollToBottomY - 200, true)
    //   // Reference: https://github.com/APSL/react-native-keyboard-aware-scroll-view
    // }

    const lastPage = this.getVariantPageCount()
    const lastPageIndex = lastPage - 1
    const { variantPageIndex } = this.state
    if (variantPageIndex !== lastPageIndex) {
      this.setState({ variantPageIndex: lastPageIndex })
    }
  }

  _renderAddVariantButton = (isAddableVariants) => {
    const { uploading, submitting } = this.state
    const isDisabled = uploading || submitting
    return isAddableVariants ? (
      <XButton disabled={isDisabled} isDisabled={isDisabled} variant='outline' onPress={this._handlePressAddVariant}>
        เพิ่มตัวเลือกสินค้า
      </XButton>
    ) : null
  }

  getVariantContainerMaxWidth = () => undefined

  _renderVariantPageHeader = () => {
    const { isVariantPageFilterVisible = false, isVariantPageFiltered = false, variantPageFilterOptions } = this.state
    const variantCount = this.getVariantCount()

    return (
      <VStack w='full' py='2' space='1'>
        <HStack w='full' alignItems='center'>
          {/* <Box w='100px' /> */}
          <HStack flex={1} alignItems='center'>
            <XText variant='inactive'>{'มีทั้งหมด '}</XText>
            <XText variant='inactive' bold>
              {variantCount}
            </XText>
            <XText variant='inactive'>{' ตัวเลือก'}</XText>
          </HStack>
          {variantCount > 5 && isVariantPageFiltered ? (
            <XButton
              w='112px'
              variant='outline'
              leftIcon={<XIcon name='filter-remove' family='MaterialCommunityIcons' />}
              onPress={this.removeVariantFilter}>
              ปิดกรอง
            </XButton>
          ) : null}
          {variantCount > 5 && !isVariantPageFiltered ? (
            <XButton w='112px' leftIcon={<XIcon name='filter' family='MaterialCommunityIcons' />} onPress={this.openVariantFilter}>
              กรอง
            </XButton>
          ) : null}
        </HStack>
      </VStack>
    )
  }

  _renderVariantPageFilterInfo = () => {
    const { variantPageFilterOptions } = this.state
    const itemCount = this.getVariantPageItemCount()

    return this.isVariantPageFiltered() ? (
      <HStack w='full' py='1' alignItems='center' justifyContent='center'>
        {itemCount === 0 ? <XText variant='inactive'>ไม่พบตัวเลือกใด ตรงกับการค้นหา "</XText> : null}
        {itemCount > 0 ? (
          <>
            <XText variant='inactive'>{'พบ '}</XText>
            <XText variant='inactive'>{this.getVariantPageItemCount()}</XText>
            <XText variant='inactive'>{' ตัวเลือก จากการค้นหา '}</XText>
          </>
        ) : null}
        <XText>{`"${variantPageFilterOptions.keyword}"`}</XText>
      </HStack>
    ) : null
  }

  // _renderVariantPageFilterOverlay = () => {
  //   const {
  //     isVariantPageFilterVisible = false,
  //     isVariantPageFilterNAME = true,
  //     isVariantPageFilterSKU = true,
  //     isVariantPageFilterUPC = true,
  //     variantPageFilterKeyword = '',
  //   } = this.state

  //   return (
  //     <XOverlay
  //       visible={isVariantPageFilterVisible}
  //       onRequestClose={() => {
  //         this.setState({ isVariantPageFilterVisible: false })
  //       }}>
  //       <XCustomHeader
  //         title='กรองตัวเลือกสินค้า'
  //         headerRightProps={{
  //           closeIcon: true,
  //           onPressItem: () => {
  //             this.setState({ isVariantPageFilterVisible: false })
  //           },
  //         }}
  //       />
  //       <VStack w='full' p='2' space='1'>
  //         <VStack w='full' space='0.5'>
  //           <XText variant='inactive'>ค้นหาใน...</XText>
  //           <HStack w='full' space='1'>
  //             <XCheckButton
  //               label='ชื่อ'
  //               checked={isVariantPageFilterNAME}
  //               onPress={(arg) => {
  //                 this.setState({ isVariantPageFilterNAME: !arg.checked })
  //               }}
  //             />
  //             <XCheckButton
  //               label='SKU'
  //               checked={isVariantPageFilterSKU}
  //               onPress={(arg) => {
  //                 this.setState({ isVariantPageFilterSKU: !arg.checked })
  //               }}
  //             />
  //             <XCheckButton
  //               label='UPC'
  //               checked={isVariantPageFilterUPC}
  //               onPress={(arg) => {
  //                 this.setState({ isVariantPageFilterUPC: !arg.checked })
  //               }}
  //             />
  //           </HStack>
  //         </VStack>

  //         <HStack w='full' space='1' alignItems='center'>
  //           <XText w='80px' variant='inactive'>
  //             {'คำค้นหา '}
  //           </XText>
  //           <XInput
  //             flex={1}
  //             placeholder='เช่น สีแดง'
  //             value={variantPageFilterKeyword}
  //             onChangeText={(newText) => this.setState({ variantPageFilterKeyword: newText })}
  //           />
  //         </HStack>

  //         <XButton mt='2'>ค้นหา</XButton>
  //       </VStack>
  //     </XOverlay>
  //   )
  // }

  _renderVariantPageControl = () => {
    const { isInitialized, variantPageIndex = 0 } = this.state
    if (!isInitialized) {
      return null
    }

    const currentPage = variantPageIndex + 1
    const pageCount = this.getVariantPageCount()
    // const itemCount = this.getVariantPageItemCount()
    // const minIdx = this.getVariantPageMinIndex()
    // const maxIdx = this.getVariantPageMaxIndex()

    if (pageCount <= 1) {
      return null
    }

    const isVariantImageAllUploaded = this._isVariantImageAllUploaded()

    return (
      <VStack w='full' py='2' space='1'>
        <HStack w='full' space='1'>
          <XIconButton
            isDisabled={!isVariantImageAllUploaded || variantPageIndex === 0}
            disabled={!isVariantImageAllUploaded || variantPageIndex === 0}
            name='chevron-double-left'
            family='MaterialCommunityIcons'
            variant='outline'
            borderRadius='full'
            onPress={this.onPressVariantPagePrev5}
          />

          <XIconButton
            isDisabled={!isVariantImageAllUploaded || variantPageIndex === 0}
            disabled={!isVariantImageAllUploaded || variantPageIndex === 0}
            name='chevron-left'
            family='MaterialCommunityIcons'
            variant='outline'
            borderRadius='full'
            onPress={this.onPressVariantPagePrev}
          />

          <HStack flex={1} alignItems='center' justifyContent='center'>
            <XText variant='inactive'>{'หน้า '}</XText>
            <XText>{currentPage}</XText>
            <XText variant='inactive'>{` / ${pageCount}`}</XText>
          </HStack>

          <XIconButton
            isDisabled={!isVariantImageAllUploaded || currentPage === pageCount}
            disabled={!isVariantImageAllUploaded || currentPage === pageCount}
            name='chevron-right'
            family='MaterialCommunityIcons'
            variant='outline'
            borderRadius='full'
            onPress={this.onPressVariantPageNext}
          />

          <XIconButton
            isDisabled={!isVariantImageAllUploaded || currentPage === pageCount}
            disabled={!isVariantImageAllUploaded || currentPage === pageCount}
            name='chevron-double-right'
            family='MaterialCommunityIcons'
            variant='outline'
            borderRadius='full'
            onPress={this.onPressVariantPageNext5}
          />
        </HStack>
      </VStack>
    )
  }

  _renderProductVariants = (): JSX.Element | null => {
    const { editingProduct } = this.props
    const { mode, isInitialized, variantPageIndex = 0, isVariantPageFilterVisible, filteredVariantIndexes, filteredVariants } = this.state
    if (!Map.isMap(editingProduct) || !_.includes(xCONS.PRODUCT_VIEW_MODE, mode)) {
      return null
    }

    // // const shouldNamedProductInAddMode = this._shouldNamedProductInAddMode()
    // // if (shouldNamedProductInAddMode) {
    // //   return (
    // //     <XText allowFontScaling={false} style={s.txtNoProductNameInfo}>
    // //       {'กรุณาระบุ "ชื่อสินค้า" ด้านบน ก่อนที่จะระบุข้อมูลเพิ่มเติมด้านล่างนี้...'}
    // //     </XText>
    // //   )
    // // }

    // // const parent_id = editingProduct.get('parent_id') || null
    // const variants = editingProduct.get('variants') || List([])
    // const variantCount = variants.size || 0

    // // const product_group_ids = editingProduct.get('product_group_ids') || List([])

    // // Do not use in Create Mode
    // // const has_parent = editingProduct.get('parent_id') || false
    // // // const has_child = editingProduct.get('has_child') || false
    // //
    // // let isAddableVariants = false
    // // // if (mode === ADD || (!has_child && !has_parent && _.includes([EDIT_SELF, EDIT_SELLER], mode))) {
    // // if (mode === ADD || (!has_parent && _.includes([EDIT_SELF, EDIT_SELLER], mode))) {
    // //   isAddableVariants = true
    // // }
    // // const variantPropertyCount = this.state.PRODUCT_VARIANT_ITEMS.length

    const isAddableVariants = this._isAddableVariants()
    if (!this.state.PRODUCT_VARIANT_ITEMS) {
      return null
    }

    const variants = this.getVariantPageItems()
    const variantCount = this.getVariantCount()
    const pageCount = this.getVariantPageCount()
    // const minIdx = this.getVariantPageMinIndex()
    // const maxIdx = this.getVariantPageMaxIndex()

    if (this.isVariantPageFiltered()) {
      return (
        <VStack key={`Filtered_${pageCount}_${variantPageIndex}`} w='full'>
          {this._renderVariantPageHeader()}

          {this._renderVariantPageFilterInfo()}

          {this._renderVariantPageControl()}

          <VStack w='full' space='1.5'>
            {filteredVariants.map((variant, filteredIndex) => {
              if (!this.isInVariantPageRange(filteredIndex)) {
                return null
              }
              const variantIndex = filteredVariantIndexes[filteredIndex]
              return this._renderVariantContainer(variantIndex, variantCount)
            })}
          </VStack>

          {pageCount > 1 ? this._renderVariantPageFilterInfo() : null}

          {this._renderVariantPageControl()}

          {pageCount > 1 ? this._renderVariantPageHeader() : null}

          <HStack w='full' minH='9' pt='2' pb='3' alignItems='center' justifyContent='center'>
            {this._renderAddVariantButton(isAddableVariants)}
          </HStack>
        </VStack>
      )
    }

    return (
      <VStack key={`Variant_${pageCount}_${variantPageIndex}`} w='full'>
        {this._renderVariantPageHeader()}

        {this._renderVariantPageControl()}

        <VStack w='full' space='1.5'>
          {variants.map((variant, variantIndex) => {
            if (!this.isInVariantPageRange(variantIndex)) {
              return null
            }

            return this._renderVariantContainer(variantIndex, variantCount)
          })}
        </VStack>

        {/* <FlatList
          key={`${mode}_${isInitialized}`}
          style={{ height: 600, width: '100%', borderWidth: 1, borderColor: 'red' }}
          keyExtractor={this._keyExtractorForVariants}
          // style={{ width: '100%' }}
          // scrollEnabled={false}
          extraData={editingProduct}
          removeClippedSubviews={true}
          data={variants.toArray()}
          renderItem={this._renderAdapterVariantItem}
        /> */}

        {pageCount > 1 ? this._renderVariantPageFilterInfo() : null}

        {this._renderVariantPageControl()}

        {pageCount > 1 ? this._renderVariantPageHeader() : null}

        <HStack w='full' minH='9' pt='2' pb='3' alignItems='center' justifyContent='center'>
          {this._renderAddVariantButton(isAddableVariants)}
        </HStack>

        <VariantSearchPanel
          visible={isVariantPageFilterVisible}
          onRequestClose={this.closeVariantFilter}
          onSubmit={this.submitVariantPageFilter}
        />
      </VStack>
    )
    // return (
    //   <VStack style={{ flex: 1, paddingHorizontal: 6, maxWidth: this.getVariantContainerMaxWidth() }}>
    //     <HStack style={{ maxWidth: this.getVariantContainerMaxWidth() }}>
    //       <VStack>{variants.map((variant, variantIndex) => this._renderVariantContainer(variantIndex))}</VStack>
    //     </HStack>
    //     <HStack>{this._renderAddVariantButton(isAddableVariants)}</HStack>
    //   </VStack>
    // )
  }

  _renderAdapterVariantItem = (info) => {
    const { item, index } = info

    const { editingProduct } = this.props
    const variants = editingProduct.get('variants') || List([])
    const variantCount = variants.size || 0

    return this._renderVariantContainer(index, variantCount)
  }

  _keyExtractorForVariants = (item, index) => index.toString()

  _renderVariantContainer = (variantIndex: number, variantCount: number) => {
    const isRemoveableVariant = this._isRemoveableVariant(variantIndex)
    const isDeletedVariant = this._isDeletedVariant(variantIndex)
    const isOdd = variantIndex % 2 === 0
    // const variantBgColor = variantIndex % 2 === 0 ? 'white' : COLORS.BG_LIGHT_GREY
    const { submitting, uploading } = this.state
    const isDisabled = submitting || uploading

    let lBg = isOdd ? 'transparent' : 'gray.50'
    let dBg = isOdd ? 'transparent' : 'dark.50'
    if (isDeletedVariant) {
      lBg = 'gray.200'
      dBg = 'gray.200'
    }

    return (
      <HStack
        // flex={1}
        w='full'
        key={variantIndex.toString()}
        bg={lBg}
        _dark={{ bg: dBg }}
        borderWidth='1'
        borderColor='muted.200'
        borderRadius='lg'
        p='1'
        // borderTopWidth={variantIndex === 0 ? '0' : '1'}
        // borderTopColor='muted.400'
        // style={{
        //   backgroundColor: variantBgColor,
        //   // paddingVertical: 10,
        //   // paddingHorizontal: p.op.isWeb() ? 0 : 6,
        //   // maxWidth: this.getVariantContainerMaxWidth(),
        //   // flexBasis: 'auto',
        //   // flexWrap: 'wrap',
        // }}
      >
        {/* {isRemoveableVariant ? (
          <Center px='1'>
            <ButtonRemoveProductVariant disabled={isDisabled} variantIndex={variantIndex} onPressItem={this._onRemoveProductVariant} />
          </Center>
        ) : null} */}
        <VStack
          w='full'
          py='3'
          // flex={1}
        >
          {variantCount > 1 ? (
            <HStack w='full' minH='10' p='1' alignItems='center' justifyContent='center'>
              {isRemoveableVariant && !isDeletedVariant ? (
                <ButtonRemoveProductVariant
                  disabled={isDisabled}
                  // isDeleted={isDeletedVariant}
                  variantIndex={variantIndex}
                  onPressItem={this._onRemoveProductVariant}
                  // onRemovePressItem={this._onRemoveProductVariant}
                  // onUndoRemovePressItem={this._onUndoRemoveProductVariant}
                />
              ) : (
                <Box w='7' />
              )}
              <XText flex={1} textAlign='center' variant='inactive' bold>{`ตัวเลือกที่ ${variantIndex + 1}`}</XText>
              <Box w='7' />
            </HStack>
          ) : null}
          {isDeletedVariant ? (
            <HStack w='full' minH='9' px='2' py='1.5'>
              <VStack flex={1} py='1' bg='gray.100' borderRadius='lg' alignItems='center' justifyContent='center' space='1'>
                <XText>ตัวเลือกนี้จะถูกลบออกเมื่อกดบันทึกเสร็จสิ้น</XText>
                <XButton
                  variant='outline'
                  onPress={() => {
                    this._onUndoRemoveProductVariant(variantIndex)
                  }}>
                  ยกเลิกการลบ
                </XButton>
              </VStack>
            </HStack>
          ) : null}

          {this._renderProductVariantImage(variantIndex)}

          {this.state.PRODUCT_VARIANT_ITEMS.map((variantProperty, itemIndex) =>
            this._renderComputedVariantItem({
              variantIndex,
              itemIndex,
              item: variantProperty,
            })
          )}

          {this._renderMkpInfoWithVariantIndex(variantIndex)}
          {this._renderMkpLinkButtonWithVariantIndex(variantIndex)}
        </VStack>
        {this._renderVariantIdLabelVariantIndex(variantIndex)}
      </HStack>
    )
  }

  _renderComputedVariantItem = ({ item, variantIndex, itemIndex }) => {
    const { editingProduct } = this.props
    const { submitting, uploading, mode } = this.state
    const isDeletedVariant = this._isDeletedVariant(variantIndex)

    const parentId = editingProduct.get('parent_id')
    const variants = editingProduct.get('variants') || Map({})
    const { key, label, additionalLabel, icon, placeholder, isMoney, isInteger, isNumber, isBarcode, iconFamily } = item
    if (variantIndex === 0 && key === NAME && variants.size === 1) {
      return null
    }
    const ppId = variants.getIn([variantIndex, 'pp_id'])
    let isEditable
    let isReservedProductButtonVisible = false
    const keys = key.split('_')
    if (keys.length > 1) {
      isEditable = this.isEditableProductVariantsMap.get(keys[0])
    } else {
      isEditable = this.isEditableProductVariantsMap.get(key)
    }
    isEditable = isEditable && !submitting && !uploading && !isDeletedVariant

    // ถ้าเป็น UPC และเป็นสินค้าที่ถูกดึงมา จะไม่ให้แก้ไข UPC
    if (key === UPC && parentId) {
      isEditable = false
    }
    const s_use_product_barcode = this.props.selectedStore.get('s_use_product_barcode')
    // ถ้า UPC ถูกปิดใช้งานอยู่ ก็จะไม่แสดง
    if ((key === UPC && s_use_product_barcode === 0) || (key === UPC && s_use_product_barcode === 2)) {
      return null
    }
    // console.log('key => ', key)
    let value = variants.getIn([variantIndex, key]) || ''

    if ((key.includes(QTY) || key === WEIGHT) && value === '' && _.includes([VIEW_SELF, VIEW_SELLER], mode) && !isEditable) {
      value = '0'
    }

    // แก้ปัญหา "ไม่มีรายการราคา" แล้วจะหยิบสินค้าเข้าตะกร้าไม่ได้ จึงปรับราคาเป็น 0 แทน undefined
    // ref https://app.clickup.com/t/3b44rz5
    // if (key.startsWith(PRICE) && value === '' && _.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_MY_PRODUCT_TO_ORDER], mode) && !isEditable) {
    //   value = '0'
    // }

    // ปรับแก้ไขเพื่อป้องกันการเปลี่ยนแปลงราคาเป็นค่าว่าง https://app.clickup.com/t/86cxwdkkp
    if (key.startsWith(PRICE) && value === '') {
      value = '0'
    }

    if (key === QTY && _.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
      const currentAvailableQty = variants.getIn([variantIndex, AVAILABLE_QTY]) || 0
      if (!util.isSkipCheckEmptyProductStock() && currentAvailableQty <= 0) {
        isEditable = false
        value = 0
      }
    }

    if (_.includes([UPC, SKU], key) && (_.isNil(value) || value === '')) {
      // ถ้าไม่ใช่โหมดแก้ไข แล้ว Barcode เป็นค่าว่างจะซ่อน หรือ ไม่มีสิทธิ์แก้ไขก็จะซ่อน
      if (!_.includes([ADD, EDIT_SELF], mode) || !isEditable) {
        return null
      }
    }

    if (key === AVAILABLE_QTY && !isEditable) {
      const currentQty = variants.getIn([variantIndex, QTY]) || 0
      const currentAvailableQty = variants.getIn([variantIndex, AVAILABLE_QTY]) || 0
      const isVisibleByMode = _.includes([VIEW_SELF], mode)
      if (isVisibleByMode && currentAvailableQty !== currentQty && _.isNil(parentId)) {
        isReservedProductButtonVisible = true
      }
    }

    return this.renderProductVariantItem({
      key: `v${variantIndex}${itemIndex}_${key}`,
      // variantIndex: idx,
      variantIndex,
      variantKey: key,
      onInputChange: this._handleOnChangeVariant,
      isEditable,
      label,
      additionalLabel,
      icon,
      iconFamily,
      value,
      placeholder,
      isMoney,
      isNumber,
      isInteger,
      isBarcode,
      onPressNavToReservedProductInOrders:
        _.isNil(ppId) || !isReservedProductButtonVisible ? null : () => this.doNavToReservedProductsOrderListView([ppId]),
      // enableTopSeparator: enableTopSeparator,
      // extraData: product_group_ids,
      // extraData: typingCount,
    })

    // // const { mode } = this.state
    // // const txtValue = editingProduct.getIn(['variants', idx, key]) || ''
    // // const has_ordered = editingProduct.getIn(['variants', idx, 'has_ordered']) || false
    // // const value = editingProduct.getIn(['variants', variantIndex, key]) || ''
    // // const typingCount = editingProduct.getIn(['variants', variantIndex, `typingCount_${key}`]) || 0
    // // const product_group_ids = this.props.editingProduct.get('product_group_ids') || List([])
    // // const variantTemplateCount = this.state.PRODUCT_VARIANT_ITEMS.length
    // // const enableTopSeparator = (index > 0) && ((index % variantTemplateCount) === 0)
    // // log(`_renderComputedVariantItem variantIndex=${variantIndex} key=${key} typingCount => `, typingCount)
    // return (
    //   <ProductVariantItem
    //     // key={`v_${key}_${index}`}
    //     key={`v${variantIndex}${itemIndex}_${key}`}
    //     // variantIndex={idx}
    //     variantIndex={variantIndex}
    //     variantKey={key}
    //     onInputChange={this._handleOnChangeVariant}
    //     isEditable={isEditable}
    //     label={label}
    //     additionalLabel={additionalLabel}
    //     icon={icon}
    //     iconFamily={iconFamily}
    //     value={value}
    //     placeholder={placeholder}
    //     isMoney={isMoney}
    //     isNumber={isNumber}
    //     isInteger={isInteger}
    //     isBarcode={isBarcode}
    //     // enableTopSeparator={enableTopSeparator}
    //     // extraData={product_group_ids}
    //     // extraData={typingCount}
    //   />
    // )
    //
    // // return (
    // //   <Row>
    // //     <VStack>
    // //       <XText>{ label }</XText>
    // //     </VStack>
    // //     <VStack>
    // //       <XInput
    // //         ref={ r => r && (this.refs[`v_${key}_${idx}`] = r) }
    // //         style={{ minHeight: 120 }}
    // //         navKey={ `v_${key}_${idx}` }
    // //         onNavRef={ this._onNavRef }
    // //         onNavFocus={ this._onNavFocus }
    // //         placeholder={ placeholder }
    // //         disabled={!isEditable}
    // //         editable={isEditable}
    // //         isMoney={isMoney}
    // //         // onChangeText={ this._onChangeProducDesc }
    // //         value={ txtValue }
    // //         onBlur={() => {
    // //           const ipRef = this.refs[`v_${key}_${idx}`]
    // //           if (ipRef && ipRef.forceUpdate) {
    // //             ipRef.forceUpdate()
    // //           }
    // //         }}
    // //         onChangeText={(val) => this.props.onChangeVariant({ idx, key, val, isMoney, isInteger })}
    // //         numberOfLines={1}
    // //       />
    // //     </VStack>
    // //   </Row>
    // // )
    // // return this._renderTempMap(index, variant)
    // // return this._renderTemp(item)
    //
    // // return (
    // //   <XText>Hello variant {index}</XText>
    // // )
  }

  renderProductVariantItem = (vProps) => <ProductVariantItem key={vProps.key} {...vProps} />

  _renderProductWarehouse = () => {
    const { selectedProduct, editingProduct, selectedStore, navigation } = this.props
    const { mode } = this.state

    // ซ่อน ถ้าเป็นสินค้าที่ถูกดึงมา
    const isNotMyProduct = selectedProduct.get('parent_id')
    if (isNotMyProduct) {
      return null
    }

    // ซ่อน ถ้าอยู่ในโหมดที่ไม่เกี่ยวกับการจัดการสินค้่า
    if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER, PULL_PRODUCT], mode)) {
      return null
    }

    const storeWarehouse = selectedStore.has('warehouses') ? selectedStore.get('warehouses') : null
    // ซ่อน ถ้าหากว่าไม่ได้มีการเปิดใช้ store's warehouse เป็นพิเศษ
    if (storeWarehouse.size < 2) {
      return null
    }

    const isDisabled = !_.includes([ADD, EDIT_SELF, EDIT_SELLER], mode)
    const selectedWarehousesIds = isDisabled ? selectedProduct : editingProduct
    // const category = _.includes([ADD, EDIT_SELF, EDIT_SELLER], mode) ? editingProduct.get(`category`) : selectedProduct.get(`category`)
    // const categoryName = _.isNil(category) ? `ไม่มีหมวดหมู่` : category.get(`name`)
    const productWarehouses = selectedWarehousesIds.has('warehouse_ids') ? selectedWarehousesIds.get('warehouse_ids') : []

    let productWarehousesJS = productWarehouses
    if (isImmutable(productWarehouses)) {
      productWarehousesJS = productWarehouses.toJS()
    }
    if (_.isNil(productWarehousesJS)) {
      return null
    }

    const navToProductWarehousesPicker = () => {
      navigation.dispatch(
        NavActions.navToProductWarehousesPicker({
          warehouses: productWarehousesJS,
          callBackEditWarehouses: this.onChangeProductWarehouseIds,
        })
      )
    }

    return (
      <XCard disabled={isDisabled} onPress={navToProductWarehousesPicker} mt='1'>
        <XCard.Body>
          <HStack flex={1} justifyContent='space-between' alignItems='center' space='1'>
            <VStack flex={1}>
              <XText variant='inactive'>อยู่ในคลัง</XText>

              <HStack flexWrap='wrap'>
                {productWarehousesJS.map((warehousesId: number, index: number) => {
                  const warehousesDetail = util.getWarehousesDetail(storeWarehouse, warehousesId)
                  if (_.isNil(warehousesDetail)) {
                    return null
                  }
                  // const name = _.isNil(warehousesDetail.name) ? 'ไม่ได้ระบุชื่อคลัง' : warehousesDetail.name
                  const name = util.getWarehouseDisplayName(storeWarehouse, warehousesDetail, index)
                  return (
                    <Box key={index.toString()} borderWidth='1' borderColor='primary.200' m='1' p='1'>
                      <XText bold>{name}</XText>
                    </Box>
                  )
                })}
              </HStack>
            </VStack>

            {isDisabled ? null : <XIcon family='AntDesign' name='arrowright' />}
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderCategoryNewVersion = () => {
    const { selectedProduct, editingProduct } = this.props
    const { mode } = this.state
    const disabled = !_.includes([ADD, EDIT_SELF, EDIT_SELLER], mode)
    const category = _.includes([ADD, EDIT_SELF, EDIT_SELLER], mode) ? editingProduct.get(`category`) : selectedProduct.get(`category`)
    const categoryName = _.isNil(category) ? `ไม่มีหมวดหมู่` : category.get(`name`)
    return (
      <XCard mt='1' disabled={disabled} onPress={this._openCategoryList}>
        <XCard.Body>
          <HStack w='full' alignItems='center' space='1'>
            <XText flex={1} variant='inactive'>
              หมวดหมู่สินค้า:
            </XText>
            {disabled || categoryName ? <XText>{categoryName}</XText> : null}
            {disabled ? null : <XIcon name='arrowright' family='AntDesign' />}
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderCategory = () => {
    const { editingProduct } = this.props
    if (!editingProduct || !Map.isMap(editingProduct)) {
      return null
    }

    const { mode, submitting, uploading } = this.state
    const isEditable = _.includes([ADD], mode) && !submitting && !uploading

    const category_id = parseInt(editingProduct.get('category_id')) || 1 // ยังไม่ได้เลือกหมวดหมู่
    const categoryIndex = xCONS.PRODUCT_CATEGORY_ITEMS.findIndex((pcat) => pcat.id === category_id)

    return (
      <XFlatPicker
        disabled={!isEditable}
        title='เลือกประเภทสินค้า'
        // ref={this._shippingTypeCreateModePickerRef}
        selectedIndex={categoryIndex}
        options={xCONS.PRODUCT_CATEGORY_ITEMS}
        onPick={this._onChangeProductCategory}
        renderSelectedValue={this._renderCategorySelectedItem}
        renderItem={this._renderCategoryPickerItem}
      />
    )

    // return (
    //   <ModalPicker
    //     style={{ paddingVertical: 8 }}
    //     disabled={!isEditable}
    //     data={xCONS.PRODUCT_CATEGORY_ITEMS}
    //     label='ประเภทสินค้า'
    //     title='เลือกประเภทสินค้า'
    //     selectedIndex={categoryIndex}
    //     onPick={this._onChangeProductCategory}
    //   />
    // )

    // const { editingProduct } = this.props
    // const category_id = editingProduct.get('category_id') || 1 // ยังไม่ได้เลือกหมวดหมู่
    // let category = xCONS.PRODUCT_CATEGORY_ITEMS.find(elem => elem.id === category_id)
    // return (
    //   <Card style={{ marginLeft: 0, marginRight: 0 }}>
    //     <Box
    //       button
    //       disabled={!editable}
    //       onPress={ () => editable ? this.goToCategoryPicker() : null }>
    //       <View style={{
    //         height: 35,
    //         flex: 1,
    //         flexDirection: 'row',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //       }}>
    //         <View style={{ flex: 30 }}>
    //           <XText style={s.txtLabel} >ประเภทสินค้า:</XText>
    //         </View>
    //         <View style={{ flex: 60 }}>
    //           <XText style={ editable ? s.txtHL : s.txtNormal }>
    //             { category ? category.label : 'ยังไม่ได้เลือกหมวดหมู่'}
    //           </XText>
    //         </View>
    //         <View style={{ flex: 10, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', paddingRight: 5 }}>
    //           { editable
    //             ? <Icon
    //               style={{
    //                 textAlign: 'right',
    //                 fontSize: STYLES.FONT_ICON_NORMAL,
    //                 color: COLORS.TEXT_INACTIVE }}
    //               name='arrow-forward' />
    //             : <View />
    //           }
    //         </View>
    //       </View>
    //     </Box>
    //   </Card>
    // )
  }

  _renderCategoryPickerItem = (data) => {
    const { item, index, disabled } = data
    return (
      <VStack
        style={{
          borderBottomWidth: p.op.isWeb() ? 0 : 1,
          borderBottomColor: COLORS.TEXT_INACTIVE,
          paddingLeft: 12,
          paddingVertical: 8,
        }}>
        <VStack size={100} style={{ justifyContent: 'center' }}>
          <XText style={s.txtNormal}>{`${item.name}`}</XText>
        </VStack>
        {!p.op.isWeb() ? (
          <VStack size={0} style={{ flex: 0, width: 50 }}>
            <ForwardIcon />
          </VStack>
        ) : null}
      </VStack>
    )
  }

  _renderCategorySelectedItem = ({ item, index, disabled }) => (
    <XCard style={INTL_STYLES.cardPickerItem}>
      <Box style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 8, paddingBottom: 8 }}>
        <VStack>
          <HStack>
            <VStack style={{ flex: 0, width: 80, justifyContent: 'center', alignItems: 'flex-start' }}>
              <XText style={s.txtLabel}>ประเภทสินค้า:</XText>
            </VStack>
            <VStack size={100} style={INTL_STYLES.colMiddleLeft}>
              <XText style={INTL_STYLES.txtPickerItem}>{item.name}</XText>
            </VStack>
            {disabled ? null : (
              <VStack style={{ flex: 0, width: 22 }}>
                <ForwardIcon inactive />
              </VStack>
            )}
          </HStack>
        </VStack>
      </Box>
    </XCard>
  )

  _renderLinkedToMkpChannels = () => {
    // // FIXME: ซ่อนปุ่มจัดการสินค้า MKP ในเว็บ
    // if (p.op.isWeb()) {
    //   return null
    // }
    const { editingProduct, selectedProduct, selectedStore } = this.props
    const { mode } = this.state
    const mkpChannels = selectedStore.get('channels')

    const hasMkpChannal = mkpChannels && mkpChannels.size > 0
    if (!hasMkpChannal) {
      return null
    }

    const isViewSelfMode = mode === `VIEW_SELF`
    if (!isViewSelfMode) {
      return null
    }

    const isMkpChannelMapsActiveAtLeastOne = util.isMkpChannelMapsActiveAtLeastOne(mkpChannels)
    // ถ้าไม่มี mkp channels ที่ใช้ได้เลยถือว่าไม่มี ให้ซ่อนของไป
    if (!isMkpChannelMapsActiveAtLeastOne) {
      return null
    }
    // let mkpChannelIDS = isViewSelfMode ? selectedProduct.get(`mkp_channel_ids`) : editingProduct.get(`mkp_channel_ids`)
    // if (isImmutable(mkpChannelIDS)) {
    //   mkpChannelIDS = mkpChannelIDS.toJS()
    // }
    // const channels = isImmutable(selectedStore.get(`channels`)) ? selectedStore.get(`channels`).toJS() : selectedStore.get(`channels`)
    // if (!_.isArray(mkpChannelIDS)) {
    //   return null
    // }
    // const linkedMkpChannels = []
    // mkpChannelIDS.map((id: number) => {
    //   const hasChannel = _.find(channels, (ch: IMKPChannelDetail) => ch.id === id)
    //   if (!_.isNil(hasChannel)) {
    //     linkedMkpChannels.push(hasChannel)
    //   }
    // })
    // const hasLinked = linkedMkpChannels.length > 0
    const productMkpChannelIds: List<number> = selectedProduct.get('mkp_channel_ids') || List([])
    const linkedMkpChannels = mkpChannels.filter((mkpCh) => productMkpChannelIds.includes(mkpCh.get('id')))
    const activeLinkedMkpChannels = linkedMkpChannels.filter(util.filterMkpChannelMapIsActive)
    const hasActiveLinked = activeLinkedMkpChannels.size > 0

    let isAllActiveMkpChannelsAlreadyLinked = false
    const activeMkpChannels = mkpChannels.filter(util.filterMkpChannelMapIsActive)

    if (activeLinkedMkpChannels.size === activeMkpChannels.size) {
      isAllActiveMkpChannelsAlreadyLinked = true
    }

    return (
      <XCard>
        <XCard.Body>
          <HStack justifyContent='space-between'>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <XIcon
                name='link'
                family='FontAwesome'
                style={{ flex: 0, fontSize: STYLES.FONT_ICON_SMALLEST, color: COLORS.TEXT_INACTIVE, minWidth: 20 }}
              />
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingLeft: 4 }}>เชื่อมต่อช่องทางขาย</XText>
              {hasActiveLinked ? null : (
                <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE, paddingLeft: 4 }}>(ยังไม่ได้เชื่อม)</XText>
              )}
              {hasActiveLinked ? null : (
                <TouchableOpacity
                  onPress={() =>
                    p.op.alert(
                      'การเชื่อมต่อช่องทางขาย',
                      'สินค้ารายการนี้ไม่ได้เชื่อมต่อกับสินค้าจากช่องทางขายอื่นๆ หากต้องการเชื่อมสามารถกดปุ่ม ลูกศร ทางด้านขวา'
                    )
                  }>
                  <XIcon name='help-circle-outline' inactive style={{ flex: 0, minWidth: 20, fontSize: STYLES.FONT_ICON_SMALLER }} />
                </TouchableOpacity>
              )}
            </View>

            {isAllActiveMkpChannelsAlreadyLinked ? null : (
              <TouchableOpacity
                onPress={this._navToMkpProductLinkManagerView}
                style={{ width: 34, alignItems: 'flex-start', justifyContent: 'center' }}>
                <XIcon family='FontAwesome' name='exchange' style={{ flex: 0, fontSize: STYLES.FONT_ICON_SMALLER }} />
              </TouchableOpacity>
            )}
          </HStack>
          {/* {!linkedMkpChannels || linkedMkpChannels.length === 0 ? ( */}
          {/* <View style={[S.BORDER, S.PADDING_8, { width: '100%', borderStyle: 'dotted' }]}>
              <XText style={[S.TEXT_INACTIVE, S.TEXT_ALIGN_CENTER]}>{'สินค้าชิ้นนี้ยังไม่ได้เชื่อมต่อกับช่องทางขายอื่นๆ'}</XText>
              <TouchableOpacity
                onPress={() =>
                  p.op.alert(
                    'การเชื่อมต่อช่องทางขาย',
                    'สินค้ารายการนี้ไม่ได้เชื่อมต่อกับสินค้าจากช่องทางขายอื่นๆ หากต้องการเชื่อมสามารถกดปุ่ม ลูกศร ทางด้านขวา'
                  )
                }>
                <XIcon name='help-circle-outline' inactive={true} style={{ flex: 0, minWidth: 20 }} />
              </TouchableOpacity>
            </View> */}
          {hasActiveLinked
            ? activeLinkedMkpChannels.map(
                (channel, index: number) => (
                  <ChannelItemBarButton
                    key={index}
                    mkpChannel={channel}
                    onPress={() => this._navToMkpProductDetailView(channel)}
                    disabledOnStatusDeactivated
                  />
                )
                // return (
                //   <TouchableOpacity
                //     key={index}
                //     onPress={() =>
                //       this.props.navigation.dispatch(
                //         NavActions.navToMkpProductView({
                //           store_id: null,
                //           pt_id: selectedProduct.get(`id`),
                //           mkp_channel_id: channel.id,
                //           mode: 'VIEW',
                //         })
                //       )
                //     }
                //     style={{
                //       flexBasis: 'auto',
                //       flex: 1,
                //       // justifyContent: 'center',
                //       alignItems: 'center',
                //       flexDirection: 'row',
                //       height: 40,
                //       borderWidth: 1,
                //       borderColor: COLORS.TEXT_INACTIVE,
                //       marginTop: index > 0 ? 4 : 0,
                //     }}>
                //     <View style={{ width: 40, justifyContent: 'center', alignItems: 'center' }}>
                //       {/* {getShopeeLogo(STYLES.MKP_ICON_SIZE_NORMAL, STYLES.MKP_ICON_SIZE_NORMAL)} */}
                //       <MkpLogo mkpId={channel.mkp_id} width={STYLES.MKP_ICON_SIZE_NORMAL} height={STYLES.MKP_ICON_SIZE_NORMAL} />
                //     </View>
                //     <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingLeft: 4, flex: 1 }}>
                //       {channel.name}
                //     </XText>
                //     <View style={{ width: 45, justifyContent: 'center', alignItems: 'center' }}>
                //       <XIcon
                //         name={'arrowright'}
                //         family={`AntDesign`}
                //         style={{ flex: 0, fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.APP_MAIN, minWidth: 20 }}
                //       />
                //     </View>
                //   </TouchableOpacity>
                // )
              )
            : null}
          {/* <View style={{ width: '100%', flexBasis: 'auto', alignItems: 'center', justifyContent: 'center', marginTop: 8 }}>
            <TouchableOpacity
              onPress={this._navToMkpProductLinkManagerView}
              style={[S.BUTTON_PRIMARY, S.PADDING_HORIZONTAL_12, S.PADDING_VERTICAL_4, { height: undefined }]}>
              <XText style={[S.TEXT_ACTIVE_DARK, S.TEXT_ALIGN_CENTER]}>{'บริหารการเชื่อมต่อ'}</XText>
            </TouchableOpacity>
          </View> */}
        </XCard.Body>
      </XCard>
    )
  }

  _renderMkpInfo = (ppId?: number) => {
    const { selectedProduct, selectedStore } = this.props
    const handlePress = () => this._navToProductDetailMkpInfoDetailView(ppId)

    return (
      <ProductDetailMkpInfoButton
        selectedStore={selectedStore}
        // @ts-ignore
        product={selectedProduct}
        onPress={handlePress}
        ppId={ppId}
      />
    )
  }

  _onChooseVariantImageSuccess = async (variantIndex: number) => {
    const { onChangeVariant } = this.props
    const { mode } = this.state

    const uploadingState = {}
    uploadingState[`variantImageUploading${variantIndex}`] = true
    await util.setStatePromise(this, uploadingState)

    const imgMgrRef = this.imgManagerVariantRefs[variantIndex]
    if (imgMgrRef && imgMgrRef.current && imgMgrRef.current.submitUploadImages) {
      const res = await imgMgrRef.current.submitUploadImages()
      // console.log(`${variantIndex}::_onChooseVariantImage res => `, res)
      const unfinishedIndexes = _.isArray(res.unfinishedIndexes) ? res.unfinishedIndexes.map((idx) => idx + 1) : []
      // console.log(`${variantIndex}::_onChooseVariantImage unfinishedIndexes => `, unfinishedIndexes)
      const variantImageState = {}

      if (res.allUploaded) {
        variantImageState[`variantImage${variantIndex}`] = res

        // if (mode !== ADD) {
        onChangeVariant({ idx: variantIndex, key: 'img_url', value: res.p[0] })
        onChangeVariant({ idx: variantIndex, key: 'thumbnail_url', value: res.t[0] })
        onChangeVariant({ idx: variantIndex, key: 'tiny_img_url', value: res.y[0] })
        // }
      } else {
        variantImageState[`variantImage${variantIndex}`] = null
      }

      await util.setStatePromise(this, variantImageState)

      // return {
      //   img_uris: res.p,
      //   thumbnail_uris: res.t,
      //   tiny_img_uris: res.y,
      //   ...res,
      //   // allUploaded,
      //   // isEmpty,
      //   unfinishedIndexes,
      // }
    }

    uploadingState[`variantImageUploading${variantIndex}`] = false
    await util.setStatePromise(this, uploadingState)
  }

  _getInitialVariantImageUris = (variantIndex: number) => {
    // const { mode } = this.state
    const variants = this.getVariants()
    const variant = variants.get(variantIndex)
    let initialImageUrls: { [key: string]: string[] } = null

    const img_url = variant.get('img_url')
    const thumbnail_url = variant.get('thumbnail_url')
    const tiny_img_url = variant.get('tiny_img_url')

    // if (mode === ADD) {
    //   initialImageUrls = this.state[`variantImage${variantIndex}`]
    // } else
    if (img_url && thumbnail_url && tiny_img_url) {
      // initialImageUrls = _.uniq([...initialImageUrls, ...this.getInitialProductImageUris()]
      initialImageUrls = {
        p: [img_url],
        t: [thumbnail_url],
        y: [tiny_img_url],
      }
    }

    return initialImageUrls
  }

  _isVariantImageAllUploaded = () => {
    const variants = this.getVariants()
    const vLength = variants.size

    let isAllUpload = true
    for (let variantIndex = 0; variantIndex < vLength; variantIndex++) {
      if (this.state[`variantImageUploading${variantIndex}`]) {
        isAllUpload = false
        break
      }
    }
    return isAllUpload
  }

  _onDeleteVariantImageSuccess = async (variantIndex: number) => {
    const { onChangeVariant } = this.props

    onChangeVariant({ idx: variantIndex, key: 'img_url', value: null })
    onChangeVariant({ idx: variantIndex, key: 'thumbnail_url', value: null })
    onChangeVariant({ idx: variantIndex, key: 'tiny_img_url', value: null })
  }

  _openChooseVariantImageFromProductImages = (variantIndex: number) => {
    this.setState({ chooseVariantImageOverlayAtIndex: variantIndex })
  }

  _closeChooseVariantImageFromProductImages = () => {
    this.setState({ chooseVariantImageOverlayAtIndex: -1, chooseVariantImageCopyAtIndex: 0 })
  }

  doCopyProductImageToVariantImage = async () => {
    const { chooseVariantImageOverlayAtIndex, chooseVariantImageCopyOptionAtIndex = 0, chooseVariantImageCopyAtIndex = 0 } = this.state
    const { editingProduct, onChangeVariant, onChangeProduct } = this.props

    try {
      const ptImgIndex = chooseVariantImageCopyAtIndex
      const img_uris: List<string> = editingProduct.get('img_uris')
      const thumbnail_uris: List<string> = editingProduct.get('thumbnail_uris')
      const tiny_img_uris: List<string> = editingProduct.get('tiny_img_uris')

      const img_url = img_uris.get(ptImgIndex)
      const thumbnail_url = thumbnail_uris.get(ptImgIndex)
      const tiny_img_url = tiny_img_uris.get(ptImgIndex)

      onChangeVariant({ idx: chooseVariantImageOverlayAtIndex, key: 'img_url', value: img_url })
      onChangeVariant({ idx: chooseVariantImageOverlayAtIndex, key: 'thumbnail_url', value: thumbnail_url })
      onChangeVariant({ idx: chooseVariantImageOverlayAtIndex, key: 'tiny_img_url', value: tiny_img_url })

      this.imgManagerVariantRefs[chooseVariantImageOverlayAtIndex].current.loadImageUrls({
        p: [img_url],
        t: [thumbnail_url],
        y: [tiny_img_url],
      })

      if (chooseVariantImageCopyOptionAtIndex === 1) {
        onChangeProduct({ key: 'img_uris', value: img_uris.delete(ptImgIndex) })
        onChangeProduct({ key: 'thumbnail_uris', value: thumbnail_uris.delete(ptImgIndex) })
        onChangeProduct({ key: 'tiny_img_uris', value: tiny_img_uris.delete(ptImgIndex) })

        this.imgManagerRef.loadImageUrls({
          p: img_uris.delete(ptImgIndex).toJS(),
          t: thumbnail_uris.delete(ptImgIndex).toJS(),
          y: tiny_img_uris.delete(ptImgIndex).toJS(),
        })
      }

      this._closeChooseVariantImageFromProductImages()
    } catch (error) {
      console.log('doCopyProductImageToVariantImage error => ', error)
    }
  }

  _renderChooseVariantImageFromProductImagesOverlay = () => {
    const { chooseVariantImageOverlayAtIndex, chooseVariantImageCopyOptionAtIndex = 0, chooseVariantImageCopyAtIndex = 0 } = this.state
    const isVisible = !_.isNil(chooseVariantImageOverlayAtIndex) && chooseVariantImageOverlayAtIndex >= 0
    if (!isVisible) {
      return null
    }

    const { editingProduct } = this.props
    const imgUris = editingProduct.get('thumbnail_uris') || List([])
    const variants = this.getVariants()
    const variantCount = variants.size || 0

    if (variantCount < 2) {
      return null
    }

    const variantName = variants.getIn([chooseVariantImageOverlayAtIndex, 'name']) || null
    // const variant = variants.get(chooseVariantImageOverlayAtIndex)
    // const img_url = variant.get('img_url')
    // const thumbnail_url = variant.get('thumbnail_url')
    // const tiny_img_url = variant.get('tiny_img_url')
    // const isAllChecked = this.state[`variantImage${chooseVariantImageOverlayAtIndex}`]

    return (
      <XOverlay visible={isVisible} onRequestClose={this._closeChooseVariantImageFromProductImages}>
        <XCustomHeader
          title='เลือกรูปตัวเลือกสินค้าจากรูปสินค้า'
          headerRightProps={{ closeIcon: true, onPressItem: this._closeChooseVariantImageFromProductImages }}
        />
        <VStack w='full' space='1'>
          <VStack w='full' px='1.5' pt='1' alignItems='center' space='1'>
            <HStack w='full'>
              <XText variant='inactive'>สำหรับตัวเลือกที่: </XText>
              <XText>{chooseVariantImageOverlayAtIndex + 1}</XText>
            </HStack>

            {variantName && variantName !== '' ? (
              <HStack w='full'>
                <XText variant='inactive'>ชื่อตัวเลือก: </XText>
                <XText>{variantName}</XText>
              </HStack>
            ) : null}

            <XSegment
              options={[
                { label: 'คัดลอกรูป', value: 0 },
                { label: 'ย้ายรูป', value: 1 },
              ]} // [''คัดลอกรูป', 'คัดลอกแล้วลบรูปสินค้าออก']}
              selectedIndex={chooseVariantImageCopyOptionAtIndex}
              onSegmentChange={(value, index) => this.setState({ chooseVariantImageCopyOptionAtIndex: index })}
            />
            {/* <RadioButton
              label='คัดลอกรูป'
              onPress={() => this.setState({ chooseVariantImageCopyOptionAtIndex: 0 })}
              isChecked={chooseVariantImageCopyOptionAtIndex === 0}
            /> */}
            {/* <RadioButton
              label='คัดลอกแล้วลบรูปสินค้าออก'
              onPress={() => this.setState({ chooseVariantImageCopyOptionAtIndex: 1 })}
              isChecked={chooseVariantImageCopyOptionAtIndex === 1}
            /> */}
          </VStack>

          <XScrollView h='380px' w='full'>
            <VStack w='full' space='1'>
              {imgUris.map((img, idx) => {
                const isImgChecked = chooseVariantImageCopyAtIndex === idx
                return (
                  <HStack key={`PImage${idx}`} w='full' px='2'>
                    <TouchableOpacity onPress={() => this.setState({ chooseVariantImageCopyAtIndex: idx })} style={{ width: '100%' }}>
                      <HStack
                        w='full'
                        p='2'
                        space='2'
                        borderWidth='1'
                        borderRadius='lg'
                        borderColor={isImgChecked ? 'gray.400' : 'gray.200'}
                        alignItems='center'>
                        <Radio checked={isImgChecked} />
                        <XImage w='128px' h='128px' source={{ uri: img }} />
                      </HStack>
                    </TouchableOpacity>
                  </HStack>
                )
              })}
            </VStack>
          </XScrollView>

          <HStack w='full' px='1.5' py='1'>
            <XButton
              w='full'
              h='11'
              // disabled={isNavToReservedProductsOrdersDisabled}
              // isDisabled={isNavToReservedProductsOrdersDisabled}
              onPress={this.doCopyProductImageToVariantImage}>
              {`ยืนยัน ${chooseVariantImageCopyOptionAtIndex === 1 ? 'ย้ายรูป' : 'คัดลอกรูป'}สินค้า`}
            </XButton>
          </HStack>
        </VStack>
      </XOverlay>
    )
  }

  _renderProductVariantImage = (variantIndex) => {
    const { mode } = this.state
    const { editingProduct } = this.props
    const variants = this.getVariants()
    const variantCount = variants.size || 0
    const ptImgCount = editingProduct.get('img_uris') ? editingProduct.get('img_uris').size : 0

    const isImageReadonly = !_.includes([ADD, EDIT_SELF, EDIT_SELLER], mode)
    const hasVariantImage = !_.isNil(variants.getIn([variantIndex, 'img_url']))

    return (
      <HStack
        key={`VI_${mode}_${variantIndex}`}
        w='full'
        py='1'
        // bg='amber.200'
      >
        <HStack w='174px' space='1'>
          <HStack w='28px' h='34px' alignItems='center'>
            <XIcon name='image' family='FontAwesome' inactive />
          </HStack>

          <VStack flex={1} space='1'>
            <XText pt='7px' variant='inactive'>
              รูปตัวเลือกสินค้า
            </XText>

            {_.includes([ADD, EDIT_SELF, EDIT_SELLER], mode) && ptImgCount > 0 && variantCount >= 2 ? (
              <XButton
                variant='outline'
                size='xs'
                w='110px'
                // h='36px'
                px='1.5'
                py='0.5'
                onPress={() => this._openChooseVariantImageFromProductImages(variantIndex)}>
                เลือกจากรูปสินค้า
              </XButton>
            ) : null}
          </VStack>
        </HStack>

        <HStack
          flex={1}
          alignItems='center'
          justifyContent='flex-end'
          // bg='blue.200'
        >
          <HStack w='96px' alignItems='center' justifyContent='flex-end'>
            {isImageReadonly && !hasVariantImage ? (
              <XText variant='inactive' textAlign='right'>
                ไม่มี
              </XText>
            ) : (
              <ImgManager
                readonly={isImageReadonly}
                ref={this.imgManagerVariantRefs[variantIndex]}
                initialImageUrls={this._getInitialVariantImageUris(variantIndex)}
                uploading={this.state[`variantImageUploading${variantIndex}`]}
                maxLength={1}
                onPressChooseSuccess={() => this._onChooseVariantImageSuccess(variantIndex)}
                onDeleteImgSuccess={() => this._onDeleteVariantImageSuccess(variantIndex)}
              />
            )}
          </HStack>
        </HStack>
        {/* <XText>{JSON.stringify(variant)}</XText> */}
      </HStack>
    )
  }

  _renderMkpInfoWithVariantIndex = (variantIndex: number) => {
    const variants = this.getVariants()
    const ppId = variants.getIn([variantIndex, 'pp_id'])

    if (!ppId) {
      return null
    }

    return this._renderMkpInfo(ppId)
  }

  _renderMkpMappingChannelListViewPickerNavReference = (spv: IProductDetailVariant) => {
    if (!spv) {
      return null
    }

    const { selectedProduct } = this.props
    const pName = selectedProduct.get('name')
    let thumbnailUri = selectedProduct.getIn(['thumbnail_uris', 0])

    if (spv.thumbnail_url && spv.thumbnail_url !== '') {
      thumbnailUri = spv.thumbnail_url
    }

    return (
      <VStack w='full' py='1.5' px='2' space='1' borderWidth='1' borderColor='muted.400' bg='primary.50'>
        {/* <XText bold>renderNavReference fn</XText> */}
        {/* <XText>{JSON.stringify(selectedProductVariant)}</XText> */}
        <HStack w='full' space='1' alignItems='center' justifyContent='center'>
          <XText variant='inactive' bold>
            กำลังดำเนินการผูกสินค้าจาก XSelly
          </XText>
        </HStack>
        <HStack w='full' space='1.5'>
          <VStack w='16' h='16'>
            <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailUri }} />

            <Box position='absolute' top='1' left='1'>
              <XSellyLogo width={24} height={24} />
            </Box>
          </VStack>

          <VStack flex={1}>
            <HStack w='full'>
              <VStack flex={1}>
                <XText w='full' numberOfLines={2}>
                  {pName}
                </XText>
              </VStack>
              <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
                <XText w='full' textAlign='right' bold numberOfLines={3}>
                  {spv.name}
                </XText>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    )
  }

  _navToMkpMappingChannelListViewPicker = (selectedProductVariant: IProductDetailVariant) => {
    const { navigation } = this.props
    const renderNavReference = () => this._renderMkpMappingChannelListViewPickerNavReference(selectedProductVariant)

    navigation.dispatch(
      NavActions.navToMkpMappingChannelListViewPicker({
        selectedProductVariant,
        renderNavReference,
        onMappingSuccess: this._onProductDetailMkpInfoDetailViewMappingSuccessAlternative,
      })
    )
  }

  _renderMkpLinkButtonWithVariantIndex = (variantIndex: number) => {
    const { mode } = this.state
    const shouldRender = _.includes([VIEW_SELF], mode)

    if (!shouldRender) {
      return null
    }

    const isFromMkpProductView = util.getNavParam(this.props, 'isFromMkpProductView', false)
    if (isFromMkpProductView) {
      return null
    }

    const { selectedStore } = this.props
    const mkpChannels = selectedStore.get('channels')
    if (!mkpChannels || mkpChannels.size === 0) {
      return null
    }

    const variants = this.getVariants()
    const mkp = variants.getIn([variantIndex, 'mkp'])
    const v = variants.get(variantIndex).toJS() || undefined

    if (!mkp || mkp.size === 0) {
      return (
        <HStack w='full' justifyContent='flex-end'>
          <XButton
            variant='outline'
            size='xs'
            w='114px'
            // h='36px'
            px='1.5'
            py='0.5'
            leftIcon={<XIcon name='link' family='FontAwesome' />}
            onPress={() => this._navToMkpMappingChannelListViewPicker(v)}>
            ผูกช่องทางขาย
          </XButton>
        </HStack>
      )
    }

    return null
  }

  _renderVariantIdLabelVariantIndex = (variantIndex: number) => {
    const variants = this.getVariants()
    const v = variants.get(variantIndex).toJS() || undefined

    if (!v || !v.pp_id) {
      return null
    }

    const id = v.pp_id
    return (
      <HStack w='full' position='absolute' left='0' right='0' bottom='0' px='1'>
        <XText
          variant='inactive'
          fontSize='2xs'
          numberOfLines={1}
          // @ts-ignore
          onPress={() => {
            Clipboard.setString(id.toString())
            p.op.showToast(`คัดลอก ${id} แล้ว`, 'success')
          }}>
          {`ID:${id}`}
        </XText>
      </HStack>
    )
  }

  _navToMkpProductLinkManagerView = async () => {
    const { navigation, mkpProductContext, selectedProduct } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    const product_id = util.getNavParam(this.props, 'product_id')
    const mkpViewKey = product_id.toString()

    // mkpProductContext.init(mkpViewKey)
    await util.delay(100)

    const coreProduct = selectedProduct.toJS() as IProductDetailItem

    mkpProductContext.init(mkpViewKey, {
      // Accutaully it should be same type
      coreProduct,
      onSuccesssMapping: () => {
        this._onRefresh()
      },
    })
    await util.delay(100)

    navigation.dispatch(
      NavActions.navToMkpProductLinkManagerView({
        store_id,
        product_id,
      })
    )
  }

  _navToMkpProductDetailView = async (channel) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { navigation, mkpProductContext, selectedProduct } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    const product_id = selectedProduct.get('id')
    const mkpViewKey = product_id.toString()

    // mkpProductContext.init(mkpViewKey)
    await util.delay(100)

    const coreProduct = selectedProduct.toJS() as IProductDetailItem

    mkpProductContext.init(mkpViewKey, {
      // Accutaully it should be same type
      coreProduct,
      onSuccesssMapping: () => {
        this._onRefresh()
      },
    })
    await util.delay(100)

    this.props.navigation.dispatch(
      NavActions.navToMkpProductDetailView({
        store_id,
        // pt_id: product_id,
        // mkp_channel_id: channel.get('id'),
        mkp_ch_id: channel.get('id'),
        mkp_id: channel.get('mkp_id'),
      })
    )

    this.inProcess = false
  }

  _renderAllShippingRates = () => {
    // const { mode } = this.state
    const { editingProduct, selectedStore } = this.props

    const useAutoCalculateShippingCost = selectedStore.get('s_auto_calc_shipping_cost') || false
    const shippingCalculateMode = selectedStore.get('s_shipping_calc_mode') || 1
    const isVisibleShippingRateMyByEach = useAutoCalculateShippingCost && _.includes([1, 3], shippingCalculateMode)

    // const shippingRatesMy = editingProduct.get('shipping_rates')
    // const shippingRateStatusesMy = editingProduct.get('shippingRateStatuses')
    // const shouldRenderShippingRatesMy = this._shouldRenderShippingRatesMy() || isVisibleShippingRateMyByEach

    const shippingRatesSeller = editingProduct.get('seller_shipping_rates') || List([])
    // const shippingRateStatusesSeller = this._getSellerShippingRateStatuses(shippingRatesSeller)
    // const shouldRenderShippingRatesSeller = this._shouldRenderShippingRatesSeller()

    // const editableShippingRatesMy = this.canAutoCalcShippingRateByQty && _.includes([ADD, EDIT_SELF, EDIT_SELLER, PULL_PRODUCT], mode)
    const sellerShippingTypeIds = shippingRatesSeller.map((shippingRateItem) =>
      Map.isMap(shippingRateItem) ? shippingRateItem.get('shipping_type_id') : null
    )
    const settingAutoCalcShippingTypeIds = selectedStore.get('s_auto_calc_shipping_type_ids') || List([])

    const isMyProduct = _.isNil(editingProduct.get('parent_id'))
    const showingMyShippingTypeIds = isMyProduct ? settingAutoCalcShippingTypeIds : List([])

    return (
      <VStack w='full' mt='1'>
        {/* {shouldRenderShippingRatesSeller
          ? this._renderShippingRatesSeller({
              shippingTypeId: xCONS.SHIPPING_TYPE_IDS.REGISTER,
              shippingRates: shippingRatesSeller,
              shippingRateStatuses: shippingRateStatusesSeller,
            })
          : null}
        {shouldRenderShippingRatesSeller
          ? this._renderShippingRatesSeller({
              shippingTypeId: xCONS.SHIPPING_TYPE_IDS.EMS,
              shippingRates: shippingRatesSeller,
              shippingRateStatuses: shippingRateStatusesSeller,
            })
          : null} */}
        {/* {shouldRenderShippingRatesMy
          ? this._renderShippingRatesMy({
              shippingTypeId: xCONS.SHIPPING_TYPE_IDS.REGISTER,
              shippingRates: shippingRatesMy,
              shippingRateStatuses: shippingRateStatusesMy,
              editable: editableShippingRatesMy,
            })
          : null}
        {shouldRenderShippingRatesMy
          ? this._renderShippingRatesMy({
              shippingTypeId: xCONS.SHIPPING_TYPE_IDS.EMS,
              shippingRates: shippingRatesMy,
              shippingRateStatuses: shippingRateStatusesMy,
              editable: editableShippingRatesMy,
            })
          : null} */}
        {sellerShippingTypeIds.map(this._renderShippingRatesSeller)}
        {isVisibleShippingRateMyByEach ? showingMyShippingTypeIds.map(this._renderShippingRatesMy) : null}
      </VStack>
    )
  }

  // _renderShippingRatesMy = ({ shippingTypeId, shippingRates, shippingRateStatuses, editable }) => {
  //   return this._renderShippingRates({ shippingTypeId, shippingRates, shippingRateStatuses, editable, isMyRate: true })
  // }
  // _renderShippingRatesSeller = ({ shippingTypeId, shippingRates, shippingRateStatuses }) => {
  //   return this._renderShippingRates({
  //     shippingTypeId,
  //     shippingRates,
  //     shippingRateStatuses,
  //     editable: false,
  //     isMyRate: false,
  //   })
  // }
  _renderShippingRatesMy = (shippingTypeId: number, index: number) => {
    if (!_.isNumber(shippingTypeId)) {
      return null
    }
    const { mode } = this.state
    const { editingProduct } = this.props
    const shippingRates = editingProduct.get('shipping_rates')
    const shippingRateStatuses = editingProduct.get('shippingRateStatuses')
    const editable = this.canAutoCalcShippingRateByQty && _.includes([ADD, EDIT_SELF, EDIT_SELLER, PULL_PRODUCT], mode)
    return this._renderShippingRates({ shippingTypeId, shippingRates, shippingRateStatuses, editable, isMyRate: true, index })
  }

  _renderShippingRatesSeller = (shippingTypeId: number, index: number) => {
    if (!_.isNumber(shippingTypeId)) {
      return null
    }
    const { mode } = this.state
    const { editingProduct, selectedStore } = this.props
    const shippingRates = editingProduct.get('seller_shipping_rates')
    const shippingRateStatuses = this._getSellerShippingRateStatuses(shippingRates)
    return this._renderShippingRates({ shippingTypeId, shippingRates, shippingRateStatuses, editable: false, isMyRate: false, index })
  }

  _renderShippingRates = ({ isMyRate, shippingTypeId, shippingRates, shippingRateStatuses, editable, index }) => {
    // log('_renderShippingRates isMyRate => ', isMyRate)
    // log('_renderShippingRates shippingTypeId => ', shippingTypeId)
    // log('_renderShippingRates shippingRates => ', shippingRates)
    // log('_renderShippingRates shippingRateStatuses => ', shippingRateStatuses)
    // log('_renderShippingRates editable => ', editable)
    if (!shippingRates || !shippingRateStatuses) {
      return null
    }
    const { onChangeShippingRate, onToggleShippingRate } = this.props
    // log('editingProduct')
    // log(editingProduct.toJS())
    // const shippingRates = editingProduct.get('shipping_rates')
    // const shippingRateStatuses = editingProduct.get('shippingRateStatuses')
    const shippingTypeIdStr = shippingTypeId.toString() // key of Immutable Map must be string
    const enabled = shippingRateStatuses.get(shippingTypeIdStr)
    const shpppingFeeLabel = isMyRate ? 'ค่าส่ง: ' : 'ต้นทุนค่าส่ง: '
    let _shippingRate
    let idx
    // console.log('shippingRates => ', shippingRates.toJS())
    for (let i = 0; i < shippingRates.size; i++) {
      if (shippingTypeId === shippingRates.get(i).get('shipping_type_id')) {
        _shippingRate = shippingRates.get(i)
        idx = i.toString()
        break
      }
    }
    // log('_shippingRate...')
    // log(_shippingRate)
    const initQty = _shippingRate ? _shippingRate.get('init_qty').toString() : '1'
    const initPrice = _shippingRate ? _shippingRate.get('init_price') : '0'
    const nextPrice = _shippingRate ? _shippingRate.get('next_price') : '0'

    const txtShippingTypeName = util.getShippingTypeNameById(shippingTypeId)
    const itemKey = `${shippingTypeId}_${isMyRate ? '1' : '0'}`

    if (!enabled && !editable) {
      return null
    }

    return (
      <XCard key={itemKey}>
        <XCard.Body>
          <HStack flex={1} alignItems='center' justifyContent='space-between'>
            <XText variant='inactive'>{shpppingFeeLabel + txtShippingTypeName}</XText>
            {isMyRate && editable ? (
              <XSwitch
                disabled={!editable}
                value={enabled}
                onValueChange={(val) => onToggleShippingRate({ key: shippingTypeIdStr, val })}
              />
            ) : null}
          </HStack>
          {!editable && enabled ? <XText>{`${initQty} ชิ้นแรกราคา ฿${initPrice} ชิ้นต่อไปชิ้นละ ฿${nextPrice}`}</XText> : null}
          {!editable && !enabled ? (
            <XText>
              {isMyRate ? 'ฉัน' : 'ผู้ขายส่ง'}
              ยังไม่ระบุ
            </XText>
          ) : null}
          {isMyRate && editable && enabled ? (
            <HStack flex={1} minH='18' pt='1' space='1' alignItems='center'>
              <XInput
                // ref={ r => util.pushNativeBaseRef(this.inputRefs, `shippingRate_${shippingTypeId}_init_qty`, r) }
                textAlign='right'
                value={initQty}
                w='12'
                isDisabled={!editable}
                onChangeText={(val) =>
                  onChangeShippingRate({
                    idx,
                    shipping_type_id: shippingTypeId,
                    key: 'init_qty',
                    value: val,
                    isMoney: false,
                    isInteger: true,
                  })
                }
                isInteger
                // style={[s.txtHLBG, s.shippingRateInputCommon, s.shippingRateInputQty]}
                placeholder='จำนวน'
                // placeholderTextColor={COLORS.TEXT_INACTIVE}
              />
              <XText variant={editable ? 'inactive' : 'active'}>ชิ้นแรกราคา</XText>
              <XInput
                w='16'
                // ref={ r => util.pushNativeBaseRef(this.inputRefs, `shippingRate_${shippingTypeId}_init_price`, r) }
                // value={'฿' + initPrice}
                textAlign='right'
                // unitLabelWidth='7'
                unitLabelWidth='4'
                value={initPrice}
                isDisabled={!editable}
                onChangeText={(newValue) =>
                  onChangeShippingRate({
                    idx,
                    shipping_type_id: shippingTypeId,
                    key: 'init_price',
                    value: newValue,
                    isMoney: true,
                    isInteger: false,
                  })
                }
                isMoney
                // style={[s.txtHLBG, s.shippingRateInputCommon, s.shippingRateInputPrice]}
                placeholder='฿'
                // placeholderTextColor={COLORS.TEXT_INACTIVE}
              />
              <XText variant={editable ? 'inactive' : 'active'}>ชิ้นต่อไปชิ้นละ</XText>
              <XInput
                w='16'
                // ref={ r => util.pushNativeBaseRef(this.inputRefs, `shippingRate_${shippingTypeId}_next_price`, r) }
                // selectTextOnFocus
                // value={'฿' + nextPrice}
                textAlign='right'
                unitLabelWidth='4'
                value={nextPrice}
                isDisabled={!editable}
                onChangeText={(newValue) =>
                  onChangeShippingRate({
                    idx,
                    shipping_type_id: shippingTypeId,
                    key: 'next_price',
                    value: newValue,
                    isMoney: true,
                    isInteger: false,
                  })
                }
                isMoney
                // style={[s.txtHLBG, s.shippingRateInputCommon, s.shippingRateInputPrice]}
                placeholder='฿'
                // placeholderTextColor={COLORS.TEXT_INACTIVE}
              />
            </HStack>
          ) : null}
        </XCard.Body>
      </XCard>
    )

    // // <Card style={[CARD_COMMON, { marginLeft: 10 }]}>
    // return (
    //   <Card>
    //     <Box disabled={!editable}>
    //       <View style={{
    //         flex: 1,
    //         flexDirection: 'column',
    //         // justifyContent: 'center',
    //         // alignItems: 'center',
    //         // keng
    //       }}>
    //         <View style={{ flex: 1, flexDirection: 'row', paddingBottom: 4 }}>
    //           <View style={{ flex: 3, marginTop: editable ? 0 : -8, paddingTop: 4 }}>
    //             <XText style={s.txtLabelShippingRate} >{shpppingFeeLabel + p.op.t(`Order.shippingTypeText._${shippingTypeIdStr}`)}</XText>
    //           </View>
    //           { isMyRate && editable
    //             ? (
    //               <View style={{ flex: 1, alignItems: 'flex-end' }}>
    //                 <Switch
    //                   disabled={!editable}
    //                   value={enabled}
    //                   onValueChange={val => onToggleShippingRate({ key: shippingTypeIdStr, val })}/>
    //               </View>
    //             ) : null
    //           }
    //         </View>
    //         { !editable && enabled
    //           ? (
    //             <XText style={s.txtNormal}>
    //               {`${initQty} ชิ้นแรกราคา ฿${initPrice} ชิ้นต่อไปชิ้นละ ฿${nextPrice}`}
    //             </XText>
    //           ) : null
    //         }
    //         { !editable && !enabled
    //           ? (
    //             <XText style={s.txtNormal}>
    //               { isMyRate ? 'ฉัน' : 'ผู้ขายส่ง' }ยังไม่ระบุ
    //             </XText>
    //           ) : null
    //         }
    //         {
    //           isMyRate && editable && enabled ? (
    //               <View style={{ flex: 1, flexDirection: 'row', paddingTop: 4 }}>
    //                 <View style={{ flexDirection: 'row' }}>
    //                   <View style={s.shippingRateInputQtyContainer}>
    //                     <Input
    //                       ref={ r => util.pushNativeBaseRef(this.inputRefs, `shippingRate_${shippingTypeId}_init_qty`, r) }
    //                       value={initQty}
    //                       disabled={ !editable }
    //                       onChangeText={ val => onChangeShippingRate({
    //                         idx,
    //                         key: 'init_qty',
    //                         val: val,
    //                         isMoney: false,
    //                         isInteger: true })
    //                       }
    //                       style={[s.txtHLBG, s.shippingRateInputCommon, s.shippingRateInputQty]}
    //                       placeholder='จำนวน'
    //                       placeholderTextColor={COLORS.TEXT_INACTIVE}
    //                     />
    //                   </View>
    //                   <XText
    //                     // style={[!editable ? s.txtHL : s.txtNormal, s.shippingRateLabel] }>
    //                     style={editable ? LOCAL_STYLES.labelInactive : LOCAL_STYLES.labelActive}>
    //                     ชิ้นแรกราคา
    //                   </XText>
    //                   <View style={{ }}>
    //                     <Input
    //                       ref={ r => util.pushNativeBaseRef(this.inputRefs, `shippingRate_${shippingTypeId}_init_price`, r) }
    //                       value={'฿' + initPrice}
    //                       disabled={ !editable }
    //                       onChangeText={ val => onChangeShippingRate({
    //                         idx,
    //                         key: 'init_price',
    //                         val: val,
    //                         isMoney: true,
    //                         isInteger: false })
    //                       }
    //                       style={[s.txtHLBG, s.shippingRateInputCommon, s.shippingRateInputPrice]}
    //                       placeholder='฿'
    //                       placeholderTextColor={COLORS.TEXT_INACTIVE}
    //                     />
    //                   </View>
    //                 </View>
    //                 <View style={{ flexDirection: 'row', width: 120 }}>
    //                   <XText
    //                     // style={[!editable ? s.txtHL : s.txtNormal, s.shippingRateLabel, { paddingLeft: 10 }] }>
    //                     style={editable ? LOCAL_STYLES.labelInactive : LOCAL_STYLES.labelActive}>
    //                     ชิ้นต่อไปชิ้นละ
    //                   </XText>
    //                   <View style={{ width: 80 }}>
    //                     <Input
    //                       ref={ r => util.pushNativeBaseRef(this.inputRefs, `shippingRate_${shippingTypeId}_next_price`, r) }
    //                       //selectTextOnFocus
    //                       value={'฿' + nextPrice}
    //                       disabled={ !editable }
    //                       onChangeText={ val => onChangeShippingRate({ idx,
    //                         key: 'next_price',
    //                         val: val,
    //                         isMoney: true,
    //                         isInteger: false })
    //                       }
    //                       style={[s.txtHLBG, s.shippingRateInputCommon, s.shippingRateInputPrice]}
    //                       placeholder='฿'
    //                       placeholderTextColor={COLORS.TEXT_INACTIVE}
    //                     />
    //                   </View>
    //                 </View>
    //               </View>
    //             )
    //             : null
    //         }
    //       </View>
    //     </Box>
    //   </Card>
    // )
  }

  _renderDeleteProductButton() {
    const { submitting, uploading, mode, isInitialized } = this.state
    const isDisabled = submitting || uploading
    return _.includes([EDIT_SELF, EDIT_SELLER], mode) && isInitialized ? (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
          marginBottom: 5,
        }}>
        <XButton
          colorScheme='danger'
          disabled={isDisabled}
          onPress={this._handleDeleteProduct}
          // variant='outline'
          // style={[STYLES.BTN_MODE_ACTIVE_SECONDARY, { width: 140, height: 30, borderWidth: 1 }]}
        >
          {/* <XText
          // @ts-ignore
          // style={STYLES.BTN_TEXT_MODE_ACTIVE_SECONDARY}
          > */}
          ลบสินค้า
          {/* </XText> */}
        </XButton>
      </View>
    ) : null
  }

  // goToCategoryPicker = () => {
  //   const cfg = {
  //     key: xCONS.PRODUCT_CATEGORY_KEY,
  //     title: 'เลือกประเภทของสินค้า',
  //     viewType: xCONS.PAGEPICKER_VIEWTYPE.ICON,
  //     items: xCONS.PRODUCT_CATEGORY_ITEMS,
  //   }
  //   this.props.navigation.dispatch(NavActions.navToPagePicker(cfg))
  // }

  _renderPulledProductStoreName() {
    const { selectedProduct } = this.props
    const parent_store_name = selectedProduct.get('parent_store_name') || null
    if (!parent_store_name) {
      return null
    }
    return (
      <HStack w='full' py='1' alignItems='center'>
        <Image source={ICON_SELLER_STORE} style={s.iconSellerProduct} />
        <XText variant='inactive' fontSize='xs' numberOfLines={1}>
          {` สินค้าจากร้าน: ${parent_store_name}`}
        </XText>
      </HStack>
    )
  }

  _renderHaveOneProductGroupCard = (mode) => {
    const { editingProduct, navigation, selectedStore } = this.props
    const { dispatch } = navigation
    if (!_.includes([EDIT_SELF, PULL_PRODUCT, ADD], mode)) {
      return null
    }
    // console.log('_renderHaveOneProductGroupCard')
    const store_id = selectedStore.get('id')
    const pt_id = editingProduct.get('id')
    const pg_id = editingProduct.get('product_group_ids').toJS()
    let pg_vd_ids = editingProduct.get('pg_vd_ids')
    if (_.isNil(pg_vd_ids)) {
      pg_vd_ids = null
    }
    // console.log(pg_id[0])
    return (
      <XCard
        disabled={false}
        onPress={
          () =>
            this.navToVolumeDiscountListView({
              store_id,
              pg_id: pg_id[0],
              pt_id,
              pg_vd_ids,
              mode: 'PULL_MODE',
            })
          // dispatch(
          //   NavActions.navToVolumeDiscountListView({
          //     store_id,
          //     pg_id: pg_id[0],
          //     pt_id,
          //     pg_vd_ids,
          //     mode: 'PULL_MODE',
          //   }),
          // )
        }>
        <HStack p='2' style={{ flex: 10 }}>
          <HStack style={{ flex: 8 }}>
            <XText
              style={{
                // fontSize: STYLES.FONT_SIZE_NORMAL,
                color: COLORS.TEXT_INACTIVE,
              }}>
              ระบุส่วนลดผู้ขายส่ง
            </XText>
          </HStack>
          <HStack style={{ flex: 2, justifyContent: 'flex-end' }}>
            <Icon
              name='arrow-forward'
              style={{
                width: 30,
                paddingRight: 7,
                fontSize: STYLES.FONT_ICON_NORMAL,
                color: COLORS.TEXT_PRIMARY,
                textAlign: 'right',
              }}
            />
          </HStack>
        </HStack>
      </XCard>
    )
  }

  _renderProductGroupCard = () => {
    const { mode, submitting, uploading } = this.state
    if (!mode || !_.includes([ADD, VIEW_SELF, EDIT_SELF, PULL_PRODUCT, VIEW_VOLUME_DISCOUNT], mode)) {
      return null
    }

    const { editingProduct, selectedStore, navigation } = this.props
    const product_groups = selectedStore.get('product_groups')
    const vds = selectedStore.get('vds')
    let isPriceNull = true
    let vPrices
    const variants = editingProduct.get('variants')
    if (!List.isList(variants) || variants.size === 0) {
      isPriceNull = false
    } else {
      variants.map((data) => {
        vPrices = data.get('prices')
        if (vPrices && vPrices.size === 0) {
          isPriceNull = false
        }
      })
    }
    if ((!List.isList(product_groups) || product_groups.size <= 1) && isPriceNull) {
      if (_.isNil(editingProduct.get('pg_vd_ids')) || editingProduct.get('pg_vd_ids').size === 0) {
        if (!selectedStore.get('s_use_volume_discount')) {
          return null
        }
        return this._renderHaveOneProductGroupCard(mode)
      }
    }
    // console.log(this.props.selectedStore.toJS())
    const product_group_ids = editingProduct.get('product_group_ids') || List([])
    // const isEditable = product_groups.size > 1 && _.includes([ADD, EDIT_SELF, PULL_PRODUCT], mode)
    let isEditable = this.isEditableProductMap.get('product_group_ids')
    isEditable = isEditable && !submitting && !uploading
    if (!List.isList(product_groups) || (product_groups.size <= 1 && mode === EDIT_SELF)) {
      isEditable = true
    }
    const s_use_volume_discount = selectedStore.get('s_use_volume_discount')

    return this.renderProductGroupCard({
      mode,
      vds,
      editingProduct,
      isEditable,
      product_groups,
      product_group_ids,
      s_use_volume_discount,
    })
    // return (
    //   <PGModalSelector
    //     // style={[CARD_COMMON, { marginLeft: 10 }]}
    //     // selectedIds={ List([1, 2, 3]) }
    //     navigation={navigation}
    //     vds={vds}
    //     editingProduct={editingProduct}
    //     disabled={!isEditable}
    //     style={{ marginLeft: 0, marginRight: 0 }}
    //     productGroups={product_groups}
    //     selectedIds={product_group_ids}
    //     allowedEmptySelected={false}
    //     onSubmitEditing={this._onChangeProductGroups}
    //     unpairVolumeDiscount={this.props.unpairVolumeDiscount}
    //     selectedStore={this.props.selectedStore}
    //     updateContInVolumeDiscounts={this.props.updateContInVolumeDiscounts}
    //     updateDataVolumeDiscountAndProductGroup={this.props.updateDataVolumeDiscountAndProductGroup}
    //     mode={mode}
    //     s_use_volume_discount={s_use_volume_discount}
    //     loadListVolumeDiscounts={this.props.loadListVolumeDiscounts}
    //     // onSubmitEditing={ (newPgIds) => onChangeProduct({
    //     //   key: 'product_group_ids',
    //     //   value: newPgIds,
    //     // }) }
    //     // onEmptySubmitEditing={ () => {
    //     //   p.op.alert('ไม่สามารถบันทึก', 'กรุณาเลือกอย่างน้อย 1 รายการราคาให้กับสินค้าชิ้นนี้')
    //     // }}
    //     // onEmptySubmitEditing={this._onChangeProductGroups}
    //   />
    // )
  }

  // _renderEnableMockingButton = () => {
  //   return null
  //   const isEnableVdMocking = _.has(this.state, 'isEnableVdMocking') ? this.state.isEnableVdMocking : false
  //   const txtBtnStartWith = isEnableVdMocking ? 'ปิด' : 'เปิด'
  //   return (
  //     <Button
  //       onPress={() => {
  //         this.setState({ isEnableVdMocking: !isEnableVdMocking })
  //       }}
  //       style={{
  //         marginTop: 40,
  //         marginBottom: 10,
  //         alignSelf: 'center',
  //         height: 28,
  //         width: '80%',
  //         backgroundColor: 'white',
  //         borderColor: STYLES.APP_MAIN,
  //         borderWidth: 1,
  //         borderRadius: 4,
  //       }}>
  //       <XText
  //         style={{
  //           flex: 1,
  //           textAlign: 'center',
  //           fontSize: STYLES.FONT_SIZE_NORMAL,
  //           color: STYLES.TEXT_INACTIVE,
  //         }}>
  //         {`${txtBtnStartWith}ใช้งานตัวอย่างส่วนลดขั้นบันได`}
  //       </XText>
  //     </Button>
  //   )
  // }

  // _renderVolumeDiscountCard(discountOf: 'my' | 'seller'): JSX.Element {
  //   const isEnableVdMocking = _.has(this.state, 'isEnableVdMocking') ? this.state.isEnableVdMocking : false
  //   if (!isEnableVdMocking) {
  //     return null
  //   }
  //
  //   if (!discountOf) {
  //     return null
  //   }
  //
  //   const { editingProduct, onChangeProduct } = this.props
  //   // const { mode } = this._getParams()
  //   const { mode } = this.state
  //   const vdsKey = `${discountOf}_vds`
  //   const vdsTypeKey = `${vdsKey}_type`
  //   const vds = editingProduct.get(vdsKey)
  //   const vds_type = editingProduct.get(vdsTypeKey)
  //
  //   if (
  //     (!vds && discountOf === 'seller') ||
  //     (!_.includes([ADD, EDIT_SELF, PULL_PRODUCT], mode) && (!vds || !vds_type || vds.size < 2)) ||
  //     (mode === ADD && discountOf === 'seller')
  //   ) {
  //     // log('ProductView _renderVolumeDiscountCard no vds of ', discountOf)
  //     // ถ้าไม่มี vds เลย ให้ซ่อนไป
  //     return null
  //   }
  //
  //   const txtVdTitle = this.OPTIONS_VOLUME_DISCOUNT_TITLES[discountOf]
  //   const txtVdSubtitle = this.OPTIONS_VOLUME_DISCOUNT_SUBTITLES[discountOf]
  //   // const txtVdType = this.OPTIONS_VOLUME_DISCOUNT_MENU_TYPES[vds_type]
  //   const txtVdColumn = this.OPTIONS_VOLUME_DISCOUNT_COLUMN_TYPES[vds_type]
  //   const isEditable = discountOf === 'my' && _.includes([ADD, EDIT_SELF, PULL_PRODUCT], mode)
  //   const isVisible =
  //     (discountOf === 'seller' && vds_type > 0) ||
  //     (discountOf === 'my' && (vds_type > 0 || _.includes([ADD, EDIT_SELF, PULL_PRODUCT], mode)))
  //
  //   let usePrefixOrSuffix
  //   const prefix = '฿'
  //   const suffix = '%'
  //   if (vds_type === 1) {
  //     usePrefixOrSuffix = prefix
  //   } else if (vds_type === 2) {
  //     usePrefixOrSuffix = suffix
  //   }
  //   return isVisible ? (
  //     <Card
  //       // @ts-ignore CARD_COMMON
  //       style={[CARD_COMMON, NO_MARGIN, { marginTop: 5, paddingTop: 5, paddingBottom: 5 }]}>
  //       <Box cardBody={true} style={{ paddingHorizontal: 3, paddingVertical: 5 }}>
  //         <VStack>
  //           {/* Title */}
  //           <Row>
  //             <VDColTitle size={1}>
  //               <VDTitle>{txtVdTitle}</VDTitle>
  //               <VDSubtitle>{txtVdSubtitle}</VDSubtitle>
  //             </VDColTitle>
  //           </Row>
  //           {isEditable ? (
  //             <Row>
  //               <VDColTitle size={1}>
  //                 <Segment
  //                   onSegmentChange={this._onChangeVolumeDiscountType}
  //                   selectedIndex={vds_type}
  //                   // onChange={ newVdType => {
  //                   //   const newVdTypeIndex = this.OPTIONS_VOLUME_DISCOUNT_MENU_TYPES
  //                   //     .findIndex(type => type === newVdType)
  //                   //   onChangeProduct({ key: vdsTypeKey, value: newVdTypeIndex })
  //                   //
  //                   //   if (_.includes([1, 2], newVdTypeIndex) && _.includes([ADD, EDIT_SELF], mode)) {
  //                   //     // Set To Default Value
  //                   //     onChangeProduct({ key: vdsKey, value: this.VOLUME_DISCOUNT_TEMPLATE })
  //                   //   }
  //                   // }}
  //                   options={this.OPTIONS_VOLUME_DISCOUNT_MENU_TYPES}
  //                   // selectedValue={txtVdType}
  //                 />
  //               </VDColTitle>
  //             </Row>
  //           ) : null}
  //         </VStack>
  //       </Box>
  //       {vds_type > 0 ? (
  //         <Box cardBody={true} style={{ paddingHorizontal: 12, paddingVertical: 8 }}>
  //           <RangeAdjuster
  //             disabled={!isEditable}
  //             metadata={[
  //               {
  //                 // key: discountKey,
  //                 prefix,
  //                 suffix,
  //                 usePrefixOrSuffix,
  //                 key: 'discount',
  //                 label: vds_type > 0 ? `ส่วนลด (${txtVdColumn})` : 'ส่วนลด',
  //                 span: 2,
  //                 defaultValue: '',
  //                 keyboardType: 'numeric',
  //                 filteredMethod: (newValue: string) => {
  //                   const newDiscount = !_.isNaN(newValue) && !_.isNull(newValue) && !_.isUndefined(newValue) ? parseFloat(newValue) : null
  //                   log('onChangeMetaField vds_type => ', vds_type)
  //                   log('onChangeMetaField newDiscount => ', newDiscount)
  //                   if (vds_type === 1 && _.isNumber(newDiscount)) {
  //                     if (newDiscount >= 0 && newDiscount <= 100) {
  //                       return newDiscount
  //                     } else if (newDiscount > 100) {
  //                       return 100
  //                     } else {
  //                       return 0
  //                     }
  //                   } else if (vds_type === 2 && _.isNumber(newDiscount)) {
  //                     if (newDiscount >= 0) {
  //                       return newDiscount
  //                     } else {
  //                       return 0
  //                     }
  //                   }
  //                   return 0
  //                 },
  //               },
  //             ]}
  //             data={vds}
  //             // onChange={newVds => {
  //             //   onChangeProduct({ key: vdsKey, value: newVds })
  //             // }}
  //             onChange={this._onChangeVolumeDiscount}
  //             labelAddButton={'เพิ่มช่วงส่วนลด'}
  //             labelLastEnd={'ขึ้นไป'}
  //           />
  //         </Box>
  //       ) : null}
  //     </Card>
  //   ) : null
  // }

  _onNavRef = (key: string, r) => {
    // util.pushNavRef(this, 'inputRefs', key, r)
    // util.pushNativeBaseRef(this.inputRefs, NAME, r)
    // log('onNavRef key => ', key)
    // log('onNavRef r => ', r)
    // log('onNavRef this.inputRefs => ', this.inputRefs)
    // if (this.sktRef) {
    //   this.sktRef.forceUpdate()
    // }
  }

  _onNavFocus = (key) => {
    // log('onNavFocus key => ', key)
    this.setState({ currentRef: key })
  }

  // _renderProductImages = (): JSX.Element => {
  //   const { submitting, uploading, showUploaded } = this.state
  //   const { txtShareContent, shareClipboardToastMessage } = this._getShareClipboardProperties()
  //   let isEditable = this.isEditableProductMap.get('img_uris')
  //   isEditable = isEditable && !submitting && !uploading
  //   return (
  //     <ImgManager
  //       fileNamePrefix={config.s3_prefix_type_product}
  //       // metadata={[
  //       // { key: 'image', width: 200, height: 120 },
  //       // { key: 'thumbnail', width: 150, height: 80 },
  //       // ]}
  //       ref={r => r && (this.imgManagerRef = r)}
  //       uploading={uploading}
  //       showUploaded={showUploaded}
  //       shareClipboardText={txtShareContent}
  //       shareClipboardToastMessage={shareClipboardToastMessage}
  //       readonly={!isEditable}
  //     />
  //   )
  // }

  renderProductImages = (): JSX.Element => {
    const { editingProduct } = this.props
    const { submitting, uploading, showUploaded } = this.state
    const { txtShareContent, shareClipboardToastMessage } = this._getShareClipboardProperties()
    let isEditable = this.isEditableProductMap.get('img_uris')
    isEditable = isEditable && !submitting && !uploading
    const img_uris = editingProduct.get('img_uris') ? editingProduct.get('img_uris').toArray() : []
    const thumbnail_uris = editingProduct.get('thumbnail_uris') ? editingProduct.get('thumbnail_uris').toArray() : []
    const tiny_img_uris = editingProduct.get('tiny_img_uris') ? editingProduct.get('tiny_img_uris').toArray() : []
    return (
      <ImgManager
        fileNamePrefix={config.s3_prefix_type_product}
        // metadata={[
        // { key: 'image', width: 200, height: 120 },
        // { key: 'thumbnail', width: 150, height: 80 },
        // ]}
        ref={(r) => r && (this.imgManagerRef = r)}
        initialImageUrls={{
          p: img_uris,
          t: thumbnail_uris,
          s: tiny_img_uris,
        }}
        uploading={uploading}
        showUploaded={showUploaded}
        shareClipboardText={txtShareContent}
        shareClipboardToastMessage={shareClipboardToastMessage}
        readonly={!isEditable}
      />
    )
  }

  // _checkSKU = async (sku: boolean, product_id: number) => {
  //   // await new Promise((resolve) => setTimeout(resolve, 500))
  //   if (sku) {
  //     this._downloadSKUAndUPC(2, product_id)
  //   } else {
  //     p.op.showConfirmationOkOnly('ไม่พบรหัส SKU กับสินค้าตัวนี้', 'กรุณาระบุรหัส SKU กับสินค้าตัวนี้ก่อน แล้วทำรายการนี้ใหม่อีกครั้ง')
  //   }
  // }

  // _checkUPC = async (upc: boolean, product_id: number) => {
  //   // const { editingProduct } = this.props
  //   // if (_.isNil(editingProduct.get('parent_store_name'))) {
  //   // await new Promise((resolve) => setTimeout(resolve, 500))
  //   if (upc) {
  //     this._downloadSKUAndUPC(1, product_id)
  //   } else {
  //     p.op.showConfirmationOkOnly('ไม่พบรหัส UPC กับสินค้าตัวนี้', 'กรุณาระบุรหัส UPC กับสินค้าตัวนี้ก่อน แล้วทำรายการนี้ใหม่อีกครั้ง')
  //   }
  // }

  // _downloadBarcodeSUKAndUPC = async () => {
  //   // 1 upc // 2 sku // 3 upc sku
  //   // s_use_product_barcode
  //   const { editingProduct, selectedStore } = this.props
  //   const store_id = editingProduct.get('store_id')
  //   const barcode_type = selectedStore.get('s_use_product_barcode')
  //   const product_id = editingProduct.get('id')
  //   let sku = false
  //   let upc = false
  //   editingProduct.get('variants').map((variants) => {
  //     if (!sku && !_.isNil(variants.get('sku'))) {
  //       sku = true
  //     }
  //     if (!upc && !_.isNil(variants.get('upc'))) {
  //       upc = true
  //     }
  //   })
  //   const printingBarcode: IPrintingBarcodeStoreSetting = selectedStore.has('printing_barcode')
  //     ? selectedStore.get('printing_barcode').toJS()
  //     : xCONS.DEFAULT_BARCODE
  //   const pref = printingBarcode.p
  //   // console.log('printingBarcode => ', printingBarcode)
  //   // console.log('sku => ', sku)
  //   // console.log('upc => ', upc)
  //   if (pref.c === 0 && pref.d === 0 && pref.v === 0) {
  //     this._downloadSKUAndUPC(null, product_id)
  //     return
  //   }
  //   if (!sku && !upc) {
  //     p.op.showConfirmationOkOnly('', 'สินค้าตัวนี้ไม่มีรหัสสินค้า SKU หรือ UPC')
  //     return
  //   }
  //   if (barcode_type === 0) {
  //     p.op.showConfirmation(
  //       'ท่านไม่เปิดใช้งาน SKU หรือ UPC',
  //       'ท่านต้องการเปิดใช้งาน SKU หรือ UPC ตอนนี้หรือไม่ ?',
  //       // () => this.props.navigation.dispatch(NavActions.navToStoreSettingStoreProductSN),
  //       this.navToStoreSettingStoreProductSN
  //     )
  //     return
  //   }
  //   if (sku && upc) {
  //     const actionProps = RECEIVER_ADDR_ACTIONS.SKU_AND_UPC
  //     p.op.showActionSheet(
  //       {
  //         options: actionProps.BUTTONS,
  //         cancelButtonIndex: actionProps.ACTION_INDEXES.CANCEL,
  //         // destructiveButtonIndex: null,
  //         title: actionProps.TITLE,
  //         // buttonRef: this.hamburgerMenuRef,
  //       },
  //       (buttonIndex) => {
  //         switch (+buttonIndex) {
  //           case actionProps.ACTION_INDEXES.SKU:
  //             this._checkSKU(sku, product_id)
  //             break
  //           case actionProps.ACTION_INDEXES.UPC:
  //             this._checkUPC(upc, product_id)
  //             break
  //           case actionProps.ACTION_INDEXES.CANCEL:
  //             break
  //           default:
  //             break
  //         }
  //       }
  //     )
  //   } else if (sku && !upc) {
  //     this._checkSKU(sku, product_id)
  //     if (barcode_type === 0 || barcode_type === 1) {
  //       p.op.showConfirmation(
  //         'ท่านไม่เปิดใช้งาน SKU',
  //         'ท่านต้องการเปิดใช้งาน SKU ตอนนี้หรือไม่ ?',
  //         // () => this.props.navigation.dispatch(NavActions.navToStoreSettingStoreProductSN),
  //         this.navToStoreSettingStoreProductSN
  //       )
  //     }
  //   } else if (!sku && upc) {
  //     if (barcode_type === 0 || barcode_type === 2) {
  //       p.op.showConfirmation(
  //         'ท่านไม่เปิดใช้งาน UPC',
  //         'ท่านต้องการเปิดใช้งาน UPC ตอนนี้หรือไม่ ?',
  //         // () => this.props.navigation.dispatch(NavActions.navToStoreSettingStoreProductSN),
  //         this.navToStoreSettingStoreProductSN
  //       )
  //       return
  //     }
  //     this._checkUPC(upc, product_id)
  //   }
  // }

  // _downloadSKUAndUPC = async (barcode_type: number, product_id: number) => {
  //   // barcode_type = 1 = UPC , 2 = SKU for api only
  //   const { selectedStore } = this.props
  //   const newDate = new Date()
  //   const day = newDate.getDate()
  //   const month = newDate.getMonth() + 1
  //   const year = newDate.getFullYear()
  //   const hours = newDate.getHours()
  //   const minute = newDate.getMinutes()
  //   const seconde = newDate.getSeconds()
  //   const store_id = selectedStore.get('id')
  //   // const print_template_id = 2

  //   const rootAPI = settings.app.GO_API_ROOT
  //   let url = `${rootAPI}/product/print_label?store_id=${store_id}&product_id=${product_id}`
  //   if (!_.isNil(barcode_type)) {
  //     url += `&barcode_type=${barcode_type}`
  //   }
  //   // console.log('url => ', url)
  //   const fileName = `${day}_${month}_${year}__${hours}_${minute}_${seconde}.pdf`
  //   if (_.isNil(store_id) || _.isNil(product_id)) {
  //     return
  //   }
  //   // await utilN.openPDFFromUrl(url, fileName)
  //   await this.openPDFFromUrl(url, fileName)
  // }
  /* // ย้ายไปเมนูใหม่ :. หากไม่มีปัญหาให้กลับมาลบ 30/08/2019 */
  // _renderCopyClipboardButton = (): JSX.Element | null => {
  //   const { editingProduct, selectedStore } = this.props
  //   const { mode } = this.state
  //   if (!Map.isMap(editingProduct)) {
  //     return null
  //   }

  //   if (!_.includes([VIEW_SELF, VIEW_SELLER, PULL_SELLER_PRODUCT_TO_ORDER, PULL_MY_PRODUCT_TO_ORDER], mode)) {
  //     return null
  //   }
  //   const txtProductName = editingProduct.get('name') || ''
  //   const txtProductDesc = editingProduct.get('description') || ''
  //   const txtClipboard = `${txtProductName}\n\n${txtProductDesc}`

  //   const onPressCopyClipboard = () => {
  //     this._copyTextToClipboard('ชื่อและคำอธิบายสินค้า', txtClipboard)
  //   }
  //   const store_id = editingProduct.get('store_id')
  //   const barcode_type = selectedStore.get('s_use_product_barcode')
  //   const product_id = editingProduct.get('id')
  //   return (
  //     <VStack style={{ flex: 1, marginTop: 4, marginBottom: 8 }}>
  //       <Row>
  //         <VStack>
  //           <TouchableOpacity
  //             style={[
  //               STYLES.BTN_MODE_ACTIVE_SECONDARY,
  //               {
  //                 flex: 0,
  //                 width: 160,
  //                 padding: 2,
  //                 borderWidth: 1,
  //                 borderRadius: 7,
  //                 // marginRight: 3
  //                 marginLeft: 12,
  //                 paddingTop: 6,
  //                 paddingBottom: 6,
  //               },
  //             ]}
  //             onPress={onPressCopyClipboard}>
  //             <XText
  //               style={[
  //                 STYLES.BTN_TEXT_MODE_ACTIVE_SECONDARY,
  //                 {
  //                   flex: 0,
  //                   textAlign: 'center',
  //                   fontSize: STYLES.FONT_SIZE_SMALLER,
  //                 },
  //               ]}>
  //               {'คัดลอกชื่อและคำอธิบายสินค้า'}
  //             </XText>
  //           </TouchableOpacity>
  //         </VStack>
  //         {/* // ย้ายไปเมนูใหม่ :. หากไม่มีปัญหาให้กลับมาลบ 30/08/2019 */}
  //         {/* {mode != VIEW_SELLER ? (
  //           <VStack style={{ alignItems: 'flex-end', paddingRight: 10 }}>
  //             <TouchableOpacity
  //               style={{
  //                 width: 80,
  //                 padding: 2,
  //                 borderWidth: 1,
  //                 borderRadius: 7,
  //                 // marginRight: 3
  //                 marginLeft: 12,
  //                 // marginTop: -5,
  //                 borderColor: COLORS.APP_MAIN,
  //                 backgroundColor: COLORS.BG_LIGHT_GREY,
  //               }}
  //               onPress={() => this._downloadBarcodeSUKAndUPC()}>
  //               <View style={{ flexDirection: 'row' }}>
  //                 <XIcon name={'print'} family={'MaterialIcons'} size={STYLES.FONT_ICON_NORMAL} style={{ marginLeft: 5 }} />
  //                 <XText
  //                   style={{
  //                     textAlign: 'center',
  //                     fontSize: STYLES.FONT_SIZE_SMALLER,
  //                     paddingTop: 4,
  //                     paddingLeft: 0,
  //                     marginLeft: 5,
  //                     color: COLORS.APP_MAIN,
  //                   }}>
  //                   บาร์โค้ด
  //                 </XText>
  //               </View>
  //             </TouchableOpacity>
  //           </VStack>
  //         ) : null} */}
  //         {/*<VStack style={{ flex: 0, width: 174, justifyContent: 'flex-end', alignItems: 'center' }}>*/}
  //         {/*<Button*/}
  //         {/*style={{*/}
  //         {/*height: 28,*/}
  //         {/*backgroundColor: COLORS.BG_LIGHT_GREY,*/}
  //         {/*borderColor: COLORS.APP_MAIN,*/}
  //         {/*borderWidth: 1,*/}
  //         {/*borderRadius: 4,*/}
  //         {/*paddingHorizontal: 4,*/}
  //         {/*}}>*/}
  //         {/*<XText style={{*/}
  //         {/*textAlign: 'center',*/}
  //         {/*fontSize: STYLES.FONT_SIZE_NORMAL*/}
  //         {/*}}>*/}
  //         {/*{'คัดลอกชื่อและคำอธิบายสินค้า'}*/}
  //         {/*</XText>*/}
  //         {/*</Button>*/}
  //         {/*</VStack>*/}
  //       </Row>
  //     </VStack>
  //   )
  // }

  _renderProductName = (): JSX.Element => {
    const { editingProduct, onChangeProduct } = this.props
    const { submitting, uploading, mode } = this.state
    if (!Map.isMap(this.isEditableProductMap) || !editingProduct || !_.isFunction(onChangeProduct)) {
      return null
    }

    const txtProductName = editingProduct.get('name') || ''
    let isEditable = this.isEditableProductMap.get('name')
    isEditable = isEditable && !submitting && !uploading

    return (
      <HStack w='full' alignItems='center' pt='1' space='2'>
        <XText variant='inactive' mr='2'>
          ชื่อ
        </XText>
        {isEditable ? (
          <XInput
            flex={1}
            // onRef={ r => util.pushNativeBaseRef(this.inputRefs, NAME, r) }
            // onFocus={ () => {
            //  if (isEditable) {
            //     this.setState({ currentRef: NAME })
            //   }
            // }}
            // navKey={NAME}
            // onNavRef={this._onNavRef}
            // onNavFocus={this._onNavFocus}
            // onNavRef={ (key, r) => {
            //     util.pushNativeBaseRef(this.inputRefs, key, r)
            //     log('onNavRef key => ', key)
            //     log('onNavRef r => ', r)
            //     log('onNavRef this.inputRefs => ', this.inputRefs)
            //     if (this.sktRef) {
            //       this.sktRef.forceUpdate()
            //     }
            //   }
            // }
            // onNavFocus={ (key) => {
            //   log('onNavFocus key => ', key)
            //   this.setState({currentRef: key})
            // }}
            placeholder='ระบุชื่อสินค้า'
            isDisabled={!isEditable}
            onChangeText={this._onChangeProductName}
            value={txtProductName}
          />
        ) : (
          <XText
            flex={1}
            onPress={() => {
              Clipboard.setString(txtProductName)
              p.op.showToast('คัดลอกชื่อสินค้าแล้ว', 'success')
            }}
            // style={{
            //   width: '100%',
            //   minHeight: 10,
            //   fontSize: STYLES.FONT_SIZE_NORMAL,
            //   color: COLORS.TEXT_ACTIVE,
            // }}
          >
            {txtProductName}
          </XText>
        )}
        {!_.includes([ADD, EDIT_SELF], mode) ? (
          <XIconButton name='copy' family='FontAwesome' variant='outline' onPress={() => this._renderCopyClipboardButton()} />
        ) : null}
      </HStack>
    )
    // {/*{*/}
    // {/*isEditable*/}
    // {/*? (*/}
    // {/*<Input*/}
    // {/*//selectTextOnFocus*/}
    // {/*ref={ r => {*/}
    // {/*if (isEditable) {*/}
    // {/*util.pushNativeBaseRef(this.inputRefs, NAME, r)*/}
    // {/*}*/}
    // {/*}}*/}
    // {/*onFocus={ () => {*/}
    // {/*if (isEditable) {*/}
    // {/*this.setState({ currentRef: NAME })*/}
    // {/*}*/}
    // {/*}}*/}
    // {/*style={[s.txtHLBG, s.formNormalText]}*/}
    // {/*value={ editingProduct.get(NAME) }*/}
    // {/*disabled={ !isEditable }*/}
    // {/*placeholder='ป้อนชื่อสินค้าของคุณ'*/}
    // {/*placeholderTextColor={ COLORS.TEXT_INACTIVE }*/}
    // {/*onChangeText={ text => onChangeProduct({ key: NAME, value: text }) }*/}
    // {/*/>*/}
    // {/*) : (*/}
    // {/*<XText*/}
    // {/*onPress={() => {*/}
    // {/*Clipboard.setString(editingProduct.get(NAME))*/}
    // {/*util.showSuccessToast('คัดลอกชื่อสินค้าแล้ว')*/}
    // {/*}}*/}
    // {/*style={[s.txtMultiple, { borderBottomWidth: 0 }]}>*/}
    // {/*{ editingProduct.get(NAME) }*/}
    // {/*</XText>*/}
    // {/*)*/}
    // {/*}*/}

    // return (
    //   <Item
    //     inlineLabel={ isEditable }
    //     stackedLabel={ !isEditable }
    //     style={s.formItem}>
    //     <Label style={s.txtNormal}>
    //       {'ชื่อสินค้า'}
    //     </Label>
    //     {/*{*/}
    //     {/*MODE === 'dev'*/}
    //     {/*? <Label style={s.txtNormal}>{` (product_id=${editingProduct.get('id')})`}</Label>*/}
    //     {/*: null*/}
    //     {/*}*/}
    //     <InputGroup
    //       selectTextOnFocusGroup
    //       borderType='regular'
    //       style={{ paddingTop: 5, borderBottomWidth: 0 }}>
    //       {
    //         isEditable
    //           ? (
    //             <Input
    //               //selectTextOnFocus
    //               ref={ r => {
    //                 if (isEditable) {
    //                   util.pushNativeBaseRef(this.inputRefs, NAME, r)
    //                 }
    //               }}
    //               onFocus={ () => {
    //                 if (isEditable) {
    //                   this.setState({ currentRef: NAME })
    //                 }
    //               }}
    //               style={[s.txtHLBG, s.formNormalText]}
    //               value={ editingProduct.get(NAME) }
    //               disabled={ !isEditable }
    //               placeholder='ป้อนชื่อสินค้าของคุณ'
    //               placeholderTextColor={ COLORS.TEXT_INACTIVE }
    //               onChangeText={ text => onChangeProduct({ key: NAME, value: text }) }
    //             />
    //           ) : (
    //             <XText
    //               onPress={() => {
    //                 Clipboard.setString(editingProduct.get(NAME))
    //                 util.showSuccessToast('คัดลอกชื่อสินค้าแล้ว')
    //               }}
    //               style={[s.txtMultiple, { borderBottomWidth: 0 }]}>
    //               { editingProduct.get(NAME) }
    //             </XText>
    //           )
    //       }
    //     </InputGroup>
    //   </Item>
    // )
  }

  _renderProductDescription = () => {
    const { editingProduct, onChangeProduct } = this.props
    const { submitting, uploading } = this.state
    if (!Map.isMap(this.isEditableProductMap) || !editingProduct || !_.isFunction(onChangeProduct)) {
      return null
    }

    let isEditable = this.isEditableProductMap.get('description')
    isEditable = isEditable && !submitting && !uploading

    const txtProductDesc = editingProduct.get('description') || ''

    return (
      <VStack mt='1' w='full'>
        <XText variant='inactive'>คำอธิบาย</XText>
        {isEditable ? (
          <XInput
            minH='16'
            maxH='40'
            textAlignVertical='top'
            // style={{ minHeight: 120 }}
            // navKey='description'
            // onNavRef={this._onNavRef}
            // onNavFocus={this._onNavFocus}
            placeholder='ระบุคำอธิบายของสินค้า เช่น สภาพการใช้งาน สรรพคุณ นำเข้าจากที่ใด เป็นต้น'
            isDisabled={!isEditable}
            onChangeText={this._onChangeProductDesc}
            value={txtProductDesc}
            multiline
          />
        ) : (
          <XText
            minH='16'
            onPress={() => {
              Clipboard.setString(txtProductDesc)
              p.op.showToast('คัดลอกคำอธิบายสินค้าแล้ว', 'success')
            }}
            // style={{
            //   // width: '100%',
            //   minHeight: 80,
            //   flexBasis: 'auto',
            //   fontSize: STYLES.FONT_SIZE_NORMAL,
            //   color: COLORS.TEXT_ACTIVE,
            //   paddingBottom: 10,
            // }}
          >
            {txtProductDesc}
          </XText>
        )}
      </VStack>
    )

    // return isEditable
    //   ? (
    //     <Item stackedLabel style={{ borderBottomWidth: 0, height: 124, minHeight: 124 }}>
    //       <Label style={s.txtNormal}>คำอธิบายสินค้า</Label>
    //       <Input
    //         //selectTextOnFocus
    //         ref={ r => {
    //           if (isEditable) {
    //             util.pushNativeBaseRef(this.inputRefs, 'description', r)
    //             // util.pushNavRef(this, 'inputRefs', 'description', r)
    //           }
    //         }}
    //         onFocus={ () => {
    //           if (isEditable) {
    //             this.setState({ currentRef: 'description' })
    //           }
    //         }}
    //         value={ editingProduct.get('description') }
    //         disabled={ !isEditable }
    //         onChangeText={ text => onChangeProduct({ key: 'description', value: text }) }
    //         style={[
    //           isEditable ? s.txtMultipleHL : s.txtMultiple,
    //           {
    //             borderBottomWidth: 0,
    //             ...Platform.select({
    //               android: {
    //                 marginTop: 10,
    //               },
    //             })
    //           }
    //         ]}
    //         placeholder='ป้อนรายละเอียดสินค้า เช่น สภาพการใช้งาน สรรพคุณ นำเข้าจากที่ใด เป็นต้น'
    //         placeholderTextColor={COLORS.TEXT_INACTIVE}
    //         multiline={true}
    //         numberOfLines={4}
    //       />
    //     </Item>
    //   )
    //   : (
    //     <View style={{ marginLeft: 15, marginTop: 10, marginBottom: 5 }}>
    //       <Label style={s.txtNormal}>คำอธิบายสินค้า</Label>
    //       <View style={{ paddingTop: 3, paddingLeft: 5}}>
    //         <XText
    //           allowFontScaling={false}
    //           onPress={() => {
    //             Clipboard.setString(editingProduct.get('description'))
    //             util.showSuccessToast('คัดลอกรายละเอียดสินค้าแล้ว')
    //           }}
    //           style={[s.txtMultiple, { borderBottomWidth: 0 }]}>
    //           { editingProduct.get('description') }
    //         </XText>
    //       </View>
    //     </View>
    //   )
  }

  _renderProductID = () => {
    const { editingProduct } = this.props
    const id = editingProduct.get('id') || ''
    if (!id) {
      return null
    }
    return (
      <HStack w='full'>
        <XText
          variant='inactive'
          fontSize='2xs'
          numberOfLines={1}
          // @ts-ignore
          onPress={() => {
            Clipboard.setString(id.toString())
            p.op.showToast(`คัดลอก ${id} แล้ว`, 'success')
          }}>
          {`ID:${id}`}
        </XText>
      </HStack>
    )
  }

  _copyTextToClipboard = (sourceName: string, txtSource: string): void => {
    try {
      Clipboard.setString(txtSource)
      p.op.showToast(`คัดลอก${sourceName}แล้ว`, 'success')
    } catch (err) {
      console.log(this, '_copyTextToClipboard err => ', err)
    }
  }

  _renderReportHistory = () => (
    <ReportHistory
      visible={this.state.isReportHistory}
      onRequestClose={() => this._handleCloseReportHistory()}
      downloadHistoryReport={(variant: IProductDetailVariantMap) => this._downloadHistoryReport(variant)}
      // @ts-ignore
      selectedProduct={this.props.selectedProduct}
      onChangeDateRange={(newDates: { begin: Dayjs; end: Dayjs }) => this._onChangeDateRange(newDates)}
    />
  )

  _renderCategoryListOverlay = () => {
    const { visibleCategoryList } = this.state
    const { fetchCategory, selectedStore, editingProduct } = this.props
    const allCategory = []
    const category = editingProduct.get(`category`)
    // const categoryInProduct = _.isNil(editingProduct.get(`category`))
    //   ? null
    //   : isImmutable(editingProduct.get(`category`))
    //   ? editingProduct.get(`category`).toJS()
    //   : editingProduct.get(`category`)
    // const visibleCategoryList = false
    const categoryId = !_.isNil(category) ? category.get('id') : null
    return (
      <BaseUICategoryListOverlay
        submit={(Cat: ICategory) => this._submitSeletedCategory(Cat)}
        mode='SELETED_CATEGORY'
        store_id={selectedStore.get(`id`)}
        fetchCategory={fetchCategory}
        categoetProps={allCategory}
        visible={visibleCategoryList}
        onRequestClose={() => this._closeCategoryList()}
        category_id={categoryId}
      />
    )
  }

  _renderPrintProductBarcode = () => (
    <PrintProductBarcode
      navigation={this.props.navigation}
      visible={this.state.isOpenPrintProductBarcode}
      onRequestClose={() => this._closePrintProductBarcode()}
      downloadPrintProductBarcode={(url: string, fileName: string) => this.openPDFFromUrl(url, fileName)}
      // @ts-ignore
      selectedProduct={this.props.editingProduct}
      selectedStore={this.props.selectedStore}
      pt_id={this.props.editingProduct.get('id')}
    />
  )

  renderMain2 = () => {
    util.logRender(this)
    const { editingProduct } = this.props
    const { mode, isInitialized } = this.state

    if (!isInitialized || !this.state.PRODUCT_VARIANT_ITEMS) {
      return (
        <XText textAlign='center' mt='20' flex={1} variant='inactive'>
          กำลังโหลดข้อมูล...
        </XText>
      )
      // return (
      //   <Spinner color={COLORS.APP_MAIN} size='small' />
      // )
    }

    // // TODO: refactor me out
    // const {
    //   shallRenderShippingRatesSeller,
    //   shallRenderShippingRatesMy,
    //   shippingRatesMy,
    //   shippingRatesSeller,
    //   shippingRateStatusesMy,
    //   shippingRateStatusesSeller,
    //   editableShippingRatesMy,
    // } = this._getMainProductRenderProperties(editingProduct, mode)

    if (!Map.isMap(editingProduct) || !_.includes(xCONS.PRODUCT_VIEW_MODE, mode)) {
      return null
    }

    const variants = editingProduct.get('variants') || List([])
    // const variantCount = variants.size || 0
    const isAddableVariants = this._isAddableVariants() || false

    return (
      <XContainer>
        {this._renderCategoryListOverlay()}
        {this._renderCustomHeader()}
        {this._renderReportHistory()}
        {/* {this._renderReportProductDailySales()} */}

        <FlatList
          // key={`${mode}_${isInitialized}`}
          // style={{ height: 600, width: '100%', borderWidth: 1, borderColor: 'red' }}
          keyExtractor={this._keyExtractorForVariants}
          refreshControl={
            !_.includes([ADD, EDIT_SELF, EDIT_SELLER], mode) ? (
              <RefreshControl refreshing={this.state.refreshing} onRefresh={this._onRefresh} />
            ) : null
          }
          // style={{ width: '100%' }}
          // scrollEnabled={false}
          // extraData={editingProduct}
          // removeClippedSubviews={true}
          extraData={editingProduct}
          data={variants.toArray()}
          ListHeaderComponent={
            <VStack w='full' px='2' space='2'>
              {/* { this._renderParentStoreName() } */}
              {/* <HStack>{this._renderProductImages()}</HStack> */}
              {/* <HStack >{this._renderCopyClipboardButton()}</HStack> */}
              <HStack w='full' py='1'>
                {this.renderProductImages()}
              </HStack>
              {this._renderProductName()}
              {this._renderProductDescription()}
              {/* {this.renderVolumeDiscountCard('seller')} */}
              {/* {this.renderVolumeDiscountCard('my')} */}
              {this._renderProductGroupCard()}
              {this._renderProductWarehouse()}
              {this._renderPulledProductStoreName()}
              {this._renderAllShippingRates()}
              {this._renderCategoryNewVersion()}
              {/* {this._renderLinkedToMkpChannels()} */}
              {this._renderMkpInfo()}
              {/* {this._renderProductVariants()} */}
              {/* { navigation.state.params.mode === 'add' ? this._renderAddMode() : <View /> } */}
              <Box h='8' w='full' />
            </VStack>
          }
          ListFooterComponent={
            <VStack w='full' px='2' space='2'>
              <HStack w='full' alignItems='center' justifyContent='center'>
                {this._renderAddVariantButton(isAddableVariants)}
              </HStack>

              <HStack w='full' pt='4' alignItems='center' justifyContent='center'>
                {this._renderDeleteProductButton()}
              </HStack>
            </VStack>
          }
          contentContainerStyle={{ paddingLeft: 8, paddingRight: 8 }}
          renderItem={this._renderAdapterVariantItem}
        />
      </XContainer>
    )
  }

  _renderReservedProductInOrdersOverlay = () => {
    const { isReservedProductOverlayVisible = false } = this.state
    const { navigation, selectedProduct } = this.props
    // const overrideRequestBody: Partial<IFetchOrdersRequestBody> = {}
    // const variants = selectedProduct.get('variants')
    // overrideRequestBody.unshipped_pp_ids = []
    // for (let i = 0; i < variants.size; i++) {
    //   const vPpId = variants.getIn([i, 'pp_id'])
    //   overrideRequestBody.unshipped_pp_ids.push(vPpId)
    // }

    // navigation.dispatch(
    //   NavActions.navToReservedProductsOrderListView({
    //     store_id: util.getNavParam(this.props, 'store_id'),
    //     overrideRequestBody,

    //     // เนื่องจาก default ใหม่ของเรามัน 2 เดือน การ search เราเลยต้องปรับใหม่เป็น 5 ปีเพื่อให้มันค้นได้ทั่วถึงมากขึ้น
    //     ordersDefaultFilterSetting: {
    //       createOrderDateRangeOptionKey: xCONS.DATERANGE_SELECTOR.OPTION.Custom,
    //       createOrderFrom: dayjs().subtract(5, 'years').startOf('day'),
    //       createOrderTo: dayjs().endOf('day'),
    //     },
    //   })
    // )

    const isAllChecked = this.isReservedProductAllChecked()
    const isNavToReservedProductsOrdersDisabled = this.isNavToReservedProductsOrderListViewDisabled()
    const totalVariantCount = this.getVariantCount()
    const checkVariantCount = this.getCheckedReservedProductVariantCount()

    return (
      <XOverlay visible={isReservedProductOverlayVisible} onRequestClose={this.closeReservedProductInOrdersOverlay}>
        <XCustomHeader
          title='เลือกตัวเลือกสินค้าติดจอง'
          headerRightProps={{ closeIcon: true, onPressItem: this.closeReservedProductInOrdersOverlay }}
        />
        <VStack w='full'>
          <XScrollView h='350px'>{this._renderCheckReservedOrdersItems()}</XScrollView>
          <HStack w='full' px='1.5' pt='1' alignItems='center' space='1'>
            <XIconButton
              onPress={this.onPressToggleAllCheckReservedProduct}
              family='MaterialCommunityIcons'
              name={isAllChecked ? 'checkbox-multiple-marked-circle' : 'checkbox-blank-circle-outline'}
              // colorScheme={isAllChecked ? 'success' : 'muted'}
            />
            <HStack alignItems='center'>
              <XText minW='80px'>เลือกทั้งหมด</XText>
            </HStack>
            <HStack flex={1} alignItems='center' justifyContent='flex-end'>
              <XText>{`${checkVariantCount}/${totalVariantCount}`}</XText>
            </HStack>
          </HStack>
          <HStack w='full' px='1.5' py='1'>
            <XButton
              w='full'
              h='11'
              disabled={isNavToReservedProductsOrdersDisabled}
              isDisabled={isNavToReservedProductsOrdersDisabled}
              onPress={this.navToReservedProductsOrderListView}>
              ดูออเดอร์ที่มีสินค้าติดจอง
            </XButton>
          </HStack>
        </VStack>
      </XOverlay>
    )
  }

  _renderCheckReservedOrdersItems = () => {
    const { navigation, selectedProduct } = this.props
    const variants = selectedProduct.get('variants') || List([])
    return (
      <VStack w='full' p='1' space='1' bg='muted.200'>
        {variants.map(this._renderCheckReservedOrdersItem)}
      </VStack>
    )
  }

  _renderCheckReservedOrdersItem = (vm: IProductDetailVariantMap, index: number) => {
    const ppId = vm.get('pp_id')
    const vName = vm.get('name')
    const sku = vm.get('sku')
    const isChecked = this.isReservedProductChecked(ppId)
    return (
      <XCard key={`${index}_${ppId}`} w='full' onPress={this.getOnPressToggleCheckReservedProductFn(ppId)}>
        <XCard.Body>
          <HStack w='full' alignItems='center' space='1.5'>
            <XIcon family='FontAwesome' name={isChecked ? 'check-circle' : 'circle-o'} colorScheme={isChecked ? 'success' : 'muted'} />
            <VStack flex={1}>
              <XText>{vName}</XText>
            </VStack>
            {sku ? (
              <VStack w='150px'>
                <XText variant='inactive'>{sku}</XText>
              </VStack>
            ) : null}
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  renderMain = () => {
    util.logRender(this)
    const { editingProduct } = this.props
    const { mode, isInitialized } = this.state

    if (!isInitialized) {
      return (
        <XText textAlign='center' mt='20' flex={1} variant='inactive'>
          กำลังโหลดข้อมูล...
        </XText>
      )
      // return (
      //   <Spinner color={COLORS.APP_MAIN} size='small' />
      // )
    }

    // // TODO: refactor me out
    // const {
    //   shallRenderShippingRatesSeller,
    //   shallRenderShippingRatesMy,
    //   shippingRatesMy,
    //   shippingRatesSeller,
    //   shippingRateStatusesMy,
    //   shippingRateStatusesSeller,
    //   editableShippingRatesMy,
    // } = this._getMainProductRenderProperties(editingProduct, mode)
    return (
      <XContainer>
        {this._renderCategoryListOverlay()}
        {this._renderReservedProductInOrdersOverlay()}
        {this._renderChooseVariantImageFromProductImagesOverlay()}
        {this._renderCustomHeader()}
        {this._renderReportHistory()}
        {this._renderPrintProductBarcode()}
        {/* {this._renderReportProductDailySales()} */}
        <XContent
          refreshControl={
            !_.includes([ADD, EDIT_SELF, EDIT_SELLER], mode) ? (
              <RefreshControl refreshing={this.state.refreshing} onRefresh={this._onRefresh} />
            ) : null
          }
          extraScrollHeight={80}
          // @ts-ignore
          keyboardShouldPersistTaps={xCONS.KEYBOARD_PERSIST_TAB_MODE}
          // keyboardDismissMode='on-drag'
          enableResetScrollToCoords={false}
          padder={false}
          enableOnAndroid
          // ref={(r) => r && (this.scrollViewRef = r._root)}
          onContentSizeChange={(contentWidth, contentHeight) => {
            this.scrollToBottomY = contentHeight
          }}
          _contentContainerStyle={{ py: 1, px: 2, flexWrap: 'wrap' }}>
          <XCard w='full' flexWrap='wrap'>
            <XCard.Body px='2' py='2'>
              <VStack w='full' space='2'>
                {/* { this._renderParentStoreName() } */}
                {/* <HStack>{this._renderProductImages()}</HStack> */}
                {/* <HStack >{this._renderCopyClipboardButton()}</HStack> */}
                {/* <XButton
                  onPress={() => {
                    const { navigation, selectedProduct } = this.props
                    const overrideRequestBody: Partial<IFetchOrdersRequestBody> = {}
                    const variants = selectedProduct.get('variants')
                    overrideRequestBody.unshipped_pp_ids = []
                    for (let i = 0; i < variants.size; i++) {
                      const vPpId = variants.getIn([i, 'pp_id'])
                      overrideRequestBody.unshipped_pp_ids.push(vPpId)
                    }

                    navigation.dispatch(
                      NavActions.navToReservedProductsOrderListView({
                        store_id: util.getNavParam(this.props, 'store_id'),
                        overrideRequestBody,

                        // เนื่องจาก default ใหม่ของเรามัน 2 เดือน การ search เราเลยต้องปรับใหม่เป็น 5 ปีเพื่อให้มันค้นได้ทั่วถึงมากขึ้น
                        ordersDefaultFilterSetting: {
                          createOrderDateRangeOptionKey: xCONS.DATERANGE_SELECTOR.OPTION.Custom,
                          createOrderFrom: dayjs().subtract(5, 'years').startOf('day'),
                          createOrderTo: dayjs().endOf('day'),
                        },
                      })
                    )
                  }}>
                  Nav to ReservedProductsOrderListView
                </XButton> */}
                <HStack w='full' py='1'>
                  {this.renderProductImages()}
                </HStack>
                {this._renderProductName()}
                {this._renderProductID()}
                {this._renderProductDescription()}
                {/* {this.renderVolumeDiscountCard('seller')} */}
                {/* {this.renderVolumeDiscountCard('my')} */}
                {this._renderProductGroupCard()}
                {this._renderProductWarehouse()}
                {this._renderPulledProductStoreName()}
                {this._renderAllShippingRates()}
                {this._renderCategoryNewVersion()}
                {/* {this._renderLinkedToMkpChannels()} */}
                {this._renderMkpInfo()}
                {this._renderProductVariants()}
                {/* { navigation.state.params.mode === 'add' ? this._renderAddMode() : <View /> } */}
              </VStack>
            </XCard.Body>
          </XCard>
          {/* {this._renderDeleteProductButton()} */}
          {/* {this._renderEnableMockingButton()} */}
        </XContent>
        {/* <StickyKeyboardTab
          // ref={ skt => skt && (this.sktRef = skt) }
          // key={ _.isArray(this.inputRefs) ? `skt_${this.inputRefs.length}_${this.state.currentRef}` : 'skt' }
          // arrayOfRefs={ this.inputRefs }
          // arrayOfRefs={ this.state.inputRefs }
          // currentRef={ this.state.currentRef }
          scrollRef={this.scrollViewRef}
        /> */}
      </XContainer>
    )
  }

  // _getArrayOfRefs = () => _.isArray(this.inputRefs) ? this.inputRefs : null
  render() {
    return this.renderMain()
    // return this.renderMain2()
  }
}
