import React from 'react'
import { I18nManager, View } from 'react-native'

import { computePosition, styles } from './ContextMenu'

/**
Simplified version of ContextMenu without animation.
*/
export default class NotAnimatedContextMenu extends React.Component {
  render() {
    // @ts-ignore
    const { style, children, layouts, ...other } = this.props
    // @ts-ignore
    const position = computePosition(layouts, I18nManager.isRTL)
    return (
      <View
        // @ts-ignore
        {...other}
        // @ts-ignore
        style={[styles.options, style, position]}>
        {children}
      </View>
    )
  }
}
