import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { View, Text } from 'react-native'
import { debug } from './logger'
import { makeTouchable } from './helpers'
import { withCtx } from './MenuProvider'
import { MenuContextProps, MenuTriggerProps } from './types'

export class MenuTrigger extends Component<MenuTriggerProps & MenuContextProps> {
  static defaultProps = {
    disabled: false,
    customStyles: {},
    testID: undefined,
  }

  _onPress() {
    debug('trigger onPress')
    // @ts-ignore
    this.props.onPress && this.props.onPress()
    // @ts-ignore
    this.props.ctx.menuActions.openMenu(this.props.menuName)
  }

  render() {
    const {
      disabled,
      // @ts-ignore
      onRef,
      text,
      children,
      style,
      customStyles,
      // @ts-ignore
      menuName,
      triggerOnLongPress,
      onAlternativeAction,
      testID,
      ...other
    } = this.props
    const onPress = () => !disabled && this._onPress()
    const { Touchable, defaultTouchableProps } = makeTouchable(customStyles.TriggerTouchableComponent)
    return (
      <View ref={onRef} collapsable={false} style={customStyles.triggerOuterWrapper}>
        <Touchable
          testID={testID}
          onPress={triggerOnLongPress ? onAlternativeAction : onPress}
          onLongPress={triggerOnLongPress ? onPress : onAlternativeAction}
          {...defaultTouchableProps}
          {...customStyles.triggerTouchable}>
          <View {...other} style={[customStyles.triggerWrapper, style]}>
            {text ? <Text style={customStyles.triggerText}>{text}</Text> : children}
          </View>
        </Touchable>
      </View>
    )
  }
}

// MenuTrigger.propTypes = {
//   disabled: PropTypes.bool,
//   text: PropTypes.string,
//   onPress: PropTypes.func,
//   onAlternativeAction: PropTypes.func,
//   customStyles: PropTypes.object,
//   triggerOnLongPress: PropTypes.bool,
//   testID: PropTypes.string,
// }

// MenuTrigger.defaultProps = {
//   disabled: false,
//   customStyles: {},
//   testID: undefined,
// }

export default withCtx(MenuTrigger) as React.FC<MenuTriggerProps>
