import React, { Component } from 'react'
import { TouchableOpacity } from 'react-native'
import dayjs from 'dayjs'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getSelectedStore } from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'

import {
  IOrderListFilterPreference,
  IOrderListFilterTemplate,
  IOrderListFilterTemplateDashboardItem,
  IOrderListFilterTemplateDashboardItemsProps,
  IOrderListFilterTemplateDashboardItemsState,
} from 'x/index'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import api, { postStoreUser } from 'x/utils/api'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'

import _ from 'lodash'
import HStack from '../HStack'
import VStack from '../VStack'
import XText from '../XText'
import Box from '../Box'
import OrderListFilterTemplateSelector from './OrderListFilterTemplateSelector'
import XIconButton from '../XIconButton'
import XSpinner from '../XSpinner'
import XCard from '../XCard'
import NavHeaderButton from '../NavHeaderButton'
import ForwardIcon from '../ForwardIcon'
import AddIcon from '../AddIcon'

interface IDashboardItem extends IOrderListFilterTemplateDashboardItem {
  disabled?: boolean
  isEditable?: boolean
  storeId: number
  template: IOrderListFilterTemplate
  orderCount?: number
  fetchOrderCount: (tmpl: IOrderListFilterTemplate) => Promise<void>
  onPress: (tmpl: IOrderListFilterTemplate) => void
  onMoveUpPress: (dashboardItemId: string) => void

  isMoveUpAvailable?: boolean
  isMoveDownAvailable?: boolean
  onMoveDownPress: (dashboardItemId: string) => void
  onRemovePress: (dashboardItemId: string) => void
}

const DashboardItem: React.FC<IDashboardItem> = (props) => {
  const {
    id,
    template,
    onPress,
    onRemovePress,
    storeId,
    disabled = false,
    fetchOrderCount,
    orderCount,
    isEditable = false,
    isMoveDownAvailable,
    isMoveUpAvailable,
    onMoveDownPress,
    onMoveUpPress,
  } = props
  const { name, color } = template

  // const [orderCount, setOrderCount] = React.useState<number>(null)
  const [isFetching, setIsFetching] = React.useState<boolean>(false)

  const _fetchOrderCount = async () => {
    setIsFetching(true)

    // try {
    //   const types_to_count = [...CONS.ORDER_SUMMARY_ALL]
    //   for (let ti = 0; ti < CONS.ORDER_SUMMARY_SPECIAL_TAB_KEYS.length; ti++) {
    //     const typeKey = CONS.ORDER_SUMMARY_SPECIAL_TAB_KEYS[ti]
    //     if (!_.includes(types_to_count, typeKey)) {
    //       types_to_count.push(typeKey)
    //     }
    //   }

    //   const filterSetting = util.translateOrdersFilterToRequestBody(template)
    //   const reqOrderBody: any = {
    //     store_id: storeId,
    //     types: types_to_count,
    //     types_to_count,
    //     offset: 0,
    //     limit: 0,
    //     ...filterSetting,
    //   }

    //   // if (!reqOrderBody.created_at_from && !reqOrderBody.created_at_to) {
    //   //   const dr = util.getDateRangeFromOption('Last30Days')
    //   //   reqOrderBody.created_at_from = dr.begin.format(CONS.SERVER_DATETIME_FORMAT).toString()
    //   //   reqOrderBody.created_at_to = dr.end.format(CONS.SERVER_DATETIME_FORMAT).toString()
    //   // }

    //   // console.log('reqOrderBody => ', reqOrderBody)
    //   const response = await api.post(api.POST_ORDERS, reqOrderBody, {
    //     showSpinner: false,
    //     axiosOptions: { retry: 0, timeout: 180000 },
    //     isErrorAlertDisabled: true,
    //   })

    //   const oSummary = response.summary
    //   console.log('response => ', response)
    //   if (oSummary) {
    //     let odCount = 0
    //     for (let yy = 0; yy < CONS.ORDER_SUMMARY_ALL.length; yy++) {
    //       const oTypeKey = CONS.ORDER_SUMMARY_ALL[yy]
    //       // console.log('oTypeKey => ', oTypeKey)
    //       // console.log('oSummary[oTypeKey] => ', oSummary[oTypeKey])

    //       if (oSummary[oTypeKey]) {
    //         odCount += oSummary[oTypeKey]
    //       }
    //     }

    //     setOrderCount(odCount)
    //   }
    // } catch (error) {
    //   console.log('error => ', error)
    // }

    await fetchOrderCount(template)

    setIsFetching(false)
  }

  React.useEffect(() => {
    if (_.isNil(orderCount)) {
      _fetchOrderCount()
    }
  }, [])

  const renderItem = () => (
    <HStack
      w='full'
      alignItems='center'
      // justifyContent='center'
      space='2'
      borderColor={color}
      borderWidth='1'
      p='2'
      borderRadius='lg'
      bg='white'>
      <Box w='20px' h='20px' borderRadius='full' bg={color} />

      <VStack flex={1}>
        <XText w='full' color={color}>
          {name}
        </XText>
      </VStack>

      {!isEditable && (
        <HStack w='38px' alignItems='center' justifyContent='flex-end'>
          {!_.isNil(orderCount) && (
            <XText color={color} bold>
              {orderCount}
            </XText>
          )}

          {isFetching && <XSpinner size='sm' color={color} />}
        </HStack>
      )}

      {isEditable && (
        <XIconButton
          opacity={isMoveUpAvailable ? 1 : 0}
          variant='ghost'
          bg='gray.100'
          borderRadius='full'
          onPress={() => onMoveUpPress(id)}
          disabled={!isMoveUpAvailable}
          // w='34px'
          // h='34px'
          name='arrow-up'
          family='Ionicons'
        />
      )}

      {isEditable && (
        <XIconButton
          opacity={isMoveDownAvailable ? 1 : 0}
          variant='ghost'
          bg='gray.100'
          borderRadius='full'
          onPress={() => onMoveDownPress(id)}
          disabled={!isMoveDownAvailable}
          // w='34px'
          // h='34px'
          name='arrow-down'
          family='Ionicons'
        />
      )}

      {isEditable && (
        <XIconButton variant='ghost' bg='gray.100' colorScheme='error' borderRadius='full' name='trash' onPress={() => onRemovePress(id)} />
      )}

      {!isEditable && <ForwardIcon />}
      {/* <HStack style={{ position: 'absolute', top: -12, right: -12 }}>
          <XIconButton name='close-circle' onPress={() => onRemovePress(id)} />
        </HStack> */}
    </HStack>
  )

  if (isEditable) {
    return renderItem()
  }

  return (
    <TouchableOpacity disabled={disabled} style={{ width: '100%' }} onPress={() => onPress(template)}>
      {renderItem()}
    </TouchableOpacity>
  )
}

class OrderListFilterTemplateDashboardItems extends Component<
  IOrderListFilterTemplateDashboardItemsProps,
  IOrderListFilterTemplateDashboardItemsState
> {
  static displayName = 'OrderListFilterTemplateDashboardItems'

  inProcess?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      isSubmitting: false,
      isEditMode: false,
      // orderCounts: {},
      // dashboardItems: [],
      editingPref: {
        templates: [],
        dashboardItems: [],
        createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
        updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      },
    }
  }

  async componentDidMount(): Promise<void> {
    await this._initialize()
  }

  _initialize = async () => {
    const pref = this._getPref()
    const { dashboardItems: dis = [] } = pref

    const editingPref = _.cloneDeep(pref)
    let dashboardItems = _.cloneDeep(dis)

    for (let di = 0; di < dis.length; di++) {
      const dashboardItem = dis[di]
      const isMissingTemplateId = this._isMissingTemplateIdOnDashboardItems(dashboardItem)

      if (isMissingTemplateId) {
        dashboardItems = dashboardItems.filter((ditem) => ditem.templateId !== dashboardItem.templateId)
      }
    }

    const isDashboardItemDiff = !_.isEqual(pref.dashboardItems, dashboardItems)

    if (isDashboardItemDiff) {
      const { updateSelectedStoreByKey, selectedStore } = this.props

      editingPref.dashboardItems = dashboardItems
      const store_id = selectedStore.get('id')
      const isSuccess = await postStoreUser({ store_id, pref_ui_order_list_filter: editingPref })

      if (isSuccess) {
        updateSelectedStoreByKey('pref_ui_order_list_filter', editingPref)
        await util.delay(100)
      }
    }

    await util.setStatePromise(this, { editingPref: pref, isInitialized: true })
  }

  _isMissingTemplateIdOnDashboardItems = (di: IOrderListFilterTemplateDashboardItem) => {
    const pref = this._getPref()
    const { templates = [] } = pref
    const foundIndex = templates.findIndex((t) => t.id === di.templateId)
    return foundIndex === -1
  }

  _getPref = (): IOrderListFilterPreference => util.getOrderListFilterPreferance()

  _fetchOrderCount = async (tmpl: IOrderListFilterTemplate) => {
    try {
      // const types_to_count = [...CONS.ORDER_SUMMARY_ALL]
      // for (let ti = 0; ti < CONS.ORDER_SUMMARY_SPECIAL_TAB_KEYS.length; ti++) {
      //   const typeKey = CONS.ORDER_SUMMARY_SPECIAL_TAB_KEYS[ti]
      //   if (!_.includes(types_to_count, typeKey)) {
      //     types_to_count.push(typeKey)
      //   }
      // }

      const usageTmplate = _.cloneDeep(tmpl)
      if (!usageTmplate.createOrderDateRangeOptionKey) {
        usageTmplate.createOrderDateRangeOptionKey = 'Last30Days'
      }

      const filterSetting = util.translateOrdersFilterToRequestBody(usageTmplate)
      const { selectedStore } = this.props
      const reqOrderBody: any = {
        store_id: selectedStore.get('id'),
        // types: types_to_count,
        // types_to_count,
        types: [],
        types_to_count: ['all'],
        offset: 0,
        limit: 0,
        ...filterSetting,
      }

      // if (!reqOrderBody.created_at_from && !reqOrderBody.created_at_to) {
      //   const dr = util.getDateRangeFromOption('Last30Days')
      //   reqOrderBody.created_at_from = dr.begin.format(CONS.SERVER_DATETIME_FORMAT).toString()
      //   reqOrderBody.created_at_to = dr.end.format(CONS.SERVER_DATETIME_FORMAT).toString()
      // }

      // console.log('reqOrderBody => ', reqOrderBody)
      const response = await api.post(api.POST_ORDERS, reqOrderBody, {
        showSpinner: false,
        axiosOptions: { retry: 0, timeout: 180000 },
        isErrorAlertDisabled: true,
      })

      const oSummary = response.summary
      // console.log('response => ', response)
      if (oSummary) {
        // let odCount = 0
        // for (let yy = 0; yy < CONS.ORDER_SUMMARY_ALL.length; yy++) {
        //   const oTypeKey = CONS.ORDER_SUMMARY_ALL[yy]
        //   // console.log('oTypeKey => ', oTypeKey)
        //   // console.log('oSummary[oTypeKey] => ', oSummary[oTypeKey])

        //   if (oSummary[oTypeKey]) {
        //     odCount += oSummary[oTypeKey]
        //   }
        // }

        // const odCount = oSummary.all || 0
        // const { orderCounts: odcs } = this.state
        // const orderCounts = _.cloneDeep(odcs)
        // orderCounts[tmpl.id] = odCount
        // this.setState({ orderCounts })
        const odCount = oSummary.all || 0
        const newwState = {}
        newwState[`orderCount_${tmpl.id}`] = odCount
        this.setState(newwState)
      }
    } catch (error) {
      console.log('error => ', error)
    }
  }

  // _onPrefManageSaveSuccess = async () => {
  //   console.log('OrderListFilterManageView::onSaveSuccess')
  //   const { selectedTemplate } = this.props
  //   const pref = this._getPref()

  //   if (selectedTemplate && pref && _.isArray(pref.templates)) {
  //     const tmplId = selectedTemplate.id
  //     const foundIndex = pref.templates.findIndex((tmpl) => tmpl.id === tmplId)

  //     if (foundIndex < 0) {
  //       const { onSelect } = this.props

  //       if (_.isFunction(onSelect)) {
  //         onSelect(null)
  //       }
  //     }
  //   }
  // }

  _onRemoveDashboardItem = (id: string) => {
    this._removeDashboardItem(id)
  }

  _getOrderListFilterTemplateById = (templateId: string) => {
    const pref = this._getPref()
    const { templates } = pref
    const foundTemplate = templates.find((tmpl) => tmpl.id === templateId)
    return foundTemplate
  }

  _onDashboardItemPress = (tmpl: IOrderListFilterTemplate) => {
    const { selectedStore } = this.props
    const { navigation } = this.props

    const usageTmplate = _.cloneDeep(tmpl)
    if (!usageTmplate.createOrderDateRangeOptionKey) {
      usageTmplate.createOrderDateRangeOptionKey = 'Last30Days'
    }

    navigation.dispatch(
      // NavActions.navToSearchOrderListView({
      NavActions.navToAllPrintDocumentOrderListView({
        store_id: selectedStore.get('id'),
        ordersDefaultFilterSetting: {},
        ordersFilterSetting: usageTmplate,
      })
    )
  }

  _getOrderCountByTemplateId = (templateId: string) =>
    // const { orderCounts = {} } = this.state
    // return orderCounts[templateId]
    this.state[`orderCount_${templateId}`]

  renderDashboardItem = (dItem: IOrderListFilterTemplateDashboardItem, index: number) => {
    const { isSubmitting, isEditMode, editingPref } = this.state
    const { dashboardItems = [] } = editingPref
    const { id, templateId } = dItem
    const tmpl = this._getOrderListFilterTemplateById(templateId)

    if (!tmpl) {
      return null
    }

    // const { name, color } = tmpl
    const { selectedStore } = this.props
    const isMoveUpAvailable = isSubmitting || index > 0
    const isMoveDownAvailable = isSubmitting || index < dashboardItems.length - 1

    return (
      <DashboardItem
        key={`dashboard-item-${id}_${index}`}
        storeId={selectedStore.get('id')}
        disabled={isSubmitting}
        isEditable={isEditMode}
        {...dItem}
        template={tmpl}
        onPress={this._onDashboardItemPress}
        onRemovePress={this._onRemoveDashboardItem}
        fetchOrderCount={this._fetchOrderCount}
        orderCount={this._getOrderCountByTemplateId(tmpl.id)}
        isMoveUpAvailable={isMoveUpAvailable}
        isMoveDownAvailable={isMoveDownAvailable}
        onMoveUpPress={this._onMoveUpPress}
        onMoveDownPress={this._onMoveDownPress}
      />
    )

    // return (
    //   <HStack
    //     key={`dashboard-item-${id}_${index}`}
    //     w='full'
    //     alignItems='center'
    //     justifyContent='center'
    //     space='2'
    //     borderColor={color}
    //     borderWidth='1'
    //     p='2'
    //     borderRadius='lg'
    //     bg='white'>
    //     <Box w='20px' h='20px' borderRadius='full' bg={color} />
    //     <XText colo={color}>{name}</XText>

    //     <HStack position='absolute' top='0' right='0'>
    //       <XIconButton name='close' onPress={() => this._onRemoveDashboardItem(id)} />
    //     </HStack>
    //   </HStack>
    // )
  }

  renderDashboardItems = () => {
    const { editingPref, isEditMode } = this.state
    const pref = this._getPref()

    const { dashboardItems: dis } = pref
    const { dashboardItems: edtdis } = editingPref

    const dashboardItems = isEditMode ? edtdis : dis
    if (!_.isArray(dashboardItems) || dashboardItems.length === 0) {
      return this.renderEmptyDashboardItems()
    }

    // const { dashboardItems = [] } = this.state
    return dashboardItems.map(this.renderDashboardItem)
  }

  // _addDashboardItem = async (tmpl: IOrderListFilterTemplate) => {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true

  //   const { selectedStore } = this.props
  //   const pref = this._getPref()
  //   const editingPref = _.cloneDeep(pref)

  //   await util.setStatePromise(this, { isSubmitting: true })

  //   if (!_.isArray(editingPref.dashboardItems)) {
  //     editingPref.dashboardItems = []
  //   }

  //   editingPref.dashboardItems.push({
  //     id: `${dayjs().format('YYYYMMDD').toString()}di${util.generateRandomString(3)}`,
  //     createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
  //     updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),

  //     templateId: tmpl.id,
  //   })

  //   const store_id = selectedStore.get('id')
  //   const isSuccess = await postStoreUser({ store_id, pref_ui_order_list_filter: editingPref })

  //   if (isSuccess) {
  //     const { updateSelectedStoreByKey } = this.props
  //     updateSelectedStoreByKey('pref_ui_order_list_filter', editingPref)
  //     p.op.showToast('แก้ไขแดชบอร์ดเรียบร้อยแล้ว', 'success')
  //   } else {
  //     p.op.showToast('แก้ไขแดชบอร์ดไม่สําเร็จ', 'danger')
  //   }

  //   await util.setStatePromise(this, { isSubmitting: false })
  //   this.inProcess = false
  // }

  _onMoveUpPress = async (id: string) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { editingPref } = this.state
    const fromIndex = _.findIndex(editingPref.dashboardItems, (di) => di.id === id)
    await this._moveDashboardItem(fromIndex, fromIndex - 1)

    this.inProcess = false
  }

  _moveDashboardItem = async (from: number, to: number) => {
    const { editingPref } = this.state
    const { dashboardItems = [] } = editingPref

    const newDashboardItems = [...dashboardItems]

    // remove `from` item and store it
    const f = newDashboardItems.splice(from, 1)[0]

    // insert stored item into position `to`
    newDashboardItems.splice(to, 0, f)

    await util.setStatePromise(this, { editingPref: { ...editingPref, dashboardItems: newDashboardItems } })
  }

  _onMoveDownPress = async (id: string) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { editingPref } = this.state
    const fromIndex = _.findIndex(editingPref.dashboardItems, (di) => di.id === id)
    await this._moveDashboardItem(fromIndex, fromIndex + 1)

    this.inProcess = false
  }

  _addDashboardItem = async (tmpl: IOrderListFilterTemplate) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { editingPref: pref } = this.state
    const editingPref = _.cloneDeep(pref)

    if (!_.isArray(editingPref.dashboardItems)) {
      editingPref.dashboardItems = []
    }

    editingPref.dashboardItems.push({
      id: `${dayjs().format('YYYYMMDD').toString()}di${util.generateRandomString(3)}`,
      createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),

      templateId: tmpl.id,
    })

    await util.setStatePromise(this, { editingPref })

    this.inProcess = false
  }

  // _removeDashboardItem = async (dashboardItemId: string) => {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true

  //   const { selectedStore } = this.props
  //   const pref = this._getPref()
  //   const editingPref = _.cloneDeep(pref)

  //   await util.setStatePromise(this, { isSubmitting: true })

  //   editingPref.dashboardItems = editingPref.dashboardItems.filter((dItem) => dItem.id !== dashboardItemId)

  //   const store_id = selectedStore.get('id')
  //   const isSuccess = await postStoreUser({ store_id, pref_ui_order_list_filter: editingPref })

  //   if (isSuccess) {
  //     const { updateSelectedStoreByKey } = this.props
  //     updateSelectedStoreByKey('pref_ui_order_list_filter', editingPref)
  //     p.op.showToast('แก้ไขแดชบอร์ดเรียบร้อยแล้ว', 'success')
  //   } else {
  //     p.op.showToast('แก้ไขแดชบอร์ดไม่สําเร็จ', 'danger')
  //   }

  //   await util.setStatePromise(this, { isSubmitting: false })
  //   this.inProcess = false
  // }

  _removeDashboardItem = async (dashboardItemId: string) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { editingPref: pref } = this.state
    const editingPref = _.cloneDeep(pref)

    editingPref.dashboardItems = editingPref.dashboardItems.filter((dItem) => dItem.id !== dashboardItemId)

    await util.setStatePromise(this, { editingPref })

    this.inProcess = false
  }

  _onSelectFilterTemplate = (selectedTemplate: IOrderListFilterTemplate) => {
    this._addDashboardItem(selectedTemplate)
  }

  _onSave = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    await util.setStatePromise(this, { isSubmitting: true })
    const pref = this._getPref()
    const { editingPref } = this.state
    const { selectedStore } = this.props

    const store_id = selectedStore.get('id')

    const newPref = _.cloneDeep(pref)
    newPref.dashboardItems = editingPref.dashboardItems
    newPref.updatedAt = dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString()

    const isSuccess = await postStoreUser({ store_id, pref_ui_order_list_filter: newPref })

    if (isSuccess) {
      const { updateSelectedStoreByKey } = this.props
      updateSelectedStoreByKey('pref_ui_order_list_filter', newPref)
      p.op.showToast('แก้ไขรายการออเดอร์ที่สนใจเรียบร้อยแล้ว', 'success')

      await util.delay(100)
      await this._initialize()
    } else {
      p.op.showToast('แก้ไขรายการออเดอร์ที่สนใจไม่สําเร็จ', 'danger')
    }

    await util.setStatePromise(this, { isSubmitting: false })
    this.inProcess = false
  }

  _onEditPress = async () => {
    await util.setStatePromise(this, { isEditMode: true })
  }

  _onSaveEditPress = async () => {
    await this._onSave()
    await util.setStatePromise(this, { isEditMode: false })
  }

  _onCancelEditPress = async () => {
    await this._initialize()
    await util.setStatePromise(this, { isEditMode: false })
  }

  renderCardHeader = () => {
    const { isEditMode = false, isSubmitting } = this.state
    return (
      <HStack w='full' py='0.5' alignItems='center'>
        {isEditMode ? (
          <NavHeaderButton submitting={isSubmitting} label='บันทึก' onPressItem={this._onSaveEditPress} />
        ) : (
          <Box w='44px' h='34px' />
        )}

        <VStack flex={1}>
          <XText w='full' textAlign='center' variant='inactive' bold>
            {isEditMode ? 'แก้ไขรายการออเดอร์ที่สนใจ' : 'รายการออเดอร์ที่สนใจ'}
          </XText>
        </VStack>

        {isEditMode ? (
          <NavHeaderButton submitting={isSubmitting} label='ยกเลิก' onPressItem={this._onCancelEditPress} />
        ) : (
          <XIconButton
            variant='ghost'
            bg='gray.100'
            borderRadius='full'
            name='edit'
            family='MaterialIcons'
            overflow='none'
            onPress={this._onEditPress}
          />
        )}
      </HStack>
    )
  }

  _renderAddDashboardItemButton = (onPress) => (
    <TouchableOpacity onPress={onPress} style={{ width: '100%' }}>
      <HStack
        // flex={1}
        w='full'
        px='2'
        py='1'
        space='2'
        alignItems='center'
        borderWidth='1'
        borderStyle='dashed'
        borderColor='gray.400'
        borderRadius='lg'>
        <AddIcon onPress={onPress} />
        <XText variant='primary' numberOfLines={1}>
          เพิ่มออเดอร์ที่สนใจ
        </XText>
      </HStack>
    </TouchableOpacity>
  )

  _renderDashboardExceededWarning = () => (
    <HStack
      // flex={1}
      w='full'
      px='2'
      py='1'
      space='2'
      alignItems='center'
      justifyContent='center'
      // borderWidth='1'
      // borderStyle='dashed'
      borderColor='gray.100'
      borderRadius='lg'>
      <XText variant='inactive' numberOfLines={1}>
        เพิ่มออเดอร์ที่สนใจได้สูงสุด 5 รายการ
      </XText>
    </HStack>
  )

  renderEmptyDashboardItems = () => (
    <HStack
      // flex={1}
      w='full'
      px='2'
      py='1'
      space='2'
      alignItems='center'
      justifyContent='center'
      // borderWidth='1'
      // borderStyle='dashed'
      borderColor='gray.100'
      borderRadius='lg'>
      <XText variant='inactive' numberOfLines={1}>
        {'ยังไม่มีรายการออเดอร์ที่สนใจ\nสามารถเพิ่มได้โดยการกดปุ่มแก้ไขทางด้านขวา'}
      </XText>
    </HStack>
  )

  isExceededMaxDashboardItems = () => {
    const { editingPref: pref } = this.state
    const { dashboardItems } = pref
    return dashboardItems.length >= 5
  }

  _onPrefSaveSuccess = async (newPref: IOrderListFilterPreference, savedTemplate?: IOrderListFilterTemplate) => {
    await this._initialize()
  }

  renderMain = () => {
    const { isSubmitting, isEditMode } = this.state
    const { navigation } = this.props

    return (
      <XCard>
        <VStack w='full' p='2' pt='1' space='1.5'>
          {this.renderCardHeader()}

          {this.renderDashboardItems()}

          {isEditMode && !this.isExceededMaxDashboardItems() && (
            <OrderListFilterTemplateSelector
              disabled={isSubmitting}
              navigation={navigation}
              type='custom'
              renderCustom={this._renderAddDashboardItemButton}
              selectedTemplate={null}
              onSelect={this._onSelectFilterTemplate}
              onSaveSuccess={this._onPrefSaveSuccess}
            />
          )}

          {isEditMode && this.isExceededMaxDashboardItems() ? this._renderDashboardExceededWarning() : null}
        </VStack>
      </XCard>
    )
  }

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return this.renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(OrderListFilterTemplateDashboardItems) as React.FC<
  Omit<IOrderListFilterTemplateDashboardItemsProps, 'selectedStore' | 'updateSelectedStoreByKey'>
>
