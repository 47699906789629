import React, { Component } from 'react'

import { View, TouchableOpacity, ScrollView } from 'react-native'

import { Map, fromJS, List } from 'immutable'
import * as util from 'x/utils/util'
import { logRender, setStatePromise } from 'x/utils/util'
import { COLORS } from 'x/config/styles'
import { UserGroupMap, UserInUserGroups, IXScreenProps, IXRadioData } from 'x/index'
import p from 'x/config/platform-specific'
import _ from 'lodash'

// import UGDisplayItem from '../../components/UGDisplayItem'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XCard from 'xui/components/XCard'
import XButton from 'xui/components/XButton'
import XCustomHeader from 'xui/components/XCustomHeader'
import XText from 'xui/components/XText'
import XRadio from 'xui/components/XRadio'
import BackIcon from 'xui/components/BackIcon'
// eslint-disable-next-line import/extensions
import UserItem from 'xui/modules/userGroup/UserItem'

const INTL_STYLES: { [key: string]: any } = {
  flatList: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  userImage: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  userEmptyImageIcon: {
    height: 50,
    width: 50,
    fontSize: 50,
  },
  middleCenterColumn: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  topLeftColumn: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}

interface ResellerChangeGroupViewProps extends IXScreenProps {
  selectedStore: Map<string, any>
  selectedUserGroups: List<UserGroupMap>

  changeUserGroup?: (ActionApiParams) => void

  // navigation?: any
}

interface ResellerChangeGroupViewState {
  store_id: number
  ug_id: number // must be new ug_id
  currentUgId: number // old ug_id
  selectedReseller: UserInUserGroups
  isEdited: boolean
  submitting: boolean
}

export default class ResellerChangeGroupView extends Component<ResellerChangeGroupViewProps, ResellerChangeGroupViewState> {
  static displayName = 'ResellerChangeGroupView'

  _inProcess: boolean

  _isNavBack: boolean

  static navigationOptions = ({ navigation }) => ({
    header: null,
  })

  // static navigationOptions = ({ navigation }) => {
  //   const { state = null } = navigation

  //   if (!state || !state.params || _.isEmpty(state.params) || !_.isFunction(state.params.navBack)) {
  //     return {
  //       headerLeft: null,
  //       title: null,
  //       headerRight: null,
  //     }
  //   }

  //   const {
  //     title = 'ย้ายสมาชิกของตัวแทน',
  //     submitting = false,
  //     isEdited = false,
  //     navBack,
  //     saveChanged = null,
  //     cancelChanged = null,
  //   } = state.params

  //   let headerLeftProps: INavHeaderButtonProps = {
  //     submitting,
  //     backIcon: true,
  //     onPressItem: navBack,
  //   }
  //   let headerRightProps: INavHeaderButtonProps = {}

  //   if (isEdited && _.isFunction(saveChanged) && _.isFunction(cancelChanged)) {
  //     headerLeftProps.backIcon = false
  //     headerLeftProps.label = 'บันทึก'
  //     headerLeftProps.onPressItem = saveChanged
  //     headerRightProps.label = 'ยกเลิก'
  //     headerRightProps.onPressItem = cancelChanged
  //     headerRightProps.submitting = submitting
  //   }

  //   return {
  //     headerLeft: <NavHeaderButton {...headerLeftProps} />,
  //     title,
  //     headerRight: <NavHeaderButton {...headerRightProps} />,
  //   }
  // }

  constructor(props: ResellerChangeGroupViewProps) {
    super(props)
    // @ts-ignore
    this.state = {
      store_id: null,
      ug_id: null,
      selectedReseller: null,
      isEdited: false,
      submitting: false,
    }

    this._inProcess = false
    this._isNavBack = false
  }

  componentDidMount() {
    this._firstInitialize()
  }

  _renderCustomHeader = () => {
    const title = 'ย้ายสมาชิกของตัวแทน'
    return (
      <XCustomHeader
        title={title}
        // headerLeftProps={{
        //   backIcon: true,
        //   onPressItem: () => goBack(state.key),
        // }}
        renderHeaderLeft={() => this.renderLeftBtn()}
        renderHeaderRight={() => this.renderRightBtn()}
      />
    )
  }

  renderLeftBtn = () => {
    const { isEdited } = this.state
    if (isEdited) {
      return (
        <XButton variant='ghost' alignItems='center' justifyContent='center' mr='2' onPress={() => this._handleSaveChanged()}>
          <XText color={COLORS.APP_MAIN} mb={p.op.isWeb() ? '0' : '2'} textAlign='center'>
            บันทึก
          </XText>
        </XButton>
      )
    }
    return (
      <XButton
        leftIcon={<BackIcon style={{ marginBottom: 4 }} />}
        variant='ghost'
        mr='2'
        alignItems='center'
        justifyContent='center'
        onPress={() => util.navGoBack(this.props)}
      />
    )
  }

  renderRightBtn = () => {
    const { isEdited } = this.state
    if (isEdited) {
      return (
        <XButton variant='ghost' alignItems='center' justifyContent='center' mr='2' onPress={() => this._handleCancelChanged()}>
          <XText color={COLORS.APP_MAIN} mb={p.op.isWeb() ? '0' : '2'} textAlign='center'>
            ยกเลิก
          </XText>
        </XButton>
      )
    }
    return null
  }

  _goBack = () => {
    try {
      if (this._isNavBack) {
        return
      }
      this._isNavBack = true
      util.navGoBack(this.props)
    } catch (e) {
      throw e
    }
  }

  _handleSaveChanged = async () => {
    // log('_handleSaveChanged')
    if (this._inProcess) {
      return
    }
    this._inProcess = true
    await this._beginSubmitting()

    const { currentUgId, ug_id, store_id, selectedReseller } = this.state
    const selectedResellerFromJS = fromJS(selectedReseller)
    if (!ug_id || !store_id || !selectedResellerFromJS || !selectedResellerFromJS.get('i')) {
      // this._inProcess = false
      await this._endSubmitting()
      return
    }

    const { selectedUserGroups, changeUserGroup } = this.props
    const currentUG = selectedUserGroups.find((ugMap) => ugMap.get('id') === currentUgId)
    const selectedUG = selectedUserGroups.find((ugMap) => ugMap.get('id') === ug_id)

    if (!Map.isMap(currentUG) || !Map.isMap(selectedUG)) {
      // this._inProcess = false
      await this._endSubmitting()
      throw new Error('ResellerChangeGroupView:: _handleSaveChanged Not found ug. (should be impossible)')
    }
    const currentUGName = currentUG.get('name')
    const selectedUGName = selectedUG.get('name')
    const displayName = selectedResellerFromJS.get('d')
    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        'ย้ายสมาชิก',
        `กรุณายืนยันว่าคุณต้องการย้ายกลุ่มของตัวแทน "${displayName}" จากเดิมอยู่กลุ่ม "${currentUGName}" ย้ายไปอยู่ในกลุ่ม "${selectedUGName}"` +
          '\n\nการดำเนินการจะใช้เวลานานกว่าหนึ่งนาที คุณจะต้องรอจนกว่าจะดำเนินการเสร็จสิ้น',
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })

    if (!isUserConfirmed) {
      // this._inProcess = false
      await this._endSubmitting()
      return
    }

    const body = {
      ug_id, // new ug_id
      store_id,
      // id: selectedResellerFromJS.i, // profile id
      profile_id: selectedResellerFromJS.get('i'), // profile id
    }

    await this._beginSubmitting()

    // await new Promise(resolveDelay => setTimeout(resolveDelay, 3000))
    const isSuccess = await new Promise<boolean>((success) => {
      changeUserGroup({
        body,
        successCallback: () => success(true),
        failedCallback: () => success(false),
      })
    })

    if (isSuccess) {
      // log('ResellerChangeGroupView:: _handleSaveChanged success')
      const callBack = util.getNavParam(this.props, 'callBack')
      if (_.isFunction(callBack)) {
        callBack()
      }
      this._goBack()
      return
    }

    await this._endSubmitting()
    this._inProcess = false
    // TODO: long fetch to patch  without retry
  }

  _beginSubmitting = async (): Promise<void> => {
    this._inProcess = true
    this.props.navigation.setParams({ submitting: true })
    await setStatePromise(this, { submitting: true })
    await new Promise((delay) => setTimeout(delay, 200))
  }

  _endSubmitting = async (): Promise<void> => {
    this.props.navigation.setParams({ submitting: false })
    await setStatePromise(this, { submitting: false })
    await new Promise((delay) => setTimeout(delay, 200))
    this._inProcess = false
  }

  _handleCancelChanged = async (): Promise<void> => {
    // log('_handleCancelChanged')
    if (this._inProcess) {
      return
    }
    this._inProcess = true
    // log('_handleCancelChanged do this._inProcess = true')
    await this._firstInitialize()
    this._inProcess = false
  }

  _firstInitialize = async (): Promise<void> => {
    const { navigation } = this.props
    if (!navigation) {
      throw new Error('No navigation props in ResellerChangeGroupView')
    }

    // const { state, goBack, setParams } = navigation
    // if (!state || !state.params || _.isEmpty(state.params)) {
    //   this._goBack()
    //   return
    // }

    // const { store_id, ug_id, selectedReseller } = state.params

    const store_id = util.getNavParam(this.props, 'store_id')
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const selectedReseller = util.getNavParam(this.props, 'selectedReseller')

    // log('ResellerChangeGroupView:: _firstInitialize selectedReseller => ', selectedReseller)

    await setStatePromise(this, { store_id, ug_id, currentUgId: ug_id, selectedReseller, isEdited: false })
    navigation.setParams({
      navBack: this._goBack,
      isEdited: false,
      saveChanged: this._handleSaveChanged,
      cancelChanged: this._handleCancelChanged,
    })
  }

  _renderUGList = () => {
    const { selectedUserGroups } = this.props
    return (
      <VStack space='3' mt='3'>
        {selectedUserGroups.map((ug, idx) => this._renderListItem(idx, ug))}
      </VStack>
    )
  }

  _markIsEditedWhenChangedUserGroup = async (): Promise<void> => {
    const { navigation } = this.props
    const { setParams } = navigation
    if (!this.state.currentUgId) {
      throw new Error('ResellerChangeGroupView:: _markIsEditedWhenChangedUserGroup no currentUgId')
    }

    const { isEdited, ug_id, currentUgId } = this.state
    if (currentUgId === ug_id && isEdited) {
      await setStatePromise(this, { isEdited: false })
      setParams({ isEdited: false })
    } else if (currentUgId !== ug_id && !isEdited) {
      await setStatePromise(this, { isEdited: true })
      setParams({ isEdited: true })
    }
  }

  _onPressRadio = async (args: IXRadioData): Promise<void> => {
    // log('_onPressRadio args => ', args)
    const { data } = args
    const selectedUserGroupId = data.get('id')
    if (selectedUserGroupId !== this.state.ug_id) {
      await setStatePromise(this, { ug_id: selectedUserGroupId })
      await this._markIsEditedWhenChangedUserGroup()
    }
  }

  _renderListItem = (index: number, ug: UserGroupMap) => {
    const { ug_id, currentUgId, submitting } = this.state
    const id = ug.get('id')
    const name = ug.get('name')
    const description = ug.get('description')
    // const description = 'test deescription test deescription test deescription test deescription test deescription test deescription test deescription '
    // const invite_code = ug.get('invite_code')

    const isChecked = ug_id === id
    // TODO ถอดออกไปเป็น Internal PureComponent
    const isCurrent = currentUgId === id
    return (
      <XCard key={index.toString()} py='2' h='20' justifyContent='center' w='full'>
        <TouchableOpacity
          // @ts-ignore
          onPress={() => this._onPressRadio({ index, data: ug })}>
          {/* <TouchableOpacity */}
          {/* onPress={this._onPressRadio} */}
          {/* key={index.toString()}> */}
          {/* </TouchableOpacity> */}
          <VStack w='full'>
            <HStack w='full' px='1'>
              <Box size={15} ml='2' style={{ alignItems: 'flex-start' }}>
                <XRadio disabled={submitting} index={index} data={ug} onPress={this._onPressRadio} checked={isChecked} />
              </Box>
              <Box size={85} ml='2' style={{ justifyContent: 'center' }}>
                <HStack>
                  <Box size={7} style={{ justifyContent: 'center' }}>
                    <XText
                      allowFontScaling={false}
                      numberOfLines={1}
                      style={{
                        // fontSize: STYLES.FONT_SIZE_NORMAL,
                        // fontWeight: 'bold',
                        color: COLORS.TEXT_ACTIVE,
                      }}>
                      {name}
                    </XText>
                  </Box>
                  {isCurrent ? (
                    <Box size={3} ml='2' style={{ justifyContent: 'center' }} w='full'>
                      <XText
                        allowFontScaling={false}
                        numberOfLines={1}
                        style={{
                          // fontSize: STYLES.FONT_SIZE_SMALLER,
                          color: COLORS.TEXT_INACTIVE,
                        }}>
                        (กลุ่มปัจจุบัน)
                      </XText>
                    </Box>
                  ) : null}
                </HStack>
                {description ? (
                  <HStack>
                    <XText
                      // w={p.op.isWeb() ? '56' : 'full'}
                      w='56'
                      allowFontScaling={false}
                      numberOfLines={2}
                      style={{
                        // fontSize: STYLES.FONT_SIZE_SMALLER,
                        color: COLORS.TEXT_INACTIVE,
                      }}>
                      {description}
                    </XText>
                  </HStack>
                ) : null}
              </Box>
            </HStack>
          </VStack>
        </TouchableOpacity>
      </XCard>
    )
  }

  render() {
    logRender(this)
    const { store_id, ug_id, selectedReseller } = this.state

    if (!store_id || !ug_id || !selectedReseller || _.isEmpty(selectedReseller)) {
      return null
    }

    const { i, d, t } = selectedReseller
    const profile_id = i
    const display_name = d
    const thumbnail_uri = t

    return (
      <View style={{ flex: 1 }}>
        {this._renderCustomHeader()}
        <View
          style={{
            width: '100%',
            // height: 60,
            // height: 50,
            // paddingVertical: 8,
            // paddingHorizontal: 12,
            backgroundColor: 'white',
            borderBottomWidth: 0.5,
            borderBottomColor: COLORS.TEXT_INACTIVE,
          }}>
          <XText variant='active' ml='4' mt='2'>
            เลือกกลุ่มใหม่ให้ตัวแทนคนนี้
          </XText>
          <UserItem
            data={fromJS(selectedReseller)}
            // onPressItem={() => log('index => ', index)}
            // onPressItem={_.isFunction(this._onPressUserItem) ? this._onPressUserItem : null}
            // onPressEmpty={this._onPressEmptyItem}
            // renderRightComponent={() => <XText style={{ color: COLORS.TEXT_INACTIVE }}>เลือกกลุ่มใหม่ให้ตัวแทนคนนี้</XText>}
            rowHeight={60}
          />
          {/* <UGDisplayItem */}
          {/* thumbnail_uri={thumbnail_uri} */}
          {/* name={display_name} */}
          {/* description={'กรุณาเลือกสมาชิกใหม่ให้ตัวแทนคนนี้'} */}
          {/* /> */}
        </View>
        <ScrollView style={{ paddingHorizontal: 12, backgroundColor: COLORS.BG_LIGHT_GREY }}>{this._renderUGList()}</ScrollView>
      </View>
    )
  }
}
