import React from 'react'

import { connect } from 'react-redux'
import { getSelectedStore } from 'x/redux/selectors'

import { TouchableOpacity, View } from 'react-native'

import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import * as api from 'x/utils/api'
import { COLORS, S } from 'x/config/styles'
import {
  IQtyAdjustmentViewProps,
  IQtyAdjustmentViewState,
  ISearchProductItem,
  IBarcodeScannerOutput,
  ISearchProductVariantListViewNavParams,
  IXSegmentOption,
  ISearchInventoryProductsParams,
  IQtyAdjustReason,
} from 'x/index'

// import BackIcon from 'xui/components/BackIcon'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import * as NavActions from 'x/utils/navigation'
import _ from 'lodash'
import HStack from 'xui/components/HStack'
import XImage from 'xui/components/XImage'
import CollapsableCard from 'xui/components/CollapsableCard'
import BarcodeScannerWithSegment from 'xui/components/BarcodeScannerWithSegment'
import XInput from 'xui/components/XInput'
import XSegment from 'xui/components/XSegment'
import XOverlay from 'xui/components/XOverlay'
import XNumericInput from 'xui/components/XNumericInput'
import XIconButton from 'xui/components/XIconButton'
import XIcon from 'xui/components/XIcon'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import XSwitch from 'xui/components/XSwitch'

const IS_USE_CAMERA_TO_SCAN_SAVE_STATE_KEY = 'QtyAdjustmentView_isBarcodeScanByCamera'

const MIN_QTY = 0
const MAX_QTY = 999999999999
const MAX_QTY_DIGITS = 12

const INITIAL_STATE: IQtyAdjustmentViewState = {
  isInitialized: false,

  isProductScanExpanded: true,
  isProductListExpanded: true,
  isFillExtraDataExpanded: false,

  isBarcodeScanByCamera: true,

  products: [],
  ppQtyChanged: {},

  refCode: '',
  note: '',
  searchCache: {},
}

const ADJUST_ADD_REASON_OPTIONS: IXSegmentOption<IQtyAdjustReason>[] = [
  { key: 'not_specified', value: 'not_specified', label: 'ไม่ระบุ' },
  { key: 'return', value: 'return', label: 'ตีกลับ' },
  { key: 'purchase', value: 'purchase', label: 'ซื้อเข้า' },
  { key: 'deposit', value: 'deposit', label: 'เติมเข้า' },
]

const ADJUST_DEDUCT_REASON_OPTIONS: IXSegmentOption<IQtyAdjustReason>[] = [
  { key: 'not_specified', value: 'not_specified', label: 'ไม่ระบุ' },
  { key: 'damage', value: 'damage', label: 'เสียหาย' },
  { key: 'lost', value: 'lost', label: 'สูญหาย' },
  { key: 'withdraw', value: 'withdraw', label: 'เบิกออก' },
]

const SEGMENT_SCAN_TYPE_OPTIONS = [
  { key: 'all', label: 'ทั้งหมด', value: 'all' },
  // { key: 'name', label: 'ชื่อสินค้า', value: 'name' },
  { key: 'sku', label: 'SKU', value: 'sku' },
  { key: 'upc', label: 'UPC', value: 'upc' },
]

class QtyAdjustmentView extends React.Component<IQtyAdjustmentViewProps, IQtyAdjustmentViewState> {
  static displayName = 'QtyAdjustmentView'

  // static navigationOptions = {
  //   header: null,
  //   title: null,
  //   headerLeft: null,
  //   headerRight: null,
  // }

  _inProcess?: boolean

  _isBarcodeScanned?: boolean

  constructor(props: IQtyAdjustmentViewProps) {
    super(props)
    this.state = INITIAL_STATE
  }

  async componentDidMount() {
    await this._initializeView()
  }

  _initializeView = async () => {
    await util.setStatePromise(this, { isInitialized: false, products: [], selectedReason: null })
    // Init activeStatesMap
    const newState: Partial<IQtyAdjustmentViewState> = {}
    const mode = this._getMode()

    if (mode === 'add') {
      // eslint-disable-next-line prefer-destructuring
      newState.selectedReason = ADJUST_ADD_REASON_OPTIONS[0]
    }

    if (mode === 'deduct') {
      // eslint-disable-next-line prefer-destructuring
      newState.selectedReason = ADJUST_DEDUCT_REASON_OPTIONS[0]
    }

    if (this._isSkuSearchAvailable() && !this._isUpcSearchAvailable()) {
      newState.segmentSearchTypeAtIndex = SEGMENT_SCAN_TYPE_OPTIONS.findIndex((opt) => opt.value === 'sku')
    }

    if (!this._isSkuSearchAvailable() && this._isUpcSearchAvailable()) {
      newState.segmentSearchTypeAtIndex = SEGMENT_SCAN_TYPE_OPTIONS.findIndex((opt) => opt.value === 'upc')
    }

    if (!p.op.isWeb() && (this._isUpcSearchAvailable() || this._isSkuSearchAvailable())) {
      const isBarcodeScanByCamera = await p.op.storageGet(IS_USE_CAMERA_TO_SCAN_SAVE_STATE_KEY)
      if (_.isBoolean(isBarcodeScanByCamera)) {
        newState.isBarcodeScanByCamera = isBarcodeScanByCamera
      }
    }

    // get ready to render
    newState.isInitialized = true

    await util.setStatePromise(this, newState)
  }

  _resetView = async () => {
    await util.setStatePromise(this, INITIAL_STATE)
    await util.delay(200)
    await this._initializeView()
  }

  _handlePressBackBtn = async () => {
    // conditionally go back
    const { products } = this.state

    let isUserConfirm = true
    if (products.length > 0) {
      isUserConfirm = await p.op.isUserConfirm('ยกเลิกการปรับจำนวนสินค้า?', 'กรุณายืนยันว่าข้อมูลที่ระบุไว้จะถูกลบทั้งหมด')
    }

    if (!isUserConfirm) {
      return
    }

    this._navBack()
  }

  _navBack = () => {
    util.navGoBack(this.props)
  }

  _getMode = () => util.getNavParam(this.props, 'mode', 'set')

  _getHeaderTitle = () => {
    let title = util.getNavParam(this.props, 'title', null)

    if (_.isString(title)) {
      return title
    }

    const mode = this._getMode()

    if (mode === 'add') {
      title = `เพิ่มจำนวนสินค้า`
    }

    if (mode === 'deduct') {
      title = `ลดจำนวนสินค้า`
    }

    if (mode === 'set') {
      title = `ปรับจำนวนสินค้า`
    }

    if (mode === 'count') {
      title = 'นับสต๊อกสินค้า'
    }

    return title
  }

  _getColorScheme = () => {
    const colorScheme = util.getNavParam(this.props, 'colorScheme', 'primary')
    return colorScheme
    // const mode = this._getMode()
    // if (mode === 'add') {
    //   return 'success'
    // }

    // if (mode === 'deduct') {
    //   return 'danger'
    // }

    // if (mode === 'set') {
    //   return 'purple'
    // }

    // if (mode === 'count') {
    //   return 'info'
    // }

    // return 'primary'
  }

  _getSectionBarInlineColorStyle = (isExpand: boolean) => {
    const colorScheme = this._getColorScheme()

    const styled = {
      borderWidth: '1',
      bg: `${colorScheme}.100`,
      borderLeftColor: `${colorScheme}.600`,
      borderTopColor: `${colorScheme}.600`,
      borderRightColor: `${colorScheme}.700`,
      borderBottomColor: `${colorScheme}.700`,
      borderTopLeftRadius: 'lg',
      borderTopRightRadius: 'lg',
      borderBottomLeftRadius: 'lg',
      borderBottomRightRadius: 'lg',
    }

    if (isExpand) {
      styled.borderBottomLeftRadius = 'none'
      styled.borderBottomRightRadius = 'none'
    }

    return styled
  }

  _renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)

    return (
      <XCustomHeader
        title={this._getHeaderTitle()}
        headerLeftProps={isGoBackButtonHidden ? undefined : { backIcon: true, onPressItem: this._handlePressBackBtn }}
        // renderHeaderRight={this._renderSettingIcon}
      />
    )
  }

  _onProductItemPressed = async (productItem: ISearchProductItem) => {
    // console.log('onProductItemPressed', productItem)
    if (productItem && _.isNumber(productItem.s)) {
      p.op.alert('ไม่ใช่สินค้าของร้านฉัน', 'กรุณาเลือกปรับจำนวนสินค้าเฉพาะสินค้าของร้านฉันเท่านั้น')
      return
    }

    this._onSelectProductItem(productItem)
  }

  _onSelectProductItem = async (pd: ISearchProductItem) => {
    const mode = this._getMode()
    const { products, ppQtyChanged } = this.state

    const foundIndex = _.findIndex(products, (item) => item.id === pd.id && item.v[0].i === pd.v[0].i)

    let newProducts = _.cloneDeep(products)
    const newPpQtyChanged = _.cloneDeep(ppQtyChanged)
    const ppId = pd.v[0].i
    const selectedPpQty = pd.v[0].q || 0
    const adjustedQty = _.isNumber(ppQtyChanged[ppId]) ? ppQtyChanged[ppId] : selectedPpQty

    const ptppName = `${pd.n} ${pd.v[0].n}`

    let newQty = adjustedQty
    let toastMessage = ''
    // let toastType: 'success' | 'warning' | 'danger' = 'success'

    switch (mode) {
      case 'add': {
        toastMessage += 'เพิ่ม'
        break
      }
      case 'deduct': {
        toastMessage += 'ลด'
        break
      }
      case 'set': {
        // toastMessage += 'ปรับ'
        toastMessage += 'เพิ่มสินค้าในรายการตรวจสอบแล้ว'
        break
      }
      case 'count': {
        toastMessage += 'นับ'
        break
      }
      default: {
        toastMessage += 'ปรับ'
        break
      }
    }

    const isAlreadyOnList = foundIndex > -1

    if (isAlreadyOnList && mode === 'add') {
      newQty = adjustedQty + 1
      newPpQtyChanged[ppId] = newQty > MAX_QTY ? MAX_QTY : newQty
    }

    if (isAlreadyOnList && mode === 'deduct') {
      newQty = adjustedQty - 1
      newPpQtyChanged[ppId] = newQty >= MIN_QTY ? newQty : 0
    }

    if (isAlreadyOnList && mode === 'set') {
      // p.op.alert('สินค้านี้อยู่ในรายการแล้ว')
      p.op.showToast('สินค้านี้อยู่ในรายการแล้ว', 'warning')
      return
    }

    if (isAlreadyOnList && mode === 'count') {
      newQty = adjustedQty + 1
      newPpQtyChanged[ppId] = newQty
    }

    if (!isAlreadyOnList && mode === 'add') {
      newProducts = [pd].concat(newProducts)
      newQty = selectedPpQty + 1
      newPpQtyChanged[ppId] = newQty
    }

    if (!isAlreadyOnList && mode === 'deduct') {
      newProducts = [pd].concat(newProducts)
      newQty = selectedPpQty - 1
      newQty = newQty >= 0 ? newQty : 0
      newPpQtyChanged[ppId] = newQty
    }

    if (!isAlreadyOnList && mode === 'set') {
      newProducts = [pd].concat(newProducts)
      newQty = selectedPpQty
      newPpQtyChanged[ppId] = selectedPpQty
    }

    if (!isAlreadyOnList && mode === 'count') {
      newProducts = [pd].concat(newProducts)
      newQty = 1
      newPpQtyChanged[ppId] = newQty
    }

    if (newQty < MIN_QTY) {
      p.op.showToast(`จำนวนสินค้าต้องมากกว่า ${MIN_QTY}`, 'warning')
      return
    }

    if (newQty > MAX_QTY) {
      p.op.showToast(`จำนวนสินค้าต้องน้อยกว่า ${MAX_QTY}`, 'warning')
      return
    }

    const diffQty = newQty - selectedPpQty

    if (diffQty !== 0) {
      toastMessage += ` (${diffQty > 0 ? '+' : ''}${diffQty})`
    }

    toastMessage += `\n\n${newPpQtyChanged[ppId]}x ${ptppName}`

    // p.op.showToast(toastMessage, toastType)
    p.op.showToast(toastMessage, 'success')
    await util.setStatePromise(this, { products: newProducts, ppQtyChanged: newPpQtyChanged })
  }

  _onSearchProductVariantSuccess = async (searchText: string, products: ISearchProductItem[], productCount: number) => {
    const { searchCache } = this.state

    if (products.length === 0) {
      return
    }

    const searchKey = `${searchText}_all`
    const newSearchCache = _.cloneDeep(searchCache)

    if (searchKey in searchCache) {
      newSearchCache[searchKey].products = products
      newSearchCache[searchKey].count = _.isNumber(productCount) ? productCount : newSearchCache[searchKey].count
    } else {
      newSearchCache[searchKey] = { products, count: productCount }
    }

    await util.setStatePromise(this, { searchCache: newSearchCache }) // update search cache
  }

  _navToSearchProductVariantListView = (extraParams?: Partial<ISearchProductVariantListViewNavParams>) => {
    const { navigation } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    const title = `ค้นหาสินค้าสำหรับ ${this._getHeaderTitle()}`
    navigation.dispatch(
      NavActions.navToSearchProductVariantListView({
        ...extraParams,
        store_id,
        title,
        defaultFilter: {
          segmentHasParentIndex: 2,
        },
        onProductItemPressed: this._onProductItemPressed,
        onSearchSuccess: this._onSearchProductVariantSuccess,
      })
    )
  }

  _onDeleteProductItem = async (idx: number) => {
    if (this._inProcess) {
      return
    }
    this._inProcess = true

    const { products, ppQtyChanged } = this.state
    const newProducts = _.cloneDeep(products)
    newProducts.splice(idx, 1)

    const newPpQtyChanged = _.cloneDeep(ppQtyChanged)
    delete newPpQtyChanged[products[idx].v[0].i]
    await util.setStatePromise(this, { products: newProducts, ppQtyChanged: newPpQtyChanged })

    this._inProcess = false
  }

  _onRequestOpenEditQty = (idx: number) => {
    const { products, ppQtyChanged } = this.state
    const pd = products[idx]

    if (_.isNil(pd)) {
      return
    }

    const inputQtyValue = ppQtyChanged[pd.v[0].i] || 0
    this.setState({ editQtyOverlayIndex: idx, editQtyInputValue: inputQtyValue })
  }

  _onRequestCloseEditQty = () => {
    this.setState({ editQtyOverlayIndex: -1 })
  }

  // _onChangeInputQtyText = (newText: string) => {
  //   this.setState({ editQtyInputValue: newText })
  // }

  _onChangeInputQtyValue = (newValue: number) => {
    this.setState({ editQtyInputValue: newValue })
  }

  _onSubmitInputQty = async () => {
    const { editQtyOverlayIndex: idx, products, ppQtyChanged, editQtyInputValue } = this.state

    const mode = this._getMode()
    const pd = products[idx]
    const newPpQtyChanged = _.cloneDeep(ppQtyChanged)

    const { v: vs } = pd
    const v = vs[0]

    const currentQty = _.isNil(v.q) ? 0 : v.q
    const changedDiff = editQtyInputValue - currentQty

    const isDiffNegativeValue = changedDiff < 0
    const isErrorPositiveValue = mode === 'add' && isDiffNegativeValue
    const isErrorNeativeValue = mode === 'deduct' && !isDiffNegativeValue
    // const isErrorToSubmit = isErrorNeativeValue || isErrorPositiveValue

    if (isErrorPositiveValue) {
      if (!p.op.isIOS()) {
        p.op.alert(`การ${this._getHeaderTitle()}`, 'กรุณาปรับให้ผลต่าง มากกว่าหรือเท่ากับ 0')
      }
      return
    }

    if (isErrorNeativeValue) {
      if (!p.op.isIOS()) {
        p.op.alert(`การ${this._getHeaderTitle()}`, 'กรุณาปรับให้ผลต่าง ติดลบ')
        return
      }
    }

    newPpQtyChanged[pd.v[0].i] = editQtyInputValue || 0
    await util.setStatePromise(this, { ppQtyChanged: newPpQtyChanged })

    this._onRequestCloseEditQty()
  }

  _renderEditQtyInput = () => {
    const mode = this._getMode()
    const { editQtyOverlayIndex: idx, products, editQtyInputValue } = this.state
    const pd = products[idx]

    if (_.isNil(pd)) {
      return null
    }

    let minValue = MIN_QTY
    let maxValue = MAX_QTY
    if (mode === 'add') {
      minValue = pd.v[0].q || 0
    }

    if (mode === 'deduct') {
      maxValue = pd.v[0].q || 0
    }

    // if (mode === 'count') {
    //   minValue = 1
    // }

    return (
      <XNumericInput
        autoFocus
        textAlign='right'
        focusable
        isInteger
        style={{ flex: 1, height: 34 }}
        value={editQtyInputValue}
        keyboardType='numeric'
        minValue={minValue}
        maxValue={maxValue}
        maxLength={MAX_QTY_DIGITS}
        // onChangeText={this._onChangeInputQtyText}
        onChangeValue={this._onChangeInputQtyValue}
        onSubmitEditing={this._onSubmitInputQty}
      />
    )
  }

  _renderEditQtyOverlay = () => {
    const mode = this._getMode()
    const { editQtyOverlayIndex: idx, products, editQtyInputValue } = this.state

    if (_.isNil(idx) || idx < 0) {
      return null
    }

    const isVisible = idx > -1
    const pd = products[idx]

    if (_.isNil(pd)) {
      return null
    }

    const { n, v: vs, t, id } = pd
    const v = vs[0]
    let imgUri = t[0]
    if (vs[0].img_url) {
      imgUri = vs[0].img_url
    }
    const sku = v.sku || null
    const upc = v.upc || null

    const currentQty = _.isNil(v.q) ? 0 : v.q
    const changedDiff = editQtyInputValue - currentQty

    const isDiffNegativeValue = changedDiff < 0
    const isErrorPositiveValue = mode === 'add' && isDiffNegativeValue
    const isErrorNeativeValue = mode === 'deduct' && !isDiffNegativeValue
    const isErrorToSubmit = isErrorNeativeValue || isErrorPositiveValue

    return (
      <XOverlay
        // contentStyle={{ padding: 8 }}
        contentStyle={{
          width: 320,
          minHeight: 290,
          // height: p.op.isWeb() ? 320 : undefined,

          // avoid keyboard style
          // ...Platform.select({
          //   ios: {
          //     marginBottom: 200,
          //   },
          //   // android: {
          //   //   marginBottom: 35,
          //   // },
          // }),
        }}
        visible={isVisible}
        // onRequestClose={this._onRequestCloseEditQty}
        onRequestClose={() => {
          // do nothing
        }}>
        <XCustomHeader headerRightProps={{ closeIcon: true, onPressItem: this._onRequestCloseEditQty }} title='ระบุจำนวนสินค้า' />
        <View style={[S.WIDTH_FULL, S.BOTTOM_LINE, { height: 1 }]} />
        <VStack flex={1} w='full' p='2' space='1.5'>
          {/* // content begin // */}

          <HStack w='full' space='2'>
            <HStack w='64px' h='64px'>
              <XImage source={{ uri: imgUri }} h='64px' w='64px' borderRadius='lg' />
            </HStack>

            <VStack flex={1}>
              <XText variant='inactive' numberOfLines={1}>
                {pd.n}
              </XText>
              <XText numberOfLines={1}>{pd.v[0].n}</XText>
              {sku ? <XText variant='inactive' numberOfLines={1}>{`SKU: ${sku}`}</XText> : null}
              {upc ? <XText variant='inactive' numberOfLines={1}>{`UPC: ${upc}`}</XText> : null}
            </VStack>
          </HStack>

          <HStack w='full' h='34px' pt='2' alignItems='center' space='1'>
            <XText w='120px' variant='inactive'>
              {`จำนวน${p.op.t('qty')}เดิม`}
            </XText>

            <XText flex={1} pr='35px' textAlign='right' variant='inactive'>
              {currentQty}
            </XText>
          </HStack>

          <HStack w='full' h='34px' alignItems='center' space='1'>
            {changedDiff !== 0 && (
              <XText flex={1} pr='35px' textAlign='right' variant={changedDiff > 0 ? 'success' : 'danger'} bold>
                {` ${changedDiff > 0 ? '+' : ''}${changedDiff}`}
              </XText>
            )}
          </HStack>

          {isErrorPositiveValue && (
            <VStack w='full' p='1.5' alignItems='center' alignContent='center' bg='gray.100' borderRadius='lg'>
              <XText variant='danger' textAlign='center'>{`การ${this._getHeaderTitle()}\nกรุณาปรับให้ผลต่าง มากกว่าหรือเท่ากับ 0`}</XText>
            </VStack>
          )}

          {isErrorNeativeValue && (
            <VStack w='full' p='1.5' alignItems='center' alignContent='center' bg='gray.100' borderRadius='lg'>
              <XText variant='danger' textAlign='center'>{`การ${this._getHeaderTitle()}\nกรุณาปรับให้ผลต่าง ติดลบ`}</XText>
            </VStack>
          )}

          <HStack w='full' alignItems='center' space='1'>
            <XText w='120px' variant='inactive'>
              {`จำนวน${p.op.t('qty')}ใหม่`}
            </XText>

            {this._renderEditQtyInput()}
          </HStack>

          {/* <VStack w='full' alignItems='flex-end'>
            <XText variant='inactive'>(ระบุจำนวนที่ไม่ติดลบ)</XText>
          </VStack> */}
        </VStack>
        {/* // content end // */}
        <HStack w='full' p='2' alignItems='center' justifyContent='center'>
          <TouchableOpacity disabled={isErrorToSubmit} onPress={this._onSubmitInputQty}>
            <HStack
              w='full'
              px='3'
              py='1.5'
              alignItems='center'
              justifyContent='center'
              bg={isErrorToSubmit ? 'muted.300' : 'primary.500'}
              borderRadius='lg'>
              <XText variant={isErrorToSubmit ? 'active' : 'activeDark'} bold>
                ยืนยัน
              </XText>
            </HStack>
          </TouchableOpacity>
        </HStack>
      </XOverlay>
    )
  }

  // _renderListItem = (info: ListRenderItemInfo<ISearchProductItem>) => {
  _renderListItem = (item: ISearchProductItem, index: number) => {
    const mode = this._getMode()
    const { ppQtyChanged } = this.state
    // const { item, index } = info
    // const { n, vn, t } = item
    const { n, v: vs, t, id } = item
    const v = vs[0]
    const productName = n || ''
    const variantName = v.n || ''
    // const priceText = v.p ? v.p : v.s[0].p
    const sku = v.sku || null
    const upc = v.upc || null
    let imgUri = t[0]
    if (vs[0].img_url) {
      imgUri = vs[0].img_url
    }

    const availableQty = _.isNil(v.a) ? 0 : v.a
    const currentQty = _.isNil(v.q) ? 0 : v.q
    const changedQty = _.isNumber(ppQtyChanged[v.i]) ? ppQtyChanged[v.i] : currentQty
    const changedDiff = changedQty - currentQty

    return (
      <VStack
        key={`${index}_${id}`}
        w='full'
        // onPress={this.getOnPressListItem(info.item)}
        borderTopWidth={index === 0 ? '0' : '1'}
        // borderTopWidth='1'
        bg={index % 2 === 0 ? undefined : 'gray.100'}
        borderTopColor='muted.300'>
        <VStack w='full' p='2' space='1'>
          <HStack w='full' space='1.5'>
            <VStack w='34px' space='2'>
              <XText variant='inactive'>{`#${index + 1}`}</XText>

              <XIconButton name='trash' variant='outline' colorScheme='danger' onPress={() => this._onDeleteProductItem(index)} />
            </VStack>

            <HStack flex={1} space='1'>
              <XImage source={{ uri: imgUri }} h='64px' w='64px' borderRadius='lg' />
              <VStack flex={1} p='1'>
                <XText variant='inactive' numberOfLines={2}>
                  {productName}
                </XText>

                <XText numberOfLines={2}>{variantName}</XText>

                {sku ? <XText variant='inactive' numberOfLines={1}>{`SKU: ${sku}`}</XText> : null}
                {upc ? <XText variant='inactive' numberOfLines={1}>{`UPC: ${upc}`}</XText> : null}

                {/* <XText>{`฿${priceText}`}</XText> */}
                {/* <XText>{JSON.stringify(info)}</XText> */}
              </VStack>
            </HStack>
          </HStack>

          <HStack w='full' space='1.5'>
            {/* Column 1 */}
            <VStack w='80px'>
              <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                {/* <XText variant='inactive'>เดิม</XText> */}
              </HStack>

              <HStack h='44px' alignItems='center'>
                <XText w='120px' variant='inactive'>
                  {p.op.t('qty')}
                </XText>
              </HStack>

              <HStack h='34px' alignItems='center'>
                <XText w='120px' variant='inactive'>
                  {p.op.t('available_qty')}
                </XText>
              </HStack>
            </VStack>

            {/* Column 2 */}
            {mode !== 'count' && (
              <VStack flex={1} minW='80px' alignItems='flex-end'>
                <HStack h='34px' w='full' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>เดิม</XText>
                </HStack>

                <HStack h='44px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>{`${currentQty}`}</XText>
                  {changedDiff !== 0 && (
                    <XText bold variant={changedDiff > 0 ? 'success' : 'danger'}>{` ${changedDiff > 0 ? '+' : ''}${changedDiff}`}</XText>
                  )}
                </HStack>

                <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>{`${availableQty}`}</XText>
                  {changedDiff !== 0 && (
                    <XText bold variant={changedDiff > 0 ? 'success' : 'danger'}>{` ${changedDiff > 0 ? '+' : ''}${changedDiff}`}</XText>
                  )}
                </HStack>
              </VStack>
            )}

            {/* Column 3 */}
            {mode !== 'count' && (
              <VStack maxW='120px' minW='80px' alignItems='flex-end'>
                <HStack h='34px' w='full' alignItems='center' justifyContent='flex-end'>
                  <XText variant='active'>ใหม่</XText>
                </HStack>

                <TouchableOpacity onPress={() => this._onRequestOpenEditQty(index)}>
                  <HStack h='44px' minW='40px' alignItems='center' justifyContent='flex-end'>
                    <XText
                      bold
                      variant='primary'
                      style={{
                        textDecorationLine: 'underline',
                        textDecorationColor: COLORS.APP_MAIN,
                      }}>{`${changedQty}`}</XText>
                  </HStack>
                </TouchableOpacity>

                <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='active'>{`${availableQty + changedDiff}`}</XText>
                </HStack>
              </VStack>
            )}

            {/* Column 2 - for count */}
            {mode === 'count' && (
              <VStack flex={1} minW='80px' alignItems='flex-end'>
                <HStack h='34px' w='full' alignItems='center' justifyContent='flex-end'>
                  <XText variant='active'>นับได้</XText>
                </HStack>

                <TouchableOpacity onPress={() => this._onRequestOpenEditQty(index)}>
                  <HStack h='44px' minW='40px' alignItems='center' justifyContent='flex-end'>
                    <XText
                      bold
                      variant='primary'
                      style={{
                        textDecorationLine: 'underline',
                        textDecorationColor: COLORS.APP_MAIN,
                      }}>{`${changedQty}`}</XText>
                  </HStack>
                </TouchableOpacity>

                <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='active'>{`${availableQty + changedDiff}`}</XText>
                </HStack>
              </VStack>
            )}

            {/* Column 3 - for count */}
            {mode === 'count' && (
              <VStack maxW='120px' minW='80px' alignItems='flex-end'>
                <HStack h='34px' w='full' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>ปัจจุบันมี</XText>
                </HStack>

                <HStack h='44px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>{`${currentQty}`}</XText>
                </HStack>

                <HStack h='34px' alignItems='center' justifyContent='flex-end'>
                  <XText variant='inactive'>{`${availableQty}`}</XText>
                </HStack>
              </VStack>
            )}
          </HStack>
        </VStack>
      </VStack>
    )

    // return (
    //   <VStack
    //     key={`${index}_${id}`}
    //     w='full'
    //     // onPress={this.getOnPressListItem(info.item)}
    //     // borderTopWidth={index === 0 ? '0' : '1'}
    //     borderTopWidth='1'
    //     borderTopColor='muted.300'>
    //     <HStack w='full' p='2' space='1'>
    //       <VStack w='34px' space='2'>
    //         <XText variant='inactive'>{`#${index + 1}`}</XText>

    //         <XIconButton name='trash' variant='outline' colorScheme='danger' onPress={() => this._onDeleteProductItem(index)} />
    //       </VStack>

    //       <HStack flex={1} space='1'>
    //         <XImage source={{ uri: imgUri }} h='64px' w='64px' borderRadius='lg' />
    //         <VStack flex={1} p='1'>
    //           <XText variant='inactive' numberOfLines={2}>
    //             {productName}
    //           </XText>

    //           <XText numberOfLines={2}>{variantName}</XText>

    //           {sku ? <XText variant='inactive'>{`SKU: ${sku}`}</XText> : null}
    //           {upc ? <XText variant='inactive'>{`UPC: ${upc}`}</XText> : null}

    //           {/* <XText>{`฿${priceText}`}</XText> */}
    //           {/* <XText>{JSON.stringify(info)}</XText> */}
    //         </VStack>
    //       </HStack>

    //       <VStack w='160px' alignItems='flex-end' space='1.5'>
    //         <HStack w='160px'>
    //           <HStack w='80px' justifyContent='flex-end'>
    //             <XText>{`${currentQty}`}</XText>
    //           </HStack>
    //           <HStack w='80px' justifyContent='flex-end'>
    //             <XText>{`${changedQty}`}</XText>
    //           </HStack>
    //         </HStack>

    //         <HStack w='160px' justifyContent='flex-end'>
    //           <TouchableOpacity onPress={() => this._onRequestOpenEditQty(index)}>
    //             <XIcon name='edit' family='FontAwesome' />
    //           </TouchableOpacity>
    //         </HStack>
    //       </VStack>
    //     </HStack>
    //   </VStack>
    // )
  }

  _onBarcodeSegmentChange = async (newIndex: number) => {
    await util.setStatePromise(this, { segmentBarcodeAtIndex: newIndex })

    // // save segment state
    // await util.saveDefaultState(CONS.STATE_SAVE_KEYS.QTY_ADJUSTMENT_SEARCH_PRODUCTS, { segmentBarcodeIndex: newIndex })
  }

  _searchProducts = async (searchText: string) => {
    const { segmentSearchTypeAtIndex = 0, searchCache } = this.state
    const store_id = util.getNavParam(this.props, 'store_id')

    try {
      const reqParams: ISearchInventoryProductsParams = {
        store_id,
        offset: 0,
        limit: CONS.PRODUCTS_FETCH_LIMIT_MORE,
        queryText: searchText,
        c: 1,
        has_parent: false,
      }

      // const segmentSearchTypeOptions = this._getSegmentSearchTypeOptions()
      // const opt = segmentSearchTypeOptions[segmentSearchTypeAtIndex] || null
      const opt = SEGMENT_SCAN_TYPE_OPTIONS[segmentSearchTypeAtIndex] || null

      // console.log('_searchProducts segmentSearchTypeAtIndex => ', segmentSearchTypeAtIndex)
      // console.log('_searchProducts opt => ', opt)
      if (opt && 'value' in opt && opt.value === 'sku') {
        reqParams.is_query_pp_name = false
        reqParams.is_query_sku = true
        reqParams.is_query_upc = false
        reqParams.is_exact_match_sku = true
        reqParams.is_exact_match_upc = false
      }

      if (opt && 'value' in opt && opt.value === 'upc') {
        reqParams.is_query_pp_name = false
        reqParams.is_query_sku = false
        reqParams.is_query_upc = true
        reqParams.is_exact_match_sku = false
        reqParams.is_exact_match_upc = true
      }

      if (opt && 'value' in opt && opt.value === 'all' && this._isSkuSearchAvailable() && this._isUpcSearchAvailable()) {
        reqParams.is_query_pp_name = false
        reqParams.is_query_sku = true
        reqParams.is_query_upc = true
        reqParams.is_exact_match_sku = true
        reqParams.is_exact_match_upc = true
      }
      const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
      if (this.isHelper() && !seller_store_id) {
        // @ts-ignore
        reqParams.is_helper = true
      }
      // if (opt && 'value' in opt && opt.value === 'name') {
      //   reqParams.is_query_sku = false
      //   reqParams.is_query_upc = false
      //   reqParams.is_query_pp_name = true
      // }

      let res

      // console.log('_searchProducts reqParams => ', reqParams)

      const searchKey = `${searchText}_${opt && opt.value ? opt.value : 'all'}`
      if (searchKey in searchCache) {
        res = searchCache[searchKey]
      } else {
        try {
          res = await api.searchInventoryProducts(reqParams)
        } catch (error) {
          await p.op.alertPromise('เกิดข้อผิดพลาด', `กรุณาติดต่อทีมงาน (${JSON.stringify(error)})`)
          return
        }

        const newSearchCache = _.cloneDeep(searchCache)
        newSearchCache[searchKey] = res
        await util.setStatePromise(this, { searchCache: newSearchCache })
      }

      // console.log('_searchProducts res => ', res)

      if (!res.products || res.products.length === 0) {
        await p.op.alertPromise('ไม่พบสินค้า', 'กรุณาตรวจสอบความถูกต้องและค้นหาสินค้าใหม่อีกครั้ง')
        return
      }

      if (res.products.length === 1) {
        const product = res.products[0]
        await this._onSelectProductItem(product)
        return
      }

      if (res.products.length > 1) {
        this._navToSearchProductVariantListView({ initialData: { products: res.products, searchText, productCount: res.count } })
        return
      }

      p.op.alert('ไม่พบสินค้า', 'กรุณาตรวจสอบความถูกต้องและค้นหาสินค้าใหม่อีกครั้ง')
    } catch (err) {
      console.log('_searchProducts err => ', err)
      await p.op.alertPromise('เกิดข้อผิดพลาด', JSON.stringify(err))
    }
  }

  _onBarcodeScanned = async (barcode: IBarcodeScannerOutput) => {
    if (barcode.data && !this.state.isBarcodeRead) {
      if (this._isBarcodeScanned) {
        return
      }
      this._isBarcodeScanned = true
      await util.setStatePromise(this, { isBarcodeRead: true })

      const searchText = barcode.data

      // เข้าใจว่า android มีเสียง beep by default และเราไม่ต้องการให้มันเปิดเสียง beep อีก
      if (!p.op.isAndroid()) {
        p.op.playSoundBeepShort()
      }

      await this._searchProducts(searchText)

      await util.delay(1000)
      await util.setStatePromise(this, { isBarcodeRead: false })
      this._isBarcodeScanned = false
    }
  }

  _onSearchTypeSegmentChange = async (selectedOption: IXSegmentOption<any>, newIndex: number) => {
    await util.setStatePromise(this, { segmentSearchTypeAtIndex: newIndex })
  }

  _isSkuSearchAvailable = () => {
    const { selectedStore } = this.props
    return selectedStore.get(CONS.STORE_SETTINGS.COMPUTED_USE_PRODUCT_SKU)
  }

  _isUpcSearchAvailable = () => {
    const { selectedStore } = this.props
    return selectedStore.get(CONS.STORE_SETTINGS.COMPUTED_USE_PRODUCT_UPC)
  }

  // _isSegmentSearchTypeVisible = () => this._isUpcSearchAvailable() || this._isSkuSearchAvailable()
  _isSegmentSearchTypeVisible = () => this._isUpcSearchAvailable() && this._isSkuSearchAvailable()

  // _getSegmentSearchTypeOptions = () => {
  //   const options = [
  //     { key: 'all', label: 'ทั้งหมด', value: 'all' },
  //     // { key: 'name', label: 'ชื่อสินค้า', value: 'name' },
  //   ]

  //   if (this._isSkuSearchAvailable()) {
  //     options.push({ key: 'sku', label: 'SKU', value: 'sku' })
  //   }

  //   if (this._isUpcSearchAvailable()) {
  //     options.push({ key: 'upc', label: 'UPC', value: 'upc' })
  //   }

  //   return options
  // }

  _renderProductScanContent = () => {
    const { segmentBarcodeAtIndex = 0, segmentSearchTypeAtIndex = 0, isBarcodeScanByCamera } = this.state

    const isSegmentSearchTypeVisible = this._isSegmentSearchTypeVisible()
    return (
      <VStack w='full' p='2' space='1'>
        {/* <TouchableOpacity onPress={() => this._navToSearchProductVariantListView()}>
          <XText fontSize='md' bold variant='active'>
            Mock Search
          </XText>
        </TouchableOpacity> */}

        <HStack w='full' pt='2' justifyContent='center'>
          <TouchableOpacity onPress={() => this._navToSearchProductVariantListView()}>
            <HStack
              px='2.5'
              py='1'
              borderWidth='1'
              borderColor='primary.400'
              bg='gray.50'
              borderRadius='full'
              alignItems='center'
              justifyContent='center'
              space='1.5'>
              <XIcon name='search' variant='primary' />

              <HStack alignItems='center' justifyContent='center'>
                <XText variant='primary'>ค้นหาด้วย ชื่อ และอื่นๆ</XText>
              </HStack>
            </HStack>
          </TouchableOpacity>
        </HStack>

        {isSegmentSearchTypeVisible && (
          <HStack w='full' pt='2' alignItems='center' justifyContent='center'>
            {/* <XText variant='inactive'>หรือ</XText> */}
            {isSegmentSearchTypeVisible && <XText variant='inactive'>หรือ สแกนจาก...</XText>}
            {this._isSkuSearchAvailable() && !this._isUpcSearchAvailable() && <XText variant='inactive'>หรือ สแกนจาก SKU</XText>}
            {!this._isSkuSearchAvailable() && this._isUpcSearchAvailable() && <XText variant='inactive'>หรือ สแกนจาก UPC</XText>}
          </HStack>
        )}

        <VStack w='full' px='1'>
          {isSegmentSearchTypeVisible && (
            <XSegment
              // options={this._getSegmentSearchTypeOptions()}
              options={SEGMENT_SCAN_TYPE_OPTIONS}
              selectedIndex={segmentSearchTypeAtIndex}
              onSegmentChange={this._onSearchTypeSegmentChange}
            />
          )}
          {/* {isSegmentSearchTypeVisible && <Box h='4px' w='full' />} */}
        </VStack>

        {!p.op.isWeb() && (this._isUpcSearchAvailable() || this._isSkuSearchAvailable()) ? (
          <HStack w='full' minH='34px' px='1' py='1' space='1' alignItems='center'>
            <XText variant='inactive'>เปิดใช้งานกล้อง</XText>

            <HStack flex={1} pr='1' alignItems='center' justifyContent='flex-end'>
              <XSwitch
                value={isBarcodeScanByCamera}
                onValueChange={(newValue) => {
                  this.setState({ isBarcodeScanByCamera: newValue }, () => {
                    p.op.storageSet(IS_USE_CAMERA_TO_SCAN_SAVE_STATE_KEY, newValue)
                  })
                }}
              />
            </HStack>
          </HStack>
        ) : null}

        {this._isSkuSearchAvailable() || this._isUpcSearchAvailable() ? (
          <BarcodeScannerWithSegment
            // saveStateKey={CONS.STATE_SAVE_KEYS.QTY_ADJUSTMENT_SEARCH_PRODUCTS}
            // disabled={isBarcodeRead}
            inputPlaceholder='สแกน SKU หรือ UPC...'
            selectedIndex={segmentBarcodeAtIndex}
            onChangeSegment={this._onBarcodeSegmentChange}
            // cameraContainerHeight={200}
            onBarcodeScanned={this._onBarcodeScanned}
            hideSegment
            forceTypingText={!isBarcodeScanByCamera && !p.op.isWeb()}
          />
        ) : null}
        <Box h='4px' w='full' />
      </VStack>
    )
  }

  _onPressProductScanToggle = () => {
    const { isProductScanExpanded } = this.state
    this.setState({ isProductScanExpanded: !isProductScanExpanded })
  }

  _onPressFillExtraDataToggle = () => {
    const { isFillExtraDataExpanded } = this.state
    this.setState({ isFillExtraDataExpanded: !isFillExtraDataExpanded })
  }

  _onPressProductListToggle = () => {
    const { isProductListExpanded } = this.state
    this.setState({ isProductListExpanded: !isProductListExpanded })
  }

  _renderProductScanCard = () => {
    const { isProductScanExpanded } = this.state

    return (
      <CollapsableCard
        disabledAnimation
        // disabled={props.disabled}
        expanded={isProductScanExpanded}
        onPressToggle={this._onPressProductScanToggle}
        renderContent={this._renderProductScanContent}>
        <HStack h='44px' w='full' p='2' alignItems='center' {...this._getSectionBarInlineColorStyle(isProductScanExpanded)}>
          <VStack flex={1}>
            <XText variant='inactive' bold>
              ค้นหาสินค้า
            </XText>
          </VStack>

          <HStack w='38px' justifyContent='flex-end' alignItems='center'>
            <XIcon
              variant='inactive'
              size='xs'
              name={isProductScanExpanded ? 'arrow-collapse-up' : 'arrow-expand-down'}
              family='MaterialCommunityIcons'
            />
          </HStack>
        </HStack>
      </CollapsableCard>
    )
  }

  _renderProductListContent = () => {
    const { products } = this.state

    // if (products.length === 0) {
    //   return null
    // }

    const isEmptyList = products.length === 0

    return (
      <VStack w='full' p='2' space='1'>
        {/* <VStack w='full'>
          <HStack w='full' p='2' alignItems='center' space='1'>
            <HStack flex={1} space='1'>
              <XText numberOfLines={2}>สินค้า</XText>
            </HStack>
            <HStack w='80px' justifyContent='flex-end'>
              <XText fontSize='xs'>จำนวนปัจจุบัน</XText>
            </HStack>
            <HStack w='80px' justifyContent='flex-end'>
              <XText fontSize='xs'>จำนวนใหม่</XText>
            </HStack>
          </HStack>
        </VStack> */}

        {isEmptyList && (
          <HStack
            w='full'
            p='2'
            alignItems='center'
            justifyContent='center'
            // borderStyle='dashed'
            // borderColor='gray.400'
            // bg='gray.100'
            // borderWidth='1'
            borderRadius='lg'>
            <XText variant='inactive'>ค้นหาสินค้า ด้านบน เพื่อเพิ่มสินค้าลงในรายการนี้</XText>
          </HStack>
        )}
        {products.map((item, index) => this._renderListItem(item, index))}
      </VStack>
    )
  }

  _renderProductListCard = () => {
    const { isProductListExpanded } = this.state

    return (
      <CollapsableCard
        disabledAnimation
        // disabled={props.disabled}
        expanded={isProductListExpanded}
        onPressToggle={this._onPressProductListToggle}
        renderContent={this._renderProductListContent}>
        <HStack h='44px' w='full' p='2' alignItems='center' {...this._getSectionBarInlineColorStyle(isProductListExpanded)}>
          <HStack flex={1}>
            <XText variant='inactive' bold>
              ตรวจสอบ/แก้ไข รายการสินค้า
            </XText>
          </HStack>

          <HStack w='38px' justifyContent='flex-end' alignItems='center'>
            <XIcon
              variant='inactive'
              size='xs'
              name={isProductListExpanded ? 'arrow-collapse-up' : 'arrow-expand-down'}
              family='MaterialCommunityIcons'
            />
          </HStack>
        </HStack>
      </CollapsableCard>
    )
  }

  _onSelectReason = (reason: IXSegmentOption<IQtyAdjustReason>, index: number) => {
    this.setState({ selectedReason: reason })
  }

  _renderReasonSelectorItem = (opt: IXSegmentOption<IQtyAdjustReason>, index: number) => {
    const { selectedReason } = this.state
    const checked = selectedReason && selectedReason.key === opt.key

    return (
      <HStack key={`qa-reason-${opt.key}_${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onSelectReason(opt, index)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {/* {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />} */}
            {checked ? <XIcon name='checkmark' /> : <Box h='24px' w='1px' />}
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {opt.label}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderReasonSelector = () => {
    const mode = this._getMode()
    if (mode === 'set' || mode === 'count') {
      return null
    }

    return (
      <VStack w='full' space='1'>
        <XText variant='inactive'>{`เหตุผลการ${this._getHeaderTitle()}`}</XText>
        <HStack w='full' space='1.5'>
          {mode === 'add' && ADJUST_ADD_REASON_OPTIONS.map(this._renderReasonSelectorItem)}
          {mode === 'deduct' && ADJUST_DEDUCT_REASON_OPTIONS.map(this._renderReasonSelectorItem)}
        </HStack>
      </VStack>
    )
  }

  _renderFillExtraDataContent = () => {
    const { refCode, note } = this.state
    return (
      <VStack w='full' p='2' space='2'>
        {this._renderReasonSelector()}

        <VStack w='full' space='1'>
          <XText variant='inactive'>รหัสอ้างอิง</XText>

          <XInput placeholder='ระบุรหัสอ้างอิง เช่น PO123456' value={refCode} onChangeText={(text) => this.setState({ refCode: text })} />
        </VStack>

        <VStack w='full' space='1'>
          <XText variant='inactive'>หมายเหตุ</XText>

          <XInput
            placeholder='ระบุหมายเหตุ เช่น เติมสินค้าจากสำนักงานใหญ่'
            value={note}
            onChangeText={(text) => this.setState({ note: text })}
            multiline
            style={{ minHeight: 60 }}
          />
        </VStack>
      </VStack>
    )
  }

  _renderFillExtraDataCard = () => {
    const { isFillExtraDataExpanded } = this.state

    return (
      <CollapsableCard
        disabledAnimation
        // disabled={props.disabled}
        expanded={isFillExtraDataExpanded}
        onPressToggle={this._onPressFillExtraDataToggle}
        renderContent={this._renderFillExtraDataContent}>
        <HStack h='44px' w='full' p='2' alignItems='center' {...this._getSectionBarInlineColorStyle(isFillExtraDataExpanded)}>
          <VStack flex={1}>
            <XText variant='inactive' bold>
              ระบุข้อมูลอ้างอิง
            </XText>
          </VStack>

          <HStack w='38px' justifyContent='flex-end' alignItems='center'>
            <XIcon
              variant='inactive'
              size='xs'
              name={isFillExtraDataExpanded ? 'arrow-collapse-up' : 'arrow-expand-down'}
              family='MaterialCommunityIcons'
            />
          </HStack>
        </HStack>
      </CollapsableCard>
    )
  }

  _renderContent() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XContent>
        <VStack w='full' p='2' space='1.5'>
          <XCard overflow='hidden'>{this._renderFillExtraDataCard()}</XCard>

          <XCard overflow='hidden'>{this._renderProductScanCard()}</XCard>

          <XCard overflow='hidden'>{this._renderProductListCard()}</XCard>
          <View style={{ height: 144 }} />
        </VStack>
      </XContent>
    )
  }

  _onSubmitSuccess = () => {
    p.op.showToast('บันทึกข้อมูลสำเร็จ', 'success')
    this._resetView()
  }

  _onPressSubmit = () => {
    console.log('onPressSubmit')

    const { navigation } = this.props
    const { products, ppQtyChanged, refCode, note, selectedReason } = this.state
    const store_id = util.getNavParam(this.props, 'store_id')
    const mode = this._getMode()

    // const title = `ตรวจสอบ${this._getHeaderTitle()}`
    const title = this._getHeaderTitle()
    const colorScheme = this._getColorScheme()
    const titleIcon = util.getNavParam(this.props, 'titleIcon')
    const confirmationTitle = util.getNavParam(this.props, 'confirmationTitle')

    navigation.dispatch(
      NavActions.navToQtyAdjustmentConfirmationView({
        title,
        titleIcon,
        confirmationTitle,
        mode,
        colorScheme,
        store_id,
        products,
        ppQtyChanged,
        refCode,
        note,
        selectedReason,
        onSubmitSuccess: this._onSubmitSuccess,
      })
    )
  }

  _renderFooter = () => {
    const { products } = this.state

    // let submitText = `ตรวจสอบ${this._getHeaderTitle()}`
    let submitText = 'ตรวจสอบ'
    let isSubmitButtonDisabled = false

    if (!products || products.length === 0) {
      isSubmitButtonDisabled = true
      submitText = `เพิ่มสินค้าก่อน${submitText}`
    }

    return (
      <HStack w='full' p='2' bg='gray.200' minH='44px' borderTopWidth='1' borderTopColor='gray.400'>
        <TouchableOpacity disabled={isSubmitButtonDisabled} style={{ flex: 1 }} onPress={this._onPressSubmit}>
          <HStack
            h='44px'
            w='full'
            alignItems='center'
            justifyContent='center'
            borderWidth='1'
            borderRadius='lg'
            borderColor={isSubmitButtonDisabled ? 'gray.400' : 'primary.500'}
            bg={isSubmitButtonDisabled ? 'gray.300' : 'primary.500'}>
            <XText fontSize='md' bold variant={isSubmitButtonDisabled ? 'inactive' : 'activeDark'} textAlign='center'>
              {submitText}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  isHelper = () => {
    const { selectedStore } = this.props
    return selectedStore.has('role_id') ? selectedStore.get('role_id') === CONS.STORE_ROLE.HELPER : false
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        {this._renderContent()}
        {this._renderFooter()}
        {this._renderEditQtyOverlay()}
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
  })
)(QtyAdjustmentView)
