import React from 'react'
import _ from 'lodash'

import {
  Modal,
  Dimensions,
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  Platform,
  LayoutChangeEvent,
  KeyboardAvoidingView,
} from 'react-native'
import { IXOverlayProps } from 'x/index'

import CONS from 'x/config/constants'
import XIconButton from './XIconButton'
import VStack from './VStack'
// import { log, setStatePromise } from 'x/utils/util'
// import NativeBaseProvider from 'xui/components/NativeBaseProvider'

// import VStack from 'xui/components/VStack'

const DEVICE_WIDTH = Dimensions.get('window').width
const DEVICE_HEIGHT = Dimensions.get('window').height

// const MIN_CONTENT_WIDTH = Math.floor(DEVICE_WIDTH / 2)
// const MAX_CONTENT_WIDTH = DEVICE_WIDTH - 32
// const INITIAL_CONTENT_WIDTH = Math.floor(MAX_CONTENT_WIDTH * 0.75)

// // const MIN_CONTENT_HEIGHT = Math.floor(DEVICE_HEIGHT / 4)
// const MIN_CONTENT_HEIGHT = 50
// const MAX_CONTENT_HEIGHT = DEVICE_HEIGHT - 64
// const INITIAL_CONTENT_HEIGHT = Math.floor(MAX_CONTENT_HEIGHT * 0.75)

interface IXOverlayState {
  containerWidth: number
  containerHeight: number
}

class XOverlay extends React.Component<IXOverlayProps, IXOverlayState> {
  static displayName = 'XOverlay'

  static defaultProps = {
    transparent: true,
    animationType: 'fade',
    supportedOrientations: ['portrait'],
    iosBarStyle: 'dark-content',
    contentStyle: {},
    style: {},
    children: null,
    isBackdropCloseDisabled: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      containerWidth: DEVICE_WIDTH,
      containerHeight: DEVICE_HEIGHT,
    }
  }

  _onRequestToCloseModal = () => {
    const { onRequestClose } = this.props
    if (_.isFunction(onRequestClose)) {
      onRequestClose()
    }
  }

  _onContainerLayout = (evt: LayoutChangeEvent) => {
    try {
      const { width, height } = evt.nativeEvent.layout
      if ((width && width !== this.state.containerWidth) || (height && height !== this.state.containerHeight)) {
        this.setState({ containerWidth: width, containerHeight: height })
      }
    } catch (err) {
      //
    }
  }

  _getContentWidthStyle = () => {
    const { containerWidth = DEVICE_WIDTH, containerHeight = DEVICE_HEIGHT } = this.state
    const minContentWidth = Math.floor(containerWidth / 2)
    const maxContentWidth = containerWidth - 32
    // const INITIAL_CONTENT_WIDTH = Math.floor(maxContentWidth * 0.75)

    // const minContentHeight = Math.floor(DEVICE_HEIGHT / 4)
    const minContentHeight = 50
    const maxContentHeight = containerHeight - 64

    return {
      minWidth: minContentWidth,
      maxWidth: maxContentWidth,
      minHeight: minContentHeight,
      maxHeight: maxContentHeight,

      // initial w/h
      // width: INITIAL_CONTENT_WIDTH,
      // height: INITIAL_CONTENT_HEIGHT,

      ...Platform.select({
        web: {
          minWidth: Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH * 0.75),
          maxWidth: CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH,
          // width: CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH,
          minHeight: 50,
          // minHeight: Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MIN_WEB_HEIGHT * 0.5),
          maxHeight: '90%',
          // height: Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MIN_WEB_HEIGHT * 0.8),
        },
      }),
    }
  }

  render() {
    const { visible, isBackdropCloseDisabled, onRequestClose, contentStyle, children, ...restProps } = this.props

    if (!visible) {
      return null
    }
    //  <View style={[s.container, { paddingTop: this._getStatusBarPaddingTop() }, containerStyle]}>
    return (
      <Modal
        visible={visible}
        statusBarTranslucent
        onRequestClose={this._onRequestToCloseModal}
        // style={[{ backgroundColor: 'red', alignItems: 'center', justifyContent: 'center' }, StyleSheet.flatten(style)]}
        {...restProps}>
        {/* <NativeBaseProvider> */}
        <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <View
            style={Platform.OS === 'web' && this.state.containerWidth < 650 ? s.containerForMobileWeb : s.container}
            onLayout={this._onContainerLayout}>
            <TouchableWithoutFeedback disabled={isBackdropCloseDisabled} onPress={this._onRequestToCloseModal}>
              <View style={s.closableBg}>
                {Platform.OS === 'web' && !isBackdropCloseDisabled ? (
                  <XIconButton
                    name='close-circle-outline'
                    onPress={this._onRequestToCloseModal}
                    position='absolute'
                    top='0'
                    right='0'
                    //  style={s.webCloseIconButton}
                  />
                ) : null}
              </View>
            </TouchableWithoutFeedback>
            {/* <VStack
          // w='full'
          style={[
            // s.content,
            contentStyle,
          ]}>
          {children}
        </VStack> */}
            <VStack w='full' style={[s.content, this._getContentWidthStyle(), StyleSheet.flatten(contentStyle)]}>
              {children}
            </VStack>
          </View>
          {/* </NativeBaseProvider> */}
        </KeyboardAvoidingView>
      </Modal>
    )
  }
}

const s = StyleSheet.create({
  content: {
    // flex: 0,
    // flexBasis: 'auto',
    overflow: 'hidden',
    flexWrap: 'wrap',
    borderRadius: 8,
    // minWidth: MIN_CONTENT_WIDTH,
    // maxWidth: MAX_CONTENT_WIDTH,
    // minHeight: MIN_CONTENT_HEIGHT,
    // maxHeight: MAX_CONTENT_HEIGHT,

    // // initial w/h
    // // width: INITIAL_CONTENT_WIDTH,
    // // height: INITIAL_CONTENT_HEIGHT,

    // ...Platform.select({
    //   web: {
    //     minWidth: Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH * 0.75),
    //     maxWidth: CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH,
    //     // width: CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH,
    //     minHeight: 50,
    //     // minHeight: Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MIN_WEB_HEIGHT * 0.5),
    //     maxHeight: '90%',
    //     // height: Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MIN_WEB_HEIGHT * 0.8),
    //   },
    // }),

    // marginHorizontal: 8,
    // padding: 4,
    // minHeight: 50,
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  container: {
    flex: 1,
    // flexBasis: 'auto',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  containerForMobileWeb: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    paddingTop: 20,
  },
  closableBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  webCloseIconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
})

export default XOverlay
