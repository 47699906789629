import React from 'react'

import { TouchableOpacity } from 'react-native'
import _ from 'lodash'
import { IXRadioProps } from 'x/types'
import XIcon from './XIcon'
import XText from './XText'
import HStack from './HStack'

class RadioIcon extends React.PureComponent<IXRadioProps<any>> {
  render() {
    const { disabled = false, checked = null, iconStyle = {} } = this.props

    return (
      <XIcon
        name={checked ? 'radio-button-on' : 'radio-button-off'}
        family='Ionicons'
        w='24px'
        h='24px'
        variant={disabled ? 'inactive' : 'primary'}
        style={iconStyle}
      />
    )

    //   return (
    //     <Icon
    //       style={{
    //         fontSize: STYLES.FONT_ICON_NORMAL,
    //         height: STYLES.FONT_ICON_NORMAL,
    //         width: STYLES.FONT_ICON_NORMAL,
    //         color: disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
    //         textAlign: 'center',
    //         ...iconStyle,
    //       }}
    //       name={checked ? 'radio-button-on' : 'radio-button-off'}
    //     />
    //   )
  }
}

export default class XRadio<T> extends React.PureComponent<IXRadioProps<T>> {
  _onPress = () => {
    const { index, data, label, checked, onPress, onPressAtIndex } = this.props
    if (_.isFunction(onPress)) {
      onPress({ index, data, label, checked })
    }
    if (_.isFunction(onPressAtIndex)) {
      onPressAtIndex(index)
    }
  }

  _renderRadio = () => {
    const { disabled = false, checked = false, ...restProps } = this.props

    return <RadioIcon {...restProps} disabled={disabled || false} checked={checked || false} />
  }

  _renderLabel = () => {
    const { disabled, checked, label, textStrikeThrough = false, renderLabel } = this.props

    if (_.isFunction(renderLabel)) {
      return renderLabel(this.props)
    }

    if (_.isString(label) && label.length > 0) {
      return (
        <XText variant={disabled || !checked ? 'inactive' : 'active'} strikeThrough={textStrikeThrough}>
          {label}
        </XText>
      )
    }

    return null
  }

  render() {
    const { disabled = false, onPress = null, onPressAtIndex = null, buttonStyle = {} } = this.props
    if (!_.isFunction(onPress) && !_.isFunction(onPressAtIndex)) {
      return this._renderRadio()
    }

    return (
      <TouchableOpacity disabled={disabled} onPress={this._onPress}>
        <HStack py='1' space='1' alignItems='center' style={buttonStyle}>
          {this._renderRadio()}
          {this._renderLabel()}
        </HStack>
      </TouchableOpacity>
    )
    // return (
    //   <TouchableOpacity
    //     style={{
    //       flex: 1,
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //       ...buttonStyle,
    //     }}
    //     disabled={disabled}
    //     onPress={this._onPress}>
    //     {this._renderRadio()}
    //   </TouchableOpacity>
    // )
  }
}
