import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dayjs from 'dayjs'

import {
  getSelectedStore,
  getSelectedStoreErpChannels,
  getSelectedStoreOrdersDates,
  getSelectedStoreOrdersDatesOptionKey,
} from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'
import {
  IOrderListFilterPreference,
  IOrderListFilterTemplate,
  IOrderListFilterViewProps,
  IOrderListFilterViewState,
  IOrdersFilter,
} from 'x/types'
import * as util from 'x/utils/util'
import * as api from 'x/utils/api'
import * as NavActions from 'x/utils/navigation'
import CONS from 'x/config/constants'

import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import OrderListFilter from 'xui/components/orders/OrderListFilter'
import OrderListFilterTemplateSelector from 'xui/components/orders/OrderListFilterTemplateSelector'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import XButton from 'xui/components/XButton'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import XOverlay from 'xui/components/XOverlay'
import XInput from 'xui/components/XInput'
import HelpButton from 'xui/components/HelpButton'
import { diff } from 'deep-object-diff'
import XIconButton from 'xui/components/XIconButton'
import XColorPalette from 'xui/components/XColorPalette'

const FILTER_COLORS = {
  black: '#000000',
  blue: '#0000ff',
  brown: '#a52a2a',
  darkblue: '#00008b',
  darkgreen: '#006400',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darkviolet: '#9400d3',
  green: '#008000',
  indigo: '#4b0082',
  maroon: '#800000',
  navy: '#000080',
  purple: '#800080',
  violet: '#800080',
}

export class OrderListFilterView<P extends IOrderListFilterViewProps, S extends IOrderListFilterViewState> extends React.Component<P, S> {
  static displayName = 'OrderListFilterView'

  inProcess?: boolean

  orderListFilterRef: React.RefObject<OrderListFilter>

  constructor(props) {
    super(props)

    // @ts-ignore
    this.state = {
      isInitialized: false,
      defaultSetting: {},
      appliedSetting: {},
      currentSetting: {},
      headerTitle: 'ตัวกรองออเดอร์',
    }

    this.orderListFilterRef = React.createRef()
  }

  componentDidMount(): void {
    this._initialize()
  }

  _initialize = async () => {
    const navPrams = util.getNavParams(this.props)
    const newState: Partial<IOrderListFilterViewState> = { ...navPrams, isInitialized: true }

    if (navPrams.appliedSetting && !_.isEmpty(navPrams.appliedSetting)) {
      newState.currentSetting = navPrams.appliedSetting

      if ('id' in navPrams.appliedSetting) {
        newState.selectedFilterTemplate = navPrams.appliedSetting
      }
    }

    await util.setStatePromise(this, newState)
  }

  getSetting = () => {
    try {
      return this.orderListFilterRef.current.getSetting()
    } catch (error) {
      console.log('getSetting error => ', error)
    }
  }

  updateNewSetting = (newSetting) => {
    try {
      this.orderListFilterRef.current.updateNewSetting(newSetting)
    } catch (error) {
      console.log('updateNewSetting error => ', error)
    }
  }

  resetToDefaultSetting = async () => {
    try {
      // console.log('resetToDefaultSetting this.orderListFilterRef => ', this.orderListFilterRef)
      await this.orderListFilterRef.current.resetToDefaultSetting()
    } catch (error) {
      console.log('resetToDefaultSetting error => ', error)
    }
  }

  resetToAppliedSetting = async () => {
    try {
      await this.orderListFilterRef.current.resetToAppliedSetting()
    } catch (error) {
      console.log('resetToAppliedSetting error => ', error)
    }
  }

  resetSelectedTemplate = async () => {
    await util.setStatePromise(this, { selectedFilterTemplate: null })
    const setting = this.getSetting()
    if ('id' in setting) {
      const newSetting = { ...setting }
      delete newSetting.id
      this.updateNewSetting(newSetting)
    }
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _isCurrentSettingNotMatchSelectedTemplate = () => {
    const { currentSetting, selectedFilterTemplate } = this.state

    if (!selectedFilterTemplate) {
      return false
    }
    // const tmplSetting = { ...selectedFilterTemplate }
    // delete tmplSetting.id
    // delete tmplSetting.name
    // delete tmplSetting.color
    // delete tmplSetting.createdAt
    // delete tmplSetting.updatedAt

    const diffSetting: Partial<IOrderListFilterTemplate> = diff(selectedFilterTemplate, currentSetting)
    // console.log('_isCurrentSettingNotMatchSelectedTemplate tmplSetting =>', selectedFilterTemplate)
    // console.log('_isCurrentSettingNotMatchSelectedTemplate currentSetting =>', currentSetting)
    // console.log('_isCurrentSettingNotMatchSelectedTemplate diffSetting =>', diffSetting)

    if (
      currentSetting.autoCancelOrderDateRangeOptionKey &&
      !diffSetting.autoCancelOrderDateRangeOptionKey &&
      currentSetting.autoCancelOrderDateRangeOptionKey !== 'Custom'
    ) {
      delete diffSetting.autoCancelOrderFrom
      delete diffSetting.autoCancelOrderTo
    }

    if (
      currentSetting.createOrderDateRangeOptionKey &&
      !diffSetting.createOrderDateRangeOptionKey &&
      currentSetting.createOrderDateRangeOptionKey !== 'Custom'
    ) {
      delete diffSetting.createOrderFrom
      delete diffSetting.createOrderTo
    }

    if (
      currentSetting.dateDeliveryDateRangeOptionKey &&
      !diffSetting.dateDeliveryDateRangeOptionKey &&
      currentSetting.dateDeliveryDateRangeOptionKey !== 'Custom'
    ) {
      delete diffSetting.dateDeliveryFrom
      delete diffSetting.dateDeliveryTo
    }

    if (
      currentSetting.paymentCompletedAtDateRangeOptionKey &&
      !diffSetting.paymentCompletedAtDateRangeOptionKey &&
      currentSetting.paymentCompletedAtDateRangeOptionKey !== 'Custom'
    ) {
      delete diffSetting.paymentCompletedAtFrom
      delete diffSetting.paymentCompletedAtTo
    }

    if (
      currentSetting.readyToShipDateRangeOptionKey &&
      !diffSetting.readyToShipDateRangeOptionKey &&
      currentSetting.readyToShipDateRangeOptionKey !== 'Custom'
    ) {
      delete diffSetting.readyToShipDateFrom
      delete diffSetting.readyToShipDateTo
    }

    if (currentSetting.shippingCompletedAtDateRangeOptionKey && currentSetting.shippingCompletedAtDateRangeOptionKey !== 'Custom') {
      delete diffSetting.shippingCompletedAtFrom
      delete diffSetting.shippingCompletedAtTo
    }

    return diffSetting && !_.isEmpty(diffSetting)
  }

  _onFilterChange = async (newSetting: IOrdersFilter) => {
    await util.setStatePromise(this, { currentSetting: newSetting })

    if (this._isCurrentSettingNotMatchSelectedTemplate()) {
      await this.resetSelectedTemplate()
    }
  }

  _onResetFilter = async () => {
    // await this.resetToDefaultSetting()
    await this._initialize()
    await this.resetToDefaultSetting()
    // await this.resetToAppliedSetting()

    const { currentSetting } = this.state

    if ('id' in currentSetting) {
      await this._onSelectFilterTemplate(currentSetting)
    } else {
      await this.resetSelectedTemplate()
    }

    const onResetFilter = util.getNavParam(this.props, 'onResetFilter')
    // const { onResetFilter } = this.props
    if (_.isFunction(onResetFilter)) {
      await onResetFilter()
    }
  }

  _onApplyFilter = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await util.setStatePromise(this, { isFilterApplyProcessing: true })

    const onApplyFilter = util.getNavParam(this.props, 'onApplyFilter')
    const preventCloseAfterApply = util.getNavParam(this.props, 'preventCloseAfterApply')

    // const { onApplyFilter, preventCloseAfterApply = false } = this.props
    let isApplied = true
    // const applySetting = this.getSetting()
    const { currentSetting: applySetting } = this.state

    if (_.isFunction(onApplyFilter)) {
      try {
        isApplied = await onApplyFilter(applySetting)
        await onApplyFilter(applySetting)
      } catch (err) {
        console.log('_onApplyFilter err => ', err)
      }
    }

    if (isApplied && !preventCloseAfterApply) {
      this._goBack()
    }

    await util.setStatePromise(this, { isFilterApplyProcessing: false })
    this.inProcess = false
  }

  _renderOrderListFilter = () => {
    const { selectedStoreOrdersDates, selectedStoreOrdersDatesOptionKey, erpChannels, updateSelectedStoreByKey } = this.props
    const { isInitialized, ...resState } = this.state

    return (
      <OrderListFilter
        {...resState}
        selectedStoreOrdersDates={selectedStoreOrdersDates}
        selectedStoreOrdersDatesOptionKey={selectedStoreOrdersDatesOptionKey}
        erpChannels={erpChannels}
        updateSelectedStoreByKey={updateSelectedStoreByKey}
        ref={this.orderListFilterRef}
        onApplyFilter={this._onApplyFilter}
        onFilterChange={this._onFilterChange}
      />
    )
  }

  _onPrefManageSaveSuccess = async (newPref: IOrderListFilterPreference, savedTemplate?: IOrderListFilterTemplate) => {
    // console.log('OrderListFilterManageView::onSaveSuccess')
    const { selectedFilterTemplate } = this.state
    const pref = this._getPref()

    let forceApply = false
    if (selectedFilterTemplate && pref && _.isArray(pref.templates)) {
      const tmplId = selectedFilterTemplate.id
      const foundIndex = pref.templates.findIndex((tmpl) => tmpl.id === tmplId)

      if (foundIndex < 0) {
        await this.resetSelectedTemplate()
        await this.resetToDefaultSetting()
        forceApply = true
      }
    }

    const onSaveSuccess = util.getNavParam(this.props, 'onSaveSuccess')
    if (_.isFunction(onSaveSuccess)) {
      await util.delay(100)
      await onSaveSuccess(newPref, savedTemplate)
    }

    if (forceApply) {
      util.navPopToCurrent(this.props)
      await util.delay(200)
      await this._onApplyFilter()
    }
  }

  // _onCreateFilterSaveSuccess = async (newPref: IOrderListFilterPreference, savedTemplate?: IOrderListFilterTemplate) => {
  //   // console.log('OrderListFilterManageView::onSaveSuccess')
  //   if (saveTemplate && saveTemplate.id) {
  //     await this._onSelectFilterTemplate(newPref, saveTemplate)
  //   }
  // }

  _navToCreateFilterTemplate = () => {
    const pref = this._getPref()
    const { templates = [] } = pref

    if (templates && templates.length >= 15) {
      p.op.alert('ไม่สามารถสร้างเทมเพลตตัวกรองออเดอร์ได้เกิน 15 รายการ', 'กรุณาลบเทมเพลตที่ไม่ได้ใช้ออกก่อน แล้วจึงสร้างใหม่')
      return
    }

    const { currentSetting } = this.state
    const { navigation } = this.props

    const newTemplate: IOrderListFilterTemplate = {
      ...currentSetting,
      id: `${dayjs().format('YYYYMMDD').toString()}x${util.generateRandomString(5)}`,
      name: '',
      createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      color: '#b79ec7',
    }

    navigation.dispatch(
      NavActions.navToOrderListFilterEditorView({
        headerTitle: 'สร้างเทมเพลตตัวกรองออเดอร์',
        store_id: util.getNavParam(this.props, 'store_id'),
        defaultSetting: newTemplate,
        appliedSetting: newTemplate,
        // onSaveSuccess: this._onCreateFilterSaveSuccess,
        onSaveSuccess: this._onPrefManageSaveSuccess,
      })
    )
  }

  _navToOrderListFilterManageView = () => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToOrderListFilterManageView({
        store_id: util.getNavParam(this.props, 'store_id'),
        onSaveSuccess: this._onPrefManageSaveSuccess,
      })
    )
  }

  _renderHeaderRight = () => (
    <HStack w='150px' alignItems='center' justifyContent='flex-end' space='2'>
      {/* <XButton w='34' h='34' variant='ghost' onPress={this._openFilterTemplateSaveModal}>
        <XIcon name='save' family='Ionicons' />
      </XButton> */}
      <XIconButton name='save' family='Ionicons' variant='ghost' onPress={this._navToCreateFilterTemplate} />
      <XIconButton name='settings' family='Ionicons' variant='ghost' onPress={this._navToOrderListFilterManageView} />
      <HelpButton
        title='เทมเพลตตัวกรองออเดอร์'
        message={
          'คุณสามารถบันทึกเทมเพลตตัวกรองโดยกดปุ่มไอคอนบันทึก ที่อยู่ในแถบด้านบนขวามือ เมื่อกดแล้วระบบจะทำการบันทึกตัวกรองปัจจุบันไว้ใช้ซ้ำได้' +
          '\n\nเมื่อทำการบันทึกแล้วกดปุ่ม "เลือกเทมเพลตตัวกรองออเดอร์" เพื่อเลือกใช้งานตัวกรองที่บันทึกไว้'
        }
      />
    </HStack>
  )

  _renderHeader = () => {
    const { headerTitle } = this.state

    return (
      <HStack
        w='full'
        px='2'
        py='1'
        minH='44px'
        alignItems='center'
        justifyContent='center'
        space='1'
        borderBottomWidth='1'
        borderBottomColor='gray.300'>
        <HStack w='46px' alignItems='center'>
          <XIconButton name='arrow-back' onPress={this._goBack} />
        </HStack>
        <HStack flex={1} alignItems='center'>
          <XText w='full' variant='active' bold>
            {headerTitle}
          </XText>
        </HStack>
        {this._renderHeaderRight()}
      </HStack>
    )

    // return (
    //   <XCustomHeader
    //     headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
    //     title={headerTitle}
    //     // headerRightProps={{ settingIcon: true, onPressItem: this._navToOrderListFilterManageView }}
    //     headerRight={this._renderHeaderRight()}
    //   />
    // )
  }

  _renderOrderTemplateBar = () => {
    const { navigation } = this.props
    const { selectedFilterTemplate } = this.state

    return (
      <VStack w='full' p='1' space='1' bg='white'>
        <OrderListFilterTemplateSelector
          type='button'
          navigation={navigation}
          selectedTemplate={selectedFilterTemplate}
          onSelect={this._onSelectFilterTemplate}
          onSaveSuccess={this._onPrefManageSaveSuccess}
        />
      </VStack>
    )
  }

  // _renderOrderTemplateBar = () => {
  //   const { selectedFilterTemplate } = this.state

  //   return (
  //     <VStack w='full' p='1' space='1' bg='white'>
  //       <XCard>
  //         <TouchableOpacity onPress={this._openFilterTemplateSelectorModal}>
  //           <HStack
  //             // flex={1}
  //             w='full'
  //             px='2'
  //             py='1'
  //             space='2'
  //             alignItems='center'
  //             borderWidth='1'
  //             borderStyle={selectedFilterTemplate ? 'solid' : 'dashed'}
  //             // borderColor={selectedFilterTemplate ? 'primary.500' : 'gray.400'}
  //             borderColor={selectedFilterTemplate ? selectedFilterTemplate.color : 'gray.400'}
  //             // borderColor='gray.400'
  //             borderRadius='lg'>
  //             {/* {selectedFilterTemplate && selectedFilterTemplate.color && (
  //               <Box w='20px' h='20px' borderRadius='full' bg={selectedFilterTemplate.color} />
  //             )} */}

  //             {selectedFilterTemplate && selectedFilterTemplate.color && (
  //               <Box w='20px' h='20px' borderRadius='full' bg={selectedFilterTemplate.color} />
  //             )}

  //             {selectedFilterTemplate && selectedFilterTemplate.name ? (
  //               <XText flex={1} color={selectedFilterTemplate.color} numberOfLines={3}>
  //                 {selectedFilterTemplate.name}
  //               </XText>
  //             ) : (
  //               <XText variant='primary' numberOfLines={1}>
  //                 เลือกเทมเพลตตัวกรองออเดอร์
  //               </XText>
  //             )}
  //           </HStack>
  //         </TouchableOpacity>
  //       </XCard>
  //     </VStack>
  //   )

  //   return (
  //     <VStack w='full' p='1' space='1' bg='white'>
  //       <XCard>
  //         <HStack w='full' p='2'>
  //           <HStack w='200px' alignItems='center'>
  //             <XText>เทมเพลตตัวกรอง</XText>
  //             {/* <XText variant='inactive'>เป็นเทมเพลตที่บันทึกไว้ใช้ซ้ำ</XText> */}
  //             <HelpButton
  //               title='เทมเพลตตัวกรอง'
  //               message={
  //                 'คุณสามารถบันทึกเทมเพลตตัวกรองโดยกดปุ่มไอคอนบันทึก ที่อยู่ในแถบด้านล่างซ้ายมือ เมื่อกดแล้วระบบจะทำการบันทึกตัวกรองปัจจุบันไว้ใช้ซ้ำได้' +
  //                 '\n\nเมื่อทำการบันทึกแล้วกดปุ่ม "เลือกเทมเพลต" เพื่อเลือกใช้งานตัวกรองที่บันทึกไว้'
  //               }
  //             />
  //           </HStack>

  //           <HStack flex={1} alignItems='center' justifyContent='flex-end'>
  //             <TouchableOpacity onPress={this._openFilterTemplateSelectorModal}>
  //               <HStack
  //                 px='2'
  //                 py='1'
  //                 space='1'
  //                 alignItems='center'
  //                 borderWidth='1'
  //                 borderStyle={selectedFilterTemplate ? 'solid' : 'dashed'}
  //                 borderColor={selectedFilterTemplate ? 'primary.500' : 'gray.400'}
  //                 // borderColor='gray.400'
  //                 borderRadius='lg'>
  //                 {selectedFilterTemplate && selectedFilterTemplate.color && (
  //                   <Box w='20px' h='20px' borderRadius='full' bg={selectedFilterTemplate.color} />
  //                 )}
  //                 {selectedFilterTemplate && selectedFilterTemplate.name ? (
  //                   <XText variant='primary' numberOfLines={1}>
  //                     {selectedFilterTemplate.name}
  //                   </XText>
  //                 ) : (
  //                   <XText variant='primary' numberOfLines={1}>
  //                     เลือกเทมเพลต
  //                   </XText>
  //                 )}
  //               </HStack>
  //             </TouchableOpacity>
  //           </HStack>
  //         </HStack>
  //       </XCard>
  //     </VStack>
  //   )
  // }

  // _openFilterTemplateSelectorModal = () => {
  //   const pref = this._getPref()
  //   const { templates } = pref
  //   if (!templates || templates.length === 0) {
  //     p.op.alert('ยังไม่มีเทมเพลตตัวกรองออเดอร์', 'กรุณาปรับตัวกรอง และกดเพิ่มเทมเพลตตัวกรองออเดอร์ จากปุ่มบันทึกในแถบด้านบนด้านขวามือ')
  //     return
  //   }

  //   this.setState({ isFilterTemplateSelectorModalVisible: true })
  // }

  // _closeFilterTemplateSelectorModal = () => {
  //   this.setState({ isFilterTemplateSelectorModalVisible: false })
  // }

  // _getNormalizeFilterTemplate = (tmpl: IOrderListFilterTemplate) => {
  //   const nTmpl = { ...tmpl }

  //   if (nTmpl.autoCancelOrderDateRangeOptionKey) {
  //     const dr = util.getDateRangeFromOption(nTmpl.autoCancelOrderDateRangeOptionKey)
  //     nTmpl.autoCancelOrderFrom = dr.begin
  //     nTmpl.autoCancelOrderTo = dr.end
  //   }

  //   if (nTmpl.createOrderDateRangeOptionKey) {
  //     const dr = util.getDateRangeFromOption(nTmpl.createOrderDateRangeOptionKey)
  //     nTmpl.createOrderFrom = dr.begin
  //     nTmpl.createOrderTo = dr.end
  //   }

  //   if (nTmpl.dateDeliveryDateRangeOptionKey) {
  //     const dr = util.getDateRangeFromOption(nTmpl.dateDeliveryDateRangeOptionKey)
  //     nTmpl.dateDeliveryFrom = dr.begin
  //     nTmpl.dateDeliveryTo = dr.end
  //   }

  //   if (nTmpl.paymentCompletedAtDateRangeOptionKey) {
  //     const dr = util.getDateRangeFromOption(nTmpl.paymentCompletedAtDateRangeOptionKey)
  //     nTmpl.paymentCompletedAtFrom = dr.begin
  //     nTmpl.paymentCompletedAtTo = dr.end
  //   }

  //   if (nTmpl.printedAtDateRangeOptionKey) {
  //     const dr = util.getDateRangeFromOption(nTmpl.printedAtDateRangeOptionKey)
  //     nTmpl.printedAtFrom = dr.begin
  //     nTmpl.printedAtTo = dr.end
  //   }

  //   if (nTmpl.readyToShipDateRangeOptionKey) {
  //     const dr = util.getDateRangeFromOption(nTmpl.readyToShipDateRangeOptionKey)
  //     nTmpl.readyToShipDateFrom = dr.begin
  //     nTmpl.readyToShipDateTo = dr.end
  //   }

  //   if (nTmpl.mkpChannelIds && nTmpl.mkpChannelIds.length > 0) {
  //     const removeMkpIds = []
  //     for (let i = 0; i < nTmpl.mkpChannelIds.length; i++) {
  //       const mkpId = nTmpl.mkpChannelIds[i]
  //       const isExist = util.isMkpChannelExist(mkpId)
  //       if (isExist) {
  //         removeMkpIds.push(mkpId)
  //       }
  //     }

  //     if (removeMkpIds.length > 0) {
  //       nTmpl.mkpChannelIds = nTmpl.mkpChannelIds.filter((id) => !removeMkpIds.includes(id))
  //     }
  //   }

  //   return nTmpl
  // }

  _onSelectFilterTemplate = async (nTmpl?: IOrderListFilterTemplate) => {
    if (_.isNil(nTmpl)) {
      await this.resetToDefaultSetting()
      await this.resetSelectedTemplate()
      p.op.showToast('เลิกใช้งานนเทมเพลตตัวกรองออเดอร์', 'success')
      return
    }

    await util.setStatePromise(this, { selectedFilterTemplate: nTmpl })
    this.updateNewSetting(nTmpl)
    p.op.showToast('ใช้งานเทมเพลตตัวกรองออเดอร์แล้ว', 'success')
  }

  // _onSelectFilterTemplate = async (tmpl: IOrderListFilterTemplate, isSelected?: boolean) => {
  //   if (isSelected) {
  //     await this.resetToDefaultSetting()
  //     await this.resetSelectedTemplate()
  //     p.op.showToast('เลิกใช้งานนเทมเพลตตัวกรองออเดอร์', 'success')
  //   } else {
  //     const nTmpl = this._getNormalizeFilterTemplate(tmpl)
  //     await util.setStatePromise(this, { selectedFilterTemplate: nTmpl })
  //     await this.updateNewSetting(nTmpl)
  //     p.op.showToast('ใช้งานเทมเพลตตัวกรองออเดอร์แล้ว', 'success')
  //   }

  //   this._closeFilterTemplateSelectorModal()
  // }

  // _renderFilterTemplateItem = (tmpl: IOrderListFilterTemplate, index: number) => {
  //   const { selectedFilterTemplate } = this.state
  //   const isSelected = selectedFilterTemplate && selectedFilterTemplate.id === tmpl.id

  //   return (
  //     <TouchableOpacity key={`filter-tmpl-${index}`} onPress={() => this._onSelectFilterTemplate(tmpl, isSelected)}>
  //       <HStack
  //         w='full'
  //         minH='34px'
  //         p='2'
  //         space='2'
  //         bg={isSelected ? 'primary.50' : 'white'}
  //         borderWidth='1'
  //         borderColor={tmpl && tmpl.color ? tmpl.color : 'gray.400'}
  //         // borderTopColor={isSelected ? 'primary.500' : 'gray.300'}
  //         // borderLeftColor={isSelected ? 'primary.500' : 'gray.300'}
  //         // borderBottomColor={isSelected ? 'primary.300' : 'gray.400'}
  //         // borderRightColor={isSelected ? 'primary.400' : 'gray.400'}
  //         borderRadius='lg'
  //         alignItems='center'>
  //         {isSelected ? (
  //           <XIcon
  //             name='checkmark-circle'
  //             family='Ionicons'
  //             // name={isSelected ? 'checkmark-circle' : 'circle-o'}
  //             // family={isSelected ? 'Ionicons' : 'FontAwesome'}
  //             // iconVariant={isSelected ? 'success' : 'inactive'}
  //           />
  //         ) : null}

  //         <Box w='20px' h='20px' borderRadius='full' bg={tmpl.color} />

  //         <HStack flex={1}>
  //           <XText>{tmpl.name}</XText>
  //         </HStack>

  //         {/* {!isSelected && <ForwardIcon />} */}
  //         {/* {isSelected && <XText>กำลังใช้งาน</XText>} */}
  //       </HStack>
  //     </TouchableOpacity>
  //   )
  // }

  // _renderFilterTemplateItems = () => {
  //   // const templates = [
  //   //   {
  //   //     id: '00001',
  //   //     name: 'กรอง 00001',
  //   //     color: FILTER_COLORS.blue,
  //   //     createdAt: '2022-11-01 00:00:00',
  //   //     updatedAt: '2022-11-01 00:00:00',
  //   //   },
  //   //   {
  //   //     id: '00002',
  //   //     name: 'กรอง 00002',
  //   //     color: FILTER_COLORS.brown,
  //   //     createdAt: '2022-11-01 00:00:00',
  //   //     updatedAt: '2022-11-01 00:00:00',
  //   //   },
  //   //   {
  //   //     id: '00003',
  //   //     name: 'กรอง 00003',
  //   //     color: FILTER_COLORS.darkgreen,
  //   //     createdAt: '2022-11-01 00:00:00',
  //   //     updatedAt: '2022-11-01 00:00:00',
  //   //   },
  //   //   {
  //   //     id: '00004',
  //   //     name: 'กรอง 00004',
  //   //     color: FILTER_COLORS.darkorchid,
  //   //     createdAt: '2022-11-01 00:00:00',
  //   //     updatedAt: '2022-11-01 00:00:00',
  //   //   },
  //   //   {
  //   //     id: '00005',
  //   //     name: 'กรอง 00005',
  //   //     color: FILTER_COLORS.maroon,
  //   //     createdAt: '2022-11-01 00:00:00',
  //   //     updatedAt: '2022-11-01 00:00:00',
  //   //   },
  //   // ]

  //   const pref = this._getPref()
  //   const { templates } = pref

  //   return templates.map(this._renderFilterTemplateItem)
  // }

  // _renderFilterTemplateSelectorModal = () => {
  //   const { isFilterTemplateSelectorModalVisible } = this.state

  //   if (!isFilterTemplateSelectorModalVisible) {
  //     return null
  //   }

  //   return (
  //     <XOverlay visible onRequestClose={this._closeFilterTemplateSelectorModal}>
  //       <XCustomHeader
  //         title='เลือกเทมเพลตตัวกรองออเดอร์'
  //         headerRightProps={{ closeIcon: true, onPressItem: this._closeFilterTemplateSelectorModal }}
  //       />
  //       <XContent flex={1} minH='350px' maxH='800px'>
  //         <VStack w='full' p='2' space='1.5'>
  //           {this._renderFilterTemplateItems()}
  //         </VStack>
  //       </XContent>
  //     </XOverlay>
  //   )
  // }

  _openFilterTemplateSaveModal = () => {
    this.setState({ isFilterTemplateSaveModalVisible: true, saveFilterTemplateText: '' })
  }

  _closeFilterTemplateSaveModal = () => {
    this.setState({ isFilterTemplateSaveModalVisible: false })
  }

  _onSaveFilterTemplateChangeText = (newText: string) => {
    this.setState({ saveFilterTemplateText: newText })
  }

  _onsaveFilterTemplateColorChange = (newText: string) => {
    this.setState({ saveFilterTemplateColor: newText })
  }

  _getPref = (): IOrderListFilterPreference => util.getOrderListFilterPreferance()

  _onSaveNewTemplate = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await util.setStatePromise(this, { isSubmitting: true })

    const { saveFilterTemplateText, saveFilterTemplateColor } = this.state
    if (!saveFilterTemplateText) {
      p.op.alert('กรุณาระบุชื่อเทมเพลตตัวกรอง')
      await util.setStatePromise(this, { isSubmitting: false })
      this.inProcess = false
      return
    }

    const newTemplate: IOrderListFilterTemplate = {
      ...this.getSetting(),
      id: `${dayjs().format('YYYYMMDD').toString()}x${util.generateRandomString(5)}`,
      name: saveFilterTemplateText,
      createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      color: saveFilterTemplateColor && saveFilterTemplateColor.length > 0 ? saveFilterTemplateColor : '#b79ec7',
    }

    if (newTemplate.autoCancelOrderDateRangeOptionKey === 'Custom') {
      delete newTemplate.autoCancelOrderDateRangeOptionKey
    }
    delete newTemplate.autoCancelOrderFrom
    delete newTemplate.autoCancelOrderTo

    if (newTemplate.createOrderDateRangeOptionKey === 'Custom') {
      delete newTemplate.createOrderDateRangeOptionKey
    }
    delete newTemplate.createOrderFrom
    delete newTemplate.createOrderTo

    if (newTemplate.dateDeliveryDateRangeOptionKey === 'Custom') {
      delete newTemplate.dateDeliveryDateRangeOptionKey
    }
    delete newTemplate.dateDeliveryFrom
    delete newTemplate.dateDeliveryTo

    if (newTemplate.paymentCompletedAtDateRangeOptionKey === 'Custom') {
      delete newTemplate.paymentCompletedAtDateRangeOptionKey
    }
    delete newTemplate.paymentCompletedAtFrom
    delete newTemplate.paymentCompletedAtTo

    if (newTemplate.printedAtDateRangeOptionKey === 'Custom') {
      delete newTemplate.printedAtDateRangeOptionKey
    }
    delete newTemplate.printedAtFrom
    delete newTemplate.printedAtTo

    if (newTemplate.readyToShipDateRangeOptionKey === 'Custom') {
      delete newTemplate.readyToShipDateRangeOptionKey
    }
    delete newTemplate.readyToShipDateFrom
    delete newTemplate.readyToShipDateTo

    const pref = this._getPref()
    const newPref = _.cloneDeep(pref)
    newPref.templates.push(newTemplate)
    newPref.updatedAt = dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString()

    const store_id = util.getNavParam(this.props, 'store_id')

    const isSuccess = await api.postStoreUser({ store_id, pref_ui_order_list_filter: newPref })

    if (isSuccess) {
      const { updateSelectedStoreByKey } = this.props
      updateSelectedStoreByKey('pref_ui_order_list_filter', newPref)
      // const newTemplateIndex = newPref.templates.length - 1
      await this._onSelectFilterTemplate(newTemplate)
      p.op.showToast('บันทึกเทมเพลตตัวกรองออเดอร์เรียบร้อยแล้ว', 'success')
    } else {
      p.op.showToast('บันทึกเทมเพลตตัวกรองออเดอร์ไม่สําเร็จ', 'danger')
    }

    await util.setStatePromise(this, { isSubmitting: false })
    this.inProcess = false
    this._closeFilterTemplateSaveModal()
  }

  _renderFilterTemplateSaveModal = () => {
    const { isFilterTemplateSaveModalVisible, saveFilterTemplateText = '', saveFilterTemplateColor } = this.state

    if (!isFilterTemplateSaveModalVisible) {
      return null
    }

    return (
      <XOverlay visible onRequestClose={this._closeFilterTemplateSaveModal}>
        <XCustomHeader
          title='บันทึกเทมเพลตตัวกรองออเดอร์'
          headerRightProps={{ closeIcon: true, onPressItem: this._closeFilterTemplateSaveModal }}
        />

        <VStack w='full' p='2' space='1.5' bg='white'>
          <VStack w='full' space='0.5'>
            <XText variant='inactive'>ชื่อเทมเพลตตัวกรองออเดอร์</XText>

            <XInput
              // flex={1}
              // w='280px'
              w='full'
              maxWidth='380px'
              placeholder='เช่น ตัวกรอง 7 วันที่ยังไม่ได้พิมพ์ใบปะหน้า'
              value={saveFilterTemplateText}
              onChangeText={this._onSaveFilterTemplateChangeText}
            />
          </VStack>
          <VStack w='full' space='0.5'>
            <XText variant='inactive'>สีเทมเพลตตัวกรองออเดอร์</XText>
            <XColorPalette value={saveFilterTemplateColor} onChange={this._onsaveFilterTemplateColorChange} />
          </VStack>
        </VStack>

        <HStack w='full' alignItems='center' justifyContent='center' p='2'>
          <XButton w='100px' onPress={this._onSaveNewTemplate}>
            บันทึก
          </XButton>
        </HStack>
      </XOverlay>
    )
  }

  _renderContent = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    return (
      <XContent>
        <VStack w='full' p='0.5' space='1' bg='white'>
          {this._renderOrderTemplateBar()}
          {this._renderOrderListFilter()}
        </VStack>
      </XContent>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        {this._renderContent()}

        <HStack h={p.op.isWeb() ? '12' : undefined} minH='12' w='full' p='2' space='2' borderTopWidth='1' borderTopColor='gray.400'>
          {/* <XButton w='60px' h='9' variant='outline' onPress={this._openFilterTemplateSaveModal}>
            <XIcon name='save' family='Ionicons' />
          </XButton> */}
          <XButton w='100px' h='9' variant='outline' onPress={this._onResetFilter}>
            ล้าง
          </XButton>
          <XButton flex={1} h='9' onPress={this._onApplyFilter}>
            กรอง
          </XButton>
        </HStack>

        {/* {this._renderFilterTemplateSelectorModal()} */}
        {this._renderFilterTemplateSaveModal()}
      </XContainer>
    )
  }
}

// export default connect(
//   (state) => ({
//     selectedStore: getSelectedStore(state),
//   }),
//   (dispatch) => ({
//     dispatch,
//     updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
//   })
//   // @ts-ignore
// )(OrderListFilterView)

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),

    selectedStoreOrdersDates: getSelectedStoreOrdersDates(state),
    selectedStoreOrdersDatesOptionKey: getSelectedStoreOrdersDatesOptionKey(state),
    erpChannels: getSelectedStoreErpChannels(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(OrderListFilterView)
