import React from 'react'
// import { COLORS, STYLES } from 'x/config/styles'
// import { Text, View } from 'react-native'
import { IMKPChannelDetail } from 'x/index'
import _ from 'lodash'
import * as util from 'x/utils/util'
import VStack from './VStack'
import HStack from './HStack'
import Box from './Box'
import XCheckableItem from './XCheckableItem'
import MkpLogo from './MkpLogo'

interface IMKPChannelsActive {
  channels: IMKPChannelDetail[]
  onPressChannel: (channel: IMKPChannelDetail[]) => void
}

const MKPChannelsActive: React.FC<IMKPChannelsActive> = ({ channels, onPressChannel }) => {
  if (!channels || (_.isArray(channels) && channels.length === 0)) {
    return null
  }

  // const [newChannels, setChannels] = useState(channels)

  // useEffect(() => {
  //   setChannels(channels)
  // }, [channels])
  const hasMKPChannel = util.isUseMKPChannels()
  if (!hasMKPChannel) {
    return null
  }

  const activeCount = channels.reduce((prevActiveCount, ch) => (ch.is_active ? prevActiveCount + 1 : prevActiveCount), 0)
  const isOnlyOneChannelSelected = activeCount === 1

  const onPickChannel = (index: number) => {
    const newChannesls = [...channels] // clone array

    if (newChannesls[index].is_active) {
      delete newChannesls[index].is_active
    } else {
      newChannesls[index].is_active = true
    }
    onPressChannel(newChannesls)
  }

  const renderCheckButton = (channel: IMKPChannelDetail, idx: number) => {
    // const { key, value, label } = option
    const isActiveCh = channel.is_active || false

    return (
      <XCheckableItem
        key={`key_${idx}`}
        disabled={isActiveCh && isOnlyOneChannelSelected}
        checked={isActiveCh}
        label={channel.name}
        onPress={() => onPickChannel(idx)}
        icon={<MkpLogo mkpId={channel.mkp_id} width={24} height={24} />}
      />
    )

    // return (
    //   <XCheckButton
    //     key={`key_${idx}`}
    //     disabled={isActiveCh && isOnlyOneChannelSelected}
    //     buttonStyle={{ marginTop: 2, marginBottom: 2, marginRight: 8, maxWidth: 280 }}
    //     checked={channel.is_active}
    //     // name={channel.name}
    //     label={channel.name}
    //     // data={channel.isActive}
    //     onPress={() => onPickChannel(idx)}
    //     mkp_id={channel.mkp_id}
    //   />
    // )
  }

  // console.log('newChannels => !! ', newChannels)
  // const onPickChannel = (channel: IMKPChannelDetail) => {
  //   const newChannesls = []
  //   newChannels.map((ch: IMKPChannelDetail) => {
  //     const newChannel = ch
  //     if (channel.id === ch.id) {
  //       if (ch.isActive) {
  //         newChannel.isActive = false
  //       } else {
  //         newChannel.isActive = true
  //       }
  //     }
  //     newChannesls.push(newChannel)
  //   })
  //   let channelsIsActive = []
  //   newChannesls.map((ch: IMKPChannelDetail) => {
  //     if (ch.isActive) {
  //       channelsIsActive.push(ch)
  //     }
  //   })
  //   if (channelsIsActive.length < 1) {
  //     channelsIsActive = null
  //   }
  //   onPressChannel(channelsIsActive)
  //   setChannels(newChannesls)
  // }

  // const renderCheckButton = (channel: IMKPChannelDetail, index: number) => {
  //   // const { key, value, label } = option
  //   return (
  //     <XCheckButton
  //       key={`key_${index}`}
  //       disabled={false}
  //       buttonStyle={{ marginTop: 2, marginBottom: 2, marginRight: 8 }}
  //       checked={channel.isActive}
  //       // name={channel.name}
  //       label={channel.name}
  //       // data={channel.isActive}
  //       onPress={() => onPickChannel(channel)}
  //       mkp_id={channel.mkp_id}
  //     />
  //   )
  // }

  // let channelsActive = null
  // if (!_.isNil(newChannels)) {
  //   channelsActive = fromJS(newChannels).filter(util.filterMkpChannelMapIsActive)
  // }
  // const hasActiveLinked = !_.isNil(channelsActive) && channelsActive.size > 0
  // if (hasActiveLinked) {
  //   channelsActive = channelsActive.toJS()
  // }

  return (
    <VStack w='full' space='1'>
      {/* <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText> */}
      <HStack w='full' space='1' flexWrap='wrap'>
        {/* {hasActiveLinked ? (
          channelsActive.map((channel: IMKPChannelDetail, index: number) => {
            return <View key={index.toString()}>{renderCheckButton(channel, index)}</View>
          })
        ) : (
          <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingLeft: 4 }}>{'ไม่พบช่องทางขาย'}</XText>
        )} */}
        {channels.map((channel: IMKPChannelDetail, index: number) => (
          <Box key={index.toString()}>{renderCheckButton(channel, index)}</Box>
        ))}
      </HStack>
    </VStack>
  )
}

export default MKPChannelsActive
