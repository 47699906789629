import React from 'react'
// import { Drawer } from 'native-base'
import { TouchableOpacity, View } from 'react-native'
import _ from 'lodash'
import { diff } from 'deep-object-diff'

import { COLORS } from 'x/config/styles'
import {
  ICategory,
  IMKPChannelDetail,
  IProductListFilterViewProps,
  IProductListFilterViewState,
  IProductListFilterState,
  IXDateRangeSelectorChangeParams,
  categoryByStore,
  IProductListFilterTemplate,
  IProductListSortType,
  IAddStoreProductSelectorOptions,
  IProductListSortBy,
  IProductVariantListSortBy,
  IXSegmentOption,
} from 'x/index'

// import NavHeaderButton from './NavHeaderButton'
// import XCheckbox from './XCheckbox'
// import XCheckButton from './XCheckButton'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import * as acl from 'x/utils/acl'
import HStack from 'xui/components/HStack'
import XContainer from 'xui/components/XContainer'
import Icon from 'xui/components/Icon'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import XDateRangeSelectorButton from 'xui/components/XDateRangeSelectorButton'
import Segment from 'xui/components/Segment'
import MKPChannelsActive from 'xui/components/MKPChannelsActive'
import XImageIcon from 'xui/components/XImageIcon'
import MkpLogo from 'xui/components/MkpLogo'
import XContent from 'xui/components/XContent'
import CollapsableCard from 'xui/components/CollapsableCard'
import XCheckableItem from 'xui/components/XCheckableItem'
import XCheckbox from 'xui/components/XCheckbox'
import XInput from 'xui/components/XInput'
import XIconButton from 'xui/components/XIconButton'
import XSegment from 'xui/components/XSegment'
import AddStoreProductSelector from 'xui/components/order/AddStoreProductSelector'
import { List } from 'immutable'
import ProductCategorySelector from 'xui/components/product/ProductCategorySelector'
import HelpButton from 'xui/components/HelpButton'
import dayjs from 'dayjs'
import XDateTimeSinglePicker from 'xui/components/XDateTimeSinglePicker'
import XRadio from 'xui/components/XRadio'

const logoMkpChannel = require('xui/img/about/icon_mkp_channel.png')

const {
  STOCK_ALMOST_EMPTY,
  STOCK_EMPTY,
  STOCK_DEAD_STOCK,
  STOCK_CUSTOM,
  STOCK_EXCEEDED_QTY,
  STOCK_HAVE_TO_SHIP,
  STOCK_NOT_HAVE_TO_SHIP,
} = CONS.PRODUCT_LIST_FILTER_STOCK_KEYS
const { COST_FILTER_EQUAL, COST_FILTER_CUSTOM } = CONS.PRODUCT_LIST_FILTER_COST_KEYS
// const STOCK_ALMOST_EMPTY = 'almost_empty'
// const STOCK_EMPTY = 'empty'
// const STOCK_DEAD_STOCK = 'dead_stock'
// const STOCK_CUSTOM = 'custom'
// const STOCK_EXCEEDED_QTY = 'exceeded_qty'
// const STOCK_HAVE_TO_SHIP = 'have_to_ship'
// const STOCK_NOT_HAVE_TO_SHIP = 'not_have_to_ship'

// const STOCK_QTY_OPTIONS = [
//   { key: STOCK_ALMOST_EMPTY, label: 'ใกล้หมด' },
//   { key: STOCK_EMPTY, label: 'หมดแล้ว' },
//   { key: STOCK_DEAD_STOCK, label: 'ค้างสต๊อก' },
//   { key: STOCK_CUSTOM, label: 'ระบุช่วง' },
// ]

// const STOCK_AVAILABLE_QTY_OPTIONS = [
//   { key: STOCK_ALMOST_EMPTY, label: 'ใกล้หมด' },
//   { key: STOCK_EMPTY, label: 'หมดแล้ว' },
//   { key: STOCK_DEAD_STOCK, label: 'ค้างสต๊อก' },
//   { key: STOCK_EXCEEDED_QTY, label: 'ขายเกินสต๊อกคงเหลือ' },
//   { key: STOCK_CUSTOM, label: 'ระบุช่วง' },
// ]

// const STOCK_QTY_TO_SHIP_OPTIONS = [
//   { key: STOCK_HAVE_TO_SHIP, label: 'มีรอส่ง' },
//   { key: STOCK_NOT_HAVE_TO_SHIP, label: 'ไม่มีรอส่ง' },
//   { key: STOCK_CUSTOM, label: 'ระบุช่วง' },
// ]

// const COST_FILTER_EQUAL = 'equal'
// const COST_FILTER_CUSTOM = 'custom'

// const COST_FILTER_OPTIONS = [
//   { key: COST_FILTER_EQUAL, label: 'เท่ากับ' },
//   { key: COST_FILTER_CUSTOM, label: 'ระบุช่วง' },
// ]

class BaseUIProductListFilterView<P extends IProductListFilterViewProps, S extends IProductListFilterViewState> extends React.Component<
  P,
  S
> {
  static displayName = 'BaseUIProductListFilterView'

  // static defaultProps: Partial<IProductListFilterViewProps> = {
  //   defaultSetting: {},
  //   currentSetting: {},
  // }

  // loadProps: boolean

  inProcess?: boolean

  constructor(props: P) {
    super(props)
    // this.drawerRef = React.createRef()

    // @ts-ignore
    this.state = {
      isInitialized: false,
      // allChildren: true,
      categoryList: null,
      // levelCategoryList: null,
      // selectedCategoryId: null,

      isModalOpen: false,
      channels: util.getMKPChannels(),
      // mkpChIds: [],

      hasCreatedByMkpChannels: util.getMKPChannels(),
      // hasCreatedByMkpChIds: [],

      // mkpChannelActiveMap: {},
      // mkpChannelNotInActiveMap: {},

      defaultSetting: {},
      currentSetting: {},
      appliedSetting: {},
    }

    // this.loadProps = true
    // this.drawerCatRef = React.createRef()
  }

  async componentDidMount(): Promise<void> {
    await this._setupNavParamsToState()
    await this._setupDateRangeFilter()
    await this._setupFilter()
    await this._setupCategoryList()
    await this._setupExtended()
    await util.setStatePromise(this, { isInitialized: true })
  }

  _setupExtended = async () => {
    // do nothing
  }

  isHiddenSearchFilterCard = () => false

  isHiddenSortFilterCard = () => false

  isHiddenStockFilterCard = () => false

  isHiddenCostFilterCard = () => false

  isHiddenDatetimeFilterCard = () => false

  isHiddenMkpFilterCard = () => false

  isHiddenCategoryFilterCard = () => false

  isHiddenPriceFilterCard = () => false

  _isPermProductQtyViewable = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_VIEW)

  _isPermProductCostViewable = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_COST)

  _isPermProductPriceViewable = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_PRICE)

  // _setupCategoryList = async () => {
  //   const storeId = util.getNavParam(this.props, 'store_id')
  //   const { categoryList } = this.props
  //   const catGroupList = categoryList.toJS()

  //   const foundIndex = catGroupList.findIndex((catG: categoryByStore) => catG.store_id === storeId)
  //   if (foundIndex >= 0) {
  //     const catGroup = catGroupList[foundIndex]
  //     await util.setStatePromise(this, { categoryList: catGroup.category })
  //   }
  // }

  _setupCategoryList = async () => {
    const { categoryList = List([]) } = this.props
    const { currentSetting } = this.state
    const catGroupList = categoryList.toJS()

    let storeId = util.getNavParam(this.props, 'store_id')
    if (currentSetting && currentSetting.sellerStoreId) {
      storeId = currentSetting.sellerStoreId
    }

    const foundIndex = catGroupList.findIndex((catG: categoryByStore) => catG.store_id === storeId)
    if (foundIndex >= 0) {
      const catGroup = catGroupList[foundIndex]
      await util.setStatePromise(this, { categoryList: util.appendNotSpecifiedProductCategoryIfNotExist(catGroup.category) })
      return
    }

    const { requestCategoryList } = this.props

    const res: ICategory[] = await new Promise((resolve) => {
      requestCategoryList({
        body: {
          store_id: storeId,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })

    // console.log('_setupCategoryList res => ', res)

    if (!_.isNil(res) && _.isArray(res)) {
      await util.setStatePromise(this, { categoryList: util.appendNotSpecifiedProductCategoryIfNotExist(res) })
    }
  }

  _setupNavParamsToState = async () => {
    const defaultSetting = util.getNavParam(this.props, 'defaultSetting', {}) as IProductListFilterState
    const appliedSetting = util.getNavParam(this.props, 'appliedSetting', {}) as IProductListFilterState
    defaultSetting.isProductTemplateFilter = false
    appliedSetting.isProductTemplateFilter = false
    await util.setStatePromise(this, { defaultSetting, currentSetting: appliedSetting, appliedSetting })
  }

  _setupDateRangeFilter = async () => {
    const { defaultSetting, currentSetting, appliedSetting } = this.state

    if (defaultSetting && _.isString(defaultSetting.createdAtRangeOptionKey) && defaultSetting.createdAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(defaultSetting.createdAtRangeOptionKey)
      defaultSetting.createdAtFrom = dr.begin
      defaultSetting.createdAtTo = dr.end
    }

    if (defaultSetting && _.isString(defaultSetting.updatedAtRangeOptionKey) && defaultSetting.updatedAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(defaultSetting.updatedAtRangeOptionKey)
      defaultSetting.updatedAtFrom = dr.begin
      defaultSetting.updatedAtTo = dr.end
    }

    if (currentSetting && _.isString(currentSetting.createdAtRangeOptionKey) && currentSetting.createdAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(currentSetting.createdAtRangeOptionKey)
      currentSetting.createdAtFrom = dr.begin
      currentSetting.createdAtTo = dr.end
    }

    if (currentSetting && _.isString(currentSetting.updatedAtRangeOptionKey) && currentSetting.updatedAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(currentSetting.updatedAtRangeOptionKey)
      currentSetting.updatedAtFrom = dr.begin
      currentSetting.updatedAtTo = dr.end
    }

    if (appliedSetting && _.isString(appliedSetting.createdAtRangeOptionKey) && appliedSetting.createdAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(appliedSetting.createdAtRangeOptionKey)
      appliedSetting.createdAtFrom = dr.begin
      appliedSetting.createdAtTo = dr.end
    }

    if (appliedSetting && _.isString(appliedSetting.updatedAtRangeOptionKey) && appliedSetting.updatedAtRangeOptionKey !== 'Custom') {
      const dr = util.getDateRangeFromOption(appliedSetting.updatedAtRangeOptionKey)
      appliedSetting.updatedAtFrom = dr.begin
      appliedSetting.updatedAtTo = dr.end
    }

    await util.setStatePromise(this, { currentSetting, defaultSetting, appliedSetting })
  }

  _setupFilter = async () => {
    const { defaultSetting, currentSetting } = this.state
    // console.log('_setupFilter defaultSetting => ', defaultSetting)
    // console.log('_setupFilter currentSetting => ', currentSetting)

    const newCurrentSetting = _.cloneDeep(currentSetting)

    const isCostPermissionGranted = this._isPermProductCostViewable()
    if (!isCostPermissionGranted && newCurrentSetting.isCostShow) {
      newCurrentSetting.isCostShow = false
    }

    if (!isCostPermissionGranted && newCurrentSetting.costFilterEnabled) {
      newCurrentSetting.costFilterEnabled = false
    }

    const isPricePermissionGranted = this._isPermProductPriceViewable()
    if (!isPricePermissionGranted && newCurrentSetting.isPriceShow) {
      newCurrentSetting.isPriceShow = false
    }

    const isQtyPermissionGranted = this._isPermProductQtyViewable()

    if (!isQtyPermissionGranted && newCurrentSetting.stockQtyEnabled) {
      newCurrentSetting.stockQtyEnabled = false
    }

    if (!isQtyPermissionGranted && newCurrentSetting.stockAvailableQtyEnabled) {
      newCurrentSetting.stockAvailableQtyEnabled = false
    }

    if (!isQtyPermissionGranted && newCurrentSetting.stockQtyToShipEnabled) {
      newCurrentSetting.stockQtyToShipEnabled = false
    }

    if (this._isPriceFilterForced()) {
      const ug_id = util.getNavParam(this.props, 'ug_id')
      const pg_ids = util.getNavParam(this.props, 'pg_ids')
      const seller_store_id = util.getNavParam(this.props, 'seller_store_id')

      if (_.isNumber(ug_id)) {
        newCurrentSetting.selectedUserGroupId = ug_id
      }

      if (_.isArray(pg_ids) && pg_ids.length > 0) {
        newCurrentSetting.selectedProductGroupIds = pg_ids
      }

      if (_.isNumber(seller_store_id)) {
        newCurrentSetting.sellerStoreId = seller_store_id
      }

      newCurrentSetting.isPriceShow = true
    }

    let newState: Partial<IProductListFilterViewState> = { currentSetting: newCurrentSetting, defaultSetting }
    // console.log('_setupFilter newState 1 => ', newState)

    if (currentSetting.selectedUserGroupId) {
      newState = { ...newState, ...this._getStoreProductSelectorOptionsFromUgId(currentSetting.selectedUserGroupId) }
    } else if (_.isArray(currentSetting.selectedProductGroupIds) && currentSetting.selectedProductGroupIds.length > 0) {
      const selectedProductGroupId = currentSetting.selectedProductGroupIds[0]
      newState = { ...newState, ...this._getStoreProductSelectorOptionsFromPgId(selectedProductGroupId) }
    } else if (_.isNumber(currentSetting.sellerStoreId)) {
      newState = { ...newState, ...this._getStoreProductSelectorOptionsFromSellerStoreId(currentSetting.sellerStoreId) }
      // console.log('_setupFilter newState 2 => ', newState)
    } else {
      newState = { ...newState, optionChooseProductsFromIndex: 1 }
    }

    // console.log('_setupFilter newState 3 => ', newState)

    await util.setStatePromise(this, newState)
  }

  _getStoreProductSelectorOptionsFromUgId = (selectedUserGroupId: number): IAddStoreProductSelectorOptions => {
    const { selectedStore, sellerStores } = this.props
    const user_groups = selectedStore.get('user_groups') || List([])
    // const product_groups = selectedStore.get('product_groups') || List([])

    const foundUgIndex = user_groups.findIndex((ug) => ug.get('id') === selectedUserGroupId)

    if (foundUgIndex >= 0) {
      return {
        optionChooseProductsFromIndex: 1,
        optionSelectedSellerStoreIndex: 0,
        optionChooseProductsFollowingIndex: 0,
        optionChooseProductsByGroupIndex: foundUgIndex,
      }
    }

    return {
      optionChooseProductsFromIndex: 1,
      optionSelectedSellerStoreIndex: 0,
      optionChooseProductsFollowingIndex: 0,
      optionChooseProductsByGroupIndex: 0,
    }
  }

  _getStoreProductSelectorOptionsFromPgId = (selectedProductGroupId: number): IAddStoreProductSelectorOptions => {
    const { selectedStore, sellerStores } = this.props
    const product_groups = selectedStore.get('product_groups') || List([])

    const foundPgIndex = product_groups.findIndex((pg) => pg.get('id') === selectedProductGroupId)

    if (foundPgIndex >= 0) {
      return {
        optionChooseProductsFromIndex: 1,
        optionSelectedSellerStoreIndex: 0,
        optionChooseProductsFollowingIndex: 1,
        optionChooseProductsByGroupIndex: foundPgIndex,
      }
    }

    return {
      optionChooseProductsFromIndex: 1,
      optionSelectedSellerStoreIndex: 0,
      optionChooseProductsFollowingIndex: 1,
      optionChooseProductsByGroupIndex: 0,
    }
  }

  _getStoreProductSelectorOptionsFromSellerStoreId = (sellerStoreId: number): IAddStoreProductSelectorOptions => {
    const { selectedStore, sellerStores } = this.props
    console.log('_getStoreProductSelectorOptionsFromSellerStoreId sellerStores.toJS() => ', sellerStores.toJS())

    const foundSellerStoreIndex = sellerStores.findIndex((ss) => ss.get('id') === sellerStoreId)
    console.log('_getStoreProductSelectorOptionsFromSellerStoreId foundSellerStoreIndex => ', foundSellerStoreIndex)

    if (foundSellerStoreIndex >= 0) {
      return {
        optionChooseProductsFromIndex: 0,
        optionSelectedSellerStoreIndex: foundSellerStoreIndex,
        optionChooseProductsFollowingIndex: 0,
        optionChooseProductsByGroupIndex: 0,
      }
    }

    return {
      optionChooseProductsFromIndex: 0,
      optionSelectedSellerStoreIndex: 0,
      optionChooseProductsFollowingIndex: 0,
      optionChooseProductsByGroupIndex: 0,
    }
  }

  _isPriceFilterForced = () => {
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    const seller_store_id = util.getNavParam(this.props, 'seller_store_id')

    return _.isNumber(ug_id) || (_.isArray(pg_ids) && pg_ids.length > 0) || _.isNumber(seller_store_id)
  }

  _getCurrentFilter = () => {
    const { currentSetting = {} } = this.state
    return currentSetting as IProductListFilterTemplate
  }

  _setCurrentFilter = async (newFilter: Partial<IProductListFilterTemplate>) => {
    const { currentSetting } = this.state
    const newCurrentFilter = { ...currentSetting, ...newFilter }
    await util.setStatePromise(this, { currentSetting: newCurrentFilter })
  }

  _seletedCategory = async (category?: ICategory) => {
    // const { selectedCategoryId } = this._getCurrentFilter()
    // if (_.isNil(category) || (_.isObject(category) && selectedCategoryId === category.id)) {
    //   await this._setCurrentFilter({ selectedCategoryId: null })
    //   return
    // }
    if (_.isNil(category)) {
      await this._setCurrentFilter({ selectedCategoryId: null })
      return
    }
    await this._setCurrentFilter({ selectedCategoryId: category.id })

    // const { levelCategoryList } = this.state
    // const newLvlelCat = []
    // levelCategoryList.map((data: ICategory) => {
    //   if (data.id === category.id) {
    //     const newCat = data
    //     newCat.selected = _.isNil(data.selected) ? true : !data.selected
    //     newLvlelCat.push(newCat)
    //   } else {
    //     const newCat = data
    //     newCat.selected = false
    //     newLvlelCat.push(newCat)
    //   }
    // })
    // await util.setStatePromise(this, {
    //   levelCategoryList: newLvlelCat,
    // })
  }

  _onPressSubmitBtn = async () => {
    const onSubmit = util.getNavParam(this.props, 'onSubmit')

    if (_.isFunction(onSubmit)) {
      await onSubmit(this._getCurrentFilter())
      this._goBack()
    }
    return

    const {
      // levelCategoryList,
      // allChildren,
      // mkpChIds,
      segmentHasMkpChIndex,
      segmentHasMkpChNotInIndex,
      mkpChannelActiveMap,
      mkpChannelNotInActiveMap,
      createdAtFrom,
      createdAtTo,
      createdAtRangeOptionKey,
      segmentCreatedAtIndex,
      segmentHasParentIndex,
      segmentUpdatedAtIndex,
      updatedAtFrom,
      updatedAtTo,
      updatedAtRangeOptionKey,
      segmentHasCreatedByMkpChIndex,
      hasCreatedByMkpChIds,
      segmentHasCreatedByMkpChSpecificIndex,
    } = this._getCurrentFilter()

    // const { onSubmit, onRequestCloseView } = util.getNavParams(this.props)

    // if (_.isFunction(onRequestCloseView)) {
    //   onRequestCloseView()
    // }
    // this.closeFilterModal()

    // let selectedCat = null
    // levelCategoryList.map((data: ICategory) => {
    //   if (data.selected) {
    //     selectedCat = data
    //   }
    // })

    const spf: IProductListFilterState = {
      // category: selectedCat,
      // allChildrenCategory: allChildren,
    }

    // if (selectedCat) {
    //   spf.category = selectedCat
    // }

    // if (!allChildren) {
    //   spf.allChildrenCategory = false
    // }

    // if (mkpChIds.length > 0) {
    //   // @ts-ignore
    //   spf.mkpChIds = mkpChIds
    // }

    // if (_.includes([1, 2], segmentHasMkpChIndex)) {
    //   spf.segmentHasMkpChIndex = segmentHasMkpChIndex
    //   spf.mkpChIds = mkpChIds
    // }

    // if (segmentHasMkpChIndex === 2) {
    //   spf.mkpChIds = mkpChIds
    // }
    if (segmentHasMkpChIndex === 1 && _.isObject(mkpChannelActiveMap) && !_.isEmpty(mkpChannelActiveMap)) {
      spf.segmentHasMkpChIndex = segmentHasMkpChIndex
      spf.mkpChannelActiveMap = mkpChannelActiveMap
    }

    if (segmentHasMkpChNotInIndex === 1 && _.isObject(mkpChannelNotInActiveMap) && !_.isEmpty(mkpChannelNotInActiveMap)) {
      spf.segmentHasMkpChNotInIndex = segmentHasMkpChNotInIndex
      spf.mkpChannelNotInActiveMap = mkpChannelNotInActiveMap
    }

    if (_.includes([1, 2], segmentHasCreatedByMkpChIndex)) {
      spf.segmentHasCreatedByMkpChIndex = segmentHasCreatedByMkpChIndex
    }

    if (segmentHasCreatedByMkpChIndex === 2 && segmentHasCreatedByMkpChSpecificIndex === 1) {
      spf.segmentHasCreatedByMkpChSpecificIndex = segmentHasCreatedByMkpChSpecificIndex
      spf.hasCreatedByMkpChIds = hasCreatedByMkpChIds
    }

    if (_.includes([1, 2], segmentHasParentIndex)) {
      spf.segmentHasParentIndex = segmentHasParentIndex
    }

    if (segmentCreatedAtIndex === 1) {
      spf.segmentCreatedAtIndex = segmentCreatedAtIndex
      spf.createdAtFrom = createdAtFrom
      spf.createdAtTo = createdAtTo
      spf.createdAtRangeOptionKey = createdAtRangeOptionKey
    }

    if (segmentUpdatedAtIndex === 1) {
      spf.segmentUpdatedAtIndex = segmentUpdatedAtIndex
      spf.updatedAtFrom = updatedAtFrom
      spf.updatedAtTo = updatedAtTo
      spf.updatedAtRangeOptionKey = updatedAtRangeOptionKey
    }

    await onSubmit(spf)
    // this.loadProps = true
  }

  resetFilter = async () => {
    const { defaultSetting = {} } = this.state
    // const defaultSetting = util.getNavParam(this.props, 'defaultSetting', {})
    // const { defaultSetting = {} } = util.getNavParams(this.props)
    // const { levelCategoryList } = this.state
    // const newLevelCategoryList = []
    // levelCategoryList.map((data: ICategory) => {
    //   const xData = data
    //   xData.selected = false
    //   newLevelCategoryList.push(xData)
    // })
    // await util.setStatePromise(this, {
    //   // levelCategoryList: newLevelCategoryList,
    //   channels: util.getMKPChannels(),
    //   hasCreatedByMkpChannels: util.getMKPChannels(),

    //   allChildren: true,

    //   segmentCreatedAtIndex: 0,
    //   createdAtFrom: null,
    //   createdAtTo: null,
    //   createdAtRangeOptionKey: null,

    //   segmentUpdatedAtIndex: 0,
    //   updatedAtFrom: null,
    //   updatedAtTo: null,
    //   updatedAtRangeOptionKey: null,

    //   segmentHasParentIndex: 0,

    //   segmentHasMkpChIndex: 0,
    //   // mkpChIds: [],
    //   segmentHasMkpChNotInIndex: 0,
    //   mkpChannelActiveMap: {},
    //   mkpChannelNotInActiveMap: {},

    //   segmentHasCreatedByMkpChIndex: 0,
    //   segmentHasCreatedByMkpChSpecificIndex: 0,
    //   hasCreatedByMkpChIds: [],

    //   ...defaultSetting,
    // })

    await util.setStatePromise(this, { currentSetting: defaultSetting })
    await this._setupDateRangeFilter()
    await this._setupFilter()
    await this._setupCategoryList()
    await this._setupExtended()
  }

  _setAllChildrenCategory = () => {
    const { isSubCategoryIncluded } = this._getCurrentFilter()
    this._setCurrentFilter({ isSubCategoryIncluded: !isSubCategoryIncluded })
  }

  isFilterDirty = () => {
    const { defaultSetting = {}, currentSetting = {} } = this.state
    // const { defaultSetting = {}, currentSetting = {} } = util.getNavParams(this.props)
    // const defaultSetting = util.getNavParam(this.props, 'defaultSetting', {})
    // const currentSetting = util.getNavParam(this.props, 'currentSetting', {})

    // @ts-ignore
    const filterDiff = diff(defaultSetting, currentSetting)
    if (_.isEmpty(filterDiff)) {
      return false
    }
    return true
  }

  // openFilterModal = () => {
  //   const { levelCategoryList, currentSetting = {} } = util.getNavParams(this.props)

  //   this.setState({ isModalOpen: true, levelCategoryList, ...currentSetting })
  // }

  // closeFilterModal = () => {
  //   this.setState({ isModalOpen: false })
  // }

  // _renderHeader = () => (
  //   <View
  //     style={{
  //       flexBasis: 44,
  //       height: 50,
  //       width: '100%',
  //       backgroundColor: '#fff',
  //       justifyContent: 'center',
  //       flexDirection: 'row',
  //       alignItems: 'center',
  //     }}>
  //     {/* {isIos ? <View style={{ marginTop: 30 }} /> : null} */}
  //     <Text style={{ color: COLORS.TEXT_ACTIVE, fontSize: STYLES.FONT_SIZE_LARGER, flex: 1, textAlign: 'center' }}>กรอง</Text>
  //     {Platform.OS === 'web' ? null : (
  //       <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center' }} onPress={this.closeFilterModal}>
  //         <XIcon name='cancel' family='MaterialIcons' style={{ flex: 0 }} />
  //       </TouchableOpacity>
  //     )}
  //   </View>
  // )

  _renderProductCategoryButton = (data: ICategory, index: number) => {
    const { selectedCategoryId } = this._getCurrentFilter()

    if (_.isNil(selectedCategoryId) && data.p) {
      return null
    }

    if (selectedCategoryId && data.p !== selectedCategoryId) {
      return null
    }

    const marginLeft = index === 0 ? 4 : 4
    return (
      <TouchableOpacity
        key={`ProductFilterCatItem_${index.toString()}_${data.id}`}
        onPress={() => this._seletedCategory(data)}
        style={{
          // minWidth: 30,
          // width: 50,
          // maxWidth: 120,
          height: 25,
          borderColor: data.l,
          borderWidth: 0.7,
          borderRadius: 6,
          marginLeft,
          paddingLeft: 8,
          marginTop: 10,
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {data.selected ? (
          <Icon name='check' type='AntDesign' style={{ color: COLORS.APP_MAIN, minWidth: 25, fontSize: 20, width: 25 }} />
        ) : (
          <View style={{ height: 1, width: 25 }} />
        )}
        <XText
          style={{
            // fontSize: STYLES.FONT_SIZE_NORMAL,
            color: data.l ? data.l : COLORS.TEXT_INACTIVE,
            textAlign: 'center',
            paddingHorizontal: 2,
            paddingRight: 6,
          }}
          numberOfLines={1}>
          {data.n}
        </XText>
      </TouchableOpacity>
    )
  }

  onChangeSegmentHasMkpChIndex = async (index: number) => {
    const { segmentHasMkpChNotInIndex = 0, mkpChannelNotInActiveMap = {} } = this._getCurrentFilter()

    const newState: Partial<IProductListFilterState> = { segmentHasMkpChIndex: index }

    if (index === 0) {
      newState.mkpChannelActiveMap = {}
    }

    if (index === 1 && segmentHasMkpChNotInIndex === 0) {
      newState.mkpChannelActiveMap = {}
      const mkpChannels = util.getMKPChannels()
      for (let i = 0; i < mkpChannels.length; i++) {
        newState.mkpChannelActiveMap[mkpChannels[i].id] = true
      }
    }

    if (index === 1 && segmentHasMkpChNotInIndex === 1) {
      newState.mkpChannelActiveMap = {}
      const erpDocTypeNotInActiveIds = Object.keys(mkpChannelNotInActiveMap).map((key) => parseInt(key))
      for (let i = 0; i < erpDocTypeNotInActiveIds.length; i++) {
        const erpDocTypeNotInActiveId = erpDocTypeNotInActiveIds[i]
        newState.mkpChannelActiveMap[erpDocTypeNotInActiveId] = !mkpChannelNotInActiveMap[erpDocTypeNotInActiveId]
      }
    }

    // await util.setStatePromise(this, newState)
    await this._setCurrentFilter(newState)
  }

  onChangeSegmentHasMkpChNotInIndex = async (index: number) => {
    const { segmentHasMkpChIndex = 0, mkpChannelActiveMap = {} } = this._getCurrentFilter()

    const newState: Partial<IProductListFilterState> = { segmentHasMkpChNotInIndex: index }

    if (index === 0) {
      newState.mkpChannelNotInActiveMap = {}
    }

    if (index === 1 && segmentHasMkpChIndex === 0) {
      newState.mkpChannelNotInActiveMap = {}
      const mkpChannels = util.getMKPChannels()
      for (let i = 0; i < mkpChannels.length; i++) {
        newState.mkpChannelNotInActiveMap[mkpChannels[i].id] = true
      }
    }

    if (index === 1 && segmentHasMkpChIndex === 1) {
      newState.mkpChannelNotInActiveMap = {}
      const erpDocTypeNotInActiveIds = Object.keys(mkpChannelActiveMap).map((key) => parseInt(key))
      for (let i = 0; i < erpDocTypeNotInActiveIds.length; i++) {
        const erpDocTypeNotInActiveId = erpDocTypeNotInActiveIds[i]
        newState.mkpChannelNotInActiveMap[erpDocTypeNotInActiveId] = !mkpChannelActiveMap[erpDocTypeNotInActiveId]
      }
    }

    // await util.setStatePromise(this, newState)
    await this._setCurrentFilter(newState)
  }

  onChangeSegmentHasMkpChPairedModeIndex = async (index: number) => {
    await this._setCurrentFilter({ segmentHasMkpChPairedModeIndex: index })
  }

  onChangeSegmentHasMkpChNotInPairedModeIndex = async (index: number) => {
    await this._setCurrentFilter({ segmentHasMkpChNotInPairedModeIndex: index })
  }

  // -------------------------------------------------------------
  _renderMkpChannelSelector = () => {
    const mkpChannels = util.getMKPChannels()
    return mkpChannels.map(this._renderMkpChannelSelectorItem)
  }

  _renderMkpChannelSelectorItem = (ch: IMKPChannelDetail, index: number) => {
    const { mkpChannelActiveMap = {} } = this._getCurrentFilter()
    const checked = mkpChannelActiveMap[ch.id] || false

    return (
      <XCheckableItem
        key={`mkp-item-${ch.id}_${index}`}
        checked={checked}
        label={ch.name}
        onPress={() => this._onMkpChannelSelectorItemPress(ch, index)}
        icon={<MkpLogo mkpId={ch.mkp_id} width={24} height={24} />}
      />
    )

    // return (
    //   <HStack key={`mkp-item-${ch.id}_${index}`} pb='1'>
    //     <TouchableOpacity onPress={() => this._onMkpChannelSelectorItemPress(ch, index)}>
    //       <HStack
    //         px='2'
    //         py='1'
    //         space='1'
    //         alignItems='center'
    //         borderWidth='1'
    //         borderColor={checked ? 'gray.400' : 'gray.200'}
    //         borderRadius='lg'>
    //         {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
    //         <MkpLogo mkpId={ch.mkp_id} width={24} height={24} />
    //         <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
    //           {ch.name}
    //         </XText>
    //       </HStack>
    //     </TouchableOpacity>
    //   </HStack>
    // )
  }

  _onMkpChannelSelectorItemPress = async (ch: IMKPChannelDetail, index: number) => {
    const mkpChId = ch.id
    const { mkpChannelNotInActiveMap: notInMap = {}, mkpChannelActiveMap: inMap = {} } = this._getCurrentFilter()
    const mkpChannelActiveMap = { ...inMap }
    const mkpChannelNotInActiveMap = { ...notInMap }

    if (_.isNil(mkpChannelActiveMap[mkpChId])) {
      mkpChannelActiveMap[mkpChId] = true
      mkpChannelNotInActiveMap[mkpChId] = false
    } else if (_.isBoolean(mkpChannelActiveMap[mkpChId])) {
      const newValue = !mkpChannelActiveMap[mkpChId]
      mkpChannelActiveMap[mkpChId] = newValue
      if (newValue === true) {
        mkpChannelNotInActiveMap[mkpChId] = false
      }
    }

    // await util.setStatePromise(this, { mkpChannelActiveMap, mkpChannelNotInActiveMap })
    await this._setCurrentFilter({ mkpChannelActiveMap, mkpChannelNotInActiveMap })
  }

  //----------------------------------
  _renderMkpChannelSelectorNotIn = () => {
    const mkpChannels = util.getMKPChannels()
    return mkpChannels.map(this._renderMkpChannelSelectorNotInItem)
  }

  _renderMkpChannelSelectorNotInItem = (ch: IMKPChannelDetail, index: number) => {
    const { mkpChannelNotInActiveMap = {} } = this._getCurrentFilter()
    const checked = mkpChannelNotInActiveMap[ch.id] || false

    return (
      <XCheckableItem
        key={`mkp-item-not-in-${ch.id}_${index}`}
        checked={checked}
        label={ch.name}
        onPress={() => this._onMkpChannelSelectorNotInItemPress(ch, index)}
        icon={<MkpLogo mkpId={ch.mkp_id} width={24} height={24} />}
      />
    )

    // return (
    //   <HStack key={`mkp-item-not-in-${ch.id}_${index}`} pb='1'>
    //     <TouchableOpacity onPress={() => this._onMkpChannelSelectorNotInItemPress(ch, index)}>
    //       <HStack
    //         px='2'
    //         py='1'
    //         space='1'
    //         alignItems='center'
    //         borderWidth='1'
    //         borderColor={checked ? 'gray.400' : 'gray.200'}
    //         borderRadius='lg'>
    //         {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
    //         <MkpLogo mkpId={ch.mkp_id} width={24} height={24} />
    //         <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
    //           {ch.name}
    //         </XText>
    //       </HStack>
    //     </TouchableOpacity>
    //   </HStack>
    // )
  }

  _onMkpChannelSelectorNotInItemPress = async (ch: IMKPChannelDetail, index: number) => {
    const mkpChId = ch.id
    const { mkpChannelNotInActiveMap: notInMap = {}, mkpChannelActiveMap: inMap = {} } = this._getCurrentFilter()
    const mkpChannelActiveMap = { ...inMap }
    const mkpChannelNotInActiveMap = { ...notInMap }

    if (_.isNil(mkpChannelNotInActiveMap[mkpChId])) {
      mkpChannelNotInActiveMap[mkpChId] = true
      mkpChannelActiveMap[mkpChId] = false
    } else if (_.isBoolean(mkpChannelNotInActiveMap[mkpChId])) {
      const newValue = !mkpChannelNotInActiveMap[mkpChId]
      mkpChannelNotInActiveMap[mkpChId] = newValue
      if (newValue === true) {
        mkpChannelActiveMap[mkpChId] = false
      }
    }

    // await util.setStatePromise(this, { mkpChannelActiveMap, mkpChannelNotInActiveMap })
    await this._setCurrentFilter({ mkpChannelActiveMap, mkpChannelNotInActiveMap })
  }

  _renderFilterWhichSystemIn = () => {
    const { channels } = this.state
    const {
      segmentHasMkpChIndex = 0,
      segmentHasMkpChNotInIndex = 0,
      segmentHasMkpChNotInPairedModeIndex = 0,
      segmentHasMkpChPairedModeIndex = 0,
      isMkpShow = false,
    } = this._getCurrentFilter()

    if (!channels || channels.length === 0) {
      return null
    }

    return (
      <VStack w='full' bg='white'>
        <VStack w='full' px='2' pt='2' pb='3' space='1' borderTopWidth='1' borderColor='gray.300'>
          <HStack alignItems='center' space='1'>
            <XImageIcon source={logoMkpChannel} />
            <XText variant='inactive'>
              <XText bold>เชื่อม</XText>อยู่กับช่องทางขาย...
            </XText>
          </HStack>

          <Segment
            selectedIndex={segmentHasMkpChIndex}
            options={['ไม่ระบุ', 'เชื่อมอยู่']}
            onSegmentChange={this.onChangeSegmentHasMkpChIndex}
          />

          {segmentHasMkpChIndex === 1 ? (
            <VStack w='full' pt='1'>
              <XText variant='inactive'>ลักษณะการเชื่อม...</XText>
              <Segment
                selectedIndex={segmentHasMkpChPairedModeIndex}
                options={['เชื่อมอย่างน้อย 1 ช่องทาง', 'เชื่อมทุกช่องทาง']}
                onSegmentChange={this.onChangeSegmentHasMkpChPairedModeIndex}
              />
            </VStack>
          ) : null}

          {segmentHasMkpChIndex === 1 ? (
            <VStack w='full' pt='1'>
              <XText variant='inactive'>
                {`เชื่อม${segmentHasMkpChPairedModeIndex === 0 ? 'อย่างน้อย 1 ช่องทาง' : 'ทุกช่องทาง'}กับช่องทางทางเหล่านี้...`}
              </XText>
              <HStack w='full' flexWrap='wrap' space='1'>
                {this._renderMkpChannelSelector()}
              </HStack>
            </VStack>
          ) : null}
        </VStack>

        <VStack w='full' px='2' pt='2' pb='3' space='1' borderTopWidth='1' borderColor='gray.300' bg='gray.50'>
          <HStack alignItems='center' space='1'>
            <Box>
              <XImageIcon source={logoMkpChannel} />
              <HStack
                alignItems='flex-start'
                justifyContent='flex-start'
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  borderRadius: 6,
                  backgroundColor: 'white',
                  overflow: 'hidden',
                  width: 12,
                  height: 12,
                }}>
                <XIcon
                  variant='danger'
                  name='close'
                  family='MaterialIcons'
                  style={{
                    position: 'absolute',
                    top: -6,
                    fontSize: 12,
                    width: 12,
                    height: 12,
                    // backgroundColor: 'red',
                  }}
                />
              </HStack>
            </Box>
            <XText variant='inactive'>
              <XText bold>ไม่เชื่อม</XText>อยู่กับช่องทางขาย...
            </XText>
          </HStack>

          <Segment
            selectedIndex={segmentHasMkpChNotInIndex}
            options={['ไม่ระบุ', 'ไม่ได้เชื่อม']}
            onSegmentChange={this.onChangeSegmentHasMkpChNotInIndex}
          />

          {segmentHasMkpChNotInIndex === 1 ? (
            <VStack w='full' pt='1'>
              <XText variant='inactive'>ลักษณะการเชื่อม...</XText>
              <Segment
                selectedIndex={segmentHasMkpChNotInPairedModeIndex}
                options={['ไม่เชื่อมอย่างน้อย 1 ช่องทาง', 'ไม่เชื่อมทุกช่องทาง']}
                onSegmentChange={this.onChangeSegmentHasMkpChNotInPairedModeIndex}
              />
            </VStack>
          ) : null}

          {segmentHasMkpChNotInIndex === 1 ? (
            <VStack w='full' pt='1'>
              <XText variant='inactive'>{`ไม่ได้เชื่อม${
                segmentHasMkpChNotInPairedModeIndex === 0 ? 'อย่างน้อย 1 ช่องทาง' : 'ทุกช่องทาง'
              }กับช่องทางทางเหล่านี้...`}</XText>
              <HStack w='full' flexWrap='wrap' space='1'>
                {this._renderMkpChannelSelectorNotIn()}
              </HStack>
            </VStack>
          ) : null}
        </VStack>
      </VStack>
    )
  }

  onChangeSegmentHasCreatedByMkpChIndex = (idx: number) => {
    // const { hasCreatedByMkpChannels } = this.state
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentHasCreatedByMkpChIndex = idx
    // if (_.includes([1, 2], idx)) {
    //   newState.hasCreatedByMkpChIds = hasCreatedByMkpChannels.filter((ch: IMKPChannelDetail) => ch.is_active).map((ch) => ch.id)
    // }
    if (_.includes([0, 1], idx)) {
      newState.segmentHasCreatedByMkpChSpecificIndex = 0
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  onChangeSegmentHasCreatedByMkpChSpecificIndex = (idx: number) => {
    const { hasCreatedByMkpChannels } = this.state
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentHasCreatedByMkpChSpecificIndex = idx
    if (idx === 2) {
      newState.hasCreatedByMkpChIds = hasCreatedByMkpChannels.filter((ch: IMKPChannelDetail) => ch.is_active).map((ch) => ch.id)
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  // _renderFilterHasCreatedByMkpCh = () => {
  //   const { hasCreatedByMkpChannels, segmentHasCreatedByMkpChIndex = 0 } = this.state

  //   if (!hasCreatedByMkpChannels || hasCreatedByMkpChannels.length === 0) {
  //     return null
  //   }

  //   return (
  //     <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
  //       <HStack alignItems='center' space='1'>
  //         {/* <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' /> */}
  //         <XImageIcon source={logoMkpChannel} />
  //         <XText variant='inactive'>ถูกสร้างอัตโนมัติโดยช่องทางขาย</XText>
  //       </HStack>

  //       <Segment
  //         selectedIndex={segmentHasCreatedByMkpChIndex}
  //         options={['ทั้งหมด', 'ไม่อยู่ใน', 'อยู่ใน']}
  //         onSegmentChange={this.onChangeSegmentHasCreatedByMkpChIndex}
  //       />

  //       {_.includes([1, 2], segmentHasCreatedByMkpChIndex) ? (
  //         <VStack w='full'>
  //           {segmentHasCreatedByMkpChIndex === 1 ? <XText variant='inactive'>ไม่แสดงช่องทางเหล่านี้...</XText> : null}
  //           {segmentHasCreatedByMkpChIndex === 2 ? <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText> : null}
  //           <HStack w='full' flexWrap='wrap'>
  //             <MKPChannelsActive channels={hasCreatedByMkpChannels} onPressChannel={this._onPressHasCreatedByMkpChMkpCh} />
  //           </HStack>
  //         </VStack>
  //       ) : null}
  //     </VStack>
  //   )
  // }

  onChangeSegmentHasParentIndex = (idx: number) => {
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentHasParentIndex = idx
    if (_.includes([0, 1], idx)) {
      newState.segmentHasCreatedByMkpChIndex = 0
      newState.segmentHasCreatedByMkpChSpecificIndex = 0
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  _renderMkpPairedVisibility = () => {
    const { channels } = this.state
    if (!channels || channels.length === 0) {
      return null
    }

    const { isMkpShow, showCreatedByMkpChId, isProductTemplateFilter } = this._getCurrentFilter()

    if (isProductTemplateFilter) {
      return null
    }

    return (
      <VStack w='full' p='2'>
        <XCheckbox
          checked={isMkpShow}
          onPress={() => this._setCurrentFilter({ isMkpShow: !isMkpShow })}
          label='แสดงข้อมูล การเชื่อมช่องทางขาย'
        />

        <XCheckbox
          checked={showCreatedByMkpChId}
          onPress={() => this._setCurrentFilter({ showCreatedByMkpChId: !showCreatedByMkpChId })}
          label='แสดงข้อมูล การสร้างสินค้าโดยระบบ'
        />
      </VStack>
    )
  }

  _renderFilterHasParent = () => {
    const {
      isHasParentHidden = false,
      segmentHasParentIndex = 0,
      segmentHasCreatedByMkpChIndex = 0,
      segmentHasCreatedByMkpChSpecificIndex = 0,
    } = this._getCurrentFilter()
    const { hasCreatedByMkpChannels } = this.state

    return (
      <VStack w='full' px='2' pt='2' pb='3' space='1' borderTopWidth='1' borderColor='gray.300' bg='gray.50'>
        {isHasParentHidden ? null : (
          <HStack alignItems='center' space='1'>
            <XIcon variant='active' name='note-add' family='MaterialIcons' />
            <XText variant='inactive'>สร้างโดย</XText>
          </HStack>
        )}

        {isHasParentHidden ? null : (
          <Segment
            selectedIndex={segmentHasParentIndex}
            options={['ทั้งหมด', 'ดึงจากร้านอื่น', 'ร้านฉัน']}
            onSegmentChange={this.onChangeSegmentHasParentIndex}
          />
        )}

        {segmentHasParentIndex === 2 ? (
          <VStack w='full' space='1'>
            <HStack alignItems='center' space='1'>
              <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' />
              <XText variant='inactive'>วิธีที่ถูกสร้าง</XText>
            </HStack>
            <Segment
              selectedIndex={segmentHasCreatedByMkpChIndex}
              options={['ทั้งหมด', 'สร้างเอง', 'ระบบสร้างให้']}
              onSegmentChange={this.onChangeSegmentHasCreatedByMkpChIndex}
            />
          </VStack>
        ) : null}

        {segmentHasCreatedByMkpChIndex === 2 && hasCreatedByMkpChannels && hasCreatedByMkpChannels.length > 0 ? (
          <VStack w='full' space='1'>
            <HStack alignItems='center' space='1'>
              <XImageIcon source={logoMkpChannel} />
              <XText variant='inactive'>ถูกสร้างอัตโนมัติจากการซิงค์ข้อมูลจากช่องทางขาย</XText>
            </HStack>

            <Segment
              selectedIndex={segmentHasCreatedByMkpChSpecificIndex}
              options={['ทั้งหมด', 'ระบุช่องทาง']}
              onSegmentChange={this.onChangeSegmentHasCreatedByMkpChSpecificIndex}
            />
          </VStack>
        ) : null}

        {segmentHasCreatedByMkpChSpecificIndex === 1 ? (
          <VStack w='full'>
            <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap'>
              <MKPChannelsActive channels={hasCreatedByMkpChannels} onPressChannel={this._onPressHasCreatedByMkpChMkpCh} />
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  _getSelectedCategory = () => {
    const { categoryList } = this.state
    const { selectedCategoryId } = this._getCurrentFilter()
    if (_.isNil(selectedCategoryId)) {
      return null
    }

    const foundCat = categoryList.find((cat: ICategory) => cat.id === selectedCategoryId)
    return foundCat
  }

  _getSelectedCategoryBreadcrumbName = () => {
    const { categoryList } = this.state
    const selectedCategory = this._getSelectedCategory()

    let selectedCatName = selectedCategory.n

    let focusCatId = selectedCategory.p
    let isMoreParent = _.isNumber(selectedCategory.p)
    while (isMoreParent) {
      const foundCat = categoryList.find((cat: ICategory) => cat.id === focusCatId)
      if (foundCat) {
        selectedCatName = `${foundCat.n} > ${selectedCatName}`
        focusCatId = foundCat.p
        isMoreParent = _.isNumber(foundCat.p)
      } else {
        isMoreParent = false
      }
    }

    return selectedCatName
  }

  // _renderCategoryCard = () => {
  //   // const { categoryList } = this.props
  //   // const { levelCategoryList, allChildren, categoryList } = this.state
  //   const { categoryList } = this.state
  //   const { isSubCategoryIncluded = false } = this._getCurrentFilter()

  //   // console.log('categoryList', categoryList)
  //   // console.log('categoryList.toJS()', categoryList.toJS())
  //   // if (_.isNil(levelCategoryList)) {
  //   //   return null
  //   // }
  //   // if (_.isNil(categoryList) || categoryList.size === 0) {
  //   //   return null
  //   // }

  //   if (_.isNil(categoryList) || categoryList.length === 0) {
  //     return null
  //   }

  //   const selectedCategory = this._getSelectedCategory()
  //   // console.log('levelCategoryList', levelCategoryList)
  //   return (
  //     <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
  //       <HStack alignItems='center' space='1'>
  //         <XIcon variant='active' family='MaterialCommunityIcons' name='view-grid' />
  //         <XText variant='inactive'>หมวดหมู่</XText>
  //       </HStack>

  //       {selectedCategory ? (
  //         <HStack w='full' flexWrap='wrap'>
  //           <TouchableOpacity
  //             onPress={() => this._setCurrentFilter({ selectedCategoryId: null })}
  //             style={{
  //               height: 25,
  //               borderColor: selectedCategory.l,
  //               borderWidth: 0.7,
  //               borderRadius: 6,
  //               // marginLeft,
  //               paddingLeft: 8,
  //               marginTop: 10,
  //               justifyContent: 'center',
  //               flexDirection: 'row',
  //               alignItems: 'center',
  //             }}>
  //             <Icon name='check' type='AntDesign' style={{ color: COLORS.APP_MAIN, minWidth: 25, fontSize: 20, width: 25 }} />
  //             <XText
  //               style={{
  //                 color: selectedCategory.l ? selectedCategory.l : COLORS.TEXT_INACTIVE,
  //                 textAlign: 'center',
  //                 paddingHorizontal: 2,
  //                 paddingRight: 6,
  //               }}
  //               numberOfLines={1}>
  //               {this._getSelectedCategoryBreadcrumbName()}
  //             </XText>
  //           </TouchableOpacity>
  //         </HStack>
  //       ) : null}

  //       <HStack w='full' flexWrap='wrap'>
  //         {categoryList.map(this._renderProductCategoryButton)}
  //       </HStack>

  //       {selectedCategory && selectedCategory.c.length > 0 ? (
  //         <TouchableOpacity
  //           // style={{ width: 130, justifyContent: 'center', flexDirection: 'row', alignItems: 'center', minHeight: 34 }}
  //           onPress={this._setAllChildrenCategory}>
  //           <HStack alignItems='center' space='1'>
  //             {isSubCategoryIncluded ? (
  //               <XIcon family='FontAwesome' name='check-square-o' />
  //             ) : (
  //               <XIcon family='FontAwesome' name='square-o' />
  //             )}
  //             <XText>รวมหมวดหมู่ย่อย</XText>
  //           </HStack>
  //         </TouchableOpacity>
  //       ) : null}
  //     </VStack>
  //   )
  // }

  _renderCategoryCard = () => {
    const { categoryList, currentSetting } = this.state
    const { isSubCategoryIncluded = false } = this._getCurrentFilter()

    if (_.isNil(categoryList) || categoryList.length === 0) {
      return null
    }

    let storeId = util.getNavParam(this.props, 'store_id')
    if (currentSetting && currentSetting.sellerStoreId) {
      storeId = currentSetting.sellerStoreId
    }

    const selectedCategory = this._getSelectedCategory()
    // console.log('levelCategoryList', levelCategoryList)
    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white' flexWrap='wrap'>
        <HStack w='full' alignItems='center' space='1'>
          <XIcon variant='active' family='MaterialCommunityIcons' name='view-grid' />
          <XText variant='inactive'>หมวดหมู่</XText>
        </HStack>

        <ProductCategorySelector
          key={storeId}
          isSelectorHorizontalScrollDisabled
          storeId={storeId}
          selectedCategoryId={
            _.isObject(currentSetting) && _.isNumber(currentSetting.selectedCategoryId) ? currentSetting.selectedCategoryId : null
          }
          onSelectCategory={this._seletedCategory}
        />

        {selectedCategory && selectedCategory.c.length > 0 ? (
          <TouchableOpacity
            // style={{ width: 130, justifyContent: 'center', flexDirection: 'row', alignItems: 'center', minHeight: 34 }}
            onPress={this._setAllChildrenCategory}>
            <HStack alignItems='center' space='1'>
              {isSubCategoryIncluded ? (
                <XIcon family='FontAwesome' name='check-square-o' />
              ) : (
                <XIcon family='FontAwesome' name='square-o' />
              )}
              <XText>รวมหมวดหมู่ย่อย</XText>
            </HStack>
          </TouchableOpacity>
        ) : null}
      </VStack>
    )
  }

  onChangeSegmentCreatedAtIndex = (idx) => {
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentCreatedAtIndex = idx
    if (idx === 0) {
      newState.createdAtRangeOptionKey = null
      newState.createdAtFrom = null
      newState.createdAtTo = null
    }
    if (idx === 1) {
      newState.createdAtRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
      const dateRange = util.getDateRangeFromOption(newState.createdAtRangeOptionKey)
      newState.createdAtFrom = dateRange.begin
      newState.createdAtTo = dateRange.end
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  onChangeDateCreatedAtFromTo = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newState: Partial<IProductListFilterState> = {}
    newState.createdAtFrom = dates.begin
    newState.createdAtTo = util.changeSecondTime(dates.end, 59)
    newState.createdAtRangeOptionKey = optionKey
    // await util.setStatePromise(this, newState)
    await this._setCurrentFilter(newState)
  }

  _renderFilterCreatedAt = () => {
    const { segmentCreatedAtIndex = 0, createdAtFrom, createdAtTo, createdAtRangeOptionKey } = this._getCurrentFilter()

    const createdAtDateRange = { begin: createdAtFrom, end: createdAtTo }

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='back-in-time' family='Entypo' />
          <XText variant='inactive'>สร้างภายใน</XText>
        </HStack>

        <Segment selectedIndex={segmentCreatedAtIndex} options={['ทั้งหมด', 'ระบุ']} onSegmentChange={this.onChangeSegmentCreatedAtIndex} />

        {segmentCreatedAtIndex === 1 ? (
          <XDateRangeSelectorButton
            // allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            // isOptionsScrollable={IS_SMALL_PHONE}
            isOptionsScrollable
            title='สร้างภายใน...'
            selectedDates={createdAtDateRange}
            selectedOptionKey={createdAtRangeOptionKey}
            onChange={this.onChangeDateCreatedAtFromTo}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  _renderDateTimeVisibility = () => {
    const { showPtCreatedAt, showPpCreatedAt, showPtUpdatedAt, showPpUpdatedAt, isProductTemplateFilter } = this._getCurrentFilter()

    if (isProductTemplateFilter) {
      return null
    }

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white' flexWrap='wrap'>
        <HStack w='full' alignItems='center'>
          <XCheckbox
            checked={showPtCreatedAt}
            onPress={() => this._setCurrentFilter({ showPtCreatedAt: !showPtCreatedAt })}
            label='แสดงวันที่สร้างสินค้า'
          />
        </HStack>

        <HStack w='full' alignItems='center'>
          <XCheckbox
            checked={showPtUpdatedAt}
            onPress={() => this._setCurrentFilter({ showPtUpdatedAt: !showPtUpdatedAt })}
            label='แสดงวันที่อัพเดทสินค้า'
          />
        </HStack>

        <HStack w='full' alignItems='center'>
          <XCheckbox
            checked={showPpCreatedAt}
            onPress={() => this._setCurrentFilter({ showPpCreatedAt: !showPpCreatedAt })}
            label='แสดงวันที่สร้างตัวเลือกสินค้า'
          />
        </HStack>

        <HStack w='full' alignItems='center'>
          <XCheckbox
            checked={showPpUpdatedAt}
            onPress={() => this._setCurrentFilter({ showPpUpdatedAt: !showPpUpdatedAt })}
            label='แสดงวันที่อัพเดทตัวเลือกสินค้า'
          />
        </HStack>
      </VStack>
    )
  }

  onChangeSegmentUpdatedAtIndex = (idx) => {
    const newState: Partial<IProductListFilterState> = {}
    newState.segmentUpdatedAtIndex = idx
    if (idx === 0) {
      newState.updatedAtRangeOptionKey = null
      newState.updatedAtFrom = null
      newState.updatedAtTo = null
    }
    if (idx === 1) {
      newState.updatedAtRangeOptionKey = CONS.DATERANGE_SELECTOR.OPTION.Today
      const dateRange = util.getDateRangeFromOption(newState.updatedAtRangeOptionKey)
      newState.updatedAtFrom = dateRange.begin
      newState.updatedAtTo = dateRange.end
    }
    // @ts-ignore
    // this.setState(newState)
    this._setCurrentFilter(newState)
  }

  onChangeDateUpdatedAtFromTo = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const newState: Partial<IProductListFilterState> = {}
    newState.updatedAtFrom = dates.begin
    newState.updatedAtTo = util.changeSecondTime(dates.end, 59)
    newState.updatedAtRangeOptionKey = optionKey
    // await util.setStatePromise(this, newState)
    await this._setCurrentFilter(newState)
  }

  _renderFilterUpdatedAt = () => {
    const { segmentUpdatedAtIndex = 0, updatedAtFrom, updatedAtTo, updatedAtRangeOptionKey } = this._getCurrentFilter()

    const updatedAtDateRange = { begin: updatedAtFrom, end: updatedAtTo }

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='back-in-time' family='Entypo' />
          <XText variant='inactive'>อัพเดทภายใน</XText>
        </HStack>
        <Segment selectedIndex={segmentUpdatedAtIndex} options={['ทั้งหมด', 'ระบุ']} onSegmentChange={this.onChangeSegmentUpdatedAtIndex} />
        {segmentUpdatedAtIndex === 1 ? (
          <XDateRangeSelectorButton
            // allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
            // isOptionsScrollable={IS_SMALL_PHONE}
            isOptionsScrollable
            title='อัพเดทภายใน...'
            selectedDates={updatedAtDateRange}
            selectedOptionKey={updatedAtRangeOptionKey}
            onChange={this.onChangeDateUpdatedAtFromTo}
            showTime
          />
        ) : null}
      </VStack>
    )
  }

  // _onPressMkpCh = (channels: IMKPChannelDetail[]) => {
  //   if (_.isNil(channels)) {
  //     this.setState({ mkpChIds: [] })
  //     return
  //   }

  //   const mkpChIds = []

  //   channels.forEach((ch: IMKPChannelDetail) => {
  //     if (ch.is_active) {
  //       mkpChIds.push(ch.id)
  //     }
  //   })

  //   this.setState({ mkpChIds })
  // }

  _onPressHasCreatedByMkpChMkpCh = (channels: IMKPChannelDetail[]) => {
    if (_.isNil(channels)) {
      // this.setState({ hasCreatedByMkpChIds: [] })
      this._setCurrentFilter({ hasCreatedByMkpChIds: [] })
      return
    }
    const hasCreatedByMkpChIds = []

    channels.forEach((ch: IMKPChannelDetail) => {
      if (ch.is_active) {
        hasCreatedByMkpChIds.push(ch.id)
      }
    })

    // this.setState({ hasCreatedByMkpChIds })
    this._setCurrentFilter({ hasCreatedByMkpChIds })
  }

  _renderCollapsibleContentWrapper = (content) => (
    <VStack w='full' p='2' borderBottomRadius='lg'>
      {content}
    </VStack>
  )

  _renderCollapsibleCard = ({ key, title, renderContent }) => {
    let isExpanded = true

    if (key === 'sort' && !_.isBoolean(this.state[`isExpanded_${key}`])) {
      isExpanded = false
    }

    if (key === 'price' && !_.isBoolean(this.state[`isExpanded_${key}`])) {
      isExpanded = false
    }

    if (_.isBoolean(this.state[`isExpanded_${key}`])) {
      isExpanded = this.state[`isExpanded_${key}`]
    }

    // const isExpanded = _.isBoolean(this.state[`isExpanded_${key}`]) ? this.state[`isExpanded_${key}`] : true
    const colorScheme = 'gray'
    const styled = {
      borderWidth: '1',
      bg: `${colorScheme}.100`,
      borderLeftColor: `${colorScheme}.400`,
      borderTopColor: `${colorScheme}.400`,
      borderRightColor: `${colorScheme}.400`,
      borderBottomColor: isExpanded ? `${colorScheme}.300` : `${colorScheme}.400`,
      borderTopLeftRadius: 'lg',
      borderTopRightRadius: 'lg',
      borderBottomLeftRadius: 'lg',
      borderBottomRightRadius: 'lg',
    }

    if (isExpanded) {
      styled.borderBottomLeftRadius = 'none'
      styled.borderBottomRightRadius = 'none'
    }

    return (
      <CollapsableCard
        disabledAnimation
        // disabled={props.disabled}
        expanded={isExpanded}
        onPressToggle={(nextExpanded) => {
          const nweState = {}
          nweState[`isExpanded_${key}`] = nextExpanded
          this.setState(nweState)
        }}
        contentContainerStyle={{
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderBottomWidth: 1,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          borderColor: '#a1a1aa',
          overflow: 'hidden',
        }}
        renderContent={renderContent}>
        <HStack h='44px' w='full' p='2' alignItems='center' {...styled}>
          <HStack flex={1}>
            <XText variant='inactive' bold>
              {title}
            </XText>
          </HStack>

          <HStack w='38px' justifyContent='flex-end' alignItems='center'>
            <XIcon
              variant='inactive'
              size='xs'
              name={isExpanded ? 'arrow-collapse-up' : 'arrow-expand-down'}
              family='MaterialCommunityIcons'
            />
          </HStack>
        </HStack>
      </CollapsableCard>
    )
  }

  _renderGroupSortFilterContent = () => {
    const {
      sortTypeIndex = 0,
      sortByIndex = 2,
      isProductTemplateFilter = false,
      // showPtCreatedAt,
      // showPpCreatedAt,
    } = this._getCurrentFilter()

    const sortByIndexOptions = isProductTemplateFilter ? CONS.PRODUCT_LIST_SORT_BY_OPTIONS : CONS.PRODUCT_VARIANT_LIST_SORT_BY_OPTIONS
    let sortTypeOptions = CONS.PRODUCT_LIST_SORT_TYPE_OPTIONS

    if (_.includes(['name', 'pt_name', 'pp_name'], sortByIndexOptions[sortByIndex].value)) {
      sortTypeOptions = CONS.PRODUCT_LIST_SORT_TYPE_OPTIONS_BY_NAME
    }

    if (
      _.includes(
        [
          'created_at',
          'pt_created_at',
          'pp_created_at',
          'updated_at',
          'pt_updated_at',
          'pp_updated_at',
          'qty_updated_at',
          'available_qty_updated_at',
        ],
        sortByIndexOptions[sortByIndex].value
      )
    ) {
      sortTypeOptions = CONS.PRODUCT_LIST_SORT_TYPE_OPTIONS
    }

    if (_.includes(['qty', 'available_qty', 'to_ship_qty'], sortByIndexOptions[sortByIndex].value)) {
      sortTypeOptions = CONS.PRODUCT_LIST_SORT_TYPE_OPTIONS_BY_QTY
    }

    return (
      <VStack w='full' p='2' space='1' flexWrap='wrap'>
        <VStack w='full'>
          <XText variant='inactive'>เรียงจาก...</XText>
          <XSegment<IProductListSortType>
            // initialIndex={editingSortTypeIndex}
            // segmentContainerStyle={{ minWidth: 200 }}
            // title='เรียงจาก...'
            selectedIndex={sortTypeIndex}
            onSegmentChange={(opt, idx) => this._setCurrentFilter({ sortTypeIndex: idx })}
            // options={sortByIndex === 0 ? CONS.PRODUCT_LIST_SORT_TYPE_OPTIONS_BY_NAME : CONS.PRODUCT_LIST_SORT_TYPE_OPTIONS}
            options={sortTypeOptions}
          />
        </VStack>

        <VStack w='full' pt='2'>
          <XText variant='inactive'>เรียงโดย...</XText>
          {/* <XRadioList<IXSegmentOption<IProductListSortBy> | IXSegmentOption<IProductVariantListSortBy>>
            // title='เรียงโดย...'
            selectedIndex={sortByIndex}
            // options={CONS.PRODUCT_VARIANT_LIST_SORT_BY_OPTIONS}
            options={sortByIndexOptions}
            onRadioChange={(opt, idx) => {
              // const newFilterState: Partial<IProductListFilterState> = { sortByIndex: idx }
              // if (opt.value === 'qty_updated_at') {
              //   newFilterState.showQtyUpdatedAt = true
              // } else if (opt.value === 'available_qty_updated_at') {
              //   newFilterState.showAvailableQtyUpdatedAt = true
              // }
              // this._setCurrentFilter(newFilterState)
              this._setCurrentFilter({ sortByIndex: idx })
            }}
          /> */}
          {sortByIndexOptions.map(this._renderSortOptionItem)}
        </VStack>
      </VStack>
    )
  }

  _renderSortOptionItem = (opt: IXSegmentOption<IProductListSortBy> | IXSegmentOption<IProductVariantListSortBy>, index: number) => {
    const { sortByIndex } = this._getCurrentFilter()
    const isChecked = sortByIndex === index

    let renderLabel

    if (opt.value === 'qty_updated_at') {
      renderLabel = ({ disabled, checked }) => (
        <XText variant={disabled || !checked ? 'inactive' : 'active'}>
          {p.op.t('qty')}: การเคลื่อนไหว
          <XText variant={disabled || !checked ? 'inactive' : 'active'} bold>
            ล่าสุด
          </XText>
        </XText>
      )
    }

    if (opt.value === 'available_qty_updated_at') {
      renderLabel = ({ disabled, checked }) => (
        <XText variant={disabled || !checked ? 'inactive' : 'active'}>
          {p.op.t('available_qty')}: การเคลื่อนไหว
          <XText variant={disabled || !checked ? 'inactive' : 'active'} bold>
            ล่าสุด
          </XText>
        </XText>
      )
    }

    return (
      <HStack w='full' key={opt.key} alignItems='center'>
        <XRadio
          checked={isChecked}
          label={opt.label}
          onPress={() => {
            this._setCurrentFilter({ sortByIndex: index })
          }}
          renderLabel={renderLabel}
        />
      </HStack>
    )
  }

  _renderStockQtyOptionItem = (option, index) => {
    const { stockQtySelectedOptionKey } = this._getCurrentFilter()
    const isChecked = stockQtySelectedOptionKey === option.key
    return (
      // @ts-ignore
      <XCheckableItem
        key={option.key}
        label={option.label}
        checked={isChecked}
        onPress={() => this._setCurrentFilter({ stockQtySelectedOptionKey: option.key })}
      />
    )
  }

  _renderStockAvailableQtyOptionItem = (option, index) => {
    const { stockAvailableQtySelectedOptionKey } = this._getCurrentFilter()
    const isChecked = stockAvailableQtySelectedOptionKey === option.key
    return (
      <XCheckableItem
        key={option.key}
        label={option.label}
        checked={isChecked}
        // @ts-ignore
        onPress={() => this._setCurrentFilter({ stockAvailableQtySelectedOptionKey: option.key })}
      />
    )
  }

  _renderStockQtyToShipOptionItem = (option, index) => {
    const { stockQtyToShipSelectedOptionKey } = this._getCurrentFilter()
    const isChecked = stockQtyToShipSelectedOptionKey === option.key
    return (
      <XCheckableItem
        key={option.key}
        label={option.label}
        checked={isChecked}
        // @ts-ignore
        onPress={() => this._setCurrentFilter({ stockQtyToShipSelectedOptionKey: option.key })}
      />
    )
  }

  _adjustOnEndEditingStockQtyValueGreaterThan = () => {
    const { stockQtyValueGreaterThan, stockQtyValueLessThanOrEqual } = this._getCurrentFilter()

    if (parseInt(stockQtyValueGreaterThan) > parseInt(stockQtyValueLessThanOrEqual)) {
      this._setCurrentFilter({ stockQtyValueLessThanOrEqual: (parseInt(stockQtyValueGreaterThan) + 1).toString() })
    }
  }

  _adjustOnEndEditingStockQtyValueLessThanOrEqual = () => {
    const { stockQtyValueGreaterThan, stockQtyValueLessThanOrEqual } = this._getCurrentFilter()

    if (parseInt(stockQtyValueGreaterThan) > parseInt(stockQtyValueLessThanOrEqual)) {
      this._setCurrentFilter({ stockQtyValueGreaterThan: (parseInt(stockQtyValueLessThanOrEqual) - 1).toString() })
    }
  }

  _adjustOnEndEditingStockAvailableQtyValueGreaterThan = () => {
    const { stockAvailableQtyValueGreaterThan, stockAvailableQtyValueLessThanOrEqual } = this._getCurrentFilter()

    if (parseInt(stockAvailableQtyValueGreaterThan) > parseInt(stockAvailableQtyValueLessThanOrEqual)) {
      this._setCurrentFilter({ stockAvailableQtyValueLessThanOrEqual: (parseInt(stockAvailableQtyValueGreaterThan) + 1).toString() })
    }
  }

  _adjustOnEndEditingStockAvailableQtyValueLessThanOrEqual = () => {
    const { stockAvailableQtyValueGreaterThan, stockAvailableQtyValueLessThanOrEqual } = this._getCurrentFilter()

    if (parseInt(stockAvailableQtyValueGreaterThan) > parseInt(stockAvailableQtyValueLessThanOrEqual)) {
      this._setCurrentFilter({ stockAvailableQtyValueGreaterThan: (parseInt(stockAvailableQtyValueLessThanOrEqual) - 1).toString() })
    }
  }

  _adjustOnEndEditingStockQtyToShipValueGreaterOrEqual = () => {
    const { stockQtyToShipValueGreaterOrEqual, stockQtyToShipValueLessThanOrEqual } = this._getCurrentFilter()

    if (parseInt(stockQtyToShipValueGreaterOrEqual) > parseInt(stockQtyToShipValueLessThanOrEqual)) {
      this._setCurrentFilter({ stockQtyToShipValueLessThanOrEqual: (parseInt(stockQtyToShipValueGreaterOrEqual) + 1).toString() })
    }
  }

  _adjustOnEndEditingStockQtyToShipValueLessThanOrEqual = () => {
    const { stockQtyToShipValueGreaterOrEqual, stockQtyToShipValueLessThanOrEqual } = this._getCurrentFilter()

    if (parseInt(stockQtyToShipValueGreaterOrEqual) > parseInt(stockQtyToShipValueLessThanOrEqual)) {
      this._setCurrentFilter({ stockQtyToShipValueGreaterOrEqual: (parseInt(stockQtyToShipValueLessThanOrEqual) - 1).toString() })
    }
  }

  _renderFilterStockQty = () => {
    const {
      stockQtyEnabled = false,
      stockQtySelectedOptionKey,

      stockQtyNotMoveEnabled = false,
      stockQtyNotMoveMoreThan = 0,
      stockQtyValueEqual,
      stockQtyValueGreaterThan,
      stockQtyValueLessThanOrEqual,
    } = this._getCurrentFilter()

    const isQtyPermissionGranted = this._isPermProductQtyViewable()

    return (
      <VStack w='full' px='2' space='1.5' flexWrap='wrap'>
        <HStack w='full' alignItems='center'>
          <XCheckbox
            textStrikeThrough={!isQtyPermissionGranted}
            disabled={!isQtyPermissionGranted}
            checked={stockQtyEnabled}
            onPress={() =>
              this._setCurrentFilter({
                stockQtyEnabled: !stockQtyEnabled,
                stockQtySelectedOptionKey: stockQtyEnabled ? null : CONS.PRODUCT_LIST_FILTER_STOCK_QTY_OPTIONS[0].key,
                stockQtyNotMoveEnabled: false,
                stockQtyNotMoveMoreThan: '0',
                stockQtyValueEqual: '0',
                stockQtyValueGreaterThan: '0',
                stockQtyValueLessThanOrEqual: '10',
              })
            }
            label={`${p.op.t('qty')}`}
          />
          {!isQtyPermissionGranted ? <HelpButton title={p.op.t('qty')} message={`คุณไม่มีสิทธิ์ในการดูจำนวน${p.op.t('qty')}`} /> : null}
        </HStack>

        {stockQtyEnabled && (
          <VStack w='full' pl='6' pb='1.5' space='1.5'>
            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.PRODUCT_LIST_FILTER_STOCK_QTY_OPTIONS.map(this._renderStockQtyOptionItem)}
            </HStack>

            {stockQtySelectedOptionKey === STOCK_ALMOST_EMPTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน น้อยกว่าหรือเท่ากับ</XText>

                <XInput
                  h='34px'
                  isInteger
                  isNegativeValueAllowed
                  textAlign='right'
                  w='100px'
                  value={stockQtyValueLessThanOrEqual.toString()}
                  onChangeText={(value) => this._setCurrentFilter({ stockQtyValueLessThanOrEqual: value })}
                  maxLength={12}
                />
              </HStack>
            )}

            {stockQtySelectedOptionKey === STOCK_EMPTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวนเท่ากับ 0</XText>

                {/* <XInput
                  h='34px'
                  isInteger
                  isNegativeValueAllowed
                  textAlign='right'
                  w='100px'
                  value={stockQtyValueEqual.toString()}
                  onChangeText={(value) => this._setCurrentFilter({ stockQtyValueEqual: value })}
                  maxLength={12}
                /> */}
              </HStack>
            )}

            {stockQtySelectedOptionKey === STOCK_DEAD_STOCK && (
              <VStack w='full'>
                <HStack w='full' alignItems='center' space='1'>
                  <XText>จำนวน มากกว่า</XText>

                  <XInput
                    h='34px'
                    isInteger
                    isNegativeValueAllowed
                    textAlign='right'
                    w='100px'
                    value={stockQtyValueGreaterThan.toString()}
                    onChangeText={(value) => this._setCurrentFilter({ stockQtyValueGreaterThan: value })}
                    maxLength={12}
                  />
                </HStack>

                {/* <HStack w='full' alignItems='center' space='1'>
                  <XCheckbox
                    checked={stockQtyNotMoveEnabled}
                    onPress={() =>
                      this._setCurrentFilter({
                        stockQtyNotMoveEnabled: !stockQtyNotMoveEnabled,
                        stockQtyNotMoveMoreThan: stockQtyNotMoveEnabled ? 0 : 180,
                      })
                    }
                  />
                  <XText>ไม่มีการเคลื่อนไหวมากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    disabled={!stockQtyNotMoveEnabled}
                    isDisabled={!stockQtyNotMoveEnabled}
                    value={stockQtyNotMoveMoreThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockQtyNotMoveMoreThan: value })}
                  />
                  <XText>วัน</XText>
                </HStack> */}
              </VStack>
            )}

            {stockQtySelectedOptionKey === STOCK_CUSTOM && (
              <VStack w='full'>
                <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
                  <HStack alignItems='center' space='1' pb='1.5'>
                    <XText>จำนวน มากกว่า</XText>

                    <XInput
                      h='34px'
                      isInteger
                      isNegativeValueAllowed
                      textAlign='right'
                      w='100px'
                      value={stockQtyValueGreaterThan.toString()}
                      onChangeText={(value) => this._setCurrentFilter({ stockQtyValueGreaterThan: value })}
                      maxLength={12}
                      onBlur={this._adjustOnEndEditingStockQtyValueGreaterThan}
                      onEndEditing={this._adjustOnEndEditingStockQtyValueGreaterThan}
                      onSubmitEditing={this._adjustOnEndEditingStockQtyValueGreaterThan}
                    />
                  </HStack>

                  <HStack alignItems='center' space='1' pb='1.5'>
                    <XText>น้อยกว่าหรือเท่ากับ</XText>

                    <XInput
                      h='34px'
                      isInteger
                      isNegativeValueAllowed
                      textAlign='right'
                      w='100px'
                      value={stockQtyValueLessThanOrEqual.toString()}
                      onChangeText={(value) => this._setCurrentFilter({ stockQtyValueLessThanOrEqual: value })}
                      maxLength={12}
                      onBlur={this._adjustOnEndEditingStockQtyValueLessThanOrEqual}
                      onEndEditing={this._adjustOnEndEditingStockQtyValueLessThanOrEqual}
                      onSubmitEditing={this._adjustOnEndEditingStockQtyValueLessThanOrEqual}
                    />
                  </HStack>
                </HStack>

                {/* <HStack w='full' alignItems='center' space='1'>
                  <XCheckbox
                    checked={stockQtyNotMoveEnabled}
                    onPress={() =>
                      this._setCurrentFilter({
                        stockQtyNotMoveEnabled: !stockQtyNotMoveEnabled,
                        stockQtyNotMoveMoreThan: stockQtyNotMoveEnabled ? 0 : 30,
                      })
                    }
                  />
                  <XText>ไม่มีการเคลื่อนไหวมากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    disabled={!stockQtyNotMoveEnabled}
                    isDisabled={!stockQtyNotMoveEnabled}
                    value={stockQtyNotMoveMoreThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockQtyNotMoveMoreThan: value })}
                  />
                  <XText>วัน</XText>
                </HStack> */}
              </VStack>
            )}
          </VStack>
        )}
      </VStack>
    )
  }

  _renderStockQtyUpdatedAtOptionItem = (option, index) => {
    const { stockQtyUpdatedAtOptionKey } = this._getCurrentFilter()
    const isChecked = stockQtyUpdatedAtOptionKey === option.key

    const newFilter: Partial<IProductListFilterState> = {
      stockQtyUpdatedAtOptionKey: option.key,
      // qtyUpdatedAtGe: null,
      // qtyUpdatedAtLe: null,
    }

    // if (stockQtyUpdatedAtOptionKey === 'custom') {
    //   newFilter.qtyUpdatedAtGe = dayjs().subtract(7, 'day').startOf('day').format(CONS.SERVER_DATETIME_FORMAT)
    //   newFilter.qtyUpdatedAtLe = dayjs().format(CONS.SERVER_DATETIME_FORMAT)
    // }

    return <XCheckableItem key={option.key} label={option.label} checked={isChecked} onPress={() => this._setCurrentFilter(newFilter)} />
  }

  _renderFilterStockQtyUpdatedAt = () => {
    const {
      stockQtyUpdatedAtEnabled = false,
      stockQtyUpdatedAtOptionKey,

      stockQtyUpdatedAtCustomOperatorKey = 'and',
      qtyUpdatedAtLe,
      qtyUpdatedAtGe,

      customQtyUpdatedAtGeEnabled,
      customQtyUpdatedAtLeEnabled,

      showQtyUpdatedAt,
    } = this._getCurrentFilter()

    const isQtyPermissionGranted = this._isPermProductQtyViewable()

    return (
      <VStack w='full' px='2' space='1.5' flexWrap='wrap'>
        <HStack w='full' alignItems='center'>
          <XCheckbox
            textStrikeThrough={!isQtyPermissionGranted}
            disabled={!isQtyPermissionGranted}
            checked={stockQtyUpdatedAtEnabled}
            onPress={() =>
              this._setCurrentFilter({
                stockQtyUpdatedAtEnabled: !stockQtyUpdatedAtEnabled,
                // stockQtyUpdatedAtKey: stockQtyUpdatedAtEnabled ? null : 'move',
                stockQtyUpdatedAtOptionKey: 'today',
                stockQtyUpdatedAtCustomOperatorKey: 'and',
                qtyUpdatedAtGe: dayjs().subtract(7, 'day').startOf('day').format(CONS.SERVER_DATETIME_FORMAT),
                qtyUpdatedAtLe: dayjs().format(CONS.SERVER_DATETIME_FORMAT),
                showQtyUpdatedAt: !stockQtyUpdatedAtEnabled,
              })
            }
            renderLabel={({ disabled, checked }) => (
              <XText variant={disabled || !checked ? 'inactive' : 'active'} strikeThrough={!isQtyPermissionGranted}>
                {p.op.t('qty')}: การเคลื่อนไหว
                <XText variant={disabled || !checked ? 'inactive' : 'active'} bold strikeThrough={!isQtyPermissionGranted}>
                  ล่าสุด
                </XText>
              </XText>
            )}
          />

          {!isQtyPermissionGranted ? <HelpButton title={p.op.t('qty')} message={`คุณไม่มีสิทธิ์ในการดูจำนวน${p.op.t('qty')}`} /> : null}
        </HStack>

        {stockQtyUpdatedAtEnabled && (
          <VStack w='full' pl='6' pb='1.5' space='1.5'>
            {/* <HStack w='full' space='1.5' flexWrap='wrap'>
              <XSegment
                options={[
                  { key: 'move', label: 'มีการเคลื่อนไหว' },
                  { key: 'not_move', label: 'ไม่มีการเคลื่อนไหว' },
                ]}
                // selectedIndex={stockQtyUpdatedAtKey === 'move' ? 0 : 1}
                // onSegmentChange={(opt, idx) => this._setCurrentFilter({ stockQtyUpdatedAtKey: opt.key })}
              />
            </HStack> */}

            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.STOCK_FILTER_QTY_UPDATED_AT_OPTIONS.map(this._renderStockQtyUpdatedAtOptionItem)}
            </HStack>

            {/* {stockQtyUpdatedAtOptionKey === 'custom' && (
              <HStack w='full' space='1.5' flexWrap='wrap'>
                <XSegment
                  options={[
                    { key: 'and', label: 'อยู่ในช่วงเวลา', value: 0 },
                    { key: 'or', label: 'ไม่อยู่ในช่วงเวลา', value: 1 },
                  ]}
                  selectedIndex={stockQtyUpdatedAtCustomOperatorKey === 'and' ? 0 : 1}
                  onSegmentChange={(opt, idx) => this._setCurrentFilter({ stockQtyUpdatedAtCustomOperatorKey: opt.key })}
                />
              </HStack>
            )} */}

            {stockQtyUpdatedAtOptionKey === 'custom' && qtyUpdatedAtGe && qtyUpdatedAtLe && (
              <VStack w='full' space='1.5'>
                <HStack w='full' alignItems='center' space='1'>
                  <HStack w='140px' alignItems='center'>
                    <XCheckbox
                      checked={customQtyUpdatedAtGeEnabled}
                      onPress={() => this._setCurrentFilter({ customQtyUpdatedAtGeEnabled: !customQtyUpdatedAtGeEnabled })}
                      label='มากกว่าหรือเท่ากับ'
                    />
                  </HStack>

                  <HStack w='180px' alignItems='center'>
                    {customQtyUpdatedAtGeEnabled ? (
                      <XDateTimeSinglePicker
                        showTime
                        selectedDate={dayjs(qtyUpdatedAtGe, CONS.SERVER_DATETIME_FORMAT).toDate()}
                        onChangeDate={(newDate) => {
                          this._setCurrentFilter({ qtyUpdatedAtGe: dayjs(newDate).format(CONS.SERVER_DATETIME_FORMAT) })
                        }}
                      />
                    ) : (
                      <XInput
                        h='34px'
                        w='full'
                        disabled
                        isDisabled
                        value={`${dayjs(qtyUpdatedAtGe).format(CONS.DISPLAY_DATETIME_FORMAT)} น.`}
                      />
                    )}
                  </HStack>
                </HStack>
                {customQtyUpdatedAtGeEnabled && customQtyUpdatedAtLeEnabled && (
                  <HStack w='full' alignItems='center' space='1'>
                    <VStack w='150px'>
                      <XSegment
                        options={[
                          { key: 'and', label: 'และ', value: 0 },
                          { key: 'or', label: 'หรือ', value: 1 },
                        ]}
                        selectedIndex={stockQtyUpdatedAtCustomOperatorKey === 'and' ? 0 : 1}
                        onSegmentChange={(opt, idx) => this._setCurrentFilter({ stockQtyUpdatedAtCustomOperatorKey: opt.key })}
                      />
                    </VStack>
                  </HStack>
                )}
                <HStack w='full' alignItems='center' space='1'>
                  <HStack w='140px' alignItems='center'>
                    <XCheckbox
                      checked={customQtyUpdatedAtLeEnabled}
                      onPress={() => this._setCurrentFilter({ customQtyUpdatedAtLeEnabled: !customQtyUpdatedAtLeEnabled })}
                      label='น้อยกว่าหรือเท่ากับ'
                    />
                  </HStack>

                  <HStack w='180px' alignItems='center'>
                    {customQtyUpdatedAtLeEnabled ? (
                      <XDateTimeSinglePicker
                        showTime
                        selectedDate={dayjs(qtyUpdatedAtLe, CONS.SERVER_DATETIME_FORMAT).toDate()}
                        onChangeDate={(newDate) => {
                          this._setCurrentFilter({ qtyUpdatedAtLe: dayjs(newDate).format(CONS.SERVER_DATETIME_FORMAT) })
                        }}
                      />
                    ) : (
                      <XInput
                        h='34px'
                        w='full'
                        disabled
                        isDisabled
                        value={`${dayjs(qtyUpdatedAtLe).format(CONS.DISPLAY_DATETIME_FORMAT)} น.`}
                      />
                    )}
                  </HStack>
                </HStack>
              </VStack>
            )}
          </VStack>
        )}

        <HStack w='full' alignItems='center'>
          <XCheckbox
            textStrikeThrough={!isQtyPermissionGranted}
            disabled={!isQtyPermissionGranted}
            checked={showQtyUpdatedAt}
            onPress={() => this._setCurrentFilter({ showQtyUpdatedAt: !showQtyUpdatedAt })}
            renderLabel={({ disabled, checked }) => (
              <XText variant={disabled || !checked ? 'inactive' : 'active'} strikeThrough={!isQtyPermissionGranted}>
                {p.op.t('qty')}: แสดงวันที่การเคลื่อนไหว
                <XText variant={disabled || !checked ? 'inactive' : 'active'} bold strikeThrough={!isQtyPermissionGranted}>
                  ล่าสุด
                </XText>
              </XText>
            )}
          />
          {!isQtyPermissionGranted ? <HelpButton title={p.op.t('qty')} message={`คุณไม่มีสิทธิ์ในการดูจำนวน${p.op.t('qty')}`} /> : null}
        </HStack>
      </VStack>
    )
  }

  _renderFilterStockAvailableQty = () => {
    const {
      stockAvailableQtyEnabled = false,
      stockAvailableQtySelectedOptionKey,

      stockAvailableQtyNotMoveEnabled = false,
      stockAvailableQtyNotMoveMoreThan = 0,
      stockAvailableQtyValueEqual,
      stockAvailableQtyValueGreaterThan,
      stockAvailableQtyValueLessThanOrEqual,
      stockAvailableQtyValueLessThan,
    } = this._getCurrentFilter()

    const isQtyPermissionGranted = this._isPermProductQtyViewable()

    return (
      <VStack w='full' px='2' space='1.5' flexWrap='wrap'>
        <HStack w='full' alignItems='center'>
          <XCheckbox
            disabled={!isQtyPermissionGranted}
            checked={stockAvailableQtyEnabled}
            onPress={() =>
              this._setCurrentFilter({
                stockAvailableQtyEnabled: !stockAvailableQtyEnabled,
                stockAvailableQtySelectedOptionKey: stockAvailableQtyEnabled
                  ? null
                  : CONS.PRODUCT_LIST_FILTER_STOCK_AVAILABLE_QTY_OPTIONS[0].key,
                stockAvailableQtyNotMoveEnabled: false,
                stockAvailableQtyNotMoveMoreThan: '0',
                stockAvailableQtyValueEqual: '0',
                stockAvailableQtyValueGreaterThan: '0',
                stockAvailableQtyValueLessThanOrEqual: '10',
                stockAvailableQtyValueLessThan: '0',
              })
            }
            label={`${p.op.t('available_qty')}`}
            textStrikeThrough={!isQtyPermissionGranted}
          />
          {!isQtyPermissionGranted ? (
            <HelpButton title={p.op.t('available_qty')} message={`คุณไม่มีสิทธิ์ในการดูจำนวน${p.op.t('available_qty')}`} />
          ) : null}
        </HStack>

        {stockAvailableQtyEnabled && (
          <VStack w='full' pl='6' pb='1.5' space='1.5'>
            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.PRODUCT_LIST_FILTER_STOCK_AVAILABLE_QTY_OPTIONS.map(this._renderStockAvailableQtyOptionItem)}
            </HStack>

            {stockAvailableQtySelectedOptionKey === STOCK_ALMOST_EMPTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน น้อยกว่าหรือเท่ากับ</XText>
                <XInput
                  h='34px'
                  isInteger
                  isNegativeValueAllowed
                  textAlign='right'
                  w='100px'
                  value={stockAvailableQtyValueLessThanOrEqual.toString()}
                  onChangeText={(value) => this._setCurrentFilter({ stockAvailableQtyValueLessThanOrEqual: value })}
                  maxLength={12}
                />
              </HStack>
            )}

            {stockAvailableQtySelectedOptionKey === STOCK_EMPTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวนเท่ากับ 0</XText>
                {/* <XInput
                  h='34px'
                  isInteger
                  isNegativeValueAllowed
                  textAlign='right'
                  w='100px'
                  value={stockAvailableQtyValueEqual.toString()}
                  onChangeText={(value) => this._setCurrentFilter({ stockAvailableQtyValueEqual: value })}
                  maxLength={12}
                /> */}
              </HStack>
            )}

            {stockAvailableQtySelectedOptionKey === STOCK_DEAD_STOCK && (
              <VStack w='full'>
                <HStack w='full' alignItems='center' space='1'>
                  <XText>จำนวน มากกว่า</XText>

                  <XInput
                    h='34px'
                    isInteger
                    isNegativeValueAllowed
                    textAlign='right'
                    w='100px'
                    value={stockAvailableQtyValueGreaterThan.toString()}
                    onChangeText={(value) => this._setCurrentFilter({ stockAvailableQtyValueGreaterThan: value })}
                    maxLength={12}
                  />
                </HStack>

                {/* <HStack w='full' alignItems='center' space='1'>
                  <XCheckbox
                    checked={stockAvailableQtyNotMoveEnabled}
                    onPress={() =>
                      this._setCurrentFilter({
                        stockAvailableQtyNotMoveEnabled: !stockAvailableQtyNotMoveEnabled,
                        stockAvailableQtyNotMoveMoreThan: stockAvailableQtyNotMoveEnabled ? 0 : 180,
                      })
                    }
                  />
                  <XText>ไม่มีการเคลื่อนไหวมากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    disabled={!stockAvailableQtyNotMoveEnabled}
                    isDisabled={!stockAvailableQtyNotMoveEnabled}
                    value={stockAvailableQtyNotMoveMoreThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyNotMoveMoreThan: value })}
                  />
                  <XText>วัน</XText>
                </HStack> */}
              </VStack>
            )}

            {stockAvailableQtySelectedOptionKey === STOCK_EXCEEDED_QTY && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน น้อยกว่า</XText>

                <XInput
                  h='34px'
                  isInteger
                  isNegativeValueAllowed
                  textAlign='right'
                  w='100px'
                  value={stockAvailableQtyValueLessThan.toString()}
                  onChangeText={(value) => this._setCurrentFilter({ stockAvailableQtyValueLessThan: value })}
                  maxLength={12}
                />
              </HStack>
            )}

            {stockAvailableQtySelectedOptionKey === STOCK_CUSTOM && (
              <VStack w='full' space='1.5'>
                <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
                  <HStack alignItems='center' space='1' pb='1.5'>
                    <XText>จำนวน มากกว่า</XText>

                    <XInput
                      h='34px'
                      isInteger
                      isNegativeValueAllowed
                      textAlign='right'
                      w='100px'
                      value={stockAvailableQtyValueGreaterThan.toString()}
                      onChangeText={(value) => this._setCurrentFilter({ stockAvailableQtyValueGreaterThan: value })}
                      maxLength={12}
                      onBlur={this._adjustOnEndEditingStockAvailableQtyValueGreaterThan}
                      onEndEditing={this._adjustOnEndEditingStockAvailableQtyValueGreaterThan}
                      onSubmitEditing={this._adjustOnEndEditingStockAvailableQtyValueGreaterThan}
                    />
                  </HStack>

                  <HStack alignItems='center' space='1' pb='1.5'>
                    <XText>และ น้อยกว่าหรือเท่ากับ</XText>

                    <XInput
                      h='34px'
                      isInteger
                      isNegativeValueAllowed
                      textAlign='right'
                      w='100px'
                      value={stockAvailableQtyValueLessThanOrEqual.toString()}
                      onChangeText={(value) => this._setCurrentFilter({ stockAvailableQtyValueLessThanOrEqual: value })}
                      maxLength={12}
                      onBlur={this._adjustOnEndEditingStockAvailableQtyValueLessThanOrEqual}
                      onEndEditing={this._adjustOnEndEditingStockAvailableQtyValueLessThanOrEqual}
                      onSubmitEditing={this._adjustOnEndEditingStockAvailableQtyValueLessThanOrEqual}
                    />
                  </HStack>
                </HStack>

                {/* <HStack w='full' alignItems='center' space='1'>
                  <XCheckbox
                    checked={stockAvailableQtyNotMoveEnabled}
                    onPress={() =>
                      this._setCurrentFilter({
                        stockAvailableQtyNotMoveEnabled: !stockAvailableQtyNotMoveEnabled,
                        stockAvailableQtyNotMoveMoreThan: stockAvailableQtyNotMoveEnabled ? 0 : 30,
                      })
                    }
                  />
                  <XText>ไม่มีการเคลื่อนไหวมากกว่า</XText>
                  <XNumericInput
                    w='40px'
                    disabled={!stockAvailableQtyNotMoveEnabled}
                    isDisabled={!stockAvailableQtyNotMoveEnabled}
                    value={stockAvailableQtyNotMoveMoreThan}
                    InputLeftElement={null}
                    InputRightElement={null}
                    onChangeValue={(value) => this._setCurrentFilter({ stockAvailableQtyNotMoveMoreThan: value })}
                  />
                  <XText>วัน</XText>
                </HStack> */}
              </VStack>
            )}
          </VStack>
        )}
      </VStack>
    )
  }

  _renderStockAvailableQtyUpdatedAtOptionItem = (option, index) => {
    const { stockAvailableQtyUpdatedAtOptionKey } = this._getCurrentFilter()
    const isChecked = stockAvailableQtyUpdatedAtOptionKey === option.key

    return (
      <XCheckableItem
        key={option.key}
        label={option.label}
        checked={isChecked}
        onPress={() => this._setCurrentFilter({ stockAvailableQtyUpdatedAtOptionKey: option.key })}
      />
    )
  }

  _renderFilterStockAvailableQtyUpdatedAt = () => {
    const {
      stockAvailableQtyUpdatedAtEnabled = false,
      stockAvailableQtyUpdatedAtOptionKey,

      stockAvailableQtyUpdatedAtCustomOperatorKey = 'and',
      availableQtyUpdatedAtLe,
      availableQtyUpdatedAtGe,

      customAvailableQtyUpdatedAtGeEnabled,
      customAvailableQtyUpdatedAtLeEnabled,

      showAvailableQtyUpdatedAt,
    } = this._getCurrentFilter()

    const isQtyPermissionGranted = this._isPermProductQtyViewable()

    return (
      <VStack w='full' px='2' space='1.5' flexWrap='wrap'>
        <HStack w='full' alignItems='center'>
          <XCheckbox
            textStrikeThrough={!isQtyPermissionGranted}
            disabled={!isQtyPermissionGranted}
            checked={stockAvailableQtyUpdatedAtEnabled}
            onPress={() =>
              this._setCurrentFilter({
                stockAvailableQtyUpdatedAtEnabled: !stockAvailableQtyUpdatedAtEnabled,
                // stockAvailableQtyUpdatedAtKey: stockAvailableQtyUpdatedAtEnabled ? null : 'move',
                stockAvailableQtyUpdatedAtOptionKey: 'today',
                stockAvailableQtyUpdatedAtCustomOperatorKey: 'and',
                availableQtyUpdatedAtGe: dayjs().subtract(7, 'day').startOf('day').format(CONS.SERVER_DATETIME_FORMAT),
                availableQtyUpdatedAtLe: dayjs().format(CONS.SERVER_DATETIME_FORMAT),
                showAvailableQtyUpdatedAt: !stockAvailableQtyUpdatedAtEnabled,
              })
            }
            renderLabel={({ disabled, checked }) => (
              <XText variant={disabled || !checked ? 'inactive' : 'active'} strikeThrough={!isQtyPermissionGranted}>
                {p.op.t('available_qty')}: การเคลื่อนไหว
                <XText variant={disabled || !checked ? 'inactive' : 'active'} bold strikeThrough={!isQtyPermissionGranted}>
                  ล่าสุด
                </XText>
              </XText>
            )}
          />
          {!isQtyPermissionGranted ? (
            <HelpButton title={p.op.t('available_qty')} message={`คุณไม่มีสิทธิ์ในการดูจำนวน${p.op.t('available_qty')}`} />
          ) : null}
        </HStack>

        {stockAvailableQtyUpdatedAtEnabled && (
          <VStack w='full' pl='6' pb='1.5' space='1.5'>
            {/* <HStack w='full' space='1.5' flexWrap='wrap'>
              <XSegment
                options={[
                  { key: 'move', label: 'มีการเคลื่อนไหว' },
                  { key: 'not_move', label: 'ไม่มีการเคลื่อนไหว' },
                ]}
                // selectedIndex={stockAvailableQtyUpdatedAtKey === 'move' ? 0 : 1}
                // onSegmentChange={(opt, idx) => this._setCurrentFilter({ stockAvailableQtyUpdatedAtKey: opt.key })}
              />
            </HStack> */}

            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.STOCK_FILTER_QTY_UPDATED_AT_OPTIONS.map(this._renderStockAvailableQtyUpdatedAtOptionItem)}
            </HStack>

            {/* {stockAvailableQtyUpdatedAtOptionKey === 'custom' && (
              <HStack w='full' space='1.5' flexWrap='wrap'>
                <XSegment
                  options={[
                    { key: 'and', label: 'อยู่ในช่วงเวลา', value: 0 },
                    { key: 'or', label: 'ไม่อยู่ในช่วงเวลา', value: 1 },
                  ]}
                  selectedIndex={stockAvailableQtyUpdatedAtCustomOperatorKey === 'and' ? 0 : 1}
                  onSegmentChange={(opt, idx) => this._setCurrentFilter({ stockAvailableQtyUpdatedAtCustomOperatorKey: opt.key })}
                />
              </HStack>
            )} */}

            {stockAvailableQtyUpdatedAtOptionKey === 'custom' && availableQtyUpdatedAtGe && availableQtyUpdatedAtLe && (
              <VStack w='full' space='1.5'>
                <HStack w='full' alignItems='center' space='1'>
                  <HStack w='140px' alignItems='center'>
                    <XCheckbox
                      checked={customAvailableQtyUpdatedAtGeEnabled}
                      onPress={() =>
                        this._setCurrentFilter({ customAvailableQtyUpdatedAtGeEnabled: !customAvailableQtyUpdatedAtGeEnabled })
                      }
                      label='มากกว่าหรือเท่ากับ'
                    />
                  </HStack>

                  <HStack w='180px' alignItems='center'>
                    {customAvailableQtyUpdatedAtGeEnabled ? (
                      <XDateTimeSinglePicker
                        showTime
                        selectedDate={dayjs(availableQtyUpdatedAtGe, CONS.SERVER_DATETIME_FORMAT).toDate()}
                        onChangeDate={(newDate) => {
                          this._setCurrentFilter({ availableQtyUpdatedAtGe: dayjs(newDate).format(CONS.SERVER_DATETIME_FORMAT) })
                        }}
                      />
                    ) : (
                      <XInput
                        h='34px'
                        w='full'
                        disabled
                        isDisabled
                        value={`${dayjs(availableQtyUpdatedAtGe).format(CONS.DISPLAY_DATETIME_FORMAT)} น.`}
                      />
                    )}
                  </HStack>
                </HStack>

                {customAvailableQtyUpdatedAtGeEnabled && customAvailableQtyUpdatedAtLeEnabled && (
                  <HStack w='full' alignItems='center' space='1'>
                    <VStack w='150px'>
                      <XSegment
                        options={[
                          { key: 'and', label: 'และ', value: 0 },
                          { key: 'or', label: 'หรือ', value: 1 },
                        ]}
                        selectedIndex={stockAvailableQtyUpdatedAtCustomOperatorKey === 'and' ? 0 : 1}
                        onSegmentChange={(opt, idx) => this._setCurrentFilter({ stockAvailableQtyUpdatedAtCustomOperatorKey: opt.key })}
                      />
                    </VStack>
                  </HStack>
                )}

                <HStack w='full' alignItems='center' space='1'>
                  <HStack w='140px' alignItems='center'>
                    <XCheckbox
                      checked={customAvailableQtyUpdatedAtLeEnabled}
                      onPress={() =>
                        this._setCurrentFilter({ customAvailableQtyUpdatedAtLeEnabled: !customAvailableQtyUpdatedAtLeEnabled })
                      }
                      label='น้อยกว่าหรือเท่ากับ'
                    />
                  </HStack>

                  <HStack w='180px' alignItems='center'>
                    {customAvailableQtyUpdatedAtLeEnabled ? (
                      <XDateTimeSinglePicker
                        showTime
                        selectedDate={dayjs(availableQtyUpdatedAtLe, CONS.SERVER_DATETIME_FORMAT).toDate()}
                        onChangeDate={(newDate) => {
                          this._setCurrentFilter({ availableQtyUpdatedAtLe: dayjs(newDate).format(CONS.SERVER_DATETIME_FORMAT) })
                        }}
                      />
                    ) : (
                      <XInput
                        h='34px'
                        w='full'
                        disabled
                        isDisabled
                        value={`${dayjs(availableQtyUpdatedAtLe).format(CONS.DISPLAY_DATETIME_FORMAT)} น.`}
                      />
                    )}
                  </HStack>
                </HStack>

                {/* <XDateRangePicker
                  showTime
                  labels={
                    stockAvailableQtyUpdatedAtCustomOperatorKey === 'and'
                      ? { begin: 'ตั้งแต่', end: 'จนถึง' }
                      : { begin: 'ก่อนหน้า', end: 'หลังจาก' }
                  }
                  selectedDates={{
                    begin: dayjs(availableQtyUpdatedAtGe, CONS.SERVER_DATETIME_FORMAT),
                    end: dayjs(availableQtyUpdatedAtLe, CONS.SERVER_DATETIME_FORMAT),
                  }}
                  onChangeDate={(newDates) =>
                    this._setCurrentFilter({
                      availableQtyUpdatedAtGe: newDates.begin.format(CONS.SERVER_DATETIME_FORMAT),
                      availableQtyUpdatedAtLe: newDates.end.format(CONS.SERVER_DATETIME_FORMAT),
                    })
                  }
                /> */}
              </VStack>
            )}
          </VStack>
        )}

        <HStack w='full' alignItems='center'>
          <XCheckbox
            textStrikeThrough={!isQtyPermissionGranted}
            disabled={!isQtyPermissionGranted}
            checked={showAvailableQtyUpdatedAt}
            onPress={() => this._setCurrentFilter({ showAvailableQtyUpdatedAt: !showAvailableQtyUpdatedAt })}
            renderLabel={({ disabled, checked }) => (
              <XText variant={disabled || !checked ? 'inactive' : 'active'} strikeThrough={!isQtyPermissionGranted}>
                {p.op.t('available_qty')}: แสดงวันที่การเคลื่อนไหว
                <XText variant={disabled || !checked ? 'inactive' : 'active'} bold strikeThrough={!isQtyPermissionGranted}>
                  ล่าสุด
                </XText>
              </XText>
            )}
          />
          {!isQtyPermissionGranted ? (
            <HelpButton title={p.op.t('available_qty')} message={`คุณไม่มีสิทธิ์ในการดูจำนวน${p.op.t('available_qty')}`} />
          ) : null}
        </HStack>
      </VStack>
    )
  }

  _renderFilterStockQtyToShip = () => {
    const {
      stockQtyToShipEnabled = false,
      stockQtyToShipSelectedOptionKey,

      stockQtyToShipValueGreaterOrEqual,
      stockQtyToShipValueGreaterThan,
      stockQtyToShipValueEqual,
      stockQtyToShipValueLessThanOrEqual,
    } = this._getCurrentFilter()

    const isQtyPermissionGranted = this._isPermProductQtyViewable()

    return (
      <VStack w='full' px='2' space='1.5' flexWrap='wrap'>
        <HStack w='full' alignItems='center'>
          <XCheckbox
            disabled={!isQtyPermissionGranted}
            checked={stockQtyToShipEnabled}
            onPress={() =>
              this._setCurrentFilter({
                stockQtyToShipEnabled: !stockQtyToShipEnabled,
                stockQtyToShipSelectedOptionKey: stockQtyToShipEnabled ? null : CONS.PRODUCT_LIST_FILTER_STOCK_QTY_TO_SHIP_OPTIONS[0].key,
                stockQtyToShipValueGreaterThan: '0',
                stockQtyToShipValueGreaterOrEqual: '0',
                stockQtyToShipValueEqual: '0',
                stockQtyToShipValueLessThanOrEqual: '10',
              })
            }
            label='ติดจอง'
            textStrikeThrough={!isQtyPermissionGranted}
          />
          {!isQtyPermissionGranted ? <HelpButton title='ติดจอง' message='คุณไม่มีสิทธิ์ในการดูจำนวนติดจอง' /> : null}
        </HStack>

        {stockQtyToShipEnabled && (
          <VStack w='full' pl='6' pb='1.5' space='1.5'>
            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.PRODUCT_LIST_FILTER_STOCK_QTY_TO_SHIP_OPTIONS.map(this._renderStockQtyToShipOptionItem)}
            </HStack>

            {stockQtyToShipSelectedOptionKey === STOCK_HAVE_TO_SHIP && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน มากกว่า</XText>

                <XInput
                  h='34px'
                  isInteger
                  textAlign='right'
                  w='100px'
                  value={stockQtyToShipValueGreaterThan.toString()}
                  onChangeText={(value) => this._setCurrentFilter({ stockQtyToShipValueGreaterThan: value })}
                  maxLength={12}
                />
              </HStack>
            )}

            {stockQtyToShipSelectedOptionKey === STOCK_NOT_HAVE_TO_SHIP && (
              <HStack w='full' alignItems='center' space='1'>
                <XText>จำนวน เท่ากับ 0</XText>
                {/* <XNumericInput
                  w='40px'
                  value={stockQtyToShipValueEqual}
                  InputLeftElement={null}
                  InputRightElement={null}
                  onChangeValue={(value) => this._setCurrentFilter({ stockQtyToShipValueEqual: value })}
                /> */}
              </HStack>
            )}

            {stockQtyToShipSelectedOptionKey === STOCK_CUSTOM && (
              <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
                <HStack alignItems='center' space='1' pb='1.5'>
                  <XText>จำนวน มากกว่าหรือเท่ากับ</XText>
                  <XInput
                    h='34px'
                    isInteger
                    textAlign='right'
                    w='100px'
                    value={stockQtyToShipValueGreaterOrEqual.toString()}
                    onChangeText={(value) => this._setCurrentFilter({ stockQtyToShipValueGreaterOrEqual: value })}
                    maxLength={12}
                    onBlur={this._adjustOnEndEditingStockQtyToShipValueGreaterOrEqual}
                    onEndEditing={this._adjustOnEndEditingStockQtyToShipValueGreaterOrEqual}
                    onSubmitEditing={this._adjustOnEndEditingStockQtyToShipValueGreaterOrEqual}
                  />
                </HStack>

                <HStack alignItems='center' space='1' pb='1.5'>
                  <XText>และ น้อยกว่าหรือเท่ากับ</XText>

                  <XInput
                    h='34px'
                    isInteger
                    textAlign='right'
                    w='100px'
                    value={stockQtyToShipValueLessThanOrEqual.toString()}
                    onChangeText={(value) => this._setCurrentFilter({ stockQtyToShipValueLessThanOrEqual: value })}
                    maxLength={12}
                    onBlur={this._adjustOnEndEditingStockQtyToShipValueLessThanOrEqual}
                    onEndEditing={this._adjustOnEndEditingStockQtyToShipValueLessThanOrEqual}
                    onSubmitEditing={this._adjustOnEndEditingStockQtyToShipValueLessThanOrEqual}
                  />
                </HStack>
              </HStack>
            )}
          </VStack>
        )}
      </VStack>
    )
  }

  _renderGroupStockFilterContent = () => {
    // const {
    //   stockQtyEnabled = false,
    //   stockAvailableQtyEnabled = false,
    //   stockQtyToShipEnabled = false,
    //   stockQtySelectedOptionKey,
    //   stockAvailableQtySelectedOptionKey,
    //   stockQtyToShipSelectedOptionKey,

    //   stockQtyNotMoveEnabled = false,
    //   stockQtyNotMoveMoreThan = 0,
    //   stockQtyValueEqual,
    //   stockQtyValueGreaterThan,
    //   stockQtyValueLessThanOrEqual,

    //   stockAvailableQtyNotMoveEnabled = false,
    //   stockAvailableQtyNotMoveMoreThan = 0,
    //   stockAvailableQtyValueEqual,
    //   stockAvailableQtyValueGreaterThan,
    //   stockAvailableQtyValueLessThanOrEqual,
    //   stockAvailableQtyValueLessThan,

    //   stockQtyToShipValueGreaterOrEqual,
    //   stockQtyToShipValueGreaterThan,
    //   stockQtyToShipValueEqual,
    //   stockQtyToShipValueLessThanOrEqual,
    // } = this._getCurrentFilter()

    const isQtyPermissionGranted = this._isPermProductQtyViewable()

    return (
      <VStack w='full' py='1.5' space='1' flexWrap='wrap'>
        <HStack w='full' px='2'>
          <XText variant='inactive'>กรองจาก...</XText>
        </HStack>

        {this._renderFilterStockQty()}
        {this._renderFilterStockQtyUpdatedAt()}
        {this._renderFilterStockAvailableQty()}
        {this._renderFilterStockAvailableQtyUpdatedAt()}
        {this._renderFilterStockQtyToShip()}
      </VStack>
    )
  }

  _renderCostFilterOptionItem = (option, index) => {
    const { costFilterSelectedOptionKey } = this._getCurrentFilter()
    const isChecked = costFilterSelectedOptionKey === option.key
    return (
      <XCheckableItem
        key={option.key}
        label={option.label}
        checked={isChecked}
        onPress={() => this._setCurrentFilter({ costFilterSelectedOptionKey: option.key })}
      />
    )
  }

  _adjustCostFilterValueGreaterOrEqual = () => {
    const { costFilterValueGreaterOrEqual, costFilterValueLessThanOrEqual } = this._getCurrentFilter()

    if (parseFloat(costFilterValueGreaterOrEqual) > parseFloat(costFilterValueLessThanOrEqual)) {
      this._setCurrentFilter({ costFilterValueLessThanOrEqual: (parseFloat(costFilterValueGreaterOrEqual) + 1).toString() })
    }
  }

  _adjustCostFilterValueLessThanOrEqual = () => {
    const { costFilterValueGreaterOrEqual, costFilterValueLessThanOrEqual } = this._getCurrentFilter()

    if (parseFloat(costFilterValueGreaterOrEqual) > parseFloat(costFilterValueLessThanOrEqual)) {
      let newCostFilterValueGreaterOrEqual = parseFloat(costFilterValueLessThanOrEqual) - 1.0
      if (newCostFilterValueGreaterOrEqual < 0) {
        newCostFilterValueGreaterOrEqual = 0
      }
      this._setCurrentFilter({ costFilterValueGreaterOrEqual: newCostFilterValueGreaterOrEqual.toString() })
    }
  }

  _renderGroupCostFilterContent = () => {
    const {
      isCostShow,
      costFilterEnabled = false,
      costFilterSelectedOptionKey,
      costFilterValueEqual,
      costFilterValueGreaterThan,
      costFilterValueGreaterOrEqual,
      costFilterValueLessThanOrEqual,
    } = this._getCurrentFilter()
    const isCostPermissionGranted = this._isPermProductCostViewable()

    return (
      <VStack w='full' p='2' space='1' borderBottomWidth='1' borderColor='gray.200' bg='white'>
        <HStack w='full' alignItems='center'>
          <XCheckbox
            disabled={!isCostPermissionGranted}
            checked={isCostShow}
            onPress={() => this._setCurrentFilter({ isCostShow: !isCostShow })}
            label='แสดงต้นทุน'
            textStrikeThrough={!isCostPermissionGranted}
          />
          {!isCostPermissionGranted ? <HelpButton title='แสดงต้นทุน' message='คุณไม่มีสิทธิ์ในการดูต้นทุนสินค้า' /> : null}
        </HStack>

        <HStack w='full' alignItems='center'>
          <XCheckbox
            disabled={!isCostPermissionGranted}
            checked={costFilterEnabled}
            onPress={() =>
              this._setCurrentFilter({
                isCostShow: !costFilterEnabled ? true : isCostShow,
                costFilterEnabled: !costFilterEnabled,
                costFilterSelectedOptionKey: costFilterEnabled ? null : CONS.PRODUCT_LIST_FILTER_COST_FILTER_OPTIONS[0].key,
                costFilterValueEqual: '0',
                costFilterValueGreaterThan: '0',
                costFilterValueGreaterOrEqual: '0',
                costFilterValueLessThanOrEqual: '399',
              })
            }
            label='กรองตามต้นทุน'
            textStrikeThrough={!isCostPermissionGranted}
          />
          {!isCostPermissionGranted ? <HelpButton title='แสดงต้นทุน' message='คุณไม่มีสิทธิ์ในการดูต้นทุนสินค้า' /> : null}
        </HStack>

        {costFilterEnabled && (
          <VStack w='full' pl='6' pb='1.5' space='1.5'>
            <HStack w='full' space='1.5' flexWrap='wrap'>
              {CONS.PRODUCT_LIST_FILTER_COST_FILTER_OPTIONS.map(this._renderCostFilterOptionItem)}
            </HStack>
          </VStack>
        )}

        {costFilterSelectedOptionKey === COST_FILTER_EQUAL && (
          <VStack w='full' pl='6' pb='1.5' space='1.5'>
            <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
              <XText>ต้นทุน เท่ากับ</XText>

              <XInput
                h='34px'
                isMoney
                textAlign='right'
                w='100px'
                value={costFilterValueEqual.toString()}
                onChangeText={(value) => this._setCurrentFilter({ costFilterValueEqual: value })}
                maxLength={12}
              />
            </HStack>
          </VStack>
        )}

        {costFilterSelectedOptionKey === COST_FILTER_CUSTOM && (
          <VStack w='full' pl='6' space='1.5'>
            <HStack w='full' alignItems='center' space='1' flexWrap='wrap'>
              <HStack alignItems='center' space='1' pb='1.5'>
                <XText>ต้นทุน มากกว่าหรือเท่ากับ</XText>

                <XInput
                  h='34px'
                  isMoney
                  textAlign='right'
                  w='100px'
                  value={costFilterValueGreaterOrEqual.toString()}
                  onChangeText={(value) => this._setCurrentFilter({ costFilterValueGreaterOrEqual: value })}
                  maxLength={12}
                  onBlur={this._adjustCostFilterValueGreaterOrEqual}
                  onEndEditing={this._adjustCostFilterValueGreaterOrEqual}
                  onSubmitEditing={this._adjustCostFilterValueGreaterOrEqual}
                />
              </HStack>

              <HStack alignItems='center' space='1' pb='1.5'>
                <XText>และ น้อยกว่าหรือเท่ากับ</XText>

                <XInput
                  h='34px'
                  isMoney
                  textAlign='right'
                  w='100px'
                  value={costFilterValueLessThanOrEqual.toString()}
                  onChangeText={(value) => this._setCurrentFilter({ costFilterValueLessThanOrEqual: value })}
                  maxLength={12}
                  onBlur={this._adjustCostFilterValueLessThanOrEqual}
                  onEndEditing={this._adjustCostFilterValueLessThanOrEqual}
                  onSubmitEditing={this._adjustCostFilterValueLessThanOrEqual}
                />
              </HStack>
            </HStack>
          </VStack>
        )}
      </VStack>
    )
  }

  _onChangeAddStoreProductSelectorOptions = async (newOpts: IAddStoreProductSelectorOptions) => {
    // console.log('_onChangeAddStoreProductSelectorOptions newOpts => ', newOpts)
    const {
      optionChooseProductsFromIndex,
      optionSelectedSellerStoreIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsByGroupIndex,
    } = newOpts
    const { selectedStore, sellerStores } = this.props
    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])

    let shouldResetSelectedCategoryId = false

    if (_.isNumber(optionChooseProductsFromIndex) && optionChooseProductsFromIndex !== this.state.optionChooseProductsFromIndex) {
      shouldResetSelectedCategoryId = true
    }

    if (_.isNumber(optionSelectedSellerStoreIndex) && optionSelectedSellerStoreIndex !== this.state.optionSelectedSellerStoreIndex) {
      shouldResetSelectedCategoryId = true
    }

    if (optionChooseProductsFromIndex === 0) {
      const sellerStoreId = sellerStores.getIn([optionSelectedSellerStoreIndex, 'id'])
      await this._setCurrentFilter({ sellerStoreId, selectedProductGroupIds: null, selectedUserGroupId: null })
    }

    if (optionChooseProductsFromIndex === 1) {
      const newFilter = { sellerStoreId: null, selectedProductGroupIds: null, selectedUserGroupId: null }
      if (optionChooseProductsFollowingIndex === 0) {
        newFilter.selectedUserGroupId = user_groups.getIn([optionChooseProductsByGroupIndex, 'id'])
      }

      if (optionChooseProductsFollowingIndex === 1) {
        newFilter.selectedProductGroupIds = [product_groups.getIn([optionChooseProductsByGroupIndex, 'id'])]
      }
      await this._setCurrentFilter(newFilter)
    }

    if (shouldResetSelectedCategoryId) {
      await this._setCurrentFilter({ selectedCategoryId: null })
    }

    await this._setupCategoryList()
    await util.setStatePromise(this, newOpts)
  }

  _renderFilterPriceCostVisibility = () => {
    const { isPriceShow } = this._getCurrentFilter()
    const isPermissionGranted = this._isPermProductPriceViewable()

    return (
      <VStack w='full' space='1'>
        <HStack w='full' alignItems='center'>
          <XCheckbox
            disabled={!isPermissionGranted}
            checked={isPriceShow}
            onPress={() => this._setCurrentFilter({ isPriceShow: !isPriceShow })}
            label='แสดงราคา'
            textStrikeThrough={!isPermissionGranted}
          />
          {!isPermissionGranted ? <HelpButton title='แสดงราคา' message='คุณไม่มีสิทธิ์ในการดูราคาสินค้า' /> : null}
        </HStack>
      </VStack>
    )
  }

  _renderFilterUgPgSelector = () => {
    const { isPriceShow } = this._getCurrentFilter()

    if (!isPriceShow) {
      return null
    }

    const {
      optionChooseProductsByGroupIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsFromIndex,
      optionSelectedSellerStoreIndex,
    } = this.state

    return (
      <AddStoreProductSelector
        onChangeOptions={this._onChangeAddStoreProductSelectorOptions}
        optionChooseProductsByGroupIndex={optionChooseProductsByGroupIndex}
        optionChooseProductsFollowingIndex={optionChooseProductsFollowingIndex}
        optionChooseProductsFromIndex={optionChooseProductsFromIndex}
        optionSelectedSellerStoreIndex={optionSelectedSellerStoreIndex}
        forceUseOnlyMyStore
      />
    )
  }

  _renderGroupCategoryFilterContent = () => <VStack w='full'>{this._renderCategoryCard()}</VStack>

  _renderGroupPriceFilterContent = () => (
    <VStack w='full' p='2'>
      {this._renderFilterPriceCostVisibility()}
      {this._renderFilterUgPgSelector()}
    </VStack>
  )

  _renderGroupMkpFilterContent = () => (
    <VStack w='full' space='1'>
      {this._renderMkpPairedVisibility()}
      {this._renderFilterHasParent()}
      {this._renderFilterWhichSystemIn()}
    </VStack>
  )

  _renderGroupDatetimeFilterContent = () => (
    <VStack w='full'>
      {this._renderFilterUpdatedAt()}
      {this._renderFilterCreatedAt()}
      {this._renderDateTimeVisibility()}
    </VStack>
  )

  _renderCollapsibleSortCard = () => {
    const isHidden = this.isHiddenSortFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'sort',
      title: 'การเรียงสินค้า',
      renderContent: this._renderGroupSortFilterContent,
    })
  }

  _renderGroupSearchFilterContent = () => {
    const { searchText = '' } = this._getCurrentFilter()

    return (
      <VStack w='full' p='2' space='1' flexWrap='wrap'>
        <HStack w='full' space='2' bg='white' flexWrap='wrap'>
          <HStack flex={1}>
            <XInput
              h='34px'
              w='full'
              value={searchText}
              onChangeText={(value) => this._setCurrentFilter({ searchText: value })}
              maxLength={200}
              placeholder='พิมพ์เพื่อค้นหาสินค้า...'
              style={{ paddingLeft: 18, paddingRight: 18 }}
            />
            <HStack w='32px' alignItems='center' justifyContent='center' position='absolute' left='0' top='0' bottom='0'>
              <XIcon inactive name='search' />
            </HStack>
            {/* {searchText.length === 0 ? null : (
              <HStack w='32px' alignItems='center' justifyContent='center' position='absolute' right='0' top='0' bottom='0'>
                <XIconButton name='close' onPress={() => this._setCurrentFilter({ searchText: '' })} />
              </HStack>
            )} */}
          </HStack>
        </HStack>
      </VStack>
    )
  }

  _renderCollapsibleSearchCard = () => {
    const isHidden = this.isHiddenSearchFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'search',
      title: 'คำค้นหา',
      renderContent: this._renderGroupSearchFilterContent,
    })
  }

  _renderCollapsibleStockCard = () => {
    const isHidden = this.isHiddenStockFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'stock',
      title: 'จำนวนสต๊อก',
      renderContent: this._renderGroupStockFilterContent,
    })
  }

  _renderCollapsibleCostCard = () => {
    const isHidden = this.isHiddenCostFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'cost',
      title: 'ต้นทุน',
      renderContent: this._renderGroupCostFilterContent,
    })
  }

  _renderCollapsibleDatetimeCard = () => {
    const isHidden = this.isHiddenDatetimeFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'datetime',
      title: 'อัพเดท',
      renderContent: this._renderGroupDatetimeFilterContent,
    })
  }

  _renderCollapsibleMkpCard = () => {
    const isHidden = this.isHiddenMkpFilterCard()
    if (isHidden) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'mkp',
      title: 'สถานะช่องทางภายนอก',
      renderContent: this._renderGroupMkpFilterContent,
    })
  }

  _renderCollapsibleCategoryCard = () => {
    const isHidden = this.isHiddenCategoryFilterCard()
    if (isHidden) {
      return null
    }

    const { categoryList } = this.state
    if (_.isNil(categoryList) || categoryList.length === 0) {
      return null
    }

    return this._renderCollapsibleCard({
      key: 'category',
      title: 'หมวดหมู่',
      renderContent: this._renderGroupCategoryFilterContent,
    })
  }

  _hasSellerStores = () => {
    const { sellerStores = List([]) } = this.props
    return sellerStores.size > 0
  }

  _renderCollapsibleCostAndPriceCard = () => {
    const isHidden = this.isHiddenPriceFilterCard()
    if (isHidden) {
      return null
    }

    if (this._isPriceFilterForced()) {
      return null
    }

    const currentSetting = this._getCurrentFilter()
    if (_.isObject(currentSetting) && currentSetting.isCostAndPriceCardHidden) {
      return null
    }

    // By pKeng on 8 Feb 2025, remove this wrong? logic as it hide the whole "การแสดงราคา" section
    // with the wrong condition.
    /*
    const { selectedStore } = this.props
    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])
    const hasOnlyOneUg = user_groups.size === 1
    const haveOnlyOnePg = product_groups.size === 1
    const hasSellerStores = this._hasSellerStores()
    if (!hasSellerStores && hasOnlyOneUg && haveOnlyOnePg) {
      return null
    }
    */

    return this._renderCollapsibleCard({
      key: 'price',
      title: 'การแสดงราคา',
      renderContent: this._renderGroupPriceFilterContent,
    })
  }

  _renderMain = () => (
    <VStack w='full' px='2' py='1' space='1.5'>
      {this._renderCollapsibleSearchCard()}
      {this._renderCollapsibleSortCard()}
      {this._renderCollapsibleCostAndPriceCard()}
      {this._renderCollapsibleStockCard()}
      {this._renderCollapsibleCostCard()}
      {this._renderCollapsibleDatetimeCard()}
      {this._renderCollapsibleMkpCard()}
      {this._renderCollapsibleCategoryCard()}
    </VStack>
  )

  _renderFooter = () => (
    <HStack w='full' h='12' p='1' space='1' alignItems='center'>
      <XButton w='120px' variant='outline' onPress={this.resetFilter}>
        ล้าง
      </XButton>
      <XButton flex={1} onPress={this._onPressSubmitBtn}>
        กรอง
      </XButton>
    </HStack>
  )

  _goBack = () => {
    util.navGoBack(this.props)
  }

  // _renderHeader = () => <XCustomHeader title='กรอง' headerLeftProps={{ onPressItem: this._goBack, backIcon: true }} />
  _renderHeaderRight = () => null

  _getHeaderTitle = () => {
    const headerTitle = util.getNavParam(this.props, 'headerTitle', 'กรอง')
    return headerTitle
  }

  _renderHeader = () => {
    const headerTitle = this._getHeaderTitle()

    return (
      <HStack
        w='full'
        px='2'
        py='1'
        minH='44px'
        alignItems='center'
        justifyContent='center'
        space='1'
        borderBottomWidth='1'
        borderBottomColor='gray.300'>
        <HStack w='46px' alignItems='center'>
          <XIconButton name='arrow-back' onPress={this._goBack} />
        </HStack>
        <HStack flex={1} alignItems='center'>
          <XText w='full' variant='active' bold>
            {headerTitle}
          </XText>
        </HStack>
        {this._renderHeaderRight()}
      </HStack>
    )
  }

  render() {
    // const { disabled, children } = util.getNavParams(this.props)
    // const { isModalOpen } = this.state
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XContainer>
        {this._renderHeader()}
        <XContent>{this._renderMain()}</XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}

export default BaseUIProductListFilterView
