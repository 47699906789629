import React from 'react'
import { connect } from 'react-redux'

import { getState, getDispatch } from 'x/modules/orderlist/StoreOrderSingleTabListViewConnect'
import { IOrderListItem, IOrderListViewProps, IOrderListViewState, PrintOptionKey } from 'x/index'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as acl from 'x/utils/acl'
import CONS from 'x/config/constants'
import * as NavActions from 'x/utils/navigation'

import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import { List } from 'immutable'
import PrintingOptionOverlayButton from '../../components/PrintingOptionOverlayButton'

import BaseUIOrderListView from './BaseUIOrderListView'

class ReservedProductsOrderListView extends BaseUIOrderListView<IOrderListViewProps, IOrderListViewState> {
  static displayName = 'ReservedProductsOrderListView'

  getHeaderTitle = () => {
    // const defaultTitle = this.getTabLabelWithCount(this.state.tabName)
    // return `สินค้าติดจอง \n${defaultTitle}`
    const ordersCount = this.getSummaryOrdersCount(this.state.tabName)
    // return ordersCount ? `สินค้าติดจอง (${ordersCount})` : 'สินค้าติดจอง'
    return 'ออเดอร์ที่มีสินค้าติดจอง'
  }

  onPressOrderListItem = (od: IOrderListItem) => {
    const { navigation } = this.props

    // this.toggleCheckOrderListItem(od.id)
    navigation.dispatch(
      NavActions.navToOrderDetail({
        order_id: od.id,
        store_id: this.getStoreId(),
      })
    )
  }

  // getOverridePreSetupInitialState = (): Partial<IOrderListViewState> => ({
  //   isCheckable: true,
  //   // availableTabKeys: ['myTasks_ship'],
  //   // availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready', 'myTasks_ship_all'],
  //   availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready'],
  //   displayMode: 'page',
  //   displayPageLimit: 30,
  // })

  getOverridePreSetupInitialState = (): Partial<IOrderListViewState> => {
    const { selectedStore, sellerStores } = this.props
    const hasReseller = selectedStore.get('has_reseller') || false
    const hasSeller = List.isList(sellerStores) && sellerStores.size > 0

    // ไม่มีทั้ง seller / reseller
    let availableTabKeys = [...CONS.ORDER_SUMMARY_NO_SELLER_AND_RESELLER]

    if (hasSeller) {
      availableTabKeys = [...CONS.ORDER_SUMMARY_NO_RESELLER]
    }

    if (hasReseller) {
      availableTabKeys = [...CONS.ORDER_SUMMARY_NO_SELLER]
    }

    if (hasSeller && hasReseller) {
      availableTabKeys = [...CONS.ORDER_SUMMARY_ALL]
    }

    if (this.isHelper()) {
      availableTabKeys = [...CONS.ORDER_SUMMARY_ALL]
    }

    for (let i = availableTabKeys.length - 1; i >= 0; i--) {
      const aKey = availableTabKeys[i]
      // console.log(`${i} getOverridePreSetupInitialState availableTabKeys => `, availableTabKeys)
      // console.log(`${i} getOverridePreSetupInitialState aKey => `, aKey)
      if (aKey.includes('doneTasks')) {
        availableTabKeys.splice(i, 1)
      }
    }

    return {
      isCheckable: true,
      availableTabKeys,
      displayMode: 'page',
      displayPageLimit: 30,
    }
  }

  // ฉันเป็นผู้ช่วย?
  isHelper = () => {
    const { selectedStore } = this.props
    return selectedStore.has('role_id') ? selectedStore.get('role_id') === CONS.STORE_ROLE.HELPER : false
  }

  hasPermissionToPrintDocumentsWithAlert = () => {
    const hasPermissionToPrint = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
    if (!hasPermissionToPrint) {
      p.op.alert('ไม่มีสิทธิ์ในการพิมพ์', 'กรุณาติดต่อเจ้าของร้านหากคุณต้องการพิมพ์เอกสารเหล่านี้')
    }
    return acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
  }

  _onPressPrintDocuments = async (printKey: PrintOptionKey) => {
    const hasPermissionToPrintDocumentsWithAlert = this.hasPermissionToPrintDocumentsWithAlert()
    if (!hasPermissionToPrintDocumentsWithAlert) {
      return
    }

    if (this.isSubmitting) {
      return
    }
    this.isSubmitting = true

    await util.setStatePromise(this, { isSubmitting: true })

    await util.submitPrintingDocuments({
      printKey,
      store_id: this.getStoreId(),
      order_ids: this.getCheckedOrderIds(),
      handleOpenPaperang: p.op.isWeb() ? null : (url) => this._onOpenPaperangFromUrl(url, printKey),
    })

    await util.setStatePromise(this, { isSubmitting: false })

    this.isSubmitting = false
  }

  _onOpenPaperangFromUrl = async (url: string, key: string): Promise<void> => {
    const { selectedStore } = this.props
    let paperangAutoSave = util.checkPaperangAutoSaveImg(selectedStore)

    if (key === 'BILL_PAPERANG') {
      paperangAutoSave = util.checkPaperangAutoSaveImgFromPrintingBill(selectedStore)
    }
    await this.props.navigation.dispatch(
      NavActions.navToPaperangView({
        imageURL: url,
        noAutoSaveImgAtHeightBelow: CONS.NO_AUTO_SAVE_IMG_AT_HEIGHT_BELOW_SHIPING_LABEL.COVER_SHEET,
        disabledAutoSave: paperangAutoSave,
      })
    )
  }

  renderSubmitCheckOrdersButton = () => this.renderPrintingOptionOverlayButton()

  renderPrintingOptionOverlayButton = () => (
    <PrintingOptionOverlayButton
      selectedStore={this.props.selectedStore}
      // renderButton={this._renderMultiOpFooterButton}
      renderButton={this._renderPrintingOptionFooterButton}
      onSubmit={this._onPressPrintDocuments}
      // disabledBill={true}
    />
  )

  _renderPrintingOptionFooterButton = (onPressGenericFooterButton: () => Promise<void>) => {
    const orderIds = this.getCheckedOrderIds()

    const checkOrderCount = orderIds.length
    const exceededLimit = 30
    // const checkOrderCount = this._getCheckedOrderCount()
    // const exceededLimit = this.getCheckedOrderExceededLimit()
    const { isSubmitting, isOrdersRefreshing: isRefreshing } = this.state
    const isExceeded = checkOrderCount > exceededLimit
    const isActive = checkOrderCount > 0
    // const isRefreshing = !this._getIsInited()
    // const btnTextColorConditionStyle = isActive && !isExceeded ? S.TEXT_ACTIVE_DARK : S.TEXT_INACTIVE
    // const btnTextSizeConditionStyle = isActive && !isExceeded ? S.TEXT_LARGER_2 : S.TEXT_LARGER
    // const btnTextCondition = isActive ? 'พิมพ์เอกสาร' : 'กรุณาเลือกออเดอร์ที่ต้องการพิมพ์ โดยแตะปุ่มกลมทางซ้าย'
    // const btnTextCondition = this._getMultiOpFooterButtonText(isActive)
    const btnTextCondition = isActive ? 'พิมพ์เอกสาร' : 'กรุณาเลือกออเดอร์ที่ต้องการพิมพ์ โดยแตะปุ่มกลมทางซ้าย'
    return (
      <HStack w='full' p='1' flexWrap='wrap'>
        <XButton
          w='full'
          minH='9'
          isDisabled={!isActive || isExceeded || isSubmitting || isRefreshing}
          disabled={!isActive || isExceeded || isSubmitting || isRefreshing}
          isLoading={isSubmitting}
          onPress={onPressGenericFooterButton}>
          {!isExceeded && !isSubmitting && !isRefreshing ? btnTextCondition : null}
          {isExceeded && !isSubmitting && !isRefreshing ? `กรุณาเลือกดำเนินงานไม่เกิน ${exceededLimit} ออเดอร์ต่อครั้ง` : null}
        </XButton>
      </HStack>
    )
  }
}

// export default OrderListView

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(ReservedProductsOrderListView)
