import React from 'react'
import * as xFmt from 'x/utils/formatter'
import { COLORS } from 'x/config/styles'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import _ from 'lodash'
import XCard from 'xui/components/XCard'
import { IResponseGetSubscriptionsOrder } from 'x/index'

interface IXPendingSubcriptionPaymentProps {
  pendingOrderData: IResponseGetSubscriptionsOrder
  packageName?: string
  footerComponent?: any // any function component
}

const XPendingSubcriptionPayment = (props: IXPendingSubcriptionPaymentProps) => {
  // console.log('props => ', props)
  // console.log('subcription => ', props.subscription)
  // console.log('type => ', props.type)
  if (_.isNil(props)) {
    return null
  }
  const { pendingOrderData, packageName, footerComponent } = props
  const foreignPurchaseAmount = _.has(pendingOrderData, 'foreign_purchase_amount') ? pendingOrderData.foreign_purchase_amount : null
  const currency = _.has(pendingOrderData, 'currency') ? pendingOrderData.currency : null
  const packageNameForRender = _.isNil(packageName) ? 'มีแพ็กเกจรอตรวจสอบ' : packageName
  let paymentAmount = `${packageNameForRender}\nยอดชำระ ${xFmt.formatCurrency(pendingOrderData.total_amount)}`
  if (!_.isNil(foreignPurchaseAmount)) {
    paymentAmount += ` (${foreignPurchaseAmount} ${currency})`
  }
  return (
    <XCard mx='2' mt='2' pb='2'>
      <HStack bgColor={COLORS.BLUE_TAB} alignItems='center' justifyContent='center' h='10' w='full'>
        <XText textAlign='center' bold fontSize='lg'>
          ชำระ
        </XText>
      </HStack>
      <VStack alignItems='center' justifyContent='center' mt='2'>
        <XText bold fontSize='lg' textAlign='center'>
          {paymentAmount}
        </XText>
        <XText bold fontSize='md'>{`หมายเลขคำสั่งซื้อ #${pendingOrderData.id}`}</XText>
        <XText
          variant='inactive'
          textAlign='center'>{`กรุณารอระบบทำการตรวจสอบหลักฐานการชำระเงิน\nระบบจะอัพเดทแพ็กเกจของคุณให้อัตโนมัติ`}</XText>
      </VStack>
      {_.isNil(footerComponent) ? null : footerComponent()}
    </XCard>
  )
}

export default XPendingSubcriptionPayment
