import React from 'react'
import xCONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import {
  IApiOptions,
  IPackageDetails,
  IResponseGetSubscriptionsOrder,
  ISubscriptionPackageList,
  IXScreenProps,
  SubscriptionPackageListItems,
} from 'x/types'
import api from 'x/utils/api'
import * as NavActions from 'x/utils/navigation'
import _ from 'lodash'
import { COLORS } from 'x/config/styles'
import dayjs from 'dayjs'
import { isImmutable } from 'immutable'

interface IBaseSubscriptionViewProps extends IXScreenProps {
  profile: any
  subscription: any
  fetchSubscription: any
  hideSubscription: any
  subscriptionWarning: {
    type: 'warning' | 'danger' | 'success'
    message: string
    closeable: boolean
    priority: number
  }
}

interface IBaseSubscriptionViewState {
  refreshing: boolean
  subscriptionPackageList: ISubscriptionPackageList
  packageDetails: IPackageDetails
  subscriptionPackangWaring: {
    bg: string
    ms: string
  }
  subscriptionOrderWaring: {
    bg: string
    ms: string
  }
  subscriptionAllQuotaWaring: {
    bg: string
    ms: string
  }
  pendingOrderData: IResponseGetSubscriptionsOrder
}

const warningActionPackage = 'กรุณาติดต่อทีมงาน LINE: @XSelly หรือ โทร 097-167-1650'

export default abstract class BaseSubscriptionView extends React.Component<IBaseSubscriptionViewProps, IBaseSubscriptionViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  mainPackageItems: any

  inLoopPamyntChackProcess: boolean

  constructor(props) {
    super(props)
    // @ts-ignore
    this.state = {
      refreshing: false,
      packageDetails: null,
      subscriptionPackangWaring: null,
      subscriptionOrderWaring: null,
      subscriptionAllQuotaWaring: null,
      pendingOrderData: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
      ...item,
      label: p.op.t(`Subscription.main.${item.key}`),
    }))
    this.inLoopPamyntChackProcess = false
  }

  async componentDidMount() {
    await this._fetchSubscriptionPackageList()

    // console.log('componentDidMount 02 => ')
    // await this._getPackageDetails()
    // console.log('componentDidMount 01 => ')
    const { profile, subscription, fetchSubscription } = this.props
    // console.log(';subscription => ', subscription.toJS())
    // util.checkSubscriptionAllQuota(subscription.toJS())
    if (!profile || !profile.get('user_id')) {
      this._onRefresh()
    } else if (profile && profile.get('user_id') && (!subscription || !subscription.get('user_id'))) {
      fetchSubscription()
    }
    await this._getSubscriptionOrder()
    this._setSubscriptionPackangWaring()
    this._setSubscriptionOrderWaring()
    this._setSubscriptionAllQuotaWaring()
  }

  componentWillUnmount() {
    this.inLoopPamyntChackProcess = false
  }

  // _getPackageDetails = async () => {
  //   await util.setStatePromise(this, {
  //     packageDetails: JSON_PACKAGE_DETAIL_DATA,
  //   })
  // }

  // unUes
  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log('shouldComponentUpdate 00 => ', nextProps)
  //   console.log('shouldComponentUpdate 01 => ', nextState)
  //   const isStateDiff = util.isDiff(this.props, nextProps, ['profile', 'subscription'])
  //   const isPropsDiff = util.isDiff(this.state, nextState, ['refreshing'])
  //   return isStateDiff || isPropsDiff
  // }

  _getSubscriptionOrder = async () => {
    const reqBody = {
      is_pending: true,
    }
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const res = await api.postV2('subscription/order/get', reqBody, apiOptions)
    if (res?.id) {
      await util.setStatePromise(this, { pendingOrderData: res })
      if (res.status === 'pending') {
        await util.delay(5000)
        this.inLoopPamyntChackProcess = true
        await this._loopChackPayment()
      }
    } else {
      this._setSubscriptionPackangWaring()
      this._setSubscriptionOrderWaring()
      this._setSubscriptionAllQuotaWaring()
    }
  }

  _fetchSubscriptionPackageList = async () => {
    const { subscription } = this.props
    const res = await util.fetchSubscriptionPackageList(subscription)
    await util.setStatePromise(this, {
      subscriptionPackageList: res,
    })
  }

  _setSubscriptionPackangWaring = () => {
    const { subscription } = this.props
    const subscriptionPackangWaring = util.getPackageWraning(subscription)
    // eslint-disable-next-line react/no-unused-state
    this.setState({ subscriptionPackangWaring })
  }

  _setSubscriptionOrderWaring = () => {
    const { subscription } = this.props
    // const showBtnPerPackage = util.isDevMode()
    // if (!showBtnPerPackage || _.isNil(subscription)) {
    //   return
    // }
    if (_.isNil(subscription)) {
      return
    }
    const subscriptionJs = subscription.toJS()
    // console.log('subscriptionJs => ', subscriptionJs)
    // order_count_quota_exceeded_at
    const { order_count_curr, order_count_quota, is_new_subscription, end_billing_date, order_count_quota_exceeded_at } = subscriptionJs
    // console.log('order_count_quota_exceeded_at => ', order_count_quota_exceeded_at)
    if (_.isNil(is_new_subscription)) {
      return
    }
    const availableOrder = order_count_quota - order_count_curr
    // console.log('availableOrder => ', availableOrder)
    const availableOrderPercent = Math.round((availableOrder / order_count_quota) * 100)
    let message = `คุณใช้ออเดอร์เกินจำนวนโควตา คุณสามารถเลือกอัพเกรดแพ็กเกจของคุณ หรือ ซื้อแพ็กเกจเสริมเพิ่มออเดอร์ เพื่อที่จะสามารถใช้งานได้ต่อไป`
    if (availableOrderPercent > 20) {
      return
    }
    if (availableOrderPercent <= 20 && availableOrderPercent > 0) {
      // start_billing_date.endDate.clone().fromNow()
      message = `รอบบิลนี้เหลืออีก ${dayjs(end_billing_date)
        .clone()
        .fromNow()} โดย คุณเหลือโควตาสำหรับใช้งานออเดอร์อีก ${availableOrder}  ออเดอร์ คุณสามารถเลือกอัพเกรดแพ็กเกจของคุณ หรือ ซื้อแพ็กเกจเสริมเพิ่มออเดอร์ เพื่อที่จะสามารถใช้งานได้อย่างต่อเนื่อง`
    }

    const orderCountQuotaExceededAtTimeLeft = dayjs(order_count_quota_exceeded_at).clone().diff(dayjs(), 'd') // ช่วงเวลาที่เหลือของอายุการใช้งาน ( 0 วันเท่ากับ วันนี้ )
    // console.log('orderCountQuotaExceededAtTimeLeft => ', orderCountQuotaExceededAtTimeLeft)
    if (!_.isNil(order_count_quota_exceeded_at) && orderCountQuotaExceededAtTimeLeft < 0) {
      message =
        'คุณจะไม่สามารถเปิดและรับออเดอร์จากตัวแทนได้ คุณสามารถเลือกอัพเกรดแพ็กเกจของคุณ หรือ ซื้อแพ็กเกจเสริมเพิ่มออเดอร์ เพื่อที่จะสามารถใช้งานได้ต่อไป'
    }
    const subscriptionOrderWaring = {
      bg: !_.isNil(order_count_quota_exceeded_at) || availableOrderPercent < 0 ? COLORS.BRAND_Danger : COLORS.BRAND_Warning,
      ms: message,
    }
    this.setState({ subscriptionOrderWaring })
  }

  _setSubscriptionAllQuotaWaring = () => {
    const { subscription } = this.props
    const showBtnPerPackage = util.isDevMode()
    if (!showBtnPerPackage || _.isNil(subscription)) {
      return
    }
    const subscriptionJs = subscription.toJS()
    const { is_new_subscription } = subscriptionJs
    if (_.isNil(is_new_subscription)) {
      return
    }
    const getWarning = util.checkSubscriptionAllQuota(subscriptionJs)
    if (_.isNil(getWarning)) {
      return
    }
    // @ts-ignore
    const warningName = util.getWraningNameByKey(getWarning.key)
    let messageWaring = ''
    if (getWarning.timeLeft > 0) {
      messageWaring = `คุณสามารถใช้งานระบบได้อีกแค่ ${getWarning.timeLeft} ชั่วโมง เพราะคุณใช้งาน ${warningName} เกินโควต้า`
    }
    if (getWarning.timeLeft <= 0) {
      messageWaring = `คุณไม่สามารถใช้งานระบบได้อีก เพราะคุณใช้งาน ${warningName} เกินโควตา`
    }
    const subscriptionAllQuotaWaring = {
      bg: getWarning.timeLeft > 0 ? COLORS.BRAND_Warning : COLORS.BRAND_Danger,
      ms: messageWaring,
    }
    this.setState({ subscriptionAllQuotaWaring })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _hideObject = () => {
    const { subscription } = this.props
    const orderCount = subscription.get('order_count_curr') || 0
    const isIOS = p.op.isIOS()
    // console.log('/// => ', orderCount < 11 && subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE)
    return isIOS && orderCount < 3 && subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE
  }

  _onRefresh = () => {
    const { fetchSubscription } = this.props
    // this.setState({ refreshing: true }, () => {
    //   const successCallback = res => {
    //     // console.log('successCallback res => ', res)
    //     if (res && res.profile && res.profile.user_id) {
    //       // this._fetchSubscription(res.profile.user_id)
    //       fetchSubscription()
    //     }
    //     if (this && !this.isUnmounting) {
    //       this.setState({ refreshing: false })
    //     }
    //   }
    //
    //   const failedCallback = err => {
    //     if (this && !this.isUnmounting) {
    //       this.setState({ refreshing: false })
    //     }
    //   }
    //   fetchUserProfile(successCallback, failedCallback)
    // })
    this.setState({ refreshing: true }, () => {
      const callback = (err) => {
        // if (this && !this.isUnmounting) {
        this.setState({ refreshing: false })
        // }
      }
      fetchSubscription({
        successCallback: callback,
        failedCallback: callback,
      })
    })
  }

  _callbackFormSlectedMainPackage = (itam: SubscriptionPackageListItems) => {
    console.log('_callbackFormSlectedMainPackage => ', itam)
  }

  _navToSelectMainPackageView = (mode: 'NEW_PACKAGE' | 'UPGRADE_PACKAGE' | 'PER_PACKAGE') => {
    const { subscriptionPackageList } = this.state
    // @ts-ignore
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToSelectMainPackageView({
        subscriptionPackageList,
        mode,
        callback: (itam: SubscriptionPackageListItems) => this._callbackFormSlectedMainPackage(itam),
      })
    )
  }

  _navToPaymentSubscriptionPackageView = () => {
    const { navigation } = this.props
    navigation.dispatch(NavActions.navToPaymentSubscriptionPackageView({}))
  }

  _navToInAppPurchaseView = (mode: 'buy_package' | 'continue_package' | 'upgrade' | 'add_on') => {
    const { subscriptionPackageList } = this.state
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToInAppPurchaseView({
        subscriptionPackageList,
        mode,
        callBackSucceedPaymentIos: () => this.callBackSucceedPaymentIos(),
        succeedPaymentCallBack: () => this._getSubscriptionOrder(),
      })
    )
  }

  _getMaimPackageByAddonMode = () => {
    const { subscription } = this.props
    const { subscriptionPackageList } = this.state
    const { type, current_package_billing_cycle } = subscription.toJS()
    let mainPackage = null
    const { items } = subscriptionPackageList
    if (!_.isNil(current_package_billing_cycle) && current_package_billing_cycle.length > 0) {
      const upgradePackageList = current_package_billing_cycle.filter((item) => item.item_type === 'package_upgrade')
      if (upgradePackageList.length > 0) {
        // @ts-ignore
        // upgradePackageList.sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
        // console.log('upgradePackageList * => ', upgradePackageList)
        const lengthPackage = upgradePackageList.length
        const upgradePackageSku = upgradePackageList[lengthPackage - 1].sku
        mainPackage = util.changePackageUpgradeSkuToNormalSku(upgradePackageSku)
      } else {
        current_package_billing_cycle.forEach((item) => {
          if (item.item_type === 'package_main') {
            mainPackage = item.sku
          }
        })
      }
    } else {
      const typeName = util.getSubscriptionTypeName(type)
      items.forEach((item) => {
        if (item.item_subtype === typeName && item.billing_cycle === 12) {
          mainPackage = item.sku
        }
      })
    }
    return mainPackage
  }

  _navToPaymentSubscriptionView = () => {
    const { subscriptionPackageList, pendingOrderData } = this.state
    // console.log('pendingOrderData => ', pendingOrderData)
    const { navigation } = this.props
    const packagePanding = _.has(pendingOrderData, 'items') ? pendingOrderData.items : null
    let mainPackage = null
    if (!_.isNil(packagePanding)) {
      packagePanding.forEach((item) => {
        if (_.includes(xCONS.SUBSCRIPTION_MAIN_PACKAGE_SKU, item.sku)) {
          mainPackage = item.sku
        }
      })
    }
    let mode = 'continue_package'
    // หากเป็นการซื้อแพ็กเสริมจะไม่มีข้อมูลแพ็กหลัก  ให้ไปหยิบแพ็กปัจจุบันมาใช้
    if (_.isNil(mainPackage)) {
      mainPackage = this._getMaimPackageByAddonMode()
      mode = 'add_on'
    }
    // console.log('mainPackage => ', mainPackage)
    this.inLoopPamyntChackProcess = false
    navigation.dispatch(
      NavActions.navToPaymentSubscriptionView({
        subscriptionPackageList,
        sku: mainPackage,
        succeedPaymentCallBack: () => {
          this._getSubscriptionOrder()
        },
        mode,
      })
    )
  }

  callBackSucceedPaymentIos = () => {
    this._onRefresh()
    p.op.storageClear(xCONS.STORAGE_KEYS.SUBSCRIPTION_PACKAGE_TRACKER)
    if (_.isFunction(this.props.hideSubscription)) {
      this.props.hideSubscription()
    }
  }

  _navToInAppPurchasePerPackageView = () => {
    const { navigation, subscription } = this.props
    const { subscriptionPackageList } = this.state
    const canPerPackage = util.PerPackageCheck(subscriptionPackageList)
    // console.log('canPerPackage => ', canPerPackage)
    if (!canPerPackage) {
      return
    }
    const isXsellier = util.isXsellier(subscription)
    if (isXsellier && util.isDevMode()) {
      this._navToInAppPurchaseView('continue_package')
      return
    }
    if (!p.op.isIOS() && util.isDevMode()) {
      // p.op.showConfirmationOkOnly('', warningActionPackage)
      // this._navToPaymentSubscriptionView()
      this._navToInAppPurchaseView('continue_package')
      return
    }
    navigation.dispatch(
      NavActions.navToInAppPurchasePerPackageView({
        subscriptionPackageList,
        callBackSucceedPaymentIos: () => this.callBackSucceedPaymentIos(),
      })
    )
    // this._navToInAppPurchaseView('continue_package')
  }

  _navToBuyAddOnPackage = () => {
    const { subscriptionPackageList, pendingOrderData } = this.state
    const { navigation, subscription } = this.props
    const packagePanding = _.has(pendingOrderData, 'items') ? pendingOrderData.items : null
    let mainPackage = null
    if (!_.isNil(packagePanding)) {
      return p.op.showConfirmationOkOnly('', 'คุณมีแพ็กเกจที่รอการตรวจสอบอยู่  กรุณาลองใหม่ภายหลัง')
    }
    // if (mainPackage === '') {
    //   return p.op.showConfirmationOkOnly('', warningActionPackage)
    // }
    this.inLoopPamyntChackProcess = false

    const { type, current_package_billing_cycle } = subscription.toJS()
    // console.log('current_package_billing_cycle => ', current_package_billing_cycle)
    const { items } = subscriptionPackageList
    if (!_.isNil(current_package_billing_cycle) && current_package_billing_cycle.length > 0) {
      const upgradePackageList = current_package_billing_cycle.filter((item) => item.item_type === 'package_upgrade')
      if (upgradePackageList.length > 0) {
        // @ts-ignore
        // upgradePackageList.sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
        // console.log('upgradePackageList * => ', upgradePackageList)
        const lengthPackage = upgradePackageList.length
        const upgradePackageSku = upgradePackageList[lengthPackage - 1].sku
        mainPackage = util.changePackageUpgradeSkuToNormalSku(upgradePackageSku)
      } else {
        current_package_billing_cycle.forEach((item) => {
          if (item.item_type === 'package_main') {
            mainPackage = item.sku
          }
        })
      }
    } else {
      const typeName = util.getSubscriptionTypeName(type)
      items.forEach((item) => {
        if (item.item_subtype === typeName && item.billing_cycle === 12) {
          mainPackage = item.sku
        }
      })
    }
    // console.log('mainPackage // => ', mainPackage)
    const isXsellier = util.isXsellier(subscription)
    if (isXsellier && util.isDevMode()) {
      navigation.dispatch(
        NavActions.navToPaymentSubscriptionView({
          subscriptionPackageList,
          sku: mainPackage,
          succeedPaymentCallBack: () => {
            this._getSubscriptionOrder()
          },
          mode: 'add_on',
        })
      )
    } else {
      p.op.showConfirmationOkOnly('', warningActionPackage)
    }
  }

  _navToUpgradePackage = () => {
    p.op.showConfirmationOkOnly('', warningActionPackage)
  }

  // _navToBuyAddOnPackage = () => {
  //   p.op.showConfirmationOkOnly('', warningActionPackage)
  // }

  _navToActivePackageDetailView = () => {
    const { subscriptionPackageList } = this.state
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToActivePackageDetailView({
        subscriptionPackageList,
        callBackSucceedPaymentIos: () => this.callBackSucceedPaymentIos(),
      })
    )
  }

  _navToNextPackageDetailView = () => {
    const { subscriptionPackageList } = this.state
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToNextPackageDetailView({
        subscriptionPackageList,
        callBackSucceedPaymentIos: () => this.callBackSucceedPaymentIos(),
      })
    )
  }

  _getCountPackageByKey = (key: string) => {
    const { subscription } = this.props
    if (_.isNil(subscription)) {
      return '-'
    }
    const subscriptionJS = subscription.toJS()
    return subscriptionJS[key]
  }

  _canRenderBuyPackage = () => {
    let isCanRenderBuyPack = false
    if (util.isDevMode() || p.op.isIOS()) {
      isCanRenderBuyPack = true
    }
    return isCanRenderBuyPack
  }

  _canRenderPerPackage = () => {
    let isCanRenderBuyPack = false
    if (util.isDevMode() || p.op.isIOS()) {
      isCanRenderBuyPack = true
    }
    const { subscription } = this.props
    const subscriptionJS = isImmutable(subscription) ? subscription.toJS() : subscription
    if (_.has(subscriptionJS, 'next_package_billing_cycle')) {
      isCanRenderBuyPack = false
    }
    return isCanRenderBuyPack
  }

  _getCurentPackage = (
    currentPackage: {
      billing_cycle: number
      data_json: any
      start_date: string
      end_date: string
      item_type: string
      qty: number
      sku: number
    }[]
  ) => {
    if (_.isNil(currentPackage)) {
      return null
    }
    let packageMain = null
    currentPackage.forEach((packageData) => {
      if (packageData.item_type === 'package_main') {
        packageMain = packageData
      }
    })
    return packageMain
  }

  _showBtnPerPackage = () => {
    const { subscription } = this.props
    const AVAILABLE_TYPES = [2, 3, 4, 5]
    if (_.isNil(subscription) || !_.includes(AVAILABLE_TYPES, subscription.get('type'))) {
      return false
    }

    const subscriptionJS = isImmutable(subscription) ? subscription.toJS() : subscription
    if (_.has(subscriptionJS, 'next_package_billing_cycle')) {
      return false
    }

    return true
  }

  _showUpgradePackage = () => {
    const end = dayjs(util.getSubcriptionEndDate(this.props.subscription)) // อายุการใช้งานวันสุดท้ายของแพ็กเกจ
    const toDay = dayjs(new Date())
    const timeLeft = end.clone().diff(toDay.clone(), 'd')
    const type = this.props.subscription.get('type')
    // console.log('timeLeft => ', timeLeft)
    return util.isDevMode() && timeLeft > 0 && type !== 5
  }

  _showAddonBtn = () => {
    const { pendingOrderData } = this.state
    const end = dayjs(util.getSubcriptionEndDate(this.props.subscription)) // อายุการใช้งานวันสุดท้ายของแพ็กเกจ
    const toDay = dayjs(new Date())
    const timeLeft = end.clone().diff(toDay.clone(), 'd')
    // console.log('timeLeft => ', timeLeft)
    return util.isDevMode() && timeLeft > 0 && _.isNil(pendingOrderData)
  }

  _loopChackPayment = async () => {
    const { pendingOrderData } = this.state
    if (_.isNil(pendingOrderData)) {
      return
    }
    const reqBody = {
      order_id: pendingOrderData.id,
    }
    const res: IResponseGetSubscriptionsOrder = await api.postV2('subscription/order/get', reqBody)
    // console.log('_loopChackPayment res => ', res)
    if (res?.status === 'completed') {
      this.props.fetchSubscription()
      // p.op.showConfirmationOkOnly('', 'ระบบได้ทำการตรวจสอบการชำระเงินเรียบร้อยแล้ว', () => {
      //   this.goBack()
      // })
      this._setSubscriptionPackangWaring()
      this._setSubscriptionOrderWaring()
      this._setSubscriptionAllQuotaWaring()
      await util.setStatePromise(this, { pendingOrderData: null })
      this.inLoopPamyntChackProcess = false
      return
    }

    if (res.status === 'cancelled') {
      p.op.showConfirmationOkOnly('', 'ระบบได้ปฎิเสธการชำระเงิน', () => {
        this.inLoopPamyntChackProcess = false
        this.setState({ pendingOrderData: null })
      })
    }

    await util.delay(5000)
    if (this.inLoopPamyntChackProcess) {
      await this._loopChackPayment()
    }
  }
}
