import React, { Component } from 'react'
import { TouchableOpacity } from 'react-native'

// import { ScrollView } from 'native-base'

import * as util from 'x/utils/util'
import _ from 'lodash'

import { IShippingType } from 'x/index'
import XOverlay from '../XOverlay'
import XIconButton from '../XIconButton'
import XText from '../XText'
import VStack from '../VStack'
import HStack from '../HStack'
import XScrollView from '../XScrollView'
import XIcon from '../XIcon'

interface IShippingTypePickerButtonProps {
  selectedIndex: number
  selectedItem: IShippingType
  disabled?: boolean
  onPress: () => void
}

interface IShippingTypePickerProps {
  disabled?: boolean
  title?: string
  isAllShippingTypesVisible?: boolean

  selectedShippingTypeId?: number
  // selectedIndex: number

  onSelect: (selectedItem: IShippingType, index: number) => void | Promise<void>
  renderButton: (props: IShippingTypePickerButtonProps) => JSX.Element

  renderLeftHeaderButton?: () => JSX.Element
  renderTitleBar?: () => JSX.Element
  renderHeader?: () => JSX.Element
  renderFooter?: () => JSX.Element
}

interface IShippingTypePickerState {
  options: IShippingType[]
  isInitialized: boolean
  isVisiblePicker?: boolean
}

export default class ShippingTypePicker extends Component<IShippingTypePickerProps, IShippingTypePickerState> {
  static displayName = 'ShippingTypePicker'

  static defaultProps = {
    title: 'เลือกรูปแบบจัดส่ง',
  }

  randomKey: string

  constructor(props: IShippingTypePickerProps) {
    super(props)

    this.randomKey = `${Math.floor(Math.random() * 10000)}`
    this.state = {
      isInitialized: false,
      options: [],
    }
  }

  async componentDidMount() {
    await this._initialize()
    await util.delay(100)
    await util.setStatePromise(this, { isInitialized: true })
  }

  componentDidUpdate(prevProps: Readonly<IShippingTypePickerProps>): void {
    if (prevProps.isAllShippingTypesVisible !== this.props.isAllShippingTypesVisible) {
      this._initialize()
    }
  }

  _initialize = async () => {
    const { isAllShippingTypesVisible = false } = this.props
    const options = util.getVisibleShippingTypes(isAllShippingTypesVisible)
    await util.setStatePromise(this, { options })
  }

  getSelectedIndex = (): number => {
    // const { selectedIndex } = this.props

    // return _.isNumber(selectedIndex) && selectedIndex >= 0 ? selectedIndex : -1

    const { options } = this.state
    const { selectedShippingTypeId } = this.props

    if (!_.isNumber(selectedShippingTypeId) || selectedShippingTypeId < 0) {
      return -1
    }

    const foundIndex = _.findIndex(options, (item) => item.value === selectedShippingTypeId)
    return foundIndex
  }

  getSelectedOption = (): IShippingType | null => {
    // const { selectedIndex } = this.props

    // if (selectedIndex < 0) {
    //   return null
    // }

    // const { options } = this.state

    // return options[selectedIndex]

    const { options } = this.state
    const { selectedShippingTypeId } = this.props

    if (!_.isNumber(selectedShippingTypeId) || selectedShippingTypeId < 0) {
      return null
    }

    const foundIndex = _.findIndex(options, (item) => item.value === selectedShippingTypeId)
    return options[foundIndex]
  }

  openPicker = (): void => {
    this.setState({ isVisiblePicker: true })
  }

  closePicker = () => {
    util.setStatePromise(this, { isVisiblePicker: false })
  }

  _onSelectItem = async (index: number): Promise<void> => {
    // log('ShippingTypePicker _onSelectItem index => ', index)
    await util.setStatePromise(this, { isVisiblePicker: false })
    await util.delay(100)

    const { onSelect } = this.props
    if (_.isFunction(onSelect)) {
      const { options } = this.state
      onSelect(options[index], index)
    }
  }

  _renderPickerItems = () => {
    const { options } = this.state
    return options.map(this._renderPickerItem)
  }

  _renderPickerItem = (item, index: number) => {
    if ('hidden' in item && item.hidden) {
      return null
      // return <Box key={`p-${index}-hidden`} h='1px' w='1px' opacity={0} />
    }

    const shippingState = util.getShippingHasXShippingLabel(item.value)

    return (
      <TouchableOpacity key={`PickerItem_${this.randomKey}_${index}`} onPress={() => this._onSelectItem(index)}>
        <HStack
          w='full'
          minH='10'
          px='2'
          py='1'
          alignItems='center'
          space='1'
          flexWrap='wrap'
          bg='white'
          borderWidth='1'
          borderTopColor='gray.300'
          borderLeftColor='gray.300'
          borderRightColor='gray.400'
          borderBottomColor='gray.400'
          borderRadius='lg'>
          <VStack flex={1}>
            <XText>{`${item.label}`}</XText>
            {_.isNil(shippingState) ? null : <XText style={{ color: shippingState.color }}>{`(${shippingState.label})`}</XText>}
          </VStack>

          <HStack w='34px' h='34px' alignItems='center' justifyContent='flex-end'>
            <XIcon name='arrowright' family='AntDesign' />
          </HStack>
        </HStack>
      </TouchableOpacity>
    )
  }

  _renderModal = () => {
    const { title, renderLeftHeaderButton, renderHeader, renderFooter, renderTitleBar } = this.props
    const { isVisiblePicker } = this.state

    return (
      <XOverlay key={`Picker_${this.randomKey}`} onRequestClose={this.closePicker} visible={isVisiblePicker}>
        {_.isFunction(renderHeader) ? (
          renderHeader()
        ) : (
          <HStack w='full' minH='11' alignItems='center'>
            <HStack minW='11' alignItems='center' justifyContent='center'>
              {_.isFunction(renderLeftHeaderButton) ? renderLeftHeaderButton() : null}
            </HStack>
            <HStack flex={1} alignItems='center' justifyContent='center'>
              <XText bold>{title}</XText>
            </HStack>
            <HStack minW='11' alignItems='center' justifyContent='center'>
              <XIconButton family='Ionicons' name='close-circle' onPress={this.closePicker} />
            </HStack>
          </HStack>
        )}

        {_.isFunction(renderTitleBar) ? renderTitleBar() : null}

        <XScrollView w='full' _web={{ flex: 1 }} _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'warmGray.700' }}>
          <VStack w='full' p='2' space='1.5'>
            {this._renderPickerItems()}
          </VStack>
        </XScrollView>
        {_.isFunction(renderFooter) ? renderFooter() : null}
      </XOverlay>
    )
  }

  _renderSelectedValue = () => {
    const { disabled = false, renderButton } = this.props
    if (!_.isFunction(renderButton)) {
      throw new Error('Missing ShippingTypePicker renderButton method')
    }
    return renderButton({
      selectedIndex: this.getSelectedIndex(),
      selectedItem: this.getSelectedOption(),
      disabled,
      onPress: this.openPicker,
    })
  }

  _renderMain = () => (
    <>
      {this._renderSelectedValue()}
      {this._renderModal()}
    </>
  )

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return this._renderMain()
  }
}
