/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import _ from 'lodash'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import BasePaymentSubscriptionView from 'x/modules/subscription/BasePaymentSubscriptionView'
import { COLORS, STYLES } from 'x/config/styles'
import * as xFmt from 'x/utils/formatter'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import XCustomHeader from 'xui/components/XCustomHeader'
import ProfilePackage from 'xui/components/ProfilePackage'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XCapsuleSegment, { IOptionsCapsuleSegment } from 'xui/components/XCapsuleSegment'
import VStack from 'xui/components/VStack'
import XCard from 'xui/components/XCard'
import { TouchableOpacity, Clipboard, Linking } from 'react-native'
import * as util from 'x/utils/util'
import XIcon from 'xui/components/XIcon'
import XImage from 'xui/components/XImage'
import XIconButton from 'xui/components/XIconButton'
import XButton from 'xui/components/XButton'
import XDocumentDropzone from 'xui/components/XDocumentDropzone'
import XDateTimeSinglePicker from 'xui/components/XDateTimeSinglePicker'
import XSpinner from 'xui/components/XSpinner'
import dayjs from 'dayjs'
import XOverlay from 'xui/components/XOverlay'
import ChangeBillingCycle from './ChangeBillingCycle'
import XPendingSubcriptionPayment from './XPendingSubcriptionPayment'
import XOrderCountHistory from './XOrderCountHistory'

// import { withIAPContext } from 'react-native-iap'
// import { createStackNavigator } from '@react-navigation/stack'
const IMG_DISCOUNT = require('../../img/discount/discount_red.png')

const BRONZ_BG = require('../../img/packge_bg/bronz.png')
const SILVER_BG = require('../../img/packge_bg/silver.png')
const GOLD_BG = require('../../img/packge_bg/gold.png')
const PLATINUM_BG = require('../../img/packge_bg/platinum.png')

const IMG_SCB = require('../../img/banks/scb.png')

const OPTIONS_NEW_SEGMENT: IOptionsCapsuleSegment[] = [
  {
    text: '1 เดือน',
  },
  {
    text: '3 เดือน',
    // labelView: <XImage source={IMG_DISCOUNT} w={50} h={50} />,
  },
  {
    text: '6 เดือน',
    // labelView: <XImage source={SILVER_BG} w={20} h={30} mt='2' />,
  },
  {
    text: '12 เดือน',
    // labelView: <XImage source={PLATINUM_BG} w={20} h={30} />,
  },
]

export default abstract class BaseUIPaymentSubscriptionView extends BasePaymentSubscriptionView {
  _renderHeader = () => {
    const { mode } = this.state
    let title = 'ซื้อแพ็กเกจ'
    if (mode === 'add_on') {
      title = 'ซื้อแพ็กเกจเสริม'
    }
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{
          backIcon: true,
          onPressItem: () => {
            const params = this._getParams()
            const { succeedPaymentCallBack } = params
            if (_.isFunction(succeedPaymentCallBack)) {
              // succeedPaymentCallBack()
            }
            this.goBack()
          },
        }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderProfile = () => {
    const { subscription } = this.props
    const { profile } = this.state
    // console.log('profile // => ', profile)
    if (_.isNil(profile) || _.isNil(subscription)) {
      return null
    }
    const typeId: number = subscription.has('type') ? subscription.get('type') : 0
    // console.log('profile => ', profile)
    return <ProfilePackage profile={profile} typeId={typeId} />
  }

  _renderTitleDescription = () => {
    const { pendingOrderData } = this.state
    if (!_.isNil(pendingOrderData)) {
      return null
    }
    return (
      <HStack w='full' alignItems='center' justifyContent='center' mt='2' h='16'>
        <XText fontSize='lg' textAlign='center'>
          {'เลือกซื้อแพ็กเกจได้อย่างอิสระ\nในทุกแบบที่คุณต้องการ'}
        </XText>
      </HStack>
    )
  }

  _renderSelectedMonthPackage = () => {
    const { selectedOptionRangePackageIndex } = this.state
    return (
      <HStack px='2' mt='2'>
        <XCapsuleSegment
          options={OPTIONS_NEW_SEGMENT}
          onSegmentChange={(newIndex: number) => this.setState({ selectedOptionRangePackageIndex: newIndex })}
          sectedIndex={selectedOptionRangePackageIndex}
        />
      </HStack>
    )
  }

  _renderMySelectedPackage = () => {
    const { selectedMainPackage, pendingOrderData, mode } = this.state
    // console.log('selectedMainPackage => ', selectedMainPackage)
    if (_.isNil(selectedMainPackage) || mode === 'add_on') {
      return null
    }
    let packageName = `แพ็กเกจ ${util.getSubscriptionTypeFullNameBySubType(selectedMainPackage.item_subtype)}`
    const priceWithVat = selectedMainPackage.price_with_vat
    const billingCycleDateTime = this._getStartAndEndBillingCycle(selectedMainPackage.billing_cycle)
    if (mode === 'upgrade') {
      packageName = selectedMainPackage.name
    }
    return (
      <XCard mx='2' mt='2'>
        <VStack w='full'>
          <HStack bgColor={COLORS.BG_GREY_PACKAGE} alignItems='center' justifyContent='center' h='10'>
            <XText textAlign='center' bold fontSize='lg'>
              แพ็กเกจที่เลือก
            </XText>
          </HStack>

          <VStack p='2'>
            <HStack w='full'>
              <HStack flex={2}>
                <XText>{`${packageName}`}</XText>
                {_.isNil(pendingOrderData) ? (
                  <TouchableOpacity style={{ marginLeft: 4, paddingBottom: 4, width: 40 }} onPress={() => this.goBack()}>
                    <XText variant='inactive' textDecorationLine='underline'>
                      แก้ไข
                    </XText>
                  </TouchableOpacity>
                ) : null}
              </HStack>
              <HStack flex={1} justifyContent='flex-end'>
                <XText color={_.isNil(pendingOrderData) ? COLORS.RED : COLORS.TEXT_ACTIVE} fontSize='lg' bold>{`${xFmt.formatCurrency(
                  priceWithVat
                )}`}</XText>
              </HStack>
            </HStack>
            <HStack>
              <XText>{`${selectedMainPackage.billing_cycle} เดือน`}</XText>
              {_.isNil(pendingOrderData) && mode !== 'upgrade' ? (
                <TouchableOpacity
                  style={{ marginLeft: 4, paddingBottom: 4, width: 40 }}
                  onPress={() => this.setState({ isvisibleChangeBillingCycle: true })}>
                  <XText variant='inactive' textDecorationLine='underline'>
                    แก้ไข
                  </XText>
                </TouchableOpacity>
              ) : null}
            </HStack>
            <HStack>
              <XText>{`ช่วงเวลา ${billingCycleDateTime}`}</XText>
            </HStack>
          </VStack>
        </VStack>
      </XCard>
    )
  }

  _getAddOnCurrentPackageBySku = (sku: string) => {
    const { currentPackageBillingCycle } = this.state
    if (_.isNil(currentPackageBillingCycle)) {
      return null
    }

    const addOnlist = []
    const fullSku = currentPackageBillingCycle.find((addOn) => addOn.sku.startsWith(sku))?.sku
    // console.log('fullSku => ', fullSku)
    if (_.isNil(fullSku)) {
      return null
    }
    currentPackageBillingCycle.forEach((addOn) => {
      if (addOn.sku.startsWith(sku)) {
        addOnlist.push(addOn)
      }
    })
    return addOnlist.length > 0 ? addOnlist : null
  }

  // เอาไว้ render รายละเอียดแพ็กเสริม
  _renderAddOnCurrentPackageListDetail = (key: string, cutSku: string) => {
    // console.log('key => ', key)
    // console.log('cutSku => ', cutSku)
    const addOnPackageList = this._getAddOnCurrentPackageBySku(cutSku)
    // const diff = this._getDifferenceFromMainPackageAndNowPackage(key)
    const unit = util.getUnitNameByDataJsonKey(key)

    if (_.isNil(addOnPackageList)) {
      return null
    }
    // console.log('addOnPackageList => ', addOnPackageList)
    return (
      <VStack>
        {addOnPackageList.map((addOn) => {
          const { start_date, end_date, qty, sku } = addOn
          const addOnData = this._getCurrentSelectedAddOnDataByCutSku(sku)
          const { data_json } = addOnData
          const startPackage = dayjs(start_date, 'YYYY-MM-DD').clone().format('D MMM YYYY')
          const endDataPackage = dayjs(end_date, 'YYYY-MM-DD').clone().format('D MMM YYYY')
          return (
            <HStack w='full' key={addOn.sku}>
              <HStack flex={1}>
                <VStack>
                  <XText>{`มีแพ็กเสริมเพิ่ม ${data_json[key]} ${unit} x ${qty}`}</XText>
                  <XText variant='inactive' fontSize='xs'>
                    {`${startPackage} - ${endDataPackage}`}
                  </XText>
                </VStack>
              </HStack>
              <HStack flex={1} justifyContent='flex-end' alignItems='flex-start'>
                <XText>{`${data_json[key] * qty}`}</XText>
                <XText variant='inactive' fontSize='xs' ml='2' w='70px'>
                  {`${unit}`}
                </XText>
              </HStack>
            </HStack>
          )
        })}
      </VStack>
    )
  }

  _renderAddOnTitle = (addOn: { title: string; addOnKey: string; cutSku: string }) => {
    const { selectedMainPackage, pendingOrderData } = this.state
    const { data_json } = selectedMainPackage
    const value = !_.isNil(data_json) ? data_json[addOn.addOnKey] : 0
    const unit = util.getUnitNameByDataJsonKey(addOn.addOnKey)
    const packageName = util.getSubscriptionTypeFullNameBySubType(selectedMainPackage.item_subtype)
    const selectedOrderAddOn = this._getSelectedAddOnDataByCutSku(addOn.cutSku)
    // const selectedOrderAddOn = this._getSelectedAddOnDataByCutSku(sku)
    // const diff = this._getDifferenceFromMainPackageAndNowPackage(key)
    // const mainPackageDateTime = this._getMainPackageStarDateEndDateText()
    if (_.isNil(selectedOrderAddOn) && _.isNil(pendingOrderData)) {
      return null
    }
    return (
      <VStack>
        <HStack w='full'>
          <HStack flex={1}>
            <HStack>
              <XText variant='inactive'>{`แพ็กเกจ ${packageName}`}</XText>
              {/* <XText>{`แพ็กเสริม${unit}`}</XText> */}
            </HStack>
            {/* {!_.isNil(mainPackageDateTime) ? (
                <XText variant='inactive' fontSize='xs'>
                  {mainPackageDateTime}
                </XText>
              ) : null} */}
          </HStack>
          <HStack flex={1} justifyContent='flex-end' alignItems='flex-start'>
            <XText>{`${value}`}</XText>
            <XText variant='inactive' fontSize='xs' ml='2' w='70px'>
              {`${unit}`}
            </XText>
          </HStack>
        </HStack>

        {/* {this._renderAddOnCurrentPackageListDetail(key, sku)} */}
      </VStack>
    )
  }

  _renderAddOnSelectedTitle = (addOn: { title: string; addOnKey: string; cutSku: string }, sku: string) => {
    const { selectedMainPackage, pendingOrderData } = this.state
    if (_.isNil(selectedMainPackage)) {
      return null
    }
    let unitCount = selectedMainPackage?.data_json ? selectedMainPackage.data_json[addOn.addOnKey] : 0
    const getCountFromSelectedAddOn = this._getAddOnCount(addOn)
    unitCount += getCountFromSelectedAddOn
    const selectedOrderAddOn = this._getSelectedAddOnDataByCutSku(addOn.cutSku)
    // console.log('addOnKey => ', addOn.addOnKey)
    // console.log('unitCount => ', unitCount)
    // const sectedAddOn = this._getAddOnSelectedData(addOn.cutSku)
    let fontColor = COLORS.TEXT_ACTIVE
    if (!_.isNil(selectedOrderAddOn)) {
      // unitCount += selectedOrderAddOn.data_json[addOn.addOnKey] * sectedAddOn.qty
      fontColor = COLORS.GREEN
    }
    const { title } = addOn
    const diff = this._getDifferenceFromMainPackageAndNowPackage(addOn.addOnKey)
    // console.log('diff => ', diff)
    // const sumtotal = unitCount + diff
    const sumtotal = unitCount
    return (
      <HStack w='full'>
        <HStack flex={1} alignItems='center'>
          <XText fontSize='lg' bold>
            {title}
          </XText>
          {_.isNil(selectedOrderAddOn) && _.isNil(pendingOrderData) ? (
            <TouchableOpacity style={{ marginLeft: 4 }} onPress={() => this._onPressAddOn(sku)}>
              <XText color={COLORS.GREEN} textDecorationLine='underline'>
                เพิ่ม
              </XText>
            </TouchableOpacity>
          ) : null}
        </HStack>
        <HStack flex={1} justifyContent='flex-end' alignItems='center'>
          <XText variant='inactive' fontSize='xs' mr='2'>
            รวม
          </XText>
          <XText color={fontColor} fontSize='lg' bold>
            {`${xFmt.formatDecimal(sumtotal)}`}
          </XText>
          <XText variant='inactive' fontSize='xs' ml='2' w='70px'>
            {title}
          </XText>
        </HStack>
      </HStack>
    )
  }

  _renderSelectedAddOn = (cutSku: string) => {
    const { addOnPackages, pendingOrderData, mode } = this.state
    const selectedOrderAddOn = this._getSelectedAddOnDataByCutSku(cutSku)
    // console.log('addOnPackages => ', addOnPackages)
    // const unit = util.getUnitNameByDataJsonKey(key)
    if (_.isNil(selectedOrderAddOn) || _.isNil(addOnPackages)) {
      return null
    }

    const addOnBySku = this._getAddOnSelectedListData(cutSku)
    // console.log('addOnBySku => ', addOnBySku)
    // console.log('cutSku => ', cutSku)
    if (_.isNil(addOnBySku) || addOnBySku.length === 0) {
      return null
    }

    const { subscription } = this.props
    return (
      <VStack space='2'>
        {addOnBySku.map((addOn) => {
          const { sku, billing_cycle, qty } = addOn
          // let unitCount = data_json[key]
          // console.log('addOn * => ', addOn)
          const seletedAddOn = this._getAddOnSelectedDataBySku(sku)
          // console.log('addOn => ', addOn)
          // console.log('seletedAddOn => ', seletedAddOn)
          const unitCount = seletedAddOn.data_json[seletedAddOn.item_subtype]
          // console.log('unitCount => ', unitCount)
          const price = seletedAddOn.price_with_vat
          const billingCycle = billing_cycle
          // if (key !== 'order_count_quota') {
          //   billingCycle = billing_cycle
          // }
          const sumTotalPrice = price * billingCycle * qty

          let billingCycleDateTime = this._getStartAndEndBillingCycle(billingCycle)
          if (mode === 'add_on' || mode === 'upgrade') {
            const LOCAL_DATE_FORMAT = 'D MMM YYYY'
            const startBillingDate = subscription.has('start_billing_date')
              ? subscription.get('start_billing_date')
              : subscription.get('startBillingDate')
            const endBillingDate = subscription.has('end_billing_date')
              ? subscription.get('end_billing_date')
              : subscription.get('endBillingDate')
            const txtEndBillingDate = dayjs(endBillingDate, CONS.SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
            const txtStartBillingDate = dayjs(startBillingDate, CONS.SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
            billingCycleDateTime = `${txtStartBillingDate} - ${txtEndBillingDate}`
          }

          const unitSumCount = _.isNil(unitCount) ? 0 : unitCount
          const unit = util.getUnitNameByDataJsonKey(seletedAddOn.item_subtype)

          // const { future_billing_dates, current_billing_dates } = subscriptionPackageList
          // const startPackage = dayjs(current_billing_dates[0].start, 'YYYY-MM-DD').clone().format('D MMM YYYY')
          // const endDataPackage = dayjs(future_billing_dates[billingCycle - 1].end, 'YYYY-MM-DD')
          //   .clone()
          //   .format('D MMM YYYY')
          return (
            <XCard bgColor={COLORS.BG_LIGHT_GREY} p='2' mx='2' key={addOn.sku}>
              <HStack w='full'>
                <HStack flex={3}>
                  <VStack>
                    <XText color={COLORS.GREEN}>{`ซื้อเพิ่ม ${xFmt.formatDecimal(unitSumCount)} ${unit} x ${qty}`}</XText>
                    <XText variant='inactive' fontSize='xs'>
                      {`${billingCycleDateTime}`}
                    </XText>
                  </VStack>

                  {_.isNil(pendingOrderData) && !_.isNil(selectedOrderAddOn) ? (
                    <HStack w='10' justifyContent='flex-end'>
                      <TouchableOpacity style={{ marginLeft: 4, paddingBottom: 4, flex: 1 }} onPress={() => this._onPressEditAddOn(sku)}>
                        <XText variant='inactive' textDecorationLine='underline'>
                          แก้ไข
                        </XText>
                      </TouchableOpacity>
                    </HStack>
                  ) : null}
                </HStack>
                <HStack flex={2} justifyContent='flex-end' alignItems='flex-start'>
                  <XText color={COLORS.GREEN}>{xFmt.formatDecimal(unitSumCount * qty)}</XText>
                  <XText color={COLORS.GREEN} variant='inactive' fontSize='xs' ml='2' w='62px'>
                    {`${unit}`}
                  </XText>
                </HStack>
              </HStack>
              {/* <HStack h='6'>
                <HStack flex={1}>
                  <XText color={COLORS.GREEN}>{`ซื้อเพิ่ม ${unitSumCount} ${unit}`}</XText>
                </HStack>
                {_.isNil(pendingOrderData) ? (
                  <HStack w='10' justifyContent='flex-end'>
                    <TouchableOpacity style={{ marginLeft: 4, paddingBottom: 4, flex: 1 }} onPress={() => this._onPressEditAddOn(cutSku)}>
                      <XText variant='inactive' textDecorationLine='underline'>
                        แก้ไข
                      </XText>
                    </TouchableOpacity>
                  </HStack>
                ) : null}
              </HStack> */}

              {/* <XText variant='inactive'>{`${seletedAddOn.name} x ${qty}`}</XText> */}
              {/* <XText variant='inactive' fontSize='xs'>{`${xFmt.formatCurrency(price)}/รอบบิล`}</XText> */}
              {/* <XText variant='inactive' fontSize='xs'>{`${billingCycle} รอบบิล (${billingCycleDateTime})`}</XText> */}
              <HStack w='full'>
                <HStack flex={2}>
                  <XText variant='inactive' fontSize='xs'>{`${xFmt.formatCurrency(price)}/รอบบิล x ${billingCycle} รอบบิล x ${qty}`}</XText>
                </HStack>
                <HStack flex={1} justifyContent='flex-end' alignItems='center'>
                  <XText color={COLORS.TEXT_ACTIVE}>{xFmt.formatCurrency(sumTotalPrice)}</XText>
                </HStack>
              </HStack>
            </XCard>
          )
        })}
      </VStack>
    )
  }

  _renderOrderCountHistoryOverlay = () => {
    const { orderCountHistory, isOrderCountHistory } = this.state
    if (_.isNil(orderCountHistory)) {
      return null
    }
    return (
      <XOverlay visible={isOrderCountHistory} onRequestClose={() => this.setState({ isOrderCountHistory: false })}>
        <VStack p='2' w='full' h='160px'>
          <XOrderCountHistory orderCountHistory={orderCountHistory} />
          <TouchableOpacity
            style={{ borderRadius: 7, borderColor: COLORS.APP_MAIN, alignItems: 'center', borderWidth: 1, padding: 6, marginTop: 4 }}
            onPress={() => this.setState({ isOrderCountHistory: false })}>
            <XText color={COLORS.APP_MAIN}>ปิด</XText>
          </TouchableOpacity>
        </VStack>
      </XOverlay>
    )
  }

  _renderOrderCountHistory = (sku: string) => {
    const { orderCountHistory } = this.state
    // console.log('sku => ', sku)
    if (_.isNil(orderCountHistory) || sku !== 'ADDON-QUOTA-ORDER') {
      return null
    }
    let countNumber = 0
    orderCountHistory.forEach((order) => {
      const { order_count } = order
      if (order_count === 0) {
        countNumber += 1
      }
    })
    if (countNumber === orderCountHistory.length) {
      return null
    }
    return (
      <HStack w='full'>
        {/* <TouchableOpacity style={{ paddingBottom: 4 }} onPress={() => this._onPressEditAddOn(sku)}> */}
        <TouchableOpacity style={{ paddingBottom: 4 }} onPress={() => this.setState({ isOrderCountHistory: true })}>
          <XText variant='inactive' textDecorationLine='underline'>
            ดูประวัติการใช้งานออเดอร์
          </XText>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderAddOnView = (addOn: { title: string; addOnKey: string; cutSku: string }) => (
    <VStack w='full' borderBottomColor={COLORS.BG_LIGHT_GREY} borderBottomWidth='1' paddingBottom='2' mt='1' key={addOn.cutSku}>
      <VStack px='2'>
        {this._renderAddOnSelectedTitle(addOn, addOn.cutSku)}
        {this._renderOrderCountHistory(addOn.cutSku)}
        {this._renderAddOnTitle(addOn)}
      </VStack>
      {this._renderSelectedAddOn(addOn.cutSku)}
    </VStack>
  )

  // xFmt.formatCurrency(80)
  _renderAddOnPackage = () => {
    const { selectedMainPackage, mode } = this.state
    if (_.isNil(selectedMainPackage) || _.isNil(this.ADD_ON_COUNTSTANT)) {
      return null
    }
    let packageName = `รายละเอียดแพ็กเกจ ${util.getSubscriptionTypeFullNameBySubType(selectedMainPackage.item_subtype)}`
    if (mode === 'upgrade' || mode === 'add_on') {
      packageName = 'รายละเอียดแพ็กเกจเสริม'
    }
    return (
      <XCard mx='2' mt='2'>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} alignItems='center' justifyContent='center' h='10' w='full'>
          <XText textAlign='center' bold fontSize='lg'>
            {`${packageName}`}
          </XText>
        </HStack>
        <VStack>{this.ADD_ON_COUNTSTANT.map((addOn) => this._renderAddOnView(addOn))}</VStack>
      </XCard>
    )
  }

  _renderOrderSummaryList = (title: string, billingCycle: number, summary: number | string) => {
    const { mode } = this.state
    if (mode === 'add_on') {
      return null
    }
    const billingCycleDateTime = this._getStartAndEndBillingCycle(billingCycle)
    return (
      <VStack p='2' w='full' key={title}>
        <VStack w='full'>
          <HStack w='full'>
            <HStack flex={2}>
              <XText>{title}</XText>
            </HStack>
            <HStack flex={1} justifyContent='flex-end'>
              <XText>{xFmt.formatCurrencyFixedPoint(summary, 2)}</XText>
            </HStack>
          </HStack>
          <XText variant='inactive'>{`${billingCycle} รอบบิล (${billingCycleDateTime})`}</XText>
        </VStack>
      </VStack>
    )
  }

  _renderOrderAddOnSummary = () => {
    const { addOnPackages } = this.state
    if (_.isNil(addOnPackages)) {
      return null
    }
    // console.log('addOnPackages => ', addOnPackages)
    return (
      <VStack>
        {addOnPackages.map((addOn) => {
          const { billing_cycle, qty, sku } = addOn
          const selectedPackage = this._getAddOnSelectedDataBySku(sku)
          const name = `${selectedPackage.name} x ${qty}`
          const price = selectedPackage.price_with_vat * qty * billing_cycle
          return this._renderOrderSummaryList(name, billing_cycle, price)
        })}
      </VStack>
    )
  }

  _renderOrderSummaryTotal = () => {
    const { selectedMainPackage, isTaxWithheld } = this.state
    if (_.isNil(selectedMainPackage)) {
      return null
    }
    const price = this._getSumTotalPayment()
    const sumTotalPriceBeforeVat = this._getSumTotalPriceBeforeVat()
    const sumTotalVat = this._getSumTotalVat()
    const whTex = isTaxWithheld ? this._getSumTotalWhTex() : 0
    const sumTotalPrice = isTaxWithheld ? price - whTex : price
    // console.log('sumTotalPriceBeforeVat => ', sumTotalPriceBeforeVat)
    return (
      <VStack>
        <HStack borderBottomColor={COLORS.TEXT_ACTIVE} borderBottomWidth='1' borderStyle='dotted' />
        {/* <HStack p='2'>
          <HStack flex={2}>
            <XText bold>รวมยอดคำสั่งซื้อ</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText bold>{xFmt.formatCurrency(price)}</XText>
          </HStack>
        </HStack> */}
        <HStack p='2'>
          <HStack flex={2}>
            <XText variant='inactive'>ราคาก่อน VAT</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText variant='inactive'>{xFmt.formatCurrencyFixedPoint(sumTotalPriceBeforeVat, 2)}</XText>
          </HStack>
        </HStack>
        <HStack p='2'>
          <HStack flex={2}>
            <XText variant='inactive'>VAT 7%</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText variant='inactive'>{xFmt.formatCurrencyFixedPoint(sumTotalVat, 2)}</XText>
          </HStack>
        </HStack>
        <HStack p='2'>
          <HStack flex={2}>
            <XText variant='inactive'>ราคารวม VAT</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText variant='inactive'>{xFmt.formatCurrencyFixedPoint(price, 2)}</XText>
          </HStack>
        </HStack>
        <HStack p='2'>
          <HStack flex={2}>
            <XText variant='inactive'>หักภาษี ณ ที่จ่าย 3%</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText variant='inactive'>{xFmt.formatCurrencyFixedPoint(whTex, 2)}</XText>
          </HStack>
        </HStack>

        <HStack borderTopColor={COLORS.TEXT_ACTIVE} borderTopWidth='1' borderStyle='dotted' />

        <VStack bgColor={COLORS.BG_GREY_PACKAGE}>
          <HStack p='2' w='full'>
            <HStack flex={2}>
              <XText bold fontSize='lg'>
                ยอดสุทธิ
              </XText>
            </HStack>
            <VStack flex={1} justifyContent='flex-end'>
              <XText bold fontSize='lg' textAlign='right'>
                {xFmt.formatCurrencyFixedPoint(sumTotalPrice, 2)}
              </XText>
              <HStack borderTopColor={COLORS.TEXT_ACTIVE} borderTopWidth='1' />
              <HStack borderTopColor={COLORS.TEXT_ACTIVE} borderTopWidth='3' mt='1' />
            </VStack>
          </HStack>
        </VStack>
      </VStack>
    )
  }

  _renderOrderSummary = () => {
    const { selectedMainPackage } = this.state
    // console.log('selectedMainPackage => ', selectedMainPackage)
    if (_.isNil(selectedMainPackage)) {
      return null
    }
    const { name, billing_cycle, price_with_vat } = selectedMainPackage
    return (
      <XCard mx='2' mt='2' flex={1}>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} alignItems='center' justifyContent='center' h='10' w='full'>
          <XText textAlign='center' bold fontSize='lg'>
            สรุปคำสั่งซื้อ
          </XText>
        </HStack>
        {!_.isNil(selectedMainPackage) ? this._renderOrderSummaryList(name, billing_cycle, price_with_vat) : null}
        {this._renderOrderAddOnSummary()}
        {this._renderOrderSummaryTotal()}
      </XCard>
    )
  }

  _renderScbPayment = () => (
    <VStack w='full' alignItems='center' justifyContent='center' m='2'>
      <XCard
        p='2'
        w='72'
        borderWidth='1'
        borderColor={COLORS.SCB_MAIN}
        bgColor='#ECE8F1'
        onPress={() => {
          Clipboard.setString('1623004722')
          p.op.showToast(`คัดลอกเลขบัญชี 1623004722 เรียบร้อยแล้ว`, 'success')
        }}>
        <HStack>
          <HStack w='16'>
            <XImage source={IMG_SCB} w='16' h='16' />
          </HStack>
          <VStack flex={1} ml='2'>
            <XText>ธนาคาร ไทยพาณิชย์ (SCB)</XText>
            <XText>สาขา บางแค</XText>
          </VStack>
        </HStack>
        <VStack w='full' alignItems='center' justifyContent='center'>
          <XText fontSize='md'>บริษัท เอฟฟิลี่ จำกัด</XText>
          <XText fontSize='lg' color={COLORS.SCB_MAIN}>
            162-3-00472-2
          </XText>
          <XText fontSize='xs' textDecorationLine='underline'>
            คัดลอกเลขที่บัญชี
          </XText>
        </VStack>
      </XCard>
    </VStack>
  )

  _renderBankPaymentDropzone = () => {
    // @ts-ignore
    const { selectedFile: sf, uploadedUrl } = this.state

    if (uploadedUrl) {
      return (
        <VStack w='full' p='2' space='2'>
          <XText fontSize='md' bold>
            อัพโหลดหลักฐานการชำระเรียบร้อย
          </XText>
          {/* <XText variant='inactive'>หมายเลขคำสั่งซื้อ #00000001</XText> */}
          {/* <XText variant='inactive' color={COLORS.RED}>
            กรุณารอระบบทำการตรวจสอบหลักฐานการชำระเงินภายใน 30 นาที
          </XText> */}
          {/* <XText variant='inactive' color={COLORS.RED}>
            ระบบจะอัพเดทแพ็กเกจของคุณให้อัตโนมัติ
          </XText> */}

          <TouchableOpacity onPress={() => Linking.openURL(uploadedUrl)}>
            <XText variant='active' style={{ textDecorationLine: 'underline' }}>
              {uploadedUrl}
            </XText>
          </TouchableOpacity>

          {/* <TouchableOpacity
            onPress={() => {
              // @ts-ignore
              this.setState({ uploadedUrl: null, selectedFile: null })
            }}>
            <XText variant='primary'>อัพโหลดใหม่</XText>
          </TouchableOpacity> */}
        </VStack>
      )
    }

    return (
      <VStack w='full' p='2' space='1.5'>
        <XText fontSize='md' bold>
          แนปรูปสลิป
        </XText>
        <XText variant='inactive'>รองรับไฟล์ JPG , PNG , PDF ในการแนบสลิปหลักฐานการชำระ และคลิกปุ่ม 'ยืนยันชำระ' เพื่อยืนยันการชำระ</XText>

        <XDocumentDropzone
          onFileSelect={async (file) => {
            console.log('XDocumentDropzone onDrop file', file)
            // @ts-ignore
            this.setState({ selectedFile: file })
          }}
          acceptFileTypes={['image', 'pdf']}
          renderContent={({ isDragActive, openDocumentPicker }) => (
            <TouchableOpacity onPress={openDocumentPicker}>
              <VStack
                w='full'
                minH='100px'
                p='2'
                alignItems='center'
                justifyContent='center'
                borderWidth='1'
                borderRadius='lg'
                borderColor='primary.500'
                borderStyle='dashed'
                bg={isDragActive ? 'primary.500' : 'primary.100'}>
                {sf && <XText>ไฟล์ที่เลือก...</XText>}
                {sf && (
                  <VStack
                    w='250px'
                    minH='80px'
                    p='1'
                    space='1.5'
                    borderWidth='1'
                    borderRadius='lg'
                    borderColor='muted.400'
                    bg='white'
                    // justifyContent='center'
                    // alignItems='center'
                  >
                    {sf.mime.startsWith('image') && (
                      <XImage
                        source={{ uri: `data:image/png;base64,${sf.data}` }}
                        alt='image'
                        w='50px'
                        h='50px'
                        borderRadius='md'
                        resizeMode='cover'
                      />
                    )}

                    {sf.mime.startsWith('application/pdf') && (
                      <XIcon name='file-pdf-box' family='MaterialCommunityIcons' size='lg' color='red.500' />
                    )}

                    <HStack w='full'>
                      <XText variant='inactive'>
                        ชื่อไฟล์:{' '}
                        <XText variant='active' bold>
                          {sf.name}
                        </XText>
                      </XText>
                    </HStack>
                    <HStack w='full'>
                      <XText variant='inactive'>
                        ชนิดไฟล์: <XText variant='active'>{sf.mime}</XText>
                      </XText>
                    </HStack>

                    <HStack style={{ position: 'absolute', top: -12, right: -12 }}>
                      <XIconButton
                        name='close-circle'
                        onPress={() => {
                          // @ts-ignore
                          this.setState({ selectedFile: null })
                        }}
                      />
                    </HStack>
                  </VStack>
                )}

                {!sf && <XText>วางไฟล์ที่นี่ หรือคลิกที่นี่</XText>}
                {!sf && <XText variant='inactive'>ต้องเป็นไฟล์รูป หรือ pdf เท่านั้น</XText>}
              </VStack>
            </TouchableOpacity>
          )}
        />
        {this._renderDateTimePickerCard()}
        {/* onPress={this._onTestUploadPress} */}
        {/* disabled={!sf} isDisabled={!sf} */}
        {util.isDevMode() ? (
          <XButton onPress={() => this._onSubmitUploadDayAddOne()}>
            <XText>ยืนยันชำระ DAY + 1</XText>
          </XButton>
        ) : null}
        <XButton onPress={() => this._onSubmitUpload()}>
          <XText>ยืนยันชำระ</XText>
        </XButton>
      </VStack>
    )
  }

  _renderSumTotalPayment = () => {
    const { isTaxWithheld } = this.state
    const price = this._getSumTotalPayment()
    const whTex = this._getSumTotalWhTex()
    const sumTotalPrice = isTaxWithheld ? price - whTex : price
    return (
      <HStack w='full' justifyContent='center' alignItems='center' mx='2' mt='2'>
        <XText fontSize='md' bold>
          ยอดสุทธิชำระ
        </XText>
        <VStack w='24' justifyContent='flex-end' mx='2'>
          <XText bold fontSize='md' textAlign='center'>
            {xFmt.formatCurrencyFixedPoint(sumTotalPrice)}
          </XText>
          <HStack borderTopColor={COLORS.TEXT_ACTIVE} borderTopWidth='1' />
          <HStack borderTopColor={COLORS.TEXT_ACTIVE} borderTopWidth='3' mt='1' />
        </VStack>
        <TouchableOpacity
          onPress={() => {
            Clipboard.setString(`${sumTotalPrice}`)
            p.op.showToast(`คัดลอกยอดชำระ ${xFmt.formatCurrencyFixedPoint(sumTotalPrice)} เรียบร้อยแล้ว`, 'success')
          }}
          style={{
            width: 120,
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 1,
            borderColor: COLORS.APP_MAIN,
            borderRadius: 90,
            paddingBottom: 6,
            paddingTop: 6,
          }}>
          <XText color={COLORS.APP_MAIN}>คัดลอกยอดชำระ</XText>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderDateTimePickerCard = () => {
    const { paymentPostDate } = this.state

    return (
      <VStack w='full'>
        <XText variant='inactive'>เวลาชำระ</XText>
        <HStack w='full' minH='9'>
          <XDateTimeSinglePicker selectedDate={paymentPostDate || new Date()} onChangeDate={this._onChangePaymentPostDate} />
        </HStack>
      </VStack>
    )
  }

  _renderPendingOrder = () => {
    const { selectedMainPackage, pendingOrderData } = this.state
    if (_.isNil(selectedMainPackage) || _.isNil(pendingOrderData)) {
      return null
    }
    return <XPendingSubcriptionPayment packageName={selectedMainPackage.name} pendingOrderData={pendingOrderData} />
  }

  _renderBankPayment = () => {
    const { selectedMainPackage, addOnPackages, pendingOrderData } = this.state
    if (!_.isNil(pendingOrderData)) {
      return null
    }
    if (_.isNil(selectedMainPackage) && _.isNil(addOnPackages)) {
      return null
    }

    const PAYMENT_BANK_DESCRIPTION = (description: string) => (
      <HStack>
        <HStack w='8' alignItems='flex-start' justifyContent='center'>
          <XIcon name='dot-single' family='Entypo' color={COLORS.TEXT_ACTIVE} />
        </HStack>
        <HStack flex={1}>
          <XText>{description}</XText>
        </HStack>
      </HStack>
    )

    return (
      <XCard mx='2' mt='2'>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} alignItems='center' justifyContent='center' h='10'>
          <XText textAlign='center' bold fontSize='lg'>
            ชำระ
          </XText>
        </HStack>
        <XText ml='2' my='1'>
          ชำระ โอนเงินผ่านบัญชีธนาคาร
        </XText>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} alignItems='center' justifyContent='flex-start' h='8'>
          <XText ml='2' textAlign='center' bold>
            คำแนะนำ
          </XText>
        </HStack>
        <VStack my='1'>
          {PAYMENT_BANK_DESCRIPTION('กรุณาแนบสลิปการชำระที่มี QR Code การชำระเพื่อให้ระบบตรวจสอบการชำระ และอัพเดทแพ็กเกจให้อัตโนมัติ')}
          {PAYMENT_BANK_DESCRIPTION('หากแนบสลิปที่ไม่มี QR Code การชำระจะต้องรอทีมงานตรวจสอบข้อมูลการชำระ ภายใน 24 ชั่วโมง')}
          {PAYMENT_BANK_DESCRIPTION('รองรับไฟล์ JPG , PNG , PDF')}
        </VStack>
        {this._renderScbPayment()}
        {this._renderSumTotalPayment()}
        {this._renderBankPaymentDropzone()}
      </XCard>
    )
  }

  _renderChangeBillingCycleOverlay = () => {
    const { isvisibleChangeBillingCycle, selectedMainPackage, subscriptionPackageList } = this.state
    if (_.isNil(selectedMainPackage)) {
      return null
    }
    // console.log('subscriptionPackageList => ', subscriptionPackageList)
    return (
      <ChangeBillingCycle
        visible={isvisibleChangeBillingCycle}
        onRequestClose={() => this.setState({ isvisibleChangeBillingCycle: false })}
        mainPackageSku={selectedMainPackage.sku}
        subscriptionPackageList={subscriptionPackageList}
        billingCycle={selectedMainPackage.billing_cycle}
        onSelected={(month) => this._onChangeBillingCycle(month)}
        key={selectedMainPackage.sku}
      />
    )
  }

  _renderSubscriptionAddressContactCard = () => {
    const { subscriptionAddressContact, pendingOrderData } = this.state
    // console.log('subscriptionAddressContact => ', subscriptionAddressContact)
    const txtReceiver = _.isNil(subscriptionAddressContact)
      ? 'ยังไม่ได้ระบุที่อยู่'
      : util.convertAddressToInlineAddress(subscriptionAddressContact)
    // const txtReceiver = 'ยังไม่ได้ระบุที่อยู่'
    return (
      <XCard w='full' mt='2' onPress={() => this._navToEditAddressView()}>
        <XCard.Body>
          <HStack w='full' alignItems='center' justifyContent='space-between' space='1'>
            <HStack flex={1} space='1'>
              <XIcon variant={!_.isNil(pendingOrderData) ? 'inactive' : 'primary'} family='FontAwesome' name='home' />
              <VStack flex={1} flexWrap='wrap'>
                <XText variant='inactive'>ที่อยู่สำหรับใบกำกับภาษี</XText>
                <XText w='full'>{txtReceiver}</XText>
              </VStack>
            </HStack>
            <XIcon
              variant={_.isNil(pendingOrderData) ? 'primary' : 'inactive'}
              style={{
                fontSize: STYLES.FONT_ICON_NORMAL,
                // color: true ? COLORS.TEXT_PRIMARY : COLORS.TEXT_PRIMARY,
              }}
              name='arrow-forward'
            />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderTaxReceiptRequested = () => {
    const { isTaxReceiptRequested, isTaxWithheld, pendingOrderData } = this.state
    const isShowWithheld = this._showWithheld()
    const onChangeChackbox = () => {
      if (!_.isNil(pendingOrderData)) {
        return
      }
      this.setState({
        isTaxReceiptRequested: !isTaxReceiptRequested,
        isTaxWithheld: !isTaxReceiptRequested === false ? false : isShowWithheld,
      })
    }
    const renderTaxWithheld = () => {
      const onChangeTaxWithheldChackbox = () => this.setState({ isTaxWithheld: !isTaxWithheld })
      return (
        <TouchableOpacity
          onPress={() => {
            if (!_.isNil(pendingOrderData)) {
              return
            }
            onChangeTaxWithheldChackbox()
          }}>
          <HStack alignItems='center'>
            <XIcon
              family='FontAwesome'
              name={isTaxWithheld ? 'check-square-o' : 'square-o'}
              variant={!_.isNil(pendingOrderData) ? 'inactive' : 'primary'}
            />
            <XText variant='active' ml='2'>
              หัก ณ ที่จ่าย
            </XText>
          </HStack>
        </TouchableOpacity>
      )
    }
    return (
      <XCard mx='2' mt='2'>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} alignItems='center' justifyContent='center' h='10' w='full'>
          <XText textAlign='center' bold fontSize='lg'>
            ใบกำกับภาษี
          </XText>
        </HStack>
        <VStack p='2'>
          <TouchableOpacity onPress={() => onChangeChackbox()}>
            <HStack alignItems='center'>
              <XIcon
                family='FontAwesome'
                name={isTaxReceiptRequested ? 'check-square-o' : 'square-o'}
                variant={!_.isNil(pendingOrderData) ? 'inactive' : 'primary'}
              />
              <XText variant='active' ml='2'>
                ใบกำกับภาษี
              </XText>
            </HStack>
          </TouchableOpacity>
          {isTaxReceiptRequested && isShowWithheld ? renderTaxWithheld() : null}
          {isTaxReceiptRequested ? this._renderSubscriptionAddressContactCard() : null}
        </VStack>
      </XCard>
    )
  }

  render() {
    const { isLoading } = this.state
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent>
          <VStack bgColor={COLORS.WHITE}>
            {this._renderProfile()}
            {this._renderPendingOrder()}
            {this._renderTitleDescription()}
            {/* {this._renderSelectedMonthPackage()} */}
            {this._renderMySelectedPackage()}
            {this._renderAddOnPackage()}
            {this._renderOrderSummary()}
            {this._renderTaxReceiptRequested()}
            {this._renderPendingOrder()}
            {this._renderBankPayment()}

            {this._renderChangeBillingCycleOverlay()}
            {this._renderOrderCountHistoryOverlay()}
            {isLoading ? (
              <VStack
                alignItems='center'
                justifyContent='center'
                bg='gray.400:alpha.50'
                style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <XSpinner />
              </VStack>
            ) : null}
          </VStack>
        </XContent>
      </XContainer>
    )
  }
}
