import React from 'react'
import _ from 'lodash'
import { diff } from 'deep-object-diff'
import { ListRenderItemInfo, FlatList, TouchableOpacity, LayoutChangeEvent } from 'react-native'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import Center from 'xui/components/Center'
import Divider from 'xui/components/Divider'
import NB from 'x/config/nativebase'
import XContainer from 'xui/components/XContainer'
import OrderListHeaderTabs from 'xui/components/orders/OrderListHeaderTabs'
import OrderListItem from 'xui/components/orders/OrderListItem'

import {
  IOrderListViewProps,
  IOrderListViewState,
  IOrderListItem,
  IOrdersSortOptions,
  IOrdersFilter,
  IOrderListFilterTemplate,
  IOrderListFilterPreference,
  IOrderListItemVisibilitySetting,
  IMultipleOperationOrdersSummaryInfo,
  PrintOptionKey,
} from 'x/index'
import Menu, { MenuOption, MenuOptions, MenuTrigger, renderers } from 'xui/components/popup'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import * as acl from 'x/utils/acl'
import CONS from 'x/config/constants'
import XText from 'xui/components/XText'

import BaseOrderListView from 'x/modules/orders/BaseOrderListView'
// import OrderListFilterButton from 'xui/components/orders/OrderListFilterButton'

// import DrawerOrderListFilter from 'xui/components/DrawerOrderListFilter'
import XIconButton from 'xui/components/XIconButton'
import VisibilitySensor from 'xui/components/VisibilitySensor'
import XSpinner from 'xui/components/XSpinner'
import OrdersSortIconButton from 'xui/components/orders/OrdersSortIconButton'
import OrderListDisplayModeControl from 'xui/components/orders/OrderListDisplayModeControl'
import OrderListPaginator from 'xui/components/orders/OrderListPaginator'
import XBlankButton from 'xui/components/XBlankButton'
import XIcon, { XIconProps } from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import { COLORS } from 'x/config/styles'
import FilterButton from 'xui/components/FilterButton'
import OrderListFilterTemplateSelector from 'xui/components/orders/OrderListFilterTemplateSelector'
import OrderListItemVisibilityControl from 'xui/components/orders/OrderListItemVisibilityControl'
import ShippingTypePicker from 'xui/components/order/ShippingTypePicker'
import PrintingOptionUI from 'xui/components/PrintingOptionUI'
import XSwitch from 'xui/components/XSwitch'
import HelpButton from 'xui/components/HelpButton'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
// import XButton from 'xui/components/XButton'

interface IOpMenuItem {
  key: string
  icon?: JSX.Element
  iconProps?: XIconProps
  label: string
  onRun: () => void
}

interface IOpMenuHeader {
  label: string
  isHeader: true
}

type IOpMenu = IOpMenuItem | IOpMenuHeader

class BaseUIOrderListView<P extends IOrderListViewProps, S extends IOrderListViewState> extends BaseOrderListView<P, S> {
  static displayName = 'BaseUIOrderListView'

  // drawerRef?: React.RefObject<DrawerOrderListFilter>

  // constructor(props) {
  //   super(props)

  //   this.drawerRef = React.createRef()
  // }

  operationMenus?: IOpMenu[]

  preSetupInitialState = async () => {
    // generate operation popup menus
    this.operationMenus = this._generateOperationMenus()

    const newState: Partial<S> = {}

    // load switches
    const isForceCreateShipping = await p.op.storageGet(CONS.STORAGE_KEYS.ORDER_LIST_IS_FORCE_CREATE_SHIPPING)
    if (_.isBoolean(isForceCreateShipping)) {
      newState.isForceCreateShipping = isForceCreateShipping
    }

    const isCreateShippingOnlyWithXShipping = await p.op.storageGet(CONS.STORAGE_KEYS.ORDER_LIST_IS_CREATE_SHIPPING_ONLY_WITH_XSHIPPING)
    if (_.isBoolean(isCreateShippingOnlyWithXShipping)) {
      newState.isCreateShippingOnlyWithXShipping = isCreateShippingOnlyWithXShipping
    }

    if (!_.isEmpty(newState)) {
      await util.setStatePromise(this, newState)
    }
  }

  _generateOperationMenus = (): IOpMenu[] => {
    const menus: IOpMenu[] = [
      { label: 'ออเดอร์', isHeader: true },
      {
        key: 'print',
        label: 'พิมพ์เอกสาร',
        iconProps: { name: 'printer', family: 'MaterialCommunityIcons' },
        onRun: this.openPrintOptionOverlay,
      },
      {
        key: 'auto_complete_shippings',
        label: 'บันทึกจัดส่งอัตโนมัติ',
        iconProps: { name: 'truck-delivery', family: 'MaterialCommunityIcons' },
        onRun: this.autoCompleteShippings,
      },
      { label: 'จัดส่ง', isHeader: true },
      {
        key: 'check',
        label: 'เปลี่ยนขนส่ง',
        // icon: <XIcon w='32px' variant='active' name='card-bulleted-settings' family='MaterialCommunityIcons' />,
        iconProps: { name: 'local-shipping', family: 'MaterialIcons' },
        onRun: this.openShippingTypePicker,
      },
    ]

    return menus
  }

  onPressSubmitCheckedOrders = async (checkedOrders: IOrderListItem[]) => {
    const { selectedMultipleOperationIndex } = this.state

    if (checkedOrders.length === 0) {
      p.op.alert('ไม่สามารถดำเนินการได้', 'กรุณาเลือกออเดอร์ที่ต้องการดำเนินการ')
      return
    }

    try {
      const selectedOpMenu = this.operationMenus[selectedMultipleOperationIndex]
      if (!selectedOpMenu || !('onRun' in selectedOpMenu)) {
        p.op.alert('ไม่สามารถดำเนินการได้', 'กรุณาเลือกดำเนินการที่ต้องการ')
        return
      }

      selectedOpMenu.onRun()
    } catch (error) {
      console.log('onPressSubmitCheckedOrders multi-op error => ', error)
    }
  }

  _onPressOrderTabButton = async (newTabKey: string) => {
    // console.log('_onPressOrderTabButton newTabKey => ', newTabKey)
    if (this.isRefreshing) {
      // console.log('_onPressOrderTabButton still refreshing!!')
      return
    }
    const { tabName, displayMode } = this.state
    // console.log('_onPressOrderTabButton tabName => ', tabName)

    let forceRefresh = false

    if (tabName && tabName === newTabKey) {
      forceRefresh = true
    } else {
      await util.setStatePromise(this, {
        tabName: newTabKey,
        ordersCurrentPage: 1,
        displayMode: this.isNoSummaryCountTabKey(newTabKey) ? 'list' : displayMode,
      })

      const orderCount = this.getFetchedOrdersCount(newTabKey)
      const hasMoreOrders = this.getHasMoreOrders(newTabKey)
      forceRefresh = orderCount === 0 && hasMoreOrders
    }
    if (this.isOrdersDisplayPageMode()) {
      forceRefresh = true
    }

    if (forceRefresh) {
      this.isRefreshing = false
      this.isLoadingMore = false
      // const focusOrders = this.getOrders(newTabKey)
      // if (!focusOrders || focusOrders.length === 0) {
      await this.loadInitOrders(newTabKey)
      // }
    }
  }

  getIsGoBackButtonHidden = () => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'isGoBackButtonHidden', false)
  }

  _onOrderListItemDataChange = (tabName: string, newData: IOrderListItem, index: number) => {
    this.updateOrder(tabName, newData, index)
  }

  renderOrderListItem = (listItemInfo: ListRenderItemInfo<IOrderListItem>) => {
    // const { selectedStore: ss } = this.props
    const { isCheckable, tabName, listItemVisibilitySetting } = this.state
    const { item: od, index: odIdx } = listItemInfo
    return (
      <OrderListItem
        key={`${tabName}_${odIdx}_${od.id}_${od.y}`}
        isCheckable={isCheckable}
        checked={this.isOrderListItemChecked(od.id)}
        onCheckedPress={isCheckable ? this.toggleCheckOrderListItem : undefined}
        // selectedStore={ss}
        data={od}
        index={odIdx}
        onPress={this._onPressOrderListItem}
        visibilitySetting={listItemVisibilitySetting}
        onDataChange={(newData, index) => this._onOrderListItemDataChange(tabName, newData, index)}
        onErrorItemUpdate={this.updateErrorOrder}
        errorMessage={this.getOrderErrorMessage(od.id)}
        resetErrorMessage={this.resetErrorOrder}
        onRequestEditShippingType={(odId: number, newShippingTypeId: number) => this.editShippingType(newShippingTypeId, [odId])}
      />
    )
  }

  renderOrderListFooter = () => {
    const { isOrdersLoadingMore, tabName } = this.state
    const currentOrderCount = this.getFetchedOrdersCount(tabName)
    const maxOrderCount = this.getSummaryOrdersCount(tabName)

    const hasNoMoreOrders = this.getHasNoMoreOrders(tabName)

    if (hasNoMoreOrders && currentOrderCount === 0) {
      const orderLabel = this.getTabLabel(tabName)
      return (
        <Center py='4'>
          <XText variant='inactive'>{`ไม่มีออเดอร์ในรายการ "${orderLabel}"`}</XText>
        </Center>
      )
    }

    if (this.isOrdersDisplayPageMode()) {
      return null
    }

    if (hasNoMoreOrders || (currentOrderCount && maxOrderCount && currentOrderCount >= maxOrderCount)) {
      return (
        <Center py='2'>
          <XText variant='inactive'>สิ้นสุดรายการ</XText>
        </Center>
      )
    }

    const isLoadMoreVisible =
      (currentOrderCount && !maxOrderCount) || (currentOrderCount && maxOrderCount && currentOrderCount < maxOrderCount)

    if (isLoadMoreVisible && !isOrdersLoadingMore) {
      return (
        <VisibilitySensor onChange={this.handleLoadMoreButtonVisibility}>
          <XBlankButton
            my='2'
            variant='outline'
            _light={{ bg: NB.C.L.BG.CLEAR }}
            _dark={{ bg: NB.C.D.BG.CLEAR }}
            onPress={() => this.loadMoreOrders(tabName)}>
            <XIcon
              // my='2'
              family='FontAwesome'
              name='angle-double-down'
              // variant='outline'
              // onPress={() => this.loadMoreOrders(tabName)}
            />

            {/* <XButton mt='2' isDisabled={isOrdersLoadingMore} isLoading={isOrdersLoadingMore} onPress={() => this.loadMoreOrders(tabName)}>
          {isOrdersLoadingMore ? null : 'โหลดเพิ่มเติม'}
        </XButton> */}
          </XBlankButton>
        </VisibilitySensor>
      )
    }

    if (isOrdersLoadingMore) {
      return <XSpinner variant='inactive' my='2' />
    }

    return null
  }

  // onOrderListScroll = (evt) => {
  //   console.log('onOrderListScroll evt.nativeEvent => ', evt.nativeEvent)
  // }

  renderOrderListItems = () => {
    // const { tabName, isOrdersRefreshing } = this.state
    // const { orders, selectedStore } = this.props
    const { tabName, isOrdersRefreshing, isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    const orders = this.getOrders(tabName)
    // console.log('renderOrderListItems orders => ', orders)
    // if (!orders.has(tabName)) {
    if (
      (!orders || (orders.length === 0 && this.getHasMoreOrders(tabName))) &&
      !isOrdersRefreshing
      // && !this.isOrdersDisplayPageMode()
    ) {
      return (
        <VisibilitySensor
          key={`${tabName}`}
          checkInvisibleAtSecond={1}
          onCheckInvisibleAtSecond={() => {
            this.loadInitOrders(tabName)
          }}
          onChange={(visible) => {
            // console.log('VisibilitySensor visible => ', visible)
            if (visible) {
              // this._loadInitOrders(tabName)
              this.loadInitOrders(tabName)
            }
          }}>
          <XSpinner variant='inactive' my='2' />
        </VisibilitySensor>
      )
    }

    if (!orders) {
      return null
    }

    if (isOrdersRefreshing) {
      return <XSpinner variant='inactive' my='2' />
    }

    return (
      <FlatList<IOrderListItem>
        refreshing={isOrdersRefreshing}
        onRefresh={() => this.loadInitOrders(tabName)}
        // style={{ flex: 1, paddingVertical: 4, paddingHorizontal: 8, backgroundColor: COLORS.BG_LIGHT_GREY }}
        style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}
        contentContainerStyle={{ paddingVertical: 4, paddingHorizontal: 8 }}
        // flex={1}
        // // _web={{ p: '1' }}
        // px='1'
        // py='2'
        // bg='gray.300'
        // // _contentContainerStyle={{
        // //   bg: 'gray.400',
        // // }}
        key={tabName}
        // data={orders.get(tabName).toArray()}
        data={orders}
        renderItem={this.renderOrderListItem}
        onEndReached={this.onOrderListScrollReached}
        onEndReachedThreshold={0.2}
        // onScroll={this.onOrderListScroll}
        // onMomentumScrollEnd={this.onOrderListScroll}
        // scrollEventThrottle={4}
        ListFooterComponent={this.renderOrderListFooter()}
        ItemSeparatorComponent={this.renderItemSeparatorComponent}
        // keyExtractor={(item, idx) => `${tabName}_${item.get('id')}_${idx.toString()}`}
        keyExtractor={this.keyExtractor}
      />
    )
  }

  keyExtractor = (item, idx) => `${this.state.tabName}_${item.id}_${idx.toString()}`

  renderItemSeparatorComponent = () => <Box h='2' />

  // onApplyOrderListFilter = async (newSetting: { isCOD?: boolean; isShipBeforePay?: boolean }) => {
  //   // console.log('_onApplyFilter newSetting => ', newSetting)
  //   // this._closeDrawer()
  //   // const { tabName } = this.state
  //   const { filterSetting, orderListStateActions } = this.props
  //   if (Map.isMap(filterSetting)) {
  //     const oldSetting = filterSetting.toJS()
  //     const diffSetting = diff(oldSetting, newSetting)
  //     if (!_.isEmpty(diffSetting)) {
  //       // orderListStateActions.setOrdersFilterSetting({ ...this.state.defaultFilterSetting, ...newSetting })
  //       // @ts-ignore
  //       orderListStateActions.setOrdersFilterSetting(newSetting)
  //       await util.delay(200)
  //       // orderListStateActions.setIsInitedByTabKey(this.tabName, false)

  //       // // ถ้ามีการใช้ page mode
  //       // if (this._isOrdersDisplayPageMode()) {
  //       //   await this._onSubmitOrdersNewPage(1)
  //       //   return
  //       // }

  //       await this.loadInitOrders(this.state.tabName)
  //     }
  //   }
  // }

  // onApplyOrderListFilter = async (newSetting: Partial<IFetchOrdersRequestBody>) => {
  onApplyOrderListFilter = async (newSetting: IOrdersFilter) => {
    const { tabName, ordersFilterSetting: oldSetting } = this.state
    // console.log('onApplyOrderListFilter newSetting => ', newSetting)

    const diffSetting = diff(oldSetting, newSetting)
    if (!_.isEmpty(diffSetting)) {
      await util.setStatePromise(this, { ordersFilterSetting: newSetting, ordersCurrentPage: 1 })
      await this.clearOrdersAllTabs()
      await util.delay(200)
      await this.loadInitOrders(tabName)
    }

    return true
  }

  handleLoadMoreButtonVisibility = (visible) => {
    // handle visibility change
    // console.log('handleLoadMoreButtonVisibility => ', visible)
    if (!visible || this.isOrdersDisplayPageMode()) {
      return
    }
    const { isOrdersLoadingMore, tabName } = this.state

    if (!isOrdersLoadingMore) {
      this.loadMoreOrders(tabName)
    }
  }

  // _closeDrawer = () => {
  //   const drawerRef = this.drawerRef.current
  //   if (drawerRef && drawerRef && _.isFunction(drawerRef.close)) {
  //     drawerRef.close()
  //   }
  // }

  // _openDrawer = () => {
  //   const drawerRef = this.drawerRef.current
  //   if (drawerRef && drawerRef && _.isFunction(drawerRef.open)) {
  //     drawerRef.open()
  //   }
  // }

  onSortOptionsApply = async (sortOptions: IOrdersSortOptions) => {
    // console.log('OrdersSortIconButton onApply sortOptions => ', sortOptions)
    await util.setStatePromise(this, { sortOptions })
    await util.delay(200)

    // if (this._isOrdersDisplayPageMode() && this.state.ordersCurrentPage > 1) {
    //   await util.setStatePromise(this as any, { ordersCurrentPage: 1 })
    //   await util.delay(200)
    // }

    await this.loadInitOrders(this.state.tabName)
    // await util.delay(200)
  }

  _onSortOptionsApplyWithSaveState = async (sortOptions: IOrdersSortOptions) => {
    await this.onSortOptionsApply(sortOptions)
    // await util.saveDefaultCurrentViewState(this as any, this._getDefaultStateSavedKey(), { sortOptions })
  }

  renderSortByIcon = () => (
    <OrdersSortIconButton
      // btnStyle={{ marginRight: 4 }}
      currentSortOptions={this.state.sortOptions}
      onApply={this._onSortOptionsApplyWithSaveState}
    />
  )

  renderListItemVisibilitySettingIcon = () => {
    const { listItemVisibilitySetting } = this.state

    return (
      <OrderListItemVisibilityControl
        onSubmit={this._onSubmitListItemVisibility}
        disabled={false}
        visibilitySetting={listItemVisibilitySetting}
      />
    )
  }

  _onSubmitListItemVisibility = (visibilitySetting: IOrderListItemVisibilitySetting) => {
    console.log('_onSubmitListItemVisibility visibilitySetting => ', visibilitySetting)
    this.setState({ listItemVisibilitySetting: visibilitySetting }, () => {
      p.op.storageSet(CONS.STATE_SAVE_KEYS.ORDER_LIST_ITEM_VISIBILITY_CONTROL, visibilitySetting, true)

      const { tabName } = this.state
      this.loadInitOrders(tabName)
    })
  }

  _navToOrderListFilterView = () => {
    const { navigation } = this.props
    const { ordersDefaultFilterSetting, ordersFilterSetting } = this.state

    navigation.dispatch(
      NavActions.navToOrderListFilterView({
        store_id: util.getNavParam(this.props, 'store_id'),
        defaultSetting: ordersDefaultFilterSetting,
        appliedSetting: ordersFilterSetting,
        onApplyFilter: this.onApplyOrderListFilter,
      })
    )
  }

  isFiltered = () => {
    const { ordersDefaultFilterSetting, ordersFilterSetting } = this.state
    const diffFilter: Partial<IOrderListFilterTemplate> = diff(ordersDefaultFilterSetting, ordersFilterSetting)

    // console.log('isFiltered diffFilter => ', diffFilter)
    if (
      ordersFilterSetting.autoCancelOrderDateRangeOptionKey &&
      !diffFilter.autoCancelOrderDateRangeOptionKey &&
      ordersFilterSetting.autoCancelOrderDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.autoCancelOrderFrom
      delete diffFilter.autoCancelOrderTo
    }

    if (
      ordersFilterSetting.createOrderDateRangeOptionKey &&
      !diffFilter.createOrderDateRangeOptionKey &&
      ordersFilterSetting.createOrderDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.createOrderFrom
      delete diffFilter.createOrderTo
    }

    if (
      ordersFilterSetting.dateDeliveryDateRangeOptionKey &&
      !diffFilter.dateDeliveryDateRangeOptionKey &&
      ordersFilterSetting.dateDeliveryDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.dateDeliveryFrom
      delete diffFilter.dateDeliveryTo
    }

    if (
      ordersFilterSetting.paymentCompletedAtDateRangeOptionKey &&
      !diffFilter.paymentCompletedAtDateRangeOptionKey &&
      ordersFilterSetting.paymentCompletedAtDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.paymentCompletedAtFrom
      delete diffFilter.paymentCompletedAtTo
    }

    if (
      ordersFilterSetting.readyToShipDateRangeOptionKey &&
      !diffFilter.readyToShipDateRangeOptionKey &&
      ordersFilterSetting.readyToShipDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.readyToShipDateFrom
      delete diffFilter.readyToShipDateTo
    }

    if (
      ordersFilterSetting.shippingCompletedAtDateRangeOptionKey &&
      !diffFilter.shippingCompletedAtDateRangeOptionKey &&
      ordersFilterSetting.shippingCompletedAtDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.shippingCompletedAtFrom
      delete diffFilter.shippingCompletedAtTo
    }

    return diffFilter && !_.isEmpty(diffFilter)
  }

  renderFilterIcon = () => {
    // const { ordersDefaultFilterSetting, ordersFilterSetting } = this.state
    // return (
    //   <OrderListFilterButton
    //     // forceMkpOnly={this.isForceMkpOnly()}
    //     defaultSetting={ordersDefaultFilterSetting}
    //     appliedSetting={ordersFilterSetting}
    //     onApplyFilter={this.onApplyOrderListFilter}
    //   />
    // )
    const isFiltered = this.isFiltered()
    return <FilterButton isFiltered={isFiltered} onPress={this._navToOrderListFilterView} />
  }

  _renderOperationMenuItem = (menu: IOpMenu, index: number) => {
    if ('isHeader' in menu) {
      return (
        <HStack key={`header_menu_${index}`} px='1.5' py='1' bg='muted.200' alignItems='center' space='1'>
          <XText variant='inactive' bold>
            {menu.label}
          </XText>
        </HStack>
      )
    }

    return (
      <MenuOption key={`menu_${index}`} onSelect={() => this.setState({ selectedMultipleOperationIndex: index })}>
        <HStack alignItems='center' space='1'>
          {'icon' in menu ? menu.icon : null}
          {'iconProps' in menu ? <XIcon w='28px' variant='active' {...menu.iconProps} /> : null}
          <XText>{menu.label}</XText>
        </HStack>
      </MenuOption>
    )
  }

  _renderSelectedMultipleOperation = () => {
    const { selectedMultipleOperationIndex = -1, multipleOperationButtonContainerWidth } = this.state
    if (selectedMultipleOperationIndex < 0) {
      return (
        <HStack
          w='full'
          px='2'
          py='1'
          borderWidth='1'
          borderColor='primary.500'
          borderRadius='lg'
          bg='primary.50'
          alignItems='center'
          space='1'>
          {/* <XIcon name='gears' variant='active' family='FontAwesome' /> */}
          <XText flex={1} fontSize={multipleOperationButtonContainerWidth > 520 ? 'sm' : 'xs'}>
            เลือกดำเนินการ...
          </XText>

          <HStack w='34px' alignItems='center' justifyContent='flex-end'>
            <XIcon variant='primary' name='angle-down' family='FontAwesome' />
          </HStack>
        </HStack>
      )
    }

    const selectedOpMenu = this.operationMenus[selectedMultipleOperationIndex]

    return (
      <HStack
        w='full'
        px='2'
        py='1'
        borderWidth='1'
        borderColor='primary.500'
        borderRadius='lg'
        bg='primary.50'
        alignItems='center'
        space='1'>
        <HStack flex={1} alignItems='center' space='1'>
          {'icon' in selectedOpMenu ? selectedOpMenu.icon : null}
          {'iconProps' in selectedOpMenu ? <XIcon variant='active' {...selectedOpMenu.iconProps} /> : null}
          <XText fontSize={multipleOperationButtonContainerWidth > 520 ? 'sm' : 'xs'}>{selectedOpMenu.label}</XText>
        </HStack>

        <HStack w='34px' alignItems='center' justifyContent='flex-end'>
          <XIcon variant='primary' name='angle-down' family='FontAwesome' />
        </HStack>
      </HStack>
    )
  }

  onMultipleOperationButtonLayout = (evt: LayoutChangeEvent) => {
    if (evt.nativeEvent.layout.width > 0) {
      this.setState({ multipleOperationButtonContainerWidth: evt.nativeEvent.layout.width })
    }
  }

  renderMultiOperationSubmitCheckOrdersButton = () => {
    const {
      isCheckable,
      isInitialized,
      multipleOperationButtonContainerWidth = 350,
      selectedMultipleOperationIndex = -1,
      isFooterCollapsed,
    } = this.state
    if (!isCheckable || !isInitialized) {
      return null
    }

    // if (isFooterCollapsed) {
    //   return (
    //     <VStack w='full' pt='2' borderTopWidth='1' borderTopColor='muted.300'>
    //       {this._renderCollapseFooterButton()}
    //     </VStack>
    //   )
    // }

    const checkedOrderCount = this.getCheckedOrderCount()
    if (checkedOrderCount === 0) {
      return (
        <VStack w='full' borderTopWidth='1' borderTopColor='muted.300'>
          <HStack w='full' minH='34px' px='1' py='1' space='1' onLayout={this.onMultipleOperationButtonLayout}>
            <HStack
              w='full'
              px='2'
              py='1'
              // borderWidth='1'
              // borderColor='muted.500'
              borderRadius='lg'
              bg='muted.50'
              alignItems='center'
              justifyContent='center'
              space='1'>
              <XText variant='inactive'>กรุณาเลือกออเดอร์ที่ต้องการดำเนินการ...</XText>
            </HStack>
          </HStack>

          {/* {this._renderCollapseFooterButton()} */}
        </VStack>
      )
    }

    const selectedOpMenu = this.operationMenus[selectedMultipleOperationIndex] || null

    return (
      <VStack w='full'>
        {selectedOpMenu && 'key' in selectedOpMenu && selectedOpMenu.key === 'auto_complete_shippings' && (
          <VStack w='full' pt='2' borderTopWidth='1' borderTopColor='muted.300'>
            {!isFooterCollapsed && this.renderCreateShippingOnlyWithXShippingSwitch()}

            {!isFooterCollapsed && this.renderForceCreateShippingSwitch()}

            {this._renderCollapseFooterButton()}
          </VStack>
        )}

        <HStack w='full' px='1' py='1' space='1' onLayout={this.onMultipleOperationButtonLayout}>
          <Menu
            renderer={renderers.Popover}
            style={{ width: multipleOperationButtonContainerWidth > 520 ? '40%' : '60%' }}
            rendererProps={{ preferredPlacement: 'top' }}
            onBackdropPress={() => this.setState({ selectedMultipleOperationIndex: -1 })}>
            <MenuTrigger>{this._renderSelectedMultipleOperation()}</MenuTrigger>

            <MenuOptions>
              <HStack px='1.5' py='1' minW='170px' alignItems='center' justifyContent='center' space='1'>
                <XText variant='inactive'>เลือกดำเนินการ...</XText>
              </HStack>

              {this.operationMenus.map(this._renderOperationMenuItem)}
            </MenuOptions>
          </Menu>

          {selectedMultipleOperationIndex >= 0 ? (
            <HStack flex={1}>
              <TouchableOpacity
                disabled={this.isSubmitCheckOrdersButtonDisabled()}
                style={{ width: '100%' }}
                onPress={this._onPressSubmitCheckedOrders}>
                <HStack
                  w='full'
                  // w={multipleOperationButtonContainerWidth > 450 ? '250px' : '170px'}
                  minH='34px'
                  px='2'
                  py='1'
                  borderWidth='1'
                  borderColor={this.isSubmitCheckOrdersButtonDisabled() ? 'muted.500' : 'primary.500'}
                  borderRadius='lg'
                  bg={this.isSubmitCheckOrdersButtonDisabled() ? 'muted.50' : 'primary.500'}
                  alignItems='center'
                  justifyContent='center'
                  space='1'>
                  <XText
                    fontSize={this.isSubmitCheckOrdersButtonDisabled() ? 'xs' : 'sm'}
                    variant={this.isSubmitCheckOrdersButtonDisabled() ? 'inactive' : 'activeDark'}
                    bold>
                    {this.getSubmitCheckOrdersButtonText()}
                  </XText>
                </HStack>
              </TouchableOpacity>
            </HStack>
          ) : (
            <HStack flex={1} />
          )}
        </HStack>

        {/* {this._renderCollapseFooterButton()} */}
      </VStack>
    )
  }

  _renderCollapseFooterButton = () => {
    const { isFooterCollapsed = false } = this.state

    return (
      <HStack position='absolute' top='-12px' left='0' right='0' w='full' alignItems='center' justifyContent='center' space='1'>
        <TouchableOpacity onPress={() => this.setState({ isFooterCollapsed: !isFooterCollapsed })}>
          <HStack
            px='2px'
            py='2px'
            borderWidth='1'
            borderColor='muted.300'
            borderRadius='full'
            bg='white'
            alignItems='center'
            justifyContent='center'
            // space='1'
          >
            <XIcon
              size='xs'
              name={isFooterCollapsed ? 'chevron-up' : 'chevron-down'}
              family='FontAwesome'
              variant={isFooterCollapsed ? 'active' : 'inactive'}
            />
            {/* <XText variant='inactive'>{isFooterCollapsed ? 'แสดงเพิ่มเติม' : 'ซ่อน'}</XText> */}
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  // onOrderListFilterTemplateSelect = (nTmpl) => {
  //   const { ordersDefaultFilterSetting } = this.state

  //   if (nTmpl) {
  //     this.onApplyOrderListFilter(nTmpl)
  //     return
  //   }

  //   this.onApplyOrderListFilter(ordersDefaultFilterSetting)
  // }

  onOrderListFilterTemplateSelect = (nTmpl: IOrderListFilterTemplate) => {
    const { ordersDefaultFilterSetting } = this.state
    if (nTmpl && !_.isEmpty(nTmpl)) {
      this.onApplyOrderListFilter(nTmpl)
    } else {
      this.onApplyOrderListFilter(ordersDefaultFilterSetting)
    }
  }

  onOrderListFilterTemplateSaveSuccess = async (newPref: IOrderListFilterPreference, savedTemplate?: IOrderListFilterTemplate) => {
    const { ordersDefaultFilterSetting, ordersFilterSetting } = this.state

    if ('id' in ordersFilterSetting) {
      const { templates = [] } = newPref
      const foundIndex = templates.findIndex((tmpl) => tmpl.id === ordersFilterSetting.id)
      if (foundIndex < 0) {
        this.onApplyOrderListFilter(ordersDefaultFilterSetting)
      }
    }
  }

  renderFilterTemplateIcon = () => {
    const { ordersFilterSetting, isSubmitting, isOrdersRefreshing, isOrdersLoadingMore } = this.state

    return (
      <OrderListFilterTemplateSelector
        navigation={this.props.navigation}
        disabled={isSubmitting || isOrdersRefreshing || isOrdersLoadingMore}
        type='icon'
        selectedTemplate={ordersFilterSetting as IOrderListFilterTemplate}
        onSelect={this.onOrderListFilterTemplateSelect}
        onSaveSuccess={this.onOrderListFilterTemplateSaveSuccess}
      />
    )
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderViewHeader = () => (
    <HStack w='full' minH='10' alignItems='center'>
      <HStack minW='10' px='1'>
        {this.getIsGoBackButtonHidden() ? null : <XIconButton name='arrow-back' onPress={this.goBack} />}
      </HStack>
      <VStack flex={1} justifyContent='center' flexWrap='wrap'>
        <XText>{this.getHeaderTitle()}</XText>
      </VStack>
      <HStack minW='10' px='1' alignItems='center' justifyContent='flex-end' space='1'>
        {/* {this.renderDisplayModeControl()} */}
        {/* {this.renderSortByIcon()} */}
        {this.renderListItemVisibilitySettingIcon()}
        {this.renderFilterTemplateIcon()}
        {this.renderFilterIcon()}
      </HStack>
    </HStack>
  )

  renderOrderListTabsHeader = () => {
    const { availableTabKeys, isOrdersRefreshing, ordersCountSummary } = this.state
    if (!availableTabKeys || availableTabKeys.length <= 1) {
      return null
    }
    return (
      <OrderListHeaderTabs
        availableTabKeys={availableTabKeys}
        // disabled={isOrdersRefreshing || isOrdersLoadingMore}
        disabled={isOrdersRefreshing}
        onChangeTab={this._onPressOrderTabButton}
        // orderCountSummaryMap={this.props.summary ? this.props.summary.toJS() : {}}
        orderCountSummaryMap={ordersCountSummary}
      />
    )
  }

  renderDisplayModeControl = () => {
    const { tabName, displayMode = 'list', displayPageLimit } = this.state
    // const idx = displayMode === 'page' ? 1 : 0
    const disabled = this.isNoSummaryCountTabKey(tabName) || false
    return (
      <OrderListDisplayModeControl
        key={displayMode}
        disabled={disabled}
        // initialIndex={idx}
        initialMode={displayMode}
        initialPageLimit={displayPageLimit}
        // onChange={this._onChangeDisplayMode}
        onSubmit={this._onChangeDisplayMode}
      />
    )
  }

  _onChangeDisplayMode = ({ displayMode, pageLimit }) => {
    this.setState({ displayMode, displayPageLimit: pageLimit, ordersCurrentPage: 1 }, () => {
      const { tabName } = this.state
      this.loadInitOrders(tabName)
      // @ts-ignore
      if (displayMode === 'page' && _.isNumber(pageLimit) && _.includes([10, 30, 50], parseInt(pageLimit))) {
        p.op.storageSet('ordersDisplayPageLimit', pageLimit, false)
      }
    })
  }

  // _onChangeDisplayMode = (opt, idx) => {
  //   this.setState({ displayMode: opt.value, ordersCurrentPage: 1 }, () => {
  //     const { tabName } = this.state
  //     this.loadInitOrders(tabName)
  //     // if (idx === 1) {
  //     //   // page mode
  //     // } else {
  //     //   // list mode (idx=0)
  //     // }
  //   })
  // }

  renderOrderPaginator = () => {
    const isPageMode = this.isOrdersDisplayPageMode()
    if (!isPageMode) {
      return null
    }
    const { tabName, isOrdersRefreshing, ordersCurrentPage } = this.state

    return (
      <VStack minW='140px' alignItems='center' justifyContent='center'>
        <OrderListPaginator
          disabled={isOrdersRefreshing}
          currentPage={ordersCurrentPage}
          maxPage={this.getOrdersMaxPage(tabName)}
          onChangePage={this._onSubmitOrdersNewPage}
          onPressPrevious={this._onSubmitOrdersNewPage}
          onPressNext={this._onSubmitOrdersNewPage}
        />
      </VStack>
    )
  }

  renderCheckAllButton = () => {
    const { isCheckable, tabName, isInitialized, isOrdersRefreshing } = this.state
    if (!isCheckable || !isInitialized || isOrdersRefreshing) {
      return <Box w='10' />
    }
    const checked = this.isOrderListItemsAllChecked(tabName)

    return (
      <TouchableOpacity onPress={this.toggleCheckAllOrderListItems}>
        <Box w='28px' alignItems='center' justifyContent='center'>
          <XIcon family={checked ? 'Ionicons' : 'FontAwesome'} variant='primary' name={checked ? 'checkmark-done-circle' : 'circle-o'} />
        </Box>
      </TouchableOpacity>
    )
    // return (
    //   // <XBlankButton h='full' variant='ghost' >
    //   <XIconButton
    //     onPress={this.toggleCheckAllOrderListItems}
    //     // family='FontAwesome'
    //     // name={checked ? 'check-circle' : 'circle-o'}
    //     family={checked ? 'Ionicons' : 'FontAwesome'}
    //     variant={checked ? 'success' : 'inactive'}
    //     name={checked ? 'checkmark-done-circle' : 'circle-o'}
    //   />
    //   // </XBlankButton>
    // )
  }

  // renderPaginatorSettings = () => (
  //   <HStack>
  //     <XIconButton name='settings' />
  //     <XOverlay />
  //   </HStack>
  // )

  _onSubmitOrdersNewPage = async (newPage: number) => {
    // this._onPressUncheckAll()
    // await util.delay(100)
    await util.setStatePromise(this, { ordersCurrentPage: newPage })

    await util.delay(100)
    const { tabName } = this.state
    await this.loadInitOrders(tabName)
    // const forceOffset = (newPage - 1) * 30
    // this.loadInitOrders(tabName, { overrideRequestBody: { offset: forceOffset, limit: 30 } })
  }

  renderOrdersCheckInfo = () => {
    const { isCheckable, availableTabKeys, tabName, ordersCheckedMap = {} } = this.state
    if (!isCheckable) {
      return null
    }
    // const isMultipleTabs = availableTabKeys.length > 1
    // const inTabCount = this.getCheckedOrdersInTabCount(tabName)
    // const tabLabel = this.getTabLabel(tabName)
    const allTabCount = this.getCheckedOrdersAllTabCount()
    const isUncheckAllAvailable = this.getCheckedOrderCount() > 0
    return (
      <HStack flex={1} flexWrap='wrap' alignItems='center' space='1'>
        {/* <XText>{`เลือกทั้งหมด ${allTabCount}`}</XText> */}
        {/* {isMultipleTabs && allTabCount !== inTabCount ? <XText>{`เลือก ${inTabCount} ใน${tabLabel}`}</XText> : null} */}
        <XText>{`เลือกแล้ว ${allTabCount}`}</XText>
        {/* {isUncheckAllAvailable ? (
          // <HStack>
          <XIconButton
            size='xs'
            name='close-circle'
            iconVariant='danger'
            _icon={{ size: 'xs' }}
            rounded='full'
            onPress={this.uncheckCheckAllOrderListItems}
          />
        ) : //  </HStack>
        null} */}

        {isUncheckAllAvailable ? (
          <TouchableOpacity onPress={this.uncheckCheckAllOrderListItems}>
            <Box w='24px' h='24px' pb='2px' alignItems='center' justifyContent='center'>
              <XIcon size='xs' name='close-circle' variant='danger' rounded='full' />
            </Box>
          </TouchableOpacity>
        ) : null}
      </HStack>
    )
  }

  renderMultipleOperationSummaryInfoItem = (
    info: IMultipleOperationOrdersSummaryInfo,
    idx: number,
    arr: IMultipleOperationOrdersSummaryInfo[]
  ) => {
    const sumLength = arr.length
    if (sumLength > 3 && idx < sumLength - 3) {
      return null
    }

    const { taskId, title, failed_order_count, success_order_count, total_order_count, orderIds, successOrderIds, errorOrderIds } = info

    let type = failed_order_count > 0 ? 'warning' : 'success'
    if (success_order_count === 0) {
      type = 'error'
    }

    const errorOrderNames =
      errorOrderIds.length > 3
        ? `${errorOrderIds
            .slice(0, 3)
            .map((odId) => `#${odId}`)
            .join(', ')} และอีก ${errorOrderIds.length - 3} ออเดอร์`
        : errorOrderIds.map((odId) => `#${odId}`).join(', ')

    const isAllChecked = orderIds.every((odId) => this.isOrderListItemChecked(odId))
    const isSuccessChecked = successOrderIds.every((odId) => this.isOrderListItemChecked(odId))
    const isErrorChecked = errorOrderIds.every((odId) => this.isOrderListItemChecked(odId))

    return (
      <HStack key={`multi_op_summary_${idx}_${taskId}`} w='full' px='0.5' py='1' alignItems='center' bg='transparent'>
        <HStack w='full' minH='9' px='2' py='1.5' alignItems='center' bg={`${type}.600`} borderRadius='lg'>
          <VStack flex={1} flexWrap='wrap' alignItems='center'>
            <XText w='full' fontSize='md' variant='activeDark'>
              <XText w='full' fontSize='xs' variant='activeDark'>
                {`#${taskId} `}
              </XText>

              {title}
            </XText>

            <HStack w='full' alignItems='center' flexWrap='wrap'>
              <HStack alignItems='center' space='1'>
                <TouchableOpacity
                  // disabled={isAllChecked}
                  onPress={() => (isAllChecked ? this.uncheckOrderListItems(orderIds) : this.checkOrderListItems(orderIds))}>
                  <HStack alignItems='center' justifyContent='center' bg='white' borderRadius='full' w='23px' h='23px'>
                    <XIcon
                      family={isAllChecked ? 'Ionicons' : 'FontAwesome'}
                      variant={isAllChecked ? 'primary' : 'inactive'}
                      name={isAllChecked ? 'checkmark-done-circle' : 'circle-o'}
                    />
                  </HStack>
                </TouchableOpacity>
                <XText pl='1' fontSize='sm' variant='activeDark'>
                  {`ทั้งหมด ${total_order_count}: `}
                </XText>
              </HStack>

              <HStack alignItems='center' space='1'>
                {success_order_count > 0 && failed_order_count > 0 ? (
                  <TouchableOpacity
                    // disabled={isSuccessChecked}
                    onPress={() =>
                      isSuccessChecked ? this.uncheckOrderListItems(successOrderIds) : this.checkOrderListItems(successOrderIds)
                    }>
                    <HStack alignItems='center' justifyContent='center' bg='white' borderRadius='full' w='23px' h='23px'>
                      <XIcon
                        family={isSuccessChecked ? 'Ionicons' : 'FontAwesome'}
                        variant={isSuccessChecked ? 'primary' : 'inactive'}
                        name={isSuccessChecked ? 'checkmark-done-circle' : 'circle-o'}
                      />
                    </HStack>
                  </TouchableOpacity>
                ) : null}

                <XText fontSize='sm' variant='activeDark'>
                  {`สำเร็จ ${success_order_count}`}
                </XText>
              </HStack>

              {failed_order_count > 0 ? (
                <HStack alignItems='center' space='1'>
                  <XText fontSize='sm' variant='activeDark'>
                    {`, `}
                  </XText>

                  {success_order_count > 0 && failed_order_count > 0 ? (
                    <TouchableOpacity
                      // disabled={isErrorChecked}
                      onPress={() =>
                        isErrorChecked ? this.uncheckOrderListItems(errorOrderIds) : this.checkOrderListItems(errorOrderIds)
                      }>
                      <HStack alignItems='center' justifyContent='center' bg='white' borderRadius='full' w='23px' h='23px'>
                        <XIcon
                          family={isErrorChecked ? 'Ionicons' : 'FontAwesome'}
                          variant={isErrorChecked ? 'primary' : 'inactive'}
                          name={isErrorChecked ? 'checkmark-done-circle' : 'circle-o'}
                        />
                      </HStack>
                    </TouchableOpacity>
                  ) : null}

                  <XText fontSize='sm' variant='activeDark'>
                    {`ไม่สำเร็จ ${failed_order_count}`}
                  </XText>
                </HStack>
              ) : null}

              {failed_order_count > 0 ? (
                <XText fontSize='sm' variant='activeDark'>
                  {` (${errorOrderNames})`}
                </XText>
              ) : null}
            </HStack>
          </VStack>

          <HStack w='34px' justifyContent='flex-end'>
            <TouchableOpacity onPress={() => this._closeMultipleOperationSummaryBar(taskId)}>
              <XIcon name='close-circle' color='white' />
            </TouchableOpacity>
          </HStack>
        </HStack>
      </HStack>
    )
  }

  _closeMultipleOperationSummaryBar = async (taskId: number) => {
    const { multiOpSummaryInfo } = this.state
    const newMultiOpSummaryInfo = multiOpSummaryInfo.filter((info) => info.taskId !== taskId)

    await util.setStatePromise(this, { multiOpSummaryInfo: newMultiOpSummaryInfo, orderErrors: {} })

    if (newMultiOpSummaryInfo.length > 0) {
      await util.delay(50)

      for (let i = 0; i < newMultiOpSummaryInfo.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        await this.handleResponseFromMultipleOrderOperation(newMultiOpSummaryInfo[i])
        // eslint-disable-next-line no-await-in-loop
        await util.delay(50)
      }
    }
  }

  renderMultipleOperationSummaryBar = () => {
    const { multiOpSummaryInfo = [] } = this.state
    if (_.isNil(multiOpSummaryInfo) || multiOpSummaryInfo.length === 0) {
      return null
    }

    return multiOpSummaryInfo.map(this.renderMultipleOperationSummaryInfoItem)
  }

  renderWarningBar = () => {
    const { warningInfo } = this.state
    if (_.isNil(warningInfo) || _.isEmpty(warningInfo)) {
      return null
    }

    const { text, type = 'success' } = warningInfo

    if (!_.isString(text) || text.length === 0) {
      return null
    }

    return (
      <HStack w='full' minH='9' px='4' py='1.5' alignItems='center' bg={`${type}.600`}>
        <HStack flex={1} flexWrap='wrap' alignItems='center'>
          <XText flex={1} fontSize='md' variant='activeDark'>
            {text}
          </XText>
        </HStack>

        <HStack w='34px' justifyContent='flex-end'>
          <TouchableOpacity onPress={() => this.setState({ warningInfo: null, orderErrors: {} })}>
            <XIcon name='close-circle' color='white' />
          </TouchableOpacity>
        </HStack>
      </HStack>
    )
  }

  // ------------------ BEGIN พิมพ์เอกสาร ------------------
  hasPermissionToPrintDocumentsWithAlert = () => {
    const hasPermissionToPrint = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
    if (!hasPermissionToPrint) {
      p.op.alert('ไม่มีสิทธิ์ในการพิมพ์', 'กรุณาติดต่อเจ้าของร้านหากคุณต้องการพิมพ์เอกสารเหล่านี้')
    }
    return acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
  }

  _onPressPrintDocuments = async (printKey: PrintOptionKey) => {
    const hasPermissionToPrintDocumentsWithAlert = this.hasPermissionToPrintDocumentsWithAlert()
    if (!hasPermissionToPrintDocumentsWithAlert) {
      return
    }

    if (this.isSubmitting) {
      return
    }
    this.isSubmitting = true

    await util.setStatePromise(this, { isSubmitting: true })

    await util.submitPrintingDocuments({
      printKey,
      store_id: this.getStoreId(),
      order_ids: this.getCheckedOrderIds(),
      handleOpenPaperang: p.op.isWeb() ? null : (url) => this._onOpenPaperangFromUrl(url, printKey),
    })

    await util.setStatePromise(this, { isSubmitting: false })

    this.isSubmitting = false
  }

  _onOpenPaperangFromUrl = async (url: string, key: string): Promise<void> => {
    const { selectedStore } = this.props
    let paperangAutoSave = util.checkPaperangAutoSaveImg(selectedStore)

    if (key === 'BILL_PAPERANG') {
      paperangAutoSave = util.checkPaperangAutoSaveImgFromPrintingBill(selectedStore)
    }
    await this.props.navigation.dispatch(
      NavActions.navToPaperangView({
        imageURL: url,
        noAutoSaveImgAtHeightBelow: CONS.NO_AUTO_SAVE_IMG_AT_HEIGHT_BELOW_SHIPING_LABEL.COVER_SHEET,
        disabledAutoSave: paperangAutoSave,
      })
    )
  }
  // ------------------ END พิมพ์เอกสาร ------------------

  renderCreateShippingOnlyWithXShippingSwitch = () => {
    const {
      isCreateShippingOnlyWithXShipping = false,
      selectedMultipleOperationIndex = -1,
      multipleOperationButtonContainerWidth = 350,
    } = this.state
    if (selectedMultipleOperationIndex < 0) {
      return null
    }

    const selectedOpMenu = this.operationMenus[selectedMultipleOperationIndex]

    if ('key' in selectedOpMenu && selectedOpMenu.key !== 'auto_complete_shippings') {
      return null
    }

    if (this.getCheckedOrderCount() === 0) {
      return null
    }

    return (
      <HStack w='full' px='2' py='0.5'>
        <HStack flex={1} alignItems='center'>
          <XText fontSize={multipleOperationButtonContainerWidth > 450 ? 'sm' : 'xs'}>บันทึกจัดส่งเฉพาะที่มีเลขพัสดุแล้ว</XText>
          <HelpButton
            title='บันทึกจัดส่งเฉพาะที่มีเลขพัสดุแล้ว'
            message='เมื่อเปิดใช้งาน จะบันทึกจัดส่งโดยอัตโนมัติต่อเมื่อในออเดอร์ "มีเลขพัสดุ" พร้อมแล้วเท่านั้น'
          />
        </HStack>

        <HStack w='60px' alignItems='center' justifyContent='flex-end'>
          <XSwitch
            value={isCreateShippingOnlyWithXShipping}
            onValueChange={(newValue: boolean) => {
              this.setState({ isCreateShippingOnlyWithXShipping: newValue }, () => {
                p.op.storageSet(CONS.STORAGE_KEYS.ORDER_LIST_IS_CREATE_SHIPPING_ONLY_WITH_XSHIPPING, newValue)
              })
            }}
          />
        </HStack>
      </HStack>
    )
  }

  renderForceCreateShippingSwitch = () => {
    const { isForceCreateShipping = false, selectedMultipleOperationIndex = -1, multipleOperationButtonContainerWidth = 350 } = this.state
    if (selectedMultipleOperationIndex < 0) {
      return null
    }

    const selectedOpMenu = this.operationMenus[selectedMultipleOperationIndex]

    if ('key' in selectedOpMenu && selectedOpMenu.key !== 'auto_complete_shippings') {
      return null
    }

    if (this.getCheckedOrderCount() === 0) {
      return null
    }

    return (
      <HStack w='full' px='2' py='0.5'>
        <HStack flex={1} alignItems='center'>
          <XText fontSize={multipleOperationButtonContainerWidth > 450 ? 'sm' : 'xs'}>{`บันทึกจัดส่งแม้จำนวนสินค้า${p.op.t(
            'qty'
          )}ไม่พอ`}</XText>
          <XHelpModal
            key='XHelpModalFromXForceCreateShipping'
            headerTitle={`บันทึกจัดส่งแม้จำนวนสินค้า${p.op.t('qty')}ไม่พอ`}
            FAQ={FAQ.FORCE_CREATE_SHIPPING}
            renderButton={(onPressOpenOverlay) => (
              <Box style={{ width: 30 }}>
                <HelpButton style={{ minWidth: 10, width: 10 }} onPress={() => onPressOpenOverlay()} />
              </Box>
            )}
          />
        </HStack>

        <HStack w='60px' alignItems='center' justifyContent='flex-end'>
          <XSwitch
            value={isForceCreateShipping}
            onValueChange={(newValue: boolean) => {
              this.setState({ isForceCreateShipping: newValue }, () => {
                p.op.storageSet(CONS.STORAGE_KEYS.ORDER_LIST_IS_FORCE_CREATE_SHIPPING, newValue)
              })
            }}
          />
        </HStack>
      </HStack>
    )

    // return (
    //   <XForceCreateShipping
    //     value={isForceCreateShipping}
    //     onClick={(newValue: boolean) => {
    //       this.setState({ isForceCreateShipping: newValue })
    //     }}
    //     textColorActive
    //   />
    // )
  }

  renderPaginationBar = () => {
    const { isCheckable, isInitialized, isOrdersRefreshing } = this.state
    if (!isInitialized) {
      return null
    }
    return (
      <HStack w='full' px='2' py='1' alignItems='center' borderTopWidth='1' borderTopColor='muted.300'>
        {this.renderCheckAllButton()}
        {isCheckable ? <Divider h='full' mr='1' orientation='vertical' /> : null}
        <HStack flex={1} px='1' space='1' alignItems='center' flexWrap='wrap'>
          {this.renderOrdersCheckInfo()}
          {this.renderOrderPaginator()}
        </HStack>
        <Divider h='full' ml='1' orientation='vertical' />
        {/* {this.renderPaginatorSettings()} */}
        {this.renderDisplayModeControl()}
      </HStack>
    )
  }

  isSubmitCheckOrdersButtonDisabled = () => {
    const checkedOrders = this.getCheckedOrderIds()
    const checkedCount = checkedOrders.length
    return checkedCount === 0
  }

  renderSubmitCheckOrdersButton = () => {
    const { isCheckable, isInitialized } = this.state
    if (!isCheckable || !isInitialized) {
      return null
    }

    return (
      <VStack w='full' minH='12' maxH='16' px='1' pb='1'>
        <XButton
          w='full'
          size={this.isSubmitCheckOrdersButtonDisabled() ? 'sm' : 'md'}
          minH='10'
          _stack={{ alignItems: 'center' }}
          _text={{ textAlign: 'center' }}
          colorScheme={this.isSubmitCheckOrdersButtonDisabled() ? 'muted' : 'primary'}
          disabled={this.isSubmitCheckOrdersButtonDisabled()}
          // isDisabled={this.isSubmitCheckOrdersButtonDisabled()}
          onPress={this._onPressSubmitCheckedOrders}>
          {this.getSubmitCheckOrdersButtonText()}
        </XButton>
      </VStack>
    )
  }

  renderShippingTypePicker = () => {
    const { isCheckable, isInitialized } = this.state

    if (!isCheckable || !isInitialized) {
      return null
    }
    return (
      <ShippingTypePicker
        ref={this.shippingTypePickerRef}
        title='เปลี่ยนรูปแบบจัดส่ง'
        // selectedShippingTypeId={-1}
        onSelect={(item) => {
          this.editShippingType(item.value)
        }}
        renderButton={() => null}
      />
    )
  }

  openPrintOptionOverlay = () => {
    this.printOptionUIRef.current?.openOverlay()
  }

  closePrintOptionOverlay = (printKey: PrintOptionKey) => {
    if (p.op.isIOS() && (printKey === CONS.PRINT_OPTIONS.A4_PDF || printKey === CONS.PRINT_OPTIONS.STICKER_PDF)) {
      // Do nothing here (no code).
      //
      // Similar logic to PrintOptionOverlayButton > _handleAfterSubmit
      //
      // There is an issue on iOS (only) that loading PDF dialog (from OrderView)
      // will be closed together with this print menu dialog IF the PDF can be loadded very quickly
      // i.e. small file like "ไม่มีออเดอร์ที่รอฉันจัดส่ง" but the regular content in PDF is fine
      // (the doc view is not closed).
      //
      // This behavior is wrong as closing the print menu dialog shall
      // happen behind the doc viewer dialog and not also closing the doc viewer dialog.
      //
      // Don't know why the same code and lib version doesn't happen on XSelly
      //
      // The work around here is to not "automatically" close the print menu dialog
      // on iOS.
      return
    }
    this.printOptionUIRef.current?.closeOverlay()
  }

  renderPrintOptionsOverlay = () => {
    const { selectedStore } = this.props

    return (
      <PrintingOptionUI
        ref={this.printOptionUIRef}
        selectedStore={selectedStore}
        onSubmit={this._onPressPrintDocuments}
        //  onSubmitingStateChange={_onSubmitingStateChange}
        afterSubmit={this.closePrintOptionOverlay}
        enabledReport
      />
    )
  }

  renderIsLoadingSpinner = () => {
    const { isLoading } = this.state
    if (!isLoading) {
      return null
    }

    return (
      <Box position='absolute' left='0' right='0' top='0' bottom='0' bg='rgba(0,0,0,0.1)' justifyContent='center' alignItems='center'>
        <XSpinner />
      </Box>
    )
  }

  renderExtraHeader = (): JSX.Element => null

  renderMain = () => (
    <XContainer>
      {/* <XButton
        onPress={() =>
          this.props.navigation.push('OrderListView', {
            store_id: 3,
            availableTabKeys: ['myTasks_confirmOrder'],
            onPressOrderListItem: (od) => p.op.alert(JSON.stringify(od)),
          })
        }>
        Test Nav
      </XButton> */}
      {this.renderViewHeader()}
      {this.renderExtraHeader()}
      {/* {this.renderDisplayModeControl()} */}
      {this.renderOrderListTabsHeader()}
      <VStack flex={1}>{this.renderOrderListItems()}</VStack>
      {this.renderMultipleOperationSummaryBar()}
      {this.renderWarningBar()}
      {this.renderPaginationBar()}

      {this.renderSubmitCheckOrdersButton()}

      {this.renderShippingTypePicker()}
      {this.renderPrintOptionsOverlay()}
      {this.renderIsLoadingSpinner()}
    </XContainer>
  )

  render() {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }
    return this.renderMain()
  }
}

export default BaseUIOrderListView

// export default connect(
//   (state) => ({
//     ...getState(state),
//   }),
//   (dispatch) => ({
//     ...getDispatch(dispatch),
//   })
// )(BaseUIOrderListView)
