import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import { getSelectedStore, getEditingStore, getSellerStores } from 'x/redux/selectors'

import VStack from 'xui/components/VStack'

import * as util from 'x/utils/util'
import xCONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import { APP_CONFIG } from 'x/config/mode'

import XText from 'xui/components/XText'
import {
  IAddStoreProductSelectorProps,
  IAddStoreProductSelectorState,
  IBarcodeScannerOutput,
  IAddStoreProductSelectorOptions,
  IAddStoreProductSelectorPredefinedNavParams,
  IAddStoreProductSelectorExternalProps,
} from 'x/types'
import HStack from 'xui/components/HStack'
import _ from 'lodash'
import BarcodeScannerModalButton from 'xui/components/BarcodeScannerModalButton'
import XButtonWithIcon from 'xui/components/XButtonWithIcon'
import XIcon from 'xui/components/XIcon'
import FastImage from 'react-native-fast-image'
import * as ProductActions from 'x/modules/product/ProductState'
import XCard from '../XCard'
import ForwardIcon from '../ForwardIcon'
import XFlatPicker from '../XFlatPicker'
import Segment from '../Segment'
import OrderRadioGroupItem from '../OrderRadioGroupItem'

const { ORDER_VIEW_MODE, PERM_STORE_HELPER, STORE_SETTINGS } = xCONS
const { CREATE, VIEW_EDIT, VIEW_ONLY, EDIT } = ORDER_VIEW_MODE
const { COMPUTED_USE_PRODUCT_UPC, COMPUTED_USE_PRODUCT_SKU } = STORE_SETTINGS

const OPTIONS_CHOOSE_PRODUCTS_FROM = ['ร้านผู้ขายส่ง', 'ร้านฉัน']
const OPTIONS_CHOOSE_PRODUCTS_FOLLOWING = ['กลุ่มสมาชิก', 'รายการราคา']
// const OPTIONS_CHOOSE_SHIPPING_COD = ['บาท', '%']
// const OPTIONS_CHOOSE_MINIMUM_FEE = ['ไม่มีขั้นต่ำ', 'มีขั้นต่ำ']
// const OPTIONS_CHOOSE_DCIMAL = ['ปัดขึ้น', 'ปัดลง', 'ไม่ปัด']
// const OPTIONS_CHOOSE_ADDRESSES = [`ใช้ที่อยู่หลัก`, `ใช้ที่อยู่รอง`]
// const OPTIONS_CHOOSE_SHIPPING_MKP_TYPE = ['นัดรับ', 'Dropoff']
// const OPTIONS_CHOOSE_SETUP_DEADLINE_ORDER = ['ไม่มีกำหนดส่ง', 'ระบุ']
// const OPTIONS_CHOOSE_SETUP_AUTO_CANCEL_ORDER = ['ไม่ยกเลิก', 'ระบุ', 'ใช้ตั้งค่าร้าน']

class AddStoreProductSelector extends React.Component<IAddStoreProductSelectorProps, IAddStoreProductSelectorState> {
  static displayName = 'AddStoreProductSelector'

  static defaultProps = {
    optionSelectedSellerStoreIndex: 0,
    optionChooseProductsFromIndex: 0, // seller or my
    optionChooseProductsFollowingIndex: 0, // ug or pg
    optionChooseProductsByGroupIndex: 0, // which group ?

    forceUseOnlyUg: false,
    forceUseOnlyPg: false,
    // chooseProductMode: 'None',
  }

  inProcess?: boolean

  isBarcodeProcessing?: boolean

  // isFetching?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      isRefreshing: false,
    }
  }

  async componentDidMount() {
    await this._initialize()
    await util.setStatePromise(this, { isInitialized: true })
  }

  _initialize = async () => {
    const {
      selectedStore,
      sellerStores,
      saveOptionChooseProductsFromIndexKey,
      saveOptionSelectedSellerStoreIndexKey,
      saveOptionChooseProductsFollowingIndexKey,
      saveOptionChooseProductsByGroupIndexKey,
      forceUseOnlyPg,
      forceUseOnlyUg,
      forceUseOnlyMyStore,

      optionChooseProductsFromIndex,
      optionSelectedSellerStoreIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsByGroupIndex,
    } = this.props
    const userGroups = selectedStore.get('user_groups') || List([])
    const productGroups = selectedStore.get('product_groups') || List([])
    const initReplaceOptions: Partial<IAddStoreProductSelectorOptions> = {}

    // ค่าพื้นฐาน
    if (userGroups.size > 1) {
      initReplaceOptions.optionChooseProductsFollowingIndex = 0 // ใช้กลุ่มสมาชิก
      initReplaceOptions.optionChooseProductsByGroupIndex = 0
    } else {
      initReplaceOptions.optionChooseProductsFollowingIndex = 1 // // ใช้กลุ่มราคา
      initReplaceOptions.optionChooseProductsByGroupIndex = 0
    }

    // Init from props
    initReplaceOptions.optionChooseProductsFromIndex = _.isNumber(optionChooseProductsFromIndex) ? optionChooseProductsFromIndex : 1
    initReplaceOptions.optionSelectedSellerStoreIndex = _.isNumber(optionSelectedSellerStoreIndex) ? optionSelectedSellerStoreIndex : 0
    initReplaceOptions.optionChooseProductsFollowingIndex = _.isNumber(optionChooseProductsFollowingIndex)
      ? optionChooseProductsFollowingIndex
      : initReplaceOptions.optionChooseProductsFollowingIndex
    initReplaceOptions.optionChooseProductsByGroupIndex = _.isNumber(optionChooseProductsByGroupIndex)
      ? optionChooseProductsByGroupIndex
      : initReplaceOptions.optionChooseProductsByGroupIndex

    // --------------- Load From AsyncStoreage ---------------
    let savedOptionChooseProductsFromIndex
    let savedOptionSelectedSellerStoreIndex
    let savedOptionChooseProductsFollowingIndex
    let savedOptionChooseProductsByGroupIndex

    if (_.isString(saveOptionChooseProductsFromIndexKey)) {
      savedOptionChooseProductsFromIndex = await p.op.storageGet(saveOptionChooseProductsFromIndexKey)
    }
    if (_.isString(saveOptionSelectedSellerStoreIndexKey)) {
      savedOptionSelectedSellerStoreIndex = await p.op.storageGet(saveOptionSelectedSellerStoreIndexKey)
    }
    if (_.isString(saveOptionChooseProductsFollowingIndexKey)) {
      savedOptionChooseProductsFollowingIndex = await p.op.storageGet(saveOptionChooseProductsFollowingIndexKey)
    }
    if (_.isString(saveOptionChooseProductsByGroupIndexKey)) {
      savedOptionChooseProductsByGroupIndex = await p.op.storageGet(saveOptionChooseProductsByGroupIndexKey)
    }

    //   savedOptionChooseProductsFromIndex
    if (
      !_.isNil(savedOptionChooseProductsFromIndex) &&
      _.isNumber(parseInt(savedOptionChooseProductsFromIndex)) &&
      parseInt(savedOptionChooseProductsFromIndex) >= 0
    ) {
      initReplaceOptions.optionChooseProductsFromIndex = parseInt(savedOptionChooseProductsFromIndex)
    }

    if (
      !_.isNil(savedOptionSelectedSellerStoreIndex) &&
      _.isNumber(parseInt(savedOptionSelectedSellerStoreIndex)) &&
      parseInt(savedOptionSelectedSellerStoreIndex) >= 0
    ) {
      const selectedStoreIndex = parseInt(savedOptionSelectedSellerStoreIndex)
      const isAvailableStore = !_.isNil(sellerStores.getIn([selectedStoreIndex, 'id']))
      if (isAvailableStore) {
        initReplaceOptions.optionSelectedSellerStoreIndex = selectedStoreIndex
      }
    }

    let optionChooseProductsFollowingIndexForStorage
    if (
      !_.isNil(savedOptionChooseProductsFollowingIndex) &&
      _.isNumber(parseInt(savedOptionChooseProductsFollowingIndex)) &&
      parseInt(savedOptionChooseProductsFollowingIndex) >= 0
    ) {
      initReplaceOptions.optionChooseProductsFollowingIndex = parseInt(savedOptionChooseProductsFollowingIndex)
      optionChooseProductsFollowingIndexForStorage = parseInt(savedOptionChooseProductsFollowingIndex)
    }

    if (
      !_.isNil(savedOptionChooseProductsByGroupIndex) &&
      _.isNumber(parseInt(savedOptionChooseProductsByGroupIndex)) &&
      parseInt(savedOptionChooseProductsByGroupIndex) >= 0
    ) {
      if (optionChooseProductsFollowingIndexForStorage === 0 && userGroups.size >= parseInt(savedOptionChooseProductsByGroupIndex) + 1) {
        initReplaceOptions.optionChooseProductsByGroupIndex = parseInt(savedOptionChooseProductsByGroupIndex)
        // ใช้กลุ่มราคา
      } else if (
        optionChooseProductsFollowingIndexForStorage === 1 &&
        productGroups.size >= parseInt(savedOptionChooseProductsByGroupIndex) + 1
      ) {
        initReplaceOptions.optionChooseProductsByGroupIndex = parseInt(savedOptionChooseProductsByGroupIndex)
      }
    }

    // --------------- Init condition ---------------
    if (!this._hasSellerStores()) {
      initReplaceOptions.optionChooseProductsFromIndex = 1
    }

    if (forceUseOnlyUg) {
      initReplaceOptions.optionChooseProductsFollowingIndex = 0
    }

    if (forceUseOnlyPg) {
      initReplaceOptions.optionChooseProductsFollowingIndex = 1
    }

    const roleId = selectedStore.get('role_id')
    // FIXME: เงื่อนไขตัวนี้วางใน render function ไม่น่าดี เพราะมันมีโอกาส change state ก่อให้เกิด infinite loop ได้
    if (roleId === 2 || APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn) {
      // 1 คือ my store
      // this._onSegmentChangeChooseProductFrom(1)
      initReplaceOptions.optionChooseProductsFromIndex = 1
    }

    // ถ้า config แสดงเฉพาะร้านเรา ไม่ต้องแสดง และเลือกจากรายการราคาอันดับแรกเสมอ
    if (APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn) {
      // await this._onSegmentChangeChooseProductFollowing(1)
      initReplaceOptions.optionChooseProductsFollowingIndex = 1
      // return null
    }

    if (forceUseOnlyMyStore) {
      initReplaceOptions.optionChooseProductsFromIndex = 1
    }
    // console.log('initReplaceOptions', initReplaceOptions)
    if (!_.isEmpty(initReplaceOptions)) {
      await this._onChangeOptions(initReplaceOptions)
    }
  }

  _hasSellerStores = () => {
    const { sellerStores = List([]) } = this.props
    return sellerStores.size > 0
  }

  onBarcodeScanned: (output: IBarcodeScannerOutput) => Promise<void>

  _handleBarcodeScanedUPC = async (output: IBarcodeScannerOutput) => {
    if (this.isBarcodeProcessing) {
      return
    }
    this.isBarcodeProcessing = true
    try {
      await this._handleOrderBarcodeScaned({ data: output.data, isUPC: true })
    } catch (err) {
      p.op.showToast(JSON.stringify(err), 'danger')
    }
    this.isBarcodeProcessing = false
  }

  _handleBarcodeScanedSKU = async (output: IBarcodeScannerOutput) => {
    if (this.isBarcodeProcessing) {
      return
    }
    this.isBarcodeProcessing = true
    try {
      await this._handleOrderBarcodeScaned({ data: output.data, isSKU: true })
    } catch (err) {
      p.op.showToast(JSON.stringify(err), 'danger')
    }
    this.isBarcodeProcessing = false
  }

  _handleOrderBarcodeScaned = async (params: { data: string; isUPC?: boolean; isSKU?: boolean }): Promise<void> => {
    if (!params || !params.data || !_.isString(params.data)) {
      return
    }

    if (!params.isUPC && !params.isSKU) {
      return
    }

    const {
      selectedStore,
      fetchProducts,
      sellerStores,
      // userGroups,
      // productGroups,
      optionSelectedSellerStoreIndex,
      optionChooseProductsFromIndex,
    } = this.props
    if (!Map.isMap(selectedStore) || !selectedStore.get('id') || !_.isFunction(fetchProducts)) {
      return
    }
    const { data, isSKU, isUPC } = params

    const userGroups = selectedStore.get('user_groups') || List([])

    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      queryTxt: data,
      v: 1, // เอา variants มาคิดด้วย
      offset: 0,
      limit: xCONS.PRODUCTS_FETCH_LIMIT_INIT,
      // returnDetailIfMatchOne: true,
    }
    const sellerStoresId = sellerStores.getIn([optionSelectedSellerStoreIndex, 'id'])
    if (List.isList(sellerStores) && sellerStores.size > 0 && optionChooseProductsFromIndex === 0) {
      // Change store_id to seller_store_id
      body.store_id = sellerStoresId
    }

    const userRoleId = selectedStore.get('role_id') || null
    if (userRoleId === 2) {
      // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
      body.role_id = userRoleId
    }

    if (isUPC) {
      body.isUPC = true
    }

    if (isSKU) {
      body.isSKU = true
    }

    if (body.store_id) {
      const additionParams = this._getProductFilterParams()
      if (_.has(additionParams, 'pg_ids')) {
        body.product_group_ids = additionParams.pg_ids
      }
      if (_.has(additionParams, 'ug_id')) {
        body.user_group_id = additionParams.ug_id
      }
      // console.log('additionParams => ')
      const response: any = await new Promise((resolveFetch) => {
        fetchProducts({
          body,
          isNewSearch: true,
          showSpinner: true,
          successCallback: resolveFetch,
          failedCallback: () => resolveFetch(null),
        })
      })

      // Maybe do something
      if (response && _.has(response, 'products')) {
        // if (response.product && returnDetailIfMatchOne) {
        // console.log('_handleBarcodeScaned multiple products response => ', response)
        if (!_.isArray(response.products) || response.products.length === 0) {
          let txtTitle = 'ไม่พบสินค้า '
          let txtCode = ''
          if (isSKU) {
            txtCode = 'SKU '
          } else if (isUPC) {
            txtCode = 'UPC '
          }
          txtTitle += `${txtCode}${data}`
          let txtErr = ' กรุณาตรวจสอบรหัสสินค้า\n\nไม่พบสินค้า รหัส '
          txtErr += `${txtCode}${data}`
          if (_.has(additionParams, 'pg_ids') && additionParams.pg_ids[0]) {
            const foundPg = userGroups.find((pg) => pg.get('id') === additionParams.pg_ids[0])
            if (Map.isMap(foundPg) && _.isString(foundPg.get('name'))) {
              txtErr += ` ที่สามารถเข้าถึงได้ด้วยกลุ่มสมาชิก "${foundPg.get('name')}"`
            }
          } else if (_.has(additionParams, 'ug_id') && additionParams.ug_id) {
            const foundUg = userGroups.find((ug) => ug.get('id') === additionParams.ug_id)
            if (Map.isMap(foundUg) && _.isString(foundUg.get('name'))) {
              txtErr += ` อยู่ภายในกลุ่มสมาชิก "${foundUg.get('name')}"`
            }
          }

          p.op.alert(txtTitle, txtErr)
          return
        }
        await this._doAfterBarcodeSearchMatchProducts(response, sellerStoresId)
      }
    }
  }

  // Refactor to re-use method
  _showActionSheetToSelectProductIndex = async (products: any[]): Promise<number> => {
    // const BUTTON_LABELS = products.map(pt => pt.n).concat('ยกเลิก')
    const notFoundProductPrices = '(ไม่พบราคาสินค้า)'
    const BUTTON_LABELS = products
      .map((pt) => {
        const productVariants = pt.v

        const productName = pt.n
        let txtAdditionProductInfo = ''
        if (productVariants.length === 1) {
          const vi = productVariants[0]
          // TODO for p'O ตรวจสอบให้หน่อย
          let price
          if (!_.isNil(vi.s)) {
            price = _.isArray(vi.s) && vi.s.length === 1 && _.has(vi.s[0], 'p') ? `(฿${parseFloat(vi.s[0].p)})` : notFoundProductPrices
          } else {
            price = !_.isNil(vi.p) ? vi.p : notFoundProductPrices
          }
          txtAdditionProductInfo = price
        } else if (productVariants.length > 1) {
          const variantNames = productVariants.map((vi) => vi.n)
          txtAdditionProductInfo = `- ${variantNames.join(',')}`
        }
        const txtProductItem = `${productName} ${txtAdditionProductInfo}`
        let hasAvailableQty = false
        for (const vi of productVariants) {
          const vAvailableQty = _.has(vi, 'q') && vi.q ? _.has(vi, 'q') && vi.q > 0 : false
          if (vAvailableQty) {
            hasAvailableQty = true
            break
          }
        }
        return hasAvailableQty ? txtProductItem : `(หมดสต๊อก) ${txtProductItem}`
      })
      .concat('ยกเลิก')

    const CANCEL_INDEX = BUTTON_LABELS.length - 1
    // log('BUTTON_LABELS => ', BUTTON_LABELS)
    const selectedIndex = await new Promise<number>((chooseAtIndex) => {
      p.op.showActionSheet(
        {
          options: BUTTON_LABELS,
          cancelButtonIndex: CANCEL_INDEX,
          // destructiveButtonIndex: -1,
          title: 'เลือกสินค้าเพื่อเพิ่ม 1 ชิ้นในออเดอร์...',
        },
        (idx: string | number) => {
          // log('ActionSheet.show idx => ', idx)

          const intIdx = _.isString(idx) ? parseInt(idx) : idx
          chooseAtIndex(intIdx)
        }
      )
    })

    // log('selectedIndex => ', selectedIndex)

    if (selectedIndex >= 0 && selectedIndex < CANCEL_INDEX && products.length > selectedIndex) {
      // log('products.length => ', products.length)
      // log('selectedIndex => ', selectedIndex)
      return selectedIndex
    }
    return -1
  }

  _doAfterBarcodeSearchMatchProducts = async (response, sellerStoresId): Promise<void> => {
    const { onChooseProductAfterBarcodeScanned } = this.props

    if (!_.isFunction(onChooseProductAfterBarcodeScanned)) {
      throw new Error('onChooseProductAfterBarcodeScanned is not a function')
    }

    // console.log('_handleBarcodeScaned multiple response 111 => ', response.products)
    const { products } = response
    if (_.isNil(products) || !_.isArray(products)) {
      return
    }
    // console.log('_handleBarcodeScaned multiple response 222 => ', response.products)
    // เอา product ซ้ำออก
    const newProduct = _.uniqBy(products, 'id')
    const { storeIdFromRequest } = response

    // const { optionChooseProductsFromIndex, optionChooseProductsFollowingIndex } = this.props
    let chosenProductIndex = -1
    if (newProduct.length === 1) {
      // Has One Product
      chosenProductIndex = 0
    } else {
      // Has Many Products
      chosenProductIndex = await this._showActionSheetToSelectProductIndex(newProduct)
    }
    // console.log('chosenProductIndex => ', chosenProductIndex)

    if (chosenProductIndex < -1 || chosenProductIndex >= newProduct.length) {
      // User Cancelled
      // console.log('User Cancelled on product selection')
      return
    }

    const chosenProduct = newProduct[chosenProductIndex]
    if (!chosenProduct || !_.has(chosenProduct, 'v') || !_.isArray(chosenProduct.v)) {
      // Invalid Product Variants key
      // console.log('Invalid Product Variants key')
      return
    }

    let chosenVariantIndex = -1
    if (chosenProduct.v.length > 1) {
      chosenVariantIndex = await this._showActionSheetToSelectVariantIndex(chosenProduct)
    } else {
      chosenVariantIndex = 0
    }

    if (chosenVariantIndex === -1 || chosenVariantIndex >= chosenProduct.v.length) {
      // User Cancelled
      // console.log('User Cancelled on variant selection')
      return
    }
    const chosenVariant = chosenProduct.v[chosenVariantIndex]

    // console.log('chosenProduct => ', chosenProduct)
    // console.log('chosenVariant => ', chosenVariant)

    const { selectedStore } = this.props
    // const { selectedStore, quickAddProductToOrder } = this.props
    // const { ug_id, pg_ids } = this._getProductFilterParams()
    const { ug_id, pg_ids } = this._getProductFilterParams()
    const store_id = selectedStore.get('id')
    // const selectedUserGroups = selectedStore.get('user_groups') || List([])
    // const selectedProductGroups = selectedStore.get('product_groups') || List([])

    // const selectedProduct = fromJS(chosenProduct)
    // const selectedVariant = fromJS(chosenVariant)

    // if (!Map.isMap(selectedProduct) || !Map.isMap(selectedVariant)) {
    //   return
    // }

    // // Begin prepare action to dispatch quick add/merge
    // const parent_store_id = selectedProduct.get('s') ? parseInt(selectedProduct.get('s')) : null
    // let cost = selectedVariant.has('c') ? parseFloat(selectedVariant.get('c')) : null
    // const product_id = selectedProduct.get('id')
    // const name = selectedProduct.get('n')
    // const variant = selectedVariant.get('n') // variant name
    // // const qty = 1 // +1 จากของเดิม
    // const available_qty = selectedVariant.get('q') || 0

    // if (!available_qty || available_qty <= 0) {
    //   p.op.showToast('สินค้าหมดสต๊อกแล้ว กรุณาเติมสินค้าก่อนเปิดออเดอร์', 'warning')
    //   return
    // }

    // const pp_id = parseFloat(selectedVariant.get('i'))
    // const thumbnail_uris = selectedProduct.get('t')
    // const h = parseInt(selectedVariant.get('h')) || null

    // // const price = parseFloat(selectedVariant.get('p'))
    // // const price_rate_value = parseFloat(selectedVariant.get('p'))
    // // const price_rate_type = 1

    // // FIXME: Implement VDs
    // const my_vds = selectedProduct.get('my_vds')
    // const my_vds_type = selectedProduct.get('my_vds_type')
    // const seller_vds = selectedProduct.get('seller_vds')
    // const seller_vds_type = selectedProduct.get('seller_vds_type')

    // let price = null // to be compute
    // let pg_id = null // to be compute
    // let ugpgSaleDiscountAmount = null // to be compute
    // let ugpgSaleDiscountPercent = null // to be compute

    // const productDiscount = selectedProduct.get('pdc') || Map({})
    // const ugpgPurchaseDiscountAmount = productDiscount.get('ua') ? parseFloat(productDiscount.get('ua')) : null
    // const ugpgPurchaseDiscountPercent = productDiscount.get('up') ? parseFloat(productDiscount.get('up')) : null

    // let hasEmptyPrice = false

    // // ปิดด้วยสินค้าของร้านฉันเท่านั้น !!
    // let prices = selectedVariant.get('s')
    // if (!prices || !List.isList(prices) || prices.size < 1) {
    //   // should be impossible case
    //   prices = List([])
    // }

    // switch (optionChooseProductsFromIndex) {
    //   case 0:
    //     price = selectedVariant.get('p') ? parseFloat(selectedVariant.get('p')) : null
    //     // log('optionChooseProductsFromIndex case 0:: price => ', price)
    //     break
    //   case 1:
    //     price = prices.hasIn([0, 'p']) ? parseFloat(prices.getIn([0, 'p'])) : null
    //     // log('optionChooseProductsFromIndex case 1:: price => ', price)
    //     break
    //   default:
    //     price = null
    //   // log('optionChooseProductsFromIndex case default:: optionChooseProductsFromIndex => ', optionChooseProductsFromIndex)
    // }
    // // price = prices.hasIn([0, 'p']) ? parseInt(prices.getIn([0, 'p'])) : null
    // pg_id = prices.hasIn([0, 'g']) ? parseInt(prices.getIn([0, 'g'])) : null
    // if (_.isNull(price) || _.isUndefined(price) || (!pg_id && optionChooseProductsFollowingIndex === 1)) {
    //   // p.op.alert('ไม่พบราคาสินค้าชิ้นนี้', 'กรุณาระบุราคาของสินค้าชิ้นนี้ก่อนใช้เปิดออเดอร์')
    //   // return
    //   // const isUserConfirmed = await p.op.isUserConfirm('ไม่พบราคาสินค้าชิ้นนี้', 'กรุณายืนยันว่าจะให้จะหยิบสินค้าเข้าออเดอร์แล้วกำหนดราคาไว้ ฿0 โดยคุณสามารถปรับราคาได้ภายหลัง')
    //   const txtProductName = variant ? `${name} ${variant}` : name
    //   const isUserConfirmed = await p.op.isUserConfirm(
    //     'ไม่พบราคาสินค้า',
    //     `กรุณายืนยันว่าจะให้จะหยิบสินค้า "${txtProductName}" เข้าสู่ออเดอร์แล้วกำหนดราคาไว้ ฿0 โดยคุณสามารถปรับราคาได้ภายหลัง`
    //   )
    //   if (!isUserConfirmed) {
    //     return
    //   }
    //   price = 0
    //   hasEmptyPrice = true
    // }

    // if (ug_id && pg_id) {
    //   // ถ้ามีการเลือกเปิดโดย UG มา และมี pg_id สำหรับคิดส่วนลด
    //   const focusedUserGroup = selectedUserGroups.find((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
    //   if (!Map.isMap(focusedUserGroup) || !focusedUserGroup.get('id')) {
    //     p.op.alert('ไม่พบข้อมูลกลุ่มสมาชิก', 'กรุณาลองใหม่อีกครั้ง') // should be impossible case
    //     return
    //   }
    //   const discountRelations = focusedUserGroup.get('pgs') // discount relation
    //   const focusedDiscount = discountRelations.find((pgd) => Map.isMap(pgd) && pgd.get('id') === pg_id)
    //   if (Map.isMap(focusedDiscount)) {
    //     ugpgSaleDiscountAmount = focusedDiscount.get('discount_amount') ? parseFloat(focusedDiscount.get('discount_amount')) : null
    //     ugpgSaleDiscountPercent = focusedDiscount.get('discount_percent') ? parseFloat(focusedDiscount.get('discount_percent')) : null
    //   }
    // } else if (_.isArray(pg_ids) && pg_ids.length > 0) {
    //   // TODO: ตอนนี้ยังไม่มีส่วนลดของ PG โดดๆ อนาคตมีก็มา compute ตรงนี้
    // }
    // // ถ้าไม่มี h แปลว่าสินค้าที่ดึงมา มาจากร้านขายส่ง ปรับ cost = price เพื่อไม่ให้คำนวณกำไรผิด
    // if (_.isNil(h) && _.isNil(cost)) {
    //   cost = price
    // }
    // // ตรวจสอบว่า store id ของ ฉันตรงหับ store id ที่ส่งไปหรือเปล่า
    // let newSellerStoresId = sellerStoresId
    // if (storeIdFromRequest === store_id) {
    //   newSellerStoresId = undefined
    // }

    // const pulledOrderProduct = {
    //   variant,
    //   cost,
    //   product_id,
    //   name,
    //   qty: 1, // +1 จากของเดิม
    //   available_qty,
    //   pp_id,
    //   price,
    //   price_rate_value: hasEmptyPrice ? -1.33 : price, // default price ถ้าถูก user เปลี่ยน จะทำให้หน้าบ้านส่ง custom price ไป
    //   price_rate_type: 1, // default mode
    //   thumbnail_uris,
    //   h,
    //   parent_store_id,

    //   // FIXME: Maybe use in when Enable Vds in version 2.1
    //   my_vds,
    //   my_vds_type,
    //   seller_vds,
    //   seller_vds_type,

    //   // for create api body with pg_id
    //   ug_id,
    //   pg_id,

    //   // for compute real-time discount
    //   ugpgPurchaseDiscountAmount, // discount per each
    //   ugpgPurchaseDiscountPercent, // discount per each
    //   ugpgSaleDiscountAmount, // discount per each
    //   ugpgSaleDiscountPercent, // discount per each
    // }

    // // quickAddProductToOrder({
    // //   store_id,
    // //   seller_store_id: newSellerStoresId,
    // //   orderProducts: [pulledOrderProduct],
    // //   // callback,
    // //   callback: this._handleOnQuickAddProductToOrderWithBarcodeSuccess,

    // //   // for order create validation
    // //   ug_id,
    // //   pg_ids,
    // // })

    let newSellerStoresId = sellerStoresId
    if (storeIdFromRequest === store_id) {
      newSellerStoresId = undefined
    }

    // console.log(' resuile => ', {
    //   store_id,
    //   seller_store_id: newSellerStoresId,
    //   product: chosenProduct,
    //   variantIndex: chosenVariantIndex,
    //   ug_id,
    //   pg_ids,
    // })

    onChooseProductAfterBarcodeScanned({
      store_id,
      seller_store_id: newSellerStoresId,
      product: chosenProduct,
      variantIndex: chosenVariantIndex,
      ug_id,
      pg_ids,
    })
  }

  _getProductFilterParams = (): { pg_ids?: number[]; ug_id?: number; isError?: boolean } => {
    const { selectedStore } = this.props
    const {
      optionChooseProductsFromIndex = 0, // seller or my
      optionChooseProductsFollowingIndex = 0, // ug or pg
      optionChooseProductsByGroupIndex = 0, // which group ?
    } = this.props
    const hasSellerStores = this._hasSellerStores()
    let isMineProducts = optionChooseProductsFromIndex === 1
    if (!isMineProducts && !hasSellerStores) {
      isMineProducts = true
    }

    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])
    const hasOnlyOneUG = user_groups.size === 1
    const hasOnlyOnePG = product_groups.size === 1
    const additionParams: { pg_ids?: number[]; ug_id?: number; isError?: boolean } = {}
    if (isMineProducts) {
      if (hasOnlyOneUG && hasOnlyOnePG) {
        // log('_getProductFilterParams hasOnlyOneUG && hasOnlyOnePG')
        const selectedPG = product_groups.get(0)
        if (!Map.isMap(selectedPG) || !selectedPG.get('id')) {
          p.op.alert('ไม่พบรายการราคาที่คุณเลือก', 'กรุณาลองใหม่อีกครั้ง')
          return { isError: true } // กรณีไม่พบ PG should be impossible
        }
        additionParams.pg_ids = [selectedPG.get('id')]
      } else if (optionChooseProductsFollowingIndex === 0) {
        // log('_getProductFilterParams optionChooseProductsFollowingIndex === 0')
        const selectedUG = user_groups.get(optionChooseProductsByGroupIndex)
        if (!Map.isMap(selectedUG) || !selectedUG.get('id')) {
          p.op.alert('ไม่พบกลุ่มสมาชิกที่คุณเลือก', 'กรุณาลองใหม่อีกครั้ง')
          return { isError: true } // กรณีไม่พบ UG should be impossible
        }
        additionParams.ug_id = selectedUG.get('id')
        // log('_getProductFilterParams additionParams => ', additionParams)
      } else if (optionChooseProductsFollowingIndex === 1) {
        // log('_getProductFilterParams optionChooseProductsFollowingIndex === 1')
        const selectedPG = product_groups.get(optionChooseProductsByGroupIndex)
        if (!Map.isMap(selectedPG) || !selectedPG.get('id')) {
          p.op.alert('ไม่พบรายการราคาที่คุณเลือก', 'กรุณาลองใหม่อีกครั้ง')
          return { isError: true } // กรณีไม่พบ PG should be impossible
        }
        additionParams.pg_ids = [selectedPG.get('id')]
      } else {
        // log('_getProductFilterParams else')
        return { isError: true } // should be impossible case but safety first
      }
    }
    return additionParams
  }

  _showActionSheetToSelectVariantIndex = async (product: { [key: string]: any }): Promise<number> => {
    if (!product || !product.v || product.v.length < 1) {
      return -1
    }
    const { optionChooseProductsFromIndex } = this.props
    const variants: any[] = product.v
    const BUTTON_LABELS = variants
      .map((vi) => {
        const productName = product.n
        const variantName = vi.n
        let price
        switch (optionChooseProductsFromIndex) {
          case 0:
            price = !_.isNil(vi.p) ? `(฿${parseFloat(vi.p)})` : '(ไม่พบราคาสินค้า)'
            break
          case 1:
            price = _.isArray(vi.s) && vi.s.length === 1 && _.has(vi.s[0], 'p') ? `(฿${parseFloat(vi.s[0].p)})` : '(ไม่พบราคาสินค้า)'
            break
          default:
            price = '(ไม่พบราคาสินค้า)'
        }
        const txtProductItem = `${productName} - ${variantName} ${price}`

        const hasAvailableQty = _.has(vi, 'q') && vi.q > 0
        return hasAvailableQty ? txtProductItem : `(หมดสต๊อก) ${txtProductItem}`
      })
      .concat('ยกเลิก')
    const CANCEL_INDEX = BUTTON_LABELS.length - 1
    const selectedIndex = await new Promise<number>((chooseAtIndex) => {
      p.op.showActionSheet(
        {
          options: BUTTON_LABELS,
          cancelButtonIndex: CANCEL_INDEX,
          // destructiveButtonIndex: -1,
          title: 'เลือกตัวเลือกสินค้าเพื่อเพิ่ม 1 ชิ้นในออเดอร์...',
        },
        (idx: string | number) => {
          const intIdx = _.isString(idx) ? parseInt(idx) : idx
          chooseAtIndex(intIdx)
        }
      )
    })
    if (selectedIndex >= 0 && selectedIndex < CANCEL_INDEX && variants.length > selectedIndex) {
      return selectedIndex
    }
    return null
  }

  handlePrepareToAddProductsToCart = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { sellerStores } = this.props
    // const { navigation, selectedStore, sellerStores } = this.props
    // if (!navigation) {
    //   return null
    // }

    // if (!navigation || !_.isFunction(navigation.dispatch) || !Map.isMap(selectedStore)) {
    //   return
    // }

    // if (p.op.isWeb()) {
    //   await this.popToCurrentView()
    // }

    const {
      selectedStore,
      optionSelectedSellerStoreIndex,
      optionChooseProductsFromIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsByGroupIndex,
      onChooseProductButtonPressed,
    } = this.props

    if (!_.isFunction(onChooseProductButtonPressed)) {
      throw new Error('onChooseProductButtonPressed is required')
    }

    const store_id = selectedStore.get('id')
    if (!store_id) {
      p.op.alert('ไม่พบข้อมูลร้านค้า', 'กรุณากล้าไปเลือกร้านค้าใหม่อีกครั้ง')
      return
    }

    const hasSellerStores = this._hasSellerStores()
    let isMineProducts = optionChooseProductsFromIndex === 1
    if (!isMineProducts && !hasSellerStores) {
      isMineProducts = true
    }

    const additionParams = this._getProductFilterParams()

    // const generateNavAction = isMineProducts
    //   ? NavActions.navToProductListPullMyProductToOrder
    //   : // : NavActions.navToProductListPulSellerProductToOrder
    //     NavActions.navToProductListPullSellerProductToOrder
    const navParams: IAddStoreProductSelectorPredefinedNavParams = { store_id, ...additionParams }

    if (!isMineProducts) {
      navParams.seller_store_id = sellerStores.getIn([optionSelectedSellerStoreIndex, 'id'])
    }
    // // @ts-ignore navToProductListPullMyProductToOrder or navToProductListPullSellerProductToOrder why error ????
    // const navAction = generateNavAction(navParams)
    // dispatch(navAction)

    // setTimeout(() => {
    //   this.inProcess = false
    // }, 500)

    // this.navToAddProductListView(navParams, isMineProducts)

    onChooseProductButtonPressed(navParams, {
      optionSelectedSellerStoreIndex,
      optionChooseProductsFromIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsByGroupIndex,
    })

    await util.delay(200)
    this.inProcess = false
  }

  // navToAddProductListView = (navParams, isMineProducts) => {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true

  //   // const generateNavAction = isMineProducts
  //   //   ? NavActions.navToProductListViewPullMyProductsToOrder
  //   //   : NavActions.navToProductListViewPullSellersProductsToOrder
  //   // const navAction = generateNavAction(navParams)
  //   // this.props.navigation.dispatch(navAction)

  // console.log('navToAddProductListView navParams => ', navParams)
  // console.log('navToAddProductListView isMineProducts => ', isMineProducts)

  //   setTimeout(() => {
  //     this.inProcess = false
  //   }, 500)
  // }

  _navToSearchableProductListView = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { selectedStore, sellerStores, onSearchProductButtonPressed } = this.props
    // const { navigation, selectedStore, sellerStores } = this.props
    // if (!navigation) {
    //   return null
    // }

    // if (!navigation || !_.isFunction(navigation.dispatch) || !Map.isMap(selectedStore)) {
    //   return
    // }

    // if (p.op.isWeb()) {
    //   await this.popToCurrentView()
    // }

    if (!_.isFunction(onSearchProductButtonPressed)) {
      throw new Error('onSearchProductButtonPressed is required')
    }

    const {
      // store_id,
      optionSelectedSellerStoreIndex,
      optionChooseProductsFromIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsByGroupIndex,
    } = this.props

    const store_id = selectedStore.get('id')
    if (!store_id) {
      p.op.alert('ไม่พบข้อมูลร้านค้า', 'กรุณากล้าไปเลือกร้านค้าใหม่อีกครั้ง')
      return
    }

    const hasSellerStores = this._hasSellerStores()
    let isMineProducts = optionChooseProductsFromIndex === 1
    if (!isMineProducts && !hasSellerStores) {
      isMineProducts = true
    }

    const additionParams = this._getProductFilterParams()

    const navParams: IAddStoreProductSelectorPredefinedNavParams = { store_id, ...additionParams }
    if (!isMineProducts) {
      navParams.seller_store_id = sellerStores.getIn([optionSelectedSellerStoreIndex, 'id'])
    }

    // console.log('_navToSearchableProductListView navParams => ', navParams)
    // // @ts-ignore
    // this.props.navigation.dispatch(NavActions.navToSearchableProductListView(navParams))

    onSearchProductButtonPressed(navParams, {
      optionSelectedSellerStoreIndex,
      optionChooseProductsFromIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsByGroupIndex,
    })

    await util.delay(200)
    this.inProcess = false
  }

  _renderButtonCameraUPCAndSKU = (isStoreUseSKU, isStoreUseUPC) => {
    const { optionSelectedSellerStoreIndex, optionChooseProductsFromIndex } = this.props
    const { sellerStores } = this.props
    const storeName = sellerStores.getIn([optionSelectedSellerStoreIndex, 'name'])
    return (
      <HStack alignItems='center' space='2' mt='0.5'>
        {/* <XButtonWithIcon
          h='full'
          flex={1}
          flexWrap='wrap'
          _text={{ numberOfLines: 1 }}
          rightIcon={<XIcon inactive name='search' />}
          onPress={this._navToSearchableProductListView}>
          {optionChooseProductsFromIndex === 0 && !APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn
            ? `เพิ่มสินค้าจากร้าน ${storeName}`
            : 'เพิ่มสินค้า'}
        </XButtonWithIcon> */}
        {/* <XButtonWithIcon
          h='full'
          flex={1}
          flexWrap='wrap'
          _text={{ numberOfLines: 1 }}
          rightIcon={<ForwardIcon />}
          onPress={this.handlePrepareToAddProductsToCart}>
          {optionChooseProductsFromIndex === 0 && !APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn
            ? `เพิ่มสินค้าจากร้าน ${storeName}`
            : 'เพิ่มสินค้า'}
        </XButtonWithIcon> */}
        <XButtonWithIcon
          h='full'
          flex={1}
          flexWrap='wrap'
          _text={{ numberOfLines: 1 }}
          leftIcon={<XIcon name='cart-sharp' family='Ionicons' />}
          onPress={this.handlePrepareToAddProductsToCart}>
          เลือก
        </XButtonWithIcon>

        <XButtonWithIcon
          h='full'
          flex={1}
          flexWrap='wrap'
          _text={{ numberOfLines: 1 }}
          leftIcon={<XIcon name='search' family='Ionicons' />}
          onPress={this._navToSearchableProductListView}>
          ค้นหา
        </XButtonWithIcon>

        {/* <XIconButton h='11' w='11' name='md-cart-sharp' family='Ionicons' variant='solid' onPress={this.handlePrepareToAddProductsToCart} /> */}

        {/* <XIconButton h='11' w='11' name='search' family='Ionicons' variant='solid' onPress={this._navToSearchableProductListView} /> */}
        {/* <XButton
          _text={{ fontSize: 'xs' }}
          w='11'
          h='11'
          flexDir='column'
          // flexDirection='column'
          alignContent='center'
          justifyContent='center'
          startIcon={<XIcon name='search' family='Ionicons' variant='activeDark' w='6' />}
          onPress={this._navToSearchableProductListView}>
          ค้นหา
        </XButton> */}

        {/* <XBlankButton
          h='full'
          flex={1}
          flexWrap='wrap'
          // rightIcon={<ForwardIcon variant='activeDark' />}
          onPress={this.handlePrepareToAddProductsToCart}>
          <HStack flex={1} space='1' alignItems='center' justifyContent='space-between'>
            <HStack flex={1} px='1' alignItems='center' justifyContent='center'>
              <XText numberOfLines={1} variant='activeDark'>
                {optionChooseProductsFromIndex === 0 && !APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn
                  ? `เพิ่มสินค้าจากร้าน ${storeName}`
                  : 'เพิ่มสินค้า'}
              </XText>
            </HStack>
            <ForwardIcon variant='activeDark' />
          </HStack>
        </XBlankButton> */}
        {/* {isStoreUseUPC && isStoreUseSKU ? <VStack style={{ flex: 0, width: 10 }} /> : null} */}
        {isStoreUseSKU && APP_CONFIG.order.create_order.show_add_product_by_sku_btn ? (
          <BarcodeScannerModalButton
            saveStateKey={xCONS.STATE_SAVE_KEYS.BARCODE_SCANNER_MODAL}
            label='SKU'
            headerTitle='สแกนบาร์โค้ดสินค้า (SKU)'
            onBarcodeScanned={this._handleBarcodeScanedSKU}
          />
        ) : null}
        {APP_CONFIG.order.create_order.show_add_product_by_upc_btn ? (
          <BarcodeScannerModalButton
            saveStateKey={xCONS.STATE_SAVE_KEYS.BARCODE_SCANNER_MODAL}
            label='UPC'
            headerTitle='สแกนบาร์โค้ดสินค้า (UPC)'
            onBarcodeScanned={this._handleBarcodeScanedUPC}
          />
        ) : null}
      </HStack>
    )
  }

  _onSegmentChangeChooseProductFrom = (newIndex: number): void => {
    // if (this.state.optionChooseProductsFromIndex === newIndex) {
    //   return // ignore duplicated
    // }
    // this.setState({ optionChooseProductsFromIndex: newIndex }, () => {
    //   if (p.op.isWeb()) {
    //     util.navPopToCurrent(this.props)
    //   }
    // })
    const { optionChooseProductsFromIndex } = this.props

    if (optionChooseProductsFromIndex === newIndex) {
      return // ignore duplicated
    }
    const { optionChooseProductsFollowingIndex, optionChooseProductsByGroupIndex, optionSelectedSellerStoreIndex } = this.props

    this._onChangeOptions({
      optionChooseProductsFromIndex: newIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsByGroupIndex,
      optionSelectedSellerStoreIndex,
    })
  }

  _renderChooseProductFrom = () => {
    const hasSellerStores = this._hasSellerStores()
    if (!hasSellerStores) {
      return null
    }
    const { optionChooseProductsFromIndex, forceUseOnlyMyStore } = this.props

    if (forceUseOnlyMyStore) {
      return null
    }

    // // ผู้ช่วยร้านจะถูกเลือก 'ร้านฉัน' อัตโนมัติ และจะไม่เห็นฟีเจอร์เลือกร้าน
    // // หรือถูก config ไว้ mode
    // const { selectedStore, optionChooseProductsFromIndex } = this.props
    // const selectMyStore = 1
    // const roleId = selectedStore.get('role_id')
    // // FIXME: เงื่อนไขตัวนี้วางใน render function ไม่น่าดี เพราะมันมีโอกาส change state ก่อให้เกิด infinite loop ได้
    // if (roleId === 2 || APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn) {
    //   this._onSegmentChangeChooseProductFrom(selectMyStore)
    //   return
    // }
    return (
      <VStack w='full' space='1'>
        <XText variant='inactive'>เลือกจากร้าน...</XText>
        <Segment
          options={OPTIONS_CHOOSE_PRODUCTS_FROM}
          selectedIndex={optionChooseProductsFromIndex}
          onSegmentChange={this._onSegmentChangeChooseProductFrom}
        />
        {this._renderSellerStoreSelector()}
      </VStack>
    )
  }

  // _getCurrentOrderChooseProductMode = (): 'UG' | 'PG' | 'None' => {
  //   const { editingOrder } = this.props
  //   const chooseProductParams = editingOrder.get('chooseProductParams')
  //   if (chooseProductParams) {
  //     const ug_id = chooseProductParams.get('ug_id')
  //     const pg_ids = chooseProductParams.get('pg_ids')
  //     if (ug_id && ug_id > 0) {
  //       return 'UG'
  //     }
  //     if (List.isList(pg_ids) && pg_ids.size > 0) {
  //       return 'PG'
  //     }
  //   }
  //   return 'None'
  // }

  _onSegmentChangeChooseProductFollowing = async (newIndex: number): Promise<void> => {
    const { optionChooseProductsFollowingIndex, optionChooseProductsByGroupIndex, selectedStore } = this.props

    if (optionChooseProductsFollowingIndex === newIndex) {
      return // ignore duplicated
    }

    // // const chooseProductMode = this._getCurrentOrderChooseProductMode()
    // let txtTargetMode = null
    // if (chooseProductMode === 'UG' && newIndex !== 0) {
    //   txtTargetMode = 'รายการราคา'
    // } else if (chooseProductMode === 'PG' && newIndex !== 1) {
    //   txtTargetMode = 'กลุ่มสินค้า'
    // }

    // if (txtTargetMode) {
    //   const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
    //     p.op.showConfirmation(
    //       'เปลี่ยนแปลงลักษณะราคา',
    //       `กรุณายืนยันว่าคุณต้องการจะเลือกสินค้าใหม่ โดยคิดราคาออเดอร์ตาม${txtTargetMode} และสินค้าที่ถูกเลือกมาแล้วจะลบออกโดยอัตโนมัติ`,
    //       () => passConfirm(true),
    //       () => passConfirm(false)
    //     )
    //   })
    //   if (!isUserConfirmed) {
    //     return
    //   }
    //   await this._initializeDefaultValueByMode()
    // }

    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])
    const hasOnlyOneUg = user_groups.size === 1
    // const haveOnlyOnePg = product_groups.size === 1
    let groupItems = List([])
    let targetIndex = newIndex
    if (targetIndex === 0) {
      groupItems = user_groups
    } else if (targetIndex === 1) {
      groupItems = product_groups
    } else {
      return
    }

    if (hasOnlyOneUg && newIndex === 0) {
      // ถ้ามี ug แค่ 1 ตัว ให้เปลี่ยนไปเป็น pg แทน
      groupItems = product_groups
      targetIndex = 1
    }

    const { optionSelectedSellerStoreIndex, optionChooseProductsFromIndex } = this.props
    const newOptions: Partial<IAddStoreProductSelectorOptions> = {
      optionChooseProductsFollowingIndex: newIndex,
      optionSelectedSellerStoreIndex,
      optionChooseProductsFromIndex,
    }
    const isValuableGroupIndex = groupItems.has(optionChooseProductsByGroupIndex)
    if (!isValuableGroupIndex) {
      newOptions.optionChooseProductsByGroupIndex = 0
    } else {
      newOptions.optionChooseProductsByGroupIndex = optionChooseProductsByGroupIndex
    }
    // await util.setStatePromise(this, newOptions)
    this._onChangeOptions(newOptions)

    // if (p.op.isWeb()) {
    //   util.navPopToCurrent(this.props)
    // }
  }

  _saveLastSelectedOptions = async (opts: Partial<IAddStoreProductSelectorOptions>) => {
    const {
      saveOptionChooseProductsFromIndexKey,
      saveOptionSelectedSellerStoreIndexKey,
      saveOptionChooseProductsFollowingIndexKey,
      saveOptionChooseProductsByGroupIndexKey,
    } = this.props

    if (_.isString(saveOptionChooseProductsFromIndexKey) && _.isNumber(opts.optionChooseProductsFromIndex)) {
      await p.op.storageSet(saveOptionChooseProductsFromIndexKey, opts.optionChooseProductsFromIndex)
    }
    if (_.isString(saveOptionSelectedSellerStoreIndexKey) && _.isNumber(opts.optionSelectedSellerStoreIndex)) {
      await p.op.storageSet(saveOptionSelectedSellerStoreIndexKey, opts.optionSelectedSellerStoreIndex)
    }
    if (_.isString(saveOptionChooseProductsFollowingIndexKey) && _.isNumber(opts.optionChooseProductsFollowingIndex)) {
      await p.op.storageSet(saveOptionChooseProductsFollowingIndexKey, opts.optionChooseProductsFollowingIndex)
    }
    if (_.isString(saveOptionChooseProductsByGroupIndexKey) && _.isNumber(opts.optionChooseProductsByGroupIndex)) {
      await p.op.storageSet(saveOptionChooseProductsByGroupIndexKey, opts.optionChooseProductsByGroupIndex)
    }
  }

  _onChangeOptions = async (newOptions: Partial<IAddStoreProductSelectorOptions>, byOnPress?: boolean) => {
    const {
      onChangeOptions,
      // optionSelectedSellerStoreIndex = 0,
      // optionChooseProductsFromIndex = 0,
      // optionChooseProductsFollowingIndex = 0,
      // optionChooseProductsByGroupIndex = 0,
    } = this.props

    if (!_.isFunction(onChangeOptions)) {
      throw new Error('AddStoreProductSelector need "onChangeOptions" props')
    }

    // onChangeOptions({
    //   optionSelectedSellerStoreIndex,
    //   optionChooseProductsFromIndex,
    //   optionChooseProductsFollowingIndex,
    //   optionChooseProductsByGroupIndex,
    //   ...newOptions,
    // })
    await this._saveLastSelectedOptions(newOptions)
    onChangeOptions(newOptions, byOnPress)
  }

  _onRadioChangeChooseProductsByGroup = async (newIndex: number): Promise<void> => {
    const { optionChooseProductsByGroupIndex, selectedStore } = this.props

    if (optionChooseProductsByGroupIndex === newIndex) {
      return // ignore duplicated
    }

    const userGroups = selectedStore.get('user_groups') || List([])
    const productGroups = selectedStore.get('product_groups') || List([])

    const user_groups = userGroups || List([])
    const product_groups = productGroups || List([])
    const hasOnlyOneUG = user_groups.size === 1
    const hasOnlyOnePG = product_groups.size === 1
    // const orderId = selectedOrder.get('id')
    // const ug_id = selectedOrder.get('user_group_id')
    // if (orderId && ug_id) {
    //   p.op.alert('ไม่สามารถเปลี่ยนกลุ่มผู้ใช้ได้', 'เนื่องจากมีการกำหนดกลุ่มสมาชิกขณะเปิดออเดอร์ไปแล้ว')
    //   return
    // }

    // const chooseProductMode = this._getCurrentOrderChooseProductMode()
    // if (chooseProductMode === 'UG') {
    //   // เริ่มมีการเลือกสินค้าใน mode UG ไปบ้างแล้ว
    //   if (optionChooseProductsFollowingIndex === 0 && optionChooseProductsByGroupIndex !== newIndex) {
    //     // ถ้ามีการเปลี่ยน UG จะเคลียร์สินค้าออก
    //     const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
    //       p.op.showConfirmation(
    //         'เปลี่ยนแปลงลักษณะราคา',
    //         `กรุณายืนยันว่าคุณต้องการจะเลือกสินค้าใหม่ โดยคิดราคาออเดอร์ตามกลุ่มสมาชิกอื่น และสินค้าที่ถูกเลือกมาแล้วจะลบออกโดยอัตโนมัติ`,
    //         () => passConfirm(true),
    //         () => passConfirm(false)
    //       )
    //     })
    //     if (!isUserConfirmed) {
    //       return
    //     }
    //     // await this._initializeDefaultValueByMode()
    //   }
    // }

    const { optionChooseProductsFollowingIndex, optionSelectedSellerStoreIndex, optionChooseProductsFromIndex } = this.props
    const newOptions: Partial<IAddStoreProductSelectorOptions> = {
      optionChooseProductsByGroupIndex: newIndex,
      optionChooseProductsFollowingIndex,
      optionSelectedSellerStoreIndex,
      optionChooseProductsFromIndex,
    }

    if (hasOnlyOneUG && hasOnlyOnePG) {
      newOptions.optionChooseProductsFollowingIndex = 1 // always by pg
    }

    // await setStatePromise(this, newState)
    this._onChangeOptions(newOptions, true)

    // if (p.op.isWeb()) {
    //   util.navPopToCurrent(this.props)
    // }
  }

  _renderAddMyProductBySelectedGroup = () => {
    const { selectedStore } = this.props
    const {
      // mode,
      optionChooseProductsFromIndex = 0,
      optionChooseProductsFollowingIndex = 0,
      optionChooseProductsByGroupIndex = 0,
      forceUseOnlyUg,
      forceUseOnlyPg,
    } = this.props

    const hasSellerStores = this._hasSellerStores()
    if (optionChooseProductsFromIndex === 0 && hasSellerStores) {
      // ถ้าเลือกสินค้าจากร้านผู้ขายส่งก็ไม่ต้อง set
      return null
    }

    // log(this, '_renderAddMyProductBySelectedGroup optionChooseProductsFollowingIndex => ', optionChooseProductsFollowingIndex)
    // log(this, '_renderAddMyProductBySelectedGroup optionChooseProductsByGroupIndex => ', optionChooseProductsByGroupIndex)
    let groupItems = List([])
    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])

    const hasNoUg = user_groups.size === 0
    const hasOnlyOneUg = user_groups.size === 1
    const haveOnlyOnePg = product_groups.size === 1

    if ((hasOnlyOneUg && haveOnlyOnePg) || (hasNoUg && haveOnlyOnePg)) {
      // ถ้ามี ug และ pg แค่อย่างละ 1 ตัว
      return null
    }
    if (optionChooseProductsFollowingIndex === 1) {
      // ถ้ามี ug แค่ 1 ตัว
      groupItems = product_groups
    } else if (optionChooseProductsFollowingIndex === 0) {
      groupItems = user_groups
    } else {
      return null
    }

    // const { selectedOrder } = this.props
    // const orderId = selectedOrder.get('id')
    // const ug_id = selectedOrder.get('user_group_id')
    // const forceUseOnlyUg = _.includes([VIEW_EDIT, EDIT], mode) && orderId && ug_id
    // const forceUseOnlyPg = _.includes([VIEW_EDIT, EDIT], mode) && orderId && !ug_id

    // let txtChooseProductFollowing = null
    // if ((_.includes([CREATE], mode) && hasOnlyOneUg) || forceUseOnlyPg) {
    //   txtChooseProductFollowing = 'เลือกสินค้าตามรายการราคา'
    // } else if (forceUseOnlyUg) {
    //   txtChooseProductFollowing = 'เลือกสินค้าตามกลุ่มสมาชิก'
    // } else {
    //   txtChooseProductFollowing = 'เลือกสินค้าตาม...'
    // }

    let txtChooseProductFollowing = null
    if (optionChooseProductsFollowingIndex === 1 || forceUseOnlyPg) {
      txtChooseProductFollowing = 'เลือกตามรายการราคา'
    } else if (optionChooseProductsFollowingIndex === 0 || forceUseOnlyUg) {
      txtChooseProductFollowing = 'เลือกตามกลุ่มสมาชิก'
    } else {
      txtChooseProductFollowing = 'เลือกตาม...'
    }

    // // ถ้า config แสดงเฉพาะร้านเรา ไม่ต้องแสดง และเลือกจากรายการราคาอันดับแรกเสมอ
    // if (APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn) {
    //   this._onSegmentChangeChooseProductFollowing(1)
    //   return null
    // }

    if (APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn) {
      return null
    }

    return (
      <VStack w='full' mt='2'>
        <XText variant='inactive'>{txtChooseProductFollowing}</XText>
        {forceUseOnlyUg || forceUseOnlyPg ? null : (
          <Segment
            options={OPTIONS_CHOOSE_PRODUCTS_FOLLOWING}
            selectedIndex={optionChooseProductsFollowingIndex}
            onSegmentChange={this._onSegmentChangeChooseProductFollowing}
          />
        )}
        <VStack mt='2' space='1'>
          {groupItems.map((groupItem, groupItemIndex) => {
            const isActive = groupItemIndex === optionChooseProductsByGroupIndex
            return (
              <OrderRadioGroupItem
                key={`ASPS_GroupItem${groupItemIndex.toString()}`}
                index={groupItemIndex}
                data={groupItem}
                active={isActive}
                onPressItem={this._onRadioChangeChooseProductsByGroup}
              />
            )
          })}
        </VStack>

        {/* { */}
        {/* groupItems.map((groupItem, groupItemIndex) => { */}
        {/* return ( */}
        {/* <HStack key={groupItemIndex}> */}
        {/* <VStack style={[INTL_STYLES.colMiddleCenter, { flex: 0, width: 24 }]}> */}
        {/* <XRadio */}
        {/* index={groupItemIndex} */}
        {/* data={groupItem} */}
        {/* // onPress={({ index }) => this.setState({ optionChooseProductsByGroupIndex: groupItemIndex })} */}
        {/* onPressAtIndex={this._onRadioChangeChooseProductsByGroup} */}
        {/* checked={groupItemIndex === optionChooseProductsByGroupIndex} /> */}
        {/* </VStack> */}
        {/* <VStack style={INTL_STYLES.colMiddleLeft}> */}
        {/* <XText style={INTL_STYLES.txtActive}> */}
        {/* { groupItem.get('name') } */}
        {/* </XText> */}
        {/* </VStack> */}
        {/* </HStack> */}
        {/* ) */}
        {/* }) */}
        {/* } */}
      </VStack>
    )
  }

  _handleChangeSellerStore = (newIndex: number) => {
    const { sellerStores, optionChooseProductsByGroupIndex, optionChooseProductsFromIndex, optionChooseProductsFollowingIndex } = this.props
    const isAvailableStore = !_.isNil(sellerStores.getIn([newIndex, 'id']))
    const newSelectedStoreIndex = isAvailableStore ? newIndex : -1
    // this.setState(
    //   {
    //     isVisibleSellerStoreSelector: false,
    //     optionSelectedSellerStoreIndex: newSelectedStoreIndex,
    //   },
    //   () => {
    //     p.op.setAppDefaultValue('order_selected_seller_store_index', newSelectedStoreIndex)

    //     if (p.op.isWeb()) {
    //       util.navPopToCurrent(this.props)
    //     }
    //   }
    // )

    this._onChangeOptions({
      optionSelectedSellerStoreIndex: newSelectedStoreIndex,
      optionChooseProductsByGroupIndex,
      optionChooseProductsFromIndex,
      optionChooseProductsFollowingIndex,
    })
  }

  _renderSellerStoreSelectorItem = ({ coverImgUri, storeName, storeDesc, isChecked = false, isSelector = false }) => (
    <XCard>
      <XCard.Body>
        <HStack alignItems='center' justifyContent='space-between' space='1'>
          <HStack alignItems='center' space='1'>
            <FastImage
              style={{ height: 50, width: 50 }}
              source={{
                uri: coverImgUri || 'https://s3-ap-southeast-1.amazonaws.com/xselly/c/1.jpg',
                priority: FastImage.priority.low,
              }}
              // resizeMode='cover'
              resizeMode={FastImage.resizeMode.cover}
            />
            <VStack>
              <XText>{storeName}</XText>
              {storeDesc ? (
                <XText variant='inactive' numberOfLines={3}>
                  {storeDesc}
                </XText>
              ) : null}
            </VStack>
          </HStack>
          <HStack alignItems='center' justifyContent='flex-end' space='1'>
            {isChecked ? <XIcon name='check' family='FontAwesome' /> : null}
            {isSelector ? <ForwardIcon inactive /> : null}
          </HStack>
        </HStack>
      </XCard.Body>
    </XCard>
  )

  _renderSellerStoreSelectorListItem = ({ item, index }) => {
    const { optionSelectedSellerStoreIndex } = this.props
    const isChecked = optionSelectedSellerStoreIndex === index
    const coverImgUri = item.get('cover_thumbnail_uri')
    const storeName = item.get('name')
    const storeDesc = item.get('description')
    // const handlePress = () => this._handleChangeSellerStore(index)
    return this._renderSellerStoreSelectorItem({ coverImgUri, storeName, storeDesc, isChecked, isSelector: !isChecked })
  }

  _renderSellerStoreSelector = () => {
    const { optionChooseProductsFromIndex, optionSelectedSellerStoreIndex } = this.props
    const { sellerStores } = this.props
    if (optionChooseProductsFromIndex !== 0 || !List.isList(sellerStores) || sellerStores.size === 0) {
      return null
    }
    const coverImgUri = sellerStores.getIn([optionSelectedSellerStoreIndex, 'cover_thumbnail_uri'])
    const storeName = sellerStores.getIn([optionSelectedSellerStoreIndex, 'name'])
    const storeDesc = sellerStores.getIn([optionSelectedSellerStoreIndex, 'description'])
    // const handlePress = () => this.setState({ isVisibleSellerStoreSelector: true })
    const onPickSellerStore = (newItem) => this._handleChangeSellerStore(newItem.index)
    if (!storeName) {
      // should be impossible
      return null
    }

    const renderSelectedValue = ({ item, index }) =>
      this._renderSellerStoreSelectorItem({ coverImgUri, storeName, storeDesc, isSelector: true })
    //  (
    // <View style={{ borderStyle: 'dotted', borderWidth: 0.5, borderColor: TEXT_INACTIVE }}>
    //  {this._renderSellerStoreSelectorItem({ coverImgUri, storeName, storeDesc, isSelector: true })}
    // </View>
    // )

    return (
      <XFlatPicker
        key='Add_Product_From_Store'
        title='เพิ่มสินค้าจากร้าน...'
        selectedIndex={optionSelectedSellerStoreIndex}
        options={sellerStores.toArray()}
        onPick={onPickSellerStore}
        // renderSelectedValue={this._renderDetailSaleChannelCardButton}
        renderSelectedValue={renderSelectedValue}
        renderItem={this._renderSellerStoreSelectorListItem}
      />
    )
  }

  _isAddProductButtonDisabled = () => {
    const { onChooseProductButtonPressed, onSearchProductButtonPressed, onChooseProductAfterBarcodeScanned } = this.props
    if (!_.isFunction(onChooseProductButtonPressed)) {
      return true
    }
    if (!_.isFunction(onSearchProductButtonPressed)) {
      return true
    }
    if (!_.isFunction(onChooseProductAfterBarcodeScanned)) {
      return true
    }

    return false
  }

  _renderAddProductButton = () => {
    const { selectedStore, sellerStores, optionChooseProductsFromIndex, optionSelectedSellerStoreIndex } = this.props
    if (this._isAddProductButtonDisabled()) {
      return null
    }

    let isStoreUseUPC = selectedStore.get(COMPUTED_USE_PRODUCT_UPC) || false
    let isStoreUseSKU = selectedStore.get(COMPUTED_USE_PRODUCT_SKU) || false
    const isMyStoreChoose = optionChooseProductsFromIndex === 1
    let isAvailableBarcodeSearch = (isStoreUseUPC || isStoreUseSKU) && isMyStoreChoose
    if (!isMyStoreChoose) {
      isStoreUseUPC = true
      isStoreUseSKU = false
      isAvailableBarcodeSearch = true
    }
    return this._renderButtonCameraUPCAndSKU(isStoreUseSKU, isStoreUseUPC)
  }

  renderMain = () => {
    const { selectedStore } = this.props
    if (!Map.isMap(selectedStore)) {
      return null
    }

    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])
    const hasOnlyOneUg = user_groups.size === 1
    const haveOnlyOnePg = product_groups.size === 1
    const hasSellerStores = this._hasSellerStores()
    if (!hasSellerStores && hasOnlyOneUg && haveOnlyOnePg) {
      return this._renderAddProductButton()
    }

    return (
      <VStack
        w='full'
        // borderWidth='1'
        // borderColor='warmGray.300'
        // p='1'
        // style={INTL_STYLES.rowGroupAreaBordered}
      >
        {/* Begin Wrapper */}

        {this._renderChooseProductFrom()}
        {this._renderAddMyProductBySelectedGroup()}

        {this._isAddProductButtonDisabled() ? null : (
          <VStack w='full' mt='2' space='1'>
            <XText variant='inactive'>กดปุ่มด้านล่างเพื่อเลือกสินค้าเข้าออเดอร์...</XText>
            {this._renderAddProductButton()}
          </VStack>
        )}

        {/* End Wrapper */}
      </VStack>
    )
  }

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return this.renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    sellerStores: getSellerStores(state),
    // subscription: getSubscription(state),
  }),
  (dispatch) => ({
    dispatch,
    fetchProducts: bindActionCreators(ProductActions.fetchProducts, dispatch),
    // submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    // resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(AddStoreProductSelector) as React.FC<IAddStoreProductSelectorExternalProps>
