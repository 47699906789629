import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getSelectedStore } from 'x/redux/selectors'

// import api from 'x/utils/api'
import * as util from 'x/utils/util'
import * as acl from 'x/utils/acl'
import CONS from 'x/config/constants'
import { COLORS } from 'x/config/styles'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import XIconButton from 'xui/components/XIconButton'
import {
  ISearchProductVariantListViewProps,
  ISearchProductVariantListViewState,
  ISearchProductItem,
  IProductListFilterTemplate,
  ICategory,
} from 'x/types'
import { FlatList, ListRenderItemInfo, TextInput, TouchableOpacity } from 'react-native'
import * as api from 'x/utils/api'
import Box from 'xui/components/Box'
import XSpinner from 'xui/components/XSpinner'
import ErrorListItem from 'xui/components/ErrorListItem'
import XIcon from 'xui/components/XIcon'

import * as NavActions from 'x/utils/navigation'
import ProductVariantListFilterTemplateSelector from 'xui/components/product/ProductVariantListFilterTemplateSelector'
import { diff } from 'deep-object-diff'
import ProductCategorySelector from 'xui/components/product/ProductCategorySelector'
import SearchProductVariantListItem from 'xui/components/product/SearchProductVariantListItem'

// const { NAME, COST, PRICE, PRICE_RETAIL, QTY, AVAILABLE_QTY, WEIGHT, H, SKU, UPC, CATEGORY } = CONS.PRODUCT_ATTR

class SearchProductVariantListView extends React.Component<ISearchProductVariantListViewProps, ISearchProductVariantListViewState> {
  static displayName = 'SearchProductVariantListView'

  // NodeJs.Timeout
  searchProductTimeoutId?: string | number | any

  inProcess?: boolean

  isFetchingInit?: boolean

  isFetchingMore?: boolean

  searchInputRef: React.RefObject<TextInput>

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,

      searchText: '',
      isInitFetched: false,

      products: [],
      productCount: 0,
      fetchErrorMessage: null,

      isFetchingInit: false,
      isFetchingMore: false,

      defaultFilter: {},
      currentFilter: {},

      listItemRevisions: {},
    }

    this.searchProductTimeoutId = null

    this.searchInputRef = React.createRef()
  }

  async componentDidMount() {
    await this._setupFilter()

    const initialData = util.getNavParam(this.props, 'initialData')
    if (initialData && initialData.products && initialData.products.length > 0) {
      await util.setStatePromise(this, { ...initialData, isInitFetched: true })
    } else {
      await this.fetchInit()
    }

    await util.setStatePromise(this, { isInitialized: true })
  }

  _updateListItemRevision = async (itemIndex: number) => {
    const { listItemRevisions } = this.state
    const newListItemRevisions = { ...listItemRevisions }
    newListItemRevisions[itemIndex] = newListItemRevisions[itemIndex] ? newListItemRevisions[itemIndex] + 1 : 1
    await util.setStatePromise(this, { listItemRevisions: newListItemRevisions })
  }

  _isPermProductQtyViewable = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_VIEW)

  _isPermProductCostViewable = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_COST)

  _isPermProductPriceViewable = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_PRICE)

  _isSelectedStoreHasMkpChannels = () => {
    const { selectedStore } = this.props
    const hasMkp = selectedStore.get('has_mkp') || false
    const mkpChannels = util.getMKPChannels()

    if (hasMkp && mkpChannels && mkpChannels.length > 0) {
      return true
    }

    return false
  }

  _setupFilter = async () => {
    const defaultFilter = util.getNavParam(this.props, 'defaultFilter', {}) as IProductListFilterTemplate
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    const seller_store_id = util.getNavParam(this.props, 'seller_store_id')

    if (!_.has(defaultFilter, 'searchText')) {
      defaultFilter.searchText = ''
    }

    if (_.isNumber(ug_id)) {
      defaultFilter.selectedUserGroupId = ug_id
    }

    if (_.isArray(pg_ids) && pg_ids.length > 0) {
      defaultFilter.selectedProductGroupIds = pg_ids
    }

    if (_.isNumber(seller_store_id)) {
      defaultFilter.sellerStoreId = seller_store_id
    }

    if (this._isSelectedStoreHasMkpChannels()) {
      defaultFilter.isMkpShow = true
    }

    await util.setStatePromise(this, { defaultFilter, currentFilter: defaultFilter, searchText: defaultFilter.searchText })
  }

  getTargetStoreId = () => {
    const sellerStoreId = util.getNavParam(this.props, 'seller_store_id')

    if (_.isNumber(sellerStoreId)) {
      return sellerStoreId
    }

    // const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    // if (this.isSellerStore()) {
    //   storeId =util.getNavParam(this.props, 'seller_store_id')
    // } else {
    //   storeId =util.getNavParam(this.props, 'store_id')
    // }
    if (typeof storeId !== 'number') {
      throw new Error('store_id is required')
    }

    return storeId
  }

  _onSearchChangeText = (searchText: string) => {
    this.setState({ searchText, isInitFetched: false }, () => {
      if (searchText === '') {
        this._clearSearch()
        return
      }

      if (this.searchProductTimeoutId) {
        clearTimeout(this.searchProductTimeoutId)
      }

      this.searchProductTimeoutId = setTimeout(this.fetchInit, 600)
    })
  }

  isHelper = () => {
    const { selectedStore } = this.props
    return selectedStore.has('role_id') ? selectedStore.get('role_id') === CONS.STORE_ROLE.HELPER : false
  }

  _checkSearchTextOnFilter = async () => {
    const { currentFilter, searchText } = this.state

    const filterSearchText = currentFilter && currentFilter.searchText ? currentFilter.searchText : null
    if (_.isString(searchText) && (_.isNil(filterSearchText) || filterSearchText !== searchText)) {
      const newFilter = _.cloneDeep(currentFilter)
      newFilter.searchText = searchText

      // if ('id' in newFilter && filterSearchText !== searchText) {
      //   delete newFilter.id
      // }

      await util.setStatePromise(this, { currentFilter: newFilter })
    }
  }

  fetchInit = async () => {
    if (this.isFetchingInit) {
      return
    }
    this.isFetchingInit = true

    await util.setStatePromise(this, { isFetchingInit: true })

    await this._checkSearchTextOnFilter()
    await util.delay(50)
    await util.setStatePromise(this, { productCount: 0, products: [], fetchErrorMessage: null })
    await util.delay(50)
    await this.fetchProducts({ offset: 0 })
    await util.delay(50)

    await util.setStatePromise(this, { isInitFetched: true, isFetchingInit: false, isListItemEdited: false })
    this.isFetchingInit = false
  }

  fetchMore = async () => {
    if (this.isFetchingInit || this.isFetchingMore || this.state.isFetchingMore) {
      return
    }
    this.isFetchingMore = true

    if (!this.state.isInitFetched || this.state.isFetchingInit) {
      this.isFetchingMore = false
      return
    }

    if (!this.hasLoadMore()) {
      this.isFetchingMore = false
      return
    }

    await util.setStatePromise(this, { isFetchingMore: true })

    const { products } = this.state
    // console.log('SearchProductVariantListView::fetchMore products.length => ', products.length)
    await this.fetchProducts({ offset: products.length })
    await util.delay(100)

    await util.setStatePromise(this, { isFetchingMore: false })
    this.isFetchingMore = false
  }

  hasLoadMore = () => {
    const { products = [], productCount = 0 } = this.state
    const currentItemCount = products.length
    if (currentItemCount >= productCount) {
      return false
    }
    return true
  }

  isListItemPressable = () => {
    const onProductItemPressed = util.getNavParam(this.props, 'onProductItemPressed')
    return _.isFunction(onProductItemPressed)
  }

  getOnPressListItem = (item: ISearchProductItem, index: number) => async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    // this.onPressListItem(item)

    const onProductItemPressed = util.getNavParam(this.props, 'onProductItemPressed')

    if (_.isNil(onProductItemPressed) || !_.isFunction(onProductItemPressed)) {
      throw new Error('onProductItemPressed is not a function')
    }

    onProductItemPressed(item)
    await util.delay(100)
    await this._updateListItemRevision(index)

    this.inProcess = false
  }

  fetchProducts = async (extraParams: any) => {
    const { searchText, currentFilter } = this.state
    // console.log('fetchProducts extraParams => ', extraParams)
    // console.log('fetchProducts searchText => ', searchText)

    const store_id = util.getNavParam(this.props, 'store_id')
    const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
    // const ug_id = util.getNavParam(this.props, 'ug_id')
    // const pg_ids = util.getNavParam(this.props, 'pg_ids')

    // const queryTextArrayFiltered = searchText.split(/(\s+)/g).filter((str) => str.trim().length > 0)
    // const queryTextArray = queryTextArrayFiltered.slice(0, 12)
    // console.log('fetchProducts currentFilter => ', currentFilter)
    const filterReqBody = util.translateProductListFilterToRequestBody(currentFilter)
    // console.log('fetchProducts filterReqBody => ', filterReqBody)

    if (seller_store_id && filterReqBody && filterReqBody.show_mkp_ch_ids) {
      delete filterReqBody.show_mkp_ch_ids
    }

    const reqBody = {
      store_id,
      // store_id: seller_store_id || store_id,
      offset: 0,
      // limit: CONS.PRODUCTS_FETCH_LIMIT_MORE,
      limit: 20,
      queryText: searchText,

      ...filterReqBody,
    }

    if (_.isNumber(extraParams.offset)) {
      reqBody.offset = extraParams.offset
    }

    if (reqBody.offset === 0) {
      // @ts-ignore c คือขอ total count
      reqBody.c = 1
      reqBody.limit = 15
    }

    if (this.isHelper() && !seller_store_id) {
      // @ts-ignore
      reqBody.is_helper = true
    }

    try {
      // // console.log('fetchProducts reqBody => ', reqBody)
      // // BEGIN TODO: Remove this temp code
      // if (this.isListItemPressable()) {
      //   // หน้า เลือกสินค้าเข้าออเดอร์
      //   reqBody.is_v2 = false
      // } else {
      //   // หน้า รายการสต๊อก
      //   reqBody.is_v2 = true
      // }
      // // END: Remove this temp code

      const res = await api.searchInventoryProducts(reqBody)

      // console.log('fetchProducts res => ', res)
      // this.isFetching = false
      // return res

      // if (res.c) {
      //   await util.setStatePromise(this, { productCount: res.c })
      // }

      let productCount
      let newProducts
      const newState: Partial<ISearchProductVariantListViewState> = {}

      if (res.count) {
        // await util.setStatePromise(this, { productCount: res.count })
        productCount = res.count
        newState.productCount = productCount
      }

      if (res.products) {
        const { products } = this.state
        // await util.setStatePromise(this, { products: products.concat(res.products) })
        newProducts = products.concat(res.products)
        newState.products = newProducts
      }

      await util.setStatePromise(this, newState)

      const onSearchSuccess = util.getNavParam(this.props, 'onSearchSuccess')
      if (_.isFunction(onSearchSuccess)) {
        onSearchSuccess(searchText, newProducts, productCount)
      }
    } catch (err) {
      console.log('fetchProducts err => ', err)
      if (reqBody.offset === 0) {
        await util.setStatePromise(this, { fetchErrorMessage: util.getErrorMessageFromErrorResponse(err) })
      }
    }
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _keyExtractor = (item: ISearchProductItem, index: number) => `p_${index}_${item.id}`

  _onChangeItemData = (newData: ISearchProductItem) => {
    const { products } = this.state
    const newProducts = products.map((item) => {
      if (item.id === newData.id && item.v[0].i === newData.v[0].i) {
        return newData
      }
      return item
    })

    this.setState({ products: newProducts, isListItemEdited: true })
  }

  _onSuccessPullProductToOrderCallback = async () => {
    const onSuccessPullMyProductToOrderCallback = util.getNavParam(this.props, 'onSuccessPullMyProductToOrderCallback')
    const onSuccessPullSellersProductToOrderCallback = util.getNavParam(this.props, 'onSuccessPullSellersProductToOrderCallback')

    if (_.isFunction(onSuccessPullMyProductToOrderCallback)) {
      await onSuccessPullMyProductToOrderCallback()
    }

    if (_.isFunction(onSuccessPullSellersProductToOrderCallback)) {
      await onSuccessPullSellersProductToOrderCallback()
    }
  }

  _renderListItem = (info: ListRenderItemInfo<ISearchProductItem>) => {
    const { navigation } = this.props
    const { listItemRevisions } = this.state
    const { item, index } = info
    // // const { n, vn, t } = item
    // const { n, v: vs, t } = item
    // const v = vs[0]
    // const productName = n || ''
    // const variantName = v.n || ''
    // // const priceText = v.p ? v.p : v.s[0].p
    // const sku = v.sku || null
    // const upc = v.upc || null
    // let imgUri = t[0]
    // if (vs[0].img_url) {
    //   imgUri = vs[0].img_url
    // }

    // const currentQty = _.isNil(v.q) ? 0 : v.q
    const productItemButtonText = util.getNavParam(this.props, 'productItemButtonText')
    const renderProductVariantItemButton = util.getNavParam(this.props, 'renderProductVariantItemButton')
    const isPullProductToOrderMode = util.getNavParam(this.props, 'isPullProductToOrderMode', false)
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    const store_id = util.getNavParam(this.props, 'store_id')
    const { currentFilter } = this.state

    return (
      <SearchProductVariantListItem
        key={`pp-listitem-${index}-${item.id}-${listItemRevisions[index]}`}
        index={index}
        isPullProductToOrderMode={isPullProductToOrderMode}
        ugId={ug_id}
        pgIds={pg_ids}
        onSuccessPullMyProductToOrderCallback={this._onSuccessPullProductToOrderCallback}
        onSuccessPullSellersProductToOrderCallback={this._onSuccessPullProductToOrderCallback}
        storeId={store_id}
        sellerStoreId={currentFilter.sellerStoreId ? currentFilter.sellerStoreId : null}
        navigation={navigation}
        data={item}
        buttonText={productItemButtonText}
        renderListItemButton={renderProductVariantItemButton}
        onPress={this.isListItemPressable() ? this.getOnPressListItem(item, index) : null}
        onChangeData={this._onChangeItemData}
      />
    )

    // return (
    //   <XCard w='full' onPress={this.getOnPressListItem(info.item)}>
    //     <XCard.Body>
    //       <HStack w='full' space='1'>
    //         <XImage source={{ uri: imgUri }} h='64px' w='64px' borderRadius='lg' />
    //         <VStack p='1' flex={1}>
    //           <XText variant='inactive' numberOfLines={2}>
    //             {productName}
    //           </XText>

    //           <XText numberOfLines={2}>{variantName}</XText>

    //           {sku ? <XText variant='inactive' fontSize='xs' numberOfLines={1}>{`SKU: ${sku}`}</XText> : null}
    //           {upc ? <XText variant='inactive' fontSize='xs' numberOfLines={1}>{`UPC: ${upc}`}</XText> : null}

    //           {/* <XText>{`฿${priceText}`}</XText> */}
    //           <XText variant='inactive'>
    //             {p.op.t('qty')}: <XText variant='active'>{currentQty}</XText>
    //           </XText>
    //           {/* <XText>{JSON.stringify(info)}</XText> */}
    //         </VStack>
    //       </HStack>
    //     </XCard.Body>
    //   </XCard>
    // )
  }

  _renderEmptyList = () => {
    const { isInitFetched, searchText, isFetchingInit, fetchErrorMessage } = this.state

    if (isFetchingInit) {
      return (
        <VStack w='full' p='2' py='4' alignItems='center' justifyContent='center'>
          <XText>กำลังค้นหา...</XText>
        </VStack>
      )
    }

    if (searchText === '' && !isInitFetched) {
      return (
        <VStack w='full' p='2' pt='4' alignItems='center' justifyContent='center'>
          <XText>พิมพ์เพื่อค้นหาสินค้าจากข้อมูลเหล่านี้</XText>
          <VStack w='full' p='2' px='6'>
            <XText>- ชื่อสินค้า</XText>
            <XText>- ชื่อตัวเลือกสินค้า</XText>
            <XText>- SKU</XText>
            <XText>- UPC</XText>
          </VStack>
          <VStack w='full' p='2' px='4'>
            <XText>คุณสามารถ*เคาะวรรค*เพื่อกรองสินค้าที่มีทุกคำที่ระบุมาได้เช่น "กางเกงทะเล แดง 36"</XText>
            <XText pt='1.5'>
              ก็จะแสดงผลการค้นหาสินค้าที่มีทั้ง 3 คำนี้ "กางเกงทะเล" "แดง" และ "36" อยู่ใน ชื่อสินค้า ชื่อตัวเลือกสินค้า SKU หรือ UPC
              เป็นต้น
            </XText>
          </VStack>
        </VStack>
      )
    }

    if (!isInitFetched) {
      return null
    }

    if (_.isObject(fetchErrorMessage) && !_.isEmpty(fetchErrorMessage)) {
      return this._renderFetchErrorMessage()
    }

    const emptyWarningText = `ไม่พบรายการค้นหา "${searchText}"`

    return (
      <HStack w='full' p='2' py='4' alignItems='center' justifyContent='center' flexWrap='wrap'>
        <XText w='full' textAlign='center' textAlignVertical='center'>
          {emptyWarningText}
        </XText>
      </HStack>
    )
  }

  _renderListFooter = () => {
    const { isFetchingMore, isInitFetched, productCount } = this.state

    if (!isInitFetched) {
      return null
    }

    if (isInitFetched && productCount === 0) {
      return null
    }

    if (isFetchingMore) {
      return (
        <VStack w='full' p='2' alignItems='center' justifyContent='center'>
          <XSpinner size='sm' color={COLORS.TEXT_INACTIVE} />
        </VStack>
      )
    }

    return (
      <VStack w='full' p='2' alignItems='center' justifyContent='center'>
        <XText variant='inactive'>-- สิ้นสุดรายการ --</XText>
      </VStack>
    )
  }

  _renderListItemSeparator = () => <Box h='8px' w='full' />

  _renderListItems = () => {
    const { products = [] } = this.state
    return (
      <FlatList
        data={products}
        keyExtractor={this._keyExtractor}
        renderItem={this._renderListItem}
        // style={{ backgroundColor: 'rgba(0,188,0,0.5)' }}
        contentContainerStyle={{
          padding: 8,
          // backgroundColor: 'rgba(188,0,0,0.5)',
        }}
        ItemSeparatorComponent={this._renderListItemSeparator}
        ListFooterComponent={this._renderListFooter()}
        ListEmptyComponent={this._renderEmptyList()}
        onEndReached={this.fetchMore}
      />
    )
  }

  _clearSearch = async () => {
    // const { currentFilter } = this.state
    // const newCurrentFilter = _.cloneDeep(currentFilter)
    // if (currentFilter && currentFilter.selectedCategoryId) {
    //   newCurrentFilter.selectedCategoryId = null
    // }

    await util.setStatePromise(this, {
      productCount: 0,
      products: [],
      searchText: '',
      isInitFetched: false,
      //  currentFilter: newCurrentFilter
    })
    if (this.searchProductTimeoutId) {
      await util.delay(100)
      clearTimeout(this.searchProductTimeoutId)
    }

    await util.delay(100)
    await this._setupFilter()
    await util.delay(100)
    this.fetchInit()

    try {
      this.searchInputRef.current.focus()
    } catch (err) {
      console.log('_clearSearch err => ', err)
    }
  }

  _renderCloseSearchButton = () => {
    const { searchText, isFetchingInit } = this.state
    if (searchText.length === 0) {
      return null
    }

    if (isFetchingInit) {
      return (
        <HStack w='32px' alignItems='center' justifyContent='center' position='absolute' right='0' top='0' bottom='0'>
          <XSpinner size='sm' />
        </HStack>
      )
    }

    return (
      <HStack w='32px' alignItems='center' justifyContent='center' position='absolute' right='0' top='0' bottom='0'>
        <XIconButton name='close' onPress={this._clearSearch} />
      </HStack>
    )
  }

  // แสดง error message จากการ fetch
  _renderFetchErrorMessage = () => {
    const { fetchErrorMessage } = this.state
    const handleshowAlertFetchError = () => util.showAlertFetchError(fetchErrorMessage)

    return <ErrorListItem onPressShowAlertInfo={handleshowAlertFetchError} onPressDoRefresh={this.fetchInit} />
  }

  _renderSearchInput = () => {
    const { searchText, productCount } = this.state
    return (
      <XInput
        ref={this.searchInputRef}
        // flex={1}
        // h='9'
        w='full'
        // px='50px'
        autoFocus
        // placeholder='พิมพ์เพื่อค้นหาสินค้า...'
        placeholder={_.isNumber(productCount) ? `ค้นหาสต๊อก ${productCount} รายการ` : 'พิมพ์เพื่อค้นหาสินค้า...'}
        value={searchText}
        maxLength={200}
        onChangeText={this._onSearchChangeText}
        style={{ paddingLeft: 18, paddingRight: 18 }}
      />
    )
  }

  _renderTitleText = () => {
    // const { selectedStore } = this.props
    // let targetStoreName = selectedStore.get('name')
    const titleFromNavParam = util.getNavParam(this.props, 'title', 'รายการสต๊อก')

    return (
      <XText bold textAlign='center' textAlignVertical='center' numberOfLines={2}>
        {titleFromNavParam}
      </XText>
    )
  }

  _renderSearchInfoBar = () => {
    const { searchText = '', productCount, isInitFetched, isFetchingInit, fetchErrorMessage } = this.state
    if (searchText === '') {
      return null
    }

    if (fetchErrorMessage && !_.isEmpty(fetchErrorMessage)) {
      return null
    }

    let txtSearchResult = ''

    if (isFetchingInit) {
      txtSearchResult = `กำลังค้นหาสินค้าที่มีชื่อประกอบด้วย "${searchText}" ...`
    }

    if (isInitFetched && productCount === 0) {
      txtSearchResult = `ไม่พบสินค้าที่มีชื่อประกอบด้วย "${searchText}"`
    }

    if (isInitFetched && productCount > 0) {
      txtSearchResult = `${productCount} รายการตรงกับการค้นหา "${searchText}"`
    }

    return (
      <HStack w='full' px='2' py='1' alignItems='center' bg='white'>
        <XText fontSize='xs' variant='inactive' numberOfLines={2}>
          {txtSearchResult}
        </XText>
      </HStack>
    )
  }

  _onSubmitFilter = async (currentFilter: IProductListFilterTemplate) => {
    // console.log('SearchProductVariantListView::_onSubmitFilter filter => ', currentFilter)
    const useableFilter = this._getSetupFilter(currentFilter)

    const newSearchText = 'searchText' in useableFilter ? useableFilter.searchText : null

    await util.setStatePromise(this, {
      currentFilter: useableFilter,
      searchText: _.isNil(newSearchText) ? this.state.searchText : newSearchText,
    })

    await util.delay(100)
    await this.fetchInit()
  }

  _getSetupFilter = (currentFilter: IProductListFilterTemplate) => {
    if (!_.isObject(currentFilter)) {
      return {}
    }

    const newFilterTemlate = _.cloneDeep(currentFilter)

    const isCostPermissionGranted = this._isPermProductCostViewable()
    if (!isCostPermissionGranted && newFilterTemlate.isCostShow) {
      newFilterTemlate.isCostShow = false
    }

    if (!isCostPermissionGranted && newFilterTemlate.costFilterEnabled) {
      newFilterTemlate.costFilterEnabled = false
    }

    const isPricePermissionGranted = this._isPermProductPriceViewable()
    if (!isPricePermissionGranted && newFilterTemlate.isPriceShow) {
      newFilterTemlate.isPriceShow = false
    }

    const isQtyPermissionGranted = this._isPermProductQtyViewable()

    if (!isQtyPermissionGranted && newFilterTemlate.stockQtyEnabled) {
      newFilterTemlate.stockQtyEnabled = false
    }

    if (!isQtyPermissionGranted && newFilterTemlate.stockAvailableQtyEnabled) {
      newFilterTemlate.stockAvailableQtyEnabled = false
    }

    if (!isQtyPermissionGranted && newFilterTemlate.stockQtyToShipEnabled) {
      newFilterTemlate.stockQtyToShipEnabled = false
    }

    if (this._isPriceFilterForced()) {
      const ug_id = util.getNavParam(this.props, 'ug_id')
      const pg_ids = util.getNavParam(this.props, 'pg_ids')
      const seller_store_id = util.getNavParam(this.props, 'seller_store_id')

      if (_.isNumber(ug_id)) {
        newFilterTemlate.selectedUserGroupId = ug_id
      }

      if (_.isArray(pg_ids) && pg_ids.length > 0) {
        newFilterTemlate.selectedProductGroupIds = pg_ids
      }

      if (_.isNumber(seller_store_id)) {
        newFilterTemlate.sellerStoreId = seller_store_id
      }

      newFilterTemlate.isPriceShow = true
    }

    return newFilterTemlate
  }

  _isPriceFilterForced = () => {
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
    return _.isNumber(ug_id) || (_.isArray(pg_ids) && pg_ids.length > 0) || _.isNumber(seller_store_id)
  }

  _navToProductVariantListFilterView = () => {
    const { navigation } = this.props
    const { defaultFilter, currentFilter, searchText } = this.state

    const ug_id = util.getNavParam(this.props, 'ug_id')
    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    const seller_store_id = util.getNavParam(this.props, 'seller_store_id')

    navigation.dispatch(
      NavActions.navToProductVariantListFilterView({
        store_id: util.getNavParam(this.props, 'store_id'),
        defaultSetting: defaultFilter,
        appliedSetting: { ...currentFilter, searchText },
        onSubmit: this._onSubmitFilter,
        ug_id,
        pg_ids,
        seller_store_id,
      })
    )
  }

  onSelectProductListFilterTemplate = (filterTemplate: IProductListFilterTemplate) => {
    this._onSubmitFilter(filterTemplate)
  }

  onSaveProductListFilterTemplateSuccess = async (newPref, filterTemplate: IProductListFilterTemplate) => {
    if (filterTemplate && filterTemplate.id) {
      this._onSubmitFilter(filterTemplate)
    }
  }

  isFilterDirty = () => {
    const { defaultFilter = {}, currentFilter = {} } = this.state

    const filterDiff = diff(defaultFilter, currentFilter)
    // console.log('SearchProductVariantListView::isFilterDirty filterDiff => ', filterDiff)
    if ('searchText' in filterDiff) {
      delete filterDiff.searchText
    }

    if (_.isEmpty(filterDiff)) {
      return false
    }
    return true
  }

  renderFilterTemplateIcon = () => {
    const { navigation } = this.props
    const { currentFilter, isFetchingInit, isFetchingMore } = this.state

    const isDisabled = isFetchingInit || isFetchingMore

    return (
      <ProductVariantListFilterTemplateSelector
        navigation={navigation}
        disabled={isDisabled}
        type='icon'
        selectedTemplate={(currentFilter as IProductListFilterTemplate) || null}
        onSelect={this.onSelectProductListFilterTemplate}
        onSaveSuccess={this.onSaveProductListFilterTemplateSuccess}
      />
    )
  }

  renderFilterTemplateBar = () => {
    const { navigation } = this.props
    const { currentFilter, isFetchingInit, isFetchingMore } = this.state

    if (_.isNil(currentFilter) || !_.has(currentFilter, 'id')) {
      return null
    }

    const isDisabled = isFetchingInit || isFetchingMore

    return (
      <HStack w='full' px='2' py='0.5' bg='white'>
        <ProductVariantListFilterTemplateSelector
          disabled={isDisabled}
          type='button'
          navigation={navigation}
          selectedTemplate={(currentFilter as IProductListFilterTemplate) || null}
          onSelect={this.onSelectProductListFilterTemplate}
          onSaveSuccess={this.onSaveProductListFilterTemplateSuccess}
        />
      </HStack>
    )
  }

  _renderFilterIconButton = () => {
    const { isFetchingInit, isFetchingMore } = this.state

    const isFiltered = this.isFilterDirty()
    const isDisabled = isFetchingInit || isFetchingMore

    return (
      <TouchableOpacity disabled={isDisabled} onPress={this._navToProductVariantListFilterView}>
        <VStack pb='1px' alignItems='center' justifyContent='center'>
          <XIcon family='MaterialCommunityIcons' name='filter' variant={isDisabled ? 'inactive' : 'primary'} />
          {isFiltered ? (
            <XIcon
              family='MaterialCommunityIcons'
              name='check-circle'
              variant='success'
              position='absolute'
              bottom='0'
              right='0'
              size='12px'
            />
          ) : null}
        </VStack>
      </TouchableOpacity>
    )
  }

  _onSelectCategory = async (category?: ICategory) => {
    // console.log('SearchProductVariantListView::_onSelectCategory category => ', category)
    const { currentFilter } = this.state
    const newFilterState = { ...currentFilter } as IProductListFilterTemplate

    if (category && _.isNumber(category.id)) {
      newFilterState.selectedCategoryId = category.id
    } else {
      delete newFilterState.selectedCategoryId
    }

    if ('id' in currentFilter && currentFilter.selectedCategoryId !== newFilterState.selectedCategoryId) {
      delete newFilterState.id
    }

    await this._onSubmitFilter(newFilterState)
  }

  _renderCatListBar = () => {
    let storeId = util.getNavParam(this.props, 'store_id')
    const { currentFilter = {} } = this.state
    if (currentFilter && currentFilter.sellerStoreId) {
      storeId = currentFilter.sellerStoreId
    }

    return (
      <ProductCategorySelector
        key={storeId}
        storeId={storeId}
        selectedCategoryId={
          _.isObject(currentFilter) && _.isNumber(currentFilter.selectedCategoryId) ? currentFilter.selectedCategoryId : null
        }
        onSelectCategory={this._onSelectCategory}
      />
    )
  }

  _renderListItemEditedWarning = () => {
    const { isListItemEdited } = this.state
    if (!isListItemEdited) {
      return null
    }

    return (
      <HStack w='full' px='2' py='1' bg='gray.100' alignItems='center' justifyContent='center'>
        <XText variant='inactive'>
          มีการแก้ไขข้อมูลสินค้า เพื่อได้ข้อมูลที่เที่ยงตรงกรุณากด
          <XText variant='primary' bold borderline onPress={this.fetchInit}>
            โหลดใหม่
          </XText>
        </XText>
      </HStack>
    )
  }

  render() {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)

    return (
      <VStack w='full' flex={1}>
        <VStack w='full' borderBottomWidth='1' borderBottomColor='gray.300'>
          <HStack w='full' px='2' space='1' bg='white' alignItems='center'>
            {isGoBackButtonHidden ? null : <XIconButton name='arrow-back' onPress={this._goBack} />}
            {/* <XIconButton name='arrow-back' onPress={this._goBack} /> */}

            <HStack flex={1} alignItems='center' justifyContent='flex-start' flexWrap='wrap'>
              {this._renderTitleText()}
            </HStack>

            <HStack alignItems='center' space='1'>
              {this.renderFilterTemplateIcon()}
              {this._renderFilterIconButton()}
              {/* <XIconButton family='MaterialCommunityIcons' name='filter' onPress={this._navToProductVariantListFilterView} /> */}
            </HStack>
          </HStack>

          {this.renderFilterTemplateBar()}

          <HStack w='full' px='2' py='0.5' space='2' bg='white'>
            <HStack flex={1}>
              {this._renderSearchInput()}
              <HStack w='32px' alignItems='center' justifyContent='center' position='absolute' left='0' top='0' bottom='0'>
                <XIcon inactive name='search' />
              </HStack>
              {this._renderCloseSearchButton()}
            </HStack>
          </HStack>

          {this._renderSearchInfoBar()}
          {this._renderCatListBar()}
          {this._renderListItemEditedWarning()}
        </VStack>

        {/* <HStack w='full' p='1' bg='white'>
          <XText>Search Text: {searchText}</XText>
        </HStack> */}
        <VStack w='full' flex={1} bg='muted.200'>
          {this._renderListItems()}
        </VStack>
      </VStack>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
  })
)(SearchProductVariantListView)
