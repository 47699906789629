import React from 'react'
import XImage from './XImage'
import { ImageStyle, StyleSheet } from 'react-native'

const newIcon = require('../img/icon-new.png')

export interface NewIconProps {
  width?: number
  // height?: number
  style?: ImageStyle
}

const aspectRatio = 637 / 402
const baseWidth = 24
// const baseHeight = baseWidth / aspectRatio

const NewIcon = (props: NewIconProps) => {
  const { width = baseWidth, style = {}, ...restProps } = props
  const height = width / aspectRatio

  return (
    <XImage
      source={newIcon}
      w={`${width}px`}
      h={`${height}px`}
      {...restProps}
      style={StyleSheet.flatten(style)}
    />
  )
}

export default NewIcon
