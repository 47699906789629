import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native'
import _ from 'lodash'
import XOverlay from 'xui/components/XOverlay'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import { COLORS } from 'x/config/styles'
import { ISubscriptionPackageList } from 'x/index'
import VStack from 'xui/components/VStack'
import XIcon from 'xui/components/XIcon'
import * as xFmt from 'x/utils/formatter'
import * as util from 'x/utils/util'

interface IChangeBillingCycle {
  visible: boolean
  onRequestClose: () => void
  subscriptionPackageList: ISubscriptionPackageList
  mainPackageSku: string
  billingCycle: number
  onSelected: (month: number) => void
}

const MONTH_PACKAGE = [1, 3, 6, 12]

const ChangeBillingCycle: React.FC<IChangeBillingCycle> = function ({
  visible,
  onRequestClose,
  subscriptionPackageList,
  mainPackageSku,
  billingCycle,
  onSelected,
}) {
  const [selectedMonth, setSelectedMonth] = useState(billingCycle)

  const getPackagePrice = (month: number): number => {
    if (_.isNil(subscriptionPackageList)) {
      return 0
    }
    const { items } = subscriptionPackageList
    const cleanedSku = mainPackageSku.replace(/\d+/g, '') // Remove numbers from sku
    const sku = cleanedSku + month
    let price = 0
    items.forEach((item) => {
      if (item.sku === sku) {
        price = item.price_with_vat
      }
    })
    return price
  }

  const textSavePrice = () => {
    if (_.isNil(mainPackageSku) || _.isNil(subscriptionPackageList)) {
      return null
    }
    const { items } = subscriptionPackageList
    let mainPackageData = null
    items.forEach((item) => {
      if (item.sku === mainPackageSku) {
        mainPackageData = item
      }
    })
    // console.log('mainPackageData => ', mainPackageData)
    const packageName = util.getSubscriptionTypeFullNameBySubType(mainPackageData.item_subtype)
    const price = getPackagePrice(selectedMonth)
    const oneMonthPrice = getPackagePrice(1)
    // const selected = selectedMonth === selectedMonth
    // const averagePrice = price / selectedMonth
    const savePrice = oneMonthPrice * selectedMonth - price
    let text = `แพ็ก ${packageName} ${selectedMonth} เดือน`
    if (selectedMonth === 1) {
      text = 'ประหยัดมากขึ้นเมื่อเลือกเปิดรอบบิลที่มากขึ้น'
    }
    return (
      <VStack alignItems='center' justifyContent='center'>
        <XText textAlign='center' color={COLORS.TEXT_ACTIVE}>
          {text}
        </XText>
        {selectedMonth === 1 ? null : (
          <XText textAlign='center' color={COLORS.GREEN} bold>
            {`ประหยัด ${xFmt.formatCurrency(savePrice)} บาท`}
          </XText>
        )}
      </VStack>
    )
  }

  const renderSelectedBillingCycle = (month: number) => {
    const price = getPackagePrice(month)
    const oneMonthPrice = getPackagePrice(1)
    const selected = selectedMonth === month
    const averagePrice = price / month
    const savePrice = oneMonthPrice * month - price
    return (
      <HStack w='full' h='100px'>
        <TouchableOpacity
          key={`renderSelectedBillingCycle_${month}`}
          onPress={() => {
            setSelectedMonth(month)
          }}
          style={{
            flex: 1,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 8,
            paddingBottom: 8,
            alignItems: 'flex-start',
            justifyContent: 'center',
            borderWidth: selected ? 2 : 1,
            borderColor: selected ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
            borderRadius: 7,
            // height: 100,
            backgroundColor: selected ? COLORS.APP_SECONDARY : COLORS.WHITE,
          }}>
          <HStack alignItems='center' justifyContent='center' w='full' pr='2'>
            {/* <HStack w='10'>{selected ? <XIcon name='check' family='AntDesign' color={COLORS.APP_MAIN} /> : null}</HStack> */}
            <HStack flex={1} h='100px'>
              <VStack flex={1} h='full'>
                <HStack flex={1} mt={month === 1 ? '0' : '2'}>
                  <HStack w='32' alignItems='center'>
                    <HStack w='10'>{selected ? <XIcon name='check' family='AntDesign' color={COLORS.APP_MAIN} pl='2' /> : null}</HStack>
                    <XText fontSize='2xl'>{`${month} เดือน`}</XText>
                  </HStack>
                  <HStack flex={1} alignItems='center' justifyContent='flex-end'>
                    <XText fontSize='2xl'>{`${xFmt.formatCurrency(price)} บาท`}</XText>
                  </HStack>
                </HStack>
                {month === 1 ? null : (
                  <HStack flex={1} justifyContent='flex-end' mt='2'>
                    <XText variant='inactive'>{`${xFmt.formatCurrency(averagePrice)}/เดือน`}</XText>
                    <XText color={COLORS.GREEN} bold>{` (ประหยัด ${xFmt.formatCurrency(savePrice)})`}</XText>
                  </HStack>
                )}
              </VStack>
            </HStack>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  return (
    <XOverlay
      contentStyle={{ padding: 8, width: 315, alignSelf: 'center', flexBasis: 'auto' }}
      visible={visible}
      // numColumns={1}
      // children={_renderChildren()}
      onRequestClose={() => null}>
      <Box w='full'>
        {/* HREADER */}
        <HStack w='full' h='35px' alignItems='center'>
          <Box flex={1} alignItems='center' justifyContent='center'>
            <XText variant='active' bold>
              เลือกจำนวนเดือน
            </XText>
          </Box>
          {/* <TouchableOpacity
            style={{ width: 30, alignItems: 'center', justifyContent: 'center' }}
            onPress={() => (_.isFunction(onRequestClose) ? onRequestClose() : null)}>
            <XIcon name='close' family='AntDesign' />
          </TouchableOpacity> */}
        </HStack>
        {/* // ประหยัด */}
        {textSavePrice()}
        {/* // SELETED */}
        <VStack space='2' mt='2'>
          {MONTH_PACKAGE.map((month) => renderSelectedBillingCycle(month))}
        </VStack>
        <HStack mt='2' space='2'>
          <TouchableOpacity
            onPress={() => (_.isFunction(onRequestClose) ? onRequestClose() : null)}
            style={{
              width: 60,
              alignItems: 'center',
              justifyContent: 'center',
              height: 40,
              // backgroundColor: COLORS.APP_MAIN,
              borderRadius: 7,
              borderColor: COLORS.APP_MAIN,
              borderWidth: 1,
            }}>
            <XText color={COLORS.APP_MAIN}>ยกเลิก</XText>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (_.isFunction(onSelected)) {
                onSelected(selectedMonth)
              }
              if (_.isFunction(onRequestClose)) {
                onRequestClose()
              }
            }}
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: 40,
              backgroundColor: COLORS.APP_MAIN,
              borderRadius: 7,
            }}>
            <XText color={COLORS.WHITE}>เลือก</XText>
          </TouchableOpacity>
        </HStack>
      </Box>
    </XOverlay>
  )
}

export default ChangeBillingCycle

// const stylesReport = StyleSheet.create({
//   box: {
//     height: 50,
//     borderWidth: 1,
//     borderColor: COLORS.TEXT_ACTIVE,
//   },
// })
