import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  getSelectedStore,
  getSelectedStoreErpChannels,
  getSelectedStoreOrdersDates,
  getSelectedStoreOrdersDatesOptionKey,
} from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import * as api from 'x/utils/api'

import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XInput from 'xui/components/XInput'
import XText from 'xui/components/XText'
import XColorPalette from 'xui/components/XColorPalette'
import { diff } from 'deep-object-diff'
import _ from 'lodash'
import NavHeaderButton from 'xui/components/NavHeaderButton'
import Box from 'xui/components/Box'
import { IOrderListFilterEditorViewProps, IOrderListFilterEditorViewState, IOrderListFilterPreference } from 'x/index'
import dayjs from 'dayjs'
import XIconButton from 'xui/components/XIconButton'
import { OrderListFilterView } from './OrderListFilterView'

const FILTER_COLORS = {
  black: '#000000',
  blue: '#0000ff',
  brown: '#a52a2a',
  darkblue: '#00008b',
  darkgreen: '#006400',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darkviolet: '#9400d3',
  green: '#008000',
  indigo: '#4b0082',
  maroon: '#800000',
  navy: '#000080',
  purple: '#800080',
  violet: '#800080',
}

class OrderListFilterEditorView extends OrderListFilterView<IOrderListFilterEditorViewProps, IOrderListFilterEditorViewState> {
  static displayName = 'OrderListFilterEditorView'

  // override
  resetSelectedTemplate = async () => {
    await util.setStatePromise(this, { selectedFilterTemplate: null })
  }

  _onEditingTemplateColorChange = (newColor: string) => {
    const { currentSetting } = this.state
    this.updateNewSetting({ ...currentSetting, color: newColor })
  }

  _onEditingTemplateNameChange = (newText: string) => {
    const { currentSetting } = this.state
    this.updateNewSetting({ ...currentSetting, name: newText })
  }

  _renderOrderTemplateBar = () => {
    const { currentSetting } = this.state

    return (
      <VStack w='full' p='1' space='2' bg='white'>
        {/* <VStack w='full' space='0.5'>
          <XText variant='inactive'>ID</XText>
          <XText>{currentSetting.id || ''}</XText>
        </VStack> */}

        <VStack w='full' space='0.5'>
          <XText>ชื่อเทมเพลต</XText>
          <XInput
            w='full'
            h='9'
            value={currentSetting.name || ''}
            onChangeText={this._onEditingTemplateNameChange}
            placeholder='เช่น กรองออเดอร์ที่มี COD'
          />
        </VStack>

        <VStack w='full' space='0.5'>
          <XText variant='inactive'>สีเทมเพลต</XText>
          <XColorPalette value={currentSetting.color || ''} onChange={this._onEditingTemplateColorChange} />
        </VStack>

        {/* <VStack w='full' space='0.5'>
          <XText variant='inactive'>updatedAt</XText>
          <XText>{currentSetting.updatedAt || ''}</XText>
        </VStack> */}

        {/* <VStack w='full' space='0.5'>
          <XText variant='inactive'>createdAt</XText>
          <XText>{currentSetting.createdAt || ''}</XText>
        </VStack> */}
      </VStack>
    )
  }

  _renderContent = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    return (
      <XContent>
        <VStack w='full' p='0.5' space='1' bg='white'>
          {this._renderOrderTemplateBar()}

          {this._renderOrderListFilter()}
        </VStack>
      </XContent>
    )
  }

  _onCancel = async () => {
    if (this.inProcess) {
      return
    }

    this.inProcess = true
    const { currentSetting, appliedSetting } = this.state

    const diffSetting = diff(currentSetting, appliedSetting)
    const isDirty = !_.isEmpty(diffSetting)

    if (isDirty) {
      const isUserConfirm = await p.op.isUserConfirm(
        'ยกเลิกการแก้ไข',
        `กรุณายืนยันว่าฉันต้องการยกเลิกการเปลี่ยนแปลงทั้งหมดที่ทำไว้`,
        'ยืนยัน',
        'กลับไปแก้ไข'
      )

      if (!isUserConfirm) {
        this.inProcess = false
        return
      }
    }

    if (!this._isAlreadyExist()) {
      this._goBack()
      this.inProcess = false
    }

    await this._onResetFilter()

    this.inProcess = false
  }

  _getPref = (): IOrderListFilterPreference => util.getOrderListFilterPreferance()

  _isAlreadyExist = () => {
    const { currentSetting } = this.state
    const { id } = currentSetting
    const pref = this._getPref()
    const foundIndex = pref.templates.findIndex((t) => t.id === id)
    return foundIndex > -1
  }

  _onDelete = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { currentSetting } = this.state
    const pref = this._getPref()
    const editingPref = _.cloneDeep(pref)
    const foundIndex = editingPref.templates.findIndex((t) => t.id === currentSetting.id)

    if (foundIndex < 0) {
      this.inProcess = false
      return
    }

    const isUserConfirm = await p.op.isUserConfirm(
      'ลบเทมเพลตตัวกรองออเดอร์',
      `กรุณายืนยันว่าฉันต้องการลบเทมเพลต "${editingPref.templates[foundIndex].name}"`,
      'ลบ',
      'ยกเลิก'
    )

    if (!isUserConfirm) {
      this.inProcess = false
      return
    }

    await util.setStatePromise(this, { isSubmitting: true })

    editingPref.templates.splice(foundIndex, 1)

    const store_id = util.getNavParam(this.props, 'store_id')
    const onSaveSuccess = util.getNavParam(this.props, 'onSaveSuccess')
    const isPreventGoBackAfterSaveSuccess = util.getNavParam(this.props, 'isPreventGoBackAfterSaveSuccess', false)

    // const isSuccess = await api.postStoreUser({ store_id, perf_ui_order_list_filter: editingPref })
    const isSuccess = await api.postStoreUser({ store_id, pref_ui_order_list_filter: editingPref })
    // console.log('_onSavePressed isSuccess => ', isSuccess)

    if (isSuccess) {
      const { updateSelectedStoreByKey } = this.props
      updateSelectedStoreByKey('pref_ui_order_list_filter', editingPref)
      p.op.showToast('ลบทมเพลตตัวกรองออเดอร์เรียบร้อยแล้ว', 'success')

      if (_.isFunction(onSaveSuccess)) {
        await util.delay(100)
        await onSaveSuccess(editingPref)
      }

      if (!isPreventGoBackAfterSaveSuccess) {
        util.navGoBack(this.props)
      }
    } else {
      p.op.showToast('ลบทมเพลตตัวกรองออเดอร์ไม่สําเร็จ', 'danger')
    }

    await util.setStatePromise(this, { isSubmitting: false })
    this.inProcess = false
  }

  _onSave = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { currentSetting } = this.state
    const pref = this._getPref()
    const editingPref = _.cloneDeep(pref)
    const foundIndex = editingPref.templates.findIndex((t) => t.id === currentSetting.id)
    const isAlreadyExist = foundIndex > -1

    let isUserConfirm = await p.op.isUserConfirm(
      'บันทึกเทมเพลตตัวกรองออเดอร์',
      `กรุณายืนยันว่าฉันต้องการ${isAlreadyExist ? 'แก้ไข' : 'บันทึก'}เทมเพลตตัวกรองออเดอร์`,
      'ยืนยัน',
      'กลับไปแก้ไข'
    )

    if (!isUserConfirm) {
      this.inProcess = false
      return
    }

    await util.setStatePromise(this, { isSubmitting: true })

    const savedTemplate = _.cloneDeep(currentSetting)
    savedTemplate.updatedAt = dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString()

    if (!savedTemplate.name || savedTemplate.name === '') {
      savedTemplate.name = savedTemplate.id
    }

    let hasCustomDateRange = false

    if (savedTemplate.autoCancelOrderDateRangeOptionKey === 'Custom') {
      delete savedTemplate.autoCancelOrderDateRangeOptionKey
      hasCustomDateRange = true
    }
    delete savedTemplate.autoCancelOrderFrom
    delete savedTemplate.autoCancelOrderTo

    if (savedTemplate.createOrderDateRangeOptionKey === 'Custom') {
      delete savedTemplate.createOrderDateRangeOptionKey
      hasCustomDateRange = true
    }
    delete savedTemplate.createOrderFrom
    delete savedTemplate.createOrderTo

    if (savedTemplate.dateDeliveryDateRangeOptionKey === 'Custom') {
      delete savedTemplate.dateDeliveryDateRangeOptionKey
      hasCustomDateRange = true
    }
    delete savedTemplate.dateDeliveryFrom
    delete savedTemplate.dateDeliveryTo

    if (savedTemplate.paymentCompletedAtDateRangeOptionKey === 'Custom') {
      delete savedTemplate.paymentCompletedAtDateRangeOptionKey
      hasCustomDateRange = true
    }
    delete savedTemplate.paymentCompletedAtFrom
    delete savedTemplate.paymentCompletedAtTo

    if (savedTemplate.printedAtDateRangeOptionKey === 'Custom') {
      delete savedTemplate.printedAtDateRangeOptionKey
      hasCustomDateRange = true
    }
    delete savedTemplate.printedAtFrom
    delete savedTemplate.printedAtTo

    if (savedTemplate.readyToShipDateRangeOptionKey === 'Custom') {
      delete savedTemplate.readyToShipDateRangeOptionKey
      hasCustomDateRange = true
    }
    delete savedTemplate.readyToShipDateFrom
    delete savedTemplate.readyToShipDateTo

    if (_.isNil(savedTemplate.createOrderDateRangeOptionKey)) {
      const { selectedStoreOrdersDatesOptionKey } = this.props
      savedTemplate.createOrderDateRangeOptionKey = selectedStoreOrdersDatesOptionKey || 'Last30Days'
    }

    if (hasCustomDateRange) {
      isUserConfirm = await p.op.isUserConfirm(
        'มีการตั้งค่าช่วงเวลาแบบกำหนดเอง',
        'กรุณายืนยันการบันทึกเทมเพลตโดยที่การตั้งค่าตัวกรองของช่วงเวลาแบบ "กำหนดเอง" จะไม่ถูกบันทึกไปด้วย',
        'ยืนยัน',
        'กลับไปแก้ไข'
      )

      if (!isUserConfirm) {
        this.inProcess = false
        return
      }
    }

    if (isAlreadyExist) {
      editingPref.templates[foundIndex] = savedTemplate
    } else {
      savedTemplate.createdAt = savedTemplate.updatedAt
      editingPref.templates.push(savedTemplate)
    }

    const store_id = util.getNavParam(this.props, 'store_id')
    const onSaveSuccess = util.getNavParam(this.props, 'onSaveSuccess')
    const isPreventGoBackAfterSaveSuccess = util.getNavParam(this.props, 'isPreventGoBackAfterSaveSuccess', false)

    // const isSuccess = await api.postStoreUser({ store_id, perf_ui_order_list_filter: editingPref })
    const isSuccess = await api.postStoreUser({ store_id, pref_ui_order_list_filter: editingPref })
    // console.log('_onSavePressed isSuccess => ', isSuccess)

    if (isSuccess) {
      const { updateSelectedStoreByKey } = this.props
      updateSelectedStoreByKey('pref_ui_order_list_filter', editingPref)
      p.op.showToast('บันทึกเทมเพลตตัวกรองออเดอร์เรียบร้อยแล้ว', 'success')

      if (_.isFunction(onSaveSuccess)) {
        await util.delay(100)
        await onSaveSuccess(editingPref, savedTemplate)
      }

      if (!isPreventGoBackAfterSaveSuccess) {
        util.navGoBack(this.props)
      }
    } else {
      p.op.showToast('บันทึกเทมเพลตตัวกรองออเดอร์ไม่สําเร็จ', 'danger')
    }

    await util.setStatePromise(this, { isSubmitting: false })
    this.inProcess = false
  }

  _renderHeaderLeft = () => {
    const { currentSetting, appliedSetting } = this.state
    const isAlreadyExist = this._isAlreadyExist()
    const diffSetting = diff(currentSetting, appliedSetting)
    const isDirty = !_.isEmpty(diffSetting) || !isAlreadyExist

    if (isDirty) {
      return <NavHeaderButton label='บันทึก' onPressItem={this._onSave} />
    }

    return <NavHeaderButton backIcon onPressItem={this._goBack} />
  }

  _renderHeaderRight = () => {
    const { currentSetting, appliedSetting } = this.state
    const isAlreadyExist = this._isAlreadyExist()
    const diffSetting = diff(currentSetting, appliedSetting)
    const isDirty = !_.isEmpty(diffSetting) || !isAlreadyExist

    if (isDirty) {
      return <NavHeaderButton label='ยกเลิก' onPressItem={this._onCancel} />
    }

    if (isAlreadyExist) {
      return <XIconButton name='trash' family='Ionicons' colorScheme='danger' onPress={this._onDelete} />
    }

    return <Box w='44px' h='34px' />
  }

  _renderHeader = () => {
    const { headerTitle } = this.state

    return (
      <HStack
        w='full'
        px='2'
        py='1'
        minH='44px'
        alignItems='center'
        justifyContent='center'
        space='1'
        borderBottomWidth='1'
        borderBottomColor='gray.300'>
        {this._renderHeaderLeft()}

        <HStack flex={1} alignItems='center' justifyContent='center'>
          <XText w='full' variant='active' bold textAlign='center'>
            {headerTitle}
          </XText>
        </HStack>

        {this._renderHeaderRight()}
      </HStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        {this._renderContent()}

        {/* <HStack h={p.op.isWeb() ? '12' : undefined} minH='12' w='full' p='2' space='2' borderTopWidth='1' borderTopColor='gray.400'>
          <XButton flex={1} h='9' variant='outline' onPress={this._onApplyFilter}>
            ดูตัวอย่างผลลัพธ์
          </XButton>
        </HStack> */}

        {/* {this._renderFilterTemplateSelectorModal()} */}
        {this._renderFilterTemplateSaveModal()}
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),

    selectedStoreOrdersDates: getSelectedStoreOrdersDates(state),
    selectedStoreOrdersDatesOptionKey: getSelectedStoreOrdersDatesOptionKey(state),
    erpChannels: getSelectedStoreErpChannels(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(OrderListFilterEditorView)
