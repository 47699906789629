import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Platform, StyleSheet, View } from 'react-native'
import HoverableView from './HoverableView'
import { debug } from './logger'
import { makeTouchable } from './helpers'
import { withCtx } from './MenuProvider'
import { MenuOptionProps } from './types'
import XText from '../XText'

export class MenuOption extends Component<MenuOptionProps> {
  static defaultProps = {
    disabled: false,
    disableTouchable: false,
    customStyles: {},
    testID: undefined,
  }

  _onSelect() {
    const { value } = this.props
    const onSelect = this.props.onSelect || this._getMenusOnSelect()
    const shouldClose = onSelect(value) !== false
    debug('select option', value, shouldClose)
    if (shouldClose) {
      // @ts-ignore
      this.props.ctx.menuActions.closeMenu()
    }
  }

  _getMenusOnSelect() {
    // @ts-ignore
    const menu = this.props.ctx.menuActions._getOpenedMenu()
    return menu.instance.props.onSelect
  }

  _getCustomStyles() {
    // FIXME react 16.3 workaround for ControlledExample!
    // @ts-ignore
    const menu = this.props.ctx.menuActions._getOpenedMenu() || {}
    const { optionsCustomStyles } = menu
    return {
      ...optionsCustomStyles,
      ...this.props.customStyles,
    }
  }

  render() {
    const { text, disabled, disableTouchable, children, style, testID } = this.props
    const customStyles = this._getCustomStyles()
    if (text && React.Children.count(children) > 0) {
      console.warn("MenuOption: Please don't use text property together with explicit children. Children are ignored.")
    }
    if (disabled) {
      const disabledStyles = [defaultStyles.optionXTextDisabled, customStyles.optionXText]

      if (Platform.OS === 'ios') {
        return (
          <View style={[defaultStyles.option, customStyles.optionWrapper, style]}>
            {text ? <XText style={disabledStyles}>{text}</XText> : children}
          </View>
        )
      }

      return (
        <HoverableView
          style={[defaultStyles.option, customStyles.optionWrapper, style]}
          hoverStyle={[defaultStyles.option_hover, customStyles.optionWrapper, style]}
          activeStyle={[defaultStyles.option_active, customStyles.optionWrapper, style]}>
          {text ? <XText style={disabledStyles}>{text}</XText> : children}
        </HoverableView>
      )
    }

    const rendered =
      Platform.OS === 'ios' ? (
        <View style={[defaultStyles.option, customStyles.optionWrapper, style]}>
          {text ? <XText style={customStyles.optionXText}>{text}</XText> : children}
        </View>
      ) : (
        <HoverableView
          style={[defaultStyles.option, customStyles.optionWrapper, style]}
          hoverStyle={[defaultStyles.option_hover, customStyles.optionWrapper, style]}
          activeStyle={[defaultStyles.option_active, customStyles.optionWrapper, style]}>
          {text ? <XText>{text}</XText> : children}
        </HoverableView>
      )

    if (disableTouchable) {
      return rendered
    }

    const { Touchable, defaultTouchableProps } = makeTouchable(customStyles.OptionTouchableComponent)
    return (
      <Touchable testID={testID} onPress={() => this._onSelect()} {...defaultTouchableProps} {...customStyles.optionTouchable}>
        {rendered}
      </Touchable>
    )
  }
}

// MenuOption.propTypes = {
//   disabled: PropTypes.bool,
//   disableTouchable: PropTypes.bool,
//   onSelect: PropTypes.func,
//   text: PropTypes.string,
//   value: PropTypes.any,
//   customStyles: PropTypes.object,
//   testID: PropTypes.string,
// }

// MenuOption.defaultProps = {
//   disabled: false,
//   disableTouchable: false,
//   customStyles: {},
//   testID: undefined,
// }

const defaultStyles = StyleSheet.create({
  option: {
    padding: 5,
    backgroundColor: 'transparent',
  },
  option_hover: {
    padding: 5,
    backgroundColor: 'transparent',
    transition: 'all 100ms ease',
    transform: [{ scale: 1.05 }],
    boxShadow: '0 0 2px rgba(0, 0, 0, 0.2)',
  },
  option_active: {
    padding: 5,
    backgroundColor: 'transparent',
    transition: 'all 50ms ease',
    transform: [{ scale: 1.03 }],
    // background: '#e5432d',
  },
  optionXTextDisabled: {
    color: '#ccc',
  },
})

export default withCtx(MenuOption) as React.FC<MenuOptionProps>
