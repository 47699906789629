import React from 'react'

import { IDashboardViewProps } from 'x/types'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import * as acl from 'x/utils/acl'
import CONS from 'x/config/constants'

import VStack from 'xui/components/VStack'

import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import DailySaleBox from 'xui/components/dashboard/DailySaleBox'
import OrderListFilterTemplateDashboardItems from 'xui/components/orders/OrderListFilterTemplateDashboardItems'
import ProductVariantListFilterTemplateDashboardItems from 'xui/components/product/ProductVariantListFilterTemplateDashboardItems'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'

interface IState {
  // dailySalesAmount: string
  // dailySalesQty: string
  // dailySalesOrder: string
  // dailySalesProfit: string
}

export default class DashboardView extends React.Component<IDashboardViewProps, IState> {
  static displayName = 'DashboardView'

  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     dailySalesAmount: '0',
  //     dailySalesQty: '0',
  //     dailySalesOrder: '0',
  //     dailySalesProfit: '0',
  //   }
  // }

  // async componentDidMount(): Promise<void> {
  //   await this._iniData()
  // }

  // _iniData = async () => {
  //   try {
  //     const ds = await this._fetchTodayDailySales()
  //     if (ds.length === 1) {
  //       await util.setStatePromise(this, {
  //         dailySalesAmount: Math.round(parseFloat(ds[0].amount)).toString(),
  //         dailySalesProfit: Math.round(parseFloat(ds[0].profit)).toString(),
  //         dailySalesOrder: ds[0].order,
  //         dailySalesQty: ds[0].qty,
  //       })
  //     }
  //   } catch (err) {
  //     console.log('DashboardView componentDidMount err => ', err)
  //   }
  // }

  // _fetchTodayDailySales = async () => {
  //   const store_id = util.getNavParam(this.props, 'store_id')
  //   const defaultCreatedDateRangeKey = CONS.DATERANGE_SELECTOR.OPTION.Today
  //   const dr = util.getDateRangeFromOption(defaultCreatedDateRangeKey)
  //   const orderFilterSetting = {
  //     createOrderDateRangeOptionKey: defaultCreatedDateRangeKey,
  //     createOrderFrom: dr.begin,
  //     createOrderTo: dr.end,
  //   }

  //   try {
  //     const translatedOrderFilter = util.translateOrdersFilterToRequestBody(orderFilterSetting)
  //     console.log('_fetchTodayDailySales translatedOrderFilter => ', translatedOrderFilter)

  //     const data = await api.fetchDailySalesData({ store_id, ...translatedOrderFilter })
  //     console.log('_fetchTodayDailySales data => ', data)
  //     return data
  //   } catch (err) {
  //     console.log('_fetchTodayDailySales err => ', err)
  //   }
  // }

  // _renderBox = (label: string, value: string, valueColor: string) => (
  //   <VStack flex={1} alignItems='center' justifyContent='space-between'>
  //     <XText fontSize='md' color={valueColor || 'black'} bold>
  //       {value}
  //     </XText>

  //     <XText fontSize='sm' textAlign='center'>
  //       {label}
  //     </XText>
  //   </VStack>
  // )

  _navToDailySaleChartView = () => {
    const { navigation } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    navigation.dispatch(NavActions.navToDailySaleChartView({ store_id }))
  }

  // _renderDailySalesSummary = () => {
  //   const { dailySalesAmount, dailySalesOrder, dailySalesQty, dailySalesProfit } = this.state
  //   return (
  //     <TouchableOpacity onPress={this._navToDailySaleChartView}>
  //       <VStack w='full' space='1'>
  //         <HStack w='full' h='9' alignItems='center' justifyContent='center'>
  //           <Box w='38px' />
  //           <HStack flex={1} alignItems='center' justifyContent='center'>
  //             <XText bold textAlignVertical='center' textAlign='center'>
  //               ยอดขายวันนี้
  //             </XText>
  //           </HStack>
  //           <Box w='38px' pb='1.5' alignItems='center' justifyContent='center'>
  //             <ForwardIcon />
  //           </Box>
  //         </HStack>
  //         <HStack w='full' space='0.5'>
  //           {this._renderBox('ยอดขาย', xFmt.formatCurrency(dailySalesAmount), 'blue.500')}
  //           <Box borderLeftWidth='1' borderLeftColor='gray.200' />
  //           {this._renderBox('กำไร', xFmt.formatCurrency(dailySalesProfit), 'success.500')}
  //         </HStack>
  //         <HStack w='full' space='0.5' px='2'>
  //           <Box w='full' borderBottomWidth='1' borderBottomColor='gray.200' />
  //         </HStack>
  //         <HStack w='full' space='0.5'>
  //           {this._renderBox('จำนวนออเดอร์', dailySalesOrder, 'primary.500')}
  //           <Box borderLeftWidth='1' borderLeftColor='gray.200' />
  //           {this._renderBox('จำนวนสินค้า', dailySalesQty, 'yellow.700')}
  //         </HStack>
  //       </VStack>
  //     </TouchableOpacity>
  //   )
  // }

  renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)

    return (
      <XCustomHeader
        title='แดชบอร์ด'
        headerLeftProps={isGoBackButtonHidden ? undefined : { backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
      />
    )
  }

  _hasDailySalesPerm = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.DASHBOARD)

  _hasOrderListPerm = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_LIST)

  _hasProductListPerm = () => acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_LIST)

  _hasNoPermissions = () => {
    const hasDailySalesPerm = this._hasDailySalesPerm()
    const hasOrderListPerm = this._hasOrderListPerm()

    return !hasDailySalesPerm && !hasOrderListPerm
  }

  _renderDailySalesSummary = () => {
    if (!this._hasDailySalesPerm()) {
      return null
    }

    const store_id = util.getNavParam(this.props, 'store_id')
    return <DailySaleBox storeId={store_id} onPress={this._navToDailySaleChartView} />
  }

  _renderOrderListFilterTemplateDashboardItems = () => {
    if (!this._hasOrderListPerm()) {
      return null
    }

    const { navigation } = this.props
    return <OrderListFilterTemplateDashboardItems navigation={navigation} />
  }

  _renderProductVariantListFilterTemplateDashboardItems = () => {
    if (!this._hasProductListPerm()) {
      return null
    }

    const { navigation } = this.props
    return <ProductVariantListFilterTemplateDashboardItems navigation={navigation} />
  }

  renderNoPermissionsWarning = () => (
    <HStack w='full' pt='8' px='2' justifyContent='center'>
      <XText variant='inactive' textAlign='center'>
        คุณไม่มีสิทธิ์ในการเข้าถึงแดชบอร์ด
      </XText>
    </HStack>
  )

  renderContent = () => {
    if (this._hasNoPermissions()) {
      return this.renderNoPermissionsWarning()
    }

    return (
      <VStack w='full' p='1' space='2'>
        {/* <XCard>
              <VStack w='full' p='2'>
                {this._renderDailySalesSummary()}
              </VStack>
            </XCard> */}
        {this._renderDailySalesSummary()}
        {this._renderOrderListFilterTemplateDashboardItems()}
        {this._renderProductVariantListFilterTemplateDashboardItems()}
      </VStack>
    )
  }

  renderMain = () => (
    <XContainer>
      {this.renderCustomHeader()}
      <XContent>{this.renderContent()}</XContent>
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}
