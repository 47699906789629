import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getState, getDispatch } from 'x/modules/orderlist/StoreOrderSingleTabListViewConnect'

import {
  BackgroundJobTypeType,
  IBackgroundJobListResponse,
  IFetchOrdersRequestBody,
  IMKPChannelDetail,
  IOnSubmitOverlayEditTime,
  IOrderListItem,
  IOrderListViewProps,
  IOrderListViewState,
} from 'x/index'
import VStack from 'xui/components/VStack'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'

import api from 'x/utils/api'
import Segment from 'xui/components/Segment'
import XText from 'xui/components/XText'
import XIconButton from 'xui/components/XIconButton'
import BaseUIOrderListView from './BaseUIOrderListView'

interface IMkpConfirmOrderListViewState extends IOrderListViewState {
  isShowSetTimeOverlay?: boolean // use only overlay timeslot
  isShowSetAddressOverlay?: boolean // use only overlay timeslot
  textHeaderOverlay?: string // use only overlay timeslot
  responsFromOverlay?: IOnSubmitOverlayEditTime // use only overlay timeslot
  optionChooseShippingMKPTypeFromIndex?: number // use only overlay timeslot
}

class MkpConfirmOrderListView extends BaseUIOrderListView<IOrderListViewProps, IMkpConfirmOrderListViewState> {
  static displayName = 'MkpConfirmOrderListView'

  constructor(props) {
    super(props)
    this.state = {
      ...this.getBaseInitialState(),
      isShowSetTimeOverlay: false,
      isShowSetAddressOverlay: false,
      textHeaderOverlay: '',
      optionChooseShippingMKPTypeFromIndex: 0,
    }
  }

  onPressOrderListItem = (od: IOrderListItem) => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToOrderDetail({
        order_id: od.id,
        store_id: this.getStoreId(),
      })
    )
    // navigation.dispatch(
    //   NavActions.navToOrderListView({
    //     // order_id: od.id,
    //     store_id: this.getStoreId(),
    //     onPressOrderListItem: this._mockOnPressOrderListItem,
    //     onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
    //   })
    // )
    // this.toggleCheckOrderListItem(od.id)
  }

  getOverridePreSetupInitialState = (): Partial<IOrderListViewState> => ({
    isCheckable: true,
    availableTabKeys: ['myTasks_confirmOrder'],
    // ordersFilterSetting: {
    //   order_segment: 'my_unshipped_only_and_not_mkp',
    // },
    displayMode: 'page',
    displayPageLimit: 30,
  })

  getOverrideRequestBody = (): Partial<IFetchOrdersRequestBody> => ({
    // order_segment: 'my_unshipped_only_and_not_mkp',
    order_origin: 'mkp_only',
  })

  getHeaderTitle = () => {
    const defaultTitle = this.getTabLabelWithCount(this.state.tabName)
    return `ยืนยันออเดอร์ที่มาจากช่องทางขาย\n${defaultTitle}`
  }

  getSubmitCheckOrdersButtonText = () => {
    const checkedCount = this.getCheckedOrderCount()
    return checkedCount > 0 ? 'ยืนยันทุกออเดอร์' : 'กรุณาเลือกออเดอร์ที่ต้องการยืนยัน\nโดยแตะปุ่มกลมทางซ้าย'
  }

  isSubmitCheckOrdersButtonDisabled = () => {
    const checkedOrders = this.getCheckedOrderIds()
    const checkedCount = checkedOrders.length
    return checkedCount === 0
  }

  onPressSubmitCheckedOrders = async (checkedOrders: IOrderListItem[]) => {
    this._handlePressMultipleConfirmMkpOrder(checkedOrders)
  }

  _quitMkpConfirmOrderListView = () => {
    this.goBack()
  }

  _navToBackgroundJobInProcessByTypeView = (params: {
    bgJobType: BackgroundJobTypeType
    initBgJobResponse: IBackgroundJobListResponse
    warningText?: string
    warningTextBgColor?: string
  }) => {
    const { navigation } = this.props
    const selectedStoreId = this.getStoreId()
    navigation.dispatch(
      NavActions.navToBackgroundJobInProcessByTypeView({
        storeId: selectedStoreId,
        // bgJobType: CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE,
        // initBgJobResponse,
        // warningText: '',
        ...params,
      })
    )
  }

  isOrderIdsCheckedSingleUgId = () => {
    const { tabName } = this.state
    if (!tabName) {
      return false
    }
    const checkedOrderCount = this.getCheckedOrderCount()
    if (checkedOrderCount < 1) {
      return false
    }
    const checkedOrders = this.getCheckedOrders()

    // const orders = this.getOrders(tabName)

    const ugIdsList = checkedOrders.map((checkedOrder) => {
      // const foundOrder = orders.find((o) => o.id === orderId)
      // console.log('foundOrder.toJS() => ', foundOrder.toJS())
      if (checkedOrder && checkedOrder.ug) {
        // ug_id
        return checkedOrder.ug
      }
      return null
    })

    // console.log('checkOrderIds => ', checkOrderIds)
    // console.log('ugIdsList => ', ugIdsList)
    const firstUgId = ugIdsList[0]
    for (let i = 1; i < ugIdsList.length; i++) {
      const nextUgId = ugIdsList[i]
      // ถ้าไม่ใช่ตัวเลข (null) หรือ ตัวแรกเทียบกับตัวถัดไปแล้วไม่ใช่เลขเดียวกัน
      // ให้ return false ออกไปทันที
      if (!nextUgId || firstUgId !== nextUgId) {
        return false
      }
    }
    // ถ้าจบ loop แล้วยังไม่พบเลขที่แตกต่างแสดงว่าเป็น ug_id (mkp) เดียวกันหมด
    return true
  }

  _getMKPChannelDetail = (): IMKPChannelDetail => {
    const orderCheckedCount = this.getCheckedOrderCount()
    if (orderCheckedCount > 0) {
      const isOrderIdsCheckedSingleUgId = this.isOrderIdsCheckedSingleUgId()
      if (isOrderIdsCheckedSingleUgId) {
        const checkedOrders = this.getCheckedOrders()
        // const foundOrder = orders.find((o) => o.ug)
        // return util.getMKPChannelByUGID(foundOrder.ug)
        const firstOrder = checkedOrders[0]
        return util.getMKPChannelByUGID(firstOrder.ug)
      }
    }
    return null
  }

  _getShippingTypeIdIfOrdersHaveOnlyOneShippingType = (): number => {
    const orderCheckedCount = this.getCheckedOrderCount()
    if (orderCheckedCount > 0) {
      const isOrderIdsCheckedSingleUgId = this.isOrderIdsCheckedSingleUgId()
      if (isOrderIdsCheckedSingleUgId) {
        const checkOrderIds = this.getCheckedOrderIds()
        const shippingTypeIdList = checkOrderIds.map((orderId) => {
          // const foundOrder = checkedOrders.find((o) => o.id === orderId)
          // const { ordersCheckedMap = {} } = this.state
          // const foundOrder = ordersCheckedMap[orderId]
          const foundOrder = this.getCheckedOrder(orderId) || this.findOrderById(orderId)
          // console.log('foundOrder.toJS() => ', foundOrder.toJS())
          if (foundOrder && _.isNumber(foundOrder.y)) {
            // if (foundOrder) {
            // ug_id
            return foundOrder.y
          }
        })
        const firstshippingType = shippingTypeIdList[0]
        for (let i = 0; i < shippingTypeIdList.length; i++) {
          if (shippingTypeIdList[i] !== firstshippingType) {
            return null
          }
        }
        return firstshippingType
      }
    } else {
      return null
    }
  }

  _onSegmentChangeShippingMKPType = (index: number) => {
    // if (index === 1) {
    //   p.op.showConfirmationOkOnly(
    //     'กรุณาติดต่อทีมงาน',
    //     'หากต้องการใช้ "Dropoff" กรุณาติดต่อ ทีมงานที่ LINE: @XSelly หรือ โทร 097-167-1650'
    //   )
    //   return
    // } else
    if (index === 2) {
      p.op.showConfirmationOkOnly(
        'กรุณาติดต่อทีมงาน',
        'หากต้องการใช้รูปแบบขนส่ง "อื่นๆ" กรุณาติดต่อ ทีมงานที่ LINE: @XSelly หรือ โทร 097-167-1650'
      )
      return
    }
    util.setStatePromise(this as any, {
      optionChooseShippingMKPTypeFromIndex: index,
    })
  }

  showActionSheetChangeTimeAndAddressActions = async (onPressOpenOverlay: () => void) => {
    const orderCheckedCount = this.getCheckedOrderCount()
    if (orderCheckedCount < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกออเดอร์ก่อน แล้วทำรายการนี้อีกครั้ง')
      return
    }
    const CHANGE_TIME_AND_ADDRESS_ACTIONS = {
      TITLE: 'เลือกเปลี่ยนข้อมูลในการเข้ารับพัสดุ...',
      BUTTONS: ['เปลี่ยนเวลาเข้ารับ', 'เปลี่ยนที่อยู่เข้ารับ', 'เปลี่ยนเวลาและที่อยู่เข้ารับ', 'ยกเลิก'],
      ACTION_INDEXES: {
        CHANGE_TIME: 0,
        CHANGE_ADDRESS: 1,
        CHANGE_TIME_AND_ADDRESS: 2,
        CANCEL: 3,
      },
    }

    // const channel = this._getMKPChannelDetail()
    // const shippingTypeId = this._getShippingTypeIdIfOrdersHaveOnlyOneShippingType()

    const index: number = await new Promise((resolveSave) => {
      p.op.showActionSheet(
        {
          options: CHANGE_TIME_AND_ADDRESS_ACTIONS.BUTTONS,
          cancelButtonIndex: CHANGE_TIME_AND_ADDRESS_ACTIONS.ACTION_INDEXES.CANCEL,
          // destructiveButtonIndex: null,
          title: CHANGE_TIME_AND_ADDRESS_ACTIONS.TITLE,
        },
        async (buttonIndex: string | number) => {
          await util.delay(200)
          const btnIdx = _.isString(buttonIndex) ? parseInt(buttonIndex, 10) : buttonIndex
          // console.log('buttonIndex => ', buttonIndex.toString())
          resolveSave(btnIdx)
        }
      )
    })
    // console.log('response => ', index)
    const isOrderIdsCheckedSingleUgId = this.isOrderIdsCheckedSingleUgId()
    if (index === 0) {
      await util.setStatePromise(this as any, {
        isShowSetTimeOverlay: true,
        isShowSetAddressOverlay: false,
        textHeaderOverlay: 'เปลี่ยนเวลาเข้ารับ',
      })
      onPressOpenOverlay()
    } else if (index === 1) {
      if (!isOrderIdsCheckedSingleUgId) {
        p.op.showConfirmationOkOnly('', 'ออเดอร์ทั้งหมดที่ถูกเลือก ช่องทางขายไม่ตรงกัน ไม่สามารถระบุที่อยู่จัดส่งได้')
        return
      }
      await util.setStatePromise(this as any, {
        isShowSetTimeOverlay: false,
        isShowSetAddressOverlay: true,
        textHeaderOverlay: 'เปลี่ยนที่อยู่เข้ารับ',
      })
      onPressOpenOverlay()
    } else if (index === 2) {
      if (!isOrderIdsCheckedSingleUgId) {
        p.op.showConfirmationOkOnly('', 'ออเดอร์ทั้งหมดที่ถูกเลือก ช่องทางขายไม่ตรงกัน ไม่สามารถระบุที่อยู่จัดส่งได้')
        return
      }
      await util.setStatePromise(this as any, {
        isShowSetTimeOverlay: true,
        isShowSetAddressOverlay: true,
        textHeaderOverlay: 'เปลี่ยนเวลาและที่อยู่เข้ารับ',
      })
      onPressOpenOverlay()
    }
  }

  _renderIconTimer = (onPressOpenOverlay: () => void) => (
    <XIconButton
      name='calendar-edit'
      family='MaterialCommunityIcons'
      variant='outline'
      onPress={() => this.showActionSheetChangeTimeAndAddressActions(onPressOpenOverlay)}
    />
  )

  onSubmitOverlayEditTimeSlot = async (data: IOnSubmitOverlayEditTime) => {
    // console.log('data 00 => ', data)
    const isConfirmOrder = await new Promise((resolve) => {
      p.op.showConfirmation(
        'ยืนยันออเดอร์ทั้งหมด',
        'หากกดตกลงระบบจะยืนยันออเดอร์ทั้งหมดที่คุณเลือกไว้',
        () => resolve(true),
        () => resolve(false),
        'ตกลง',
        'ยกเลิก'
      )
    })
    if (!isConfirmOrder) {
      await util.setStatePromise(this as any, {
        isShowSetTimeOverlay: false,
        isShowSetAddressOverlay: false,
        textHeaderOverlay: '',
        responsFromOverlay: null,
      })
      return
    }
    await util.setStatePromise(this as any, {
      responsFromOverlay: data,
    })

    await this._onPressSubmitCheckedOrders()
    // this._handlePressMultipleConfirmMkpOrder()
  }

  _handlePressMultipleConfirmMkpOrder = async (checkedOrders: IOrderListItem[]) => {
    const { selectedStore } = this.props
    const { tabName, responsFromOverlay, optionChooseShippingMKPTypeFromIndex, isShowSetAddressOverlay, isShowSetTimeOverlay } = this.state
    // const responsFromOverlay = this.state.responsFromOverlay
    // const order_ids = this.getCheckedOrderIds()
    const order_ids = checkedOrders.map((od) => od.id)
    // console.log('responsFromOverlay => 01', this.state.responsFromOverlay)
    // console.log('order_ids => ', order_ids)
    // p.op.alert('submit order_ids', `${JSON.stringify(order_ids)}`)
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      order_ids,
    }

    // @ts-ignore
    body.shipping_method =
      optionChooseShippingMKPTypeFromIndex === 0 ? 'PICKUP' : optionChooseShippingMKPTypeFromIndex === 1 ? 'DROPOFF' : 'OTHER'

    if (!_.isNil(responsFromOverlay) && isShowSetTimeOverlay) {
      body.pickup = {
        timeslot_pref: {
          start_time: util.getTimeFormathhmm(new Date(responsFromOverlay.time.start)),
          end_time: util.getTimeFormathhmm(new Date(responsFromOverlay.time.end)),
        },
      }
    }
    if (!_.isNil(responsFromOverlay) && isShowSetAddressOverlay) {
      if (_.isNil(body.pickup)) {
        body.pickup = {
          pickup_addr_id: responsFromOverlay.addressId,
        }
      } else {
        body.pickup.pickup_addr_id = responsFromOverlay.addressId
      }
    }
    const response: { status: 'ok' } = await api.post(api.POST_COMFIRM_ORDERS_MKP, body, { isApiV2: true })
    // console.log('response => ', response)
    if (!_.isNil(response) && !_.isNil(response.status) && response.status === 'ok') {
      // this.props.orderListStateActions.setIsInitedByTabKey(tabName, false)
      // this._onPressUncheckAll()
      this.uncheckCheckAllOrderListItems()
      await util.setStatePromise(this as any, {
        isShowSetTimeOverlay: false,
        isShowSetAddressOverlay: false,
        textHeaderOverlay: '',
        responsFromOverlay: null,
      })
      this.loadInitOrders(tabName)

      p.op.showToast('ยืนยันออเดอร์สำเร็จ', 'success')
      // this._fetchInitOrders()
    }
  }

  _renderMkpDropOffHeader = () => {
    // const isHeaderAvailable = this.isConfirmMkpOrdersMode()
    // if (!isHeaderAvailable) {
    //   return null
    // }

    // // let isAddressPickerVisible = false
    // // let isTimePickerVisible = false
    // // const orderCheckedCount = this._getCheckedOrderCount()
    // // if (orderCheckedCount > 0) {
    // //   // TODO: O -> Artid :: ตรงนี้บอกว่าตอนนี้ออเดอร์ที่ถูกเลือกอยู่ มาจาก ug_id เลขเดียวกันหรือไม่
    // //   // ถ้ากรณีไม่เลือก และไม่ใช่เลขเดียวกัน จะเป็น false ทั้งคู่
    // //   const isOrderIdsCheckedSingleUgId = this.isOrderIdsCheckedSingleUgId()
    // //   console.log('isOrderIdsCheckedSingleUgId => ', isOrderIdsCheckedSingleUgId)
    // //   if (isOrderIdsCheckedSingleUgId) {
    // //     isAddressPickerVisible = true
    // //     isTimePickerVisible = true
    // //   } else {
    // //     isTimePickerVisible = true
    // //   }
    // // }

    // ----- OVERLAY ------
    const channel = this._getMKPChannelDetail()
    // console.log('channel 00 => ', channel)
    const shippingTypeId = this._getShippingTypeIdIfOrdersHaveOnlyOneShippingType()
    // console.log('shippingTypeId 00 => ', shippingTypeId)
    const OPTIONS_CHOOSE_SHIPPING_MKP_TYPE = ['นัดรับ', 'Dropoff']
    // ----- OVERLAY ------
    // const sHeight = 65
    const { optionChooseShippingMKPTypeFromIndex = 0 } = this.state
    // if (optionChooseShippingMKPTypeFromIndex !== 0) {
    //   sHeight -= 35
    // }
    return (
      <VStack w='full' px='2' py='1'>
        <XText variant='inactive'>รูปแบบจัดส่ง</XText>
        <Segment
          options={OPTIONS_CHOOSE_SHIPPING_MKP_TYPE}
          selectedIndex={optionChooseShippingMKPTypeFromIndex}
          onSegmentChange={this._onSegmentChangeShippingMKPType}
        />
        {/* {optionChooseShippingMKPTypeFromIndex === 0 ? (
          <HStack w='full' mt='2' alignItems='center' justifyContent='space-between'>
            <XText variant='inactive'>เปลี่ยนเวลา/ที่อยู่เข้ารับ</XText>
            <OverlayEditTimeSlot
              renderButton={(onPressOpenOverlay: () => void) => this._renderIconTimer(onPressOpenOverlay)}
              channel={channel}
              shippingTypeId={shippingTypeId}
              isSingleMkpChannel
              onSubmit={(data: IOnSubmitOverlayEditTime) => this.onSubmitOverlayEditTimeSlot(data)}
              headertitle={this.state.textHeaderOverlay}
              footerText='ยืนยัน'
              showSectionSetTime={this.state.isShowSetTimeOverlay}
              showsectionSetAddress={this.state.isShowSetAddressOverlay}
              isWarningOverlayClose
            />
          </HStack>
        ) : null} */}
      </VStack>
    )
  }

  renderExtraHeader = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }
    return (
      <VStack w='full' p='2'>
        {this._renderMkpDropOffHeader()}
      </VStack>
    )
  }
}

// export default OrderListView

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(MkpConfirmOrderListView)
