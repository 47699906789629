import React, { Component } from 'react'
import { TouchableOpacity } from 'react-native'
import dayjs from 'dayjs'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getSelectedStore } from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'

import {
  IProductListFilterPreference,
  IProductListFilterTemplate,
  IProductVariantListFilterTemplateDashboardItem,
  IProductVariantListFilterTemplateDashboardItemsProps,
  IProductVariantListFilterTemplateDashboardItemsState,
} from 'x/index'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import * as api from 'x/utils/api'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'

import _ from 'lodash'
import HStack from '../HStack'
import VStack from '../VStack'
import XText from '../XText'
import Box from '../Box'
import ProductVariantListFilterTemplateSelector from './ProductVariantListFilterTemplateSelector'
import XIconButton from '../XIconButton'
import XSpinner from '../XSpinner'
import XCard from '../XCard'
import NavHeaderButton from '../NavHeaderButton'
import ForwardIcon from '../ForwardIcon'
import AddIcon from '../AddIcon'

interface IDashboardItem extends IProductVariantListFilterTemplateDashboardItem {
  disabled?: boolean
  isEditable?: boolean
  storeId: number
  template: IProductListFilterTemplate
  productCount?: number
  fetchProductCount: (tmpl: IProductListFilterTemplate) => Promise<void>
  onPress: (tmpl: IProductListFilterTemplate) => void
  onMoveUpPress: (dashboardItemId: string) => void

  isMoveUpAvailable?: boolean
  isMoveDownAvailable?: boolean
  onMoveDownPress: (dashboardItemId: string) => void
  onRemovePress: (dashboardItemId: string) => void
}

const DashboardItem: React.FC<IDashboardItem> = (props) => {
  const {
    id,
    template,
    onPress,
    onRemovePress,
    storeId,
    disabled = false,
    fetchProductCount,
    productCount,
    isEditable = false,
    isMoveDownAvailable,
    isMoveUpAvailable,
    onMoveDownPress,
    onMoveUpPress,
  } = props
  const { name, color } = template

  // const [productCount, setOrderCount] = React.useState<number>(null)
  const [isFetching, setIsFetching] = React.useState<boolean>(false)

  const _fetchProductCount = async () => {
    setIsFetching(true)

    await fetchProductCount(template)

    setIsFetching(false)
  }

  React.useEffect(() => {
    if (_.isNil(productCount)) {
      _fetchProductCount()
    }
  }, [])

  const renderItem = () => (
    <HStack
      w='full'
      alignItems='center'
      // justifyContent='center'
      space='2'
      borderColor={color}
      borderWidth='1'
      p='2'
      borderRadius='lg'
      bg='white'>
      <Box w='20px' h='20px' borderRadius='full' bg={color} />

      <VStack flex={1}>
        <XText w='full' color={color}>
          {name}
        </XText>
      </VStack>

      {!isEditable && (
        <HStack w='38px' alignItems='center' justifyContent='flex-end'>
          {!_.isNil(productCount) && (
            <XText color={color} bold>
              {productCount}
            </XText>
          )}

          {isFetching && <XSpinner size='sm' color={color} />}
        </HStack>
      )}

      {isEditable && (
        <XIconButton
          opacity={isMoveUpAvailable ? 1 : 0}
          variant='ghost'
          bg='gray.100'
          borderRadius='full'
          onPress={() => onMoveUpPress(id)}
          disabled={!isMoveUpAvailable}
          // w='34px'
          // h='34px'
          name='arrow-up'
          family='Ionicons'
        />
      )}

      {isEditable && (
        <XIconButton
          opacity={isMoveDownAvailable ? 1 : 0}
          variant='ghost'
          bg='gray.100'
          borderRadius='full'
          onPress={() => onMoveDownPress(id)}
          disabled={!isMoveDownAvailable}
          // w='34px'
          // h='34px'
          name='arrow-down'
          family='Ionicons'
        />
      )}

      {isEditable && (
        <XIconButton variant='ghost' bg='gray.100' colorScheme='error' borderRadius='full' name='trash' onPress={() => onRemovePress(id)} />
      )}

      {!isEditable && <ForwardIcon />}
      {/* <HStack style={{ position: 'absolute', top: -12, right: -12 }}>
          <XIconButton name='close-circle' onPress={() => onRemovePress(id)} />
        </HStack> */}
    </HStack>
  )

  if (isEditable) {
    return renderItem()
  }

  return (
    <TouchableOpacity disabled={disabled} style={{ width: '100%' }} onPress={() => onPress(template)}>
      {renderItem()}
    </TouchableOpacity>
  )
}

class ProductVariantListFilterTemplateDashboardItems extends Component<
  IProductVariantListFilterTemplateDashboardItemsProps,
  IProductVariantListFilterTemplateDashboardItemsState
> {
  static displayName = 'ProductVariantListFilterTemplateDashboardItems'

  inProcess?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      isSubmitting: false,
      isEditMode: false,
      // productCounts: {},
      // dashboardItems: [],
      editingPref: {
        templates: [],
        dashboardItems: [],
        createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
        updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      },
    }
  }

  async componentDidMount(): Promise<void> {
    await this._initialize()
  }

  _initialize = async () => {
    const pref = this._getPref()
    const { dashboardItems: dis = [] } = pref

    const editingPref = _.cloneDeep(pref)
    let dashboardItems = _.cloneDeep(dis)

    for (let di = 0; di < dis.length; di++) {
      const dashboardItem = dis[di]
      const isMissingTemplateId = this._isMissingTemplateIdOnDashboardItems(dashboardItem)

      if (isMissingTemplateId) {
        dashboardItems = dashboardItems.filter((ditem) => ditem.templateId !== dashboardItem.templateId)
      }
    }

    const isDashboardItemDiff = !_.isEqual(pref.dashboardItems, dashboardItems)

    if (isDashboardItemDiff) {
      const { updateSelectedStoreByKey, selectedStore } = this.props

      editingPref.dashboardItems = dashboardItems
      const store_id = selectedStore.get('id')
      const isSuccess = await api.postStoreUser({ store_id, pref_ui_pp_list_filter: editingPref })

      if (isSuccess) {
        updateSelectedStoreByKey('pref_ui_pp_list_filter', editingPref)
        await util.delay(100)
      }
    }

    await util.setStatePromise(this, { editingPref: pref, isInitialized: true })
  }

  _isMissingTemplateIdOnDashboardItems = (di: IProductVariantListFilterTemplateDashboardItem) => {
    const pref = this._getPref()
    const { templates = [] } = pref
    const foundIndex = templates.findIndex((t) => t.id === di.templateId)
    return foundIndex === -1
  }

  _getPref = (): IProductListFilterPreference => util.getProductListFilterPreferance()

  _fetchProductCount = async (tmpl: IProductListFilterTemplate) => {
    const { selectedStore } = this.props
    const filterReqBody = util.translateProductListFilterToRequestBody(tmpl)

    const isHelper = selectedStore.get('role_id') === 2

    const reqBody: any = {
      store_id: selectedStore.get('id'),
      offset: 0,
      limit: 0,

      ...filterReqBody,
    }

    if (tmpl.searchText) {
      reqBody.queryText = tmpl.searchText
    }

    if (reqBody.offset === 0) {
      // @ts-ignore c คือขอ total count
      reqBody.c = 1
      reqBody.limit = 15
    }

    if (isHelper) {
      // @ts-ignore
      reqBody.is_helper = true
    }

    try {
      const res = await api.searchInventoryProducts(reqBody)

      if (res && _.isNumber(res.count)) {
        // await util.setStatePromise(this, { productCount: res.count })
        // const { productCounts: ppcs } = this.state
        // const productCounts = _.cloneDeep(ppcs)
        // productCounts[tmpl.id] = res.count
        const newState = {}
        newState[`count_${tmpl.id}`] = res.count
        this.setState(newState)
      }
    } catch (err) {
      console.log('fetchProducts err => ', err)
    }
  }

  // _onPrefManageSaveSuccess = async () => {
  //   console.log('ProductListFilterManageView::onSaveSuccess')
  //   const { selectedTemplate } = this.props
  //   const pref = this._getPref()

  //   if (selectedTemplate && pref && _.isArray(pref.templates)) {
  //     const tmplId = selectedTemplate.id
  //     const foundIndex = pref.templates.findIndex((tmpl) => tmpl.id === tmplId)

  //     if (foundIndex < 0) {
  //       const { onSelect } = this.props

  //       if (_.isFunction(onSelect)) {
  //         onSelect(null)
  //       }
  //     }
  //   }
  // }

  _onRemoveDashboardItem = (id: string) => {
    this._removeDashboardItem(id)
  }

  _getProductListFilterTemplateById = (templateId: string) => {
    const pref = this._getPref()
    const { templates } = pref
    const foundTemplate = templates.find((tmpl) => tmpl.id === templateId)
    return foundTemplate
  }

  _onDashboardItemPress = (tmpl: IProductListFilterTemplate) => {
    const { selectedStore } = this.props
    const { navigation } = this.props

    // const usageTmplate = _.cloneDeep(tmpl)
    // if (!usageTmplate.createOrderDateRangeOptionKey) {
    //   usageTmplate.createOrderDateRangeOptionKey = 'Last30Days'
    // }

    // console.log('_onDashboardItemPress tmpl => ', tmpl)

    navigation.dispatch(
      // NavActions.navToSearchOrderListView({
      NavActions.navToSearchProductVariantListView({
        store_id: selectedStore.get('id'),
        // defaultFilter: usageTmplate,
        defaultFilter: tmpl,
        title: 'รายการสต๊อกที่สนใจ',
      })
    )
  }

  _getProductCountByTemplateId = (templateId: string) => this.state[`count_${templateId}`]

  renderDashboardItem = (dItem: IProductVariantListFilterTemplateDashboardItem, index: number) => {
    const { isSubmitting, isEditMode, editingPref } = this.state
    const { dashboardItems = [] } = editingPref
    const { id, templateId } = dItem
    const tmpl = this._getProductListFilterTemplateById(templateId)

    if (!tmpl) {
      return null
    }

    // const { name, color } = tmpl
    const { selectedStore } = this.props
    const isMoveUpAvailable = isSubmitting || index > 0
    const isMoveDownAvailable = isSubmitting || index < dashboardItems.length - 1

    return (
      <DashboardItem
        key={`dashboard-item-${id}_${index}`}
        storeId={selectedStore.get('id')}
        disabled={isSubmitting}
        isEditable={isEditMode}
        {...dItem}
        template={tmpl}
        onPress={this._onDashboardItemPress}
        onRemovePress={this._onRemoveDashboardItem}
        fetchProductCount={this._fetchProductCount}
        productCount={this._getProductCountByTemplateId(tmpl.id)}
        isMoveUpAvailable={isMoveUpAvailable}
        isMoveDownAvailable={isMoveDownAvailable}
        onMoveUpPress={this._onMoveUpPress}
        onMoveDownPress={this._onMoveDownPress}
      />
    )

    // return (
    //   <HStack
    //     key={`dashboard-item-${id}_${index}`}
    //     w='full'
    //     alignItems='center'
    //     justifyContent='center'
    //     space='2'
    //     borderColor={color}
    //     borderWidth='1'
    //     p='2'
    //     borderRadius='lg'
    //     bg='white'>
    //     <Box w='20px' h='20px' borderRadius='full' bg={color} />
    //     <XText colo={color}>{name}</XText>

    //     <HStack position='absolute' top='0' right='0'>
    //       <XIconButton name='close' onPress={() => this._onRemoveDashboardItem(id)} />
    //     </HStack>
    //   </HStack>
    // )
  }

  renderDashboardItems = () => {
    const { editingPref, isEditMode } = this.state
    const pref = this._getPref()

    const { dashboardItems: dis } = pref
    const { dashboardItems: edtdis } = editingPref

    const dashboardItems = isEditMode ? edtdis : dis
    if (!_.isArray(dashboardItems) || dashboardItems.length === 0) {
      return this.renderEmptyDashboardItems()
    }

    // const { dashboardItems = [] } = this.state
    return dashboardItems.map(this.renderDashboardItem)
  }

  _onMoveUpPress = async (id: string) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { editingPref } = this.state
    const fromIndex = _.findIndex(editingPref.dashboardItems, (di) => di.id === id)
    await this._moveDashboardItem(fromIndex, fromIndex - 1)

    this.inProcess = false
  }

  _moveDashboardItem = async (from: number, to: number) => {
    const { editingPref } = this.state
    const { dashboardItems = [] } = editingPref

    const newDashboardItems = [...dashboardItems]

    // remove `from` item and store it
    const f = newDashboardItems.splice(from, 1)[0]

    // insert stored item into position `to`
    newDashboardItems.splice(to, 0, f)

    await util.setStatePromise(this, { editingPref: { ...editingPref, dashboardItems: newDashboardItems } })
  }

  _onMoveDownPress = async (id: string) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { editingPref } = this.state
    const fromIndex = _.findIndex(editingPref.dashboardItems, (di) => di.id === id)
    await this._moveDashboardItem(fromIndex, fromIndex + 1)

    this.inProcess = false
  }

  _addDashboardItem = async (tmpl: IProductListFilterTemplate) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { editingPref: pref } = this.state
    const editingPref = _.cloneDeep(pref)

    if (!_.isArray(editingPref.dashboardItems)) {
      editingPref.dashboardItems = []
    }

    editingPref.dashboardItems.push({
      id: `${dayjs().format('YYYYMMDD').toString()}di${util.generateRandomString(3)}`,
      createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      updatedAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),

      templateId: tmpl.id,
    })

    await util.setStatePromise(this, { editingPref })

    this.inProcess = false
  }

  _removeDashboardItem = async (dashboardItemId: string) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { editingPref: pref } = this.state
    const editingPref = _.cloneDeep(pref)

    editingPref.dashboardItems = editingPref.dashboardItems.filter((dItem) => dItem.id !== dashboardItemId)

    await util.setStatePromise(this, { editingPref })

    this.inProcess = false
  }

  _onSelectFilterTemplate = (selectedTemplate: IProductListFilterTemplate) => {
    this._addDashboardItem(selectedTemplate)
  }

  _onSave = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    await util.setStatePromise(this, { isSubmitting: true })
    const pref = this._getPref()
    const { editingPref } = this.state
    const { selectedStore } = this.props

    const store_id = selectedStore.get('id')

    const newPref = _.cloneDeep(pref)
    newPref.dashboardItems = editingPref.dashboardItems
    newPref.updatedAt = dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString()

    const isSuccess = await api.postStoreUser({ store_id, pref_ui_pp_list_filter: newPref })

    if (isSuccess) {
      const { updateSelectedStoreByKey } = this.props
      updateSelectedStoreByKey('pref_ui_pp_list_filter', newPref)
      p.op.showToast('แก้ไขรายการสต๊อกที่สนใจเรียบร้อยแล้ว', 'success')

      await util.delay(100)
      await this._initialize()
    } else {
      p.op.showToast('แก้ไขรายการสต๊อกที่สนใจไม่สําเร็จ', 'danger')
    }

    await util.setStatePromise(this, { isSubmitting: false })
    this.inProcess = false
  }

  _onEditPress = async () => {
    await util.setStatePromise(this, { isEditMode: true })
  }

  _onSaveEditPress = async () => {
    await this._onSave()
    await util.setStatePromise(this, { isEditMode: false })
  }

  _onCancelEditPress = async () => {
    await this._initialize()
    await util.setStatePromise(this, { isEditMode: false })
  }

  renderCardHeader = () => {
    const { isEditMode = false, isSubmitting } = this.state
    return (
      <HStack w='full' py='0.5' alignItems='center'>
        {isEditMode ? (
          <NavHeaderButton submitting={isSubmitting} label='บันทึก' onPressItem={this._onSaveEditPress} />
        ) : (
          <Box w='44px' h='34px' />
        )}

        <VStack flex={1}>
          <XText w='full' textAlign='center' variant='inactive' bold>
            {isEditMode ? 'แก้ไขรายการสต๊อกที่สนใจ' : 'รายการสต๊อกที่สนใจ'}
          </XText>
        </VStack>

        {isEditMode ? (
          <NavHeaderButton submitting={isSubmitting} label='ยกเลิก' onPressItem={this._onCancelEditPress} />
        ) : (
          <XIconButton
            variant='ghost'
            bg='gray.100'
            borderRadius='full'
            name='edit'
            family='MaterialIcons'
            overflow='none'
            onPress={this._onEditPress}
          />
        )}
      </HStack>
    )
  }

  _renderAddDashboardItemButton = (onPress) => (
    <TouchableOpacity onPress={onPress} style={{ width: '100%' }}>
      <HStack
        // flex={1}
        w='full'
        px='2'
        py='1'
        space='2'
        alignItems='center'
        borderWidth='1'
        borderStyle='dashed'
        borderColor='gray.400'
        borderRadius='lg'>
        <AddIcon onPress={onPress} />
        <XText variant='primary' numberOfLines={1}>
          เพิ่มสินค้าที่สนใจ
        </XText>
      </HStack>
    </TouchableOpacity>
  )

  _renderDashboardExceededWarning = () => (
    <HStack
      // flex={1}
      w='full'
      px='2'
      py='1'
      space='2'
      alignItems='center'
      justifyContent='center'
      // borderWidth='1'
      // borderStyle='dashed'
      borderColor='gray.100'
      borderRadius='lg'>
      <XText variant='inactive' numberOfLines={1}>
        เพิ่มสินค้าที่สนใจได้สูงสุด 5 รายการ
      </XText>
    </HStack>
  )

  renderEmptyDashboardItems = () => (
    <HStack
      // flex={1}
      w='full'
      px='2'
      py='1'
      space='2'
      alignItems='center'
      justifyContent='center'
      // borderWidth='1'
      // borderStyle='dashed'
      borderColor='gray.100'
      borderRadius='lg'>
      <XText variant='inactive' numberOfLines={1}>
        {'ยังไม่มีรายการสต๊อกที่สนใจ\nสามารถเพิ่มได้โดยการกดปุ่มแก้ไขทางด้านขวา'}
      </XText>
    </HStack>
  )

  isExceededMaxDashboardItems = () => {
    const { editingPref: pref } = this.state
    const { dashboardItems } = pref
    return dashboardItems.length >= 5
  }

  _onPrefSaveSuccess = async (newPref: IProductListFilterPreference, savedTemplate?: IProductListFilterTemplate) => {
    await this._initialize()
  }

  renderMain = () => {
    const { isSubmitting, isEditMode } = this.state
    const { navigation } = this.props

    return (
      <XCard>
        <VStack w='full' p='2' pt='1' space='1.5'>
          {this.renderCardHeader()}

          {this.renderDashboardItems()}

          {isEditMode && !this.isExceededMaxDashboardItems() && (
            <ProductVariantListFilterTemplateSelector
              disabled={isSubmitting}
              navigation={navigation}
              type='custom'
              renderCustom={this._renderAddDashboardItemButton}
              selectedTemplate={null}
              onSelect={this._onSelectFilterTemplate}
              onSaveSuccess={this._onPrefSaveSuccess}
            />
          )}

          {isEditMode && this.isExceededMaxDashboardItems() ? this._renderDashboardExceededWarning() : null}
        </VStack>
      </XCard>
    )
  }

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return this.renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(ProductVariantListFilterTemplateDashboardItems) as React.FC<
  Omit<IProductVariantListFilterTemplateDashboardItemsProps, 'selectedStore' | 'updateSelectedStoreByKey'>
>
