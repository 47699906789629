import React from 'react'

import { COLORS } from 'x/config/styles'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'
import _ from 'lodash'

export const JSON_PACKAGE_DETAIL_DATA = {
  items_package_detail: [
    {
      section_name: 'ระบบจัดการคำสั่งซื้อ',
      items: [
        {
          key: 'order_count_quota',
          name: 'จำนวนออเดอร์',
          unit: 'ออเดอร์',
        },
        {
          name: 'ลิงก์บิลออนไลน์ ให้ลูกค้าชำระและติดตามสถานะจัดส่ง',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'แจ้งเตือนการสร้างออเดอร์ซ้ำ',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'กระจายที่อยู่เบอร์โทรอัตโนมัติ สร้างออเดอร์ไว',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'สร้างออเดอร์ใหม่จากสำเนาออเดอร์เดิม',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ระบุหมายเหตุบนใบปะหน้า',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'คำนวณค่าจัดส่งอัตโนมัติ เช่นตามชิ้น ตามน้ำหนัก ตามยอด',
          available_types: [2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบจัดการช่องทางขาย',
      items: [
        {
          key: 'mkp_quota',
          name: 'จำนวนการเชื่อมต่อช่องทางขายและระบบบัญชี',
          unit: 'ช่องทาง',
        },
        {
          name: 'เชื่อมต่อ Shopee Lazada TikTok และ LINE Shopping และอื่นๆ',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'แชร์สต๊อก คลังเดียว ทุกช่องทาง แบบเรียลไทม์',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'พิมพ์ใบปะหน้าพัสดุรวมทุกช่องทาง ใน 1 คลิ๊ก',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'รองรับ Seller Own Fleet ทั้งแบบ 1 หรือหลายพัสดุต่อออเดอร์ ออกเลขพัสดุและอัพเดทไปที่ช่องทางขายอัตโนมัติเมื่อจัดส่ง',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ดึงข้อมูลในการออกใบกำกับภาษีจากช่องทางขายให้อัตโนมัติ',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ดูรายงานยอดขาย กำไรจริงหลังหักค่าธรรมเนียม แยกช่องทาง',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ดูรายงานยอดขาย กำไรจริงหลังหักค่าธรรมเนียม แยกช่องทาง',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบบัญชี',
      items: [
        {
          name: 'เชื่อมต่อระบบบัญชีออนไลน์ PEAK',
          available_types: [4, 5, 999],
        },
        {
          name: 'สร้างใบแจ้งหนี้ ใบเสร็จรับเงิน ใบกำกับภาษี จากออเดอร์ที่สร้างเองหรือช่องทางขาย',
          available_types: [4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบจัดการแอดมิน',
      items: [
        {
          key: 'helper_count',
          name: 'จำนวนบัญชีผู้ใช้งาน',
          unit: 'คน',
        },
        {
          name: 'กำหนดสิทธิแยกแผนก',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ดูรายงานการขายแยกบัญชีเพื่อคำนวณค่าคอม',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบราคา',
      items: [
        {
          key: 'pg_quota',
          name: 'จำนวนราคา',
          unit: 'ราคา',
        },
        {
          name: 'ตั้งได้หลายราคาต่อสินค้า เช่น ราคาปลีก ส่ง VIP',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบสมาชิก',
      items: [
        {
          key: 'ug_quota',
          name: 'จำนวนกลุ่มสมาชิก',
          unit: 'กลุ่ม',
        },
        {
          name: 'จำนวนสมาชิกไม่จำกัด',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'จัดการกลุ่ม และเพิ่ม/ย้าย/ลบสมาชิก',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'กำหนดราคาขาย และการมองเห็นสินค้าเฉพาะกลุ่ม',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ดูรายงานยอดขายรวม หรือแยกสินค้าเฉพาะสมาชิกแต่ละคน',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ระบุข้อความระหว่างผู้ซื้อผู้ขายได้ในแต่ละออเดอร์',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'สร้างเครือข่ายสมาชิกต่อท้ายได้ไม่จำกัดชั้นและจำนวน',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบร้านค้า',
      items: [
        {
          key: 'store_count',
          name: 'จำนวนร้านค้า',
          unit: 'ร้าน',
        },
      ],
    },
    {
      section_name: 'ระบบจัดการสินค้า',
      items: [
        {
          name: 'จำนวนสินค้าไม่จำกัด',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'หมวดหมู่สินค้าหลัก/ย่อยไม่จำกัด',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'รองรับตัวเลือกสินค้า เช่น สี ขนาด พร้อมรูปแยก',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ดูรายงานสินค้าคงคลัง และความเคลื่อนไหว',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'พิมพ์ฉลากบาร์โคดสินค้า',
          available_types: [3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบพรีออเดอร์',
      items: [
        {
          name: 'ระบุวันกำหนดส่งแยกแต่ละออเดอร์',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'กรองดูออเดอร์เฉพาะสินค้าที่รอส่งหรือวันกำหนดส่ง เพื่อดูหรือพิมพ์ใบปะหน้าพัสดุ',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ดูรายงานสรุปรายการและจำนวนสินค้าที่ลูกค้าสั่งมาในแต่ละรอบเพื่อนำไปสั่งซื้อ',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบจัดการการชำระเงิน',
      items: [
        {
          name: 'การแนบสลิปโอนเงิน',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'การตรวจสลิปอัตโนมัติ',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'การเก็บเงินปลายทาง (COD)',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบเตรียมการจัดส่ง',
      items: [
        {
          name: 'เลือกพิมพ์ใบปะหน้าได้ตามต้องการ เช่น ตามขนส่ง ตามรอบเวลา ตามสินค้า ตามวันกำหนดส่ง และอื่นๆ',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'พิมพ์ใบปะหน้าพัสดุผ่าน Paperang เครื่องพิมพ์สติ๊กเกอร์ลาเบล หรือ A4 แยกหลายพัสดุต่อ 1 ออเดอร์',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'เพื่อขอเลขติดตามพัสดุและพิมพ์ใบปะหน้าแยก สแกนตรวจสอบสินค้าขณะแพ็ค',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบการจัดส่ง',
      items: [
        {
          name: 'เชื่อมขนส่งชั้นนำ เช่น Flash ไปรษณีย์ไทย Kerry J&T BEST',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ขอเลขพัสดุอัตโนมัติ รวดเร็ว',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'อัพเดทสถานะการจัดส่งแบบเรียลไทม์',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบร้านขายส่ง',
      items: [
        {
          name: 'สมัครเป็นตัวแทนได้ไม่จำกัดจำนวนร้านขายส่ง',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ดึงสินค้าร้านขายส่งเข้าร้าน สร้างเป็นรายการสินค้าและ ตั้งราคาของร้านตัวเองได้',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'อัพเดทสต๊อกจากร้านขายส่งแบบเรียลไทม์',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'สร้างออเดอร์ให้ร้านขายส่งอัตโนมัติ',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'อัพเดทสถานะการจัดส่งจากร้านขายส่งให้ลูกค้าร้านเรา',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบจัดการส่วนลด',
      items: [
        {
          name: 'ส่วนลดท้ายบิล',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ส่วนลดกลุ่มสมาชิก',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ส่วนลดขายส่ง ซื้อมาก ลดมาก',
          available_types: [4, 5, 999],
        },
      ],
    },
    {
      section_name: 'ระบบนำเข้าข้อมูล',
      items: [
        {
          name: 'นำเข้าสินค้า',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'นำเข้าลูกค้า',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'นำเข้ารายการยืนยันการได้รับเงินปลายทาง (COD) ที่ได้รับจากขนส่ง',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'แก้ไข SKU แบบชุด',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'ปรับคลังสินค้าทั้งร้าน หรือ ตาม SKU',
          available_types: [2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'อุปกรณ์',
      items: [
        {
          name: 'ใช้ง่ายได้ทุก Platform ผ่าน iPhone iPad Android หรือ เว็บ บน PC/Mac',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ใช้งานพร้อมกันได้ไม่จำกัดอุปกรณ์ ซิงก์ข้อมูลข้ามอุปกรณ์ เรียลไทม์',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'รายงาน',
      items: [
        {
          key: 'r_dsc_day',
          name: 'ดูย้อนหลัง',
          unit: 'วัน',
        },
        {
          name: 'แดชบอร์ดการขาย',
          available_types: [1, 2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'รายงานออเดอร์',
      items: [
        {
          name: 'ยอดขายประจำวัน',
          available_types: [1, 2, 3, 4, 5, 999],
        },
        {
          name: 'ยอดขายอย่างละเอียด',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'ยอดขายผู้ใช้งานและผู้ช่วย',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'ยอดขายตัวแทน',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'ยอดขายจำแนกตาช่องทาง',
          available_types: [2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'รายงานสินค้า',
      items: [
        {
          name: 'สินค้าคงคลัง',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'การปรับคลังสินค้า',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'การขายสินค้ารายวัน',
          available_types: [2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'รายงานชำระ',
      items: [
        {
          name: 'การรับชำระตามบัญชี',
          available_types: [2, 3, 4, 5, 999],
        },
      ],
    },
    {
      section_name: 'รายงานจัดส่ง',
      items: [
        {
          name: 'ออเดอร์ที่จัดส่งแล้ว',
          available_types: [2, 3, 4, 5, 999],
        },
        {
          name: 'ออเดอร์ที่ยังไม่ได้จัดส่ง',
          available_types: [2, 3, 4, 5, 999],
        },
      ],
    },
  ],
}

interface IXUISubcriptionDetailProps {
  subscription: {
    // m_o_count: number
    mkp_quota: number
    helper_count: number
    order_count_quota: number
    pg_quota: number
    store_count: number
    ug_quota: number
    vd_quota: number
    wh_quota: number
  }
  type: number
}

const XUISubcriptionDetail = (props: IXUISubcriptionDetailProps) => {
  // console.log('props => ', props)
  // console.log('subcription => ', props.subscription)
  // console.log('type => ', props.type)
  if (_.isNil(props)) {
    return null
  }
  const itemPackageDetails = JSON_PACKAGE_DETAIL_DATA.items_package_detail
  const subscriptionData = props.subscription
  const subscriptionType = props.type
  if (_.isNil(subscriptionData) || _.isNil(subscriptionType)) {
    return null
  }
  const _getCountPackageByKey = (key: string) => {
    if (_.isNil(key)) {
      return '0'
    }
    if (_.isNil(subscriptionData[key])) {
      return '0'
    }
    return subscriptionData[key]
  }
  return (
    <VStack w='full' bgColor={COLORS.WHITE}>
      {itemPackageDetails.map((section, idx) => {
        const sectionName = section.section_name
        let haveQuotaCount = true
        return (
          <VStack key={`section_${idx}`}>
            <VStack justifyContent='flex-start' w='full' mt='3.5'>
              <XText bold fontSize='md'>
                {sectionName}
              </XText>
              {section.items.map((item, indexMap) => {
                const availableTypes = item?.available_types
                // const abc = indexMap === 1 ? '1' : '99,999'
                const countPackage = item?.key ? _getCountPackageByKey(item.key) : null
                if (countPackage === '0' || countPackage === 0) {
                  haveQuotaCount = false
                }
                let packageActive = !_.isNil(availableTypes) && availableTypes.includes(subscriptionType)
                // subscriptionType === 999 : ถ้าเป็นแพ็กเกจทดลองให้ติ๊กถูกเสมอ
                if (subscriptionType === 999) {
                  packageActive = true
                }
                if (!haveQuotaCount) {
                  packageActive = false
                }
                let RENDER_ICON = (
                  <HStack justifyContent='center' w='32'>
                    <XIcon name='closecircleo' family='AntDesign' color={COLORS.RED} />
                  </HStack>
                )
                if (packageActive) {
                  RENDER_ICON = (
                    <HStack justifyContent='center' w='32'>
                      <XIcon name='checkcircleo' family='AntDesign' color={COLORS.GREEN} />
                    </HStack>
                  )
                }
                return (
                  <HStack w='full' mt='2' space='1' key={`item_${indexMap}`}>
                    <HStack flex={1}>
                      <XText variant='inactive' fontSize='md'>
                        {item.name}
                      </XText>
                    </HStack>
                    {/* {_.isNil(availableTypes) && !p.op.isIOS() ? (
                      <HStack justifyContent='flex-end' w='10'>
                        <XText
                          variant='inactive'
                          color={COLORS.BRAND_Info}
                          fontSize='md'
                          onPress={() => p.op.showConfirmationOkOnly('', 'กรุณาติดต่อทีมงาน')}>
                          เพิ่ม?
                        </XText>
                      </HStack>
                    ) : null} */}
                    {_.isNil(availableTypes) ? (
                      <HStack justifyContent='center' w='32'>
                        <XText variant='active' bold fontSize='md'>{`${countPackage} ${item.unit}`}</XText>
                      </HStack>
                    ) : (
                      RENDER_ICON
                    )}
                  </HStack>
                )
              })}
            </VStack>
            <HStack borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth='1' mt='2' />
          </VStack>
        )
      })}
    </VStack>
  )
}

export default XUISubcriptionDetail
